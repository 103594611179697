import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { InputLabel, Select, MenuItem } from '@material-ui/core';

const CountrySelect = props => {
  const { classes, countries } = props;
  const [countryCode, setCountryCode] = React.useState(props.defaultValue);

  const handleChange = e => {
    setCountryCode(e.target.value);
    props.onChange({ target: { name: props.name, value: e.target.value } });
  };

  React.useEffect(() => {
    setCountryCode(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <>
      <InputLabel shrink htmlFor="country-label-placeholder">
        Country
      </InputLabel>
      <Select
        displayEmpty
        name={props.name}
        value={countryCode}
        onChange={handleChange}
        className={classes.selectEmpty}
      >
        {countries.map(country => (
          <MenuItem key={country.code} value={country.code}>
            {country.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

CountrySelect.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  classes: PropTypes.object.isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

CountrySelect.defaultProps = {
  onChange: f => f,
  name: 'countryCode',
  defaultValue: 'CA',
};

const mapStateToProps = ({ common }) => ({
  countries: common.countries,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelect);
