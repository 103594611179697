import BaseApi from './BaseApi';

class PaymentApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl += '/payments';
  }

  async getMethod() {
    return await this.api.get(`${this.baseUrl}/methods`);
  }

  async saveMethod(model) {
    return await this.api.post(`${this.baseUrl}/methods`, model);
  }
}

export default new PaymentApi();
