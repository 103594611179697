import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import _ from 'lodash';
import { enqueueError } from '../../modules/global';
import reportApi from '../../apis/report.api';
import analyzeApi from '../../apis/analyze.api';
import accountApi from '../../apis/account.api';

const RESET = 'reports/RESET';
const CUSTOM_REPORTS_LOADED = 'reports/CUSTOM_REPORTS_LOADED';
const CUSTOM_REPORT_DELETED = 'reports/CUSTOM_REPORTS_DELETED';
const COMPANY_LOGO_LOADED = 'reports/COMPANY_LOGO_LOADED';

const resetAction = createAction(RESET);
const getCompanyLogoAction = createAction(COMPANY_LOGO_LOADED);
const getCustomReportsAction = createAction(CUSTOM_REPORTS_LOADED);
const deleteCustomReportAction = createAction(CUSTOM_REPORT_DELETED);
const initState = {
  loaded: false,
  reportItems: [],
  reportResult: [],
  companyLogo: undefined,
};

export default handleActions(
  {
    [RESET]: (state, action) =>
      produce(state, draft => {
        draft.loaded = false;
      }),
    [CUSTOM_REPORTS_LOADED]: (state, action) =>
      produce(state, draft => {
        const data = action.payload;
        draft.reportItems = data;
        draft.loaded = true;
      }),
    [CUSTOM_REPORT_DELETED]: (state, action) =>
      produce(state, draft => {
        const id = action.payload;
        draft.reportItems = draft.reportItems.filter(x => x.id !== id);
      }),
    [COMPANY_LOGO_LOADED]: (state, action) =>
      produce(state, draft => {
        const data = action.payload;
        draft.companyLogo = data;
        draft.loaded = true;
      }),
  },
  initState,
);

export const getCustomReports = () => async dispatch => {
  dispatch(resetAction());
  try {
    const { data: reportItems } = await reportApi.getCustomReports();
    const { data: analysisItems } = await analyzeApi.getReports();
    const mergedItems = [].concat(reportItems).concat(analysisItems);
    _.sortBy(mergedItems, ['creationDate']);
    dispatch(getCustomReportsAction(mergedItems));
  } catch (err) {
    dispatch(enqueueError(err));
  }
};

export const deleteCustomReport = item => async dispatch => {
  dispatch(resetAction());
  try {
    if (item.chartType) {
      await analyzeApi.deleteReport(item.id);
      dispatch(deleteCustomReportAction(item.id));
    } else {
      await reportApi.deleteCustomReport(item.id);
      dispatch(deleteCustomReportAction(item.id));
    }
  } catch (err) {
    dispatch(enqueueError(err));
  }
};

export const getCompanyLogo = () => async (dispatch, getState) => {
  dispatch(resetAction());
  try {
    const { reports } = getState();

    if (!reports.companyLogo) {
      const { data } = await accountApi.getLogo();
      dispatch(getCompanyLogoAction(data));
    }
  } catch (err) {
    dispatch(enqueueError(err));
  }
};
