import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import {
  FormControl,
  Typography,
  Button,
  InputLabel,
  Input,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  group: {
    margin: 0,
  },
  label: {
    marginLeft: theme.spacing(-1),
  },
  searchFields: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}));

const CompanySearchOption = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    hasCompany: undefined,
    isOwner: undefined,
  });
  const {
    name,
    hasCompany,
    relationReporter,
    isOwner,
    relationVictim,
    relationPersonOfInterest,
    relationSuspect,
    relationWitness,
    relationSubject,
  } = state;

  const inputHandleChange = event => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const addSearchCriteria = () => {
    const relations = [];
    if (relationReporter) {
      relations.push('REPORTER');
    }
    if (relationVictim) {
      relations.push('VICTIM');
    }
    if (relationPersonOfInterest) {
      relations.push('PERSON_OF_INTEREST');
    }
    if (relationSuspect) {
      relations.push('SUSPECT');
    }
    if (relationWitness) {
      relations.push('WITNESS');
    }
    if (relationSubject) {
      relations.push('SUBJECT');
    }

    const company = {
      id: uuid(),
      hasCompany,
      name,
      isOwner,
      relations: relations.length > 0 ? relations : undefined,
    };

    if (company.hasCompany || company.isOwner || company.name) {
      props.onSearchCriteriaAdded(company);
    } else {
      props.onError(
        'Please set "Has legal entity" on, enter name or set "Is owner" on.',
      );
    }
  };

  const toggleHasCompany = () => {
    setState({
      ...state,
      hasCompany: !hasCompany,
      name: hasCompany ? '' : name,
    });
  };

  const toggleIsOwner = event => {
    setState({
      ...state,
      name: event.target.checked ? props.ownerCompanyName : '',
      isOwner: event.target.checked ? true : undefined,
    });
  };

  const toggleRelationChange = event => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const getCompanyName = () => {
    if (hasCompany) {
      return '';
    }
    if (isOwner) {
      return props.ownerCompanyName;
    }
    return state.name;
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography variant="h6">Legal entity</Typography>
        <div className={classes.searchFields}>
          <FormControlLabel
            control={
              <Switch checked={hasCompany} onChange={toggleHasCompany} />
            }
            label={<Typography variant="body1">Has legal entity</Typography>}
          />
        </div>
        <div className={classes.searchFields}>
          <FormControl
            className={classes.formControl}
            disabled={hasCompany || isOwner}
          >
            <InputLabel htmlFor="name" shrink={isOwner}>
              Name
            </InputLabel>
            <Input
              id="name"
              name="name"
              autoFocus={!hasCompany}
              value={getCompanyName()}
              onChange={inputHandleChange}
            />
          </FormControl>
          <FormControlLabel
            disabled={hasCompany}
            control={<Switch checked={isOwner} onChange={toggleIsOwner} />}
            label={<Typography variant="body1">Is Owner</Typography>}
          />
        </div>
        <div className={classes.switchGroup}>
          <Typography variant="h6" className={classes.switchGroupLabel}>
            Relation
          </Typography>
          <FormControlLabel
            disabled={hasCompany}
            control={
              <Switch
                checked={state.relationReporter || false}
                onChange={toggleRelationChange}
                name="relationReporter"
              />
            }
            label={<Typography variant="body1">Reporter</Typography>}
          />
          <FormControlLabel
            disabled={hasCompany}
            control={
              <Switch
                checked={state.relationVictim || false}
                onChange={toggleRelationChange}
                name="relationVictim"
              />
            }
            label={<Typography variant="body1">Victim</Typography>}
          />
          <FormControlLabel
            disabled={hasCompany}
            control={
              <Switch
                checked={state.relationSubject || false}
                onChange={toggleRelationChange}
                name="relationSubject"
              />
            }
            label={<Typography variant="body1">Subject</Typography>}
          />
        </div>
        <div className={classes.searchFields}>
          <FormControl className={classes.formControl}>
            <Button
              variant="contained"
              color="primary"
              className={classes.searchButton}
              onClick={addSearchCriteria}
            >
              Add Search Criteria
            </Button>
          </FormControl>
        </div>
      </div>
    </React.Fragment>
  );
};
CompanySearchOption.propTypes = {
  ownerCompanyName: PropTypes.string.isRequired,
  onSearchCriteriaAdded: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

CompanySearchOption.defaultProps = {
  onSearchCriteriaAdded: () => {},
  onError: () => {},
};

export default CompanySearchOption;
