import React from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import _ from 'lodash';
import SearchResultColumn from './SearchResultColumn';
import BaseDialog from '../../components/dialogs/BaseDialog';
import { SearchResultColumnDef } from '../../components/propTypeDefs';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
}));

const SearchResultColumnsDialog = props => {
  const classes = useStyles();

  const [columns, setColumns] = React.useState(props.searchResultColumns);
  const moveColumn = React.useCallback(
    (dragIndex, hoverIndex) => {
      const dragCol = columns[dragIndex];
      setColumns(
        produce(columns, draft => {
          draft.splice(dragIndex, 1);
          draft.splice(hoverIndex, 0, dragCol);
        }),
      );
    },
    [columns],
  );

  const onColumnChange = (columnId, checked) => {
    setColumns(
      produce(columns, draft => {
        const found = _.find(draft, { id: columnId });
        if (found) {
          found.checked = checked;
        }
      }),
    );
  };

  React.useEffect(() => {
    props.onChange(columns);
    sessionStorage.setItem('mySearchResultColumns', JSON.stringify(columns));
  }, [columns]);

  const renderColumn = (col, index) => {
    return (
      <SearchResultColumn
        key={col.id}
        index={index}
        col={col}
        moveColumn={moveColumn}
        onChange={onColumnChange}
      />
    );
  };

  return (
    <BaseDialog
      open={props.open}
      onClose={props.onClose}
      title="Select columns"
      actionContent={
        <>
          <Button onClick={props.onClose} color="default" pl={3}>
            Close
          </Button>
        </>
      }
    >
      <DndProvider backend={Backend}>
        <div className={classes.root}>
          {columns && columns.map((col, i) => renderColumn(col, i))}
        </div>
      </DndProvider>
    </BaseDialog>
  );
};

SearchResultColumnsDialog.propTypes = {
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  open: PropTypes.bool.isRequired,
  searchResultColumns: PropTypes.arrayOf(SearchResultColumnDef),
};
SearchResultColumnsDialog.defaultProps = {
  onChange: f => f,
  onClose: f => f,
};

export default SearchResultColumnsDialog;
