import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import config from '../../config';
import {
  Typography,
  Box,
  Avatar,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Button,
  Link,
  Container,
  CssBaseline,
  FormControl,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import _ from 'lodash';

import DefaultLayout from '../DefaultLayout';
import { useMediaInfo } from '../../utils/hooks';
import { indigo } from '@material-ui/core/colors';
import { useForm } from '../../utils/hooks';
import * as yup from 'yup';
import auth from '../../auth/Auth';
import { sendOTP, verifyEnrol, sendOTPForEnrol } from './ducks';
import AlertDialog from '../../components/dialogs/AlertDialog';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5),
    backgroundColor: '#fafafa',
    justifyContent: 'center',
    alignItems: 'center',
  },

  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  mobileRoot: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0),
    backgroundColor: '#fafafa',
  },
  title: {
    padding: theme.spacing(2),
  },
  subTitle: {
    paddingBottom: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(1),
    color: theme.palette.getContrastText(indigo[500]),
    backgroundColor: indigo[500],
  },
  content1: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: theme.spacing(2),
  },
  phone: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(2),
  },
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 180,
    width: '100%',
  },
  buttons: {
    marginBottom: theme.spacing(2),
  },
}));

const schema = yup.object().shape({
  code: yup
    .string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(6, 'Must be exactly 6 digits')
    .max(6, 'Must be exactly 6 digits')
    .required('Enter 6 digit code.'),
});

const VerifyMfaEnrollment = props => {
  const {
    formValues,
    updateFormValues,
    handleFormChange,
    handleFormBlur,
    hasFormError,
    formHelpText,
    handleFormSubmit,
  } = useForm({
    validationSchema: schema,
    onSubmit(values, errors) {
      if (_.isEmpty(errors)) {
        const { code } = values;
        props
          .verifyEnrol(code)
          .then(() => {
            setState({
              ...state,
              completed: true,
            });
          })
          .catch(err => {});
      }
    },
  });

  const { phoneNumber } = props.location.state || {};

  const [state, setState] = React.useState({
    phoneNumber,
    codeSent: false,
    completed: false,
  });

  const { isSmaller, isMobile } = useMediaInfo({ minBreakPoint: 'md' });
  const classes = useStyles();

  const logout = () => {
    auth.logout();
  };

  const sendCode = () => {
    props.sendOTPForEnrol().then(() => {
      setState({
        ...state,
        codeSent: true,
      });
    });
  };

  return (
    <DefaultLayout title={null}>
      <div className={classes.root}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div className={classes.center}>
              <Avatar className={classes.avatar}>
                <LockIcon />
              </Avatar>
              <div />
              <Typography component="h1" variant="h6">
                Security Check
              </Typography>
            </div>

            <div className={classes.content1}>
              <Typography variant="body1">
                To verify your phone number, we'll send a six-digit code to your
                phone number.
              </Typography>
            </div>
            <div className={classes.phone}>
              <Typography variant="h5">
                Send code to: {state.phoneNumber}
              </Typography>
            </div>

            <Box component="form" noValidate sx={{ mt: 1 }}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="code"
                  margin="normal"
                  required
                  fullWidth
                  label="Code"
                  name="code"
                  inputProps={{ autoComplete: 0 }}
                  variant="outlined"
                  onChange={handleFormChange}
                  onBlur={handleFormBlur}
                  helperText={formHelpText('code')}
                  error={hasFormError('code')}
                />
              </FormControl>
              <div className={classes.buttons}>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={props.loading}
                  id="verifyMfaEnrollmentSubmit"
                  // className="free-trial-form-submit"
                  onClick={handleFormSubmit}
                >
                  Verify code
                </Button>
              </div>

              <div className={classes.buttons}>
                <div className={classes.center}>
                  Didn't get the code?
                  <Button
                    // type="submit"
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    disabled={props.loading}
                    id="mfaResendCode"
                    // className="free-trial-form-submit"
                    onClick={sendCode}
                  >
                    Send new code
                  </Button>
                </div>
              </div>
              <div className={classes.buttons}>
                <Button
                  // type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  id="mfaSendCode"
                  className="free-trial-form-submit"
                  onClick={logout}
                >
                  Cancel
                </Button>
              </div>
            </Box>
          </Box>
        </Container>
      </div>
      {state.completed && (
        <AlertDialog
          title="Success"
          message={
            <div className={classes.alertTextBold}>
              Your phone is successfully registered. Please login again.
            </div>
          }
          open={state.completed}
          onOk={() => {
            logout();
          }}
          cancelButtonHidden={true}
        />
      )}
    </DefaultLayout>
  );
};

VerifyMfaEnrollment.propTypes = {
  phone: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ login }) => {
  return {
    phone: login.phone,
    loading: login.loading,
  };
};
const mapDispatchToProps = {
  sendOTP,
  verifyEnrol,
  sendOTPForEnrol,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VerifyMfaEnrollment);
