import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  IconButton,
  FormControl,
  TextField,
  Fab,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import produce from 'immer';
import uuid from 'uuid/v4';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import IncidentCompanyItem from './IncidentCompanyItem';
import ExpansionCard from '../../components/cards/ExpansionCard';
import BaseDialog from '../../components/dialogs/BaseDialog';
import { IncidentCompanyDef } from '../../components/propTypeDefs';
import { useMediaInfo } from '../../utils/hooks';
import { searchCompany, resetCompanyList } from './ducks';
import { enqueueError, enqueueSuccess } from '../../modules/global';
import CompanyList from './CompanyList';

const useStyles = makeStyles(theme => ({
  expansionCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  expansionCardItem: {},
  companyItem: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
  },
  itemAddButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
  },
  addButton: {
    marginLeft: theme.spacing(1),
  },
  searchForm: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  personList: {
    minHeight: theme.spacing(4),
  },
  cardDialogTitle: {
    display: 'flex',
  },
  cardDialogTitleText: {
    flexGrow: 1,
  },
}));

const IncidentCompanyCard = props => {
  const classes = useStyles();
  const { companies, incidentId } = props;
  const [state, setState] = React.useState({
    companies,
    selectedCompanyId:
      companies && companies.length === 1 ? companies[0].id : undefined,
    searchCompanyDialogOpen: false,
    search: {
      name: '',
    },
  });
  const { isSmaller } = useMediaInfo({ minBreakPoint: 'sm' });

  // React.useEffect(() => {
  //   props.onChange({ companies: state.companies });
  // }, [state.companies]);

  React.useEffect(() => {
    setState({ ...state, companies });
  }, [props.companies]);

  const emitData = data => {
    props.onChange({ companies: data });
  };

  const addCompany = () => {
    // const companyCount = state.companies.length;
    const name = ''; // `Legal entity ${companyCount + 1}`;
    const newCompany = {
      id: uuid(),
      name,
      incidentId,
    };
    const newList = [...state.companies, newCompany];
    setState({
      ...state,
      companies: newList,
      selectedCompanyId: newCompany.id,
    });
    emitData(newList);
  };

  const onCompanyCardExpanded = (expanded, companyId) => {
    setState({
      ...state,
      selectedCompanyId: expanded ? companyId : undefined,
    });
  };

  const onCompanyDeleted = companyId => {
    const newList = state.companies.filter(x => x.id !== companyId);
    setState({
      ...state,
      companies: newList,
    });
    emitData(newList);
  };

  const companyChanged = company => {
    const nextState = produce(state, draft => {
      const found = draft.companies.find(x => x.id === company.id);
      if (found) {
        !found.incidentId && (found.incidentId = incidentId);
        Object.assign(found, company);
      }
    });
    setState(nextState);
    emitData(nextState.companies);
  };

  const openSearchDialog = () => {
    setState({ ...state, searchCompanyDialogOpen: true });
  };

  const closeSearchDialog = () => {
    props.resetCompanyList();
    setState({
      ...state,
      searchCompanyDialogOpen: false,
      search: {},
      invalidSearchForm: false,
    });
  };

  const validateSearchForm = searchState => {
    const invalidSearchForm = !searchState.name;

    setState({ ...state, invalidSearchForm });
    return !invalidSearchForm;
  };
  const handleSearchChange = e => {
    const { name, value } = e.target;
    const nextSearch = { ...state.search, [name]: value };
    setState({ ...state, search: nextSearch });
  };
  const handleSearch = _.debounce(() => {
    if (!validateSearchForm(state.search)) return;
    const { name } = state.search;
    props.searchCompany(name);
  }, 200);

  const addCompanyWithModel = _.debounce(model => {
    const newList = [...state.companies, model];
    setState({
      ...state,
      companies: newList,
      selectedCompanyId: model.id,
      searchCompanyDialogOpen: false,
      search: {},
      invalidSearchForm: false,
    });
    emitData(newList);
    props.resetCompanyList();
  }, 200);

  const handleCompanyItemCommand = (command, value) => {
    const { id: personId, ...others } = value;

    const exists = state.companies.some(x => x.personId === personId);
    if (exists) {
      props.enqueueError(`'${value.name}' already exists.`);
      return;
    }

    // remove null values
    const compressed = _.omitBy(others, _.isNull);
    const newCompany = { ...compressed, id: uuid(), personId, incidentId };
    addCompanyWithModel(newCompany);
  };

  const searchDialogTitle = () => {
    const titleText = 'Select legal entity';
    return (
      <div className={classes.cardDialogTitle}>
        <div className={classes.cardDialogTitleText}>{titleText}</div>
        <IconButton onClick={() => closeSearchDialog()}>
          <CloseIcon />
        </IconButton>
      </div>
    );
  };

  const inputKeyDown = event => {
    if (event.charCode === 13 || event.keyCode === 13) {
      handleSearch();
    }
  };

  return (
    <ExpansionCard
      title={`WHO - Legal entities (${state.companies.length})`}
      helpMessage="Add information about any organizations related to the incident. Tip: Search to see if the legal entity already exists. If not, select Add to add the legal entity's details to the incident record."
    >
      <div className={classes.expansionCardContainer}>
        <div className={classes.expansionCardItem}>
          {state.companies.map(company => {
            return (
              <div className={classes.companyItem} key={company.id}>
                <IncidentCompanyItem
                  company={company}
                  expanded={state.selectedCompanyId === company.id}
                  onCardExpanded={onCompanyCardExpanded}
                  onDeleted={onCompanyDeleted}
                  onChange={companyChanged}
                />
              </div>
            );
          })}
          <div className={classes.itemAddButton}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => openSearchDialog()}
            >
              Search legal entity
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.addButton}
              onClick={addCompany}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
      {state.searchCompanyDialogOpen && (
        <BaseDialog
          open={state.searchCompanyDialogOpen}
          maxWidth="md"
          title={searchDialogTitle()}
          onClose={() => closeSearchDialog()}
          fullScreen={isSmaller}
        >
          <div className={classes.searchForm}>
            <FormControl className={classes.formControl}>
              <TextField
                autoFocus
                name="name"
                label="Legal entity name"
                placeholder="Enter legal entity name"
                onChange={handleSearchChange}
                onKeyDown={inputKeyDown}
                value={state.search.name}
                error={state.invalidSearchForm}
                helperText={
                  state.invalidSearchForm
                    ? 'Please enter "Legal entity name".'
                    : undefined
                }
              />
            </FormControl>

            <Fab
              size="small"
              color="secondary"
              className={classes.button}
              onClick={handleSearch}
            >
              <SearchIcon />
            </Fab>
          </div>
          <div className={classes.personList}>
            <CompanyList
              companies={props.companyList}
              onItemCommand={handleCompanyItemCommand}
            />
          </div>
        </BaseDialog>
      )}
    </ExpansionCard>
  );
};

IncidentCompanyCard.propTypes = {
  onChange: PropTypes.func,
  incidentId: PropTypes.string,
  companies: PropTypes.arrayOf(IncidentCompanyDef),
  companyList: PropTypes.arrayOf(IncidentCompanyDef).isRequired,
  searchCompany: PropTypes.func.isRequired,
  resetCompanyList: PropTypes.func.isRequired,
  enqueueError: PropTypes.func.isRequired,
};

IncidentCompanyCard.defaultProps = {
  onChange: f => f,
  companies: [],
  incidentId: undefined,
  searchCompany,
  resetCompanyList,
  enqueueError,
  companyList: [],
};

const mapStateToProps = ({ newIncident }) => ({
  companyList: newIncident.companyList,
});

const mapDispatchToProps = {
  searchCompany,
  resetCompanyList,
  enqueueError,
  enqueueSuccess,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncidentCompanyCard);
