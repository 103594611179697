import React from 'react';
import PropTypes from 'prop-types';
import { convertFromRaw, EditorState } from 'draft-js';
import Editor, { createEditorStateWithText } from 'draft-js-plugins-editor';

const RichReader = props => {
  let editorState;
  try {
    editorState = props.content
      ? EditorState.createWithContent(convertFromRaw(props.content))
      : createEditorStateWithText('');
  } catch (err) {
    editorState = createEditorStateWithText('');
  }
  return editorState ? (
    <Editor editorState={editorState} readOnly onChange={f => f} />
  ) : (
    undefined
  );
};

RichReader.propTypes = {
  content: PropTypes.object,
};

RichReader.defaultProps = {
  content: undefined,
};

export default RichReader;
