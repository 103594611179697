import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  FormControl,
  InputLabel,
  Input,
  Button,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { IncidentSearchType } from '../../constants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  group: {
    margin: 0,
  },
  label: {
    marginLeft: theme.spacing(-1),
  },
  searchFields: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}));

const AttachmentSearchOption = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    hasAttachment: false,
    name: '',
  });
  const { hasAttachment, name } = state;

  const inputHandleChange = event => {
    const { value } = event.target;
    setState({ ...state, [event.target.name]: value, hasAttachment: false });
  };
  const addSearchCriteria = () => {
    props.onSearchCriteriaAdded({
      id: uuid(),
      hasAttachment,
      name,
    });
  };
  const toggleHasAttachment = () => {
    setState({
      ...state,
      hasAttachment: !hasAttachment,
      name: hasAttachment ? '' : name,
    });
  };
  return (
    <div className={classes.root}>
      <Typography variant="h6">Attachment</Typography>
      <div className={classes.searchFields}>
        <FormControlLabel
          control={
            <Switch
              checked={state.hasAttachment}
              onChange={toggleHasAttachment}
            />
          }
          label={<Typography variant="body1">Has attachment</Typography>}
        />
      </div>
      <div className={classes.searchFields}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="firstName">Name</InputLabel>
          <Input
            name="name"
            autoFocus={!state.hasAttachment}
            disabled={state.hasAttachment}
            value={state.hasAttachment ? '' : state.name}
            onChange={inputHandleChange}
          />
        </FormControl>
      </div>
      <div className={classes.searchFields}>
        <FormControl className={classes.formControl}>
          <Button
            variant="contained"
            color="primary"
            className={classes.searchButton}
            onClick={addSearchCriteria}
          >
            Add Search Criteria
          </Button>
        </FormControl>
      </div>
    </div>
  );
};

AttachmentSearchOption.propTypes = {
  onSearchCriteriaAdded: PropTypes.func.isRequired,
};

export default AttachmentSearchOption;
