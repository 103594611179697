import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  CircularProgress,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/styles';
import uuid from 'uuid/v4';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import IncidentViewDetails from './IncidentViewDetails';
import IncidentViewPersons from './IncidentViewPersons';
import IncidentViewCompanies from './IncidentViewCompanies';
import IncidentViewVehicles from './IncidentViewVehicles';
import IncidentViewItems from './IncidentViewItems';
import IncidentViewAttachments from './IncidentViewAttachments';
import IncidentViewHistory from './IncidentViewHistory';
import {
  IncidentDef,
  DraftIncidentDef,
  LookupDataSetDef,
} from '../../components/propTypeDefs';
import { loadDraftIncident, lockIncident, unlockIncident } from './ducks';
import { IncidentStatusEnum } from '../../constants';
import IncidentFormWrapper from './IncidentFormWrapper';
import { toReadableIncident } from '../../utils/incidentViewUtil';
import ReportViewer from '../../components/ReportViewer';
import reportApi from '../../apis/report.api';
import { canEditIncident, canPrintIncident } from '../../utils/privilegeUtil';
import auth from '../../auth/Auth';
import { useMediaInfo } from '../../utils/hooks';
import { setOpenDeleteDraft, closeIncident } from '../newIncident/ducks';
import AlertDialog from '../../components/dialogs/AlertDialog';
import IncidentCloseDialog from '../../components/dialogs/IncidentCloseDialog';
import IncidentViewCorrectiveActions from './IncidentViewCorrectiveActions';
import IncidentViewClose from './IncidentViewClose';
import { getCompanyLogo } from '../reports/ducks';
import HelpButton from '../../components/buttons/HelpButton';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    marginBottom: theme.spacing(1),
  },
  flex: {
    flex: 1,
  },
  section: {
    padding: theme.spacing(1),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    // flexWrap: 'wrap',
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  label: {
    ...theme.typography.caption,
    color: 'grey',
  },
  value: {
    ...theme.typography.body,
  },
  historyTable: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flexGrow: 1,
    overflow: 'auto',
  },
  reportDialog: {
    '& .MuiDialog-paperScrollPaper': {
      flexGrow: 1,
    },
  },
  closeIncidentButton: {
    flexGrow: 0,
    flexShrink: 0,
    marginLeft: theme.spacing(1),
    background: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  closeIncidentMenu: {
    // background: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  labelWithHelpIcon: {
    display: 'flex',
    alignItems: 'center',
  },
}));

// @ref : https://material-ui.com/guides/composition/#caveat-with-refs
const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const LoadingProgress = props => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt={4}
    >
      <CircularProgress color="secondary" size={100} />
    </Box>
  );
};

const IncidentViewDialog = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    viewMode: props.initialMode,
    draftIncident: undefined,
  });
  const [reportState, setReportState] = React.useState({
    id: undefined,
    opened: false,
  });
  const [accessState, setAccessState] = React.useState({
    editAvailable: false,
  });

  const [closeIncidentDlgOpen, setCloseIncidentDlgOpen] = React.useState(false);
  const [lockIncidentDlgOpen, setLockIncidentDlgOpen] = React.useState(false);
  const [closeIncidentDialogOpen, setCloseIncidentDialogOpen] = React.useState(
    false,
  );
  const { isSmaller, isMobile } = useMediaInfo({ minBreakPoint: 'sm' });
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [menuState, setMenuState] = React.useState({
    showPrint: false,
    showChangeMode: false,
    showLock: false,
    showReopen: false,
    showEdit: false,
    showView: false,
  });

  const { open, incident } = props;
  const readableIncident = React.useMemo(
    () =>
      toReadableIncident(
        props.draftIncident,
        props.lookupDataSet,
        props.companySettings.dateFormat,
      ),
    [props.draftIncident],
  );
  const reportableIncident = React.useMemo(
    () =>
      toReadableIncident(
        props.draftIncident,
        props.lookupDataSet,
        props.companySettings.dateFormat,
        'report',
      ),
    [props.draftIncident],
  );

  const handleClose = () => {
    setState({ ...state, draftIncident: undefined });
    props.onClose();
  };

  const handleSubmit = () => {
    setState({ ...state, draftIncident: undefined });
    props.onRefreshParentItems(['draftIncidents', 'incidents']);
    props.onClose();
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuClick = event => {
    setMenuAnchorEl(event.currentTarget);
  };

  const changeViewMode = viewMode => () => {
    handleMenuClose();
    setState({
      ...state,
      viewMode,
    });
  };
  const handleOpenReport = reportOpen => () => {
    handleMenuClose();
    if (reportOpen) {
      const id = uuid();
      reportApi
        .uploadReportData(
          id,
          reportableIncident,
          `Incident #${reportableIncident.number}`,
        )
        .then(res => {
          setReportState({
            id,
            opened: true,
          });
        })
        .catch(err => {
          console.warn('### save report error', err);
        });
    } else {
      setReportState({ ...reportState, opened: reportOpen });
    }
  };

  React.useEffect(() => {
    props.getCompanyLogo();
  }, []);

  React.useEffect(() => {
    setState({ ...state, viewMode: props.initialMode });
  }, [props.initialMode]);

  React.useEffect(() => {
    setAccessState({
      ...accessState,
      editAvailable: canEditIncident(incident.createUserId),
      reportAvailable: canPrintIncident(incident.createUserId),
    });
    incident && props.loadDraftIncident(incident);
  }, [props.incident]);

  React.useEffect(() => {
    setReportState({ id: undefined, opened: false });
    if (props.loaded && props.draftIncident) {
      setState({ ...state, draftIncident: props.draftIncident });
    }
  }, [props.loaded, props.draftIncident]);

  // decide visibilities of menu items
  React.useEffect(() => {
    const draftStatus = props.draftIncident
      ? props.draftIncident.status
      : undefined;
    const incidentStatus = props.incident ? props.incident.status : undefined;
    const isChangeable =
      props.loaded &&
      props.draftIncident &&
      [IncidentStatusEnum.open, IncidentStatusEnum.closed].includes(
        draftStatus,
      );
    setMenuState({
      ...menuState,
      showPrint: isChangeable && !isMobile,
      showHistory: !isMobile,
      showChangeMode: isChangeable,
      showLock:
        state.viewMode === 'view' &&
        incidentStatus === IncidentStatusEnum.closed,
      showReopen:
        state.viewMode === 'view' &&
        incidentStatus === IncidentStatusEnum.closed,
      accessReopen:
        state.viewMode === 'view' &&
        incidentStatus === IncidentStatusEnum.closed &&
        auth.hasPrivilege(['OPEN_CLOSED_INCIDENT']),
      showEdit:
        state.viewMode === 'view' &&
        incidentStatus !== IncidentStatusEnum.closed,
      showView:
        state.viewMode === 'edit' &&
        incidentStatus !== IncidentStatusEnum.closed,
    });
  }, [
    props.loaded,
    props.draftIncident,
    state.viewMode,
    props.incident,
    accessState.editAvailable,
  ]);

  const incidentNumberPrefix = isSmaller ? '' : 'Incident ';
  const title = incident
    ? `${incidentNumberPrefix}#${incident.number}`
    : undefined;
  const historyButtonTitle =
    state.viewMode === 'history' ? 'Incident' : 'History';

  const handleCloseIncident = () => {
    props.closeIncident(incident.id).then(() => {
      props.onRefreshParentItems(['draftIncidents', 'incidents']);
      handleClose();
    });
  };

  const handleLockIncident = () => {
    if (incident.locked) {
      props
        .unlockIncident(incident.id)
        .then(() => {
          props.onRefreshParentItems(['incidents']);
          handleClose();
        })
        .catch(err => {});
    } else {
      props
        .lockIncident(incident.id)
        .then(() => {
          props.onRefreshParentItems(['incidents']);
          handleClose();
        })
        .catch(err => {});
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              {title}
            </Typography>
            {isSmaller ? (
              <>
                <IconButton
                  aria-label="more"
                  aria-haspopup="true"
                  onClick={handleMenuClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  keepMounted
                  anchorEl={menuAnchorEl}
                  open={Boolean(menuAnchorEl)}
                  onClose={handleMenuClose}
                >
                  {menuState.showPrint &&
                    canPrintIncident(incident.createUserId) && (
                      <MenuItem onClick={handleOpenReport(true)}>
                        Print
                      </MenuItem>
                    )}
                  {menuState.showChangeMode && menuState.showHistory && (
                    <MenuItem
                      onClick={changeViewMode(
                        state.viewMode === 'history' ? 'view' : 'history',
                      )}
                    >
                      {historyButtonTitle}
                    </MenuItem>
                  )}
                  {menuState.showLock && (
                    <MenuItem
                      onClick={() => {
                        handleMenuClose();
                        setLockIncidentDlgOpen(true);
                      }}
                    >
                      {incident.locked ? (
                        'Unlock'
                      ) : (
                        <div>
                          Lock
                          <HelpButton
                            dark={false}
                            message="Lock the incident to restrict User access."
                          />
                        </div>
                      )}
                    </MenuItem>
                  )}
                  {menuState.showReopen && (
                    <MenuItem
                      onClick={() => {
                        handleMenuClose();
                        props.openClosedIncident(incident.id);
                      }}
                    >
                      Reopen
                    </MenuItem>
                  )}
                  {menuState.showEdit && (
                    <MenuItem onClick={changeViewMode('edit')}>Edit</MenuItem>
                  )}
                  {menuState.showView && (
                    <MenuItem onClick={changeViewMode('view')}>View</MenuItem>
                  )}
                </Menu>
              </>
            ) : (
              <>
                {menuState.showPrint && (
                  <Button
                    color="inherit"
                    onClick={handleOpenReport(true)}
                    disabled={!canPrintIncident(incident.createUserId)}
                  >
                    Print
                  </Button>
                )}
                {menuState.showChangeMode && (
                  <Button
                    color="inherit"
                    onClick={changeViewMode(
                      state.viewMode === 'history' ? 'view' : 'history',
                    )}
                  >
                    {historyButtonTitle}
                  </Button>
                )}
                {menuState.showLock && (
                  <Button
                    color="inherit"
                    onClick={() => {
                      handleMenuClose();
                      setLockIncidentDlgOpen(true);
                    }}
                    disabled={!auth.hasPrivilege(['INCIDENT_LOCK'])}
                  >
                    {incident.locked ? (
                      'Unlock'
                    ) : (
                      <div className={classes.labelWithHelpIcon}>
                        Lock
                        <HelpButton
                          dark={false}
                          message="Lock the incident to restrict User access."
                        />
                      </div>
                    )}
                  </Button>
                )}

                {menuState.showReopen && (
                  <Button
                    color="inherit"
                    onClick={() => {
                      props.openClosedIncident(incident.id);
                    }}
                    disabled={!menuState.accessReopen}
                  >
                    Reopen
                  </Button>
                )}

                {menuState.showEdit && (
                  <Button
                    color="inherit"
                    onClick={changeViewMode('edit')}
                    disabled={!accessState.editAvailable}
                  >
                    Edit
                  </Button>
                )}
                {menuState.showView && (
                  <Button color="inherit" onClick={changeViewMode('view')}>
                    View
                  </Button>
                )}
              </>
            )}

            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className={classes.contentWrapper}>
          {state.viewMode === 'view' && (
            <div>
              {props.draftIncident ? (
                <>
                  <div className={classes.section}>
                    <IncidentViewDetails incident={readableIncident} expanded />
                  </div>
                  <div className={classes.section}>
                    <IncidentViewPersons
                      persons={readableIncident.persons}
                      incidentStatus={readableIncident.status}
                    />
                  </div>
                  <div className={classes.section}>
                    <IncidentViewCompanies
                      companies={readableIncident.companies}
                    />
                  </div>
                  <div className={classes.section}>
                    <IncidentViewVehicles
                      vehicles={readableIncident.vehicles}
                      attachments={readableIncident.attachments}
                    />
                  </div>
                  <div className={classes.section}>
                    <IncidentViewItems
                      items={readableIncident.items}
                      attachments={readableIncident.attachments}
                    />
                  </div>
                  <div className={classes.section}>
                    <IncidentViewAttachments
                      attachments={readableIncident.attachments}
                    />
                  </div>
                  <div className={classes.section}>
                    <IncidentViewCorrectiveActions
                      correctiveActions={readableIncident.correctiveActions}
                    />
                  </div>
                  {readableIncident.close && (
                    <div className={classes.section}>
                      <IncidentViewClose close={readableIncident.close} />
                    </div>
                  )}
                </>
              ) : (
                <LoadingProgress />
              )}
            </div>
          )}
          {state.viewMode === 'history' && (
            <div className={classes.historyTable}>
              <IncidentViewHistory incidentId={props.draftIncident.id} />
            </div>
          )}
          {state.viewMode === 'edit' && (
            <div className={classes.section}>
              {state.draftIncident ? (
                <IncidentFormWrapper onSubmit={handleSubmit} />
              ) : (
                <LoadingProgress />
              )}
            </div>
          )}
        </div>
      </Dialog>

      <Dialog
        maxWidth="lg"
        open={reportState.opened}
        onClose={handleOpenReport(false)}
        className={classes.reportDialog}
      >
        <ReportViewer
          reportName="incidentView"
          reportId={reportState.id}
          reportTitle="Incident"
          logoUrl={props.companyLogo}
        />
      </Dialog>
      <AlertDialog
        title="Close Incident"
        message={
          <div>
            Are you sure you want to CLOSE this INCIDENT?{' '}
            <b>CLOSED INCIDENTS CANNOT BE EDITED.</b>
          </div>
        }
        open={closeIncidentDlgOpen}
        onClose={() => {
          setCloseIncidentDlgOpen(false);
        }}
        onCancel={() => {
          setCloseIncidentDlgOpen(false);
        }}
        onOk={handleCloseIncident}
      />
      <IncidentCloseDialog
        open={closeIncidentDialogOpen}
        onClose={() => setCloseIncidentDialogOpen(false)}
      />
      <AlertDialog
        title="Lock/Unlock Incident"
        message={
          incident.locked ? (
            <div>
              Are you sure you want to unlock this incident? If an incident is
              unlocked, a user with proper privilege’s will be able to search or
              view the incident.
            </div>
          ) : (
            <div>
              Are you sure you want to lock this incident? If an incident is
              locked, a user cannot search or view the locked incident without
              the <b>INCIDENT LOCK</b> privilege enabled.
            </div>
          )
        }
        open={lockIncidentDlgOpen}
        onClose={() => {
          setLockIncidentDlgOpen(false);
        }}
        onCancel={() => {
          setLockIncidentDlgOpen(false);
        }}
        onOk={handleLockIncident}
      />
    </React.Fragment>
  );
};

IncidentViewDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  initialMode: PropTypes.oneOf(['view', 'history', 'edit']),
  onClose: PropTypes.func.isRequired,
  onRefreshParentItems: PropTypes.func,
  incident: IncidentDef,
  draftIncident: DraftIncidentDef,
  loaded: PropTypes.bool,
  loadDraftIncident: PropTypes.func,
  lookupDataSet: LookupDataSetDef.isRequired,
  openClosedIncident: PropTypes.func,
  closeIncident: PropTypes.func,
  unlockIncident: PropTypes.func,
  lockIncident: PropTypes.func,
  companyLogo: PropTypes.string.isRequired,
  getCompanyLogo: PropTypes.func.isRequired,
};

IncidentViewDialog.defaultProps = {
  onClose: () => {},
  onRefreshParentItems: f => f,
  openClosedIncident: f => f,
};

const mapStateToProps = ({ incidentView, common, reports }) => ({
  draftIncident: incidentView.draftIncident,
  loaded: incidentView.loaded,
  companyLogo: reports.companyLogo,
  companySettings: common.companySettings,
});

const mapDispatchToProps = {
  setOpenDeleteDraft,
  loadDraftIncident,
  closeIncident,
  unlockIncident,
  lockIncident,
  getCompanyLogo,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentViewDialog);
