import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import LayoutBody from '../components/LayoutBody';

const fbIcon = require('../images/appFooterFacebook.png');
const twitterIcon = require('../images/appFooterTwitter.png');

const styles = theme => ({
  root: {
    display: 'flex',
    // backgroundColor: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.light,
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
    },
  },
  layoutBody: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: 'flex',
  },
  icon: {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.warning.main,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  list: {
    margin: 0,
    listStyle: 'none',
    paddingLeft: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
});

function AppFooter(props) {
  const { classes } = props;

  return (
    <div />
    // <Typography component="footer" className={classes.root}>
    //   <Grid container spacing={2}>
    //     <Grid item xs={12} sm={5} md={3}>
    //       <Grid
    //         container
    //         direction="column"
    //         justify="flex-start"
    //         className={classes.iconsWrapper}
    //         spacing={2}
    //       >
    //         <Grid item className={classes.icons}>
    //           <a href="https://material-ui.com/" className={classes.icon}>
    //             <img src={fbIcon} alt="Facebook" />
    //           </a>
    //           <a href="https://twitter.com/MaterialUI" className={classes.icon}>
    //             <img src={twitterIcon} alt="Twitter" />
    //           </a>
    //         </Grid>
    //         <Grid item>
    //           <Typography variant="caption" marked="left" gutterBottom>
    //             © 2019 Incident Xpress Inc.
    //           </Typography>
    //         </Grid>
    //       </Grid>
    //     </Grid>
    //     <Grid item xs={6} sm={3} md={3}>
    //       <Typography variant="h6" marked="left" gutterBottom>
    //         Legal
    //       </Typography>
    //       <ul className={classes.list}>
    //         <li className={classes.listItem}>
    //           <Link href="/premium-themes/onepirate/terms">Terms</Link>
    //         </li>
    //         <li className={classes.listItem}>
    //           <Link href="/premium-themes/onepirate/privacy">Privacy</Link>
    //         </li>
    //       </ul>
    //     </Grid>
    //     <Grid item xs={6} sm={4} md={3}>
    //       <Typography variant="h6" marked="left" gutterBottom>
    //         About us
    //       </Typography>
    //       <ul className={classes.list}>
    //         <li className={classes.listItem}>
    //           <Link href="/premium-themes/onepirate/terms">Contact us</Link>
    //         </li>
    //         <li className={classes.listItem}>
    //           <Link href="/premium-themes/onepirate/privacy">Company</Link>
    //         </li>
    //       </ul>
    //     </Grid>
    //   </Grid>
    // </Typography>
  );
}

AppFooter.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppFooter);
