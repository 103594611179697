import React from 'react';
import PropTypes from 'prop-types';
import { convertFromRaw, EditorState } from 'draft-js';
import { createEditorStateWithText } from 'draft-js-plugins-editor';
import _ from 'lodash';
import { isNullOrUndefined } from 'util';
import moment from 'moment';
import LockIcon from '@material-ui/icons/Lock';
import { dateFormat } from './dateUtil';
import { buildLookupPath } from './lookupTreeUtil';
import {
  LookupTypeKey,
  PersonType,
  DefaultDateFormat,
  MeasurementType,
} from '../constants';
import { translateConfig } from './simpleTranslateUtil';
import {
  getPersonName,
  getCompanyName,
  getVehicleName,
  getIncidentItemName,
} from './nameUtil';
import Auth from '../auth/Auth';
import { convertNumberToCurrency } from './currencyUtil';
import { getAzureFileUrl } from '../utils/fileUtil';

export const incidentConvertType = {
  view: 'view',
  diff: 'diff',
  report: 'report',
};

export const getHeightMeasurementLabel = () => {
  if (Auth.currentUserValue.measurementType === MeasurementType.us) {
    return 'feet';
  }
  return 'cm';
};

export const getHeightPlaceholder = () => {
  if (Auth.currentUserValue.measurementType === MeasurementType.us) {
    return 'e.g. 5 feet 7 inches';
  }
  return 'e.g. 170';
};

export const getWeightMeasurementLabel = () => {
  if (Auth.currentUserValue.measurementType === MeasurementType.us) {
    return 'lb';
  }
  return 'kg';
};

export const getEditorContent = (
  data,
  convertType = incidentConvertType.diff,
) => {
  if (convertType === incidentConvertType.view) return data; // bypass for editor view

  let editorState;
  try {
    editorState = data
      ? EditorState.createWithContent(convertFromRaw(data))
      : createEditorStateWithText('');
  } catch (err) {
    editorState = createEditorStateWithText('');
  }
  const contentState = editorState.getCurrentContent();
  return contentState.getPlainText(
    convertType === incidentConvertType.diff ? '\u21b5' : '\n',
  );
};

const flattenAddress = data => {
  const {
    address,
    cityName,
    countryName,
    postalCode,
    stateName,
    street,
    unit,
  } = data || {};
  return [unit, address, street, cityName, stateName, countryName, postalCode]
    .filter(x => !!x)
    .join(', ');
};

const convertLocation = (location, locationLookup) => {
  if (!location) return {};
  const { lastLocationId } = location || {};
  const locationText = lastLocationId
    ? buildLookupPath(locationLookup, lastLocationId)
    : undefined;

  return {
    address: flattenAddress(location),
    location: locationText,
  };
};

const flattenPersonStatus = (
  statusInjury,
  statusFirstAid,
  statusHospitalized,
  statusRefusedHelp,
) => {
  const checked = [];
  statusInjury && checked.push('Injury');
  statusFirstAid && checked.push('FirstAid');
  statusHospitalized && checked.push('Hospitalized');
  statusRefusedHelp && checked.push('RefusedHelp');
  return checked.join(', ');
};

const flattenPersonRelation = (
  relationReporter,
  relationVictim,
  relationPersonOfInterest,
  relationSuspect,
  relationWitness,
  relationSubject,
) => {
  const checked = [];
  relationReporter && checked.push('Reporter');
  relationVictim && checked.push('Victim');
  relationPersonOfInterest && checked.push('Person of Interest');
  relationSuspect && checked.push('Suspect');
  relationWitness && checked.push('Witness');
  relationSubject && checked.push('Subject');
  return checked.join(', ');
};

const objectToArray = data => {
  if (!data) return [];
  return Object.keys(data).reduce((acc, name) => {
    if (data[name]) acc.push({ name, value: data[name] });
    return acc;
  }, []);
};

export const getSingleLookupName = (id, lookups) => {
  if (!id || !lookups || lookups.length === 0) return undefined;
  const found = lookups.find(x => x.id === id);
  return found ? found.name : undefined;
};

const FlatItem = props => {
  const labelStyle = {
    fontSize: '0.9em',
    color: '#aaa',
    marginRight: '6px',
  };
  const valueStyle = {
    marginRight: '17px',
  };
  let labelName = translateConfig.statics[props.name];
  if (labelName === 'Height') {
    labelName = `${labelName} (${getHeightMeasurementLabel()})`;
  } else if (labelName === 'Weight') {
    labelName = `${labelName} (${getWeightMeasurementLabel()})`;
  }
  return (
    <>
      <span style={labelStyle}>{labelName} |</span>
      <span style={valueStyle}>{props.value}</span>
    </>
  );
};
FlatItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
};
FlatItem.defaultProps = {
  value: undefined,
};

const FlatList = props => {
  const itemList = objectToArray(props.value);
  return (
    <>
      {itemList.map(item => (
        <FlatItem key={item.name} name={item.name} value={item.value} />
      ))}
    </>
  );
};
FlatList.propTypes = {
  value: PropTypes.object,
};

export const flattenCharacteristicsComponent = data => {
  const {
    charHeight,
    charWeight,
    charHairColor,
    charHairLength,
    charHairType,
    charFacialHair,
    charEyeGlasses,
    charOther,
  } = data;
  return (
    <FlatList
      value={{
        charHeight,
        charWeight,
        charHairColor,
        charHairLength,
        charHairType,
        charFacialHair,
        charEyeGlasses,
        charOther,
      }}
    />
  );
};

const flattenCharacteristics = ({
  charHeight,
  charWeight,
  charHairColor,
  charHairLength,
  charHairType,
  charFacialHair,
  charEyeGlasses,
  charOther,
}) => {
  const items = [];
  if (charHeight) {
    items.push(`Height: ${charHeight}`);
  }
  if (charWeight) {
    items.push(`Weight: ${charWeight}`);
  }
  if (charHairColor) {
    items.push(`Hair color: ${charHairColor}`);
  }
  if (charHairType) {
    items.push(`Hair type: ${charHairType}`);
  }
  if (charFacialHair) {
    items.push(`Facial hair: ${charFacialHair}`);
  }
  if (charEyeGlasses) {
    items.push(`Eye glasses: ${charEyeGlasses}`);
  }
  if (charOther) {
    items.push(`Other: ${charOther}`);
  }

  return items.join(', ');
};

const convertPerson = (
  person,
  convertType,
  departmentLookup,
  personTypeLookup,
  genderTypeLookup,
  accountDateFormat,
) => {
  if (!person) return {};
  const {
    isEmployee,
    address,
    cityName,
    countryName,
    postalCode,
    stateName,
    street,
    unit,
    statusInjury,
    statusFirstAid,
    statusHospitalized,
    statusRefusedHelp,
    charHeight,
    charWeight,
    charHairColor,
    charHairLength,
    charHairType,
    charFacialHair,
    charEyeGlasses,
    charOther,

    relationReporter,
    relationVictim,
    relationPersonOfInterest,
    relationSuspect,
    relationWitness,
    relationSubject,

    firstName,
    lastName,
    middleName,
    alias,
    phone,
    mobilePhone,
    email,
    comments,
    dateOfBirth,
    id,
    creationDate,
    lastUpdateDate,
    lookupPersonTypeId,
    lookupDepartmentId,
    lookupGenderTypeId,
    position,
    incidentId,
    // ...others
    personPicture,
    personPictureSasKey,
  } = person;

  const photo = personPicture
    ? getAzureFileUrl(
        Auth.currentUser.accountId,
        personPicture,
        false,
        personPictureSasKey,
      )
    : undefined;

  return {
    // ...others,
    id: convertType === incidentConvertType.diff ? undefined : id,
    isEmployee,
    displayName:
      convertType === incidentConvertType.diff
        ? null
        : getPersonName({ firstName, middleName, lastName }),
    firstName,
    lastName,
    middleName,
    alias,
    status: flattenPersonStatus(
      statusInjury,
      statusFirstAid,
      statusHospitalized,
      statusRefusedHelp,
    ),
    incidentRelation: flattenPersonRelation(
      relationReporter,
      relationVictim,
      relationPersonOfInterest,
      relationSuspect,
      relationWitness,
      relationSubject,
    ),
    personType: getSingleLookupName(lookupPersonTypeId, personTypeLookup),
    department: getSingleLookupName(lookupDepartmentId, departmentLookup),
    genderType: getSingleLookupName(lookupGenderTypeId, genderTypeLookup),
    position,
    address: flattenAddress({
      address,
      cityName,
      countryName,
      postalCode,
      stateName,
      street,
      unit,
    }),
    phone,
    mobilePhone,
    email,
    dateOfBirth: dateOfBirth
      ? moment(dateOfBirth).format(
          DefaultDateFormat(accountDateFormat).dateMoment,
        )
      : undefined,
    characteristics:
      convertType !== incidentConvertType.view
        ? flattenCharacteristics(person)
        : flattenCharacteristicsComponent(person),
    comments: getEditorContent(comments, convertType),
    photo: convertType === incidentConvertType.diff ? undefined : photo,
  };
};

const convertCompany = (company, convertType) => {
  if (!company) return {};
  const {
    address,
    cityName,
    countryName,
    postalCode,
    stateName,
    street,
    unit,
    name,
    email,
    phone,
    contact,
    comments,
    id,
    incidentId,
    cityId,
    stateCode,
    countryCode,
    creationDate,
    lastUpdateDate,
    relationReporter,
    relationVictim,
    relationPersonOfInterest,
    relationSuspect,
    relationWitness,
    relationSubject,
    isOwner,
    // ...others
  } = company;
  return {
    // ...others,
    id: convertType === incidentConvertType.diff ? undefined : id,
    displayName:
      convertType === incidentConvertType.diff
        ? null
        : getCompanyName({ name }),
    name,
    address: flattenAddress({
      address,
      cityName,
      countryName,
      postalCode,
      stateName,
      street,
      unit,
    }),
    phone,
    email,
    contact,
    comments: getEditorContent(comments, convertType),
    incidentRelation: flattenPersonRelation(
      relationReporter,
      relationVictim,
      relationPersonOfInterest,
      relationSuspect,
      relationWitness,
      relationSubject,
    ),
    isOwner,
  };
};

const getPersonById = (id, ownerList) => {
  if (!id || !ownerList || ownerList.length === 0) return undefined;
  const found = ownerList.find(x => x.id === id);
  return found ? found.name : undefined;
};

const convertVehicle = (
  vehicle,
  ownerList,
  involvementLookup,
  convertType,
  attachments,
) => {
  if (!vehicle) return {};
  const {
    ownerPersonId,
    licensePlate,
    stateProvince,
    maker,
    model,
    color,
    vin,
    involvementId,
    comments,
    id,
    incidentId,
    creationDate,
    lastUpdateDate,
    updateUserId,
    ...others
  } = vehicle;
  return {
    ...others,
    id: convertType === incidentConvertType.diff ? null : id,
    displayName:
      convertType === incidentConvertType.diff ? null : getVehicleName(vehicle),
    ownerName: getPersonById(ownerPersonId, ownerList),
    licensePlate,
    stateProvince,
    maker,
    model,
    color,
    vin,
    involvement: getSingleLookupName(involvementId, involvementLookup),
    comments: getEditorContent(comments, convertType),
    attachments: attachments
      .filter(x => x.incidentVehicleId)
      .map(x => `${x.name} (${x.attachmentType})`)
      .join(', '),
  };
};

const convertItem = (item, ownerList, convertType, attachments) => {
  if (!item) return {};
  const {
    ownerPersonId,
    status,
    name,
    brand,
    model,
    color,
    serialNumber,
    value,
    comments,

    id,
    incidentId,
    creationDate,
    lastUpdateDate,
    updateUserId,
    ...others
  } = item;
  return {
    ...others,
    id: convertType === incidentConvertType.diff ? null : id,
    displayName:
      convertType === incidentConvertType.diff
        ? null
        : getIncidentItemName(item),
    ownerName: getPersonById(ownerPersonId, ownerList),
    status,
    name,
    brand,
    model,
    color,
    serialNumber,
    value: convertNumberToCurrency(value),
    comments: getEditorContent(comments, convertType),
    attachments: attachments
      .filter(x => x.incidentItemId)
      .map(x => `${x.name} (${x.attachmentType})`)
      .join(', '),
  };
};

const convertAttachment = (attachment, convertType) => {
  if (!attachment) return {};
  const {
    name,
    comments,
    attachmentType,
    thumbnailSasKey,
    sasKey,
    fileName,
    fileType,
  } = attachment;

  if (convertType === incidentConvertType.diff) {
    return {
      name,
      comments,
      attachmentType,
      fileType,
    };
  }

  const photo = thumbnailSasKey
    ? getAzureFileUrl(
        Auth.currentUser && Auth.currentUser.accountId,
        fileName,
        true,
        sasKey,
        thumbnailSasKey,
      )
    : undefined;

  return {
    ...attachment,
    photo,
  };
};

const convertCorrectiveAction = (
  correctiveAction,
  personList,
  correctiveActionTypeLookup,
  departmentLookup,
  agencyLookup,
  convertType,
) => {
  if (!correctiveAction || _.isEmpty(correctiveAction))
    return convertType === incidentConvertType.report ? { isEmpty: true } : {};

  const {
    id,
    typeId,
    displayType,
    referredType,
    details,
    involvedPersonList,
    referredInternalDepartmentId,
    referredExternalAgencyId,
    referredContactFirstName,
    referredContactMiddleName,
    referredContactLastName,
    referredContactAlias,
    referredContactPosition,
    referredContactMobilePhone,
    referredContactPhone,
    referredContactEmail,
  } = correctiveAction;

  return {
    id,
    type: getSingleLookupName(typeId, correctiveActionTypeLookup),
    displayType: displayType || '',
    referredType,
    referredTypeLabel: referredType === 'EXTERNAL' ? 'Agency' : 'Department',
    referredTypeName:
      referredType === 'EXTERNAL'
        ? getSingleLookupName(referredExternalAgencyId, agencyLookup)
        : getSingleLookupName(referredInternalDepartmentId, departmentLookup),
    details: getEditorContent(details, convertType),
    referredInternalDepartment: getSingleLookupName(
      referredInternalDepartmentId,
      departmentLookup,
    ),
    referredExternalAgency: getSingleLookupName(
      referredExternalAgencyId,
      agencyLookup,
    ),
    referredContactFirstName,
    referredContactMiddleName,
    referredContactLastName,
    referredContactAlias,
    referredContactPosition,
    referredContactMobilePhone,
    referredContactPhone,
    referredContactEmail,
    involvedPersons: involvedPersonList
      .map(x => getPersonById(x, personList))
      .join(', '),
  };
};

const makePropertyOwnerMap = (persons, companies) => {
  const extractor = personType => ({
    id,
    name,
    firstName,
    middleName,
    lastName,
  }) => ({
    id,
    name:
      personType === PersonType.person
        ? getPersonName({ firstName, middleName, lastName })
        : getCompanyName({ name }),
    personType,
  });
  return persons
    .map(extractor(PersonType.person))
    .concat(companies.map(extractor(PersonType.company)));
};

const convertIncidentClose = (incident, convertType) => {
  if (!incident.close) {
    return undefined;
  }

  const {
    closedBy,
    closeComments,
    closeUserFirstName,
    closeUserLastName,
    closeFirstName,
    closeMiddleName,
    closeLastName,
    closeDepartment,
    closePosition,
    closeAlias,
    closePhone,
    closeMobilePhone,
    closeEmail,
  } = incident.close;

  return {
    closedBy,
    closeUserName: getPersonName({
      firstName: closeUserFirstName,
      lastName: closeUserLastName,
    }),
    closeComments: getEditorContent(closeComments, convertType),
    closeFirstName,
    closeMiddleName,
    closeLastName,
    closeDepartment,
    closePosition,
    closeAlias,
    closePhone,
    closeMobilePhone,
    closeEmail,
  };
};

export const toReadableIncident = (
  incident,
  lookupDataSet,
  accountDateFormat,
  convertType = incidentConvertType.view,
) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (!incident) return {};
  const {
    number,
    status,
    locked,
    creationDate,
    lastUpdateDate,
    assignedUserName,
    createUserName,
    summary,
    causeComments,
    primaryCauseId,
    severityId,
    occurredDate,
    occurredDateUncertain,
    reportedDate,
    lastClassId,
    location,
    persons = [],
    companies = [],
    vehicles = [],
    items = [],
    attachments = [],
    correctiveActions = [],
  } = incident;
  const ownerList = makePropertyOwnerMap(persons, companies);
  return {
    number,
    locked: !!locked,
    status:
      !isNullOrUndefined(status) &&
      translateConfig.enums.incidentStatus[status],
    occurredDate: dateFormat(
      occurredDate,
      DefaultDateFormat(accountDateFormat).datetimeMoment,
      occurredDateUncertain,
    ),
    reportedDate: dateFormat(
      reportedDate,
      DefaultDateFormat(accountDateFormat).datetimeMoment,
    ),
    creationDate: dateFormat(
      creationDate,
      DefaultDateFormat(accountDateFormat).datetimeMoment,
    ),
    assignedUserName,
    createUserName: createUserName || Auth.currentUserValue.name,
    summary: getEditorContent(summary, convertType),
    causeComments: getEditorContent(causeComments, convertType),
    whatHappened: buildLookupPath(
      lookupDataSet[LookupTypeKey.incidentClass],
      lastClassId,
    ),
    primaryCause: getSingleLookupName(
      primaryCauseId,
      lookupDataSet[LookupTypeKey.primaryCause],
    ),
    severity: getSingleLookupName(
      severityId,
      lookupDataSet[LookupTypeKey.severityType],
    ),
    ...convertLocation(location, lookupDataSet[LookupTypeKey.location]),
    persons: persons.map(x =>
      convertPerson(
        x,
        convertType,
        lookupDataSet[LookupTypeKey.department],
        lookupDataSet[LookupTypeKey.personType],
        lookupDataSet[LookupTypeKey.genderType],
        accountDateFormat,
      ),
    ),
    companies: companies.map(x => convertCompany(x, convertType)),
    vehicles: vehicles.map(vehicle =>
      convertVehicle(
        vehicle,
        ownerList,
        lookupDataSet[LookupTypeKey.vehicleInvolvement],
        convertType,
        attachments,
      ),
    ),
    items: items.map(item =>
      convertItem(item, ownerList, convertType, attachments),
    ),
    attachments: attachments.map(x => convertAttachment(x, convertType)),
    correctiveActions: _.sortBy(
      correctiveActions.map(correctiveAction =>
        convertCorrectiveAction(
          correctiveAction,
          ownerList,
          lookupDataSet[LookupTypeKey.correctiveActionType],
          lookupDataSet[LookupTypeKey.department],
          lookupDataSet[LookupTypeKey.referredAgency],
          convertType,
        ),
      ),
      ['id'],
    ),
    close: convertIncidentClose(incident, convertType),
    timezone,
  };
};

export const getStatusText = incident => {
  if (incident.locked) {
    return <LockIcon />;
  }
  return translateConfig.enums.incidentStatus[incident.status];
};

export default {
  toReadableIncident,
};
