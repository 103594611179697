import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import _ from 'lodash';
import { enqueueError } from '../../modules/global';
import incidentApi from '../../apis/incident.api';
import draftIncidentApi from '../../apis/draftIncident.api';
import searchApi from '../../apis/search.api';
import analyzeApi from '../../apis/analyze.api';
import { IncidentStatusEnum } from '../../constants';

const RESET = 'dashboard/RESET';
const DRAFTS_QUERIED = 'dashboard/DRAFTS_QUERIED';
const MY_INCIDENTS_QUERIED = 'dashboard/MY_INCIDENTS_QUERIED';
const DRAFT_INCIDENT_DELETED = 'dashboard/DRAFT_INCIDENT_DELETED';
const DRAFT_INCIDENT_UPDATED = 'dashboard/DRAFT_INCIDENT_UPDATED';
const OPEN_DRAFT_INCIDENT_DELETE_DIALOG =
  'dashboard/OPEN_DRAFT_INCIDENT_DELETE_DIALOG';
const CUSTOM_SEARCH_LOADED = 'dashboard/CUSTOM_SEARCH_LOADED';
const CUSTOM_SEARCH_EXECUTED = 'dashboard/CUSTOM_SEARCH_EXECUTED';

const OPEN_CUSTOM_SEARCH_DELETE_DIALOG =
  'dashboard/OPEN_CUSTOM_SEARCH_DELETE_DIALOG';
const CUSTOM_SEARCH_DELETED = 'dashboard/CUSTOM_SEARCH_DELETED';

const CUSTOM_ANALYSIS_LOADED = 'dashboard/CUSTOM_ANALYSIS_LOADED';
const CUSTOM_ANALYSIS_EXECUTED = 'dashboard/CUSTOM_ANALYSIS_EXECUTED';
const OPEN_CUSTOM_ANALYSIS_DELETE_DIALOG =
  'dashboard/OPEN_CUSTOM_ANALYSIS_DELETE_DIALOG';
const CUSTOM_ANALYSIS_DELETED = 'dashboard/CUSTOM_ANALYSIS_DELETED';

const OPEN_CLOSED_INCIDENT_COMPLETED =
  'dashboard/OPEN_CLOSED_INCIDENT_COMPLETED';

const INCIDENT_DELETED = 'dashboard/INCIDENT_DELETED';

const resetAction = createAction(RESET);
const draftQueriedAction = createAction(DRAFTS_QUERIED);
const myIncidentsQueriedAction = createAction(MY_INCIDENTS_QUERIED);
const draftIncidentDeletedAction = createAction(DRAFT_INCIDENT_DELETED);
export const draftIncidentUpdateAction = createAction(DRAFT_INCIDENT_UPDATED);
const openDraftIncidentDeletedDialogAction = createAction(
  OPEN_DRAFT_INCIDENT_DELETE_DIALOG,
);
const customSearchLoadedAction = createAction(CUSTOM_SEARCH_LOADED);
const customSearchExecutedAction = createAction(CUSTOM_SEARCH_EXECUTED);
const openCustomSearchDeleteAction = createAction(
  OPEN_CUSTOM_SEARCH_DELETE_DIALOG,
);
const customSearchDeletedAction = createAction(CUSTOM_SEARCH_DELETED);

const customAnalysisLoadedAction = createAction(CUSTOM_ANALYSIS_LOADED);
const customAnalysisExecutedAction = createAction(CUSTOM_ANALYSIS_EXECUTED);
const openCustomAnalysisDeletedAction = createAction(
  OPEN_CUSTOM_ANALYSIS_DELETE_DIALOG,
);
const customAnalysisDeletedAction = createAction(CUSTOM_ANALYSIS_DELETED);

const openClosedIncidentCompletedAction = createAction(
  OPEN_CLOSED_INCIDENT_COMPLETED,
);

const incidentDeletedAction = createAction(INCIDENT_DELETED);

const initState = {
  loaded: false,
  myIncidents: [],
  draftIncidents: [],
  customSearches: [],
  customResultSet: {},
  deleteCustomSearchDialogOpened: false,
  deleteDraftIncidentDialogOpened: false,
  customAnalysis: [],
  analysisResultSet: {},
  deleteCustomAnalysisDialogOpened: false,
};

export default handleActions(
  {
    [RESET]: (state, action) =>
      produce(state, draft => {
        draft.loaded = false;
      }),
    [DRAFTS_QUERIED]: (state, action) =>
      produce(state, draft => {
        const list = action.payload;
        draft.draftIncidents = list;
        draft.draftSelectorOpened = list && list.length > 0;
        draft.loaded = true;
      }),
    [MY_INCIDENTS_QUERIED]: (state, action) =>
      produce(state, draft => {
        draft.myIncidents = action.payload;
        draft.loaded = true;
      }),
    [CUSTOM_SEARCH_LOADED]: (state, action) =>
      produce(state, draft => {
        draft.customSearches = action.payload;
        draft.loaded = true;
      }),
    [CUSTOM_SEARCH_EXECUTED]: (state, action) =>
      produce(state, draft => {
        const { id, data } = action.payload;
        draft.customResultSet[id] = data;
      }),
    [DRAFT_INCIDENT_DELETED]: (state, action) =>
      produce(state, draft => {
        const id = action.payload;
        draft.draftIncidents = draft.draftIncidents.filter(x => x.id !== id);
        draft.deleteDraftIncidentDialogOpened = false;
      }),
    [DRAFT_INCIDENT_UPDATED]: (state, action) =>
      produce(state, draft => {
        const { id, data } = action.payload;
        if (id) {
          const found = _.find(draft.draftIncidents, { id });
          if (found) {
            Object.assign(found, data);
          }
        }
      }),
    [OPEN_DRAFT_INCIDENT_DELETE_DIALOG]: (state, action) =>
      produce(state, draft => {
        draft.deleteDraftIncidentDialogOpened = action.payload;
      }),
    [OPEN_CUSTOM_SEARCH_DELETE_DIALOG]: (state, action) =>
      produce(state, draft => {
        draft.deleteCustomSearchDialogOpened = action.payload;
      }),
    [CUSTOM_SEARCH_DELETED]: (state, action) =>
      produce(state, draft => {
        const id = action.payload;
        draft.customResultSet[id] = undefined;
        draft.customSearches = state.customSearches.filter(x => x.id !== id);
        draft.deleteCustomSearchDialogOpened = false;
      }),
    [CUSTOM_ANALYSIS_LOADED]: (state, action) =>
      produce(state, draft => {
        draft.customAnalysis = action.payload;
        draft.loaded = true;
      }),
    [CUSTOM_ANALYSIS_EXECUTED]: (state, action) =>
      produce(state, draft => {
        const { id, data } = action.payload;
        draft.analysisResultSet[id] = data;
      }),
    [OPEN_CUSTOM_ANALYSIS_DELETE_DIALOG]: (state, action) =>
      produce(state, draft => {
        draft.deleteCustomAnalysisDialogOpened = action.payload;
      }),
    [CUSTOM_ANALYSIS_DELETED]: (state, action) =>
      produce(state, draft => {
        const id = action.payload;
        draft.analysisResultSet[id] = undefined;
        draft.customAnalysis = state.customAnalysis.filter(x => x.id !== id);
        draft.deleteCustomAnalysisDialogOpened = false;
      }),
    [OPEN_CLOSED_INCIDENT_COMPLETED]: (state, action) =>
      produce(state, draft => {
        const id = action.payload;
        const incident = _.find(draft.myIncidents, { id });
        if (incident) {
          incident.status = IncidentStatusEnum.open;
          incident.close = undefined;
        }
      }),
    [INCIDENT_DELETED]: (state, action) =>
      produce(state, draft => {
        const id = action.payload;
        draft.draftIncidents = draft.draftIncidents.filter(x => x.id !== id);
        draft.myIncidents = draft.myIncidents.filter(x => x.id !== id);
      }),
  },
  initState,
);

export const queryDraftIncidents = status => async dispatch => {
  dispatch(resetAction());
  try {
    const { data } = await draftIncidentApi.query(status);
    dispatch(draftQueriedAction(data));
  } catch (err) {
    dispatch(enqueueError(err));
  }
};
export const queryMyIncidents = () => async dispatch => {
  dispatch(resetAction());
  try {
    const { data } = await incidentApi.queryMyIncidents();
    dispatch(myIncidentsQueriedAction(data));
  } catch (err) {
    dispatch(enqueueError(err));
  }
};
export const loadCustomSearches = () => async dispatch => {
  dispatch(resetAction());
  try {
    const { data } = await searchApi.queryCustomSearches();
    dispatch(customSearchLoadedAction(data));
  } catch (err) {
    dispatch(enqueueError(err));
  }
};
export const deleteDraftIncident = id => async dispatch => {
  dispatch(resetAction());
  try {
    await draftIncidentApi.remove(id);
    dispatch(draftIncidentDeletedAction(id));
  } catch (err) {
    dispatch(enqueueError(err));
  }
};
export const deleteIncident = id => async dispatch => {
  dispatch(resetAction());
  try {
    await incidentApi.deleteIncident(id);
    dispatch(incidentDeletedAction(id));
  } catch (err) {
    dispatch(enqueueError(err));
  }
};
export const executeCustomSearch = searchModel => async dispatch => {
  dispatch(resetAction());
  try {
    const { id, criteria, name } = searchModel;
    const { data } = await searchApi.search(criteria, name);
    dispatch(customSearchExecutedAction({ id, data }));
  } catch (err) {
    dispatch(enqueueError(err));
  }
};
export const deleteCustomSearch = id => async dispatch => {
  dispatch(resetAction());
  try {
    await searchApi.deleteCustom(id);
    dispatch(customSearchDeletedAction(id));
  } catch (err) {
    dispatch(enqueueError(err));
  }
};
export const setOpenCustomSearchDeleteDialog = open =>
  openCustomSearchDeleteAction(open);

export const refreshData = keys => async dispatch => {
  dispatch(queryDraftIncidents());
  dispatch(queryMyIncidents());
};

export const openDraftIncidentDeleteDialog = open => async dispatch => {
  dispatch(openDraftIncidentDeletedDialogAction(open));
};

export const loadCustomAnalysis = () => async dispatch => {
  dispatch(resetAction());
  try {
    const { data } = await analyzeApi.getCustoms();
    dispatch(customAnalysisLoadedAction(data));
  } catch (err) {
    dispatch(enqueueError(err));
  }
};
export const executeCustomAnalysis = analysisModel => async dispatch => {
  dispatch(resetAction());
  try {
    const { id, criteria, name } = analysisModel;
    const { data } = await analyzeApi.analyze(criteria, name);
    dispatch(customAnalysisExecutedAction({ id, data }));
  } catch (err) {
    dispatch(enqueueError(err));
  }
};
export const deleteCustomAnalysis = id => async dispatch => {
  dispatch(resetAction());
  try {
    await analyzeApi.deleteCustom(id);
    dispatch(customAnalysisDeletedAction(id));
  } catch (err) {
    dispatch(enqueueError(err));
  }
};
export const setOpenCustomAnalysisDeleteDialog = open =>
  openCustomAnalysisDeletedAction(open);

export const openClosedIncident = incidentId => async (dispatch, getState) => {
  const {
    dashboard: { myIncidents },
  } = getState();

  dispatch(resetAction());
  try {
    await incidentApi.openClosedIncident(incidentId);
    dispatch(openClosedIncidentCompletedAction(incidentId));
  } catch (err) {
    dispatch(enqueueError(err));
  }
};
