import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import ExpansionCard from '../../components/cards/ExpansionCard';
import {
  ReadableIncidentVehicleDef,
  IncidentAttachmentDef,
} from '../../components/propTypeDefs';
import RichReader from '../../components/forms/RichReader';
import { labelValueStyle } from '../../theme';
import IncidentAttachmentVehicle from '../newIncident/IncidentAttachmentVehicle';
import { AttachmentType } from '../../constants';
import Auth from '../../auth/Auth';
import { getAzureFileUrl, downloadFromUrl } from '../../utils/fileUtil';
import AttachedImageViewer from '../../components/dialogs/AttachedImageViewer';

const useStyles = makeStyles(theme => ({
  ...labelValueStyle,
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // flexWrap: 'wrap',
  },
  subRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    display: 'flex',
    alignItems: 'flex-start',
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
    // height: 40,
  },
  // label: {
  //   ...theme.typography.h5,
  //   fontSize: '1.1em',
  //   fontWeight: 600,
  //   color: theme.palette.grey[500],
  // },
  // value: {
  //   ...theme.typography.body,
  //   width: '100%',
  // },
  attachmentRoot: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  attachmentVehicle: {
    padding: 10,
  },
}));

const IncidentViewVehicles = props => {
  const classes = useStyles();
  const { expanded, vehicles, attachments } = props;
  const [state, setState] = React.useState({
    selectedAttachment: undefined,
    viewerOpen: false,
  });

  const renderVehicle = vehicle => {
    const handleDownload = attachment => {
      if (
        attachment.attachmentType === AttachmentType.image ||
        attachment.attachmentType === AttachmentType.movie ||
        attachment.attachmentType === AttachmentType.audio ||
        attachment.attachmentType === AttachmentType.txt ||
        attachment.attachmentType === AttachmentType.pdf
      ) {
        setState({
          ...state,
          viewerOpen: true,
          selectedAttachment: attachment,
        });
      } else {
        if (!Auth.currentUser) return;
        const url = getAzureFileUrl(
          Auth.currentUser.accountId,
          attachment.fileName,
          false,
          attachment.sasKey,
          attachment.thumbnailSasKey
        );
        downloadFromUrl(url, attachment.name);
      }
    };
    const handleViewerClose = () => {
      setState({ ...state, viewerOpen: false, selectedAttachment: undefined });
    };

    return (
      <ExpansionCard title={vehicle.displayName} theme="sub">
        <div className={classes.root}>
          <div className={classes.lineItem}>
            <FormControl className={classes.formControl}>
              <div className={classes.label}>Property of</div>
              <div className={classes.value}>{vehicle.ownerName}</div>
            </FormControl>
          </div>
          <div className={classes.lineItem}>
            <FormControl className={classes.formControl}>
              <div className={classes.label}>License plate #</div>
              <div className={classes.value}>{vehicle.licensePlate}</div>
            </FormControl>
            <FormControl className={classes.formControl}>
              <div className={classes.label}>State/Province</div>
              <div className={classes.value}>{vehicle.stateProvince}</div>
            </FormControl>
            <FormControl className={classes.formControl}>
              <div className={classes.label}>Maker</div>
              <div className={classes.value}>{vehicle.maker}</div>
            </FormControl>
            <FormControl className={classes.formControl}>
              <div className={classes.label}>Model</div>
              <div className={classes.value}>{vehicle.model}</div>
            </FormControl>
            <FormControl className={classes.formControl}>
              <div className={classes.label}>Color</div>
              <div className={classes.value}>{vehicle.color}</div>
            </FormControl>
            <FormControl className={classes.formControl}>
              <div className={classes.label}>VIN #</div>
              <div className={classes.value}>{vehicle.vin}</div>
            </FormControl>
            <FormControl className={classes.formControl}>
              <div className={classes.label}>Involvement</div>
              <div className={classes.value}>{vehicle.involvement}</div>
            </FormControl>
          </div>

          <div className={classes.lineItem}>
            <FormControl className={classes.formControlLong}>
              <div className={classes.label}>Comments</div>
              <div className={classes.value}>
                <RichReader content={vehicle.comments} />
              </div>
            </FormControl>
          </div>
          <div className={classes.lineItem}>
            <div className={classes.label}>Attachments</div>
            <div className={classes.attachmentRoot}>
              {attachments
                .filter(x => x.incidentVehicleId)
                .map(attachment => {
                  return (
                    <div
                      key={attachment.id}
                      className={classes.attachmentVehicle}
                    >
                      <IncidentAttachmentVehicle
                        editable={false}
                        attachment={attachment}
                        onDownload={handleDownload}
                      />
                    </div>
                  );
                })}
            </div>
            {state.selectedAttachment && (
              <AttachedImageViewer
                attachment={state.selectedAttachment}
                open={state.viewerOpen}
                onClose={handleViewerClose}
              />
            )}
          </div>
        </div>
      </ExpansionCard>
    );
  };

  return (
    <ExpansionCard title={`Vehicles (${vehicles.length})`} expanded={expanded}>
      <div className={classes.root}>
        {vehicles.map(vehicle => (
          <div key={vehicle.id} className={classes.subRoot}>
            {renderVehicle(vehicle)}
          </div>
        ))}
      </div>
    </ExpansionCard>
  );
};

IncidentViewVehicles.propTypes = {
  expanded: PropTypes.bool,
  vehicles: PropTypes.arrayOf(ReadableIncidentVehicleDef).isRequired,
  attachments: PropTypes.arrayOf(IncidentAttachmentDef).isRequired,
};

IncidentViewVehicles.defaultProps = {
  expanded: false,
  vehicles: [],
  attachments: [],
};

export default IncidentViewVehicles;
