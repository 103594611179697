import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  TextField,
  Radio,
  Checkbox,
  FormGroup,
  Typography,
  Button,
  Paper,
  Chip,
} from '@material-ui/core';
import ViewIcon from '@material-ui/icons/Pageview';
import ChartIcon from '@material-ui/icons/BarChart';
import moment from 'moment';
import _ from 'lodash';
import { chartTypes } from './ChartTypeSelector';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  paper: {
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  group: {
    margin: 0,
  },
  label: {
    marginLeft: theme.spacing(-1),
  },
  chip: {
    margin: theme.spacing(1),
    textTransform: 'capitalize',
  },
  searchCriteria: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
});

class ChartTypeCriteria extends Component {
  handleClick = () => {
    this.props.onClick();
  };

  render() {
    const { classes, chartType } = this.props;
    const type = _.find(chartTypes, { value: chartType });

    return (
      <React.Fragment>
        {chartType && (
          <div className={classes.searchCriteria} hidden={!chartType}>
            <Typography variant="h6">Chart type: </Typography>
            <Chip
              icon={<ChartIcon />}
              label={type && type.label}
              onClick={this.handleClick}
              className={classes.chip}
              color="secondary"
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

ChartTypeCriteria.propTypes = {
  chartType: PropTypes.string,
  onClick: PropTypes.func,
  classes: PropTypes.object,
};

ChartTypeCriteria.defaultProps = {
  chartType: undefined,
  onClick: () => {},
};

export default withStyles(styles)(ChartTypeCriteria);
