import Axios from 'axios';
import { hashMd5 } from './cryptoUtil';
import config from '../config';

export const getAzureFileUrl = (accountId, fileName, thumbnail, sasKey, thumbnailSasKey) => {
  if (thumbnail) {
    return `${config.url.fileStorageUrl}/${hashMd5(
      accountId,
    )}/thumbnail_${fileName}?${thumbnailSasKey}`;
  }

  return `${config.url.fileStorageUrl}/${hashMd5(accountId)}/${fileName}?${sasKey}`;
};

// @ref : https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
// @ref : https://github.com/eligrey/FileSaver.js/
export const downloadFromUrl = (url, fileName, mime, bom) => {
  // const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = url;
  tempLink.setAttribute('download', fileName);

  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);

  // const blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
  // const blob = new Blob(blobData, { type: mime || 'application/octet-stream' });
  // if (typeof window.navigator.msSaveBlob !== 'undefined') {
  //   window.navigator.msSaveBlob(blob, fileName);
  // } else {
  //   const blobURL = window.URL.createObjectURL(blob);
  //   const tempLink = document.createElement('a');
  //   tempLink.style.display = 'none';
  //   tempLink.href = blobURL;
  //   tempLink.setAttribute('download', fileName);

  //   if (typeof tempLink.download === 'undefined') {
  //     tempLink.setAttribute('target', '_blank');
  //   }

  //   document.body.appendChild(tempLink);
  //   tempLink.click();
  //   document.body.removeChild(tempLink);
  //   window.URL.revokeObjectURL(blobURL);
  // }
};

const getFileNameFromDispositionHeader = contentDisposition => {
  const fileName = contentDisposition
    .split(';')
    .filter(ele => {
      return ele.indexOf('filename') > -1;
    })
    .map(ele => {
      return ele.replace(/"/g, '').split('=')[1];
    });
  return fileName[0] ? fileName[0] : null;
};

export const downloadByBlobResponse = res => {
  // const blob = new Blob([res.data], { type: res.headers['content-type'] });
  const blob = res.data;
  const fileName = getFileNameFromDispositionHeader(
    res.headers['content-disposition'],
  );

  if (typeof window.navigator.msSaveOrOpenBlob !== 'undefined') {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', fileName);

    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }
};

export const getContentFromUrl = async (url, responseType = 'stream') => {
  return await Axios.get(url, {
    responseType,
  });
};

export default { getAzureFileUrl };
