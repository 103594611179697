import BaseApi from './BaseApi';

class Subscription2Api extends BaseApi {
  constructor() {
    super();
    this.baseUrl += '/subscriptions2';
  }

  async getSubscription() {
    return await this.get(this.baseUrl);
  }

  async getPlans(promotionCode) {
    return await this.get(`${this.baseUrl}/plans?promotionCode=${promotionCode || ''}`);
  }

  async save(model) {
    return await this.post(this.baseUrl, model);
  }

  async cancel() {
    return await this.delete(this.baseUrl);
  }
}

export default new Subscription2Api();
