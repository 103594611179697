import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Card,
  CardMedia,
  Typography,
  Button,
} from '@material-ui/core';
import { Person as PersonIcon, PhotoCamera } from '@material-ui/icons';
import { fade } from '@material-ui/core/styles';
import ImageCropperDialog from '../dialogs/ImageCropperDialog';
import incidentApi from '../../apis/incident.api';
import { getAzureFileUrl } from '../../utils/fileUtil';
import Auth from '../../auth/Auth';

const useStyles = makeStyles(theme => ({
  root: {
    width: 200,
    height: 200,
    color: '#aaa',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '&:hover > Button': {
      visibility: 'visible',
    },
    [theme.breakpoints.down('xs')]: {
      width: 150,
      height: 150,
      marginTop: theme.spacing(1),
    },
  },
  profileMedia: {
    height: 200,
    width: 200,
    [theme.breakpoints.down('xs')]: {
      width: 150,
      height: 150,
    },
  },
  pictureSelector: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    visibility: 'hidden',
    backgroundColor: fade('#000', 0.5),
    color: '#ccc',
    '&:hover': {
      backgroundColor: fade('#000', 0.5),
      color: '#fff',
    },
  },
  photoIcon: {
    marginRight: theme.spacing(1),
  },
  hidden: {
    display: 'none',
  },
}));

const PersonPictureForm = props => {
  const { pictureUrl, sasKey, thumbnailSasKey } = props;
  const fileRef = React.useRef();
  const classes = useStyles();
  const [state, setState] = React.useState({
    picture: pictureUrl
      ? getAzureFileUrl(
          Auth.currentUser && Auth.currentUser.accountId,
        pictureUrl,
        true,
        sasKey,
        thumbnailSasKey
        )
      : undefined,
    cropOpen: false,
    originFile: null,
    cropFile: null,
  });

  React.useEffect(() => {
    setState({
      picture: pictureUrl
        ? getAzureFileUrl(
            Auth.currentUser && Auth.currentUser.accountId,
            pictureUrl,
            false,
            sasKey,
            thumbnailSasKey
          )
        : undefined,
      cropOpen: false,
      originFile: null,
      cropFile: null,
    });
  }, [props.pictureUrl]);

  const handleFileChange = e => {
    const { files } = e.target;
    if (!files || !files.length) return;
    setState({
      ...state,
      cropOpen: true,
      originFile: URL.createObjectURL(files[0]),
    });
  };
  const handleClose = e => {
    setState({ ...state, cropOpen: false, originFile: null, cropFile: null });
    fileRef.current.value = null;
  };
  const handleCrop = async image => {
    try {
      const { data } = await incidentApi.uploadPersonPicture(
        props.personId,
        props.incidentId,
        image,
      );
      image &&
        data &&
        setState({
          ...state,
          cropFile: image,
          originFile: null,
          cropOpen: false,
          picture: getAzureFileUrl(Auth.currentUser.accountId, data.fileName, false, data.sasKey),
        });
      props.onChange(data);
    } catch (err) {
      props.onError(err);
    }
    fileRef.current.value = null;
  };
  return (
    <>
      <Card className={classes.root}>
        {state.picture ? (
          <CardMedia
            className={classes.profileMedia}
            image={state.picture}
            title="Profile Picture"
          />
        ) : (
          <PersonIcon style={{ fontSize: '150px' }} />
        )}
        <Button
          fullWidth
          valiant="text"
          className={classes.pictureSelector}
          onClick={() => {
            fileRef.current.click();
          }}
        >
          <PhotoCamera className={classes.photoIcon} />
          <Typography variant="subtitle2">Change picture...</Typography>
        </Button>
        <input
          type="file"
          name="file"
          onChange={handleFileChange}
          className={classes.hidden}
          ref={fileRef}
          accept="image/*"
        />
      </Card>
      {state.cropOpen && (
        <ImageCropperDialog
          open={state.cropOpen}
          src={state.originFile}
          options={{ aspect: 1, guides: false }}
          onCrop={handleCrop}
          onClose={handleClose}
        />
      )}
    </>
  );
};

PersonPictureForm.propTypes = {
  incidentId: PropTypes.string.isRequired,
  personId: PropTypes.string.isRequired,
  pictureUrl: PropTypes.string,
  personPictureSasKey: PropTypes.string,
  thumbnailSasKey: PropTypes.string,
  onChange: PropTypes.func,
  onError: PropTypes.func,
};
PersonPictureForm.defaultProps = {
  pictureUrl: undefined,
  onChange: f => f,
  onError: f => f,
};

export default PersonPictureForm;
