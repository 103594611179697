import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import * as yup from 'yup';
import {
  Button,
  TextField,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  InputLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import BaseDialog from './BaseDialog';
import { useForm } from '../../utils/hooks';
import Auth from '../../auth/Auth';
import RichEditor from '../forms/RichEditor';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 600,
    flexGrow: 1,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  bottomMargin: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // height: 80,
    flexWrap: 'wrap',
  },
  buttonSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    // alignItems: 'center',
    // height: 80,
    flexWrap: 'wrap',
  },
  fileButton: {
    height: 50,
  },
}));

const schema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .max(100)
    .required(),
  comments: yup
    .string()
    .nullable()
    .max(500),
});

const IncidentCloseDialog = props => {
  const classes = useStyles();
  const {
    formValues,
    updateFormValues,
    hasFormError,
    formHelpText,
    handleFormChange,
    handleFormChangeForTwo,
    handleFormBlur,
    handleFormSubmit,
  } = useForm({
    validationSchema: schema,
    onSubmit: (values, errors) => {
      if (values.closedBy || _.isEmpty(errors)) {
        props.onCloseIncident(values);
      }
    },
  });

  React.useEffect(() => {
    updateFormValues({
      closedBy: 'USER',
    });
  }, []);

  const handleOk = () => {
    handleFormSubmit();
  };

  const debounceHandleOk = _.debounce(handleOk, 200);

  const onClose = () => {
    props.onClose();
  };

  const inputHandleChange = event => {
    handleFormChange(event);
  };

  const raiseFormEvent = (name, value) => {
    inputHandleChange({ target: { name, value } });
  };

  const handleCommentsChange = (value, plainText) => {
    handleFormChangeForTwo({
      target: {
        name: 'closeComments',
        value,
        name2: 'closeCommentsText',
        value2: plainText,
      },
    });
  };

  return (
    <BaseDialog
      open={props.open}
      maxWidth="lg"
      onClose={props.onClose}
      title="Close Incident"
      actionContent={
        <>
          <Button
            onClick={debounceHandleOk}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
          <Button onClick={onClose} color="default" pl={3}>
            Cancel
          </Button>
        </>
      }
    >
      <div>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            aria-label="closePerson"
            name="closedBy"
            value={formValues.closedBy}
            onChange={inputHandleChange}
          >
            <FormControlLabel
              value="USER" // {Auth.currentUserValue.userId}
              control={<Radio />}
              label={`By user (${Auth.currentUserValue.name})`}
            />
            <FormControlLabel
              value="OTHER"
              control={<Radio />}
              label="By other"
            />

            {formValues.closedBy === 'OTHER' && (
              <div className={classes.lineItem}>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="closeFirstName"
                    label="First name"
                    value={formValues.closeFirstName || ''}
                    onChange={inputHandleChange}
                    margin="none"
                    onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('closeFirstName')}
                    helperText={formHelpText('closeFirstName')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControlShort}>
                  <TextField
                    name="closeMiddleName"
                    label="Middle initial"
                    value={formValues.closeMiddleName || ''}
                    onChange={inputHandleChange}
                    margin="none"
                    onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('closeMiddleName')}
                    helperText={formHelpText('closeMiddleName')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="closeLastName"
                    label="Last name"
                    value={formValues.closeLastName || ''}
                    onChange={inputHandleChange}
                    margin="none"
                    onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('closeLastName')}
                    helperText={formHelpText('closeLastName')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="closeAlias"
                    label="Alias"
                    value={formValues.closeAlias || ''}
                    onChange={inputHandleChange}
                    margin="none"
                    onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('closeAlias')}
                    helperText={formHelpText('closeAlias')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="closePosition"
                    label="Position"
                    value={formValues.closePosition}
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    margin="none"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('closePosition')}
                    helperText={formHelpText('closePosition')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="closeDepartment"
                    label="Department"
                    value={formValues.closeDepartment || ''}
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    margin="none"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('closeDepartment')}
                    helperText={formHelpText('closeDepartment')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="closeEmail"
                    label="Email"
                    type="email"
                    value={formValues.closeEmail || ''}
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    margin="none"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('closeEmail')}
                    helperText={formHelpText('closeEmail')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControlShort}>
                  <TextField
                    name="closePhone"
                    label="Phone"
                    type="tel"
                    placeholder="ex) 123-456-7890"
                    value={formValues.closePhone || ''}
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    margin="none"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('closePhone')}
                    helperText={formHelpText('closePhone')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControlShort}>
                  <TextField
                    name="closeMobilePhone"
                    label="Mobile phone"
                    type="tel"
                    placeholder="ex) 123-456-7890"
                    value={formValues.closeMobilePhone || ''}
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    margin="none"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('closeMobilePhone')}
                    helperText={formHelpText('closeMobilePhone')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
              </div>
            )}
          </RadioGroup>
        </FormControl>
      </div>

      <InputLabel shrink>Comments</InputLabel>
      <FormControl className={classes.subCommentForm}>
        <RichEditor
          height="low"
          onChange={handleCommentsChange}
          defaultValue={formValues.closeComments}
        />
      </FormControl>
    </BaseDialog>
  );
};

IncidentCloseDialog.propTypes = {
  onCloseIncident: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

IncidentCloseDialog.defaultProps = {
  onCloseIncident: () => {},
  onClose: () => {},
};

export default IncidentCloseDialog;
