import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import _ from 'lodash';
import { enqueueError, enqueueSuccess } from '../../modules/global';
import mfaApi from '../../apis/mfa.api';

const DEVICE_LOADED = 'mfa/DEVICE_LOADED';
const LOADING = 'mfa/LOADING';
const CODE_SENT = 'mfa/CODE_SENT';

const loadingAction = createAction(LOADING);
const loadedAction = createAction(DEVICE_LOADED);
const codeSentAction = createAction(CODE_SENT);

const initState = {
  loading: false,
  phone: undefined,
};

export default handleActions(
  {
    [LOADING]: (state, action) =>
      produce(state, draft => {
        draft.loading = true;
      }),
    [DEVICE_LOADED]: (state, action) =>
      produce(state, draft => {
        draft.loading = false;
        draft.phone = action.payload;
      }),
    [CODE_SENT]: (state, action) =>
      produce(state, draft => {
        draft.loading = false;
      }),
  },
  initState,
);

export const getDevice = () => async dispatch => {
  dispatch(loadingAction());
  try {
    const { data } = await mfaApi.getDevice();
    dispatch(loadedAction(data));
    return data;
  } catch (err) {
    dispatch(enqueueError(err));
    throw err;
  }
};

export const sendOTP = () => async dispatch => {
  dispatch(loadingAction());
  try {
    await mfaApi.sendOTP();
    dispatch(codeSentAction());
  } catch (err) {
    dispatch(enqueueError(err));
    throw err;
  }
};

export const sendOTPForEnrol = () => async dispatch => {
  try {
    await mfaApi.sendOTPForEnrol();
  } catch (err) {
    dispatch(enqueueError(err));
    throw err;
  }
};

export const verifyOTP = (deviceId, otp) => async dispatch => {
  try {
    await mfaApi.verifyOTP(deviceId, otp);
  } catch (err) {
    dispatch(enqueueError(err));
    throw err;
  }
};

export const startEnrol = phoneNumner => async dispatch => {
  try {
    await mfaApi.startEnrol(phoneNumner);
  } catch (err) {
    dispatch(enqueueError(err));
    throw err;
  }
};

export const verifyEnrol = otp => async dispatch => {
  try {
    await mfaApi.verifyEnrol(otp);
  } catch (err) {
    dispatch(enqueueError(err));
    throw err;
  }
};
