import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, Box, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import DefaultLayout from '../DefaultLayout';
import SectionHeader from '../../components/typo/SectionHeader';
import ProfileForm from './ProfileForm';
import '../../App.css';
import ProfileDetails from './ProfileDetails';
import AlertDialog from '../../components/dialogs/AlertDialog';

import {
  loadProfile,
  updateProfile,
  requestChangePassword,
  restoreDemoSite,
  syncQuickBooks,
} from './ducks';
import UserPictureForm from '../../components/forms/UserPictureForm';
import { enqueueError, enqueueSuccess } from '../../modules/global';
import Auth from '../../auth/Auth';

const useStyles = makeStyles(theme => ({
  actionButton: {
    margin: theme.spacing(1),
  },
  profileForm: {
    margin: theme.spacing(1),
  },
  restoreDemoSiteButton: {
    margin: theme.spacing(1),
    color: 'white',
    background: theme.palette.error.main,
  },
}));

const title = <SectionHeader title="Profile" subtitle="" />;

const Profile = props => {
  const classes = useStyles();
  const { actions, loaded, profile } = props;
  const [model, setModel] = React.useState({});
  const [isValidForm, setIsValidForm] = React.useState(false);
  const [changePasswordAlertOpen, setChangePasswordAlertOpen] = React.useState(
    false,
  );
  const [restoreDemoSiteLoading, setRestoreDemoSiteLoading] = React.useState(
    false,
  );

  React.useEffect(() => {
    actions.loadProfile();
  }, []);

  React.useEffect(() => {
    loaded && setModel(profile);
  }, [loaded]);

  const handleSubmit = () => {
    if (isValidForm) {
      actions.updateProfile(model);
    }
  };
  const debounceHandleSubmit = _.debounce(handleSubmit, 200);

  const handleFieldChange = value => {
    setModel(Object.assign({}, model, value));
  };

  const handleValidationChange = valid => {
    setIsValidForm(valid);
  };

  const handleChangePassword = () => {
    actions.requestChangePassword().then(() => {
      setChangePasswordAlertOpen(true);
    });
  };
  const debounceHandleChangePassword = _.debounce(handleChangePassword, 200);

  const handlePictureChange = url => {
    actions.enqueueSuccess(
      'Profile image is successfully updated and the new image will be applied in the next login.',
    );
  };

  const handlePictureError = error => {
    actions.enqueueError(error);
  };

  const isDemoServer = () => {
    return (
      Auth.hasPrivilege(['SYSTEM_ADMIN']) &&
      (window.location.hostname.includes('demo.incidentxpress.com') ||
        window.location.hostname.includes('localhost'))
    );
  };

  const isSystemAdmin = () => {
    return Auth.isSystemAdmin();
  };

  const handleRestoreDemoSite = () => {
    setRestoreDemoSiteLoading(true);
    actions
      .restoreDemoSite()
      .then(() => {
        setRestoreDemoSiteLoading(false);
      })
      .catch(() => {
        setRestoreDemoSiteLoading(false);
      });
  };

  const handleSyncQuickBooks = () => {
    actions
      .syncQuickBooks()
      .then(redirectUrl => {
        window.open(redirectUrl, 'quickbook');
      })
      .catch(err => {
        props.enqueueError(err);
      });
  };

  const change2FAPhone = () => {
    props.history.push({
      pathname: '/change2FAPhone',
    });
  };

  return (
    <DefaultLayout title={title}>
      {loaded && (
        <>
          <Box display="flex" flex="1" flexDirection="row">
            <Box>
              <ProfileForm
                className={classes.profileForm}
                user={profile}
                onFieldChange={handleFieldChange}
                onValidationChange={handleValidationChange}
              />
              <div className="details">
                <ProfileDetails
                  user={profile}
                  accountDateFormat={props.companySettings.dateFormat}
                />
              </div>
            </Box>
            <Box>
              <UserPictureForm
                avatarUrl={`${profile.avatarUrl}?${profile.avatarSasKey}`}
                onChange={handlePictureChange}
                onError={handlePictureError}
              />
              <Typography variant="body2">
                * upload limit {props.maxAvatarFileSize}MB
              </Typography>
            </Box>
          </Box>

          <div className="action-container">
            <Button
              variant="contained"
              disabled={!isValidForm}
              color="primary"
              onClick={debounceHandleSubmit}
            >
              Save
            </Button>
            <Button
              className={classes.actionButton}
              variant="contained"
              color="primary"
              onClick={debounceHandleChangePassword}
            >
              Change Password
            </Button>
            {props.mfaEnabled && (
              <Button
                className={classes.actionButton}
                variant="contained"
                color="primary"
                onClick={change2FAPhone}
              >
                Change MFA Phone
              </Button>
            )}
            {isDemoServer() && (
              <Button
                className={classes.restoreDemoSiteButton}
                variant="contained"
                onClick={handleRestoreDemoSite}
              >
                Restore demo site
                {restoreDemoSiteLoading && (
                  <CircularProgress
                    color="secondary"
                    className={classes.circularProgress}
                    size={20}
                  />
                )}
              </Button>
            )}
            {isSystemAdmin() && (
              <Button
                className={classes.restoreDemoSiteButton}
                variant="contained"
                onClick={handleSyncQuickBooks}
              >
                Sync Quickbook Online
              </Button>
            )}
          </div>
        </>
      )}
      <AlertDialog
        title="Change Password"
        message={"We've just sent you an email to reset your password."}
        open={changePasswordAlertOpen}
        cancelButtonHidden
        onClose={() => {
          setChangePasswordAlertOpen(false);
        }}
        onOk={() => {
          setChangePasswordAlertOpen(false);
        }}
      />
    </DefaultLayout>
  );
};

Profile.propTypes = {
  actions: PropTypes.func,
  loaded: PropTypes.bool,
  profile: PropTypes.object,
  enqueueError: PropTypes.func,
  enqueueSuccess: PropTypes.func,
  restoreDemoSite: PropTypes.func,
  syncQuickBooks: PropTypes.func,
  maxAvatarFileSize: PropTypes.number,
};
Profile.defaultProps = {
  maxAvatarFileSize: 1,
};

function mapStateToProps({ profiles, common }) {
  const { loaded, profile } = profiles;
  return {
    loaded,
    profile,
    maxAvatarFileSize: common.fileSizeLimit.maxAvatarFileSize,
    companySettings: common.companySettings,
    mfaEnabled: common.companySettings.mfa,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = bindActionCreators(
    {
      loadProfile,
      updateProfile,
      requestChangePassword,
      enqueueError,
      enqueueSuccess,
      restoreDemoSite,
      syncQuickBooks,
    },
    dispatch,
  );
  return { actions, dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
