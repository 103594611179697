import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import DefaultLayout from '../DefaultLayout';
import auth from '../../auth/Auth';
import userApi from '../../apis/user.api';
import { enqueueSuccess } from '../../modules/global';

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    title: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    detail: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      whiteSpace: 'pre-line',
    },
    actionsContainer: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    button: {
      marginRight: theme.spacing(3),
    },
  }),
);

const errorTypeMap = {
  pending: {
    name: 'pending',
    title: 'Action Required',
    detail:
      'Almost done!\nA verification email has been sent to you. Please verify your account using the link provided. If you do not see the verification email in your inbox, please check your junk or spam folder.',
    action: 'logout',
  },
  inactive: {
    name: 'inactive',
    title: 'Authorization Error',
    detail: 'Your account is inactive. Please contact your administrator.',
    action: 'logout',
  },
  unknownStatus: {
    name: 'unknownStatus',
    title: 'Authorization Error',
    detail: `We're afraid something went wrong...Please try again.`,
    action: 'logout',
  },
  noPermission: {
    name: 'noPermission',
    title: 'Permission Error',
    detail: `You do not have permission.`,
  },
  pageNotExist: {
    name: 'pageNotExist',
    title: 'Page Not Exist Error',
    detail: `The page does not exist.`,
  },
  exceedMaxConcurrentUser: {
    name: 'exceedMaxConcurrentUser',
    title: 'Exceed Max Concurrent User',
    detail: `The number of current concurrent users exceeded the max concurrent user. Please contact to your system admin.`,
    action: 'logout',
  },
};

const makeErrorMessage = type => {
  return (
    errorTypeMap[type] || {
      detail: `We're afraid something went wrong...Please try again.`,
    }
  );
};

const CustomError = props => {
  const { match } = props;
  const classes = useStyles();
  const errorMessage = makeErrorMessage(
    match ? match.params.type : 'pageNotExist',
  );

  const sendEmailVerification = () => {
    userApi.resendMyEmailVerification().then(() => {
      props.enqueueSuccess('Email verification is sent to your email account.');
    });
  };

  return (
    <DefaultLayout>
      <Typography variant="h2" className={classes.title}>
        {errorMessage.title}
      </Typography>

      <Typography variant="h5" component="h5" className={classes.detail}>
        {errorMessage.detail}
      </Typography>

      <ErrorActions
        actionName={errorMessage.action}
        errorName={errorMessage.name}
        onSendEmailVerification={sendEmailVerification}
      />
    </DefaultLayout>
  );
};

CustomError.propTypes = {
  match: PropTypes.object,
  enqueueSuccess: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  enqueueSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomError);

const ErrorActions = props => {
  const { actionName } = props;
  const classes = useStyles();
  const handleLogout = () => {
    auth.logout();
  };

  const actionMap = {
    logout: {
      text: 'Go to main page',
      onClick: handleLogout,
    },
  };
  const currentAction = actionMap[actionName];

  return (
    <>
      <div className={classes.actionsContainer}>
        {currentAction && (
          <Button
            onClick={currentAction.onClick}
            color="primary"
            variant="contained"
            className={classes.button}
          >
            {currentAction.text}
          </Button>
        )}
        {props.errorName === 'pending' && (
          <Button
            onClick={props.onSendEmailVerification}
            color="primary"
            variant="contained"
            className={classes.button}
          >
            Resend email verification
          </Button>
        )}
      </div>
    </>
  );
};

ErrorActions.propTypes = {
  actionName: PropTypes.string,
  errorName: PropTypes.string,
  onSendEmailVerification: PropTypes.func,
};
