import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, FormControl, TextField } from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { DefaultDateFormat } from '../../constants';

const styles = theme => ({
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    // height: 80,
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
});

const ProfileDetails = withStyles(styles)(props => {
  const { user, classes } = props;

  return (
    <>
      <form>
        <div className={classes.lineItem}>
          <FormControl className={classes.formControl}>
            <TextField
              name="position"
              label="Position"
              value={user.position}
              disabled
              margin="none"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Start date with company"
                format={DefaultDateFormat(props.accountDateFormat).date}
                value={user.startDate}
                onChange={f => f}
                disabled
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </div>
        <div className={classes.lineItem}>
          <FormControl className={classes.formControl}>
            <TextField
              name="company"
              label="Company"
              value={user.company}
              margin="none"
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              name="division"
              label="Division"
              value={user.division}
              disabled
              margin="none"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              name="department"
              label="Department"
              value={user.department}
              disabled
              margin="none"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              name="region"
              label="Region"
              value={user.region}
              disabled
              margin="none"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              name="location"
              label="Location"
              value={user.location}
              disabled
              margin="none"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              name="phone"
              label="Phone"
              type="tel"
              placeholder="ex) 123-456-7890"
              value={user.phone}
              disabled
              margin="none"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              name="fax"
              label="Fax"
              type="tel"
              placeholder="ex) 123-456-7890"
              value={user.fax}
              disabled
              margin="none"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </div>
        <div className={classes.lineItem}>
          <FormControl className={classes.formControlLong}>
            <TextField
              name="userGroupName"
              label="Workgroup"
              value={user.userGroupName}
              disabled
              margin="none"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </div>
      </form>
    </>
  );
});

ProfileDetails.propTypes = {
  user: PropTypes.shape({
    position: PropTypes.string,
    startDate: PropTypes.instanceOf(Date),
    company: PropTypes.string,
    department: PropTypes.string,
    division: PropTypes.string,
    region: PropTypes.string,
    location: PropTypes.string,
    phone: PropTypes.string,
    fax: PropTypes.string,
    userGroupName: PropTypes.string.isRequired,
  }).isRequired,
  accountDateFormat: PropTypes.number,
};

ProfileDetails.defaultProps = {
  user: {},
};

export default ProfileDetails;
