import React from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  Popover,
  IconButton,
  Box,
  makeStyles,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '15px',
    // height: '15px',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  helpIcon: {
    fontSize: '15px',
    color: theme.palette.grey[500],
  },
  helpIconLight: {
    fontSize: '15px',
    color: 'white',
  },
  popover: {
    pointerEvents: 'none',
  },
  box: {
    backgroundColor: 'grey',
    color: 'white',
  },
}));

const HelpButton = props => {
  const classes = useStyles();
  const { popupId, message, iconMargin, ...other } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'help-popover' : undefined;

  return (
    <div className={classes.root}>
      <HelpIcon
        aria-owns={open ? id : undefined}
        aria-haspopup="true"
        className={props.dark ? classes.helpIcon : classes.helpIconLight}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
      />
      <Popover
        id={id}
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
        style={
          {
            // display: 'flex',
            // flexDirection: 'column',
            // padding: '20px',
            // position: 'absolute',
            // maxHeight: '450px',
            // maxWidth: '700px',
          }
        }
      >
        <Box p={2} className={classes.box}>
          <Typography variantMapping="body2">{message}</Typography>
        </Box>
      </Popover>
    </div>
  );
};

HelpButton.propTypes = {
  popupId: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  iconMargin: PropTypes.string,
  className: PropTypes.string,
  dark: PropTypes.bool,
};

HelpButton.defaultProps = {
  iconMargin: '0 0 0 0',
  dark: true,
};

export default HelpButton;
