import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { FormControl, TextField } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: 400,
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
});

// validation rules
const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('invalid email address')
    .required('required'),
  firstName: yup
    .string()
    .max(100, 'maximum length is 100')
    .required('required'),
  lastName: yup
    .string()
    .max(100, 'maximum length is 100')
    .required('required'),
});

// @ts-ignore
const FormGroup = withStyles(styles)(props => {
  const {
    errors,
    touched,
    values,
    handleBlur,
    handleChange,
    validateForm,
    classes,
    onFieldChange,
    onValidationChange,
  } = props;

  const checkValid = () => {
    validateForm().then(() => {
      onValidationChange(_.isEmpty(errors));
    });
  };

  const handleInputChange = e => {
    handleChange(e);
    onFieldChange({ [e.target.name]: e.target.value });
    checkValid();
  };
  const handleInputBlur = e => {
    handleBlur(e);
    checkValid();
  };

  return (
    <Form>
      <div className={classes.lineItem}>
        <FormControl className={classes.formControlLong}>
          <TextField
            required
            type="email"
            name="email"
            label="Email (User ID)"
            disabled
            value={values.email}
            // onChange={handleInputChange}
            onBlur={handleInputBlur}
            helperText={errors.email && touched.email && errors.email}
            error={touched.email && Boolean(errors.email)}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        {/* <FormControl className={classes.formControlLong}>
          <TextField
            required
            autoComplete="new-password"
            type="password"
            name="password"
            label="Password"
            value={values.password}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            helperText={errors.password && touched.password && errors.password}
            error={touched.password && Boolean(errors.password)}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        {user.id && (
          <FormControl className={classes.formControl}>
            <Button variant="contained" color="primary">
              Password Reset
            </Button>
          </FormControl>
        )} */}
      </div>
      <div className={classes.lineItem}>
        <FormControl className={classes.formControl}>
          <TextField
            required
            name="firstName"
            label="First name"
            value={values.firstName}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            helperText={
              errors.firstName && touched.firstName && errors.firstName
            }
            error={touched.firstName && Boolean(errors.firstName)}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            name="middleName"
            label="Middle initial"
            value={values.middleName}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            helperText={
              errors.middleName && touched.middleName && errors.middleName
            }
            error={touched.middleName && Boolean(errors.middleName)}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            required
            name="lastName"
            label="Last name"
            value={values.lastName}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            helperText={errors.lastName && touched.lastName && errors.lastName}
            error={touched.lastName && Boolean(errors.lastName)}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      </div>
    </Form>
  );
});

const ProfileForm = props => {
  const formRef = React.useRef();
  const { user, onValidationChange } = props;

  React.useEffect(() => {
    if (formRef.current) {
      formRef.current.validateForm().then(res => {
        onValidationChange(_.isEmpty(res));
      });
    }
  }, []);

  return (
    <Formik
      ref={formRef}
      initialValues={user}
      isInitialValid={false}
      validationSchema={validationSchema}
      // validateOnBlur={true}
      // validateOnChange={true}
      // onSubmit={(values, { setSubmitting }) => {
      //   setSubmitting(true);
      // }}
      render={formikBag => <FormGroup {...formikBag} {...props} />}
    />
  );
};

ProfileForm.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    middleName: PropTypes.string,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  onValidationChange: PropTypes.func,
};

ProfileForm.defaultProps = {
  user: {},
  onFieldChange: f => f,
  onValidationChange: f => f,
};

export default ProfileForm;
