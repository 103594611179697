import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormControl, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import BaseDialog from '../../components/dialogs/BaseDialog';
import { LookupTypeDef, LookupDef } from '../../components/propTypeDefs';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
}));

const LookupFormDialog = props => {
  const classes = useStyles();
  const { lookupType, lookup, open, mode, labels } = props;
  const [value, setValue] = React.useState('');
  const getSafeValue = lookupValue =>
    (mode === 'update' && lookupValue && lookupValue.name) || '';

  const getLabelName = () => {
    const { type } = lookupType;
    if (type === 'single' || labels.length === 0) return undefined;
    const level = mode === 'add' ? lookup.level : lookup.level - 1;
    return labels[level];
  };

  const getTitle = () => {
    if (lookupType.type === 'single') {
      return mode === 'update'
        ? `Edit ${lookupType.name}`
        : `Add ${lookupType.name}`;
    }
    return mode === 'update'
      ? `Edit ${lookupType.name} (${getLabelName()})`
      : `Add ${lookupType.name} (${getLabelName()})`;
  };

  React.useEffect(() => {
    setValue(getSafeValue(lookup));
  }, [lookup, open]);

  const onClose = () => {
    props.onClose();
  };

  const onOk = () => {
    if (!value) return;
    if (mode === 'add') {
      props.onLookupAdded(lookup, value);
    } else {
      props.onLookupEdited({ ...lookup, name: value });
    }
  };

  const onDisable = () => {
    if (!value) return;
    props.onLookupEdited({ ...lookup, active: false });
  };

  const onEnable = () => {
    if (!value) return;
    props.onLookupEdited({ ...lookup, active: true });
  };

  const debounceOnOk = _.debounce(onOk, 200);
  const debounceOnDisable = _.debounce(onDisable, 200);
  const debounceOnEnable = _.debounce(onEnable, 200);

  const inputHandleChange = event => {
    setValue(event.target.value);
  };

  const inputKeyDown = event => {
    if (event.charCode === 13 || event.keyCode === 13) {
      debounceOnOk();
    }
  };

  return (
    <BaseDialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xs"
      title={getTitle()}
      actionContent={
        <>
          {lookup.active && mode === 'update' && (
            <Button
              onClick={debounceOnDisable}
              variant="contained"
              color="primary"
            >
              Disable
            </Button>
          )}
          {!lookup.active && mode === 'update' && (
            <Button
              onClick={debounceOnEnable}
              variant="contained"
              color="primary"
            >
              Enable
            </Button>
          )}

          <Button onClick={debounceOnOk} variant="contained" color="primary">
            Ok
          </Button>
          <Button onClick={onClose} color="primary" pl={3}>
            Cancel
          </Button>
        </>
      }
    >
      <FormControl className={classes.formControl}>
        <TextField
          required
          autoComplete="off"
          name="value"
          label={getLabelName()}
          InputLabelProps={{
            shrink: true,
          }}
          value={value}
          onChange={inputHandleChange}
          onKeyDown={inputKeyDown}
          margin="none"
          autoFocus
        />
      </FormControl>
    </BaseDialog>
  );
};

LookupFormDialog.propTypes = {
  lookupType: LookupTypeDef,
  lookup: LookupDef,
  labels: PropTypes.arrayOf(PropTypes.string),
  mode: PropTypes.string,
  onClose: PropTypes.func,
  onLookupAdded: PropTypes.func,
  onLookupEdited: PropTypes.func,
  open: PropTypes.bool,
};

LookupFormDialog.defaultProps = {
  onClose: () => {},
  onLookupAdded: () => {},
  onLookupEdited: () => {},
  mode: 'add',
  open: false,
};

export default LookupFormDialog;
