import React, { ref, useImperativeHandle } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  MenuItem,
  FormControl,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import * as yup from 'yup';
import { AccountDef } from '../../../components/propTypeDefs';
import { enqueueError, enqueueSuccess } from '../../../modules/global';
import { updateBillingInformation } from '../companyManagement/ducks';
import { companyCountryCodes } from '../../../constants';
import { useForm } from '../../../utils/hooks';

const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  lineItem: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  submitButton: {
    margin: theme.spacing(1),
  },
}));

const schema = yup.object().shape({
  billingAddressLine1: yup
    .string()
    .nullable()
    .max(100)
    .required('Address 1 is required'),
  billingAddressLine2: yup
    .string()
    .nullable()
    .max(100),
  billingAddressCity: yup
    .string()
    .nullable()
    .max(100)
    .required('City is required'),
  billingAddressState: yup
    .string()
    .nullable()
    .max(100),

  billingAddressPostalCode: yup
    .string()
    .nullable()
    .max(10)
    .required('Postal code is required'),

  billingAddressCountryCode: yup
    .string()
    .nullable()
    .max(2)
    .required('Country is required'),

  billingEmail: yup
    .string()
    .nullable()
    .max(255)
    .required('Email is required'),

  billingPhone: yup
    .string()
    .nullable()
    .max(20)
    .required('Phone is required'),

  billingContactName: yup
    .string()
    .nullable()
    .max(100)
    .required('Contact Name is required'),
});

const BillingInformation = React.forwardRef((props, ref) => {
  const classes = useStyles();

  const {
    formValues,
    updateFormValues,
    hasFormError,
    formHelpText,
    handleFormChange,
    handleFormBlur,
    handleFormSubmit,
  } = useForm({
    validationSchema: schema,
    onSubmit: (values, errors) => {
      if (_.isEmpty(errors)) {
        props.updateBillingInformation(values).then(() => {
          props.enqueueSuccess('Billing information is successfully update.');
        });
      }
    },
  });

  React.useEffect(() => {
    const {
      billingAddressLine1,
      billingAddressLine2,
      billingAddressCity,
      billingAddressState,
      billingAddressPostalCode,
      billingAddressCountryCode,
      billingEmail,
      billingPhone,
      billingContactName,
    } = props.company;
    updateFormValues({
      billingAddressLine1,
      billingAddressLine2,
      billingAddressCity,
      billingAddressState,
      billingAddressPostalCode,
      billingAddressCountryCode,
      billingEmail,
      billingPhone,
      billingContactName,
    });
  }, [props.company]);

  const inputHandleChange = event => {
    handleFormChange(event);
  };

  return (
    <>
      <div>
        <FormControl className={classes.formControlLong}>
          <TextField
            name="billingContactName"
            label="Contact Name"
            value={formValues.billingContactName || ''}
            onChange={inputHandleChange}
            margin="none"
            onBlur={handleFormBlur}
            error={hasFormError('billingContactName')}
            helperText={formHelpText('billingContactName')}
            inputProps={{ autoComplete: 0 }}
            variant="outlined"
            required
          />
        </FormControl>
      </div>
      <div>
        <FormControl className={classes.formControlLong}>
          <TextField
            required
            name="billingAddressLine1"
            label="Address 1"
            value={formValues.billingAddressLine1 || ''}
            onChange={inputHandleChange}
            margin="none"
            onBlur={handleFormBlur}
            error={hasFormError('billingAddressLine1')}
            helperText={formHelpText('billingAddressLine1')}
            inputProps={{ autoComplete: 0 }}
            variant="outlined"
          />
        </FormControl>
        <FormControl className={classes.formControlLong}>
          <TextField
            name="billingAddressLine2"
            label="Address 2"
            value={formValues.billingAddressLine2 || ''}
            onChange={inputHandleChange}
            margin="none"
            onBlur={handleFormBlur}
            error={hasFormError('billingAddressLine2')}
            helperText={formHelpText('billingAddressLine2')}
            inputProps={{ autoComplete: 0 }}
            variant="outlined"
          />
        </FormControl>
      </div>
      <div>
        <FormControl className={classes.formControlLong}>
          <TextField
            name="billingAddressCity"
            label="City"
            value={formValues.billingAddressCity || ''}
            onChange={inputHandleChange}
            margin="none"
            onBlur={handleFormBlur}
            error={hasFormError('billingAddressCity')}
            helperText={formHelpText('billingAddressCity')}
            inputProps={{ autoComplete: 0 }}
            variant="outlined"
            required
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            name="billingAddressState"
            label="State or Province"
            value={formValues.billingAddressState || ''}
            onChange={inputHandleChange}
            margin="none"
            onBlur={handleFormBlur}
            error={hasFormError('billingAddressState')}
            helperText={formHelpText('billingAddressState')}
            inputProps={{ autoComplete: 0 }}
            variant="outlined"
          />
        </FormControl>

        <FormControl className={classes.formControl}>
          <TextField
            name="billingAddressPostalCode"
            label="Zip code or Postal code"
            value={formValues.billingAddressPostalCode || ''}
            onChange={inputHandleChange}
            margin="none"
            onBlur={handleFormBlur}
            error={hasFormError('billingAddressPostalCode')}
            helperText={formHelpText('billingAddressPostalCode')}
            inputProps={{ autoComplete: 0 }}
            variant="outlined"
            required
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Country"
            select
            margin="none"
            name="billingAddressCountryCode"
            value={formValues.billingAddressCountryCode || ''}
            onChange={handleFormChange}
            onBlur={handleFormBlur}
            error={hasFormError('billingAddressCountryCode')}
            helperText={formHelpText('billingAddressCountryCode')}
            variant="outlined"
            required
          >
            {companyCountryCodes.map(type => (
              <MenuItem key={type.code} value={type.code}>
                {type.name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </div>
      <div>
        <FormControl className={classes.formControlLong}>
          <TextField
            name="billingEmail"
            label="Email"
            value={formValues.billingEmail || ''}
            onChange={inputHandleChange}
            margin="none"
            onBlur={handleFormBlur}
            error={hasFormError('billingEmail')}
            helperText={formHelpText('billingEmail')}
            inputProps={{ autoComplete: 0 }}
            variant="outlined"
            required
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            name="billingPhone"
            label="Phone"
            value={formValues.billingPhone || ''}
            onChange={inputHandleChange}
            margin="none"
            onBlur={handleFormBlur}
            error={hasFormError('billingPhone')}
            helperText={formHelpText('billingPhone')}
            inputProps={{ autoComplete: 0 }}
            variant="outlined"
            required
          />
        </FormControl>
      </div>
      <div className={classes.submitButton}>
        <Box display="flex" mt={2} px={4} justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleFormSubmit}
          >
            Save
          </Button>
        </Box>
      </div>
    </>
  );
});

BillingInformation.propTypes = {
  company: AccountDef,
  updateBillingInformation: PropTypes.func.isRequired,
  onChangeBillingInformation: PropTypes.func.isRequired,
};

BillingInformation.defaultProps = {
  company: {},
};

const mapStateToProps = ({ administration }) => ({
  // company: administration.company.company,
});
const mapDispatchToProps = {
  enqueueError,
  enqueueSuccess,
  updateBillingInformation,
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(BillingInformation);
