import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Person as PersonIcon } from '@material-ui/icons';
import { CardMedia, Card } from '@material-ui/core';

import { getAzureFileUrl } from '../../utils/fileUtil';
import Auth from '../../auth/Auth';

const useStyles = makeStyles(theme => ({
  root: {
    width: 200,
    height: 200,
    color: '#aaa',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '&:hover > Button': {
      visibility: 'visible',
    },
    [theme.breakpoints.down('xs')]: {
      width: 150,
      height: 150,
      marginTop: theme.spacing(1),
    },
  },
  profileMedia: {
    height: 200,
    width: 200,
    [theme.breakpoints.down('xs')]: {
      width: 150,
      height: 150,
    },
  },
}));

const PersonPictureView = props => {
  const { pictureUrl, sasKey, thumbnailSasKey } = props;
  const classes = useStyles();
  const [state, setState] = React.useState({
    picture: pictureUrl
      ? getAzureFileUrl(
          Auth.currentUser && Auth.currentUser.accountId,
        pictureUrl,
        false,
        sasKey,
        thumbnailSasKey
        )
      : undefined,
    cropOpen: false,
    originFile: null,
    cropFile: null,
  });

  return (
    <Card className={classes.root}>
      {state.picture ? (
        <CardMedia
          className={classes.profileMedia}
          image={state.picture}
          title="Profile Picture"
        />
      ) : (
        <PersonIcon style={{ fontSize: '150px' }} />
      )}
    </Card>
  );
};

PersonPictureView.propTypes = {
  pictureUrl: PropTypes.string,
  sasKey: PropTypes.string,
  thumbnailSasKey: PropTypes.string,
};
PersonPictureView.defaultProps = {
  pictureUrl: undefined,
};

export default PersonPictureView;
