import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import produce from 'immer';
import _ from 'lodash';
import { Link, Tooltip, IconButton } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import analyzeApi from '../../apis/analyze.api';
import { getChartTypeInfo } from './ChartTypeSelector';
import { enqueueError } from '../../modules/global';
import { LookupLabelSetDef } from '../../components/propTypeDefs';
import { LookupTypeKey } from '../../constants';
import ExcelIcon from '../../components/icons/ExcelIcon';
import { convertAnalysisDataToCSV } from '../../utils/excelUtil';
import chartThemes from '../../utils/chartThemes';

echarts.registerTheme('shine', chartThemes.shine);
echarts.registerTheme('macarons', chartThemes.macarons);
echarts.registerTheme('infographic', chartThemes.infographic);

const CHART_HEIGHT = 500;
const MAX_ROW_COUNT = 2;
const ROW_HEIGHT = CHART_HEIGHT / MAX_ROW_COUNT;

const useStyles = makeStyles(theme => ({
  classNavigator: {
    display: 'flex',
    alignItems: 'center',
  },
  classNavigatorItem: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  hidden: {
    display: 'none',
  },
}));

const ChartViewer = props => {
  const classes = useStyles();
  const { title, analysisCriteria, classLabels } = props;

  const chartTypeInfo = getChartTypeInfo(props.chartType);
  const chartRef = React.useRef();
  const csvRef = React.useRef();

  const [state, setState] = React.useState({
    chartDataList: [],
    excelData: '',
    chartLoading: true,
    chartType: chartTypeInfo.type || 'bar',
    isStacked: chartTypeInfo.isStacked,
  });

  const getFrequency = () => {
    const firstCriteria = _.first(analysisCriteria);
    return firstCriteria ? firstCriteria.frequency : undefined;
  };

  const getLookupLabels = () => {
    const frequency = getFrequency();
    if (frequency === 'CLASS') {
      return props.lookupLabelSet[LookupTypeKey.incidentClass];
    }
    return [];
  };

  const [selectedClasses, setSelectedClasses] = React.useState([]);

  const getChartData = (data, type) => {
    const legend = data.map(x => x.periodName);
    const category = data[0].data.map(x => x.period);
    const extractData = period =>
      period.data.map(x => {
        if (type === 'pie') {
          if (x.count) {
            return {
              name: x.period,
              value: x.count,
            };
          }
          return undefined;
        }
        return x.count;
      });
    const series = data.map(x => extractData(x));
    return { legend, category, series };
  };

  React.useEffect(() => {
    if (selectedClasses.length < state.chartDataList.length) {
      setState({
        ...state,
        chartDataList: state.chartDataList.slice(0, selectedClasses.length + 1),
      });
      return;
    }

    const criteria = analysisCriteria.map(x => {
      return { ...x, selectedClasses };
    });

    analyzeApi
      .analyze(criteria, title)
      .then(({ data }) => {
        setState({
          ...state,
          chartDataList: [
            ...state.chartDataList,
            getChartData(data, state.chartType),
          ],
          excelData: convertAnalysisDataToCSV(data),
          chartLoading: false,
        });
      })
      .catch(err => {
        props.enqueueError(err);
      });
  }, [selectedClasses]);

  React.useEffect(() => {
    const info = getChartTypeInfo(props.chartType);
    setState({
      chartDataList: [],
      excelData: '',
      chartLoading: true,
      chartType: info.type || 'bar',
      isStacked: info.isStacked,
    });
    setSelectedClasses([]);
  }, [props.analysisCriteria]);

  const getXAxis = dataSet => {
    if (state.chartType === 'pie') {
      return undefined;
    }

    return {
      type: 'category',
      data: dataSet.category,
      name: getFrequency(),
      nameLocation: 'center',
      nameGap: 35,
      axisLabel: {
        interval: 0,
        rotate: 20,
      },
    };
  };

  const getYAxis = () => {
    if (state.chartType === 'pie') {
      return undefined;
    }

    return {
      type: 'value',
      minInterval: 1,
      name: 'Incident Count',
      nameLocation: 'center',
      nameGap: 35,
    };
  };

  const getPieRadius = dataSet => {
    let radius = 200;
    if (dataSet.series.length === 2) {
      // 2 X 1
      radius = 100;
    } else if (dataSet.series.length > 2) {
      // 3 X 2
      radius = 50;
    }

    return `${radius}px`;
  };

  const getPieCenter = (dataSet, index) => {
    if (dataSet.series.length === 1) return [`50%`, `${CHART_HEIGHT / 2}px`];
    if (dataSet.series.length === 2) {
      if (index === 0) {
        return [`25%`, `${CHART_HEIGHT / 2}px`];
      }
      if (index === 1) {
        return [`75%`, `${CHART_HEIGHT / 2}px`];
      }
    }

    // chart series count >= 3
    const colWidth = 100 / 3;
    const totalRowCount = Math.floor(dataSet.series.length / 3) + 1;
    const row = Math.floor(index / 3) + 1;
    const col = (index % 3) + 1;
    const rowPos = ROW_HEIGHT * row - ROW_HEIGHT / 2;
    const colPos = Math.floor(colWidth * col - colWidth / 2);
    return [`${colPos}%`, `${rowPos}px`];
  };

  const getSeries = dataSet => {
    if (state.chartType === 'pie') {
      return dataSet.series.map((x, index) => ({
        type: state.chartType,
        radius: getPieRadius(dataSet),
        center: getPieCenter(dataSet, index),
        label: {
          show: true,
          position: 'outside',
          alignTo: 'labelLine',
          bleedMargin: 5,
          formatter: value => {
            return `${value.name}(${value.value})`;
          },
        },
        data: x,
        stack: false,
      }));
    }

    return dataSet.series.map((x, index) => ({
      type: state.chartType,
      label: {
        show: true,
        position: 'top',
        formatter: data => {
          return data.value === 0 ? '' : data.value;
        },
      },
      data: x,
      stack: state.isStacked,
      name: dataSet.legend[index],
    }));
  };

  const getSubTitles = dataSet => {
    if (state.chartType !== 'pie') {
      return [];
    }
    return dataSet.legend.map((x, index) => {
      let col = 50;
      let row = `${CHART_HEIGHT - 50}px`;

      if (dataSet.series.length === 1) {
        col = 50;
        row = `${CHART_HEIGHT - 50}px`;
      } else if (dataSet.series.length === 2) {
        col = 25 + 50 * index;
        row = `${CHART_HEIGHT - 50}px`;
      } else if (dataSet.series.length > 2) {
        col = 16.66665 + 33.3333 * (index % 3);
        row = (Math.floor(index / 3) + 1) * ROW_HEIGHT - 50;
      }
      return {
        subtext: x,
        left: `${col}%`,
        top: `${row}px`,
        textAlign: 'center',
      };
    });
  };

  const getCharOptions = () => {
    const dataSet = _.last(state.chartDataList);
    if (!dataSet) return {};

    return {
      title: [
        {
          text: title,
          textStyle: {
            fontSize: 24,
            fontWeight: 600,
          },
        },
        ...getSubTitles(dataSet),
      ],
      toolbox: {
        show: true,
        right: 50,
        feature: {
          myTool1: {
            show: true,
            title: 'Export',
            icon:
              'path://M 12 3 L 2 5 L 2 19 L 12 21 L 12 3 z M 14 5 L 14 7 L 16 7 L 16 9 L 14 9 L 14 11 L 16 11 L 16 13 L 14 13 L 14 15 L 16 15 L 16 17 L 14 17 L 14 19 L 21 19 C 21.552 19 22 18.552 22 18 L 22 6 C 22 5.448 21.552 5 21 5 L 14 5 z M 18 7 L 20 7 L 20 9 L 18 9 L 18 7 z M 4.1757812 8.296875 L 5.953125 8.296875 L 6.8769531 10.511719 C 6.9519531 10.692719 7.0084063 10.902625 7.0664062 11.140625 L 7.0917969 11.140625 C 7.1247969 10.997625 7.1919688 10.779141 7.2929688 10.494141 L 8.3222656 8.296875 L 9.9433594 8.296875 L 8.0078125 11.966797 L 10 15.703125 L 8.2714844 15.703125 L 7.1582031 13.289062 C 7.1162031 13.204062 7.0663906 13.032922 7.0253906 12.794922 L 7.0097656 12.794922 C 6.9847656 12.908922 6.934375 13.079594 6.859375 13.308594 L 5.7363281 15.703125 L 4 15.703125 L 6.0605469 11.996094 L 4.1757812 8.296875 z M 18 11 L 20 11 L 20 13 L 18 13 L 18 11 z M 18 15 L 20 15 L 20 17 L 18 17 L 18 15 z',
            onclick() {
              csvRef.current.link.click();
            },
          },
          saveAsImage: { type: 'png', title: 'Save as image' },
          // dataView: {
          //   show: true,
          //   title: 'Data View',
          //   lang: ['Data view', 'Close', 'Apply'],
          // },
          // magicType: {
          //   type: ['line', 'bar', 'stack', 'tilted'],
          //   title: {
          //     line: 'for line charts',
          //     bar: 'for bar charts',
          //     stack: 'for stacked charts',
          //     titled: 'for tiled charts',
          //   },
          // },
          // restore: {
          //   title: 'restore',
          // },
        },
      },
      legend: {
        type: 'scroll',
        data: dataSet.legend,
        top: 'middle',
        x: 'right',
        orient: 'vertical',
      },
      tooltip: {},
      xAxis: getXAxis(dataSet),
      yAxis: getYAxis(),
      series: getSeries(dataSet),
    };
  };

  const getChartHeight = () => {
    const dataSet = _.last(state.chartDataList);

    if (dataSet.series.length <= 6) {
      return `${CHART_HEIGHT}px`;
    }

    const height = (Math.floor(dataSet.series.length / 3) + 1) * ROW_HEIGHT;
    return `${height}px`;
  };

  const navigateToClass = level => {
    setSelectedClasses(selectedClasses.slice(0, level));
  };

  const getClassNavigator = () => {
    const lookupLabels = getLookupLabels();
    if (lookupLabels.length === 0) {
      return '';
    }
    const firstClass = (
      <Link
        className={classes.classNavigatorItem}
        key={lookupLabels[0]}
        href="#0"
        onClick={() => navigateToClass(0)}
      >
        {lookupLabels[0]}
      </Link>
    );
    const nav = selectedClasses.map((x, index) => (
      <>
        <div>{`${x.name} >`}</div>
        <Link
          className={classes.classNavigatorItem}
          key={lookupLabels[index + 1]}
          href="#0"
          onClick={() => navigateToClass(index + 1)}
        >
          {lookupLabels[index + 1]}
        </Link>
      </>
    ));

    return [firstClass, ...nav];
  };

  const navigateToChild = name => {
    // skip navigation if frequency is not class
    if (getFrequency() !== 'CLASS') return;

    if (selectedClasses.length === getLookupLabels().length - 1) return;

    // hold to re-reder chart for current class
    setState({
      ...state,
      chartLoading: true,
    });

    const newSelectedClasses = produce(selectedClasses, draft => {
      const last = _.last(selectedClasses);
      draft.push({
        level: last ? last.level + 1 : 1,
        name,
      });
    });

    setSelectedClasses(newSelectedClasses);
  };

  return (
    <React.Fragment>
      <CSVLink
        ref={csvRef}
        data={state.excelData || ''}
        filename={`${title}.csv`}
        className={classes.hidden}
      >
        <Tooltip title="Export to excel">
          <IconButton color="primary" size="small" aria-label="Export to excel">
            <ExcelIcon />
          </IconButton>
        </Tooltip>
      </CSVLink>

      {getFrequency() === 'CLASS' && (
        <div className={classes.classNavigator}>{getClassNavigator()}</div>
      )}

      {state.chartDataList.length > 0 && (
        <ReactEcharts
          ref={chartRef}
          option={getCharOptions()}
          showLoading={state.chartLoading}
          style={{
            width: '100%',
            height: getChartHeight(),
          }}
          theme="infographic"
          onEvents={{
            click: e => {
              navigateToChild(e.name);
            },
          }}
        />
      )}
    </React.Fragment>
  );
};

ChartViewer.propTypes = {
  title: PropTypes.string,
  chartType: PropTypes.string,
  analysisCriteria: PropTypes.arrayOf(PropTypes.object),
  classLabels: PropTypes.arrayOf(PropTypes.string),
  lookupLabelSet: LookupLabelSetDef.isRequired,
  enqueueError: PropTypes.func,
};

ChartViewer.defaultProps = {
  onChange: () => {},
};

const withConnect = connect(
  state => ({
    lookupLabelSet: state.common.lookupLabelSet,
  }),
  dispatch => ({
    enqueueError: bindActionCreators(enqueueError, dispatch),
  }),
);

export default withConnect(ChartViewer);
