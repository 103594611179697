import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import produce from 'immer';
import * as yup from 'yup';
import {
  FormControl,
  TextField,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  IconButton,
  FormHelperText,
  Tooltip,
} from '@material-ui/core';
import uuid from 'uuid/v4';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles, createStyles } from '@material-ui/styles';
import _ from 'lodash';
import { Label } from '@material-ui/icons';
import BaseDialog from '../../../components/dialogs/BaseDialog';
import { useForm } from '../../../utils/hooks';
import {
  LookupDataSetDef,
  LookupLabelSetDef,
} from '../../../components/propTypeDefs';
import LookupMultiplesSelect from '../../newIncident/LookupMultiplesSelect';
import { LookupTypeKey } from '../../../constants';
import { buildLookupPath } from '../../../utils/lookupTreeUtil';
import HelpButton from '../../../components/buttons/HelpButton';

const useStyles = makeStyles(theme =>
  createStyles({
    whatHappen: {
      display: 'flex',
      // flexDirection: 'row',
      alignItems: 'center',
      // height: 80,
      flexWrap: 'wrap',
      marginTop: theme.spacing(2),
    },
    lineItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      // height: 80,
      flexWrap: 'wrap',
    },
    addUserDialog: {
      width: '80%',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 180,
      // width: '100%',
    },
    formControlShort: {
      margin: theme.spacing(1),
      width: 150,
      // width: '100%',
    },
    formControlLong: {
      margin: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        minWidth: 400,
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    formControlFull: {
      margin: theme.spacing(1),
      width: '100%',
    },
    itemButton: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    deleteButton: {
      marginLeft: theme.spacing(1),
      backgroundColor: theme.palette.error.main,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
    error: {
      color: theme.palette.error.main,
      marginLeft: theme.spacing(1),
      ...theme.typography.caption,
    },
  }),
);

const NotificationAddDlg = props => {
  const classes = useStyles();
  const {
    notificationItem,
    onClose,
    onAdd,
    onEdit,
    isEdit,
    lookupLabelSet,
    lookupDataSet,
    ...other
  } = props;

  const schema = yup.object().shape({
    name: yup
      .string()
      .max(100)
      .required('Email Subject Heading is a required.'),
    recipientEmails: yup
      .array()
      .min(1, 'Please add at least one email to be notified.')
      .of(yup.string().email('Please enter a valid email.'))
      .required('At least one email address is required.'),
    lookupClasses: yup
      .array()
      .min(1, 'Please add at least one event to be notified.')
      .of(
        yup.object().shape({
          classLookupId: yup
            .string()
            .length(36)
            .required('Please select an incident class.'),
          eventTypeList: yup
            .string()
            .min(1)
            .required('Please select at least one event type.'),
        }),
      ),
  });

  const [
    lookupClassesValidationErrors,
    setLookupClassesValidationErrors,
  ] = React.useState(undefined);

  const {
    formValues,
    updateFormValues,
    hasFormError,
    formHelpText,
    handleFormChange,
    handleFormBlur,
    handleFormSubmit,
    formErrors,
  } = useForm({
    validationSchema: schema,
    onSubmit: (values, errors) => {
      if (_.isEmpty(errors)) {
        props.isEdit ? props.onEdit(values) : props.onAdd(values);
      } else {
        const validationErrors = {};
        Object.keys(errors).forEach(key => {
          const index = key.match(/\d+/g) && key.match(/\d+/g).map(Number);
          validationErrors[index] = errors[key];
        });

        setLookupClassesValidationErrors(validationErrors);
      }
    },
  });

  React.useEffect(() => {
    const {
      id,
      name,
      lookupClasses,
      recipientEmails: emailList,
    } = props.notificationItem;

    updateFormValues({
      id,
      name,
      lookupClasses:
        lookupClasses.map(x => {
          return { ...x, id: uuid() }; // add id field for react array key
        }) || [],
      recipientEmails: emailList || [],
    });
  }, []);

  const inputHandleChange = event => {
    setLookupClassesValidationErrors(undefined);
    handleFormChange(event);
  };

  const emailHandleChange = event => {
    const { name, type, value } = event.target;
    let emails = [];
    if (value) {
      emails = value.split(',').map(x => x.trim());
    }
    const newEvent = {
      target: { name, type, value: emails },
    };
    event.target.value = handleFormChange(newEvent);
  };

  const handleSubmit = () => {
    handleFormSubmit();
  };

  const handleChangeLookups = index => classLookupId => {
    const lookupClasses = produce(formValues.lookupClasses, draft => {
      const lookupClass = draft[index];
      lookupClass.classLookupId = classLookupId;
    });
    setLookupClassesValidationErrors(undefined);
    updateFormValues({ lookupClasses });
  };

  const switchHandleChange = (index, type) => event => {
    const lookupClasses = produce(formValues.lookupClasses, draft => {
      const lookupClass = draft[index];
      let types = lookupClass.eventTypeList
        ? lookupClass.eventTypeList.split(',').filter(x => x)
        : [];
      if (event.target.checked) {
        types.push(type);
      } else {
        types = types.filter(x => x !== type);
      }
      lookupClass.eventTypeList = _.uniq(types).join(',');
    });
    setLookupClassesValidationErrors(undefined);
    updateFormValues({ lookupClasses });
  };

  const checkEventType = (index, type) => {
    const lookupClass = formValues.lookupClasses[index];
    return lookupClass.eventTypeList.includes(type);
  };

  const debounceHandleSubmit = _.debounce(handleSubmit, 200);

  const addNewClass = () => {
    const lookupClasses = produce(formValues.lookupClasses, draft => {
      draft.push({
        id: uuid(),
        classLookupId: undefined,
        eventTypeList: 'CREATE',
      });
    });
    setLookupClassesValidationErrors(undefined);
    updateFormValues({ lookupClasses });
  };

  const deleteClass = index => {
    const lookupClasses = produce(formValues.lookupClasses, draft => {
      draft.splice(index, 1);
    });
    setLookupClassesValidationErrors(undefined);
    updateFormValues({ lookupClasses });
  };

  const handleEmailValues = emailValues => {
    if (Array.isArray(emailValues)) {
      return emailValues.join(', ');
    }
    return emailValues;
  };

  const hasEmailErrors = () => {
    const emailErrorFunc = () => {
      const keys = Object.keys(formErrors);
      return keys && keys.filter(x => x.includes('recipientEmails')).length > 0;
    };
    return hasFormError('recipientEmails', emailErrorFunc);
  };
  const emailHelpText = () => {
    const emailErrorTextFunc = () => {
      const keys = Object.keys(formErrors).filter(x =>
        x.includes('recipientEmails'),
      );
      return keys.map(x => formErrors[x]).join(', ');
    };
    return formHelpText('recipientEmails', emailErrorTextFunc);
  };

  return (
    <BaseDialog
      {...other}
      maxWidth="lg"
      title={props.isEdit ? 'Edit notification' : 'Add notification'}
      onClose={onClose}
      actionContent={
        <>
          <Button
            variant="contained"
            onClick={debounceHandleSubmit}
            color="primary"
          >
            Save
          </Button>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
        </>
      }
    >
      <div className={classes.lineItem}>
        <FormControl className={classes.formControlLong}>
          <TextField
            required
            name="name"
            label="Email Subject Heading"
            value={formValues.name || ''}
            onChange={inputHandleChange}
            margin="none"
            onBlur={handleFormBlur}
            InputLabelProps={{
              shrink: true,
            }}
            error={hasFormError('name')}
            helperText={formHelpText('name')}
            inputProps={{ autoComplete: 0 }}
          />
        </FormControl>
        <HelpButton
          popupId="help-expansion-card-email-notification-subject-heading"
          message="Add the subject heading for the email notification."
        />
      </div>

      <div className={classes.lineItem}>
        <FormControl className={classes.formControlFull}>
          <TextField
            required
            name="recipientEmails"
            label="Emails"
            value={handleEmailValues(formValues.recipientEmails)}
            placeholder="Enter multiple email addresses, separated by commas. E.g. hello@test.com, world@test.com"
            onChange={emailHandleChange}
            margin="none"
            onBlur={handleFormBlur}
            InputLabelProps={{
              shrink: true,
            }}
            error={hasEmailErrors()}
            helperText={emailHelpText()}
            inputProps={{ autoComplete: 0 }}
          />
        </FormControl>
      </div>

      <div className={classes.whatHappen}>
        <Typography variant="h6">What</Typography>
        <HelpButton
          popupId="help-expansion-card-email-notification-what"
          message="Specify the class/category/subcategory/type that will generate an email notification. Select the first level or drill down as far as required."
        />
        <Tooltip
          title="Add another email notification to go to the same list of recipients."
          placement="right"
        >
          <IconButton
            color="primary"
            aria-label="Add"
            onClick={() => {
              addNewClass();
            }}
            size="small"
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </div>
      {formValues.lookupClasses &&
        formValues.lookupClasses.map((lookupClass, index) => {
          return (
            <FormControl
              key={lookupClass.id}
              className={classes.lineItem}
              error={
                lookupClassesValidationErrors &&
                lookupClassesValidationErrors[index]
              }
            >
              <Typography variant="h6">{`${index + 1}: `}</Typography>
              {/* <div className={classes.searchFields}> */}
              <LookupMultiplesSelect
                required
                labels={props.lookupLabelSet[LookupTypeKey.incidentClass]}
                lookups={props.lookupDataSet[LookupTypeKey.incidentClass]}
                onChange={handleChangeLookups(index)}
                defaultValue={lookupClass.classLookupId}
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={checkEventType(index, 'CREATE')}
                    onChange={switchHandleChange(index, 'CREATE')}
                    value="CREATE"
                  />
                }
                label={<Typography variant="body1">CREATE</Typography>}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={checkEventType(index, 'EDIT')}
                    onChange={switchHandleChange(index, 'EDIT')}
                    value="EDIT"
                  />
                }
                label={<Typography variant="body1">EDIT</Typography>}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={checkEventType(index, 'CLOSE')}
                    onChange={switchHandleChange(index, 'CLOSE')}
                    value="CLOSE"
                  />
                }
                label={<Typography variant="body1">CLOSE</Typography>}
              />

              <IconButton
                onClick={() => {
                  deleteClass(index);
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
              <FormHelperText>
                {lookupClassesValidationErrors &&
                  lookupClassesValidationErrors[index]}
              </FormHelperText>
            </FormControl>
          );
        })}
    </BaseDialog>
  );
};

NotificationAddDlg.defaultProps = {};

NotificationAddDlg.propTypes = {
  isEdit: PropTypes.bool,
  notificationItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    lookupClasses: PropTypes.arrayOf(
      PropTypes.shape({
        classLookupId: PropTypes.string,
        eventTypeList: PropTypes.string,
      }),
    ),
    recipientEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  lookupDataSet: LookupDataSetDef.isRequired,
  lookupLabelSet: LookupLabelSetDef.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

const mapStateToProps = ({ common }) => ({
  lookupLabelSet: common.lookupLabelSet,
  lookupDataSet: common.lookupDataSet,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationAddDlg);
