import BaseApi from './BaseApi';

class CompanyApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl += '/companies';
  }

  async search(name) {
    return await this.get(`${this.baseUrl}/search?name=${name}`);
  }

  async getOwnerCompany() {
    const res = await this.get(`${this.baseUrl}/owner`);
    return res.data;
  }

  async duplicationCheck(name) {
    return await this.post(`${this.baseUrl}/duplicationCheck`, {
      name,
    });
  }
}

export default new CompanyApi();
