import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Paper, Button } from '@material-ui/core';

import { NotificationDef } from '../components/propTypeDefs';
import { loadNotifications, dismissNotification } from '../modules/common';
import { NotificationType } from '../constants';
import history from '../history';
import Auth from '../auth/Auth';

const useStyles = makeStyles(theme => ({
  root: {},
  alertBar: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    display: 'flex',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    fontWeight: '600',
    alignItems: 'center',
  },
  message: {
    flexGrow: 1,
    textAlign: 'center',
  },
  actions: {
    flexGrow: 0,
    marginLeft: theme.spacing(2),
  },
  rightButton: {
    marginLeft: theme.spacing(1),
  },
}));

const NotificationBar = props => {
  const classes = useStyles();

  const handleDismiss = id => () => {
    props.dismissNotification(id);
  };

  const handleSubscription = () => {
    history.push({
      pathname: '/administration',
      state: { subRoute: 'subscription' },
    });
  };

  const renderActionButton = notification => {
    if (!notification) return null;

    switch (notification.notificationType) {
      case NotificationType.paymentError:
      case NotificationType.trialExpired:
        return Auth.currentUser.admin ||
          Auth.hasPrivilege(['SUBSCRIPTION_EDIT']) ? (
          <Button
            onClick={handleSubscription}
            variant="contained"
            size="small"
            color="secondary"
            className={classes.rightButton}
          >
            Go to Subscription
          </Button>
        ) : null;
      default:
        return null;
    }
  };

  React.useEffect(() => {
    props.loadNotifications();
  }, []);

  return (
    <div className={classes.root}>
      {props.notifications.map(n => (
        <Paper key={n.id} className={classes.alertBar}>
          <div className={classes.message}>{n.message}</div>
          <div className={classes.actions}>
            {renderActionButton(n)}
            {n.userDismissAvailable && (
              <Button
                onClick={handleDismiss(n.id)}
                variant="text"
                color="inherit"
                size="small"
                className={classes.rightButton}
              >
                Dismiss
              </Button>
            )}
          </div>
        </Paper>
      ))}
    </div>
  );
};

NotificationBar.propTypes = {
  notifications: PropTypes.arrayOf(NotificationDef).isRequired,
  loadNotifications: PropTypes.func.isRequired,
  dismissNotification: PropTypes.func.isRequired,
};

const mapStateToProps = ({ common }) => ({
  notifications: common.notifications,
});
const mapDispatchToProps = {
  loadNotifications,
  dismissNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBar);
