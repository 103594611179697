import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper, Box } from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import BillingInformation from './BillingInformation';
import { enqueueError, enqueueSuccess } from '../../../modules/global';

const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  root: {
    width: '100%',
  },
  paper: {
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    // textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    alignItems: 'center',
    // height: 80,
    flexWrap: 'wrap',
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  subscriptionItem: {
    padding: theme.spacing(1),
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardHeaderActive: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  selectedTier: {
    border: theme.palette.primary.main,
    borderWidth: '2px',
    borderStyle: 'solid',
  },
  planLabel: {
    display: 'flex',
  },
  planChange: {
    display: 'flex',
    flexDirection: 'row',
    // alignItems: 'center',
    // flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  cancelButton: {
    marginLeft: theme.spacing(1),
    color: 'white',
    backgroundColor: theme.palette.error.main,
  },
  prevButton: {
    marginRight: theme.spacing(1),
  },
}));

const SubscriptionManagement = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box display="flex" flexDirection="column" width="100%">
        <Paper className={classes.paper}>
          <BillingInformation company={props.company} />
        </Paper>
      </Box>
    </div>
  );
};
SubscriptionManagement.propTypes = {
  enqueueError: PropTypes.func.isRequired,
};

SubscriptionManagement.defaultProps = {};

const mapStateToProps = ({ administration, common }) => ({
  company: administration.company.company,
  loaded: administration.subscriptions.loaded,
  companySettings: common.companySettings,
});
const mapDispatchToProps = {
  enqueueError,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubscriptionManagement);
