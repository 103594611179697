import BaseApi from './BaseApi';

class MfaApi extends BaseApi {
  constructor() {
    super();
  }

  async getDevice(token) {
    return await this.get(`${this.baseUrl}/mfa/device`);
  }

  async sendOTP(otp) {
    return await this.get(`${this.baseUrl}/mfa/sendOTP`);
  }

  async sendOTPForEnrol(otp) {
    return await this.get(`${this.baseUrl}/mfa/sendOTPForEnrol`);
  }

  async verifyOTP(deviceId, otp) {
    return await this.post(`${this.baseUrl}/mfa/verifyOTP`, { deviceId, otp });
  }

  async startEnrol(phoneNumber) {
    return await this.post(`${this.baseUrl}/mfa/startEnrol`, { phoneNumber });
  }

  async verifyEnrol(otp) {
    return await this.post(`${this.baseUrl}/mfa/verifyEnrol`, { otp });
  }
}

export default new MfaApi();
