import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import FormControl from '@material-ui/core/FormControl';
import { TextField, IconButton } from '@material-ui/core';
import { SaveAlt as SaveAltIcon, Save as SaveIcon } from '@material-ui/icons';
import uuid from 'uuid/v4';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';

// import Back from './common/Back';
import { makeStyles } from '@material-ui/styles';
import PeriodSelector from './PeriodSelector';
import PeriodItem from './PeriodItem';
import FrequencySelector from './FrequencySelector';
import FrequencyCriteria from './FrequencyCriteria';
import ChartTypeSelector, { getChartTypeInfo } from './ChartTypeSelector';
import ChartTypeCriteria from './ChartTypeCriteria';
import DefaultLayout from '../DefaultLayout';
import { getInitData } from '../../modules/common';
import LookupSelector from './LookupSelector';
import { LookupTypeKey, CustomReportTypeEnum } from '../../constants';
import LookupItem from './LookupItem';
import { getDescendantLookupIds } from '../../utils/lookupTreeUtil';
import { LookupDataSetDef } from '../../components/propTypeDefs';
import {
  saveCustomAnalysis,
  updateCustomAnalysis,
  saveReportAnalysis,
  updateReportAnalysis,
  resetAnalyze,
} from './ducks';
import ChartViewer from './ChartViewer';
import ReportTitleCriteria from './ReportTitleCriteria';

const monthNames = moment.monthsShort();
monthNames.unshift('Year');

numeral.defaultFormat('0,000');

const useStyles = makeStyles(theme => ({
  grid: {
    margin: `0 ${theme.spacing(2)}px`,
  },
  smallContainer: {
    width: '60%',
  },
  bigContainer: {
    width: '100%',
  },
  stepContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  stepGrid: {
    width: '80%',
  },
  leftButton: {
    marginRight: theme.spacing(1),
  },
  outlinedButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing(1),
  },
  stepper: {
    backgroundColor: 'transparent',
  },
  topInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // marginBottom: 42,
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  borderColumn: {
    borderBottom: `1px solid ${theme.palette.grey['100']}`,
    paddingBottom: 24,
    marginBottom: 24,
  },
  flexBar: {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
    // width: 1200,
  },
  reportPaper: {
    padding: theme.spacing(1),
    // color: theme.palette.text.secondary,
    maxWidth: 1000,
    width: 1000,
    height: 600,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    position: 'relative',
  },
  reportActions: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
  reportWrapper: {
    width: '90%',
    height: '90%',
    alignSelf: 'center',
  },
  report: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: 'grey',
  },
  buttonIcon: {
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
  reportButton: {
    marginLeft: theme.spacing(1),
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  lineItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  stepperContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  printButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));

const getSteps = () => {
  return [
    'Period',
    'Frequency',
    'Incident class',
    'Location',
    'Report type',
    'Generate',
  ];
};

const convertLookupChildrenList = (idListName, options, lookups) => {
  return options.map(({ allClass, includeDescendant, lookupId }) => {
    if (allClass) {
      return { allClass: true, [idListName]: [] };
    }
    const ids = [];
    ids.push(lookupId);
    includeDescendant && ids.push(...getDescendantLookupIds(lookups, lookupId));
    return { [idListName]: ids };
  });
};

// const title = <SectionHeader title="Search" subtitle="" />;
const createCriteriaModel = (
  period,
  frequency,
  chartType,
  incidentClasses,
  locations,
  lookupSet,
) => {
  const {
    periodName,
    fromDate,
    toDate,
    id: periodId,
    type,
    days,
    lastNMonths,
  } = period;
  const classCriteria = incidentClasses.length
    ? {
        values: convertLookupChildrenList(
          'classIdList',
          incidentClasses,
          lookupSet[LookupTypeKey.incidentClass],
        ),
        logicalOperation: 'OR',
      }
    : undefined;
  const locationCriteria = locations.length
    ? {
        values: convertLookupChildrenList(
          'locationIdList',
          locations,
          lookupSet[LookupTypeKey.location],
        ),
        logicalOperation: 'OR',
      }
    : undefined;
  return {
    periodName,
    period: {
      id: periodId || uuid(),
      type,
      days,
      lastNMonths,
      fromDate,
      toDate,
    },
    frequency,
    chartType,
    timezoneOffset: new Date().getTimezoneOffset(),
    classCriteria,
    locationCriteria,
  };
};

const Analyze = props => {
  const classes = useStyles();
  // get selected item for edit mode
  const { selectedItem, itemType } = props.location.state || {};
  let defaultState = {
    periods: [],
    frequency: undefined,
    incidentClasses: [],
    locations: [],
    activeStep: 0,
    chartType: undefined,
    reportTitle: undefined,
    chartDataSet: undefined,
    reportGenerated: false,
    criteriaList: undefined,
  };

  if (selectedItem) {
    defaultState = {
      ...defaultState,
      selectedItem,
      itemType,
      chartType: selectedItem.chartType,
      reportTitle: selectedItem.name,
      periods: selectedItem.criteria.map(x => {
        return { ...x.period, periodName: x.periodName };
      }),
      frequency: _.first(selectedItem.criteria).frequency,
      incidentClasses: selectedItem.incidentClasses || [],
    };
  }
  const [state, setState] = React.useState(defaultState);
  const steps = getSteps();
  const { activeStep, reportGenerated, reportKey } = state;
  const chartRef = React.useRef();

  const selectedChartType = getChartTypeInfo(state.chartType);

  const handleNext = () => {
    setState(prevState => {
      let reportTitle = prevState.reportTitle;
      if (prevState.activeStep + 1 === 5 && !reportTitle) {
        reportTitle = 'My analysis';
      }
      return {
        ...prevState,
        reportTitle,
        activeStep: prevState.activeStep + 1,
      };
    });
  };

  const getCriteriaList = () => {
    const { periods, frequency, incidentClasses, locations, chartType } = state;
    return periods.map(x =>
      createCriteriaModel(
        x,
        frequency,
        chartType,
        incidentClasses,
        locations,
        props.lookupDataSet,
      ),
    );
  };

  const generateReport = () => {
    setState({
      ...state,
      reportGenerated: true,
      criteriaList: getCriteriaList(),
    });
  };

  const handleBack = () => {
    setState(prevState => ({
      ...prevState,
      activeStep: prevState.activeStep - 1,
      reportGenerated: false,
    }));
  };

  const clearCriteria = () => {
    setState({
      periods: [],
      frequency: undefined,
      incidentClasses: [],
      locations: [],
      activeStep: 0,
      chartType: undefined,
      reportTitle: undefined,
      chartDataSet: undefined,
      reportGenerated: false,
      criteriaList: undefined,
    });
  };

  const nextDisabled = () => {
    if (state.activeStep === 0) {
      return state.periods.length === 0;
      // return !!criteria.period;
    }
    if (state.activeStep === 1) {
      return !state.frequency;
    }
    if (state.activeStep === 2) {
      return false;
    }
    if (state.activeStep === 3) {
      return false;
    }
    if (state.activeStep === 4) {
      return !state.chartType;
    }
    if (state.activeStep === 5) {
      return true;
    }

    return true;
  };

  const addPeriod = period => {
    const {
      id,
      type,
      days,
      lastNMonths,
      periodName,
      fromDate,
      toDate,
    } = period;

    state.periods.push({
      id,
      type,
      days,
      lastNMonths,
      periodName,
      fromDate: moment(fromDate).toISOString(),
      toDate: moment(toDate).toISOString(),
    });

    setState({ ...state, periods: state.periods });
  };

  const deletePeriod = id => {
    const periods = state.periods.filter(period => period.id !== id);
    setState({ ...state, periods, activeStep: 0 });
  };

  const moveToPeriod = () => {
    setState({ ...state, activeStep: 0 });
  };

  const onFrequencyChanged = frequency => {
    setState({ ...state, frequency });
  };

  const moveToFrequency = () => {
    setState({ ...state, activeStep: 1 });
  };

  const moveToTitle = () => {
    setState({ ...state, activeStep: 5 });
  };

  const onChartTypeChanged = chartType => {
    setState({ ...state, chartType });
  };

  const moveToChartType = () => {
    setState({ ...state, activeStep: 4 });
  };

  const addIncidentClass = incidentClass => {
    const incidentClasses = state.incidentClasses;
    incidentClasses.push(incidentClass);
    setState({ ...state, incidentClasses });
  };

  const moveToIncidentClass = () => {
    setState({ ...state, activeStep: 2 });
  };

  const deleteIncidentClass = id => {
    const incidentClasses = state.incidentClasses.filter(
      incidentClass => incidentClass.id !== id,
    );
    setState({ ...state, incidentClasses, activeStep: 2 });
  };

  const addLocation = location => {
    const locations = state.locations;
    locations.push(location);
    setState({ ...state, locations });
  };

  const moveToLocation = () => {
    setState({ ...state, activeStep: 3 });
  };

  const deleteLocation = id => {
    const locations = state.locations.filter(location => location.id !== id);
    setState({ ...state, locations, activeStep: 3 });
  };

  const inputHandleChange = event => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const destructureChartData = data => {
    const legend = data.map(x => x.periodName);
    const category = data[0].data.map(x => x.period);
    const extractData = period => period.data.map(x => x.count);
    const series = data.map(x => extractData(x));
    return { legend, category, series };
  };

  const handleSaveCustom = id => {
    if (id) {
      props.updateCustomAnalysis({
        id,
        name: state.reportTitle,
        criteria: getCriteriaList(),
        chartType: state.chartType,
        incidentClasses: state.incidentClasses,
      });
    } else {
      props.saveCustomAnalysis({
        id: uuid(),
        name: state.reportTitle,
        criteria: getCriteriaList(),
        chartType: state.chartType,
        incidentClasses: state.incidentClasses,
      });
    }
  };

  const handleSaveReport = id => {
    if (id) {
      props.updateReportAnalysis({
        id: id || uuid(),
        name: state.reportTitle,
        criteria: getCriteriaList(),
        incidentClasses: state.incidentClasses,
        chartType: state.chartType,
      });
    } else {
      props.saveReportAnalysis({
        id: uuid(),
        name: state.reportTitle,
        criteria: getCriteriaList(),
        incidentClasses: state.incidentClasses,
        chartType: state.chartType,
      });
    }
  };

  const handleUpdate = () => {
    if (state.itemType === CustomReportTypeEnum.dashboardAnalysis) {
      handleSaveCustom(state.selectedItem.id);
    } else {
      handleSaveReport(state.selectedItem.id);
    }
  };

  const generateDisabled = () => {
    if (state.periods.length === 0) return true;
    if (!state.frequency) return true;
    if (!state.chartType) return true;
    if (!state.reportTitle) return true;
    if (state.incidentClasses.length === 0 && state.locations.length === 0)
      return false;

    return false;
  };

  React.useEffect(() => {
    props.getInitData();

    return () => {
      props.resetAnalyze();
    };
  }, []);

  return (
    <DefaultLayout title={null}>
      <div className={classes.stepperContainer}>
        <Stepper
          classes={{ root: classes.stepper }}
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map(label => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      <div className={classes.lineItem}>
        {activeStep === 0 && (
          <Paper className={classes.paper}>
            <div className={classes.topInfo}>
              <div>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: 'bold' }}
                  gutterBottom
                >
                  Date occurred
                </Typography>
              </div>
            </div>
            <PeriodSelector
              onPeriodAdded={addPeriod}
              dateFormat={props.companySettings.dateFormat}
            />
          </Paper>
        )}
        {activeStep === 1 && (
          <Paper className={classes.paper}>
            <div>
              <div>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: 'bold' }}
                  gutterBottom
                >
                  Frequency
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Select frequency or class
                </Typography>
              </div>
              <FrequencySelector
                frequency={state.frequency}
                onChange={onFrequencyChanged}
              />
            </div>
          </Paper>
        )}
        {activeStep === 2 && (
          <Paper className={classes.paper}>
            <div className={classes.topInfo}>
              <div>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: 'bold' }}
                  gutterBottom
                >
                  Incident class
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Select incident class
                </Typography>
              </div>
            </div>
            <LookupSelector
              onLookupAdded={addIncidentClass}
              lookupType={LookupTypeKey.incidentClass}
            />
          </Paper>
        )}
        {activeStep === 3 && (
          <Paper className={classes.paper}>
            <div className={classes.topInfo}>
              <div>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: 'bold' }}
                  gutterBottom
                >
                  Location
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Select location
                </Typography>
              </div>
            </div>
            <LookupSelector
              onLookupAdded={addLocation}
              lookupType={LookupTypeKey.location}
            />
            {/* <LocationSelector onLocationAdded={addLocation} /> */}
          </Paper>
        )}
        {activeStep === 4 && (
          <Paper className={classes.paper}>
            <div>
              <div>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: 'bold' }}
                  gutterBottom
                >
                  Report type
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Select report type
                </Typography>
              </div>
              <ChartTypeSelector
                chartType={state.chartType}
                onChange={onChartTypeChanged}
              />
            </div>
          </Paper>
        )}
        {activeStep === 5 && (
          <Paper className={classes.paper}>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: 'bold' }}
                  gutterBottom
                >
                  Title and generate
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Enter title and generate analysis report.
                </Typography>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="reportTitle"
                    label="Title"
                    value={state.reportTitle}
                    autoFocus
                    onChange={inputHandleChange}
                    margin="none"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        )}
      </div>
      <Grid container justify="center">
        <Grid
          spacing={1}
          alignItems="center"
          justify="center"
          container
          className={classes.grid}
        >
          <div className={classes.bigContainer} />

          <Grid
            item
            // xs={12}
            // alignItems="center"
            justify="center"
            container
            className={classes.grid}
          >
            <div className={classes.stepContainer}>
              <div className={classes.flexBar}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.leftButton}
                  size="large"
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  size="large"
                  disabled={nextDisabled()}
                  className={classes.leftButton}
                >
                  Next
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={generateReport}
                  size="large"
                  disabled={generateDisabled()}
                >
                  Generate
                </Button>
                <Button onClick={clearCriteria} size="large">
                  Clear
                </Button>
              </div>

              <div className={classes.bigContainer}>
                <Paper className={classes.paper}>
                  <PeriodItem
                    periods={state.periods}
                    onDelete={deletePeriod}
                    onClick={moveToPeriod}
                    dateFormat={props.companySettings.dateFormat}
                  />
                  <FrequencyCriteria
                    frequency={state.frequency}
                    onClick={moveToFrequency}
                  />
                  <LookupItem
                    lookupType={LookupTypeKey.incidentClass}
                    lastLookupIds={state.incidentClasses}
                    onDelete={deleteIncidentClass}
                    onClick={moveToIncidentClass}
                  />
                  <LookupItem
                    lookupType={LookupTypeKey.location}
                    lastLookupIds={state.locations}
                    onDelete={deleteLocation}
                    onClick={moveToLocation}
                  />
                  <ChartTypeCriteria
                    chartType={state.chartType}
                    onClick={moveToChartType}
                  />
                  <ReportTitleCriteria
                    reportTitle={state.reportTitle}
                    onClick={moveToTitle}
                  />
                </Paper>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            alignItems="center"
            justify="center"
            container
            className={classes.grid}
          >
            {reportGenerated && state.chartType !== 'excel' && (
              <Paper className={classes.reportPaper}>
                <div className={classes.reportActions}>
                  {state.selectedItem && (
                    <Button
                      onClick={e => handleUpdate()}
                      color="primary"
                      variant="contained"
                      className={classes.reportButton}
                    >
                      <SaveIcon className={classes.buttonIcon} />
                      Update
                    </Button>
                  )}
                  <Button
                    onClick={e => handleSaveReport()}
                    color="primary"
                    variant="contained"
                    className={classes.reportButton}
                  >
                    <SaveIcon className={classes.buttonIcon} />
                    Save to reports
                  </Button>
                  <Button
                    onClick={e => handleSaveCustom()}
                    color="primary"
                    variant="contained"
                    className={classes.reportButton}
                  >
                    <SaveIcon className={classes.buttonIcon} />
                    Save to dashboard
                  </Button>
                </div>
                <div className={classes.reportWrapper}>
                  <ChartViewer
                    title={state.reportTitle}
                    chartType={state.chartType || 'bar'}
                    analysisCriteria={state.criteriaList}
                  />
                  {/* <ReactEcharts
                    ref={chartRef}
                    option={getCharOptions()}
                    style={{ width: '100%', height: '100%' }}
                  /> */}
                </div>
              </Paper>
            )}
          </Grid>
        </Grid>
      </Grid>
    </DefaultLayout>
  );
};

Analyze.propTypes = {
  getInitData: PropTypes.func.isRequired,
  lookupDataSet: LookupDataSetDef.isRequired,
  saveCustomAnalysis: PropTypes.func.isRequired,
  updateCustomAnalysis: PropTypes.func.isRequired,
  saveReportAnalysis: PropTypes.func.isRequired,
  updateReportAnalysis: PropTypes.func.isRequired,
  resetAnalyze: PropTypes.func.isRequired,
  location: PropTypes.object,
};
Analyze.defaultProps = {};

const mapStateToProps = ({ administration, common, analyze }) => ({
  companySettings: common.companySettings,
  lookupDataSet: common.lookupDataSet,
  loaded: analyze.loaded,
});

const mapDispatchToProps = {
  getInitData,
  saveCustomAnalysis,
  updateCustomAnalysis,
  saveReportAnalysis,
  updateReportAnalysis,
  resetAnalyze,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Analyze),
);
