import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import DashboardItem from './DashboardItem';

const grayColor = [
  '#999',
  '#777',
  '#3C4858',
  '#AAAAAA',
  '#D2D2D2',
  '#DDD',
  '#b4b4b4',
  '#555555',
  '#333',
  '#a9afbb',
  '#eee',
  '#e7e7e7',
];

const useStyles = makeStyles(theme => ({
  cardCategory: {
    color: grayColor[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1',
    },
  },
}));

const ClosedIncidentCard = props => {
  const classes = useStyles();

  return (
    <DashboardItem>
      <Card>
        <CardActionArea onClick={props.onClick}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Closed Incidents
            </Typography>
            <p className={classes.cardCategory}>My closed incidents</p>
            <h3 className={classes.cardTitle}>{props.count}</h3>
          </CardContent>
        </CardActionArea>
      </Card>
    </DashboardItem>
  );
};

ClosedIncidentCard.propTypes = {
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};
ClosedIncidentCard.defaultProps = {
  onShowData: f => f,
};

export default ClosedIncidentCard;
