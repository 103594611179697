import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, Paper, Button } from '@material-ui/core';
import history from '../../history';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(10),
  },
  content: {
    padding: theme.spacing(4),
  },
  message: {
    margin: theme.spacing(4, 0),
    fontSize: '1.3rem',
    lineHeight: '2.5rem',
  },
  actions: {
    textAlign: 'right',
  },
}));

const SignupCompleted = props => {
  const classes = useStyles();

  const handleLogin = () => {
    history.push('/login');
  };
  return (
    <div className={classes.root}>
      <Paper className={classes.content}>
        {props.emailVerified ? (
          <div className={classes.message}>
            Thank you for signing up for Incident Xpress.
            <br />
            Please click OK button to login.
          </div>
        ) : (
          <div className={classes.message}>
            Thank you for signing up for Incident Xpress. An email has been sent
            to you via Incident Xpress asking you to verify your email address.
            <br />
            Please click on the link in this email to complete the sign up
            process.
          </div>
        )}

        <div className={classes.actions}>
          <Button onClick={handleLogin} variant="contained" color="primary">
            OK
          </Button>
        </div>
      </Paper>
    </div>
  );
};

SignupCompleted.propTypes = {
  emailVerified: PropTypes.bool.isRequired,
};

export default SignupCompleted;
