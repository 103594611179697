import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { SubscriptionDef, PlanDef } from '../../../components/propTypeDefs';
import { enqueueError } from '../../../modules/global';
import {
  DefaultDateFormat,
  AccountSubscriptionTypeEnum,
} from '../../../constants';
import { displayCentAsDollar } from '../../../utils/currencyUtil';

const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    alignItems: 'center',
    // height: 80,
    flexWrap: 'wrap',
    padding: theme.spacing(2),
  },
  lineItem2: {
    display: 'flex',
    // flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
    'white-space': 'pre-line',
  },
  subscriptionItem: {
    padding: theme.spacing(1),
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardHeaderActive: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  selectedPlan: {
    border: theme.palette.primary.main,
    borderWidth: '2px',
    borderStyle: 'solid',
  },
  currentPlan: {
    backgroundColor: '#ffc',
  },
  currentCharge: {
    padding: theme.spacing(1),
    fontWeight: 600,
  },
  alertTextBold: {
    fontSize: 12,
  },
  itemButton: {
    marginLeft: theme.spacing(1),
  },
  concurrentUserSlider: {
    marginTop: theme.spacing(3),
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  planSwitch: {
    display: 'flex',
    alignItems: 'center',
  },
  cancelButton: {
    marginLeft: theme.spacing(1),
    color: 'white',
    backgroundColor: theme.palette.error.main,
  },
}));

const PlanSelector = props => {
  const classes = useStyles();
  // const [plans, setPlans] = React.useState(undefined);
  const { subscription, plans } = props;

  const getSubscriptionPrice = (
    subscriptionPlans,
    periodType,
    concurrentUserCount,
  ) => {
    if (!subscriptionPlans) {
      return 0;
    }

    if (periodType === 'YEAR') {
      return (
        subscriptionPlans.yearly.defaultAmount +
        subscriptionPlans.yearly.additionalAmount *
          Math.ceil((concurrentUserCount - 5) / 10)
      );
    }
    return (
      subscriptionPlans.monthly.defaultAmount +
      subscriptionPlans.monthly.additionalAmount *
        Math.ceil((concurrentUserCount - 5) / 10)
    );
  };

  const [state, setState] = React.useState({
    loading: false,
    savePlanAlertOpen: false,
    planChangeSuccessAlertOpen: false,
    periodType: 'MONTH',
    concurrentUserCount: 5,
    cacelSubscriptionAlertOpen: false,
    savePlanSuccessAlertOpen: false,
  });

  React.useEffect(() => {
    if (!subscription) return;
    setState({
      ...state,
      periodType: subscription.periodType,
      concurrentUserCount: subscription.concurrentUserCount || 5, // set default 5 count if not exist
    });
  }, [subscription]);

  const getSubscriptionDesc = () => {
    if (subscription) {
      if (subscription.subscriptionType === AccountSubscriptionTypeEnum.trial) {
        const trialExpirationDate = moment(subscription.trialExpirationDate);
        if (trialExpirationDate.isBefore(moment())) {
          return `30 day free trial - expired on ${trialExpirationDate.format(
            DefaultDateFormat(props.companySettings.dateFormat).dateMoment,
          )}`;
        }
        return `30 day free trial - expires on ${trialExpirationDate.format(
          DefaultDateFormat(props.companySettings.dateFormat).dateMoment,
        )}`;
      } else if (
        subscription.subscriptionType === AccountSubscriptionTypeEnum.manual
      ) {
        return `MANUAL - ${subscription.concurrentUserCount} concurrent users available.`;
      } else if (
        subscription.subscriptionType === AccountSubscriptionTypeEnum.stripe
      ) {
        if (subscription.cancelled) {
          if (moment(subscription.subscriptionValidDate).isAfter(moment())) {
            return `Subscription has been cancelled and will expire on ${moment(
              subscription.subscriptionValidDate,
            ).format(
              DefaultDateFormat(props.companySettings.dateFormat).dateMoment,
            )}. ${
              subscription.concurrentUserCount
            } concurrent users available.`;
          }
          return `Subscription successfully cancelled.`;
        }

        return `Type: ${
          subscription.periodType === 'MONTH' ? 'MONTHLY' : 'ANNUAL'
        }
        Amount: ${displayCentAsDollar(subscription.amount)} ${
          subscription.taxPercent > 0 ? ' (tax included)' : ''
        }
        Concurrent user count: ${subscription.concurrentUserCount ?? 0}
        Next charge date: ${moment(subscription.nextChargeDate).format(
          DefaultDateFormat(props.companySettings.dateFormat).dateMoment,
        )}
      `;
      }
    }
    return undefined;
  };

  return (
    <>
      <div>
        <div className={classes.lineItem2}>
          <Typography
            variant="subtitle1"
            color="secondary"
            className={classes.currentCharge}
          >
            {getSubscriptionDesc()}
          </Typography>
        </div>
      </div>
    </>
  );
};

PlanSelector.propTypes = {
  subscription: SubscriptionDef,
  plans: PlanDef,
  enqueueError: PropTypes.func.isRequired,
};
PlanSelector.defaultProps = {};

const mapStateToProps = ({ administration, common }) => ({
  currentSubscription: administration.subscriptions.currentSubscription,
  companySettings: common.companySettings,
  promotionCode: administration.subscriptions.promotionCode,
});
const mapDispatchToProps = {
  enqueueError,
};
export default connect(mapStateToProps, mapDispatchToProps)(PlanSelector);
