import { combineReducers } from 'redux';
import users from './userManagement';
import userGroups from '../../views/administration/userGroupManagement/ducks';
import lookups from './lookupManagement';
import company from '../../views/administration/companyManagement/ducks';
import audits from '../../views/administration/auditManagement/ducks';
import lookup from '../../views/administration/lookupManagement/ducks';
import subscriptions from '../../views/administration/subscriptionManagement2/ducks';
import notifications from '../../views/administration/notificationManagement/ducks';

export default combineReducers({
  users,
  userGroups,
  lookups,
  lookup,
  company,
  audits,
  subscriptions,
  notifications,
});
