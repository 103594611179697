import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import _ from 'lodash';
import lookupApi from '../../../apis/lookup.api';
import { enqueueError } from '../../../modules/global';
import { LookupTypeKey } from '../../../constants';

const RESET = 'administration/lookupManagement/RESET';
const ERROR = 'administration/lookupManagement/ERROR';
const LABEL_LOADED = 'administration/lookupManagement/LABEL_LOADED';
const LABEL_UPDATED = 'administration/lookupManagement/LABEL_UPDATED';
const LABEL_DIALOG_OPEN_SET =
  'administration/lookupManagement/LABEL_DIALOG_OPEN_SET';

// const LOOKUP_SINGLE_LOADED =
//   'administration/lookupManagement/LOOKUP_SINGLE_LOADED';
// const LOOKUP_SINGLE_ADDED =
//   'administration/lookupManagement/LOOKUP_SINGLE_ADDED';
// const LOOKUP_SINGLE_UPDATED =
//   'administration/lookupManagement/LOOKUP_SINGLE_UPDATED';
// const LOOKUP_SINGLE_REMOVED =
//   'administration/lookupManagement/LOOKUP_SINGLE_REMOVED';
// const LOOKUP_MULTIPLE_LOADED =
//   'administration/lookupManagement/LOOKUP_MULTIPLE_LOADED';
// const LOOKUP_MULTIPLE_ADDED =
//   'administration/lookupManagement/LOOKUP_MULTIPLE_ADDED';
// const LOOKUP_MULTIPLE_UPDATED =
//   'administration/lookupManagement/LOOKUP_MULTIPLE_UPDATED';
// const LOOKUP_MULTIPLE_REMOVED =
//   'administration/lookupManagement/LOOKUP_MULTIPLE_REMOVED';

const resetAction = createAction(RESET);
const errorAction = createAction(ERROR);
const multipleLookupLabelsLoadedAction = createAction(LABEL_LOADED);
const updateLabelsAction = createAction(LABEL_UPDATED);
const labelDialogOpenSetAction = createAction(LABEL_DIALOG_OPEN_SET);

const initState = {
  loaded: false,
  labelLoaded: false,
  multipleLookupLabels: {
    [LookupTypeKey.incidentClass]: [],
    [LookupTypeKey.incidentCause]: [],
    [LookupTypeKey.location]: [],
  },
  labelDialogOpen: false,
};

export default handleActions(
  {
    [RESET]: (state, action) =>
      produce(state, draft => {
        draft.loaded = false;
        draft.labelLoaded = false;
      }),
    [ERROR]: (state, action) =>
      produce(state, draft => {
        draft.loaded = true;
      }),
    [LABEL_LOADED]: (state, action) =>
      produce(state, draft => {
        const { type, data } = action.payload;
        draft.multipleLookupLabels[type] = data;
        draft.labelLoaded = true;
      }),
    [LABEL_UPDATED]: (state, action) =>
      produce(state, draft => {
        const { type, labels } = action.payload;
        draft.multipleLookupLabels[type] = labels;
        draft.labelLoaded = true;
        draft.labelDialogOpen = false;
      }),
    [LABEL_DIALOG_OPEN_SET]: (state, action) =>
      produce(state, draft => {
        draft.labelDialogOpen = action.payload;
      }),
  },
  initState,
);

export const queryLookupLabels = params => async dispatch => {
  dispatch(resetAction());
  try {
    const res = await lookupApi.queryMultipleLabels(params);
    dispatch(
      multipleLookupLabelsLoadedAction({ data: res.data, type: params.type }),
    );
  } catch (err) {
    dispatch(enqueueError(err));
  }
};

export const updateLookupLabels = model => async dispatch => {
  dispatch(resetAction());
  try {
    await lookupApi.addMultipleLabel(model);
    dispatch(updateLabelsAction(model));
  } catch (err) {
    dispatch(errorAction());
    dispatch(enqueueError(err));
  }
};

export const setLabelDialogOpen = open => labelDialogOpenSetAction(open);
