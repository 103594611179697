import React from 'react';
import produce from 'immer';
import _ from 'lodash';
import { useTheme } from '@material-ui/styles';
import { isMobile, isIOS } from 'react-device-detect';

export const useImmerReducer = (reducer, initialState) =>
  React.useReducer(produce(reducer), initialState);

export const useFormInput = initialValue => {
  const [value, setValue] = React.useState(initialValue);
  const handleChange = e => {
    setValue(e.target.value);
  };

  return {
    value,
    onChange: handleChange,
  };
};

// @ref : https://www.codebeast.dev/react-forms-then-and-now/
export const useForm = ({ onSubmit, validationSchema }) => {
  const [values, setValues] = React.useState({});
  const [touched, setTouched] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [validated, setValidated] = React.useState(false);

  const validate = (
    validateValues,
    wholeChecked = false,
    options = { abortEarly: false },
  ) => {
    wholeChecked && setValidated(true);
    try {
      validationSchema.validateSync(validateValues, options);
    } catch (ex) {
      const validationError = {};
      if (ex.inner) {
        ex.inner.forEach(e => {
          validationError[e.path] = e.message;
        });
      } else {
        validationError.FORM = ex.message;
      }
      return validationError;
    }
    return {};
  };

  const handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChangeForTwo = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const value2 = target.type === 'checkbox' ? target.checked : target.value2;
    const name2 = target.name2;
    setValues({
      ...values,
      [name]: value,
      [name2]: value2,
    });
  };

  const handleBlur = event => {
    const target = event.target;
    const name = target.name;
    setTouched({
      ...touched,
      [name]: true,
    });
    const e = validate(values);
    setErrors(e);
  };

  const handleSubmit = event => {
    event && event.preventDefault();
    const e = validate(values, true);
    setErrors(e);
    onSubmit(values, e);
  };

  const hasFieldError = (name, customErrorFunc = null) => {
    if (!(touched[name] || validated)) {
      return false;
    }

    if (customErrorFunc) {
      return _.hasIn(errors, name) || customErrorFunc(errors);
    }
    return _.hasIn(errors, name);
  };

  const fieldHelpText = (name, customErrorFunc = null) => {
    if (hasFieldError(name)) {
      return errors[name];
    }
    if (customErrorFunc) {
      return customErrorFunc(errors);
    }
    return undefined;
  };
  const updateValues = (data, changeTouched = false) => {
    const nextValues = Object.assign({}, values, data);
    setValues(nextValues);
    if (changeTouched) {
      const changed = {};
      Object.keys(data).forEach(k => {
        changed[k] = true;
      });
      setTouched({
        ...touched,
        ...changed,
      });
      setErrors(validate(nextValues));
    }
  };

  return {
    formValues: values,
    formTouched: touched,
    formErrors: errors,
    updateFormValues: updateValues,
    handleFormChange: handleChange,
    handleFormChangeForTwo: handleChangeForTwo,
    handleFormSubmit: handleSubmit,
    handleFormBlur: handleBlur,
    hasFormError: hasFieldError,
    formHelpText: fieldHelpText,
  };
};

export const useMediaInfo = ({ minBreakPoint }) => {
  const theme = useTheme();
  const breakPointValues = theme.breakpoints.values;

  const getSmaller = () => {
    const width = window.innerWidth;
    const breakWidth = breakPointValues[minBreakPoint];
    return width < breakWidth;
  };

  const [state, setState] = React.useState({
    isSmaller: getSmaller(),
  });

  const handleResize = () => {
    setState({ ...state, isSmaller: getSmaller() });
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    isSmaller: state.isSmaller,
    isMobile,
    isIOS,
  };
};
