import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { initializeIncidentForm, selectOpenDraft } from './ducks';
import { setSelectedDraft } from '../newIncident/ducks';
import { getInitData } from '../../modules/common';
import { LookupTypeKey } from '../../constants';
import { LookupQueryFilter } from '../../models/helpers/QueryModel';
import IncidentCompanyCard from '../newIncident/IncidentCompanyCard';
import {
  IncidentDef,
  DraftIncidentDef,
  LookupDataSetDef,
} from '../../components/propTypeDefs';
import IncidentContainer from '../newIncident/IncidentContainer';
import AlertDialog from '../../components/dialogs/AlertDialog';

const IncidentFormWrapper = props => {
  const handleConfirmDraft = useDraft => () => {
    props.selectOpenDraft(useDraft);
  };

  const handleSubmitted = () => {
    props.onSubmit();
  };

  // on mounted
  React.useEffect(() => {
    props.getInitData();

    return () => {
      props.setSelectedDraft(null);
    };
  }, []);

  React.useEffect(() => {
    if (props.draftIncident) {
      props.initializeIncidentForm(props.draftIncident);
    }
  }, [props.draftIncident]);

  return (
    <>
      {props.loaded && <IncidentContainer onSubmit={handleSubmitted} />}
      <AlertDialog
        title="Use Draft"
        message="Are you sure you want to use draft?"
        open={props.needConfirmDraft}
        onClose={handleConfirmDraft(false)}
        onCancel={handleConfirmDraft(false)}
        onOk={handleConfirmDraft(true)}
      />
    </>
  );
};

IncidentFormWrapper.propTypes = {
  draftIncident: DraftIncidentDef, // ducks
  initializeIncidentForm: PropTypes.func.isRequired,
  selectOpenDraft: PropTypes.func.isRequired,
  getInitData: PropTypes.func.isRequired,
  setSelectedDraft: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  needConfirmDraft: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
};

IncidentFormWrapper.defaultProps = {
  onSubmit: f => f,
};

const mapStateToProps = ({ incidentView }) => ({
  draftIncident: incidentView.draftIncident,
  needConfirmDraft: incidentView.needConfirmDraft,
  loaded: incidentView.loaded,
});

const mapDispatchToProps = {
  getInitData,
  initializeIncidentForm,
  selectOpenDraft,
  setSelectedDraft,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncidentFormWrapper);
