import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import PaymentMethod from './PaymentMethod';
import PlanSelector from './PlanSelector';
import Plan from './Plan';
import { SubscriptionDef, PlanDef } from '../../../components/propTypeDefs';
import {
  loadSubscription,
  saveSubscription,
  cancelSubscription,
} from './ducks';
import BillingInformation from './BillingInformation';
import HelpButton from '../../../components/buttons/HelpButton';
import { updateBillingInformation } from '../companyManagement/ducks';
import { enqueueError, enqueueSuccess } from '../../../modules/global';
import AlertDialog from '../../../components/dialogs/AlertDialog';
import auth from '../../../auth/Auth';
import moment from 'moment';
import {
  DefaultDateFormat,
  AccountSubscriptionTypeEnum,
} from '../../../constants';

const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  root: {
    width: '100%',
  },
  paper: {
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    // textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    alignItems: 'center',
    // height: 80,
    flexWrap: 'wrap',
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  subscriptionItem: {
    padding: theme.spacing(1),
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardHeaderActive: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  selectedTier: {
    border: theme.palette.primary.main,
    borderWidth: '2px',
    borderStyle: 'solid',
  },
  planLabel: {
    display: 'flex',
  },
  planChange: {
    display: 'flex',
    flexDirection: 'row',
    // alignItems: 'center',
    // flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  cancelButton: {
    marginLeft: theme.spacing(1),
    color: 'white',
    backgroundColor: theme.palette.error.main,
  },
  prevButton: {
    marginRight: theme.spacing(1),
  },
}));

const SubscriptionStep = {
  currentPlan: 'Current Plan',
  selectPlan: 'Select Plan',
  paymentMethod: 'Payment Method',
  billingInformation: 'Billing Information',
};

const SubscriptionManagement = props => {
  const LoadingProgress = props => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt={4}
      >
        <CircularProgress color="secondary" size={100} />
      </Box>
    );
  };

  const classes = useStyles();
  const [state, setState] = React.useState({
    promotionCode: '',
    step: SubscriptionStep.currentPlan,
    periodType: props.subscription ? props.subscription.periodType : 'MONTH',
    concurrentUserCount: props.subscription
      ? props.subscription.concurrentUserCount
      : 5,
    billingInformation: props.company,
    savePlanSuccessAlertOpen: false,
    cacelSubscriptionAlertOpen: false,
    alertLoading: false,
  });

  const billingInformationRef = useRef();

  React.useEffect(() => {
    setState({
      ...state,
      periodType: props.subscription ? props.subscription.periodType : 'MONTH',
      concurrentUserCount: props.subscription
        ? props.subscription.concurrentUserCount
        : 5,
    });
  }, [props.subscription]);

  React.useEffect(() => {
    props.loadSubscription();
  }, []);

  const changePromotionCode = event => {
    const { value } = event.target;
    setState({
      ...state,
      promotionCode: value.toUpperCase(),
      periodType: props.subscription.periodType,
      concurrentUserCount: props.subscription.concurrentUserCount || 5, // set default 5 count if not exist
    });
  };

  const updatePromotionCode = event => {
    if (event.key === 'Enter') {
      props.loadSubscription(state.promotionCode.toUpperCase());
    }
  };

  const updatePromotionCodeOnBlue = event => {
    props.loadSubscription(state.promotionCode.toUpperCase());
  };

  const changeSubscription = async () => {
    if (state.step === SubscriptionStep.currentPlan) {
      setState({
        ...state,
        step: SubscriptionStep.paymentMethod,
      });
    } else if (state.step === SubscriptionStep.paymentMethod) {
      setState({
        ...state,
        step: SubscriptionStep.selectPlan,
      });
    } else if (state.step === SubscriptionStep.selectPlan) {
      setState({
        ...state,
        step: SubscriptionStep.billingInformation,
      });
    } else if (state.step === SubscriptionStep.billingInformation) {
      // save billing information
      if (billingInformationRef) {
        billingInformationRef.current.saveBillingInformation(
          state.billingInformation,
        );
      }
    }
  };

  const getSaveButtonName = () => {
    switch (state.step) {
      case SubscriptionStep.currentPlan:
        return 'Change';
      case SubscriptionStep.paymentMethod:
        return 'Next';
      case SubscriptionStep.selectPlan:
        return 'Next';
      case SubscriptionStep.billingInformation:
        return 'Save';
    }
    return '';
  };

  const saveButtonDisabled = () => {
    if (!props.loaded) {
      return true;
    }
    if (state.step === SubscriptionStep.paymentMethod) {
      if (props.subscription && !props.subscription.cardLast4) {
        return true;
      }
    } else if (state.step === SubscriptionStep.selectPlan) {
      return (
        props.subscription.concurrentUserCount === state.concurrentUserCount &&
        props.subscription.concurrentUserCount !== 0 &&
        props.subscription.periodType === state.periodType &&
        props.subscription.trial === false
      );
    }

    return false;
  };

  const cancelSubscriptionDisabled = () => {
    if (!props.loaded) {
      return true;
    }

    if (props.subscription.trial) {
      return true;
    }

    if (props.subscription.cancelled) {
      return true;
    }

    return state.step !== SubscriptionStep.currentPlan;
  };

  const prevButtonHidden = () => {
    switch (state.step) {
      case SubscriptionStep.paymentMethod:
        return true;
      case SubscriptionStep.currentPlan:
        return true;
      case SubscriptionStep.selectPlan:
        return false;
      case SubscriptionStep.billingInformation:
        return false;
    }
    return '';
  };

  const preButtonClicked = () => {
    switch (state.step) {
      case SubscriptionStep.currentPlan:
        return;
      case SubscriptionStep.paymentMethod:
        return;
      case SubscriptionStep.selectPlan:
        setState({
          ...state,
          step: SubscriptionStep.paymentMethod,
        });
        return;
      case SubscriptionStep.billingInformation:
        setState({
          ...state,
          step: SubscriptionStep.selectPlan,
        });
        return;
    }
  };

  const onChangePeriodType = periodType => {
    setState({
      ...state,
      periodType,
    });
  };
  const onChangeConcurrentUser = value => {
    setState({
      ...state,
      concurrentUserCount: value,
    });
  };

  const cancelChange = () => {
    setState({
      ...state,
      step: SubscriptionStep.currentPlan,
      periodType: props.subscription.periodType || 'MONTH',
      concurrentUserCount: props.subscription.concurrentUserCount || 5,
    });
  };

  const cancelChangeButtonHidden = () => {
    return state.step === SubscriptionStep.currentPlan;
  };

  const onChangeBillingInformation = async billingInformation => {
    try {
      await props.saveSubscription(
        billingInformation,
        state.periodType,
        state.concurrentUserCount,
        state.promotionCode,
      );

      setState({
        ...state,
        step: SubscriptionStep.currentPlan,
        savePlanSuccessAlertOpen: true,
      });
    } catch (err) {
      props.enqueueError(err);
    }
  };

  const handleCancelSubscription = () => {
    setState({ ...state, alertLoading: true });
    props
      .cancelSubscription()
      .then(() => {
        setState({
          ...state,
          alertLoading: false,
          cacelSubscriptionAlertOpen: false,
        });
      })
      .catch(err => {
        props.enqueueError(err);
        setState({ ...state, alertLoading: false });
      });
  };

  const handleCancelSubscriptionDebounce = _.debounce(() => {
    handleCancelSubscription();
  }, 500);

  const subscriptionType =
    props.subscription && props.subscription.subscriptionType;

  return (
    <div className={classes.root}>
      {!props.loaded && <LoadingProgress />}
      <Box display="flex" flexDirection="column" width="100%">
        <Paper className={classes.paper}>
          <div className={classes.planChange}>
            <Typography variant="h4" className={classes.planLabel}>
              {state.step}
              <HelpButton
                popupId="help-subscription-management-2-plan"
                message="Each Incident Xpress system has a minimum of 5 concurrent users to start. If more users are required, you can add 10 additional users at a time. Use the bar below to select the number of users required for your organization."
              />
            </Typography>

            {state.step === SubscriptionStep.selectPlan && (
              <TextField
                className={classes.promotionCode}
                name="promotionCode"
                label="Promotion Code"
                value={state.promotionCode}
                onChange={changePromotionCode}
                onKeyDown={updatePromotionCode}
                onBlur={updatePromotionCodeOnBlue}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ style: { textTransform: 'uppercase' } }}
              />
            )}
          </div>
          {state.step === SubscriptionStep.currentPlan && (
            <Plan subscription={props.subscription} plans={props.plans} />
          )}
          {state.step === SubscriptionStep.selectPlan && (
            <PlanSelector
              subscription={props.subscription}
              plans={props.plans}
              periodType={state.periodType}
              concurrentUserCount={state.concurrentUserCount || 5}
              onChangePeriodType={onChangePeriodType}
              onChangeConcurrentUser={onChangeConcurrentUser}
            />
          )}
          {state.step === SubscriptionStep.paymentMethod && (
            <PaymentMethod subscription={props.subscription} />
          )}
          {state.step === SubscriptionStep.billingInformation && (
            <BillingInformation
              ref={billingInformationRef}
              company={props.company}
              onChangeBillingInformation={onChangeBillingInformation}
            />
          )}

          {/* <PlanSelector subscription={props.subscription} plans={props.plans} /> */}
          {/* <PlanSelector subscription={props.subscription} plans={props.plans} /> */}
        </Paper>
        {subscriptionType !== AccountSubscriptionTypeEnum.manual ? (
          <div>
            <Box display="flex" mt={2} px={4} justifyContent="center">
              {!prevButtonHidden() && (
                <Button
                  className={classes.prevButton}
                  variant="contained"
                  // color="secondary"
                  size="large"
                  onClick={preButtonClicked}
                  disabled={!props.loaded}
                >
                  Prev
                </Button>
              )}

              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={changeSubscription}
                disabled={saveButtonDisabled()}
              >
                {getSaveButtonName()}
              </Button>
              {!cancelChangeButtonHidden() && (
                <Button
                  className={classes.cancelButton}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={cancelChange}
                  disabled={!props.loaded}
                >
                  Cancel
                </Button>
              )}

              <Button
                className={classes.cancelButton}
                variant="contained"
                size="large"
                onClick={() => {
                  setState({ ...state, cacelSubscriptionAlertOpen: true });
                }}
                disabled={cancelSubscriptionDisabled()}
              >
                Cancel subscription
              </Button>
            </Box>
          </div>
        ) : (
          <div>
            To change the number of concurrent users, please contact
            subscription@incidentxpress.com
          </div>
        )}
      </Box>
      <AlertDialog
        title="Logout required"
        message="Subscription plan is successfully changed. Please log out and log in again to apply the new plan."
        open={state.savePlanSuccessAlertOpen}
        cancelButtonHidden
        onClose={() => {
          // do not close by click out of view
        }}
        onOk={() => {
          auth.logout();
        }}
      />
      <AlertDialog
        title="Cancel subscription"
        message={`Are you sure you want to cancel your subscription? If you cancel now, your subscription will expire on ${moment(
          props.subscription && props.subscription.nextChargeDate,
        ).format(
          DefaultDateFormat(props.companySettings.dateFormat).dateMoment,
        )}.`}
        open={state.cacelSubscriptionAlertOpen}
        loading={state.loading}
        buttonDisabled={state.loading}
        onClose={() => {
          // do not close by click out of view
        }}
        onCancel={() => {
          setState({ ...state, cacelSubscriptionAlertOpen: false });
        }}
        onOk={() => {
          handleCancelSubscriptionDebounce();
        }}
      />
    </div>
  );
};
SubscriptionManagement.propTypes = {
  subscription: SubscriptionDef,
  plans: PlanDef,
  loadSubscription: PropTypes.func.isRequired,
  updateBillingInformation: PropTypes.func.isRequired,
  saveSubscription: PropTypes.func.isRequired,
  enqueueError: PropTypes.func.isRequired,
};

SubscriptionManagement.defaultProps = {};

const mapStateToProps = ({ administration, common }) => ({
  subscription: administration.subscriptions.subscription,
  plans: administration.subscriptions.plans,
  company: administration.company.company,
  loaded: administration.subscriptions.loaded,
  companySettings: common.companySettings,
});
const mapDispatchToProps = {
  loadSubscription,
  updateBillingInformation,
  saveSubscription,
  enqueueError,
  cancelSubscription,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubscriptionManagement);
