import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Card,
  CardMedia,
  Typography,
  Button,
} from '@material-ui/core';
import {
  Image as ImageIcon,
  PhotoCamera,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { fade } from '@material-ui/core/styles';
import ImageCropperDialog from '../dialogs/ImageCropperDialog';
import userApi from '../../apis/user.api';
import accountApi from '../../apis/account.api';
import AlertDialog from '../dialogs/AlertDialog';

const useStyles = makeStyles(theme => ({
  root: {
    width: 200,
    height: 200,
    color: '#aaa',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '&:hover > div': {
      visibility: 'visible',
    },
    [theme.breakpoints.down('xs')]: {
      width: 150,
      height: 150,
      marginTop: theme.spacing(1),
    },
  },
  logoMedia: {
    height: 200,
    width: 200,
    backgroundSize: 'contain',
    [theme.breakpoints.down('xs')]: {
      width: 150,
      height: 150,
    },
  },
  logoButtons: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    visibility: 'hidden',
  },
  logoButton: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: fade('#000', 0.5),
    color: '#ccc',
    '&:hover': {
      backgroundColor: fade('#000', 0.5),
      color: '#fff',
    },
  },
  photoIcon: {
    marginRight: theme.spacing(1),
  },
  hidden: {
    display: 'none',
  },
}));

const LogoImageForm = props => {
  const fileRef = React.useRef();
  const classes = useStyles();
  const [state, setState] = React.useState({
    picture: undefined,
    cropOpen: false,
    originFile: null,
    cropFile: null,
  });
  const [deleteLogoDialogOpen, setDeleteLogoDialogOpen] = React.useState(false);

  React.useEffect(() => {
    setState({
      ...state,
      picture: props.logoImage,
    });
  }, [props.logoImage]);

  const handleFileChange = e => {
    const { files } = e.target;
    if (!files || !files.length) return;
    setState({
      ...state,
      cropOpen: true,
      originFile: URL.createObjectURL(files[0]),
    });
  };

  const handleClose = e => {
    setState({ ...state, cropOpen: false, originFile: null, cropFile: null });
    fileRef.current.value = null;
  };
  const handleCrop = async image => {
    try {
      const { data } = await accountApi.uploadLogo(image);
      image &&
        data &&
        setState({
          ...state,
          cropFile: image,
          originFile: null,
          cropOpen: false,
          picture: data,
        });
      props.onChange(data);
    } catch (err) {
      props.onError(err);
    }
    fileRef.current.value = null;
  };

  const onConfirmDeleteLogo = async () => {
    try {
      await accountApi.deleteLogo();
      setState({
        ...state,
        picture: undefined,
        cropOpen: false,
        originFile: null,
        cropFile: null,
      });
    } catch (err) {
      props.onError(err);
    }
    setDeleteLogoDialogOpen(false);
  };

  return (
    <>
      <Card className={classes.root}>
        {state.picture ? (
          <CardMedia
            className={classes.logoMedia}
            image={state.picture}
            title="Logo image"
          />
        ) : (
          <ImageIcon style={{ fontSize: '100px' }} />
        )}
        <div className={classes.logoButtons}>
          <Button
            fullWidth
            valiant="text"
            className={classes.logoButton}
            onClick={() => {
              fileRef.current.click();
            }}
          >
            <PhotoCamera className={classes.photoIcon} />
            <Typography variant="subtitle2">
              {state.picture ? 'Change' : 'Upload'}
            </Typography>
          </Button>
          {state.picture && (
            <Button
              fullWidth
              valiant="text"
              className={classes.logoButton}
              onClick={() => {
                setDeleteLogoDialogOpen(true);
              }}
            >
              <DeleteIcon className={classes.photoIcon} />
              <Typography variant="subtitle2">Delete</Typography>
            </Button>
          )}
        </div>

        <input
          type="file"
          name="file"
          onChange={handleFileChange}
          className={classes.hidden}
          ref={fileRef}
          accept="image/*"
        />
      </Card>
      {state.cropOpen && (
        <ImageCropperDialog
          open={state.cropOpen}
          src={state.originFile}
          options={{ aspect: undefined, guides: false }}
          onCrop={handleCrop}
          onClose={handleClose}
        />
      )}
      {deleteLogoDialogOpen && (
        <AlertDialog
          open={deleteLogoDialogOpen}
          title="Delete logo"
          message="Are you sure you want to delete this logo?"
          onOk={onConfirmDeleteLogo}
          onCancel={() => {
            setDeleteLogoDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

LogoImageForm.propTypes = {
  logoImage: PropTypes.string,
  onChange: PropTypes.func,
  onError: PropTypes.func,
};
LogoImageForm.defaultProps = {
  logoImage: undefined,
  onChange: f => f,
  onError: f => f,
};

export default LogoImageForm;
