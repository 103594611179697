import React from 'react';
import PropTypes from 'prop-types';
import MapIcon from '@material-ui/icons/Map';
import { Button, Typography, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ExpansionCard from '../../components/cards/ExpansionCard';
import {
  ReadableIncidentDef,
  ReadableIncidentCloseDef,
} from '../../components/propTypeDefs';
import RichReader from '../../components/forms/RichReader';
import { labelValueStyle } from '../../theme';

const useStyles = makeStyles(theme => ({
  ...labelValueStyle,
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    flexWrap: 'wrap',
  },
  // label: {
  //   ...theme.typography.body1,
  //   fontWeight: '500',
  //   color: theme.palette.grey[900],
  //   marginBottom: theme.spacing(0.5),
  // },
  // value: {
  //   ...theme.typography.body2,
  //   fontWeight: '400',
  //   width: '100%',
  //   color: theme.palette.grey[700],
  // },
  incidentNumber: {
    margin: '0 8px',
    fontSize: 16,
  },
}));

const IncidentViewClose = props => {
  const classes = useStyles();
  const { expanded, close } = props;

  return (
    <ExpansionCard title="Close" expanded={expanded}>
      <div className={classes.root}>
        <div className={classes.lineItem}>
          <FormControl className={classes.formControl}>
            <div className={classes.label}>Closed by</div>
            <div className={classes.value}>{close.closedBy}</div>
          </FormControl>
        </div>

        <div className={classes.lineItem}>
          {close.closedBy === 'USER' && (
            <FormControl className={classes.formControl}>
              <div className={classes.label}>User name</div>
              <div className={classes.value}>{close.closeUserName}</div>
            </FormControl>
          )}
          {close.closedBy === 'OTHER' && (
            <div>
              <FormControl className={classes.formControl}>
                <div className={classes.label}>First name</div>
                <div className={classes.value}>{close.closeFirstName}</div>
              </FormControl>
              <FormControl className={classes.formControl}>
                <div className={classes.label}>Middle initial</div>
                <div className={classes.value}>{close.closeMiddleName}</div>
              </FormControl>
              <FormControl className={classes.formControl}>
                <div className={classes.label}>Last name</div>
                <div className={classes.value}>{close.closeLastName}</div>
              </FormControl>
              <FormControl className={classes.formControl}>
                <div className={classes.label}>Alias</div>
                <div className={classes.value}>{close.closeAlias}</div>
              </FormControl>
              <FormControl className={classes.formControl}>
                <div className={classes.label}>Department</div>
                <div className={classes.value}>{close.closeDepartment}</div>
              </FormControl>
              <FormControl className={classes.formControl}>
                <div className={classes.label}>Position</div>
                <div className={classes.value}>{close.closePosition}</div>
              </FormControl>
              <FormControl className={classes.formControl}>
                <div className={classes.label}>phone</div>
                <div className={classes.value}>{close.closePhone}</div>
              </FormControl>
              <FormControl className={classes.formControl}>
                <div className={classes.label}>Mobile phone</div>
                <div className={classes.value}>{close.closeMobilePhone}</div>
              </FormControl>
              <FormControl className={classes.formControl}>
                <div className={classes.label}>Email</div>
                <div className={classes.value}>{close.closeEmail}</div>
              </FormControl>
            </div>
          )}
        </div>
        <div className={classes.lineItem}>
          <FormControl className={classes.formControl}>
            <div className={classes.label}>Comments</div>
            <div className={classes.value}>
              <RichReader content={close.closeComments} />
            </div>
          </FormControl>
        </div>
      </div>
    </ExpansionCard>
  );
};

IncidentViewClose.propTypes = {
  expanded: PropTypes.bool,
  close: ReadableIncidentCloseDef.isRequired,
};

IncidentViewClose.defaultProps = {
  expanded: false,
};

export default IncidentViewClose;
