import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Paper, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import moment from 'moment';
import BaseDialog from '../../../components/dialogs/BaseDialog';
import { toReadableAudit } from '../../../utils/auditViewUtil';
import PeriodItem from '../../analyze/PeriodItem';
import FrequencyCriteria from '../../analyze/FrequencyCriteria';
import ChartTypeCriteria from '../../analyze/ChartTypeCriteria';
import LookupItem from '../../analyze/LookupItem';
import {
  DateSearchPeriodType,
  DefaultDateFormat,
  IncidentSearchType,
  LookupTypeKey,
} from '../../../constants';
import { getPersonName } from '../../../utils/nameUtil';
import { buildLookupPath } from '../../../utils/lookupTreeUtil';
import { LookupDef } from '../../../components/propTypeDefs';
import { translateConfig } from '../../../utils/simpleTranslateUtil';

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: 600,
  },
  value: {
    fontWeight: 500,
    whiteSpace: 'pre-line',
  },
  paper: {
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  searchCriteriaTitle: {
    display: 'flex',
    // flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
}));

const AuditAnalyzeViewer = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    readable: {},
  });
  const { auditData } = props;
  const { readable } = state;

  const handleClose = () => {
    props.onClose();
  };

  const getMonthLabel = months => {
    const monthNames = moment.monthsShort();
    let result = '';
    months.forEach(month => {
      result = `${result + monthNames[month - 1]} `;
    });

    return result.trim();
  };

  const getWeekDaysLabel = dayOfWeeks => {
    const weekdaysNames = moment.weekdaysShort();
    let result = '';
    dayOfWeeks.forEach(day => {
      result = `${result + weekdaysNames[day - 1]} `;
    });

    return result.trim();
  };

  const getDateCriteriaName = option => {
    switch (option.periodType) {
      case DateSearchPeriodType.period:
        return `From  ${moment(option.fromDate).format(
          DefaultDateFormat(props.accountDateFormat).dateMoment,
        )}  To  ${moment(option.toDate).format(
          DefaultDateFormat(props.accountDateFormat).dateMoment,
        )}`;
      case DateSearchPeriodType.month:
        return getMonthLabel(option.months);
      case DateSearchPeriodType.dayOfWeek:
        return getWeekDaysLabel(option.dayOfWeeks);
      case DateSearchPeriodType.lastNDays:
        if (option.days === 1) {
          return `Last 1 day`;
        }
        return `Last ${option.days} days`;
      case DateSearchPeriodType.lastNMonths:
        if (option.lastNMonths === 0) {
          return `Current month`;
        }
        if (option.lastNMonths === 1) {
          return `Prvious month`;
        }
        return `Last ${option.lastNMonths} months`;
      default:
        return '';
    }
  };
  const getCriteriaNameHandlers = {
    [IncidentSearchType.dateReported]: getDateCriteriaName,
    [IncidentSearchType.dateOccurred]: getDateCriteriaName,
    [IncidentSearchType.person]: option => {
      const {
        firstName,
        lastName,
        middleName,
        hasPerson,
        isEmployee,
        relations,
      } = option;
      let personName = getPersonName({ firstName, lastName, middleName });

      if (hasPerson) {
        return 'has person';
      }

      if (isEmployee) {
        personName = `${personName} - Employee`;
      }

      if (relations) {
        personName = `${personName} - ${relations.join(', ')}`;
      }
      return personName;
    },
    [IncidentSearchType.company]: option => {
      const { name, hasCompany, isOwner, relations } = option;

      if (hasCompany) {
        return 'has legal entity';
      }

      let companyName = name;

      if (isOwner) {
        companyName = `${name} - Owner`;
      }

      if (relations) {
        companyName = `${companyName} - ${relations.join(', ')}`;
      }

      return companyName;
    },
    [IncidentSearchType.item]: option => {
      const { name, brand, model, color, serialNumber, hasItem } = option;

      if (hasItem) {
        return 'has item';
      }

      const modelName = `${brand || ''} ${model || ''} ${color || ''}`.trim();

      const itemName = `${name || ''} ${modelName || ''} ${serialNumber ||
        ''}`.trim();

      if (itemName) {
        return itemName;
      }

      return 'Unknown';
    },
    [IncidentSearchType.vehicle]: option => {
      const {
        licensePlate,
        stateProvince,
        maker,
        model,
        color,
        hasVehicle,
      } = option;

      if (hasVehicle) {
        return 'has vehicle';
      }

      let vehicleName = '';
      const modelName = `${maker || ''} ${model || ''} ${color || ''}`.trim();
      const number = `${licensePlate || ''} ${stateProvince || ''}`.trim();

      if (modelName && number) {
        vehicleName = `${modelName} (${number})`.trim();
      } else if (modelName) {
        vehicleName = modelName;
      } else if (number) {
        vehicleName = number;
      }

      if (vehicleName) {
        return vehicleName;
      }

      return 'Unknown';
    },
    [IncidentSearchType.address]: option => {
      const {
        unit,
        address,
        street,
        countryCode,
        stateProvinceCode,
        cityName,
      } = option;
      return [unit, address, street, countryCode, stateProvinceCode, cityName]
        .filter(Boolean)
        .join(', ');
    },
    [IncidentSearchType.attachment]: option =>
      option.hasAttachment ? 'has attachment' : option.name,
    [IncidentSearchType.status]: option =>
      translateConfig.enums.incidentStatus[option.status],
    [IncidentSearchType.creator]: getPersonName,
  };

  React.useEffect(() => {
    setState({
      ...state,
      readable: toReadableAudit(auditData, props.auditType),
    });
  }, [props.auditData]);

  const periods = auditData.map(x => x.period);
  const firstPeriod = _.first(auditData);
  const frequency = firstPeriod && firstPeriod.frequency;
  const incidentClasses =
    firstPeriod &&
    firstPeriod.classCriteria &&
    firstPeriod.classCriteria.values &&
    firstPeriod.classCriteria.values.map(x => {
      return {
        allClass: _.first(x.classIdList) === undefined,
        lookupPath: buildLookupPath(
          props.incidentLookups,
          _.first(x.classIdList),
        ),
      };
    });
  const locations =
    firstPeriod &&
    firstPeriod.locationCriteria &&
    firstPeriod.locationCriteria.values;

  const chartType = firstPeriod && firstPeriod.chartType;

  return (
    <BaseDialog
      open={props.open}
      maxWidth="md"
      onClose={handleClose}
      title="Audit detail"
      actionContent={
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      }
    >
      <Paper className={classes.paper}>
        <PeriodItem periods={periods} dateFormat={props.dateFormat} />
        <FrequencyCriteria frequency={frequency} />
        {incidentClasses && (
          <LookupItem
            lookupType={LookupTypeKey.incidentClass}
            lastLookupIds={incidentClasses}
          />
        )}

        {locations && (
          <LookupItem
            lookupType={LookupTypeKey.location}
            lastLookupIds={locations}
          />
        )}

        <ChartTypeCriteria chartType={chartType} />
      </Paper>
    </BaseDialog>
  );
};

AuditAnalyzeViewer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  auditData: PropTypes.object.isRequired,
  auditType: PropTypes.string.isRequired,
  incidentLookups: PropTypes.arrayOf(LookupDef).isRequired,
  accountDateFormat: PropTypes.oneOf([0, 1]), // AccountDateFormatEnum
};

AuditAnalyzeViewer.defaultProps = {
  onClose: f => f,
};

export default AuditAnalyzeViewer;
