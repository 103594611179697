import uuid from 'uuid/v4';

export default class IncidentItemModel {
  constructor(id, name) {
    if (id) {
      this.id = id;
    } else {
      this.id = uuid();
    }
    this.name = name;
    this.brand = '';
    this.model = '';
    this.color = '';
  }

  getName = () => {
    const modelName = `${this.brand} ${this.model} ${this.color}`.trim();

    if (modelName && this.name) {
      return `${this.name} (${modelName})`.trim();
    }
    if (modelName) {
      return modelName;
    }
    if (this.name) {
      return this.name;
    }
    return 'Unknown';
  };
}
