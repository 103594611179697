import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Auth from '../auth/Auth';
import config from '../config';
import reportApi from '../apis/report.api';

const styles = theme => ({
  reportViewer: {
    width: '100%',
    height: '810px',
  },
});

class ReportViewer extends Component {
  constructor(props) {
    super(props);
    const { reportId } = this.props;
    this.reportId = reportId;
  }

  componentWillUnmount() {
    reportApi.deleteReportData(this.reportId);
  }

  render() {
    const { classes, reportTitle, reportId, reportName, logoUrl } = this.props;
    const apiUrl = config.url.apiUrl;
    if (!reportId) {
      return <div />;
    }

    return (
      <div className={classes.reportViewer}>
        <iframe
          title="reportViewer"
          src={`${
            config.url.reportServerUrl
          }/Reports/reportViewer.html?reportName=${reportName}&accessToken=${
            Auth.accessToken
          }&reportTitle=${reportTitle}&url=${apiUrl}/reports/${reportId}&logo=${btoa(
            logoUrl,
          )}`}
          scrolling="no"
          frameBorder="0"
          width="100%"
          height="100%"
        />
      </div>
    );
  }
}

ReportViewer.propTypes = {
  classes: PropTypes.object.isRequired,
  reportName: PropTypes.string.isRequired,
  reportId: PropTypes.string.isRequired,
  reportTitle: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
};

ReportViewer.defaultProps = {
  parameters: {},
};

export default withStyles(styles)(ReportViewer);
