import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  Typography,
  Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import HomeIcon from '@material-ui/icons/Home';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import cx from 'classnames';
import {
  LookupTypeDef,
  LookupDef,
  ChildrenDef,
} from '../../components/propTypeDefs';
import { labelValueStyle } from '../../theme';
import auth from '../../auth/Auth';

const useStyles = makeStyles(theme => ({
  treeIconBox: {
    minWidth: 'inherit',
  },
  treeIcon: {
    width: 16,
    height: 16,
  },
  treeIconEmpty: {
    color: 'transparent',
  },
  lookupName: {
    marginLeft: 8,
  },
  marginY0: {
    marginTop: 5,
    marginBottom: 5,
  },
  lookupItem: {
    marginTop: 2,
    marginBottom: 2,
  },
  lookupItemSelected: {
    marginTop: 2,
    marginBottom: 2,
    backgroundColor: theme.palette.grey[400],
  },
  redButton: {
    color: theme.palette.error.main,
    // '&:hover': {
    //   color: theme.palette.error.dark,
    // },
  },
  itemActions: {
    display: 'flex',
    width: 81,
  },
}));

const LookupItem = props => {
  const classes = useStyles();
  const { selectedLookupId, lookup, level, ...others } = props;
  const { labels } = others;
  const [open, setOpen] = React.useState(level === 0 || lookup.opened);
  const handleSelect = e => {
    level > 0 && props.onEdit(lookup);
  };

  const availableLevel =
    (props.lookupType && props.lookupType.type === 'single') ||
    (level >= 0 && level < labels.length);

  const handleEdit = e => {
    e.stopPropagation();
    level > 0 && props.onEdit(lookup);
  };

  const handleAdd = e => {
    e.stopPropagation();
    if (!availableLevel) return;
    props.onAdd(lookup);
  };

  const handleAttachAddress = e => {
    e.stopPropagation();
    props.onAttachAddress(lookup);
  };

  const handleDelete = e => {
    e.stopPropagation();
    props.onDelete(lookup);
  };

  React.useEffect(() => {
    lookup.opened && setOpen(lookup.opened);
  }, [lookup.opened]);

  return (
    <React.Fragment key={lookup.id}>
      <ListItem
        dense
        button={level > 0}
        className={
          selectedLookupId && selectedLookupId === lookup.id
            ? classes.lookupItemSelected
            : classes.lookupItem
        }
        style={{ padding: `3px 0 3px ${level * 22 + 8}px` }}
        onClick={e => {
          e.stopPropagation();
          setOpen(!open);
        }}
      >
        <ListItemText className={classes.marginY0}>
          {lookup.children && lookup.children.length ? (
            <IconButton
              edge="start"
              color="primary"
              size="small"
              onClick={e => {
                e.stopPropagation();
                setOpen(!open);
              }}
            >
              {open ? (
                <ExpandMore className={classes.treeIcon} />
              ) : (
                <ChevronRightIcon className={classes.treeIcon} />
              )}
            </IconButton>
          ) : (
            <IconButton edge="start" color="default" size="small" disabled>
              <ChevronRightIcon
                className={cx(classes.treeIcon, classes.treeIconEmpty)}
              />
            </IconButton>
          )}
          <Tooltip
            title={labels[level > 0 ? level - 1 : 0] || ''}
            disableFocusListener
            disableHoverListener={
              level === 0 || props.lookupType.type === 'single'
            }
            disableTouchListener
            placement="right"
          >
            <Typography
              variant="subtitle2"
              className={classes.lookupName}
              display="inline"
            >
              {lookup.active ? lookup.name : <strike>{lookup.name}</strike>}
            </Typography>
          </Tooltip>
          <ListItemSecondaryAction className={classes.itemActions}>
            {(props.lookupType.type === 'multiple' || level === 0) && (
              <Tooltip
                title={availableLevel ? 'Add child lookup' : ''}
                placement="left"
              >
                <IconButton
                  edge="start"
                  color="primary"
                  size="small"
                  onClick={handleAdd}
                  disabled={!auth.hasPrivilege(['LOOKUP_ADD'])}
                >
                  {availableLevel ? (
                    <AddCircleIcon />
                  ) : (
                    <AddCircleIcon color="disabled" />
                  )}
                </IconButton>
              </Tooltip>
            )}

            {level > 0 && (
              <Tooltip
                title={availableLevel ? 'Edit lookup' : ''}
                placement="left"
              >
                <IconButton
                  edge="start"
                  color="primary"
                  size="small"
                  onClick={handleEdit}
                  disabled={!auth.hasPrivilege(['LOOKUP_EDIT'])}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}

            {props.lookupType.type === 'location' && availableLevel && (
              <Tooltip title="Add address" placement="left">
                <IconButton
                  edge="start"
                  color="secondary"
                  size="small"
                  onClick={handleAttachAddress}
                >
                  <HomeIcon />
                </IconButton>
              </Tooltip>
            )}
            {level > 0 && (
              <Tooltip title="Delete item" placement="left">
                <IconButton
                  edge="start"
                  color="default"
                  size="small"
                  className={classes.redButton}
                  onClick={handleDelete}
                  disabled={!auth.hasPrivilege(['LOOKUP_DELETE'])}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </ListItemSecondaryAction>
        </ListItemText>
      </ListItem>
      {lookup.children && (
        <LookupChildren
          open={open}
          level={level + 1}
          selectedLookupId={selectedLookupId}
          {...others}
        >
          {lookup.children}
        </LookupChildren>
      )}
    </React.Fragment>
  );
};

LookupItem.propTypes = {
  lookup: LookupDef,
  lookupType: LookupTypeDef,
  children: PropTypes.arrayOf(LookupDef),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onAdd: PropTypes.func,
  onAttachAddress: PropTypes.func,
  level: PropTypes.number,
  selectedLookupId: PropTypes.string,
};

const LookupChildren = props => {
  const { children, open, selectedLookupId, ...others } = props;
  return (
    <Collapse in={open} unmountOnExit>
      <List style={{ padding: 0, margin: 0 }}>
        {children.map(item => (
          <LookupItem
            key={item.id}
            lookup={item}
            selectedLookupId={selectedLookupId}
            {...others}
          />
        ))}
      </List>
    </Collapse>
  );
};

LookupChildren.propTypes = {
  children: PropTypes.arrayOf(LookupDef),
  open: PropTypes.bool,
  selectedLookupId: PropTypes.string,
};

const LookupList = props => {
  const { lookupType, lookups, selectedLookupId, ...others } = props;
  const wrappingData = {
    id: 0,
    type: lookupType.key,
    name: lookupType.name,
    fullName: lookupType.fullName,
    children: lookups,
    level: 0,
    active: true,
  };

  return (
    <List style={{ margin: 0, padding: 0 }}>
      {lookupType && (
        <LookupItem
          lookup={wrappingData}
          level={0}
          lookupType={lookupType}
          selectedLookupId={selectedLookupId}
          {...others}
        />
      )}
    </List>
  );
};

LookupList.propTypes = {
  lookupType: LookupTypeDef.isRequired,
  lookups: PropTypes.arrayOf(LookupDef),
  labels: PropTypes.arrayOf(PropTypes.string),
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  onAttachAddress: PropTypes.func,
  selectedLookupId: PropTypes.string,
};

LookupList.defaultProps = {
  expanded: true,
  labels: [],
  lookups: [],
  onEdit: f => f,
  onDelete: f => f,
  onAdd: f => f,
  onAttachAddress: f => f,
  selectedLookupId: undefined,
};

export default LookupList;
