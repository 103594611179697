import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import * as yup from 'yup';
import _ from 'lodash';
import { Button, FormControl, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import BaseDialog from '../../components/dialogs/BaseDialog';
import { useForm } from '../../utils/hooks';

const useStyles = makeStyles(theme => ({
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const schema = yup.object().shape({
  name: yup
    .string()
    .max(100)
    .required(),
  description: yup.string().max(500),
});

const SaveCustomSearchDialog = props => {
  const classes = useStyles();
  const {
    formValues,
    updateFormValues,
    hasFormError,
    formHelpText,
    handleFormChange,
    handleFormBlur,
    handleFormSubmit,
  } = useForm({
    validationSchema: schema,
    onSubmit: (values, errors) => {
      if (_.isEmpty(errors)) {
        props.onSave(values);
      }
    },
  });

  const handleSave = () => {
    handleFormSubmit();
  };
  const inputHandleChange = event => {
    handleFormChange(event);
  };

  React.useEffect(() => {
    updateFormValues({
      id: uuid(),
      name: props.name,
      description: props.description,
    });
  }, []);

  return (
    <BaseDialog
      open={props.open}
      onClose={f => f}
      title="Save Search"
      actionContent={
        <>
          <Button onClick={handleSave} variant="contained" color="primary">
            Ok
          </Button>
          <Button onClick={props.onClose} color="default" pl={3}>
            Cancel
          </Button>
        </>
      }
    >
      <div className={classes.lineItem}>
        <FormControl className={classes.formControl}>
          <TextField
            required
            name="name"
            label="Name"
            value={formValues.name || ''}
            onChange={inputHandleChange}
            margin="none"
            onBlur={handleFormBlur}
            InputLabelProps={{
              shrink: true,
            }}
            error={hasFormError('name')}
            helperText={formHelpText('name')}
            inputProps={{ autoComplete: 0 }}
          />
        </FormControl>
      </div>
      <div className={classes.lineItem}>
        <FormControl className={classes.formControlFull}>
          <TextField
            name="description"
            label="Description"
            value={formValues.description || ''}
            onChange={inputHandleChange}
            margin="none"
            onBlur={handleFormBlur}
            InputLabelProps={{
              shrink: true,
            }}
            error={hasFormError('description')}
            helperText={formHelpText('description')}
            inputProps={{ autoComplete: 0 }}
          />
        </FormControl>
      </div>
    </BaseDialog>
  );
};

SaveCustomSearchDialog.propTypes = {
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  open: PropTypes.bool.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
};
SaveCustomSearchDialog.defaultProps = {
  onSave: f => f,
  onClose: f => f,
  name: undefined,
  description: undefined,
};

export default SaveCustomSearchDialog;
