import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, Box } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { makeStyles } from '@material-ui/styles';
import auth from '../../auth/Auth';

import ExpansionCard from '../../components/cards/ExpansionCard';
import {
  ReadableIncidentPersonDef,
  ChildrenDef,
} from '../../components/propTypeDefs';
import RichReader from '../../components/forms/RichReader';
import { useMediaInfo } from '../../utils/hooks';
import PersonPictureView from './PersonPictureView';
import { labelValueStyle } from '../../theme';

const useStyles = makeStyles(theme => ({
  ...labelValueStyle,
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    // flexWrap: 'wrap',
  },
  unlockButtonItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    display: 'flex',
    alignItems: 'flex-start',
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
      maxWidth: 1000,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
    // height: 40,
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    flexWrap: 'wrap',
  },
  personPicture: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  value: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const PrivacyInformation = props => {
  const classes = useStyles();
  const { locked, children } = props;

  if (locked) {
    return (
      <div className={classes.value}>
        ************
        <LockIcon />
      </div>
    );
  }
  return (
    <div className={classes.value}>
      {children}
      {/* <LockOpenIcon /> */}
    </div>
  );
};
PrivacyInformation.propTypes = {
  locked: PropTypes.bool,
  children: ChildrenDef,
};
PrivacyInformation.defaultProps = {
  locked: true,
  children: undefined,
};

const IncidentViewPersonItem = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    expanded: props.expanded,
    privacyInformationLocked: true,
    canAccessPrivacy: false,
  });
  const { person } = props;
  const { privacyInformationLocked } = state;
  const { isSmaller } = useMediaInfo({ minBreakPoint: 'sm' });

  const unlockPrivacyInformation = () => {
    setState({
      ...state,
      privacyInformationLocked: false,
    });
  };

  const onExpansionCardExpanded = expanded => {
    setState({
      ...state,
      expanded,
    });
  };

  React.useState(() => {
    setState({
      ...state,
      canAccessPrivacy: auth.hasPrivilege(['PRIVACY_INFO_VIEW']),
    });
  }, [person]);

  return (
    <ExpansionCard
      title={person.displayName}
      theme="sub"
      onChange={onExpansionCardExpanded}
    >
      <div className={classes.root}>
        <Box
          display="flex"
          // flex="1"
          flexDirection="row"
          className={classes.box}
        >
          <Box flexGrow="1">
            <div className={classes.lineItem}>
              <FormControl className={classes.formControl}>
                <div className={classes.label}>First name</div>
                <div className={classes.value}>{person.firstName}</div>
              </FormControl>
              <FormControl className={classes.formControl}>
                <div className={classes.label}>Middle initial</div>
                <div className={classes.value}>{person.middleName}</div>
              </FormControl>
              <FormControl className={classes.formControl}>
                <div className={classes.label}>Last name</div>
                <div className={classes.value}>{person.lastName}</div>
              </FormControl>
              <FormControl className={classes.formControl}>
                <div className={classes.label}>Alias</div>
                <div className={classes.value}>{person.alias}</div>
              </FormControl>
            </div>
            <div className={classes.lineItem}>
              <FormControl className={classes.formControl}>
                <div className={classes.label}>Person status</div>
                <div className={classes.value}>{person.status}</div>
              </FormControl>
              <FormControl className={classes.formControl}>
                <div className={classes.label}>Person relation</div>
                <div className={classes.value}>{person.incidentRelation}</div>
              </FormControl>
              <FormControl className={classes.formControl}>
                <div className={classes.label}>Person type</div>
                <div className={classes.value}>{person.personType}</div>
              </FormControl>
              {person.isEmployee && (
                <>
                  <FormControl className={classes.formControl}>
                    <div className={classes.label}>Department</div>
                    <div className={classes.value}>{person.department}</div>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <div className={classes.label}>Position</div>
                    <div className={classes.value}>{person.position}</div>
                  </FormControl>
                </>
              )}
              <FormControl className={classes.formControl}>
                <div className={classes.label}>Date of birth</div>
                <PrivacyInformation
                  locked={privacyInformationLocked}
                  classes={classes}
                >
                  {person.dateOfBirth}
                </PrivacyInformation>
              </FormControl>
              <FormControl className={classes.formControl}>
                <div className={classes.label}>Gender</div>
                <PrivacyInformation
                  locked={privacyInformationLocked}
                  classes={classes}
                >
                  {person.genderType}
                </PrivacyInformation>
              </FormControl>
            </div>
            {!person.isEmployee && (
              <div className={classes.lineItem}>
                <FormControl className={classes.formControl}>
                  <div className={classes.label}>Location</div>
                  <PrivacyInformation
                    locked={privacyInformationLocked}
                    classes={classes}
                  >
                    {person.address}
                  </PrivacyInformation>
                </FormControl>
              </div>
            )}

            <div className={classes.lineItem}>
              <FormControl className={classes.formControl}>
                <div className={classes.label}>Phone #</div>
                <PrivacyInformation
                  locked={privacyInformationLocked}
                  classes={classes}
                >
                  {person.phone}
                </PrivacyInformation>
              </FormControl>
              <FormControl className={classes.formControl}>
                <div className={classes.label}>Mobile phone #</div>
                <PrivacyInformation
                  locked={privacyInformationLocked}
                  classes={classes}
                >
                  {person.mobilePhone}
                </PrivacyInformation>
              </FormControl>
              <FormControl className={classes.formControl}>
                <div className={classes.label}>Email</div>
                <PrivacyInformation
                  locked={privacyInformationLocked}
                  classes={classes}
                >
                  {person.email}
                </PrivacyInformation>
              </FormControl>
            </div>
            {!person.isEmployee && (
              <div className={classes.lineItem}>
                <FormControl className={classes.formControlLong}>
                  <div className={classes.label}>
                    Distinguishing characteristics
                  </div>
                  <div className={classes.value}>{person.characteristics}</div>
                </FormControl>
              </div>
            )}

            <div className={classes.lineItem}>
              <div className={classes.formControlLong}>
                <div className={classes.label}>Comments</div>
                <div className={classes.value}>
                  <RichReader content={person.comments} />
                </div>
              </div>
            </div>
            {privacyInformationLocked && (
              <div className={classes.unlockButtonItem}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={unlockPrivacyInformation}
                  disabled={!state.canAccessPrivacy}
                >
                  <LockOpenIcon />
                </Button>
              </div>
            )}
          </Box>
          <Box>
            <PersonPictureView pictureUrl={person.personPicture} />
          </Box>
        </Box>
      </div>
    </ExpansionCard>
  );
};
IncidentViewPersonItem.propTypes = {
  expanded: PropTypes.bool,
  person: ReadableIncidentPersonDef.isRequired,
};

IncidentViewPersonItem.defaultProps = {
  expanded: false,
};

export default IncidentViewPersonItem;
