import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import _ from 'lodash';

import {
  MuiPickersUtilsProvider,
  DateTimePicker,
  DatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/styles';
import ExpansionCard from '../../components/cards/ExpansionCard';
import LookupMultiplesSelect from './LookupMultiplesSelect';
import LocationDetailForm from './LocationDetailForm';
import {
  LookupDef,
  DraftIncidentDef,
  CompanySettingsDef,
} from '../../components/propTypeDefs';
import { DefaultDateFormat, LookupTypeKey } from '../../constants';
import { useForm } from '../../utils/hooks';
import HelpButton from '../../components/buttons/HelpButton';
import { incidentSchema } from '../../models/validateSchemas';
import RichEditor from '../../components/forms/RichEditor';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },
  },
  expansionCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  expansionCardItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center', // 'flex-end',
    padding: 10,
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    width: '100%',
  },
  expansionCard: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
    },
  },
  helpIcon: {
    // marginBottom: theme.spacing(1),
    // marginLeft: theme.spacing(-1),
    // marginRight: theme.spacing(1),
  },
  expansionTitle: {
    display: 'flex',
    flexDirection: 'column',
  },
  causeComments: {
    flexGrow: 1,
    margin: theme.spacing(1, 0),
    width: '100%',
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'col',
    alignItems: 'center',
    // height: 80,
    flexWrap: 'wrap',
    width: '100%',
  },
  labelWithHelpIcon: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const IncidentDetailsCard = props => {
  const classes = useStyles();
  const { draftIncident } = props;
  const {
    formValues,
    updateFormValues,
    hasFormError,
    handleFormChange,
    handleFormBlur,
    handleFormSubmit,
    formHelpText,
    formErrors,
  } = useForm({
    validationSchema: incidentSchema,
    onSubmit: (values, errors) => {},
  });

  React.useEffect(() => {
    if (!draftIncident) return;
    const {
      occurredDate,
      occurredDateUncertain,
      reportedDate,
      lastClassId,
      primaryCauseId,
      severityId,
      location = {},
      causeComments,
    } = draftIncident || {};
    const defaultValue = {
      occurredDate,
      occurredDateUncertain,
      reportedDate,
      lastClassId,
      primaryCauseId,
      severityId,
      location,
      causeComments,
    };
    updateFormValues(defaultValue);
  }, [draftIncident]);

  React.useEffect(() => {
    if (props.errors && props.errors.length > 0) {
      handleFormSubmit();
    }
  }, [props.errors]);

  const handleInputChange = event => {
    handleFormChange(event);
  };

  const raiseFormEvent = (name, value) => {
    props.onChange({ ...formValues, [name]: value });
    handleInputChange({ target: { name, value } });
  };

  const handleChangeIncidentLookups = value => {
    raiseFormEvent('lastClassId', value);
  };

  const handleChangePrimaryCauseLookups = event => {
    raiseFormEvent('primaryCauseId', event.target.value);
  };

  const handleChangeSeverityLookups = event => {
    raiseFormEvent('severityId', event.target.value);
  };

  const handleIncidentDateChange = date => {
    raiseFormEvent('occurredDate', date);
  };

  const handleReportDateChange = date => {
    raiseFormEvent('reportedDate', date);
  };

  const handleOccurredDateUncertain = event => {
    raiseFormEvent('occurredDateUncertain', event.target.checked);
  };

  React.useEffect(() => {
    if (props.errors && props.errors.length > 0) {
      handleFormBlur({
        target: { name: 'occurredDate', value: formValues.occurredDate },
      });
    }
  }, [formValues.occurredDate]);

  React.useEffect(() => {
    if (props.errors && props.errors.length > 0) {
      handleFormBlur({
        target: { name: 'reportedDate', value: formValues.reportedDate },
      });
    }
  }, [formValues.reportedDate]);

  const handelChangeLocation = values => {
    raiseFormEvent('location', values);
  };

  const handleCauseCommentsChange = value => {
    raiseFormEvent('causeComments', value);
  };

  return (
    <>
      <ExpansionCard
        title={
          draftIncident && draftIncident.status === 0
            ? `When #${draftIncident.number} (Draft)`
            : `When`
        }
      >
        {draftIncident && (
          <div className={classes.expansionCardContainer}>
            <div className={classes.expansionCardItem}>
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    id="date-time-picker-date-reported"
                    // required
                    clearable
                    disableFuture
                    label={
                      <div className={classes.labelWithHelpIcon}>
                        Date reported *
                        <HelpButton
                          popupId="help-reported-date"
                          message="Date and time the incident was reported."
                        />
                      </div>
                    }
                    format={
                      DefaultDateFormat(props.companySettings.dateFormat)
                        .datetime
                    }
                    name="reportedDate"
                    value={formValues.reportedDate || null}
                    onChange={handleReportDateChange}
                    error={hasFormError('reportedDate')}
                    helperText={formHelpText('reportedDate')}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  {!formValues.occurredDateUncertain ? (
                    <DateTimePicker
                      // required
                      clearable
                      disableFuture
                      // label="Date occurred"
                      label={
                        <div className={classes.labelWithHelpIcon}>
                          Date occurred *
                          <HelpButton
                            popupId="help-occurred-date2"
                            message="Date and time the incident occurred. If the exact time of the incident is unknown, select the 'Uncertain' checkbox."
                          />
                        </div>
                      }
                      format={
                        DefaultDateFormat(props.companySettings.dateFormat)
                          .datetime
                      }
                      name="occurredDate"
                      value={formValues.occurredDate || null}
                      maxDate={formValues.reportedDate || null}
                      onChange={handleIncidentDateChange}
                      error={hasFormError('occurredDate')}
                      helperText={formHelpText('occurredDate')}
                    />
                  ) : (
                    <DatePicker
                      clearable
                      disableFuture
                      label={
                        <div className={classes.labelWithHelpIcon}>
                          Date occurred
                          <HelpButton
                            popupId="help-occurred-date2"
                            message="Date and time the incident occurred. If the exact time of the incident is unknown, select the 'Uncertain' checkbox."
                          />
                        </div>
                      }
                      format={
                        DefaultDateFormat(props.companySettings.dateFormat).date
                      }
                      name="occurredDate"
                      value={formValues.occurredDate || null}
                      onChange={handleIncidentDateChange}
                      required
                      error={hasFormError('occurredDate')}
                      helperText={formHelpText('occurredDate')}
                    />
                  )}
                </MuiPickersUtilsProvider>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!formValues.occurredDateUncertain}
                    onChange={handleOccurredDateUncertain}
                    color="primary"
                    name="occurredDateUncertain"
                  />
                }
                label="Uncertain"
              />
            </div>
          </div>
        )}
      </ExpansionCard>
      <div className={classes.expansionCard}>
        <ExpansionCard
          title="What"
          helpMessage="Specify the type of incident using the class/category/subcategory/type dropdown list."
        >
          <div className={classes.expansionCardItem}>
            <LookupMultiplesSelect
              labels={props.incidentLabels}
              lookups={props.incidentLookups}
              onChange={handleChangeIncidentLookups}
              onBlur={handleFormBlur}
              defaultValue={formValues.lastClassId}
              required
              error={hasFormError('lastClassId')}
              errorText={formHelpText('lastClassId')}
            />
            <div className={classes.lineItem}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink>Severity</InputLabel>
                <Select
                  value={formValues.severityId || ''}
                  onChange={handleChangeSeverityLookups}
                  displayEmpty
                  name="severityId"
                  className={classes.selectEmpty}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {props.severityLookups.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </ExpansionCard>
      </div>
      <div className={classes.expansionCard}>
        <ExpansionCard
          expanded={props.opened}
          title="Where"
          helpMessage="Specify the location of the incident using the location dropdown list. If location is not in the drop down list, add the location details in the address field."
        >
          <div className={classes.expansionCardItem}>
            <LocationDetailForm
              locationLabels={props.locationLabels}
              locationLookups={props.locationLookups}
              onChange={handelChangeLocation}
              defaultValues={formValues.location}
            />
          </div>
        </ExpansionCard>
      </div>
      <div className={classes.expansionCard}>
        <ExpansionCard
          expanded={props.opened}
          title="Why"
          helpMessage="Specify the root cause of the incident using the dropdown list."
        >
          <div className={classes.expansionCardItem}>
            <div className={classes.lineItem}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink>Root cause</InputLabel>
                <Select
                  value={formValues.primaryCauseId || ''}
                  onChange={handleChangePrimaryCauseLookups}
                  displayEmpty
                  name="primaryCauseId"
                  className={classes.selectEmpty}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {props.primaryCauseLookups.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={classes.lineItem}>
              <Typography variant="h6" className={classes.labelWithHelpIcon}>
                Comments{' '}
                <HelpButton message="Further define the root cause, if necessary." />
              </Typography>
              <FormControl className={classes.causeComments}>
                <RichEditor
                  height="low"
                  onChange={handleCauseCommentsChange}
                  defaultValue={formValues.causeComments}
                />
              </FormControl>
            </div>
          </div>
        </ExpansionCard>
      </div>
    </>
  );
};
IncidentDetailsCard.propTypes = {
  onChange: PropTypes.func,
  opened: PropTypes.bool,
  draftIncident: DraftIncidentDef,
  incidentLabels: PropTypes.arrayOf(PropTypes.string),
  incidentLookups: PropTypes.arrayOf(LookupDef),
  locationLabels: PropTypes.arrayOf(PropTypes.string),
  locationLookups: PropTypes.arrayOf(LookupDef),
  primaryCauseLookups: PropTypes.arrayOf(LookupDef),
  severityLookups: PropTypes.arrayOf(LookupDef),
  companySettings: CompanySettingsDef,
  errors: PropTypes.object,
};

IncidentDetailsCard.defaultProps = {
  onChange: f => f,
  opened: false,
  draftIncident: undefined,
  incidentLabels: [],
  incidentLookups: [],
  locationLabels: [],
  locationLookups: [],
  primaryCauseLookups: [],
  severityLookups: [],
  companySettings: {},
};

export default IncidentDetailsCard;
