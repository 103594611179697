import auth from './auth/Auth';

export const getMenu = (isMobile = false) => {
  const menu = [
    //   {
    //     label: 'Home',
    //     pathname: '/',
    //   },
    {
      label: 'Dashboard',
      pathname: '/dashboard',
    },
  ];

  // new incident
  if (auth.hasMenuPrivilege('/newincident')) {
    menu.push({
      label: 'New Incident',
      pathname: '/newincident',
    });
  }

  // report
  if (auth.hasMenuPrivilege('/reports')) {
    menu.push({
      label: 'Reports',
      pathname: '/reports',
    });
  }

  // search
  if (auth.hasMenuPrivilege('/search')) {
    menu.push({
      label: 'Search',
      pathname: '/search',
    });
  }

  if (!isMobile) {
    // analyze
    if (auth.hasMenuPrivilege('/analyze')) {
      menu.push({
        label: 'Analyze',
        pathname: '/analyze',
      });
    }

    // administration
    if (auth.hasMenuPrivilege('/administration')) {
      menu.push({
        label: 'Administration',
        pathname: '/administration',
      });
    }
  }

  return menu;
};

export default {
  getMenu,
};
