const csvWrite = require('csv-writer');

export const convertAnalysisDataToCSV = mapData => {
  if (!mapData) return '';

  const uniquePeriods = mapData[0].data.map(x => x.period);
  const headers = ['period'].concat(uniquePeriods);

  const createCsvStringifier = csvWrite.createObjectCsvStringifier;
  const csvStringifier = createCsvStringifier({
    header: headers.map(x => {
      return { id: x, title: x };
    }),
  });

  const records = []; // as any[];

  mapData.forEach(period => {
    const counts = period.data.map(x => {
      return { [x.period]: x.count };
    }); // as any[];
    const values = [{ period: period.periodName }].concat(counts);
    const peridValues = {};
    values.forEach(x => {
      Object.assign(peridValues, x);
    });

    records.push(peridValues);
  });

  return `${csvStringifier.getHeaderString()}${csvStringifier.stringifyRecords(
    records,
  )}`;
};

export default {
  convertAnalysisDataToCSV,
};
