import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import _ from 'lodash';
import { enqueueError } from '../../modules/global';
import { setSelectedDraft } from '../newIncident/ducks';
import incidentApi from '../../apis/incident.api';
import draftIncidentApi from '../../apis/draftIncident.api';
import { IncidentStatusEnum } from '../../constants';
import reportApi from '../../apis/report.api';

const RESET = 'incidentView/RESET';
const INCIDENT_DRAFT_LOADED = 'incidentView/INCIDENT_DRAFT_LOADED';
const INCIDENT_FORM_INITIALIZED = 'incidentView/INCIDENT_FORM_INITIALIZED';
const OPENDRAFT_SELECTED = 'incidentView/OPENDRAFT_SELECTED';
const INCIDENT_DRAFT_CLEARED = 'incidentView/INCIDENT_DRAFT_CLEARED';

const INCIDENT_HISTORY_LOADED = 'incidentView/INCIDENT_HISTORY_LOADED';
const INCIDENT_HISTORY_RESET = 'incidentView/INCIDENT_HISTORY_RESET';
const HISTORY_COMPARE_LOADED = 'incidentView/HISTORY_COMPARE_LOADED';
const HISTORY_COMPARE_RESET = 'incidentView/HISTORY_COMPARE_RESET';

const INCIDENT_LOCK = 'incidentView/INCIDENT_LOCK';
const INCIDENT_UNLOCK = 'incidentView/INCIDENT_UNLOCK';

const resetAction = createAction(RESET);
const incidentFormInitialized = createAction(INCIDENT_FORM_INITIALIZED);
const incidentDraftLoadedAction = createAction(INCIDENT_DRAFT_LOADED);
const openDraftSelectedAction = createAction(OPENDRAFT_SELECTED);
const incidentDraftClearedAction = createAction(INCIDENT_DRAFT_CLEARED);

const incidentHistoryLoadedAction = createAction(INCIDENT_HISTORY_LOADED);
const incidentHistoryResetAction = createAction(INCIDENT_HISTORY_RESET);

const historyCompareLoadedAction = createAction(HISTORY_COMPARE_LOADED);
const historyCompareResetAction = createAction(HISTORY_COMPARE_RESET);

const lockIncidentAction = createAction(INCIDENT_LOCK);
const unlockIncidentAction = createAction(INCIDENT_UNLOCK);

const initState = {
  loaded: false,
  draftIncident: undefined,
  tempDraftIncident: undefined,
  needConfirmDraft: false,
  incidentHistory: [],
  historyLoaded: false,
  historyCompareLoaded: false,
  historyCompareResult: undefined,
  reportViewOpen: false,
};

export default handleActions(
  {
    [RESET]: (state, action) =>
      produce(state, draft => {
        draft.loaded = false;
      }),
    [INCIDENT_DRAFT_LOADED]: (state, action) =>
      produce(state, draft => {
        draft.draftIncident = action.payload;
        draft.loaded = true;
      }),
    [INCIDENT_FORM_INITIALIZED]: (state, action) =>
      produce(state, draft => {
        const { needConfirmDraft, draftIncident } = action.payload;
        draft.needConfirmDraft = needConfirmDraft;
        needConfirmDraft && (draft.tempDraftIncident = draftIncident);
        draft.loaded = true;
      }),
    [OPENDRAFT_SELECTED]: (state, action) =>
      produce(state, draft => {
        const useOpenDraft = action.payload;
        draft.needConfirmDraft = false;
        useOpenDraft && (draft.draftIncident = state.tempDraftIncident);
        draft.tempDraftIncident = undefined;
        draft.loaded = true;
      }),
    [INCIDENT_DRAFT_CLEARED]: (state, action) =>
      produce(state, draft => {
        draft.draftIncident = undefined;
      }),
    [INCIDENT_HISTORY_LOADED]: (state, action) =>
      produce(state, draft => {
        draft.incidentHistory = action.payload;
        draft.historyLoaded = true;
      }),
    [INCIDENT_HISTORY_RESET]: (state, action) =>
      produce(state, draft => {
        draft.incidentHistory = [];
        draft.historyLoaded = false;
      }),
    [HISTORY_COMPARE_RESET]: (state, action) =>
      produce(state, draft => {
        draft.historyCompareLoaded = false;
        draft.historyCompareResult = undefined;
      }),
    [HISTORY_COMPARE_LOADED]: (state, action) =>
      produce(state, draft => {
        draft.historyCompareLoaded = true;
        draft.historyCompareResult = action.payload;
      }),
  },
  initState,
);
export const loadDraftIncident = incident => async dispatch => {
  dispatch(resetAction());
  dispatch(incidentDraftClearedAction());
  try {
    let draftModel;
    if (
      [IncidentStatusEnum.open, IncidentStatusEnum.closed].includes(
        incident.status,
      )
    ) {
      const {
        id,
        address,
        unit,
        street,
        postalCode,
        cityId,
        cityName,
        countryCode,
        countryName,
        stateName,
        lastLocationId,
        ...details
      } = incident;

      draftModel = {
        id,
        ...details,
        location: {
          address,
          unit,
          street,
          postalCode,
          cityId,
          cityName,
          countryCode,
          countryName,
          stateName,
          lastLocationId,
        },
      };

      await Promise.all([
        incidentApi.queryPersons(id),
        incidentApi.queryCompanies(id),
        incidentApi.queryVehicles(id),
        incidentApi.queryItems(id),
        incidentApi.queryAttachments(id),
        incidentApi.getCorrectiveActions(id),
      ])
        .then(values => {
          draftModel.persons = values[0].data;
          draftModel.companies = values[1].data;
          draftModel.vehicles = values[2].data;
          draftModel.items = values[3].data;
          draftModel.attachments = values[4].data;
          draftModel.correctiveActions = values[5].data;

          incidentApi.sendReadAudit(id, incident.number);
        })
        .catch(err => {
          dispatch(enqueueError(err));
        });
    } else {
      draftModel = incident;
    }

    dispatch(incidentDraftLoadedAction(draftModel));
  } catch (err) {
    dispatch(enqueueError(err));
  }
};

export const initializeIncidentForm = incident => async dispatch => {
  dispatch(resetAction());
  try {
    const checkResult = {
      draftIncident: undefined,
      needConfirmDraft: false,
    };

    const { status } = incident;
    if (status === IncidentStatusEnum.closed) {
      throw new Error('Cannot edit closed incident');
    }
    checkResult.draftIncident = {
      ...incident,
      status:
        status === IncidentStatusEnum.draft
          ? status
          : IncidentStatusEnum.openDraft,
    };

    !checkResult.needConfirmDraft &&
      dispatch(setSelectedDraft(checkResult.draftIncident));
    dispatch(incidentFormInitialized(checkResult));
  } catch (err) {
    dispatch(setSelectedDraft(null));
    dispatch(enqueueError(err));
  }
};

export const selectOpenDraft = useOpenDraft => async (dispatch, getState) => {
  const {
    incidentView: { tempDraftIncident, draftIncident },
  } = getState();
  if (!tempDraftIncident) return;
  dispatch(setSelectedDraft(useOpenDraft ? tempDraftIncident : draftIncident));
  dispatch(openDraftSelectedAction(useOpenDraft));
};

export const loadIncidentHistory = incidentId => async dispatch => {
  try {
    dispatch(incidentHistoryResetAction());
    const incidentHistory = (await incidentApi.queryHistory(incidentId)).data;
    dispatch(incidentHistoryLoadedAction(incidentHistory));
  } catch (err) {
    dispatch(enqueueError(err));
  }
};

export const loadHistoryCompare = historyId => async dispatch => {
  dispatch(historyCompareResetAction());
  try {
    const { data } = await incidentApi.getHistoryCompare(historyId);
    dispatch(historyCompareLoadedAction(data));
  } catch (err) {
    dispatch(enqueueError(err));
    throw new Error('Failed to load history.');
  }
};
export const resetHistoryCompare = () => historyCompareResetAction();

export const lockIncident = incidentId => async dispatch => {
  try {
    const { data } = await incidentApi.lock(incidentId);
    dispatch(lockIncidentAction(data));
  } catch (err) {
    dispatch(enqueueError(err));
    throw new Error('Failed to lock incident.');
  }
};

export const unlockIncident = incidentId => async dispatch => {
  try {
    const { data } = await incidentApi.unlock(incidentId);
    dispatch(unlockIncidentAction(data));
  } catch (err) {
    dispatch(enqueueError(err));
    throw new Error('Failed to unlock incident.');
  }
};
