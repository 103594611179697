import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import _ from 'lodash';
import { enqueueError, enqueueSuccess } from '../../modules/global';
import accountApi from '../../apis/account.api';

const COMPLETED = 'signup/SAVED';
const LOADING = 'signup/LOADING';

const savedAction = createAction(COMPLETED);
const loadingAction = createAction(LOADING);

const initState = {
  loading: false,
  completed: false,
  user: undefined,
};

export default handleActions(
  {
    [COMPLETED]: (state, action) =>
      produce(state, draft => {
        draft.completed = true;
        draft.loading = false;
        draft.user = action.payload;
      }),
    [LOADING]: (state, action) =>
      produce(state, draft => {
        draft.loading = action.payload;
      }),
  },
  initState,
);

export const executeSignup = model => async dispatch => {
  try {
    dispatch(loadingAction(true));
    const { data } = await accountApi.signup(model);
    dispatch(savedAction(data));
    return;
  } catch (err) {
    dispatch(loadingAction(false));
    dispatch(enqueueError(err));
    throw err;
  }
};
