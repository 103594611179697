import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Paper, Chip, Tooltip } from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { AndTooltip, OrTooltip } from '../../constants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  paper: {
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  group: {
    margin: 0,
  },
  label: {
    marginLeft: theme.spacing(-1),
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  chip: {
    margin: theme.spacing(1, 0, 1, 1),
    padding: '3px 0',
    height: 'inherit',
    '& .MuiChip-label': {
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '240px',
      },
    },
  },
  chipOperate: {
    margin: theme.spacing(1, 0, 1, 1),
    fontSize: '0.7rem',
    '& .MuiChip-label': {
      [theme.breakpoints.down('xs')]: {
        padding: '8px',
      },
    },
  },
  searchCriteria: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  searchCriteriaPaper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));

const BaseSearchCriteria = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    andOr: 'OR',
  });
  const { options } = props;

  const getName = props.getName;
  const handleDelete = id => {
    props.onDelete(id);
  };
  const handleAndOr = () => {
    const operator = state.andOr === 'OR' ? 'AND' : 'OR';
    setState({
      andOr: operator,
    });
    props.onChangeOperator(operator);
  };

  return (
    <Paper
      className={classes.searchCriteriaPaper}
      hidden={!options || options.length === 0}
    >
      <div className={classes.searchCriteria}>
        <Typography variant="h6" className={classes.title}>
          {props.title} :
        </Typography>
        {options.map((option, index) => (
          <React.Fragment key={option.id}>
            <Chip
              label={getName(option)}
              onClick={props.onClick}
              onDelete={() => {
                handleDelete(option.id);
              }}
              className={classes.chip}
              color="secondary"
            />
            {index < options.length - 1 && (
              <Tooltip title={state.andOr === 'AND' ? AndTooltip : OrTooltip}>
                <Chip
                  label={state.andOr}
                  onClick={handleAndOr}
                  className={classes.chipOperate}
                />
              </Tooltip>
            )}
          </React.Fragment>
        ))}
      </div>
    </Paper>
  );
};

BaseSearchCriteria.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  getName: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  onChangeOperator: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};
BaseSearchCriteria.defaultProps = {
  getName: option => 'Undefined',
  onClick: f => f,
  onDelete: f => f,
};

export default BaseSearchCriteria;
