import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import { enqueueError } from './global';
import {
  AccountDateFormatEnum,
  LookupTypeKey,
  lookupTypeList,
} from '../constants';
import commonApi from '../apis/commons.api';
import notificationsApi from '../apis/notifications.api';
import { convertTree } from '../utils/lookupTreeUtil';
import { setAutoLogoutTime } from '../utils/autoLogoutUtil';

const INIT_DATA_LOADED = 'common/INIT_DATA_LOADED';
const NOTIFICATION_LOADED = 'common/NOTIFICATION_LOADED';
const NOTIFICATION_DISMISSED = 'common/NOTIFICATION_DISMISSED';
const UPDATE_COMPANY_SETTINGS = 'common/UPDATE_COMPANY_SETTINGS';

const initDataLoadedAction = createAction(INIT_DATA_LOADED);
const notificationsLoadedAction = createAction(NOTIFICATION_LOADED);
const notificationDismissedAction = createAction(NOTIFICATION_DISMISSED);
export const updateCompanySettingsActionInCommon = createAction(
  UPDATE_COMPANY_SETTINGS,
);

const initState = {
  countries: [],
  states: [],
  notifications: [],
  lookupLabelSet: {
    [LookupTypeKey.incidentClass]: [],
    [LookupTypeKey.location]: [],
  },
  lookupDataSet: {
    [LookupTypeKey.personType]: [],
    [LookupTypeKey.vehicleInvolvement]: [],
    [LookupTypeKey.incidentClass]: [],
    [LookupTypeKey.location]: [],
    [LookupTypeKey.department]: [],
    [LookupTypeKey.primaryCause]: [],
    [LookupTypeKey.severityType]: [],
    [LookupTypeKey.referredAgency]: [],
    [LookupTypeKey.incidentCause]: [],
  },
  investigators: [],
  userAvatars: [],
  companySettings: {
    autoLogoutTime: 900,
    companyName: undefined,
    dateFormat: AccountDateFormatEnum.international,
    mfa: false,
  },
  fileSizeLimit: {
    maxUploadFileSize: 0, // 50MB
    maxAvatarFileSize: 0, // 10MB
    maxLogoFileSize: 0, // 1MB
  },
  initialized: false,
};

export default handleActions(
  {
    [INIT_DATA_LOADED]: (state, action) =>
      produce(state, draft => {
        const {
          userAvatars,
          states,
          countries,
          notifications,
          lookupLabels,
          lookupMultiples,
          lookupSingles,
          investigators,
          companySettings,
          fileSizeLimit,
        } = action.payload;
        draft.userAvatars = userAvatars;
        draft.states = states;
        draft.countries = countries;
        draft.notifications = notifications;
        draft.lookupLabelSet = {
          [LookupTypeKey.incidentClass]: lookupLabels
            .filter(x => x.type === LookupTypeKey.incidentClass)
            .map(x => x.name),
          [LookupTypeKey.location]: lookupLabels
            .filter(x => x.type === LookupTypeKey.location)
            .map(x => x.name),
        };
        draft.investigators = investigators;

        draft.lookupDataSet = {
          [LookupTypeKey.personType]: lookupSingles.filter(
            x => x.type === LookupTypeKey.personType,
          ),
          [LookupTypeKey.vehicleInvolvement]: lookupSingles.filter(
            x => x.type === LookupTypeKey.vehicleInvolvement,
          ),
          [LookupTypeKey.incidentClass]: convertTree(
            lookupMultiples.filter(x => x.type === LookupTypeKey.incidentClass),
          ),
          [LookupTypeKey.location]: convertTree(
            lookupMultiples.filter(x => x.type === LookupTypeKey.location),
          ),
          [LookupTypeKey.department]: lookupSingles.filter(
            x => x.type === LookupTypeKey.department,
          ),
          [LookupTypeKey.primaryCause]: lookupSingles.filter(
            x => x.type === LookupTypeKey.primaryCause,
          ),
          [LookupTypeKey.referredAgency]: lookupSingles.filter(
            x => x.type === LookupTypeKey.referredAgency,
          ),
          [LookupTypeKey.incidentCause]: lookupSingles.filter(
            x => x.type === LookupTypeKey.incidentCause,
          ),
          [LookupTypeKey.correctiveActionType]: lookupSingles.filter(
            x => x.type === LookupTypeKey.correctiveActionType,
          ),
          [LookupTypeKey.genderType]: lookupSingles.filter(
            x => x.type === LookupTypeKey.genderType,
          ),
          [LookupTypeKey.severityType]: lookupSingles.filter(
            x => x.type === LookupTypeKey.severityType,
          ),
        };

        lookupTypeList
          .filter(x => x.type === 'multiple')
          .forEach(x => {
            lookupMultiples.filter(y => y.type === x.key);
          });
        draft.companySettings = companySettings;
        draft.fileSizeLimit = fileSizeLimit;
        draft.initialized = true;
      }),
    [NOTIFICATION_LOADED]: (state, action) =>
      produce(state, draft => {
        draft.notifications = action.payload;
      }),
    [NOTIFICATION_DISMISSED]: (state, action) =>
      produce(state, draft => {
        draft.notifications = state.notifications.filter(
          x => x.id !== action.payload,
        );
      }),
    [UPDATE_COMPANY_SETTINGS]: (state, action) =>
      produce(state, draft => {
        draft.companySettings = action.payload;
      }),
  },
  initState,
);

export const loadNotifications = () => async dispatch => {
  try {
    const { data } = await notificationsApi.query();
    dispatch(notificationsLoadedAction(data));
  } catch (err) {
    dispatch(enqueueError(err));
  }
};

export const dismissNotification = id => async dispatch => {
  try {
    await notificationsApi.dismiss(id);
    dispatch(notificationDismissedAction(id));
  } catch (err) {
    dispatch(enqueueError(err));
  }
};

export const getInitData = (refresh = false) => async (dispatch, getState) => {
  const {
    common: { initialized },
  } = getState();
  if (!initialized || refresh) {
    try {
      const { data } = await commonApi.getInitData();

      // set auto logout time
      if (data.companySettings && data.companySettings.autoLogoutTime) {
        setAutoLogoutTime(data.companySettings.autoLogoutTime);
      }

      dispatch(initDataLoadedAction(data));
    } catch (err) {
      dispatch(enqueueError(err));
    }
  }
};
