export const getProvinceStateLabel = countryCode => {
  if (!countryCode) {
    return 'State/Prov';
  }

  if (countryCode.toLowerCase() === 'us') {
    return 'State';
  }
  if (countryCode.toLowerCase() === 'ca') {
    return 'Province';
  }

  return 'State/Prov';
};

export default { getProvinceStateLabel };
