import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import _ from 'lodash';
import { enqueueError, enqueueSuccess } from '../../modules/global';
import searchApi from '../../apis/search.api';
import reportApi from '../../apis/report.api';

const RESET = 'search/RESET';
const SEARCHED = 'search/SEARCHED';
const CLOSE_CUSTOM_SAVE = 'search/RESET_SAVED';
const OPEN_CUSTOM_SAVE = 'search/OPEN_SAVE';

const resetAction = createAction(RESET);
const searchedAction = createAction(SEARCHED);
const closeCustomSaveAction = createAction(CLOSE_CUSTOM_SAVE);
const openCustomSaveAction = createAction(OPEN_CUSTOM_SAVE);

const initState = {
  loaded: false,
  openSave: false,
  saveTarget: undefined,
  saveAction: 'create',
  incidentList: [],
};

export default handleActions(
  {
    [RESET]: (state, action) =>
      produce(state, draft => {
        draft.loaded = false;
        draft.openSave = false;
        draft.incidentList = [];
        draft.criteria = {};
      }),
    [SEARCHED]: (state, action) =>
      produce(state, draft => {
        draft.loaded = true;
        draft.incidentList = action.payload;
      }),
    [CLOSE_CUSTOM_SAVE]: (state, action) =>
      produce(state, draft => {
        draft.openSave = false;
      }),
    [OPEN_CUSTOM_SAVE]: (state, action) =>
      produce(state, draft => {
        draft.saveTarget = action.payload.saveTarget;
        draft.saveAction = action.payload.saveAction;
        draft.openSave = true;
      }),
  },
  initState,
);

export const searchIncidents = criteria => async dispatch => {
  dispatch(resetAction());
  try {
    const { data } = await searchApi.search(criteria);
    dispatch(searchedAction(data));
  } catch (err) {
    dispatch(enqueueError(err));
  }
};
export const saveCustom = model => async (dispatch, getState) => {
  try {
    const {
      search: { saveTarget, saveAction },
    } = getState();

    if (saveTarget === 'Report') {
      if (saveAction === 'update') {
        await reportApi.updateCustomReport(model);
        dispatch(closeCustomSaveAction());
        dispatch(enqueueSuccess(`${model.name} is successfully updated.`));
      } else {
        await reportApi.createCustomReport(model);
        dispatch(closeCustomSaveAction());
        dispatch(
          enqueueSuccess('Search criteria is saved to Report successfully.'),
        );
      }
    } else if (saveAction === 'update') {
      await searchApi.updateCustom(model);
      dispatch(closeCustomSaveAction());
      dispatch(enqueueSuccess(`${model.name} is successfully updated.`));
    } else {
      await searchApi.createCustom(model);
      dispatch(closeCustomSaveAction());
      dispatch(
        enqueueSuccess('Search criteria is saved to Dashboard successfully.'),
      );
    }
  } catch (err) {
    dispatch(enqueueError(err));
  }
};
export const openCustom = target => openCustomSaveAction(target);
export const closeCustom = () => closeCustomSaveAction();
export const resetSearch = () => resetAction();
