import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  group: {
    margin: 0,
  },
  label: {
    marginLeft: theme.spacing(-1),
  },
}));

export const chartTypes = [
  {
    value: 'bar',
    label: 'Bar chart',
  },
  {
    value: 'stackedBar',
    label: 'Stacked bar chart',
  },
  {
    value: 'line',
    label: 'Line chart',
  },
  {
    value: 'pie',
    label: 'Pie chart',
  },
  // {
  //   value: '3dPie',
  //   label: '3D pie chart',
  // },
  // {
  //   value: 'geo',
  //   label: 'Geo chart',
  // },
  // {
  //   value: 'excel',
  //   label: 'Excel',
  // },
];

export const getChartTypeInfo = chartType => {
  switch (chartType) {
    case 'stackedBar':
      return { type: 'bar', isStacked: true };
    case 'line':
      return { type: 'line' };
    case 'pie':
      return { type: 'pie', is3D: false };
    // case '3dPie':
    //   return { type: 'PieChart', is3D: true };
    // case 'excel':
    //   return { type: 'excel', is3D: true };
    default:
      return { type: 'bar', isStacked: false };
  }
};

const ChartTypeSelector = props => {
  const classes = useStyles();
  const { chartType } = props;

  const inputHandleChange = event => {
    const { value } = event.target;
    props.onChange(value);
  };
  return (
    <React.Fragment>
      <div className={classes.root}>
        <React.Fragment>
          <div className={classes.lineItem}>
            <FormControl className={classes.formControl}>
              <RadioGroup
                aria-label="chartType"
                name="chartType"
                className={classes.group}
                value={chartType || ''}
                onChange={inputHandleChange}
                row
              >
                {chartTypes.map(type => {
                  return (
                    <FormControlLabel
                      key={type.value}
                      value={type.value}
                      control={<Radio />}
                      label={type.label}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </div>
        </React.Fragment>
      </div>
    </React.Fragment>
  );
};

ChartTypeSelector.propTypes = {
  chartType: PropTypes.string,
  onChange: PropTypes.func,
};

ChartTypeSelector.defaultProps = {
  onChange: () => {},
};

export default ChartTypeSelector;
