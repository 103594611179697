import React from 'react';
import { applyMiddleware, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Typography,
  Paper,
  LinearProgress,
  FormControl,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Box,
  FormHelperText,
} from '@material-ui/core';

import _ from 'lodash';
import * as yup from 'yup';
import { Help } from '@material-ui/icons';
import { getCompany, updateCompanySettings } from './ducks';
import { MeasurementType, companyCountryCodes } from '../../../constants';
import { useForm } from '../../../utils/hooks';
import {
  enqueueError,
  enqueueSuccess,
  enqueueWarning,
} from '../../../modules/global';
import LogoImageForm from '../../../components/forms/LogoImageForm';
import { getAzureFileUrl } from '../../../utils/fileUtil';
import Auth from '../../../auth/Auth';
import { setAutoLogoutTime } from '../../../utils/autoLogoutUtil';
import HelpButton from '../../../components/buttons/HelpButton';
import config from '../../../config';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
    color: theme.palette.text.secondary,
    width: '100%',
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  addUserDialog: {
    width: '80%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  linearProgressSpace: {
    height: '4px',
  },
  autoLogoutLabel: {
    display: 'flex',
    alignItems: 'center',
  },
});

const schema = yup.object().shape({
  companyName: yup.string().max(100),
  measurementType: yup
    .number()
    .oneOf([MeasurementType.metric, MeasurementType.us]),
});

let autoLogoutTimeList = [
  {
    value: 600,
    name: '10 minutes',
  },
  {
    value: 900,
    name: '15 minutes',
  },
];

const CompanyManagement = withStyles(styles)(props => {
  const {
    company,
    companySettings,
    measurementTypes,
    accountDateFormats,
    multipleLookupLabels,
    loaded,
    classes,
    actions,
  } = props;

  const loadCompany = () => {
    actions.getCompany();
  };

  React.useEffect(() => {
    loadCompany();

    if (
      config.app.env === 'test' ||
      config.app.env === 'dev' ||
      config.app.env === 'local'
    ) {
      autoLogoutTimeList = [
        {
          value: 600,
          name: '10 minutes',
        },
        {
          value: 900,
          name: '15 minutes',
        },
        {
          value: 20,
          name: '20 seconds (test)',
        },
      ];
    }
  }, []);

  const {
    formValues,
    updateFormValues,
    hasFormError,
    formHelpText,
    handleFormChange,
    handleFormBlur,
    handleFormSubmit,
  } = useForm({
    onSubmit(values, errors) {
      if (_.isEmpty(errors)) {
        const companySettingsModel = {
          companyName: values && values.companyName,
          phone: values.phone,
          measurementType: values.measurementType,
          dateFormat: values.dateFormat,
          companyCountryCode: values.companyCountryCode,
          billingEmail: values.billingEmail,
          autoLogoutTime: Number(values.autoLogoutTime).valueOf(),
        };
        actions
          .updateCompanySettings(companySettingsModel)
          .then(() => {
            // set auto logout time
            setAutoLogoutTime(
              Number(companySettingsModel.autoLogoutTime).valueOf(),
            );

            actions.enqueueWarning(
              'If Measurement type is updated, please log out and log back in to apply it.',
            );

            actions.enqueueSuccess('Company settings is successfully updated.');
          })
          .catch(err => {});
      } else if (errors.FORM) {
        actions.enqueueError(errors.FORM);
      }
    },
    validationSchema: schema,
  });

  const debounceHandleFormSubmit = _.debounce(handleFormSubmit, 200);

  React.useEffect(() => {
    updateFormValues(companySettings);
  }, [companySettings]);

  const handlePictureChange = url => {
    actions.enqueueSuccess('Logo image is successfully updated.');
  };

  const handlePictureError = error => {
    actions.enqueueError(error);
  };

  const autoLogoutTime = formValues.autoLogoutTime || 900;
  const selectedDateFormat = _.find(accountDateFormats, {
    code: formValues.dateFormat,
  });
  const selectedMeasurmentType = _.find(measurementTypes, {
    code: formValues.measurementType,
  });

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        {!loaded ? (
          <LinearProgress />
        ) : (
          <div className={classes.linearProgressSpace} />
        )}
        {/* <div className={classes.title}>
          <Typography variant="h4">Settings</Typography>
        </div>
        <Typography variant="body1" gutterBottom>
          Global settings
        </Typography> */}

        <Box display="flex" flex="1" flexDirection="row">
          <Box>
            <div className={classes.lineItem}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="companyName"
                  label="Company name"
                  margin="none"
                  name="companyName"
                  value={formValues.companyName || ''}
                  onChange={handleFormChange}
                  onBlur={handleFormBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={hasFormError('companyName')}
                  helperText={formHelpText('companyName')}
                  inputProps={{ autoComplete: 0 }}
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <TextField
                  label="Phone"
                  margin="none"
                  name="phone"
                  value={formValues.phone || ''}
                  onChange={handleFormChange}
                  onBlur={handleFormBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={hasFormError('phone')}
                  helperText={formHelpText('phone')}
                  inputProps={{ autoComplete: 0, maxLength: 20 }}
                  required
                  placeholder="ex) 999-999-9999"
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="measurement-type-label-placeholder">
                  Measurement type
                </InputLabel>
                <Select
                  displayEmpty
                  name="measurementType"
                  value={formValues.measurementType || 0}
                  onChange={handleFormChange}
                  className={classes.selectEmpty}
                >
                  {measurementTypes.map(type => (
                    <MenuItem key={type.code} value={type.code}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {selectedMeasurmentType ? selectedMeasurmentType.desc : ''}
                </FormHelperText>
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="date-format-label-placeholder">
                  Date format
                </InputLabel>
                <Select
                  displayEmpty
                  name="dateFormat"
                  value={formValues.dateFormat || 0}
                  onChange={handleFormChange}
                  className={classes.selectEmpty}
                >
                  {accountDateFormats.map(type => (
                    <MenuItem key={type.code} value={type.code}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {selectedDateFormat ? selectedDateFormat.desc : ''}
                </FormHelperText>
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="company-country-label-placeholder">
                  Company country
                </InputLabel>
                <Select
                  displayEmpty
                  name="companyCountryCode"
                  value={formValues.companyCountryCode || 'XX'}
                  onChange={handleFormChange}
                  className={classes.selectEmpty}
                >
                  {companyCountryCodes.map(type => (
                    <MenuItem key={type.code} value={type.code}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputLabel
                  shrink
                  htmlFor="auto-logout-time-label-placeholder"
                  className={classes.autoLogoutLabel}
                >
                  Auto Logout
                  <HelpButton
                    popupId="help-company-management-auto-logout-time-label-placeholder"
                    message="Select how long before the system will auto logout an inactive user. Note: This setting is for all users."
                  />
                </InputLabel>
                <Select
                  displayEmpty
                  name="autoLogoutTime"
                  value={autoLogoutTime}
                  onChange={handleFormChange}
                  className={classes.selectEmpty}
                >
                  {autoLogoutTimeList.map(type => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Box>
          <Box>
            <InputLabel shrink>Logo</InputLabel>
            <LogoImageForm
              logoImage={formValues.logoImage}
              onChange={handlePictureChange}
              onError={handlePictureError}
            />
            <Typography variant="body2">
              * upload limit: {props.maxLogoFileSize} MB
            </Typography>
          </Box>
        </Box>

        <div className="action-container">
          <Button
            variant="contained"
            color="primary"
            onClick={debounceHandleFormSubmit}
          >
            Save
          </Button>
        </div>
      </Paper>
    </React.Fragment>
  );
});

function mapStateToProps({ administration, common }) {
  return {
    loaded: administration.company.loaded,
    companySettings: common.companySettings,
    measurementTypes: administration.company.measurementTypes,
    accountDateFormats: administration.company.accountDateFormats,
    maxLogoFileSize: common.fileSizeLimit.maxLogoFileSize,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = bindActionCreators(
    {
      getCompany,
      updateCompanySettings,
      enqueueError,
      enqueueWarning,
      enqueueSuccess,
    },
    dispatch,
  );
  return { actions, dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyManagement);
