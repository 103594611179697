import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography, Paper, Chip, Tooltip } from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { LookupDataSetDef } from '../../components/propTypeDefs';
import { buildLookupPath } from '../../utils/lookupTreeUtil';
import { LookupTypeKey, OrTooltip } from '../../constants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  paper: {
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  group: {
    margin: 0,
  },
  label: {
    marginLeft: theme.spacing(-1),
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  chip: {
    margin: theme.spacing(1, 0, 1, 1),
    padding: '3px 0',
    height: 'inherit',
    '& .MuiChip-label': {
      wordWrap: 'break-word',
      whiteSpace: 'normal',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '240px',
      },
    },
  },
  chipOperate: {
    margin: theme.spacing(1, 0, 1, 1),
    fontSize: '0.7rem',
    '& .MuiChip-label': {
      [theme.breakpoints.down('xs')]: {
        padding: '8px',
      },
    },
  },
  searchCriteria: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  searchCriteriaPaper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));

const LookupMultipleSearchCriteria = props => {
  const classes = useStyles();
  const { options } = props;

  const handleDelete = id => {
    props.onDelete(id);
  };

  const getName = option => {
    const path = buildLookupPath(
      props.lookupDataSet[props.lookupType],
      option.lookupId,
    );
    return path;
  };

  return (
    <Paper
      className={classes.searchCriteriaPaper}
      hidden={!options || options.length === 0}
    >
      <div className={classes.searchCriteria}>
        <Typography variant="h6" className={classes.title}>
          {props.title} :
        </Typography>
        {options.map((option, index) => (
          <React.Fragment key={option.id}>
            <Chip
              label={getName(option)}
              onDelete={() => {
                handleDelete(option.id);
              }}
              onClick={props.onClick}
              className={classes.chip}
              color="secondary"
            />
            {index < options.length - 1 && (
              <Tooltip title={OrTooltip}>
                <Chip label="OR" className={classes.chipOperate} />
              </Tooltip>
            )}
          </React.Fragment>
        ))}
      </div>
    </Paper>
  );
};

LookupMultipleSearchCriteria.propTypes = {
  title: PropTypes.string.isRequired,
  lookupType: PropTypes.string.isRequired,
  lookupDataSet: LookupDataSetDef.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
};
LookupMultipleSearchCriteria.defaultProps = {
  onDelete: f => f,
  onClick: f => f,
};

const mapStateToProps = ({ common }) => ({
  lookupDataSet: common.lookupDataSet,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LookupMultipleSearchCriteria);
