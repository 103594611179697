import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import * as yup from 'yup';
import {
  Button,
  TextField,
  FormControl,
  CircularProgress,
  LinearProgress,
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';

import AttachmentIcon from '@material-ui/icons/Attachment';
import AlertDialog from './AlertDialog';
import BaseDialog from './BaseDialog';
import { useForm } from '../../utils/hooks';
import { IncidentAttachmentDef } from '../propTypeDefs';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 600,
    flexGrow: 1,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  bottomMargin: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // height: 80,
    // flexWrap: 'wrap',
  },
  buttonSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    // alignItems: 'center',
    // height: 80,
    flexWrap: 'wrap',
  },
  fileButton: {
    height: 50,
  },
}));

const schema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .max(100, 'Name must be at most 100 characters.')
    .required(),
  comments: yup
    .string()
    .nullable()
    .max(500, 'Description must be at most 500 characters.'),
  file: yup.mixed().required('A file is required'),
});

const LoadingProgress = props => {
  return (
    <div display="flex" style={{ flexGrow: 1 }}>
      <LinearProgress color="secondary" />
    </div>
  );
};

const AddAttachmentDialog = props => {
  const classes = useStyles();
  const fileRef = React.useRef();
  const {
    formValues,
    updateFormValues,
    hasFormError,
    formHelpText,
    handleFormChange,
    handleFormBlur,
    handleFormSubmit,
  } = useForm({
    validationSchema: schema,
    onSubmit: (values, errors) => {
      if (_.isEmpty(errors)) {
        props.onAttachmentAdded(values);
      }
    },
  });

  const onAttachmentAdded = () => {
    handleFormSubmit();
  };

  const debounceAttachmentAdded = _.debounce(onAttachmentAdded, 200);

  const inputHandleChange = event => {
    handleFormChange(event);
  };
  const raiseFormEvent = (name, value) => {
    inputHandleChange({ target: { name, value } });
  };
  const handleFileChange = e => {
    const { files } = e.target;
    if (!files || !files.length) return;
    // console.info('##file ', files[0]);
    raiseFormEvent('file', files[0]);
  };

  React.useEffect(() => {
    updateFormValues(props.attachment);
  }, [props.attachment]);

  return (
    <BaseDialog
      open={props.open}
      onClose={props.onClose}
      title="Add Attachment"
      actionContent={
        <>
          <Button
            onClick={debounceAttachmentAdded}
            variant="contained"
            color="primary"
            disabled={props.uploading}
          >
            Ok
          </Button>
          <Button
            onClick={props.onCancel}
            color="default"
            pl={3}
            disabled={props.uploading}
          >
            Cancel
          </Button>
        </>
      }
    >
      {props.uploading && <LoadingProgress />}
      <FormControl className={classes.formControl}>
        <TextField
          required
          name="name"
          label="Name"
          value={formValues.name || ''}
          onChange={inputHandleChange}
          margin="none"
          onBlur={handleFormBlur}
          InputLabelProps={{
            shrink: true,
          }}
          error={hasFormError('name')}
          helperText={formHelpText('name')}
          inputProps={{ autoComplete: 0 }}
          disabled={props.uploading}
        />
      </FormControl>

      <div className={classes.lineItem}>
        <FormControl className={classes.formControlFull}>
          <TextField
            required
            type="file"
            name="file"
            label="File"
            ref={fileRef}
            onChange={handleFileChange}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
            error={hasFormError('file')}
            helperText={formHelpText('file')}
            disabled={props.uploading}
          />
        </FormControl>
      </div>
      <div className={classes.lineItem}>
        <Typography variant="body2">
          * upload limit: {props.uploadLimit} MB
        </Typography>
      </div>
    </BaseDialog>
  );
};

AddAttachmentDialog.propTypes = {
  onAttachmentAdded: PropTypes.func,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  open: PropTypes.bool.isRequired,
  uploading: PropTypes.bool,
  attachment: IncidentAttachmentDef,
  uploadLimit: PropTypes.number.isRequired,
};

AddAttachmentDialog.defaultProps = {
  uploading: false,
  onAttachmentAdded: () => {},
  onClose: () => {},
  onCancel: () => {},
  attachment: undefined,
  uploadLimit: 1,
};

export default AddAttachmentDialog;
