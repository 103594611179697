import { isNullOrUndefined } from 'util';

export const convertCurrencyToNumber = currency => {
  if (isNullOrUndefined(currency)) {
    return undefined;
  }
  if (typeof currency === 'number') {
    return currency;
  }
  if (typeof currency === 'string') {
    const numberString = currency.replace(/[^0-9.-]+/g, '').trim();
    if (numberString.length === 0) {
      return undefined;
    }

    return Number(currency.replace(/[^0-9.-]+/g, '')).valueOf();
  }
  return undefined;
};

export const convertNumberToCurrency = number => {
  if (!number) return undefined;

  return number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const displayCentAsDollar = cent => {
  const dollars = (cent / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return dollars;
};

export default {
  convertCurrencyToNumber,
  convertNumberToCurrency,
  displayCentAsDollar,
};
