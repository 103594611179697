import BaseApi from './BaseApi';

class UserApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl += '/users';
  }

  async getProfile() {
    return await this.get(`${this.baseUrl}`);
  }

  async getUser(id) {
    return await this.get(`${this.baseUrl}/${id}`);
  }

  async updateProfile(profile) {
    return await this.put(`${this.baseUrl}`, profile);
  }

  async queryUsers(params) {
    return await this.get(`${this.baseUrl}/search`, { params });
  }

  async create(user) {
    return await this.post(`${this.baseUrl}`, user);
  }

  async update(id, user) {
    return await this.put(`${this.baseUrl}/${id}`, user);
  }

  async remove(id) {
    return await this.delete(`${this.baseUrl}/${id}`);
  }

  async deactivate(id) {
    return await this.put(`${this.baseUrl}/deactivate/${id}`);
  }

  async activate(id) {
    return await this.put(`${this.baseUrl}/activate/${id}`);
  }

  async getRemainCount() {
    return await this.get(`${this.baseUrl}/remains`);
  }

  async createSession() {
    return await this.post(`${this.baseUrl}/sessions`);
  }

  async deleteSession() {
    return await this.delete(`${this.baseUrl}/sessions`);
  }

  async requestChangePassword() {
    return await this.get(`${this.baseUrl}/requestChangePassword`);
  }

  async getInvestigators() {
    return await this.get(`${this.baseUrl}/investigators`);
  }

  async uploadPicture(file) {
    const form = new FormData();
    form.append('file', file);

    return await this.post(`${this.baseUrl}/avatars`, form);
  }

  async resendEmailVerification(userId) {
    return await this.post(`${this.baseUrl}/emailVerification`, {
      userId,
    });
  }

  async resendMyEmailVerification() {
    return await this.post(`${this.baseUrl}/myEmailVerification`);
  }
}

export default new UserApi();
