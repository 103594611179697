import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Input,
  Fab,
  Typography,
  InputLabel,
  FormControl,
  TextField,
  IconButton,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import uuid from 'uuid/v4';
import produce from 'immer';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import IncidentPersonItem from './IncidentPersonItem';
import { IncidentPersonDef, LookupDef } from '../../components/propTypeDefs';
import ExpansionCard from '../../components/cards/ExpansionCard';
import { searchPerson, resetPersonList } from './ducks';
import { enqueueError } from '../../modules/global';
import BaseDialog from '../../components/dialogs/BaseDialog';
import AlertDialogYesNo from '../../components/dialogs/AlertDialogYesNo';
import PersonList from './PersonList';
import { getPersonName } from '../../utils/nameUtil';
import { useMediaInfo } from '../../utils/hooks';

const useStyles = makeStyles(theme => ({
  expansionCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  expansionCardItem: {},
  personItem: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
  },
  itemAddButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
  },
  formControl: {
    margin: theme.spacing(0, 1),
    minWidth: 200,
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1,
    },
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  addButton: {
    marginLeft: theme.spacing(1),
  },
  searchForm: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  personList: {
    minHeight: theme.spacing(4),
  },
  cardDialogTitle: {
    display: 'flex',
  },
  cardDialogTitleText: {
    flexGrow: 1,
  },
}));

const IncidentPersonCard = props => {
  const classes = useStyles();
  const { persons, incidentId } = props;
  const [personTypeDialogOpen, setPersonTypeDialogOpen] = React.useState(false);
  const [state, setState] = React.useState({
    persons,
    selectedPersonId:
      persons && persons.length === 1 ? persons[0].id : undefined,
    searchPersonDialogOpen: false,
    search: {},
    invalidSearchForm: false,
  });
  const { isSmaller } = useMediaInfo({ minBreakPoint: 'sm' });

  // React.useEffect(() => {
  //   props.onChange({ persons: state.persons });
  // }, [state.persons]);

  React.useEffect(() => {
    setState({ ...state, persons });
  }, [props.persons]);

  const emitData = data => {
    props.onChange({ persons: data });
  };

  const addPersonWithModel = _.debounce(model => {
    const newList = [...state.persons, model];
    setState({
      ...state,
      persons: newList,
      selectedPersonId: model.id,
      searchPersonDialogOpen: false,
      search: {},
      invalidSearchForm: false,
    });
    emitData(newList);
    props.resetPersonList();
  }, 200);

  const addPerson = _.debounce(() => {
    if (!incidentId) return;
    setPersonTypeDialogOpen(true);
  }, 200);

  const addPersonWithPersonType = isEmployee => {
    if (!incidentId) return;
    // const personCount = state.persons.length;
    const firstName = ''; // `Person ${personCount + 1}`;
    const newPerson = { id: uuid(), firstName, incidentId, isEmployee };
    addPersonWithModel(newPerson);
    setPersonTypeDialogOpen(false);
  };

  const onPersonCardExpanded = (expanded, personId) => {
    setState({
      ...state,
      selectedPersonId: expanded ? personId : undefined,
    });
  };

  const onPersonDeleted = personId => {
    const person = _.find(persons, { id: personId });
    const newList = state.persons.filter(x => x.id !== personId);
    setState({
      ...state,
      persons: newList,
    });
    emitData(newList);
  };
  const personChanged = person => {
    const nextState = produce(state, draft => {
      const found = draft.persons.find(x => x.id === person.id);
      Object.assign(found, person);
    });
    setState(nextState);
    emitData(nextState.persons);
  };

  const validateSearchForm = searchState => {
    const invalidSearchForm = !searchState.name;

    setState({ ...state, invalidSearchForm });
    return !invalidSearchForm;
  };

  const handleSearchChange = e => {
    const { name, value } = e.target;
    const nextSearch = { ...state.search, [name]: value };
    setState({ ...state, search: nextSearch });
  };

  const handleSearch = _.debounce(() => {
    if (!validateSearchForm(state.search)) return;
    const { name } = state.search;
    props.searchPerson(name);
  }, 200);

  const openSearchDialog = () => {
    setState({ ...state, searchPersonDialogOpen: true });
  };

  const inputKeyDown = event => {
    if (event.charCode === 13 || event.keyCode === 13) {
      handleSearch();
    }
  };

  const closeSearchDialog = () => {
    props.resetPersonList();
    setState({
      ...state,
      searchPersonDialogOpen: false,
      search: {},
      invalidSearchForm: false,
    });
  };

  const handlePersonItemCommand = (command, value) => {
    const { id: personId, ...others } = value;

    const exists = state.persons.some(x => x.personId === personId);
    if (exists) {
      props.enqueueError(`'${getPersonName(value)}' already exists.`);
      return;
    }

    // remove null values
    const compressed = _.omitBy(others, _.isNull);
    const newPerson = { ...compressed, id: uuid(), personId, incidentId };
    addPersonWithModel(newPerson);
  };

  const searchDialogTitle = () => {
    const titleText = 'Select Person';
    return (
      <div className={classes.cardDialogTitle}>
        <div className={classes.cardDialogTitleText}>{titleText}</div>
        <IconButton onClick={() => closeSearchDialog()}>
          <CloseIcon />
        </IconButton>
      </div>
    );
  };

  return (
    <ExpansionCard
      title={`WHO - Persons (${state.persons.length})`}
      helpMessage="Add information about any people who were involved in the incident. Tip: Search to see if the person already exists. If not, select Add to add the person's details to the incident record."
    >
      <div className={classes.expansionCardContainer}>
        {state.persons.map((person, index) => {
          const errors =
            props.errors &&
            props.errors
              .filter(x => {
                return x.path.includes(`persons[${index}]`);
              })
              .map(x => {
                return {
                  path: x.path.replace(`persons[${index}].`, ''),
                  message: x.message,
                };
              });
          return (
            <div className={classes.personItem} key={person.id}>
              <IncidentPersonItem
                person={person}
                expanded={state.selectedPersonId === person.id}
                onCardExpanded={onPersonCardExpanded}
                onDeleted={onPersonDeleted}
                onChange={personChanged}
                personTypeLookups={props.personTypeLookups}
                departmentLookups={props.departmentLookups}
                genderTypeLookups={props.genderTypeLookups}
                errors={errors}
              />
            </div>
          );
        })}
        <div className={classes.itemAddButton}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => openSearchDialog()}
          >
            Search person
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.addButton}
            onClick={addPerson}
          >
            Add
          </Button>
        </div>
      </div>
      {state.searchPersonDialogOpen && (
        <BaseDialog
          open={state.searchPersonDialogOpen}
          maxWidth="md"
          title={searchDialogTitle()}
          onClose={() => closeSearchDialog()}
          fullScreen={isSmaller}
        >
          <div className={classes.searchForm}>
            <FormControl className={classes.formControlLong}>
              <TextField
                autoFocus
                name="name"
                label="Person name"
                placeholder="Enter first, last or first and last name"
                onChange={handleSearchChange}
                onKeyDown={inputKeyDown}
                error={state.invalidSearchForm}
                helperText={
                  state.invalidSearchForm
                    ? 'Please enter "Person name".'
                    : undefined
                }
              />
            </FormControl>

            <Fab
              size="small"
              color="secondary"
              className={classes.button}
              onClick={() => handleSearch()}
            >
              <SearchIcon />
            </Fab>
          </div>
          <div className={classes.personList}>
            <PersonList
              persons={props.personList}
              onItemCommand={handlePersonItemCommand}
            />
          </div>
        </BaseDialog>
      )}
      {personTypeDialogOpen && (
        <AlertDialogYesNo
          title="Person type"
          message="Is the person an employee?"
          open={personTypeDialogOpen}
          onYes={() => addPersonWithPersonType(true)}
          onNo={() => addPersonWithPersonType(false)}
        />
      )}
    </ExpansionCard>
  );
};

IncidentPersonCard.propTypes = {
  onChange: PropTypes.func,
  incidentId: PropTypes.string,
  persons: PropTypes.arrayOf(IncidentPersonDef),
  personTypeLookups: PropTypes.arrayOf(LookupDef),
  departmentLookups: PropTypes.arrayOf(LookupDef),
  genderTypeLookups: PropTypes.arrayOf(LookupDef),
  personList: PropTypes.arrayOf(IncidentPersonDef).isRequired,
  resetPersonList: PropTypes.func.isRequired,
  searchPerson: PropTypes.func.isRequired,
  enqueueError: PropTypes.func.isRequired,
  errors: PropTypes.object,
};
IncidentPersonCard.defaultProps = {
  onChange: f => f,
  persons: [],
  incidentId: undefined,
  personTypeLookups: [],
  departmentLookups: [],
  genderTypeLookups: [],
};

const mapStateToProps = ({ newIncident }) => ({
  personList: newIncident.personList,
});

const mapDispatchToProps = {
  searchPerson,
  resetPersonList,
  enqueueError,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentPersonCard);
