import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, FormControl, TextField, Box } from '@material-ui/core';
import * as yup from 'yup';
import _ from 'lodash';
import uuid from 'uuid/v4';
import produce from 'immer';
import BaseDialog from '../../../components/dialogs/BaseDialog';
import { useForm } from '../../../utils/hooks';
import { LookupTypeKey } from '../../../constants';
import { LookupTypeDef } from '../../../components/propTypeDefs';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  redButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  rightActions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const labelConfig = {
  [LookupTypeKey.incidentClass]: {
    maxLength: 4,
    formName: 'Incident class labels',
  },
  [LookupTypeKey.location]: {
    maxLength: 5,
    formName: 'Location lookup labels',
  },
  [LookupTypeKey.incidentCause]: {
    maxLength: 2,
    formName: 'Root cause labels',
  },
};

const schema = yup.object().shape({});

const LookupLabelFormDialog = props => {
  const classes = useStyles();
  const { labels, open, lookupType } = props;
  const [state, setState] = React.useState({
    currentLabels: [],
  });

  const handleSubmit = e => {
    props.onSave(state.currentLabels);
  };
  const handleChangeLabel = index => e => {
    const { value } = e.target;
    const nextState = produce(state, draft => {
      draft.currentLabels[index] = value;
    });
    setState(nextState);
  };

  React.useEffect(() => {
    const values = [];
    for (let i = 0; i < labelConfig[lookupType.key].maxLength; i += 1) {
      values.push(labels[i] || '');
    }
    setState({ ...state, currentLabels: values });
  }, []);

  return (
    <BaseDialog
      open={open}
      maxWidth="md"
      title="Update labels"
      onClose={props.onClose}
      actionContent={
        <>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Ok
          </Button>
          <Button onClick={props.onClose} color="default" pl={3}>
            Cancel
          </Button>
        </>
      }
    >
      <Box
        flexDirection="row"
        flexWrap="wrap"
        className={classes.formGroup}
        p={1}
      >
        {state.currentLabels.map((lb, index) => (
          /* eslint-disable-next-line */
          <FormControl key={index} className={classes.formControl}>
            <TextField
              label={`Level ${index + 1}`}
              margin="none"
              value={state.currentLabels[index] || ''}
              onChange={handleChangeLabel(index)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ autoComplete: 0 }}
            />
          </FormControl>
        ))}
      </Box>
    </BaseDialog>
  );
};

LookupLabelFormDialog.propTypes = {
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  labels: PropTypes.arrayOf(PropTypes.string),
  open: PropTypes.bool,
  lookupType: LookupTypeDef.isRequired,
};
LookupLabelFormDialog.defaultProps = {
  onClose: f => f,
  onSave: f => f,
  labels: [],
  open: false,
};

export default LookupLabelFormDialog;
