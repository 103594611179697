export class PaginateModel {
  page = 0;

  pageSize;

  constructor({ p = 0, psz = 10 }) {
    this.pageSize = Number(psz).valueOf();
    this.page = Number(p).valueOf();
  }
}

export class QueryModel {
  constructor(filter, pagingOptions = {}) {
    this.filter = filter;
    this.paginator = new PaginateModel(pagingOptions);
  }

  nextPage = () => {
    this.paginator.page += 1;
  };

  firstPage = () => {
    this.paginator.page = 0;
  };

  toParams = () => ({
    ...this.filter.toParams(),
    p: this.paginator.page,
    psz: this.paginator.pageSize,
  });
}

export class BaseQueryFilter {
  key;

  value;

  constructor(data = {}) {
    Object.assign(this, data);
  }

  toParams() {
    return { fk: this.key, fv: this.value };
  }
}

export class UserQueryFilter extends BaseQueryFilter {}
export class LookupQueryFilter extends BaseQueryFilter {
  type;

  toParams() {
    return { ...super.toParams(), type: this.type };
  }
}
export class CityQueryFilter extends BaseQueryFilter {
  countryCode;

  name;

  toParams() {
    return {
      ...super.toParams(),
      countryCode: this.countryCode,
      name: this.name,
    };
  }
}
