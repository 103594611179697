import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import { Code } from '@material-ui/icons';
import Auth from '../auth/Auth';

export const ENQUEUE_TOAST = 'global/ENQUEUE_TOAST';
const CLOSE_TOAST = 'global/CLOSE_TOAST';
const REMOVE_TOAST = 'global/REMOVE_TOAST';

export const enqueueToast = createAction(ENQUEUE_TOAST); // {message, options:{canDismiss, variant}}
export const closeToast = createAction(CLOSE_TOAST); // key?
export const removeToast = createAction(REMOVE_TOAST); // key
export const enqueueError = error => dispatch => {
  const { message = error, statusCode } = error;
  dispatch(enqueueToast({ message, options: { variant: 'error' } }));
  if (statusCode === 401 && message === 'Token is expired') {
    Auth.logout();
  }
};
export const enqueueSuccess = message => dispatch => {
  dispatch(enqueueToast({ message, options: { variant: 'success' } }));
};
export const enqueueWarning = message => dispatch => {
  dispatch(
    enqueueToast({
      message,
      options: {
        variant: 'warning',
        autoHideDuration: 30000,
        canDismiss: true,
      },
    }),
  );
};
const initState = {
  notifications: [],
};

export default handleActions(
  {
    [ENQUEUE_TOAST]: (state, action) =>
      produce(state, draft => {
        // const { message, options: { variant, canDismiss = true } } = action.payload;
        const notification = action.payload;
        notification.key = Date.now() + Math.random();
        draft.notifications.push(notification);
      }),
    [CLOSE_TOAST]: (state, action) =>
      produce(state, draft => {
        const key = action.payload;
        draft.notifications.forEach(n => {
          if (!key || n.key === key) {
            n.dismissed = true;
          }
        });
      }),
    [REMOVE_TOAST]: (state, action) =>
      produce(state, draft => {
        const key = action.payload;
        draft.notifications = state.notifications.filter(n => n.key !== key);
      }),
  },
  initState,
);
