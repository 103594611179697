import * as yup from 'yup';
import { passwordPattern, uuidPattern } from '../constants';
import config from '../config';

export const profileSchema = {
  email: yup
    .string()
    .email('Email is invalid')
    .required('required'),
  firstName: yup
    .string()
    .max(100, 'First name cannot be greater than 100')
    .required('required'),
  lastName: yup
    .string()
    .max(100, 'Last name cannot be greater than 100')
    .required('required'),
};
export const passwordSchema =
  config.app.env !== 'prod'
    ? {
        password: yup
          .string()
          .min(4, 'Password length cannot be less than 4')
          .required('required'),
      }
    : {
        password: yup
          .string()
          .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            'Password must contain at least 8 characters, one uppercase, one number and one special case character (e.g. !@#$%^&*)',
          )
          .required('required'),
      };
export const userDetailSchema = {
  middleName: yup
    .string()
    .nullable()
    .max(100, 'Middle initial cannot be greater than 100'),
  division: yup
    .string()
    .nullable()
    .max(100, 'Division cannot be greater than 100'),
  location: yup
    .string()
    .nullable()
    .max(100, 'Location cannot be greater than 100'),
  department: yup
    .string()
    .nullable()
    .max(100, 'Department cannot be greater than 100'),
  company: yup
    .string()
    .nullable()
    .max(100, 'Company cannot be greater than 100'),
  position: yup
    .string()
    .nullable()
    .max(100, 'Position cannot be greater than 100'),
  phone: yup
    .string()
    .nullable()
    .max(20, 'Phone number cannot be greater than 20'),
  fax: yup
    .string()
    .nullable()
    .max(20, 'Fax number cannot be greater than 20'),
  mfa: yup.boolean().nullable(),
  companyStartDate: yup.date().nullable(),
};
export const userGroupSchema = {
  userGroupId: yup
    .string()
    .matches(uuidPattern, 'Id format is invalid')
    .required('required'),
};

export const incidentSchema = yup.object().shape({
  reportedDate: yup
    .string()
    .nullable()
    .required("'When / Date reported' is required"),
  occurredDate: yup
    .string()
    .nullable()
    .required("'When / Date occurred' is required"),
  lastClassId: yup
    .string()
    .nullable()
    .required("'What' is required"),
  persons: yup.array().of(
    yup.object().shape({
      firstName: yup
        .string()
        .nullable()
        .max(100)
        .required("'Person's first name is required"),
    }),
  ),
  companies: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .nullable()
        .max(100)
        .required("'Legal entity's name is required"),
    }),
  ),
});
