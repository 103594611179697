import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
// import Link from '@material-ui/core/Link';
// import Button from '../components/Button';
import Button from '@material-ui/core/Button';
// import Typography from '../components/Typography';
import Typography from '@material-ui/core/Typography';
import MainContentFirstLayout from './MainContentFirstLayout';
import Auth from '../../auth/Auth';
import history from '../../history';
import { useMediaInfo } from '../../utils/hooks';

const backgroundImage = require('../../images/hai-3347787.jpg');

const styles = theme => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundPosition: 'center',
  },
  button: {
    minWidth: 200,
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
});

function MainContentFirst(props) {
  const { classes } = props;
  const isAuthenticated = Auth.isAuthenticated;
  const { isMobile } = useMediaInfo({
    minBreakPoint: 'sm',
  });

  const signup = () => {
    history.push('/signup');
  };

  return (
    <MainContentFirstLayout backgroundClassName={classes.background}>
      {/* Increase the network loading priority of the background image. */}
      <img style={{ display: 'none' }} src={`url(${backgroundImage})`} alt="" />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        Incident Xpress Home
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        className={classes.h5}
      >
        Incident management service for your business.
      </Typography>
      {!isMobile && !isAuthenticated && (
        <Button
          color="primary"
          variant="contained"
          size="large"
          className={classes.button}
          onClick={signup}
        >
          SIGN UP
        </Button>
      )}
      <Typography variant="body2" color="inherit" className={classes.more}>
        Discover the experience
      </Typography>
    </MainContentFirstLayout>
  );
}

MainContentFirst.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MainContentFirst);
