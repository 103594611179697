import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Typography,
  Paper,
  IconButton,
  LinearProgress,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';
import uuid from 'uuid/v4';
import auth from '../../../auth/Auth';
import UserGroupItem from './UserGroupItem';
import { getAllGroups, addGroup, setSelectedUserGroup } from './ducks';
import { queryUsers } from '../../../modules/administration/userManagement';
import {
  QueryModel,
  UserQueryFilter,
} from '../../../models/helpers/QueryModel';
import UserGroupEdit from './UserGroupEdit';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
    color: theme.palette.text.secondary,
    width: '100%',
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  lineItem: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  addUserDialog: {
    width: '80%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  userGroupItem: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
  },
  inactiveUserHiddenSwitch: {
    marginLeft: theme.spacing(2),
  },
});

const WorkGroup = withStyles(styles)(props => {
  const { classes, userGroups, actions, loaded, selectedUserGroup } = props;

  const queryModel = new QueryModel(new UserQueryFilter());

  const getAllUsers = () => {
    actions.queryUsers();
  };

  React.useEffect(() => {
    actions.getAllGroups();
    getAllUsers(true);
  }, []);
  const [addUserGroupOpen, setAddUserGroupOpen] = React.useState(false);
  const [inactiveUserHidden, setInactiveUserHidden] = React.useState(true);

  const onCardExpanded = (expanded, userGroupId) => {
    const userGroup = _.find(userGroups, { id: userGroupId });
    actions.setSelectedUserGroup(expanded ? userGroup : undefined);
  };
  const isSelected = groupId => {
    const opened = !!(selectedUserGroup && selectedUserGroup.id === groupId);
    return opened;
  };

  const handleAddGroup = () => {
    let index;
    let name = index ? `New Group (${index})` : 'New Group';

    while (_.some(userGroups, { name })) {
      if (index) {
        index += 1;
      } else {
        index = 1;
      }
      name = index ? `New Group (${index})` : 'New Group';
    }

    const defaultGroup = {
      id: uuid(),
      isAdmin: false,
      name,
      userPrivileges: [],
    };
    actions.addGroup(defaultGroup);
  };

  const addUserGroup = model => {
    model.id = uuid();
    actions
      .addGroup(model)
      .then(() => {
        setAddUserGroupOpen(false);
      })
      .catch(err => {
        // if failed, reset the new id
        model.id = undefined;
      });
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        {!loaded && (
          <div>
            <LinearProgress />
          </div>
        )}

        <div className={classes.title}>
          <Typography variant="h4">
            Workgroups
            <FormControlLabel
              className={classes.inactiveUserHiddenSwitch}
              control={
                <Switch
                  checked={inactiveUserHidden}
                  onClick={() => {
                    setInactiveUserHidden(!inactiveUserHidden);
                  }}
                  name="inactiveUserHidden"
                />
              }
              label={
                <Typography
                  variant="body2"
                  onClick={() => {
                    setInactiveUserHidden(!inactiveUserHidden);
                  }}
                >
                  Inactive user hidden
                </Typography>
              }
            />
          </Typography>

          <IconButton
            color="primary"
            aria-label="Add"
            className={classes.margin}
            onClick={() => {
              setAddUserGroupOpen(true);
            }}
            disabled={!auth.hasPrivilege(['USERGROUP_ADD'])}
          >
            <AddIcon fontSize="large" />
          </IconButton>
        </div>
        {userGroups &&
          userGroups.map(userGroup => {
            return (
              <div className={classes.userGroupItem} key={userGroup.id}>
                <UserGroupItem
                  userGroup={userGroup}
                  expanded={isSelected(userGroup.id)}
                  onCardExpanded={onCardExpanded}
                  inactiveUserHidden={inactiveUserHidden}
                />
              </div>
            );
          })}
      </Paper>
      {addUserGroupOpen && (
        <UserGroupEdit
          maxWidth="lg"
          open={addUserGroupOpen}
          onClose={() => {
            setAddUserGroupOpen(false);
          }}
          userGroup={{
            isAdmin: false,
            userPrivileges: [],
          }}
          onSaveUserGroup={addUserGroup}
        />
      )}
    </React.Fragment>
  );
});

function mapStateToProps({ administration }) {
  return {
    loaded: administration.userGroups.loaded,
    userGroups: administration.userGroups.userGroups,
    selectedUserGroup: administration.userGroups.selectedUserGroup,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = bindActionCreators(
    {
      getAllGroups,
      addGroup,
      setSelectedUserGroup,
      queryUsers,
    },
    dispatch,
  );
  return { actions, dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkGroup);
