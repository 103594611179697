import React from 'react';
import PropTypes from 'prop-types';
import { pdfjs, Document, Page } from 'react-pdf';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { IconButton, Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import Auth from '../../auth/Auth';
import { getAzureFileUrl } from '../../utils/fileUtil';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(theme => ({
  pdfView: {},
  paginator: {
    flexGrow: 0,
    padding: 0,
  },
  doc: {
    flexGrow: 1,
  },
}));

const PdfFileView = props => {
  const classes = useStyles();
  const { fileName, sasKey, thumbnailSasKey } = props;
  const [state, setState] = React.useState({
    numPages: undefined,
    pageNumber: 1,
  });
  const docRef = React.useRef();

  const getFileUrl = () => {
    if (!Auth.currentUser) return '';
    return getAzureFileUrl(Auth.currentUser.accountId, fileName, false, sasKey, thumbnailSasKey);
  };
  const handlePdfLoaded = ({ numPages }) => {
    setState({ ...state, numPages });
  };
  const handlePdfLoadedError = (error) => {
    console.error(error);
  };
  const handleNextPage = () => {
    state.pageNumber < state.numPages &&
      setState({ ...state, pageNumber: state.pageNumber + 1 });
  };
  const handlePrevPage = () => {
    state.pageNumber > 1 &&
      setState({ ...state, pageNumber: state.pageNumber - 1 });
  };

  React.useEffect(() => {
    setState({
      ...state,
      docHeight: docRef.current.getBoundingClientRect().height,
    });
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" flexGrow={1}>
      <div className={classes.paginator}>
        <IconButton onClick={handlePrevPage}>
          <KeyboardArrowLeft />
        </IconButton>
        Page {state.pageNumber} of {state.numPages}
        <IconButton onClick={handleNextPage}>
          <KeyboardArrowRight />
        </IconButton>
      </div>
      <div ref={docRef} className={classes.doc}>
        <Document file={getFileUrl()} onLoadSuccess={handlePdfLoaded} onLoadError={handlePdfLoadedError}>
          <Page
            className={classes.pdfView}
            pageNumber={state.pageNumber}
            renderAnnotationLayer={false}
            height={state.docHeight}
          />
        </Document>
      </div>
    </Box>
  );
};

PdfFileView.propTypes = {
  fileName: PropTypes.string.isRequired,
  sasKey: PropTypes.string.isRequired,
};
PdfFileView.defaultProps = {};

export default PdfFileView;
