import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import lookupApi from '../../apis/lookup.api';
import addressApi from '../../apis/address.api';
import { enqueueError } from '../global';
import { LookupTypeKey } from '../../constants';
import {
  convertTree,
  updateLookupTreeNode,
  appendChildLookup,
  removeLookupTree,
} from '../../utils/lookupTreeUtil';

// / lookup singles
const LOOKUP_QUERY_SINGLE_REQUEST =
  'administration/lookups/QUERY_SINGLE_REQUEST';
const LOOKUP_QUERY_SINGLE_DONE = 'administration/lookups/QUERY_SINGLE_DONE';
const LOOKUP_QUERY_SINGLE_FAIL = 'administration/lookups/QUERY_SINGLE_FAIL';
const LOOKUP_ADD_SINGLE_REQUEST = 'administration/lookups/ADD_SINGLE_REQUEST';
const LOOKUP_ADD_SINGLE_DONE = 'administration/lookups/ADD_SINGLE_DONE';
const LOOKUP_ADD_SINGLE_FAIL = 'administration/lookups/ADD_SINGLE_FAIL';
const LOOKUP_UPDATE_SINGLE_REQUEST =
  'administration/lookups/UPDATE_SINGLE_REQUEST';
const LOOKUP_UPDATE_SINGLE_DONE = 'administration/lookups/UPDATE_SINGLE_DONE';
const LOOKUP_UPDATE_SINGLE_FAIL = 'administration/lookups/UPDATE_SINGLE_FAIL';
const LOOKUP_REMOVE_SINGLE_REQUEST =
  'administration/lookups/REMOVE_SINGLE_REQUEST';
const LOOKUP_REMOVE_SINGLE_DONE = 'administration/lookups/REMOVE_SINGLE_DONE';
const LOOKUP_REMOVE_SINGLE_FAIL = 'administration/lookups/REMOVE_SINGLE_FAIL';

// / lookup multiples
const LOOKUP_QUERY_MULTIPLE_REQUEST =
  'administration/lookups/QUERY_MULTIPLE_REQUEST';
const LOOKUP_QUERY_MULTIPLE_DONE = 'administration/lookups/QUERY_MULTIPLE_DONE';
const LOOKUP_QUERY_MULTIPLE_FAIL = 'administration/lookups/QUERY_MULTIPLE_FAIL';
const LOOKUP_ADD_MULTIPLE_REQUEST =
  'administration/lookups/ADD_MULTIPLE_REQUEST';
const LOOKUP_ADD_MULTIPLE_DONE = 'administration/lookups/ADD_MULTIPLE_DONE';
const LOOKUP_ADD_MULTIPLE_FAIL = 'administration/lookups/ADD_MULTIPLE_FAIL';
const LOOKUP_UPDATE_MULTIPLE_REQUEST =
  'administration/lookups/UPDATE_MULTIPLE_REQUEST';
const LOOKUP_UPDATE_MULTIPLE_DONE =
  'administration/lookups/UPDATE_MULTIPLE_DONE';
const LOOKUP_UPDATE_MULTIPLE_FAIL =
  'administration/lookups/UPDATE_MULTIPLE_FAIL';
const LOOKUP_REMOVE_MULTIPLE_REQUEST =
  'administration/lookups/REMOVE_MULTIPLE_REQUEST';
const LOOKUP_REMOVE_MULTIPLE_DONE =
  'administration/lookups/REMOVE_MULTIPLE_DONE';
const LOOKUP_REMOVE_MULTIPLE_FAIL =
  'administration/lookups/REMOVE_MULTIPLE_FAIL';

const LOCATION_ADD_ADDRESS_REQUEST =
  'administration/locations/ADD_ADDRESS_REQUEST';
const LOCATION_ADD_ADDRESS_DONE = 'administration/locations/ADD_ADDRESS_DONE';
const LOCATION_ADD_ADDRESS_FAIL = 'administration/locations/ADD_ADDRESS_FAIL';
const LOCATION_GET_ADDRESS_REQUEST =
  'administration/locations/GET_ADDRESS_REQUEST';
const LOCATION_GET_ADDRESS_DONE = 'administration/locations/GET_ADDRESS_DONE';
const LOCATION_GET_ADDRESS_FAIL = 'administration/locations/GET_ADDRESS_FAIL';
const LOCATION_UPDATE_ADDRESS_REQUEST =
  'administration/locations/UPDATE_ADDRESS_REQUEST';
const LOCATION_UPDATE_ADDRESS_DONE =
  'administration/locations/UPDATE_ADDRESS_DONE';
const LOCATION_UPDATE_ADDRESS_FAIL =
  'administration/locations/UPDATE_ADDRESS_FAIL';
const LOCATION_REMOVE_ADDRESS_REQUEST =
  'administration/locations/REMOVE_ADDRESS_REQUEST';
const LOCATION_REMOVE_ADDRESS_DONE =
  'administration/locations/REMOVE_ADDRESS_DONE';
const LOCATION_REMOVE_ADDRESS_FAIL =
  'administration/locations/REMOVE_ADDRESS_FAIL';

// / labels
const LOOKUP_QUERY_LABEL_REQUEST = 'administration/lookups/QUERY_LABEL_REQUEST';
const LOOKUP_QUERY_LABEL_DONE = 'administration/lookups/QUERY_LABEL_DONE';
const LOOKUP_QUERY_LABEL_FAIL = 'administration/lookups/QUERY_LABEL_FAIL';

// / lookup singles
const querySingleDone = createAction(LOOKUP_QUERY_SINGLE_DONE);
const querySingleFail = createAction(LOOKUP_QUERY_SINGLE_FAIL);
const addSingleDone = createAction(LOOKUP_ADD_SINGLE_DONE);
const addSingleFail = createAction(LOOKUP_ADD_SINGLE_FAIL);
const updateSingleDone = createAction(LOOKUP_UPDATE_SINGLE_DONE);
const updateSingleFail = createAction(LOOKUP_UPDATE_SINGLE_FAIL);
const removeSingleDone = createAction(LOOKUP_REMOVE_SINGLE_DONE);
const removeSingleFail = createAction(LOOKUP_REMOVE_SINGLE_FAIL);

// / lookup multiples
const queryMultipleDone = createAction(LOOKUP_QUERY_MULTIPLE_DONE);
const queryMultipleFail = createAction(LOOKUP_QUERY_MULTIPLE_FAIL);
const addMultipleDone = createAction(LOOKUP_ADD_MULTIPLE_DONE);
const addMultipleFail = createAction(LOOKUP_ADD_MULTIPLE_FAIL);
const updateMultipleDone = createAction(LOOKUP_UPDATE_MULTIPLE_DONE);
const updateMultipleFail = createAction(LOOKUP_UPDATE_MULTIPLE_FAIL);
const removeMultipleDone = createAction(LOOKUP_REMOVE_MULTIPLE_DONE);
const removeMultipleFail = createAction(LOOKUP_REMOVE_MULTIPLE_FAIL);

// address
const addAddressDone = createAction(LOCATION_ADD_ADDRESS_DONE);
const addAddressFail = createAction(LOCATION_ADD_ADDRESS_FAIL);
const getAddressDone = createAction(LOCATION_GET_ADDRESS_DONE);
const getAddressFail = createAction(LOCATION_GET_ADDRESS_FAIL);
const removeAddressDone = createAction(LOCATION_REMOVE_ADDRESS_DONE);
const removeAddressFail = createAction(LOCATION_REMOVE_ADDRESS_FAIL);
const updateAddressDone = createAction(LOCATION_UPDATE_ADDRESS_DONE);
const updateAddressFail = createAction(LOCATION_UPDATE_ADDRESS_FAIL);

// / labels
const queryLabelDone = createAction(LOOKUP_QUERY_LABEL_DONE);
const queryLabelFail = createAction(LOOKUP_QUERY_LABEL_FAIL);

// / lookup singles
export const querySingles = params => async dispatch => {
  dispatch({ type: LOOKUP_QUERY_SINGLE_REQUEST });
  try {
    const res = await lookupApi.querySingles(params);
    dispatch(querySingleDone({ data: res.data, type: params.type }));
  } catch (err) {
    dispatch(querySingleFail(err));
    dispatch(enqueueError(err));
  }
};
export const addSingle = model => async dispatch => {
  dispatch({ type: LOOKUP_ADD_SINGLE_REQUEST });
  try {
    await lookupApi.addSingle(model);
    dispatch(addSingleDone(model));
  } catch (error) {
    dispatch(addSingleFail(error));
    dispatch(enqueueError(error));
  }
};
export const updateSingle = model => async dispatch => {
  dispatch({ type: LOOKUP_UPDATE_SINGLE_REQUEST });
  try {
    await lookupApi.updateSingle(model);
    dispatch(updateSingleDone(model));
  } catch (error) {
    dispatch(updateSingleFail(error));
    dispatch(enqueueError(error));
  }
};
export const removeSingle = model => async dispatch => {
  dispatch({ type: LOOKUP_REMOVE_SINGLE_REQUEST });
  try {
    await lookupApi.removeSingle(model);
    dispatch(removeSingleDone(model));
  } catch (error) {
    dispatch(removeSingleFail(error));
    dispatch(enqueueError(error));
  }
};

// / lookup multiples
export const queryMultiples = params => async dispatch => {
  dispatch({ type: LOOKUP_QUERY_MULTIPLE_REQUEST });
  try {
    const res = await lookupApi.queryMultiples(params);
    dispatch(queryMultipleDone({ data: res.data, type: params.type }));
  } catch (err) {
    dispatch(queryMultipleFail(err));
    dispatch(enqueueError(err));
  }
};
export const addMultiple = model => async dispatch => {
  dispatch({ type: LOOKUP_ADD_MULTIPLE_REQUEST });
  try {
    await lookupApi.addMultiple(model);
    dispatch(addMultipleDone(model));
  } catch (err) {
    dispatch(addMultipleFail(err));
    dispatch(enqueueError(err));
  }
};
export const updateMultiple = model => async dispatch => {
  dispatch({ type: LOOKUP_UPDATE_MULTIPLE_REQUEST });
  try {
    await lookupApi.updateMultiple(model);
    dispatch(updateMultipleDone(model));
  } catch (err) {
    dispatch(updateMultipleFail(err));
    dispatch(enqueueError(err));
  }
};
export const removeMultiple = model => async dispatch => {
  dispatch({ type: LOOKUP_REMOVE_MULTIPLE_REQUEST });
  try {
    await lookupApi.removeMultiple(model);
    dispatch(removeMultipleDone(model));
  } catch (err) {
    dispatch(removeMultipleFail(err));
    dispatch(enqueueError(err));
  }
};

// / labels
export const queryLabels = params => async dispatch => {
  dispatch({ type: LOOKUP_QUERY_LABEL_REQUEST });
  try {
    const res = await lookupApi.queryMultipleLabels(params);
    dispatch(queryLabelDone({ data: res.data, type: params.type }));
  } catch (err) {
    dispatch(queryLabelFail(err));
    dispatch(enqueueError(err));
  }
};

// / address
export const addAddress = model => async dispatch => {
  dispatch({ type: LOCATION_ADD_ADDRESS_REQUEST });
  try {
    await addressApi.addAddress(model);
    dispatch(addAddressDone(model));
  } catch (err) {
    dispatch(addAddressFail(err));
    dispatch(enqueueError(err));
  }
};
export const getAddress = id => async dispatch => {
  dispatch({ type: LOCATION_GET_ADDRESS_REQUEST });
  try {
    const res = await addressApi.getAddress(id);
    dispatch(getAddressDone(res.data));
  } catch (err) {
    dispatch(getAddressFail(err));
    dispatch(enqueueError(err));
  }
};
export const removeAddress = model => async dispatch => {
  dispatch({ type: LOCATION_REMOVE_ADDRESS_REQUEST });
  try {
    await addressApi.removeAddress(model.id);
    dispatch(removeAddressDone(model));
  } catch (err) {
    dispatch(removeAddressFail(err));
    dispatch(enqueueError(err));
  }
};
export const updateAddress = model => async dispatch => {
  dispatch({ type: LOCATION_UPDATE_ADDRESS_REQUEST });
  try {
    await addressApi.updateAddress(model);
    dispatch(updateAddressDone(model));
  } catch (err) {
    dispatch(updateAddressFail(err));
    dispatch(enqueueError(err));
  }
};

const initState = {
  dataSet: {
    [LookupTypeKey.personType]: [],
    [LookupTypeKey.vehicleInvolvement]: [],
    [LookupTypeKey.incidentClass]: [],
    [LookupTypeKey.location]: [],
    [LookupTypeKey.department]: [],
    [LookupTypeKey.primaryCause]: [],
    [LookupTypeKey.severityType]: [],
    [LookupTypeKey.referredAgency]: [],
    [LookupTypeKey.incidentCause]: [],
  },
  labelSet: {
    [LookupTypeKey.incidentClass]: [],
    [LookupTypeKey.location]: [],
  },

  querySingleState: undefined,
  addSingleState: undefined,
  updateSingleState: undefined,
  removeSingleState: undefined,

  queryMultipleState: undefined,
  addMultipleState: undefined,
  updateMultipleState: undefined,
  removeMultipleState: undefined,

  addAddressState: undefined,
  getAddressState: undefined,
  updateAddressState: undefined,
  removeAddressState: undefined,

  address: undefined,

  queryLabelState: undefined,
};

export default handleActions(
  {
    // / lookup singles
    [LOOKUP_QUERY_SINGLE_REQUEST]: (state, action) =>
      produce(state, draft => {
        draft.querySingleState = 'request';
      }),
    [LOOKUP_QUERY_SINGLE_DONE]: (state, action) =>
      produce(state, draft => {
        const { data, type } = action.payload;
        draft.dataSet[type] = data;
        draft.querySingleState = 'done';
      }),
    [LOOKUP_QUERY_SINGLE_FAIL]: (state, action) =>
      produce(state, draft => {
        draft.querySingleState = 'fail';
      }),

    [LOOKUP_ADD_SINGLE_REQUEST]: (state, action) =>
      produce(state, draft => {
        draft.addSingleState = 'request';
      }),
    [LOOKUP_ADD_SINGLE_DONE]: (state, action) =>
      produce(state, draft => {
        const { type } = action.payload;
        draft.dataSet[type].push(action.payload);
        draft.addSingleState = 'done';
      }),
    [LOOKUP_ADD_SINGLE_FAIL]: (state, action) =>
      produce(state, draft => {
        draft.addSingleState = 'fail';
      }),

    [LOOKUP_UPDATE_SINGLE_REQUEST]: (state, action) =>
      produce(state, draft => {
        draft.updateSingleState = 'request';
      }),
    [LOOKUP_UPDATE_SINGLE_DONE]: (state, action) =>
      produce(state, draft => {
        const model = action.payload;
        const { id, type } = model;
        const lookup = draft.dataSet[type].find(x => x.id === id);
        lookup && Object.assign(lookup, model);
        draft.updateSingleState = 'done';
      }),
    [LOOKUP_UPDATE_SINGLE_FAIL]: (state, action) =>
      produce(state, draft => {
        draft.updateSingleState = 'fail';
      }),

    [LOOKUP_REMOVE_SINGLE_REQUEST]: (state, action) =>
      produce(state, draft => {
        draft.removeSingleState = 'request';
      }),
    [LOOKUP_REMOVE_SINGLE_DONE]: (state, action) =>
      produce(state, draft => {
        const { id, type } = action.payload;

        draft.dataSet[type] = state.dataSet[type].filter(x => x.id !== id);
        draft.removeSingleState = 'done';
      }),
    [LOOKUP_REMOVE_SINGLE_FAIL]: (state, action) =>
      produce(state, draft => {
        draft.removeSingleState = 'fail';
      }),

    // / lookup multiples
    [LOOKUP_QUERY_MULTIPLE_REQUEST]: (state, action) =>
      produce(state, draft => {
        draft.queryMultipleState = 'request';
      }),
    [LOOKUP_QUERY_MULTIPLE_DONE]: (state, action) =>
      produce(state, draft => {
        const { data, type } = action.payload;
        draft.dataSet[type] = convertTree(data);
        draft.queryMultipleState = 'done';
      }),
    [LOOKUP_QUERY_MULTIPLE_FAIL]: (state, action) =>
      produce(state, draft => {
        draft.queryMultipleState = 'fail';
      }),
    [LOOKUP_ADD_MULTIPLE_REQUEST]: (state, action) =>
      produce(state, draft => {
        draft.addMultipleState = 'request';
      }),
    [LOOKUP_ADD_MULTIPLE_DONE]: (state, action) =>
      produce(state, draft => {
        const { type } = action.payload;
        appendChildLookup(draft.dataSet[type], action.payload);
        draft.addMultipleState = 'done';
      }),
    [LOOKUP_ADD_MULTIPLE_FAIL]: (state, action) =>
      produce(state, draft => {
        draft.addMultipleState = 'fail';
      }),
    [LOOKUP_UPDATE_MULTIPLE_REQUEST]: (state, action) =>
      produce(state, draft => {
        draft.updateMultipleState = 'request';
      }),
    [LOOKUP_UPDATE_MULTIPLE_DONE]: (state, action) =>
      produce(state, draft => {
        const { type, id, name, active } = action.payload;
        updateLookupTreeNode(draft.dataSet[type], id, { name, active });
        draft.updateMultipleState = 'done';
      }),
    [LOOKUP_UPDATE_MULTIPLE_FAIL]: (state, action) =>
      produce(state, draft => {
        draft.updateMultipleState = 'fail';
      }),
    [LOOKUP_REMOVE_MULTIPLE_REQUEST]: (state, action) =>
      produce(state, draft => {
        draft.removeMultipleState = 'request';
      }),
    [LOOKUP_REMOVE_MULTIPLE_DONE]: (state, action) =>
      produce(state, draft => {
        const { type } = action.payload;
        removeLookupTree(draft.dataSet[type], action.payload);
        draft.removeMultipleState = 'done';
      }),
    [LOOKUP_REMOVE_MULTIPLE_FAIL]: (state, action) =>
      produce(state, draft => {
        draft.removeMultipleState = 'fail';
      }),

    // / labels
    [LOOKUP_QUERY_LABEL_REQUEST]: (state, action) =>
      produce(state, draft => {
        draft.queryLabelState = 'request';
      }),
    [LOOKUP_QUERY_LABEL_DONE]: (state, action) =>
      produce(state, draft => {
        const { data, type } = action.payload;
        draft.labelSet[type] = data;
        draft.queryLabelState = 'done';
      }),
    [LOOKUP_QUERY_LABEL_FAIL]: (state, action) =>
      produce(state, draft => {
        draft.queryLabelState = 'fail';
      }),

    // / addresses
    [LOCATION_ADD_ADDRESS_REQUEST]: (state, action) =>
      produce(state, draft => {
        draft.addAddressState = 'request';
      }),
    [LOCATION_ADD_ADDRESS_DONE]: (state, action) =>
      produce(state, draft => {
        const { id: addressId, lookupLocationId: lookupId } = action.payload;
        updateLookupTreeNode(draft.dataSet[LookupTypeKey.location], lookupId, {
          addressId,
        });
        draft.addAddressState = 'done';
      }),
    [LOCATION_ADD_ADDRESS_FAIL]: (state, action) =>
      produce(state, draft => {
        draft.addAddressState = 'fail';
      }),

    [LOCATION_GET_ADDRESS_REQUEST]: (state, action) =>
      produce(state, draft => {
        draft.address = undefined;
        draft.getAddressState = 'request';
      }),
    [LOCATION_GET_ADDRESS_DONE]: (state, action) =>
      produce(state, draft => {
        draft.address = action.payload;
        draft.getAddressState = 'done';
      }),
    [LOCATION_GET_ADDRESS_FAIL]: (state, action) =>
      produce(state, draft => {
        draft.getAddressState = 'fail';
      }),

    [LOCATION_REMOVE_ADDRESS_REQUEST]: (state, action) =>
      produce(state, draft => {
        draft.removeAddressState = 'request';
      }),
    [LOCATION_REMOVE_ADDRESS_DONE]: (state, action) =>
      produce(state, draft => {
        const { lookupLocationId: lookupId } = action.payload;
        updateLookupTreeNode(draft.dataSet[LookupTypeKey.location], lookupId, {
          addressId: undefined,
        });
        draft.removeAddressState = 'done';
      }),
    [LOCATION_REMOVE_ADDRESS_FAIL]: (state, action) =>
      produce(state, draft => {
        draft.removeAddressState = 'fail';
      }),
    [LOCATION_UPDATE_ADDRESS_REQUEST]: (state, action) =>
      produce(state, draft => {
        draft.updateAddressState = 'request';
      }),
    [LOCATION_UPDATE_ADDRESS_DONE]: (state, action) =>
      produce(state, draft => {
        draft.updateAddressState = 'done';
      }),
    [LOCATION_UPDATE_ADDRESS_FAIL]: (state, action) =>
      produce(state, draft => {
        draft.updateAddressState = 'fail';
      }),
  },
  initState,
);
