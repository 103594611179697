import BaseApi from './BaseApi';

class AuditApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl += '/audits';
  }

  async queryByPeriod(params) {
    return await this.get(this.baseUrl, { params });
  }

  async getById(id) {
    return await this.get(`${this.baseUrl}/${id}`);
  }
}

export default new AuditApi();
