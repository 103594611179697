import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import _ from 'lodash';
import { enqueueError, enqueueSuccess } from '../../../modules/global';
import paymentApi from '../../../apis/payment.api';
import subscription2Api from '../../../apis/subscription2.api';
import { updateBillingInformation } from '../companyManagement/ducks';

const RESET = 'administration/subscriptionManagement/RESET';
const LOAD_FAIL = 'administration/subscriptionManagement/LOAD_FAIL';
const LOADED = 'administration/subscriptionManagement/LOADED';
const SUBSCRIPTION_SAVED =
  'administration/subscriptionManagement/SUBSCRIPTION_SAVED';
const SUBSCRIPTION_CANCELLED =
  'administration/subscriptionManagement/SUBSCRIPTION_CANCELLED';
const PAYMENT_METHOD_SAVED =
  'administration/subscriptionManagement/PAYMENT_METHOD_SAVED';

const resetAction = createAction(RESET);
const loadedAction = createAction(LOADED);
const loadFailAction = createAction(LOAD_FAIL);
const saveSubscriptionAction = createAction(SUBSCRIPTION_SAVED);
const cancelSubscriptionAction = createAction(SUBSCRIPTION_CANCELLED);
const paymentMethodSavedAction = createAction(PAYMENT_METHOD_SAVED);

const initState = {
  loaded: false,
  loadFail: false,
  subscription: undefined,
  plans: undefined,
  promotionCode: undefined,
};

export default handleActions(
  {
    [RESET]: (state, action) =>
      produce(state, draft => {
        draft.loaded = false;
      }),
    [LOAD_FAIL]: (state, action) =>
      produce(state, draft => {
        draft.loaded = true;
        draft.loadFail = true;
      }),
    [LOADED]: (state, action) =>
      produce(state, draft => {
        draft.loaded = true;
        draft.plans = action.payload.plans;
        draft.subscription = action.payload.subscription;
        draft.promotionCode = action.payload.promotionCode;
      }),
    [SUBSCRIPTION_SAVED]: (state, action) =>
      produce(state, draft => {
        draft.subscription = action.payload;
        draft.loaded = true;
      }),
    [SUBSCRIPTION_CANCELLED]: (state, action) =>
      produce(state, draft => {
        draft.subscription = action.payload;
        draft.loaded = true;
      }),
    [PAYMENT_METHOD_SAVED]: (state, action) =>
      produce(state, draft => {
        draft.loaded = true;
        draft.subscription.cardLast4 = action.payload.cardNumber;
      }),
  },
  initState,
);

export const loadSubscription = promotionCode => async (dispatch, getState) => {
  dispatch(resetAction());
  try {
    const { administration } = getState();
    let plans =
      administration.subscriptions && administration.subscriptions.plans;
    if (
      !plans ||
      administration.subscriptions.promotionCode !== promotionCode
    ) {
      const { data } = await subscription2Api.getPlans(promotionCode);
      plans = data;
    }

    const { data } = await subscription2Api.getSubscription();
    dispatch(
      loadedAction({
        plans,
        subscription: data,
        promotionCode,
      }),
    );
  } catch (err) {
    dispatch(enqueueError(err));
    dispatch(loadFailAction());
  }
};

export const saveSubscription = (
  billingInformation,
  periodType,
  concurrentUserCount,
  promotionCode,
) => async dispatch => {
  dispatch(resetAction());
  try {
    await updateBillingInformation(billingInformation)(dispatch);
    const model = { periodType, concurrentUserCount, promotionCode };
    const { data } = await subscription2Api.save(model);
    dispatch(saveSubscriptionAction(data));
    dispatch(enqueueSuccess('Subscription is successfully updated.'));
  } catch (err) {
    dispatch(enqueueError(err));
    dispatch(loadFailAction());
    throw err;
  }
};

export const savePaymentMethod = model => async dispatch => {
  dispatch(resetAction());
  try {
    const { data } = await paymentApi.saveMethod(model);
    dispatch(paymentMethodSavedAction(data));
    dispatch(enqueueSuccess('Payment method is successfully saved.'));
  } catch (err) {
    dispatch(enqueueError(err));
    dispatch(loadFailAction());
  }
};

export const cancelSubscription = () => async dispatch => {
  dispatch(resetAction());
  try {
    const { data } = await subscription2Api.cancel();
    dispatch(cancelSubscriptionAction(data));
    dispatch(enqueueSuccess('Subscription successfully cancelled.'));
  } catch (err) {
    dispatch(enqueueError(err));
    dispatch(loadFailAction());
    throw err;
  }
};
