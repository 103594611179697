import React from 'react';
import PropTypes from 'prop-types';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Button, FormControl, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as yup from 'yup';
import _ from 'lodash';

import { useForm } from '../../../utils/hooks';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  checkout: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  submitPaymentMethodButton: {
    marginLeft: theme.spacing(1),
  },
  card: {
    width: '600px',
    height: `38px`,
    boxShadow: `0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1),
    0px 1px 1.5px 0px rgba(0, 0, 0, 0.07)`,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const schema = yup.object().shape({});

const CheckoutForm = props => {
  const classes = useStyles();

  const {
    formValues,
    updateFormValues,
    hasFormError,
    formHelpText,
    handleFormChange,
    handleFormBlur,
    handleFormSubmit,
  } = useForm({
    validationSchema: schema,
    onSubmit: (values, errors) => {
      if (_.isEmpty(errors)) {
        props.stripe
          .createToken()
          .then(({ token }) => {
            if (token) {
              const { email } = values;
              props.onSave({ token: token.id, email });
            } else {
              props.onError(new Error('Failed to save a payment method.'));
            }
          })
          .catch(err => {
            props.onError(new Error('Failed to save a payment method.'));
          });
      }
    },
  });

  return (
    <>
      <div className={classes.checkout}>
        <div className={classes.card}>
          <CardElement style={{ base: { fontSize: '18px' } }} />
        </div>
        <Button
          variant="contained"
          color="primary"
          className={classes.submitPaymentMethodButton}
          // autoFocus
          onClick={handleFormSubmit}
        >
          {props.cardExist ? 'Change' : 'Save'}
        </Button>
      </div>
    </>
  );
};

CheckoutForm.propTypes = {
  stripe: PropTypes.object,
  onSave: PropTypes.func,
  onError: PropTypes.func,
  cardExist: PropTypes.bool,
};
CheckoutForm.defaultProps = {
  onSave: f => f,
  onError: f => f,
};

export default injectStripe(CheckoutForm);
