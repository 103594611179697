import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  makeStyles,
  Button,
  Box,
  FormControl,
  TextField,
  MenuItem,
  Avatar,
  Fade,
  Chip,
} from '@material-ui/core';
import { Done as DoneIcon } from '@material-ui/icons';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { incidentSchema } from '../../models/validateSchemas';

import IncidentDetailsCard from './IncidentDetailsCard';
import {
  queryDraftIncidents,
  updateDraftIncident,
  createDraftIncident,
  removeDraftIncident,
  setSelectedDraft,
  submitIncident,
  closeIncident,
  // setOpenDeleteDraft,
  changeDraftIncident,
} from './ducks';
import { LookupTypeKey, IncidentStatusEnum, PersonType } from '../../constants';
import IncidentSummaryCard from './IncidentSummaryCard';
import IncidentPersonCard from './IncidentPersonCard';
import AlertDialog from '../../components/dialogs/AlertDialog';
import IncidentVehicleCard from './IncidentVehicleCard';
import IncidentCompanyCard from './IncidentCompanyCard';
import IncidentItemCard from './IncidentItemCard';
import {
  DraftIncidentDef,
  LookupDataSetDef,
  LookupLabelSetDef,
  StateProvinceDef,
} from '../../components/propTypeDefs';
import IncidentAttachmentsCard from './IncidentAttachmentsCard';
import { enqueueError } from '../../modules/global';
import auth from '../../auth/Auth';
import { getInitData } from '../../modules/common';
import IncidentCorrectiveActionCard from './IncidentCorrectiveActionCard';
import IncidentCloseDialog from '../../components/dialogs/IncidentCloseDialog';
import HelpButton from '../../components/buttons/HelpButton';
import { canCloseIncident } from '../../utils/privilegeUtil';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  expansionCard: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
      marginBottom: 0,
    },
  },
  itemButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  saveButton: {
    flexGrow: 1,
    height: theme.spacing(6),
  },
  closeButton: {
    flexGrow: 1,
    flexShrink: 0,
    marginLeft: theme.spacing(1),
  },
  deleteButton: {
    flexGrow: 1,
    flexShrink: 0,
    minWidth: 100,
    marginLeft: theme.spacing(1),
    background: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    alignItems: 'center',
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  expansionCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  expansionCardItem: {},

  avatarContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    width: 25,
    height: 25,
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'flex-start',
    // [theme.breakpoints.down('sm')]: {
    //   display: 'flex',
    //   flexDirection: 'column',
    //   justifyContent: 'center',
    // },
  },
  baseline: {
    alignSelf: 'baseline',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: 0,
    },
  },
  actionButtons: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  inline: {
    display: 'inline-block',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  personName: {
    margin: theme.spacing(1),
  },
  companyItem: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
  },
  itemAddButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-2),
  },
  inlineRight: {
    width: '30%',
    textAlign: 'right',
    marginLeft: 50,
    alignSelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
      textAlign: 'center',
    },
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  category: {
    root: {
      flexGrow: 1,
    },
    input: {
      display: 'flex',
      padding: 0,
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
    chip: {
      margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light'
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
        0.08,
      ),
    },
    noOptionsMessage: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    singleValue: {
      fontSize: 16,
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      fontSize: 16,
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing(2),
    },
    icon: {
      margin: theme.spacing(1),
      fontSize: 64,
    },
    personWrapper: {
      margin: theme.spacing(2),
    },
  },
  badgePadding: {
    paddingRight: theme.spacing(2),
  },
  label: {
    ...theme.typography.h5,
    fontSize: '1.1em',
    fontWeight: 600,
    color: theme.palette.grey[500],
  },
  value: {
    ...theme.typography.body,
    width: '100%',
  },
  investigatorMenuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  draftSavedLabel: {
    position: 'absolute',
    top: '80px',
    right: '10px',
    zIndex: 100,
  },
}));

const IncidentContainer = props => {
  const classes = useStyles();
  const { draftIncident } = props;
  const [state, setState] = React.useState({
    errors: undefined,
  });
  const [draftSaved, setDraftSaved] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(undefined);

  const handleInvestigatorChange = event => {
    const target = event.target;
    const value = target.value;

    if (value === '') {
      props.changeDraftIncident({ assignedUserId: undefined });
    } else {
      props.changeDraftIncident({ assignedUserId: target.value });
    }
  };

  const updateDraft = () => {
    props.updateDraftIncident(() => {
      setDraftSaved(false);
      setTimeout(() => {
        setDraftSaved(true);
      }, 300);
    });
  };

  const handleDraftChange = value => {
    props.changeDraftIncident(value);
  };
  const handleSave = close => {
    incidentSchema
      .validate(draftIncident, { abortEarly: false })
      .then(() => {
        props.submitIncident(close);
      })
      .catch(ex => {
        const errors = ex.inner.map(({ path, message }) => ({ path, message }));
        setState({ ...state, errors });
        props.enqueueError(errors[0].message);
      });
  };
  const debounceHandleSave = _.debounce(() => {
    handleSave();
  }, 200);

  const handleDelete = () => {
    setOpenDialog('confirmDelete');
    // props.setOpenDeleteDraft(true);
  };
  const debounceHandleDelete = _.debounce(handleDelete, 200);
  const handleDeleteOrClose = () => {
    setOpenDialog(undefined);
    // props.setOpenDeleteDraft(false);
  };
  const handleDeleteConfirm = () => {
    if (draftIncident.status === IncidentStatusEnum.draft) {
      props.removeDraftIncident(draftIncident);
    } else {
      setOpenDialog('closeIncident');
      // props.closeIncident(draftIncident.id);
    }
  };

  const closeIncidentDialog = () => {
    setOpenDialog(undefined);
  };
  const handleClose = () => {
    setOpenDialog('confirmClose');
    // props.setOpenDeleteDraft(true);
  };
  const debounceHandleClose = _.debounce(handleClose, 200);
  const handleCloseConfirm = () => {
    setOpenDialog('closeIncident');
  };

  // auto save
  React.useEffect(() => {
    props.getInitData();

    const intervalId = setInterval(() => {
      updateDraft();
    }, 1000 * 30);

    return () => {
      clearInterval(intervalId);
      updateDraft();
    };
  }, []);

  React.useEffect(() => {
    if (draftIncident) {
      const { status } = draftIncident;
    }
  }, [draftIncident]);

  React.useEffect(() => {
    props.submitted && props.onSubmit();
  }, [props.submitted]);

  // const getIncidentPersons = () => {
  //   if (!draftIncident) {
  //     return [];
  //   }

  //   const persons = draftIncident.persons ? draftIncident.persons : [];
  //   const companies = draftIncident.companies ? draftIncident.companies : [];

  //   const extractor = personType => ({
  //     id,
  //     name,
  //     firstName,
  //     middleName,
  //     lastName,
  //   }) => ({
  //     id,
  //     name:
  //       personType === PersonType.person
  //         ? getPersonName({ firstName, middleName, lastName })
  //         : getCompanyName({ name }),
  //     personType,
  //   });
  //   const mergedList = persons
  //     .map(extractor(PersonType.person))
  //     .concat(companies.map(extractor(PersonType.company)));
  //   return mergedList;
  // };

  // const incidentPersons = getIncidentPersons();

  const hasSavePrevilige = () => {
    if (draftIncident && draftIncident.status === 0) {
      return auth.hasPrivilege(['MYINCIDENT_ADD']);
    }
    return auth.hasPrivilege(['MYINCIDENT_EDIT', 'ALLINCIDENT_EDIT']);
  };

  return (
    <div className={classes.root}>
      <Fade
        in={!draftSaved}
        timeout={draftSaved ? 5000 : 0}
        className={classes.draftSavedLabel}
      >
        <Chip label="Draft saved" color="primary" deleteIcon={<DoneIcon />} />
      </Fade>

      <div className={classes.lineItem}>
        <FormControl className={classes.formControl}>
          <TextField
            id="incident-entered-by"
            disabled
            label="Entered by"
            InputLabelProps={{
              shrink: true,
            }}
            value={
              draftIncident
                ? draftIncident.createUserName
                : auth.currentUserValue.name
            }
          />
        </FormControl>
        <FormControl className={classes.formControlLong}>
          <TextField
            label="Incident Investigator"
            select
            margin="none"
            name="incidentAssignedId"
            value={
              draftIncident && draftIncident.assignedUserId
                ? draftIncident.assignedUserId
                : ''
            }
            onChange={handleInvestigatorChange}
            // onBlur={handleFormBlur}
            // error={hasFormError('companyCountryCode')}
            // helperText={formHelpText('companyCountryCode')}
            // required
          >
            <MenuItem value="">
              <em>Not assigned</em>
            </MenuItem>
            {props.investigators.map(investigator => (
              <MenuItem key={investigator.id} value={investigator.id}>
                <div className={classes.investigatorMenuItem}>
                  <Avatar
                    src={investigator.avatarUrl}
                    className={classes.avatar}
                  />
                  {investigator.name}
                </div>
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </div>

      <div className={classes.expansionCard}>
        <IncidentDetailsCard
          draftIncident={draftIncident}
          onChange={handleDraftChange}
          incidentLabels={props.lookupLabelSet[LookupTypeKey.incidentClass]}
          incidentLookups={props.lookupDataSet[LookupTypeKey.incidentClass]}
          locationLabels={props.lookupLabelSet[LookupTypeKey.location]}
          locationLookups={props.lookupDataSet[LookupTypeKey.location]}
          primaryCauseLookups={props.lookupDataSet[LookupTypeKey.primaryCause]}
          severityLookups={props.lookupDataSet[LookupTypeKey.severityType]}
          companySettings={props.companySettings}
          errors={state.errors}
        />
      </div>
      <div className={classes.expansionCard}>
        <IncidentSummaryCard
          onChange={handleDraftChange}
          defaultValue={draftIncident ? draftIncident.summary : undefined}
        />
      </div>
      <div className={classes.expansionCard}>
        <IncidentPersonCard
          incidentId={draftIncident ? draftIncident.id : undefined}
          onChange={handleDraftChange}
          persons={draftIncident ? draftIncident.persons : undefined}
          personTypeLookups={props.lookupDataSet[LookupTypeKey.personType]}
          departmentLookups={props.lookupDataSet[LookupTypeKey.department]}
          genderTypeLookups={props.lookupDataSet[LookupTypeKey.genderType]}
          errors={state.errors}
        />
      </div>
      <div className={classes.expansionCard}>
        <IncidentCompanyCard
          incidentId={draftIncident ? draftIncident.id : undefined}
          onChange={handleDraftChange}
          companies={draftIncident ? draftIncident.companies : undefined}
          errors={state.errors}
        />
      </div>
      <div className={classes.expansionCard}>
        <IncidentVehicleCard
          incidentId={draftIncident ? draftIncident.id : undefined}
          onChange={handleDraftChange}
          vehicles={draftIncident ? draftIncident.vehicles : undefined}
          persons={draftIncident ? draftIncident.persons : undefined}
          companies={draftIncident ? draftIncident.companies : undefined}
          involveLookups={props.lookupDataSet[LookupTypeKey.vehicleInvolvement]}
          attachments={draftIncident ? draftIncident.attachments : []}
          states={props.states}
          errors={state.errors}
        />
      </div>

      <div className={classes.expansionCard}>
        <IncidentItemCard
          incidentId={draftIncident ? draftIncident.id : undefined}
          onChange={handleDraftChange}
          items={draftIncident ? draftIncident.items : undefined}
          persons={draftIncident ? draftIncident.persons : undefined}
          companies={draftIncident ? draftIncident.companies : undefined}
          attachments={draftIncident ? draftIncident.attachments : []}
          errors={state.errors}
        />
      </div>

      <div className={classes.expansionCard}>
        <IncidentAttachmentsCard
          incidentId={draftIncident ? draftIncident.id : undefined}
          attachments={draftIncident ? draftIncident.attachments : []}
          onChange={handleDraftChange}
        />
      </div>
      <div className={classes.expansionCard}>
        <IncidentCorrectiveActionCard
          incidentId={draftIncident ? draftIncident.id : undefined}
          onChange={handleDraftChange}
          correctiveActions={
            draftIncident ? draftIncident.correctiveActions : undefined
          }
          persons={draftIncident ? draftIncident.persons : undefined}
          companies={draftIncident ? draftIncident.companies : undefined}
          correctiveActionTypeLookups={
            props.lookupDataSet[LookupTypeKey.correctiveActionType]
          }
          departmentLookups={props.lookupDataSet[LookupTypeKey.department]}
          agencyLookups={props.lookupDataSet[LookupTypeKey.referredAgency]}
        />
      </div>

      {draftIncident && (
        <Box
          display="flex"
          flexDirection="row"
          className={classes.actionButtons}
        >
          <Button
            variant="contained"
            color="primary"
            className={classes.saveButton}
            onClick={debounceHandleSave}
            fullWidth
            disabled={!hasSavePrevilige()}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="inherit"
            className={classes.deleteButton}
            onClick={debounceHandleClose}
            disabled={
              !canCloseIncident(draftIncident && draftIncident.createUserId)
            }
          >
            Close incident
            <HelpButton message="Close the incident when all of the information on the incident has been documented, including Corrective Actions. When an incident has been Closed, it can no longer be editted without certain system privledges." />
          </Button>
          {draftIncident.status === IncidentStatusEnum.draft && (
            <Button
              variant="contained"
              color="inherit"
              className={classes.deleteButton}
              onClick={debounceHandleDelete}
            >
              Delete Draft
            </Button>
          )}
        </Box>
      )}
      <AlertDialog
        title="Delete Incident"
        message="Are you sure you want to delete this incident?"
        open={openDialog === 'confirmDelete'}
        // open={props.openDeleteDraft}
        onClose={closeIncidentDialog}
        onCancel={closeIncidentDialog}
        onOk={handleDeleteConfirm}
      />
      <AlertDialog
        title="Close Incident"
        message={
          <div>
            Are you sure you want to CLOSE this INCIDENT?{' '}
            <b>CLOSED INCIDENTS CANNOT BE EDITED.</b>
          </div>
        }
        open={openDialog === 'confirmClose'}
        onClose={closeIncidentDialog}
        onCancel={closeIncidentDialog}
        onOk={handleCloseConfirm}
      />
      <IncidentCloseDialog
        open={openDialog === 'closeIncident'}
        onClose={() => setOpenDialog(undefined)}
        onCloseIncident={close => handleSave(close)}
      />
    </div>
  );
};

IncidentContainer.propTypes = {
  draftIncident: DraftIncidentDef,
  lookupDataSet: LookupDataSetDef.isRequired,
  lookupLabelSet: LookupLabelSetDef.isRequired,
  // openDeleteDraft: PropTypes.bool.isRequired,
  submitted: PropTypes.bool.isRequired,
  updateDraftIncident: PropTypes.func.isRequired,
  removeDraftIncident: PropTypes.func.isRequired,
  submitIncident: PropTypes.func.isRequired,
  closeIncident: PropTypes.func.isRequired,
  // setOpenDeleteDraft: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  changeDraftIncident: PropTypes.func.isRequired,
  enqueueError: PropTypes.func.isRequired,
  investigators: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  getInitData: PropTypes.func,
  states: PropTypes.arrayOf(StateProvinceDef),
};
IncidentContainer.defaultProps = {
  defaultIncident: undefined,
  draftIncident: undefined,
  onSubmit: f => f,
};

const mapStateToProps = ({ administration, newIncident, common }) => ({
  draftSelectorOpened: newIncident.draftSelectorOpened,
  draftList: newIncident.draftList,
  submitted: newIncident.submitted,
  draftIncident: newIncident.draftIncident,
  lookupLabelSet: common.lookupLabelSet,
  lookupDataSet: common.lookupDataSet,
  // openDeleteDraft: newIncident.openDeleteDraft,
  investigators: common.investigators,
  states: common.states,
  companySettings: common.companySettings,
});

const mapDispatchToProps = {
  queryDraftIncidents,
  updateDraftIncident,
  createDraftIncident,
  removeDraftIncident,
  setSelectedDraft,
  submitIncident,
  closeIncident,
  // setOpenDeleteDraft,
  changeDraftIncident,
  enqueueError,
  getInitData,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentContainer);
