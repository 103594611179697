import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Dialog, Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import ReactDiffViewer from 'react-diff-viewer';
import { makeStyles } from '@material-ui/styles';

import { isNullOrUndefined } from 'util';
import { loadHistoryCompare, resetHistoryCompare } from './ducks';
import {
  DraftIncidentDef,
  IncidentHistoryDef,
  LookupDataSetDef,
  IncidentHistoryCompareDef,
} from '../../components/propTypeDefs';
import { toReadableIncident } from '../../utils/incidentViewUtil';
import { dateFormat } from '../../utils/dateUtil';
import { DefaultDateFormat } from '../../constants';
import { useMediaInfo } from '../../utils/hooks';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    marginBottom: theme.spacing(1),
  },
  flex: {
    flex: 1,
  },
  section: {
    padding: theme.spacing(1),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    // flexWrap: 'wrap',
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  label: {
    ...theme.typography.caption,
    color: 'grey',
  },
  value: {
    ...theme.typography.body,
  },
  historyTable: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flexGrow: 1,
    overflow: 'auto',
  },
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const incidentTitle = (incident, name, accountDateFormat) => (
  <Box display="flex" alignItems="center">
    <Typography
      variant="subtitle1"
      color="primary"
      style={{ marginRight: '10px' }}
    >
      {name}
    </Typography>
    {incident ? (
      <Typography variant="caption" color="primary">
        (
        {dateFormat(
          incident.lastUpdateDate,
          DefaultDateFormat(accountDateFormat).datetimeMoment,
        )}
        )
      </Typography>
    ) : (
      undefined
    )}
  </Box>
);

const IncidentHistoryViewDialog = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    current: '',
    past: '',
    incidentNumber: undefined,
    prevTitle: undefined,
    currentTitle: undefined,
  });
  const { open } = props;
  const { isSmaller } = useMediaInfo({ minBreakPoint: 'sm' });

  const handleClose = () => {
    props.resetHistoryCompare();
    props.onClose();
  };

  const nullToUndefined = (key, value) =>
    value === null || value === '' ? undefined : value;

  const applyResult = result => {
    const { currentIncident, previousIncident } = result;

    const past = previousIncident
      ? toReadableIncident(
          previousIncident,
          props.lookupDataSet,
          props.companySettings.dateFormat,
          'diff',
        )
      : undefined;

    const current = toReadableIncident(
      currentIncident,
      props.lookupDataSet,
      props.companySettings.dateFormat,
      'diff',
    );

    setState({
      current: JSON.stringify(current, nullToUndefined, 2),
      past: past ? JSON.stringify(past, nullToUndefined, 2) : '',
      incidentNumber: currentIncident.number,
      prevTitle: props.noCompare
        ? incidentTitle(
            currentIncident,
            'Before',
            props.companySettings.dateFormat,
          )
        : incidentTitle(
            previousIncident,
            'Before',
            props.companySettings.dateFormat,
          ),
      currentTitle: incidentTitle(
        currentIncident,
        'After',
        props.companySettings.dateFormat,
      ),
    });
  };

  React.useEffect(() => {
    props
      .loadHistoryCompare(props.historyId)
      .then(() => {})
      .catch(err => {
        handleClose();
      });
  }, []);

  React.useEffect(() => {
    props.historyCompareResult && applyResult(props.historyCompareResult);
  }, [props.historyCompareResult]);

  const diffStyles = {
    line: {
      '&>td:nth-child(3n)': {
        width: '50%',
      },
    },
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Incident #{state.incidentNumber}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className={classes.contentWrapper}>
          <div className={classes.historyTable}>
            {!isSmaller && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-around"
              >
                {state.prevTitle}
                {state.currentTitle}
              </Box>
            )}
            <ReactDiffViewer
              className={classes.diffTable}
              oldValue={props.noCompare ? state.current : state.past}
              newValue={state.current}
              splitView={!isSmaller}
              onLineNumberClick={f => f}
              styles={diffStyles}
            />
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

IncidentHistoryViewDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  historyId: PropTypes.string.isRequired,
  loadHistoryCompare: PropTypes.func.isRequired,
  resetHistoryCompare: PropTypes.func.isRequired,
  historyCompareResult: IncidentHistoryCompareDef,
  lookupDataSet: LookupDataSetDef.isRequired,
  noCompare: PropTypes.bool,
};

IncidentHistoryViewDialog.defaultProps = {
  onClose: () => {},
  noCompare: false,
};

const mapStateToProps = ({ incidentView, common }) => ({
  historyCompareLoaded: incidentView.historyCompareLoaded,
  historyCompareResult: incidentView.historyCompareResult,
  lookupDataSet: common.lookupDataSet,
  companySettings: common.companySettings,
});

const mapDispatchToProps = {
  loadHistoryCompare,
  resetHistoryCompare,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncidentHistoryViewDialog);
