import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  Box,
  Typography,
  IconButton,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  CardHeader,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ReactEcharts from 'echarts-for-react';

import { CustomAnalysisDef } from '../../components/propTypeDefs';
import { getChartTypeInfo } from '../analyze/ChartTypeSelector';
import { executeCustomAnalysis } from './ducks';

const CHART_HEIGHT = 180;
const CHART_ROW_HEIGHT = CHART_HEIGHT / 2;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  chartWrapper: {
    height: 200,
  },
  cardItemTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  nospace: {
    padding: theme.spacing(1),
  },
  cardItem: {
    cursor: 'pointer',
  },
}));

const DashboardChartItem = props => {
  const classes = useStyles();
  const chartRef = React.useRef();
  const [state, setState] = React.useState({
    chartData: undefined,
  });
  const { analysis } = props;
  const { name, criteria, id } = analysis;

  const getXAxis = dataSet => {
    if (state.selectedChartType.type === 'pie') {
      return undefined;
    }

    return {
      type: 'category',
      data: dataSet.category,
      name: analysis.frequency,
      nameLocation: 'center',
      nameGap: 35,
      axisLabel: {
        interval: 0,
        rotate: 20,
      },
    };
  };

  const getYAxis = () => {
    if (state.selectedChartType.type === 'pie') {
      return undefined;
    }

    return {
      type: 'value',
      minInterval: 1,
      name: 'Incident Count',
      nameLocation: 'center',
      nameGap: 35,
    };
  };

  const getPieRadius = dataSet => {
    let radius = CHART_HEIGHT / 4;
    if (dataSet.series.length === 2) {
      // 2 X 1
      radius = CHART_HEIGHT / 6;
    } else if (dataSet.series.length > 2) {
      // 3 X 2
      radius = CHART_HEIGHT / 6;
    }

    return `${radius}px`;
  };

  const getPieCenter = (dataSet, index) => {
    if (dataSet.series.length === 1) return [`60%`, `${CHART_HEIGHT / 2}px`];
    if (dataSet.series.length === 2) {
      if (index === 0) {
        return [`40%`, `${CHART_HEIGHT / 2}px`];
      }
      if (index === 1) {
        return [`80%`, `${CHART_HEIGHT / 2}px`];
      }
    }

    // chart series count >= 3
    const colWidth = 100 / 3;
    const totalRowCount = Math.floor(dataSet.series.length / 3) + 1;
    const row = Math.floor(index / 3) + 1;
    const col = (index % 3) + 1;
    const rowPos = CHART_ROW_HEIGHT * row - CHART_ROW_HEIGHT / 2;
    const colPos = Math.floor(colWidth * col - colWidth / 2);
    return [`${colPos + 5}%`, `${rowPos}px`];
  };

  const getSeries = dataSet => {
    if (state.selectedChartType.type === 'pie') {
      return dataSet.series.map((x, index) => ({
        type: 'pie', // state.selectedChartType.type,
        radius: getPieRadius(dataSet),
        center: getPieCenter(dataSet, index),
        label: {
          show: false,
          position: 'outside',
          alignTo: 'labelLine',
          bleedMargin: 5,
          formatter: value => {
            return `${value.name}(${value.value})`;
          },
        },
        data: x,
        stack: false,
      }));
    }

    return dataSet.series.map((x, index) => ({
      type: state.selectedChartType.type,
      label: {
        show: true,
        position: 'top',
        formatter: data => {
          return data.value === 0 ? '' : data.value;
        },
      },
      data: x,
      stack: false,
      name: dataSet.legend[index],
    }));
  };

  const getSubTitles = dataSet => {
    if (state.selectedChartType.type !== 'pie') {
      return [];
    }
    return dataSet.legend.map((x, index) => {
      let col = 60;
      let row = `${CHART_HEIGHT - 50}px`;

      if (dataSet.series.length === 1) {
        col = 60;
        row = `${CHART_HEIGHT - 50}px`;
      } else if (dataSet.series.length === 2) {
        col = 40 + 40 * index;
        row = `${CHART_HEIGHT - 50}px`;
      } else if (dataSet.series.length > 2) {
        col = 16.66665 + 33.3333 * (index % 3) + 5;
        row = (Math.floor(index / 3) + 1) * CHART_ROW_HEIGHT - 50;
      }
      return {
        subtext: x,
        left: `${col}%`,
        top: `${row}px`,
        textAlign: 'center',
      };
    });
  };

  const getLegend = () => {
    if (!state.chartData) return { data: [] };
    if (
      state.selectedChartType.type === 'pie' &&
      state.chartData.series.length > 0
    ) {
      return {
        orient: 'vertical',
        left: 'left',
        data: state.chartData.series[0].map(x => x.name),
        textStyle: {
          fontSize: 10,
        },
      };
    }

    return {
      type: 'scroll',
      data: state.chartData.legend,
    };
  };
  const getChartOptions2 = () => {
    const dataSet = state.chartData;
    if (!dataSet) return undefined;

    return {
      title: [
        {
          text: '',
        },
        ...getSubTitles(dataSet),
      ],
      toolbox: { show: false },
      legend: getLegend(),
      tooltip: {},
      xAxis: getXAxis(dataSet),
      yAxis: getYAxis(),
      series: getSeries(dataSet),
    };
  };

  const destructureChartData = (data, chartType) => {
    if (!data || data.length === 0) return undefined;
    const legend = data.map(x => x.periodName);
    const category = data[0].data.map(x => x.period);
    const extractData = period =>
      period.data.map(x => {
        if (chartType === 'pie') {
          return {
            name: x.period,
            value: x.count,
          };
        }
        return x.count;
      });
    const series = data.map(x => extractData(x));
    return { legend, category, series };
  };

  const handleDelete = event => {
    event.stopPropagation();
    props.onCommand('delete');
  };
  const handleEdit = event => {
    event.stopPropagation();
    props.onCommand('edit');
  };
  const handleOpen = () => {
    props.onCommand('view');
  };

  React.useEffect(() => {
    props.executeCustomAnalysis(analysis);
  }, []);

  React.useEffect(() => {
    const chartResult = props.analysisResultSet[id];
    if (chartResult) {
      const { chartType = 'bar' } = props.analysis || {};
      const chartData = destructureChartData(chartResult, chartType);
      setState({
        ...state,
        selectedChartType: getChartTypeInfo(chartType),
        chartData,
      });
    }
  }, [props.analysisResultSet[id]]);

  const chartOption = getChartOptions2();

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card className={classes.cardItem} onClick={handleOpen}>
        <CardHeader
          // avatar={}
          // action={}
          title={
            <Box display="flex" alignItems="center">
              <Typography variant="h5" gutterBottom>
                {props.analysis.name}
              </Typography>
            </Box>
          }
          subheader={props.analysis.description}
        />
        <CardContent className={classes.nospace}>
          <div className={classes.chartWrapper}>
            {chartOption && (
              <ReactEcharts
                ref={chartRef}
                option={chartOption}
                style={{ width: '100%', height: '100%' }}
                theme="infographic"
              />
            )}
          </div>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton onClick={handleEdit} color="primary">
            <EditIcon />
          </IconButton>
          <IconButton onClick={handleDelete}>
            <DeleteIcon color="error" />
          </IconButton>
        </CardActions>
      </Card>
    </Grid>
  );
};

DashboardChartItem.propTypes = {
  analysis: CustomAnalysisDef.isRequired,
  onCommand: PropTypes.func,
  analysisResultSet: PropTypes.object.isRequired,
  executeCustomAnalysis: PropTypes.func.isRequired,
};
DashboardChartItem.defaultProps = {
  onCommand: f => f,
};

const mapStateToProps = ({ dashboard }) => ({
  analysisResultSet: dashboard.analysisResultSet,
});
const mapDispatchToProps = {
  executeCustomAnalysis,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardChartItem);
