import BaseApi from './BaseApi';

class SiteAdminApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl += '/siteAdmins';
  }

  async restoreDemoSite() {
    return await this.post(`${this.baseUrl}/demoServer/import`, {});
  }

  async syncQuickBooks() {
    return await this.get(`${this.baseUrl}/authenticateQuickBooks`);
  }
}

export default new SiteAdminApi();
