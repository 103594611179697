import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import RichEditor from '../../components/forms/RichEditor';
import ExpansionCard from '../../components/cards/ExpansionCard';

const useStyles = makeStyles(theme => ({
  expansionCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  expansionCardItem: {},
}));

const IncidentSummaryCard = props => {
  const classes = useStyles();

  const handleChange = (value, plainText) => {
    props.onChange({
      summary: value,
      summaryText: plainText,
    });
  };
  return (
    <ExpansionCard
      expanded={props.opened}
      title="Summary"
      helpMessage="Add a descriptive summary of the incident here. Tip: Add information that hasn't been entered in other sections of the incident record. Be thorough, descriptive, and objective. "
    >
      <div className={classes.expansionCardContainer}>
        <div className={classes.expansionCardItem}>
          <RichEditor
            onChange={handleChange}
            defaultValue={props.defaultValue}
          />
        </div>
      </div>
    </ExpansionCard>
  );
};

IncidentSummaryCard.propTypes = {
  onChange: PropTypes.func,
  opened: PropTypes.bool,
  defaultValue: PropTypes.object,
};
IncidentSummaryCard.defaultProps = {
  opened: false,
  defaultValue: undefined,
  onChange: f => f,
};

export default IncidentSummaryCard;
