import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  queryDraftIncidents,
  setSelectedDraft,
  createDraftIncident,
} from './ducks';
import { getInitData } from '../../modules/common';
import DraftSelectDialog from './DraftSelectDialog';
import {
  DraftIncidentDef,
  LookupDataSetDef,
  ChildrenDef,
  LookupLabelSetDef,
} from '../../components/propTypeDefs';
import { lookupTypeList } from '../../constants';
import history from '../../history';
import { LookupQueryFilter } from '../../models/helpers/QueryModel';
import IncidentContainer from './IncidentContainer';
import Auth from '../../auth/Auth';
import { enqueueWarning } from '../../modules/global';

const DraftIncidentFormWrapper = props => {
  const handleSubmitted = () => {
    history.push('/dashboard');
  };

  // on mounted
  React.useEffect(() => {
    if (Auth.currentUser && Auth.currentUser.accountDisabled) {
      history.push('/dashboard');
      props.enqueueWarning('Account is disabled.');
    } else {
      props.createDraftIncident();
      props.getInitData();
    }

    return () => {
      props.setSelectedDraft(null);
    };
  }, []);

  return (
    <>
      <IncidentContainer onSubmit={handleSubmitted} />
      {/* <DraftSelectDialog
        opened={props.draftSelectorOpened}
        draftList={props.draftList}
        incidentLookups={props.lookupDataSet[LookupTypeKey.incidentClass]}
        onSelect={handleSelectDraft}
        onCancel={handleSelectCancel}
        onCreate={handelCreateDraft}
      /> */}
    </>
  );
};

DraftIncidentFormWrapper.propTypes = {
  // draftSelectorOpened: PropTypes.bool.isRequired,
  // draftList: PropTypes.arrayOf(DraftIncidentDef).isRequired,
  // queryDraftIncidents: PropTypes.func.isRequired,
  createDraftIncident: PropTypes.func.isRequired,
  setSelectedDraft: PropTypes.func.isRequired,
  getInitData: PropTypes.func.isRequired,
  enqueueWarning: PropTypes.func.isRequired,
  // lookupDataSet: LookupDataSetDef.isRequired,
  // lookupLabelSet: LookupLabelSetDef.isRequired,
};

const mapStateToProps = ({ newIncident, common }) => ({
  // draftSelectorOpened: newIncident.draftSelectorOpened,
  // draftList: newIncident.draftList,
  // lookupDataSet: common.lookupDataSet,
  // lookupLabelSet: common.lookupLabelSet,
});

const mapDispatchToProps = {
  // queryDraftIncidents,
  createDraftIncident,
  setSelectedDraft,
  getInitData,
  enqueueWarning,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DraftIncidentFormWrapper);
