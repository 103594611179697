import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import BaseDialog from '../../components/dialogs/BaseDialog';
import { dateFormat } from '../../utils/dateUtil';
import { LookupDef } from '../../components/propTypeDefs';
import { buildLookupPath } from '../../utils/lookupTreeUtil';

const useStyles = makeStyles(theme => ({
  number: {
    flexGrow: 1,
  },
  date: {},
  itemRow: {
    cursor: 'pointer',
  },
}));

const DraftSelectDialog = props => {
  const classes = useStyles();
  const [list, setList] = React.useState(props.draftList);

  React.useEffect(() => {
    const newList = [...props.draftList];
    newList.sort((a, b) => (b.lastUpdateDate > a.lastUpdateDate ? 1 : -1)); // order by lastUpdateDate desc
    setList(newList);
  }, [props.draftList]);

  const handleSelect = item => () => {
    props.onSelect(item);
    props.onClose();
  };
  const handleCreate = () => {
    props.onCreate();
    props.onClose();
  };
  const handleCancel = () => {
    props.onCancel();
    props.onClose();
  };
  return (
    <BaseDialog
      open={props.opened}
      maxWidth="md"
      title="Select Draft"
      onClose={props.onClose}
      actionContent={
        <>
          <Button onClick={handleCreate} variant="contained" color="primary">
            Create new incident
          </Button>
          <Button onClick={handleCancel} color="default">
            Not Now
          </Button>
        </>
      }
    >
      {props.opened && (
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Date created</TableCell>
              <TableCell>Last modified</TableCell>
              <TableCell>What</TableCell>
              <TableCell>Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map(item => (
              <TableRow
                key={item.id}
                hover
                onClick={handleSelect(item)}
                className={classes.itemRow}
              >
                <TableCell>{dateFormat(item.creationDate, 'L')}</TableCell>
                <TableCell>{dateFormat(item.lastUpdateDate, 'L LT')}</TableCell>
                <TableCell>
                  {buildLookupPath(props.incidentLookups, item.lastClassId)}
                </TableCell>
                <TableCell>#{item.number}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </BaseDialog>
  );
};

DraftSelectDialog.propTypes = {
  opened: PropTypes.bool,
  draftList: PropTypes.arrayOf(PropTypes.object).isRequired,
  incidentLookups: PropTypes.arrayOf(LookupDef).isRequired,
  onSelect: PropTypes.func,
  onCreate: PropTypes.func,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  accountDateFormat: PropTypes.number,
};

DraftSelectDialog.defaultProps = {
  opened: false,
  draftList: [],
  onSelect: f => f,
  onCreate: f => f,
  onClose: f => f,
  onCancel: f => f,
};
export default DraftSelectDialog;
