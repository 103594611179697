import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Paper, Typography, Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import moment from 'moment';
import BaseDialog from '../../../components/dialogs/BaseDialog';
import { toReadableAudit } from '../../../utils/auditViewUtil';
import BaseSearchCriteria from '../../search/BaseSearchCriteria';
import {
  DateSearchPeriodType,
  DefaultDateFormat,
  IncidentSearchType,
  LookupTypeKey,
} from '../../../constants';
import { getPersonName } from '../../../utils/nameUtil';
import LookupMultipleSearchCriteria from '../../search/LookupMultipleSearchCriteria';
import { translateConfig } from '../../../utils/simpleTranslateUtil';

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: 600,
  },
  value: {
    fontWeight: 500,
    whiteSpace: 'pre-line',
  },
  paper: {
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  searchCriteriaTitle: {
    display: 'flex',
    // flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
}));

const AuditSearchViewer = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    readable: {},
  });
  const { auditData } = props;
  const { readable } = state;

  const handleClose = () => {
    props.onClose();
  };

  const getMonthLabel = months => {
    const monthNames = moment.monthsShort();
    let result = '';
    months.forEach(month => {
      result = `${result + monthNames[month - 1]} `;
    });

    return result.trim();
  };

  const getWeekDaysLabel = dayOfWeeks => {
    const weekdaysNames = moment.weekdaysShort();
    let result = '';
    dayOfWeeks.forEach(day => {
      result = `${result + weekdaysNames[day - 1]} `;
    });

    return result.trim();
  };

  const getDateCriteriaName = option => {
    switch (option.periodType) {
      case DateSearchPeriodType.period:
        return `From  ${moment(option.fromDate).format(
          DefaultDateFormat(props.accountDateFormat).dateMoment,
        )}  To  ${moment(option.toDate).format(
          DefaultDateFormat(props.accountDateFormat).dateMoment,
        )}`;
      case DateSearchPeriodType.month:
        return getMonthLabel(option.months);
      case DateSearchPeriodType.dayOfWeek:
        return getWeekDaysLabel(option.dayOfWeeks);
      case DateSearchPeriodType.lastNDays:
        if (option.days === 1) {
          return `Last 1 day`;
        }
        return `Last ${option.days} days`;
      case DateSearchPeriodType.lastNMonths:
        if (option.lastNMonths === 0) {
          return `Current month`;
        }
        if (option.lastNMonths === 1) {
          return `Prvious month`;
        }
        return `Last ${option.lastNMonths} months`;
      default:
        return '';
    }
  };

  const getCriteriaNameHandlers = {
    [IncidentSearchType.dateReported]: getDateCriteriaName,
    [IncidentSearchType.dateOccurred]: getDateCriteriaName,
    [IncidentSearchType.person]: option => {
      const {
        firstName,
        lastName,
        middleName,
        hasPerson,
        isEmployee,
        relations,
      } = option;
      let personName = getPersonName({ firstName, lastName, middleName });

      if (hasPerson) {
        return 'has person';
      }

      if (isEmployee) {
        personName = `${personName} - Employee`;
      }

      if (relations) {
        personName = `${personName} - ${relations.join(', ')}`;
      }
      return personName;
    },
    [IncidentSearchType.company]: option => {
      const { name, hasCompany, isOwner, relations } = option;

      if (hasCompany) {
        return 'has legal entity';
      }

      let companyName = name;

      if (isOwner) {
        companyName = `${name} - Owner`;
      }

      if (relations) {
        companyName = `${companyName} - ${relations.join(', ')}`;
      }

      return companyName;
    },
    [IncidentSearchType.item]: option => {
      const { name, brand, model, color, serialNumber, hasItem } = option;

      if (hasItem) {
        return 'has item';
      }

      const modelName = `${brand || ''} ${model || ''} ${color || ''}`.trim();

      const itemName = `${name || ''} ${modelName || ''} ${serialNumber ||
        ''}`.trim();

      if (itemName) {
        return itemName;
      }

      return 'Unknown';
    },
    [IncidentSearchType.vehicle]: option => {
      const {
        licensePlate,
        stateProvince,
        maker,
        model,
        color,
        hasVehicle,
      } = option;

      if (hasVehicle) {
        return 'has vehicle';
      }

      let vehicleName = '';
      const modelName = `${maker || ''} ${model || ''} ${color || ''}`.trim();
      const number = `${licensePlate || ''} ${stateProvince || ''}`.trim();

      if (modelName && number) {
        vehicleName = `${modelName} (${number})`.trim();
      } else if (modelName) {
        vehicleName = modelName;
      } else if (number) {
        vehicleName = number;
      }

      if (vehicleName) {
        return vehicleName;
      }

      return 'Unknown';
    },
    [IncidentSearchType.address]: option => {
      const {
        unit,
        address,
        street,
        countryCode,
        stateProvinceCode,
        cityName,
      } = option;
      return [unit, address, street, countryCode, stateProvinceCode, cityName]
        .filter(Boolean)
        .join(', ');
    },
    [IncidentSearchType.attachment]: option =>
      option.hasAttachment ? 'has attachment' : option.name,
    [IncidentSearchType.status]: option =>
      translateConfig.enums.incidentStatus[option.status],
    [IncidentSearchType.creator]: getPersonName,
  };

  React.useEffect(() => {
    setState({
      ...state,
      readable: toReadableAudit(auditData, props.auditType),
    });
  }, [props.auditData]);

  return (
    <BaseDialog
      open={props.open}
      maxWidth="md"
      onClose={handleClose}
      title="Audit detail"
      actionContent={
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      }
    >
      <Paper className={classes.paper}>
        <div className={classes.searchCriteriaTitle}>
          <Typography variant="h6" className={classes.labelWithHelpIcon}>
            Search Criteria
          </Typography>
        </div>
        {auditData.dateOccurredCriteria && (
          <BaseSearchCriteria
            title="Date occurred"
            options={auditData.dateOccurredCriteria.values}
            getName={getCriteriaNameHandlers[IncidentSearchType.dateOccurred]}
          />
        )}

        {auditData.dateReportedCriteria && (
          <BaseSearchCriteria
            title="Date reported"
            options={auditData.dateReportedCriteria.values}
            getName={getCriteriaNameHandlers[IncidentSearchType.dateReported]}
          />
        )}

        {auditData.classCriteria && (
          <LookupMultipleSearchCriteria
            options={auditData.classCriteria.values}
            title="Class"
            lookupType={LookupTypeKey.incidentClass}
          />
        )}

        {auditData.personCriteria && (
          <BaseSearchCriteria
            title="Person"
            options={auditData.personCriteria.values}
            getName={getCriteriaNameHandlers[IncidentSearchType.person]}
          />
        )}

        {auditData.companyCriteria && (
          <BaseSearchCriteria
            title="Legal entity"
            options={auditData.companyCriteria.values}
            getName={getCriteriaNameHandlers[IncidentSearchType.company]}
          />
        )}

        {auditData.vehicleCriteria && (
          <BaseSearchCriteria
            title="Vehicle"
            options={auditData.vehicleCriteria.values}
            getName={getCriteriaNameHandlers[IncidentSearchType.vehicle]}
          />
        )}

        {auditData.itemCriteria && (
          <BaseSearchCriteria
            title="Item"
            options={auditData.itemCriteria.values}
            getName={getCriteriaNameHandlers[IncidentSearchType.item]}
          />
        )}

        {auditData.locationCriteria && (
          <LookupMultipleSearchCriteria
            options={auditData.locationCriteria.values}
            title="Location"
            lookupType={LookupTypeKey.location}
          />
        )}

        {auditData.addressCriteria && (
          <BaseSearchCriteria
            title="Address"
            options={auditData.addressCriteria.values}
            getName={getCriteriaNameHandlers[IncidentSearchType.address]}
          />
        )}

        {auditData.attachmentCriteria && (
          <BaseSearchCriteria
            title="Attachment"
            options={auditData.attachmentCriteria.values}
            getName={getCriteriaNameHandlers[IncidentSearchType.attachment]}
          />
        )}

        {auditData.statusCriteria && (
          <BaseSearchCriteria
            title="Status"
            options={auditData.statusCriteria.values}
            getName={getCriteriaNameHandlers[IncidentSearchType.status]}
          />
        )}

        {auditData.creatorCriteria && (
          <BaseSearchCriteria
            title="Entered by"
            options={auditData.creatorCriteria.values}
            getName={getCriteriaNameHandlers[IncidentSearchType.creator]}
          />
        )}
      </Paper>
    </BaseDialog>
  );
};

AuditSearchViewer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  auditData: PropTypes.object.isRequired,
  auditType: PropTypes.string.isRequired,
  accountDateFormat: PropTypes.number,
};

AuditSearchViewer.defaultProps = {
  onClose: f => f,
};

export default AuditSearchViewer;
