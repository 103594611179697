import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import * as yup from 'yup';
import { Button, TextField, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';

import AttachmentIcon from '@material-ui/icons/Attachment';
import AlertDialog from './AlertDialog';
import BaseDialog from './BaseDialog';
import { useForm } from '../../utils/hooks';
import { IncidentAttachmentDef } from '../propTypeDefs';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 600,
    flexGrow: 1,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  bottomMargin: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // height: 80,
    // flexWrap: 'wrap',
  },
  buttonSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    // alignItems: 'center',
    // height: 80,
    flexWrap: 'wrap',
  },
  fileButton: {
    height: 50,
  },
}));

const schema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .max(100)
    .required(),
});

const EditAttachmentDialog = props => {
  const classes = useStyles();
  const {
    formValues,
    updateFormValues,
    hasFormError,
    formHelpText,
    handleFormChange,
    handleFormBlur,
    handleFormSubmit,
  } = useForm({
    validationSchema: schema,
    onSubmit: (values, errors) => {
      if (_.isEmpty(errors)) {
        props.onChange(values);
      }
    },
  });

  const handleOk = () => {
    handleFormSubmit();
  };

  const debounceHandleOk = _.debounce(handleOk, 200);

  const onClose = () => {
    props.onClose();
  };

  const inputHandleChange = event => {
    handleFormChange(event);
  };

  React.useEffect(() => {
    updateFormValues(props.attachment);
  }, [props.attachment]);

  return (
    <BaseDialog
      open={props.open}
      onClose={props.onClose}
      title="Edit Attachment"
      actionContent={
        <>
          <Button
            onClick={debounceHandleOk}
            variant="contained"
            color="primary"
          >
            Ok
          </Button>
          <Button onClick={onClose} color="default" pl={3}>
            Cancel
          </Button>
        </>
      }
    >
      <FormControl className={classes.formControl}>
        <TextField
          required
          name="name"
          label="Name"
          value={formValues.name || ''}
          onChange={inputHandleChange}
          margin="none"
          onBlur={handleFormBlur}
          InputLabelProps={{
            shrink: true,
          }}
          error={hasFormError('name')}
          helperText={formHelpText('name')}
          inputProps={{ autoComplete: 0 }}
        />
      </FormControl>
    </BaseDialog>
  );
};

EditAttachmentDialog.propTypes = {
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  attachment: IncidentAttachmentDef,
};

EditAttachmentDialog.defaultProps = {
  onChange: () => {},
  onClose: () => {},
  attachment: undefined,
};

export default EditAttachmentDialog;
