import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import {
  FormControl,
  Typography,
  Button,
  InputLabel,
  Input,
} from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  group: {
    margin: 0,
  },
  label: {
    marginLeft: theme.spacing(-1),
  },
  searchFields: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}));

const CreatorSearchOption = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({});
  const { firstName, lastName } = state;

  const inputHandleChange = event => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const addSearchCriteria = () => {
    const person = {
      id: uuid(),
      firstName,
      lastName,
    };

    props.onSearchCriteriaAdded(person);
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography variant="h6">Entered by</Typography>
        <div className={classes.searchFields}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="firstName">First name</InputLabel>
            <Input
              id="firstName"
              name="firstName"
              autoFocus
              value={firstName || ''}
              onChange={inputHandleChange}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="lastName">Last name</InputLabel>
            <Input
              id="lastName"
              name="lastName"
              value={lastName || ''}
              onChange={inputHandleChange}
            />
          </FormControl>
        </div>
        <div className={classes.searchFields}>
          <FormControl className={classes.formControl}>
            <Button
              variant="contained"
              color="primary"
              className={classes.searchButton}
              onClick={addSearchCriteria}
            >
              Add Search Criteria
            </Button>
          </FormControl>
        </div>
      </div>
    </React.Fragment>
  );
};

CreatorSearchOption.propTypes = {
  onSearchCriteriaAdded: PropTypes.func.isRequired,
};

export default CreatorSearchOption;
