import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import _ from 'lodash';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles } from '@material-ui/styles';
import { Elements, StripeProvider } from 'react-stripe-elements';

import CheckoutForm from './CheckoutForm';
import { savePaymentMethod } from './ducks';
import config, { getEnvironment } from '../../../config';
import { SubscriptionDef } from '../../../components/propTypeDefs';
import { enqueueError } from '../../../modules/global';

const poweredByStripe = require('../../../images/powered_by_stripe.png');

const env = getEnvironment();

const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    // textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    alignItems: 'center',
    // height: 80,
    flexWrap: 'wrap',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  subscriptionItem: {
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  currentPaymentCard: {
    padding: theme.spacing(1),
    fontWeight: 600,
  },
}));

const PaymentMethod = props => {
  const classes = useStyles();
  const { subscription } = props;

  const handleSavePayment = model => {
    props.savePaymentMethod(model);
  };

  return (
    <>
      {env !== 'prod' && (
        <div className={classes.subscriptionItem}>
          <div>Test credit card: 4000001240000000</div>
        </div>
      )}

      <div className={classes.subscriptionItem}>
        <StripeProvider apiKey={config.stripe.pubKey}>
          <Elements>
            <CheckoutForm
              cardExist={subscription ? !!subscription.cardLast4 : false}
              onSave={handleSavePayment}
              onError={error => {
                props.enqueueError(error);
              }}
            />
          </Elements>
        </StripeProvider>
      </div>
      <div className={classes.subscriptionItem}>
        <Typography variant="h6">Current payment method:</Typography>
        {subscription && subscription.cardLast4 ? (
          <>
            <Typography
              variant="subtitle1"
              color="secondary"
              className={classes.currentPaymentCard}
            >
              xxxx xxxx xxxx {subscription.cardLast4}
            </Typography>
            {subscription.paymentError && <ErrorIcon htmlColor="red" />}
            <Typography variant="caption" style={{ color: 'red' }}>
              {subscription.paymentError}
            </Typography>
          </>
        ) : (
          <Typography
            variant="subtitle1"
            color="error"
            className={classes.subscriptionItem}
          >
            ( No payment method )
          </Typography>
        )}
      </div>
      <div className={classes.subscriptionItem}>
        <a href="https://www.stripe.com" target="stripe">
          <img src={poweredByStripe} height="30px" alt="" />
        </a>
      </div>
    </>
  );
};

PaymentMethod.propTypes = {
  subscription: SubscriptionDef,
  savePaymentMethod: PropTypes.func.isRequired,
  enqueueError: PropTypes.func.isRequired,
};
PaymentMethod.defaultProps = {};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  savePaymentMethod,
  enqueueError,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);
