import { isNullOrUndefined } from 'util';
import { CorrectiveActionDisplayType } from '../constants';

const nullToBlank = value => {
  return isNullOrUndefined(value) ? '' : value;
};

export const getPersonName = ({
  firstName,
  lastName = '',
  middleName = '',
}) => {
  if (firstName && lastName && middleName) {
    return `${firstName} ${middleName} ${lastName}`.trim();
  }
  if (firstName && lastName) {
    return `${firstName} ${lastName}`.trim();
  }
  if (firstName && middleName) {
    return `${firstName} ${middleName}`.trim();
  }
  if (lastName && middleName) {
    return `${middleName} ${lastName}`.trim();
  }
  if (firstName) {
    return firstName.trim();
  }
  if (lastName) {
    return lastName.trim();
  }
  if (middleName) {
    return middleName.trim();
  }
  return 'Unknown';
};

export const getCompanyName = ({ name }) => {
  if (!name) {
    return 'Unknown';
  }

  return name;
};

export const getVehicleName = ({ licensePlate, maker, model, color }) => {
  let vehicleName = '';
  const modelName = `${nullToBlank(maker)} ${nullToBlank(model)} ${nullToBlank(
    color,
  )}`.trim();

  if (modelName && licensePlate) {
    vehicleName = `${nullToBlank(modelName)} (${licensePlate})`.trim();
  } else if (modelName) {
    vehicleName = modelName;
  } else if (licensePlate) {
    vehicleName = licensePlate;
  }

  if (vehicleName) {
    return vehicleName;
  }

  return 'Unknown';
};

export const getIncidentItemName = ({ name, brand, model, color }) => {
  const modelName = `${nullToBlank(brand)} ${nullToBlank(model)} ${nullToBlank(
    color,
  )}`.trim();

  if (modelName && name) {
    return `${name} (${modelName})`.trim();
  }
  if (modelName) {
    return modelName;
  }
  if (name) {
    return name;
  }
  return 'Unknown';
};

export default {
  getPersonName,
  getCompanyName,
  getVehicleName,
  getIncidentItemName,
};
