import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Input,
  Typography,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Link,
  Button,
  TableFooter,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import ViewIcon from '@material-ui/icons/Pageview';
import uuid from 'uuid/v4';
import produce from 'immer';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import moment from 'moment';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import IncidentViewDialog from '../../incidentView/IncidentViewDialog';
import { incidentSearchResult } from '../../../mockData';
import {
  AuditObjectType,
  DefaultDateFormat,
  LookupTypeKey,
} from '../../../constants';
import { dateFormat } from '../../../utils/dateUtil';
import { AuditDef, LookupDataSetDef } from '../../../components/propTypeDefs';
import { queryAuditsByPeriod } from './ducks';
import BasePagination from '../../../components/list/BasePagination';
import IncidentHistoryViewDialog from '../../incidentView/IncidentHistoryViewDialog';
import AuditDetailViewer from './AuditDetailViewer';
import AuditSearchViewer from './AuditSearchViewer';
import AuditAnalyzeViewer from './AuditAnalyzeViewer';

const useStyles = makeStyles(theme => ({
  root: {
    flexGaudit: 1,
    backgroundColor: theme.palette.grey['100'],
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
    paddingBottom: 200,
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
    width: '100%',
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  lineItem: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  addAuditDialog: {
    width: '80%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  searchBar: {
    display: 'flex',
    alignItems: 'center',
  },
  group: {
    margin: theme.spacing(1, 2),
    flexDirection: 'row',
  },
  progress: {
    margin: theme.spacing(1),
  },
}));

const auditObjectTypes = [
  { name: 'Incident', value: 'INCIDENT' },
  { name: 'User', value: 'USER' },
  { name: 'Workgroup', value: 'USER_GROUP' },
  { name: 'Account', value: 'ACCOUNT' },
  { name: 'Lookup', value: 'LOOKUP' },
  { name: 'Authentication', value: 'AUTHENTICATION' },
  { name: 'Search', value: 'SEARCH' },
  { name: 'Analyze', value: 'ANALYZE' },
  { name: 'Report', value: 'REPORT' },
  { name: 'Subscription', value: 'SUBSCRIPTION' },
  { name: 'Payment method', value: 'PAYMENT_METHOD' },
];
const today = moment();

const AuditManagement = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    audits: [],
    filterType: 'byAuditType',
    filterValue: 'INCIDENT',
    userList: [],
    deleteDialogOpen: false,
    selectedAudit: undefined,
    selectedObjectType: 'INCIDENT',
    selectedUserId: '',
    fromDate: today
      .clone()
      .add(-1, 'month')
      .startOf('day')
      .format(DefaultDateFormat().dateTimeISO),
    toDate: today
      .clone()
      .endOf('day')
      .format(DefaultDateFormat().dateTimeISO),
    page: 0,
    rowsPerPage: 10,
    totalCount: 0,
  });
  const [detailView, setDetailView] = React.useState({
    open: false,
    subject: undefined,
    subjectType: undefined,
    subjectId: undefined,
    dialogType: undefined,
    transactionType: undefined,
  });
  const { audits, selectedObjectType, incidentViewOpen } = state;

  const getFilteredState = page => {
    const filterName =
      state.filterType === 'byAuditType' ? 'objectType' : 'createUserId';
    const filterValue = state.filterValue;
    const filteredList = filterValue
      ? props.storedAudits.filter(x => x[filterName] === filterValue)
      : props.storedAudits;
    const skip = page * state.rowsPerPage;
    const currentList = filteredList.slice(skip, skip + state.rowsPerPage);
    return {
      audits: currentList,
      page,
      totalCount: filteredList.length,
    };
  };
  const filterList = (page = 0) => {
    setState({
      ...state,
      ...getFilteredState(page),
    });
  };
  const handleSelectAuditType = event => {
    const { name, value } = event.target;
    setState({
      ...state,
      selectedObjectType: value,
      filterValue: value,
    });
  };
  const handleSelectUserId = event => {
    const { name, value } = event.target;
    setState({
      ...state,
      selectedUserId: value,
      filterValue: value,
    });
  };
  const handleDateChange = name => date => {
    setState({ ...state, [name]: date });
  };
  const handleSearch = e => {
    const { fromDate, toDate } = state;
    props.queryAuditsByPeriod({
      fromDate: moment(fromDate)
        .startOf('day')
        .toDate(),
      toDate: moment(toDate)
        .endOf('day')
        .toDate(),
    });
  };
  const handleChangePage = page => {
    filterList(page);
    // setState({ ...state, ...filterList(selectedObjectType, page) });
  };
  const handleFilterTypeChange = e => {
    const { value: filterType } = e.target;
    const searchValue =
      filterType === 'byAuditType'
        ? state.selectedObjectType
        : state.selectedUserId;
    setState({ ...state, filterType, filterValue: searchValue });
  };
  const handleClickDetail = auditItem => () => {
    let dialogType;
    let subjectId;
    switch (auditItem.objectType) {
      case 'INCIDENT':
        dialogType = 'INCIDENT_HISTORY';
        subjectId = auditItem.objectHistoryId;
        break;
      case 'SEARCH':
        dialogType = 'SEARCH_CRITERIA';
        break;
      case 'ANALYZE':
        dialogType = 'ANALYZE_CRITERIA';
        break;
      default:
        dialogType = 'GENERAL';
        subjectId = auditItem.objectId;
        break;
    }

    setDetailView({
      open: true,
      dialogType,
      subjectId,
      subject: auditItem.objectData,
      subjectType: auditItem.objectType,
      transactionType: auditItem.transactionType,
    });
  };
  const hasDetailAudit = auditItem =>
    !['AUTHENTICATION', 'REPORT'].includes(auditItem.objectType);
  const handleCloseDetail = () => {
    setDetailView({
      open: false,
      dialogType: undefined,
      subject: undefined,
      subjectType: undefined,
    });
  };

  React.useEffect(() => {
    const userList = _.uniqBy(
      props.storedAudits.map(({ createUserId: id, createUserName: name }) => ({
        id,
        name,
      })),
      'id',
    );
    setState({
      ...state,
      ...getFilteredState(0),
      userList,
    });
  }, [props.storedAudits]);

  React.useEffect(() => {
    filterList(0);
  }, [state.filterType, state.filterValue]);
  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        {/* <div className={classes.title}>
          <Typography variant="h4">Audits</Typography>
        </div> */}
        <div className={classes.searchBar}>
          <FormControl className={classes.formControl}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Date from"
                name="fromDate"
                disableFuture
                format={
                  DefaultDateFormat(props.companySettings.dateFormat).date
                }
                value={state.fromDate}
                onChange={handleDateChange('fromDate')}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl className={classes.formControl}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Date to"
                name="toDate"
                disableFuture
                format={
                  DefaultDateFormat(props.companySettings.dateFormat).date
                }
                value={state.toDate}
                onChange={handleDateChange('toDate')}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <Button variant="contained" color="primary" onClick={handleSearch}>
            Search
          </Button>
          {!props.auditLoaded && (
            <CircularProgress
              color="secondary"
              className={classes.progress}
              size={30}
            />
          )}
        </div>
        <div className={classes.searchBar}>
          <FormControl className={classes.formControl}>
            <InputLabel shrink htmlFor="audit-type-label-placeholder">
              Filter
            </InputLabel>
            <Select
              value={state.filterType}
              onChange={handleFilterTypeChange}
              name="filterType"
            >
              <MenuItem value="byAuditType">By audit type</MenuItem>
              <MenuItem value="byUser">By user</MenuItem>
            </Select>
          </FormControl>
          {state.filterType === 'byAuditType' && (
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="audit-type-label-placeholder">
                Audit type
              </InputLabel>
              <Select
                value={selectedObjectType}
                onChange={handleSelectAuditType}
                name="selectedObjectType"
              >
                {auditObjectTypes.map(auditType => {
                  return (
                    <MenuItem key={auditType.value} value={auditType.value}>
                      {auditType.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
          {state.filterType === 'byUser' && (
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="audit-type-label-placeholder">
                User
              </InputLabel>
              <Select
                value={state.selectedUserId}
                onChange={handleSelectUserId}
                name="selectedUser"
              >
                <MenuItem value="" disabled>
                  Search to select a user
                </MenuItem>
                {state.userList.map(user => {
                  return (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </div>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Audit type</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Transaction Type</TableCell>
                <TableCell>User Name</TableCell>
                <TableCell>Transaction Date</TableCell>
                <TableCell>Detail</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {audits.map(audit => (
                <TableRow key={audit.id}>
                  <TableCell>{audit.objectType}</TableCell>
                  <TableCell>{audit.objectName}</TableCell>
                  <TableCell>
                    {audit.objectType === AuditObjectType.authentication &&
                    audit.transactionType === 'CREATE'
                      ? 'LOGIN'
                      : audit.transactionType}
                  </TableCell>
                  <TableCell>{audit.createUserName}</TableCell>
                  <TableCell>
                    {dateFormat(
                      audit.createUserTime,
                      DefaultDateFormat(props.companySettings.dateFormat)
                        .datetimeMoment,
                    )}
                  </TableCell>
                  <TableCell>
                    {hasDetailAudit(audit) && (
                      <IconButton
                        onClick={handleClickDetail(audit)}
                        color="primary"
                      >
                        <ViewIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <BasePagination
                  totalCount={state.totalCount}
                  onChangePage={handleChangePage}
                  rowsPerPage={state.rowsPerPage}
                  page={state.page}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
      {detailView.open && detailView.dialogType === 'INCIDENT_HISTORY' && (
        <IncidentHistoryViewDialog
          open={detailView.open}
          historyId={detailView.subjectId}
          onClose={handleCloseDetail}
          noCompare={detailView.transactionType === 'READ'}
        />
      )}
      {detailView.open && detailView.dialogType === 'GENERAL' && (
        <AuditDetailViewer
          open={detailView.open}
          auditData={detailView.subject}
          auditType={detailView.subjectType}
          onClose={handleCloseDetail}
        />
      )}
      {detailView.open && detailView.dialogType === 'SEARCH_CRITERIA' && (
        <AuditSearchViewer
          open={detailView.open}
          auditData={detailView.subject}
          auditType={detailView.subjectType}
          onClose={handleCloseDetail}
        />
      )}
      {detailView.open && detailView.dialogType === 'ANALYZE_CRITERIA' && (
        <AuditAnalyzeViewer
          open={detailView.open}
          auditData={detailView.subject}
          auditType={detailView.subjectType}
          onClose={handleCloseDetail}
          incidentLookups={props.lookupDataSet[LookupTypeKey.incidentClass]}
          accountDateFormat={props.companySettings.dateFormat}
        />
      )}
    </React.Fragment>
  );
};

AuditManagement.propTypes = {
  lookupDataSet: LookupDataSetDef.isRequired,
  storedAudits: PropTypes.arrayOf(AuditDef),
  queryAuditsByPeriod: PropTypes.func.isRequired,
};
AuditManagement.defaultProps = {};

const mapStateToProps = ({ common, administration }) => ({
  lookupDataSet: common.lookupDataSet,
  storedAudits: administration.audits.audits,
  auditLoaded: administration.audits.loaded,
  companySettings: common.companySettings,
});
const mapDispatchToProps = { queryAuditsByPeriod };

export default connect(mapStateToProps, mapDispatchToProps)(AuditManagement);
