import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import {
  FormControl,
  TextField,
  Container,
  Grid,
  Card,
  CardActionArea,
  CardHeader,
  CardContent,
  Button,
  FormHelperText,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  Box,
  LinearProgress,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Typography,
  Link,
} from '@material-ui/core';
import * as yup from 'yup';
import _ from 'lodash';

import HotelIcon from '@material-ui/icons/Hotel';
import EducationIcon from '@material-ui/icons/School';
import HospitalIcon from '@material-ui/icons/LocalHospital';
import GeneralBusinessIcon from '@material-ui/icons/Business';

import { profileSchema, passwordSchema } from '../../models/validateSchemas';
import {
  BusinessType,
  companyCountryCodes,
  InvalidSignupDomains,
} from '../../constants';
import { useForm } from '../../utils/hooks';
import AlertDialog from '../../components/dialogs/AlertDialog';

const useStyles = makeStyles(theme => ({
  '@global': {
    '.bizIcon': {
      fontSize: 48,
      color: '#999',
      '&.active': {
        color: theme.palette.primary.main,
      },
    },
  },
  lineItem: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  lastLineItem: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  typeHelper: {
    // marginLeft: theme.spacing(1),
    marginTop: 2,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardHeaderActive: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  selectedBusiness: {
    border: theme.palette.primary.main,
    borderWidth: '2px',
    borderStyle: 'solid',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bizDescription: {
    padding: theme.spacing(1),
  },
  typeSelectArea: {
    marginBottom: theme.spacing(2),
  },
  card: {
    width: '400px',
    // height: 32,
  },
  customLabel: {
    marginBottom: theme.spacing(1),
    position: 'relative',
  },
  circularProgress: {
    marginLeft: theme.spacing(1),
  },
  serviceAgreementLabel: {
    display: 'flex',
  },
  serviceAgreementLink: {
    marginLeft: theme.spacing(0.6),
    marginRight: theme.spacing(0.6),
  },
  button: {
    padding: theme.spacing(1),
  },
}));

const schema = yup.object().shape({
  ...profileSchema,
  email: yup
    .string()
    .email('Email is invalid')
    .test(
      'invalidDomain',
      'Please use company email, not personal email.',
      function(value) {
        return !InvalidSignupDomains.some(x => value.includes(x));
      },
    )
    .required('required'),
  ...passwordSchema,
  companyName: yup
    .string()
    .max(100, 'Company name length cannot be longer than 100.')
    .required('required'),
  phone: yup
    .string()
    .max(20, 'Phone length cannot be longer than 20.')
    .required('required'),
  businessType: yup
    .string()
    .oneOf(Object.values(BusinessType))
    .required('Business type is required'),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Password did not match')
    .required('Please enter the same password as above'),
  companyCountryCode: yup
    .string()
    .required('Please select the country in which your business is located'),
  serviceAgreementChecked: yup
    .mixed()
    .oneOf(
      [true],
      `If you don't agree Terms of Service and Privacy Policy, you can't continue to sign up for Incident Xpress.`,
    )
    .required(
      `If you don't agree Terms of Service and Privacy Policy, you can't continue to sign up for Incident Xpress.`,
    ),
  // .required(
  //   `If you don't agree Terms of Service and Privacy Policy, you can't continue to sign up for Incident Xpress.`,
  // ),
});

const businessTypes = [
  {
    key: BusinessType.general,
    icon: active => (
      <GeneralBusinessIcon className={classNames({ bizIcon: true, active })} />
    ),
    name: 'Corporate',
    // description: 'General corporate business',
  },
  {
    key: BusinessType.education,
    icon: active => (
      <EducationIcon className={classNames({ bizIcon: true, active })} />
    ),
    name: 'Education',
    // description: 'Education orgranization',
  },
  {
    key: BusinessType.hospital,
    icon: active => (
      <HospitalIcon className={classNames({ bizIcon: true, active })} />
    ),
    name: 'Healthcare',
    // description: 'Healthcare orgranization',
  },
  {
    key: BusinessType.hotel,
    icon: active => (
      <HotelIcon className={classNames({ bizIcon: true, active })} />
    ),
    name: 'Hospitality',
    // description: 'Hotel',
  },
];

const LoadingProgress = props => {
  return (
    <div display="flex" style={{ flexGrow: 1 }}>
      <LinearProgress color="secondary" />
    </div>
  );
};

const SignupForm = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    invalidPayment: false,
    paymentError: undefined,
    serviceAgreementOpen: false,
    privacyOpen: false,
  });
  const {
    formValues,
    updateFormValues,
    hasFormError,
    formHelpText,
    handleFormChange,
    handleFormBlur,
    handleFormSubmit,
  } = useForm({
    // signup with credit card
    // onSubmit(values, errors) {
    //   props.stripe
    //     .createToken()
    //     .then(({ token }) => {
    //       if (token) {
    //         if (_.isEmpty(errors)) {
    //           setState({
    //             ...state,
    //             invalidPayment: false,
    //             paymentError: undefined,
    //           });
    //           props.onSave({ ...values, paymentToken: token.id });
    //         }
    //       } else {
    //         setState({
    //           ...state,
    //           invalidPayment: true,
    //           paymentError: 'required',
    //         });
    //       }
    //     })
    //     .catch(err => {
    //       setState({
    //         ...state,
    //         paymentError: err.message,
    //         invalidPayment: true,
    //       });
    //     });
    // },

    // signup without credit card
    onSubmit(values, errors) {
      if (_.isEmpty(errors)) {
        props.onSave({ ...values });
      }
    },
    validationSchema: schema,
  });

  React.useEffect(() => {
    updateFormValues({});
  }, []);

  const handleSelectBusinessType = biz => {
    updateFormValues({ businessType: biz.key }, true);
  };

  const handleServiceAgreementChanged = checked => {
    updateFormValues({ serviceAgreementChecked: checked }, true);
  };

  const handleSignup = () => {
    handleFormSubmit();
  };
  const handleCardChange = ({ error }) => {
    if (error) {
      setState({ ...state, paymentError: error.message, invalidPayment: true });
    } else {
      setState({ ...state, paymentError: undefined, invalidPayment: false });
    }
  };

  return (
    <div>
      <div className={classes.lineItem}>
        <FormControl className={classes.formControlLong}>
          <TextField
            label="Company Email (User ID)"
            margin="none"
            type="eamil"
            name="email"
            value={formValues.email || ''}
            onChange={handleFormChange}
            onBlur={handleFormBlur}
            InputLabelProps={{
              shrink: true,
            }}
            error={hasFormError('email')}
            helperText={formHelpText('email')}
            inputProps={{ autoComplete: 0 }}
            required
          />
        </FormControl>
      </div>
      <div className={classes.lineItem}>
        <FormControl className={classes.formControlLong}>
          <TextField
            label="Password"
            margin="none"
            type="password"
            name="password"
            value={formValues.password || ''}
            onChange={handleFormChange}
            onBlur={handleFormBlur}
            InputLabelProps={{
              shrink: true,
            }}
            error={hasFormError('password')}
            helperText={formHelpText('password')}
            inputProps={{ autoComplete: 0 }}
            required
          />
        </FormControl>
        <FormControl className={classes.formControlLong}>
          <TextField
            label="Password confirm"
            margin="none"
            type="password"
            name="passwordConfirm"
            value={formValues.passwordConfirm || ''}
            onChange={handleFormChange}
            onBlur={handleFormBlur}
            InputLabelProps={{
              shrink: true,
            }}
            error={hasFormError('passwordConfirm')}
            helperText={formHelpText('passwordConfirm')}
            inputProps={{ autoComplete: 0 }}
            required
          />
        </FormControl>
      </div>
      <div className={classes.lineItem}>
        <FormControl className={classes.formControl}>
          <TextField
            label="First name"
            margin="none"
            name="firstName"
            value={formValues.firstName || ''}
            onChange={handleFormChange}
            onBlur={handleFormBlur}
            InputLabelProps={{
              shrink: true,
            }}
            error={hasFormError('firstName')}
            helperText={formHelpText('firstName')}
            inputProps={{ autoComplete: 0 }}
            required
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Last name"
            margin="none"
            name="lastName"
            value={formValues.lastName || ''}
            onChange={handleFormChange}
            onBlur={handleFormBlur}
            InputLabelProps={{
              shrink: true,
            }}
            error={hasFormError('lastName')}
            helperText={formHelpText('lastName')}
            inputProps={{ autoComplete: 0 }}
            required
          />
        </FormControl>
      </div>
      <div className={classes.lineItem}>
        <FormControl className={classes.formControlLong}>
          <TextField
            label="Company name"
            margin="none"
            name="companyName"
            value={formValues.companyName || ''}
            onChange={handleFormChange}
            onBlur={handleFormBlur}
            InputLabelProps={{
              shrink: true,
            }}
            error={hasFormError('companyName')}
            helperText={formHelpText('companyName')}
            inputProps={{ autoComplete: 0 }}
            required
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Phone"
            margin="none"
            name="phone"
            value={formValues.phone || ''}
            onChange={handleFormChange}
            onBlur={handleFormBlur}
            InputLabelProps={{
              shrink: true,
            }}
            error={hasFormError('phone')}
            helperText={formHelpText('phone')}
            inputProps={{ autoComplete: 0, maxLength: 20 }}
            required
            placeholder="ex) 999-999-9999"
          />
        </FormControl>
        {/* <FormControl
          className={classes.formControl}
          error={hasFormError('companyCountryCode')}
        >
          <InputLabel shrink htmlFor="measurement-type-label-placeholder">
            Company country {hasFormError('companyCountryCode')}
          </InputLabel>
          <Select
            displayEmpty
            name="companyCountryCode"
            value={formValues.companyCountryCode}
            onChange={handleFormChange}
            className={classes.selectEmpty}
          >
            {companyCountryCodes.map(type => (
              <MenuItem key={type.code} value={type.code}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{formHelpText('companyName')}</FormHelperText>
        </FormControl> */}
        <FormControl className={classes.formControlLong}>
          <TextField
            label="Country"
            select
            margin="none"
            name="companyCountryCode"
            value={formValues.companyCountryCode || ''}
            onChange={handleFormChange}
            onBlur={handleFormBlur}
            error={hasFormError('companyCountryCode')}
            helperText={formHelpText('companyCountryCode')}
            required
          >
            {companyCountryCodes.map(type => (
              <MenuItem key={type.code} value={type.code}>
                {type.name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </div>

      <div className={classes.lineItem}>
        <FormControl className={classes.formControlFull}>
          <div className={classes.lineItem}>
            <FormLabel
              className={classNames(
                'MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink',
                classes.customLabel,
              )}
              required
              error={hasFormError('businessType')}
            >
              Business type
            </FormLabel>
            <FormHelperText
              error={hasFormError('businessType')}
              className={classes.typeHelper}
            >
              {formHelpText('businessType')}
            </FormHelperText>
          </div>
          <Container
            maxWidth="md"
            component="main"
            className={classes.typeSelectArea}
          >
            <Grid container spacing={5} alignItems="flex-end">
              {businessTypes.map(biz => (
                // Enterprise card is full width at sm breakpoint
                <Grid item key={biz.key} xs={6} sm={3}>
                  <Card
                    className={classNames({
                      [classes.selectedBusiness]:
                        formValues.businessType === biz.key,
                    })}
                    elevation={formValues.businessType === biz.key ? 10 : 1}
                  >
                    <CardActionArea
                      onClick={() => {
                        handleSelectBusinessType(biz);
                      }}
                    >
                      <CardHeader
                        title={biz.name}
                        titleTypographyProps={{
                          align: 'center',
                        }}
                        className={
                          formValues.businessType === biz.key
                            ? classes.cardHeaderActive
                            : classes.cardHeader
                        }
                      />
                      <CardContent className={classes.cardContent}>
                        <div>
                          {biz.icon(biz.key === formValues.businessType)}
                        </div>
                        <div className={classes.bizDescription}>
                          {biz.description}
                        </div>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </FormControl>
      </div>
      <div className={classes.lastLineItem}>
        <FormControl required>
          <FormControlLabel
            classes={{
              label: classes.serviceAgreementLabel,
            }}
            control={
              <Checkbox
                checked={formValues.serviceAgreementChecked}
                onChange={e => {
                  handleServiceAgreementChanged(e.target.checked);
                }}
                name="serviceAgreementChecked"
                color="primary"
              />
            }
            label={
              <>
                <Typography variant="body2">
                  I agree to the Incident Xpress
                  <Link
                    className={classes.serviceAgreementLink}
                    href="#"
                    onClick={e => {
                      setState({
                        ...state,
                        serviceAgreementOpen: true,
                      });
                    }}
                  >
                    Terms of Service
                  </Link>
                  and
                  <Link
                    className={classes.serviceAgreementLink}
                    href="#"
                    onClick={e => {
                      setState({
                        ...state,
                        privacyOpen: true,
                      });
                    }}
                    color="primary"
                  >
                    Privacy Policy
                  </Link>
                </Typography>
              </>
            }
          />
          <FormHelperText error={hasFormError('serviceAgreementChecked')}>
            {formHelpText('serviceAgreementChecked')}
          </FormHelperText>
        </FormControl>
      </div>
      <div className={classes.button}>
        <Button
          onClick={handleSignup}
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          disabled={props.loading}
          id="free-trial-form-submit"
          className="free-trial-form-submit"
        >
          Sign up
          {props.loading && (
            <CircularProgress
              color="secondary"
              className={classes.circularProgress}
              size={20}
            />
          )}
        </Button>
      </div>
      <AlertDialog
        title="Privacy Policy"
        maxWidth="xl"
        cancelButtonHidden
        message={
          <div className={classes.serviceAgreementContent}>
            <h4>Last updated on September 19, 2020</h4>
            <p>
              Incident Xpress Inc. (“Incident Xpress”, “iX”, “we”, or “us”)
              makes available its Incident Xpress incident reporting software as
              a service via Microsoft Azure Cloud Services which allows you to
              automate your incident reporting processes.
            </p>
            <p>
              This Privacy Policy applies to www.incidentxpress.com (the “Site”)
              which is owned and operated by Incident Xpress Inc. This explains
              how we collect, use, and disclose personally identifying
              information about individuals (“Personal Information”) that is
              gathered through our Sites and Services.
            </p>
            <p>
              By using our Site and Services, you agree to this Privacy Policy,
              which is subject to and incorporates by reference our Software
              License Agreement Terms of Use, including our limitation on
              liability. To review the Terms of Use, go to{' '}
              <a href="http://www.incidentxpress.com">
                http://www.incidentxpress.com
              </a>
              .
            </p>

            <h4>This Privacy Policy does not apply to:</h4>
            <p>
              <ul>
                <li>
                  (i) the collection, use or disclosure of Personal Information
                  about persons by our Customers, or
                </li>
                <li>
                  (ii) visitors to our Customers’ websites. We have no control
                  over our Customer’s information privacy practices. It is
                  recommended that you check the Privacy Policy of any website
                  you use for information about how that site collects, uses and
                  discloses Personal Information.
                </li>
              </ul>
            </p>
            <p>
              <b>Note:</b> Incident Xpress Inc. uses STRIPE™ for processing our
              online payments. By completing and submitting the
              subscription/sign up form, you agree and acknowledge that you have
              read and agree to Stripe’s Privacy Policy. Their Privacy Policy is
              accessible by visiting{' '}
              <a href="https://stripe.com/en-ca/privacy">
                https://stripe.com/en-ca/privacy
              </a>
              .
            </p>

            <h4>Changes to this Privacy Policy</h4>

            <p>
              As we update our Site and Services and roll-out new versions or
              features, we may amend this Privacy Policy. You should check{' '}
              <a href="https://www.incidentxpress.com/privacy-policy/">
                http://www.incidentxpress.com
              </a>{' '}
              frequently to review our current Privacy Policy, which is
              effective as of the publication date. Your continued use of any of
              our websites and services constitutes your deemed acceptance and
              understanding of the Privacy Policy in effect at the time of your
              continued use.
            </p>

            <h4>Personal Information we Collect and Receive</h4>
            <h5>About Visitors to our Site</h5>
            <p>
              We automatically collect through our Site and Services,
              information that is often not personally identifiable, such as the
              website from which visitors came to our website, IP address,
              browser type, domain name, referring URL, page views and
              information relating to the device through which users access the
              website. We may combine this information with the Personal
              Information we have collected about Customers.
            </p>

            <h5>About Our Customers</h5>
            <p>
              <u>Account Information.</u> Customers wishing to subscribe for a
              paid or free trial subscription must create an account and provide
              their contact information – first and last name, business name,
              email address, phone number and country the business is in.
            </p>
            <p>
              <u>Payment Information.</u> When you subscribe to our Services,
              you acknowledge that billing information including your billing
              address, credit card number, expiration date, and other billing
              information necessary to process the transaction is collected. We
              use this information to process your transaction.
            </p>
            <p>
              Please note that credit card details are submitted directly to
              STRIPE™, the online payment processing company Incident Xpress
              Inc. uses. Incident Xpress Inc. never has access to your credit
              card details. This information is collected, retained, and managed
              by STRIPE™ on behalf of their organization and as such is subject
              to their Privacy Policy. You are encouraged to visit{' '}
              <a href="https://stripe.com/en-ca/privacy">
                https://stripe.com/en-ca/privacy
              </a>{' '}
              to view their privacy policy.
            </p>
            <p>
              <u>History and Usage.</u> We also collect information about our
              Customers’ use of the Services, including their order history,
              services usage, and other similar information.
            </p>
            <p>
              <u>Other Information.</u> We may ask Customers to submit certain
              optional information about their business, such as their industry
              or demographics. We may also collect or receive information from
              third parties (e.g. LinkedIn.com), and public records and sources.
              We may combine such information with the Personal Information we
              have collected about Customer contacts.
            </p>
            <p>
              A customer who discloses personal information to Incident Xpress
              Inc. warrants that it has read, understands and agrees to this
              Privacy Policy and warrants that it has obtained the consent of
              the relevant persons to such collection, use and disclosure of
              personal information as described in this Privacy Policy.
            </p>
            <p>
              Notwithstanding anything else in this Privacy Policy, we may also
              use aggregate or non-personally identifying information about
              Customers or Persons for market analysis, research, marketing, or
              other purposes. We may also identify your company name and/or logo
              on client lists, marketing materials, and/or press releases. If
              you wish to be excluded from this program, do so by emailing:{' '}
              <a href="mailto:privacy@incidentxpress.com">
                privacy@incidentxpress.com
              </a>
              .
            </p>
            <h4>How We Use Personal Information</h4>
            <p>
              We use the Personal Information we collect online for the
              following purposes, to:
            </p>
            <ul>
              <li>Provide our products and Services to Customers.</li>
              <li>
                Grant access to our Services; process payments and orders.
              </li>
              <li>Respond to requests or inquiries from Customers.</li>
              <li>
                Communicate with Customers about their accounts or orders, and
                for similar customer-service-related purposes.
              </li>
              <li>
                Effectuate or enforce a transaction or agreement with a
                Customer.
              </li>
              <li>Tailor the information we send or display to Customers.</li>
              <li>Send marketing and promotional e-mails to our Customers.</li>
              <li>Improve our Site, offerings, or services. And,</li>
              <li>
                Better understand how users’ access and use our Site and
                offerings, on an aggregated and individualized basis.
              </li>
            </ul>

            <h4>How We Disclose Personal Information</h4>
            <p>
              We may disclose any of the Personal Information we collect or
              receive through our Site and Services as follows, to:
            </p>
            <ul>
              <li>
                Contractors or service providers who provide services or perform
                functions on our behalf.
              </li>
              <li>
                Respond to a subpoena or other legal process by a governmental
                entity or third party, or if otherwise required by law.
              </li>
              <li>
                Protect or enforce our rights or property, including as evidence
                in litigation in which we are involved.
              </li>
              <li>
                Others in the event of the sale or dissolution (bankruptcy) of
                assets, in whole or in part, of Incident Xpress Inc. or any of
                its affiliates. And,
              </li>
              <li>Comply with purposes consistent with this Privacy Policy.</li>
            </ul>
            <p>
              Notwithstanding anything else in this Privacy Policy, our
              Customers may access, view, and download any personal information
              belonging to them, and we have no control over their collection,
              use or disclosure of this information.
            </p>
            <p>
              We will not disclose any personal information collected to any
              third parties for such third parties’ own marketing purposes
              unless we first receive your consent. However, we may share
              aggregate or non-personally identifying information with third
              parties for marketing, advertising, research, or other purposes.
            </p>

            <h4>Cookies and Analytics</h4>
            <p>
              When you visit our website, we strive to make your experience easy
              and meaningful. We, or our third-party service providers, may use
              cookies, web beacons (clear GIFs, web bugs) and similar
              technologies to track site visitor activity and collect site data.
              We may combine this data with the Personal Information we have
              collected from Customers.
            </p>
            <p>
              <u>Cookies.</u> We (or our third-party service providers) use
              cookies to track visitor activity on the Site. A cookie is a text
              file that a website transfers to your computer’s hard drive for
              record-keeping purposes. Our cookies assign a random, unique
              number to each visitor’s computer. They do not contain information
              that would personally identify the visitor, although we can
              associate a cookie with any identifying information that has been
              provided by a Customer while visiting our Site. We, or our
              third-party service providers, use cookies that remain on your
              computer for a specified period or until they are deleted
              (persistent cookies). These cookies record clickstream information
              (data reporting the URLs, or names of the pages, on our website
              that have been visited). We may also use cookies that exist only
              temporarily during an online session (session cookies). These
              cookies allow you to log in to your account and they allow us to
              identify you temporarily as you move through the website. Some
              browsers allow users to refuse cookies but doing so may impede the
              functionality of some portions of our Site.
            </p>

            <p>
              <u>Web Beacons.</u> Web beacons are tiny graphics with a unique
              identifier, similar in function to cookies, that are used to track
              the online movements of Web users. In contrast to cookies, which
              are stored on your computer’s hard drive, Web beacons are embedded
              invisibly on webpages and may not be disabled or controlled
              through your browser.
            </p>
            <p>
              Incident Xpress Inc. may engage third parties to track and analyze
              site activity on our behalf. To do so, these third parties may
              place cookies or web beacons to track user activity on our Site.
              We use the data collected by such third parties to help us
              administer and improve the quality of the Site and to analyze
              usage of the Site.
            </p>

            <h4>Third-Party Links</h4>
            <p>
              Our website may contain links to other third-party websites. Any
              access to, and use of such linked websites, is not governed by
              this Privacy Policy, but, instead, is governed by the privacy
              policies of those third-party websites. We are not responsible for
              the information practices of such third-party websites.
            </p>
            <h4>Marketing Communications</h4>
            <p>
              You may receive emails from us about information that we believe
              may be of interest to you as well as news and offers from us or
              other related third parties, which may include our newsletter or
              information about special offers, products or offerings, or other
              items.
            </p>
            <p>
              If, at any time, you would like to stop receiving these
              promotional e-mails, you may follow the opt-out instructions
              contained in any such e-mail. If you opt-out of receiving emails
              or promotions from us, we still may send you e-mail about your
              account, any Services you have requested or received from us, or
              for other customer-service purposes.
            </p>
            <h4>Security</h4>
            <p>
              We use commercially reasonable physical, technical, and
              administrative measures to protect the security of the Personal
              Information that we collect or receive from loss, misuse, and
              unauthorized access, disclosure, alteration, and destruction.
            </p>
            <h4>Access to Personal Information</h4>
            <p>
              Incident Xpress Inc will cooperate with anyone requesting
              information about their personal data that is stored by IXI.
              However, access may be denied where Incident Xpress Inc. is
              required by law to deny access to such information.
            </p>
            <h4>How to Contact Us</h4>
            <p>
              Questions? You may contact us with any questions or concerns about
              this Privacy Policy at&nbsp;
              <a href="mailto:privacy@autopilothq.com">
                privacy@incidentxpress.com
              </a>
              .
            </p>
          </div>
        }
        open={state.privacyOpen}
        onClose={() => {
          setState({
            ...state,
            privacyOpen: false,
          });
        }}
        onOk={() => {
          setState({
            ...state,
            privacyOpen: false,
          });
        }}
      />

      <AlertDialog
        title="INCIDENT XPRESS INC., SOFTWARE LICENSE AGREEMENT"
        maxWidth="xl"
        cancelButtonHidden
        message={
          <div className={classes.serviceAgreementContent}>
            <p>
              <strong>Last updated on September 17, 2020</strong>
            </p>
            <p>
              These Terms of Service, (the “Terms”), create an agreement between
              the company, organization, legal entity or legal person listed on
              the Subscription Order Form (“Licensee”, “Customer”, “You” or
              “Your” and terms of similar meaning) and Incident Xpress Inc.
              (“Incident Xpress”, “Licensor”, “We”, “Our”, or “Us” and terms of
              similar meaning) regarding Your access to and use of any Incident
              Xpress software (“Software”) and receipt of consulting, training,
              professional and/or other services (“Services”). All Subscribers
              must agree to these Terms before using the Software and Services.
            </p>
            <p>
              If You are an individual agreeing to these Terms, You agree that
              these Terms apply to You as a Licensee or User; additionally, if
              You are agreeing to these Terms on behalf of a company,
              organization, other legal entity or legal person, You represent
              that You have the authority to bind that entity and its
              affiliates, and its respective employees, agents, delegates,
              representatives and any other individuals that have been
              authorized by You to access and use the Software and receive the
              Services (“Users”), to these Terms. If You do not agree with the
              Terms or if You do not have such authority, you must not submit a
              Subscription Order Form (as applicable) and Customer and Users
              will not be permitted to access, receive and use the Software and
              Services.
            </p>
            <p>&nbsp;</p>
            <h4>GENERAL This License:</h4>
            <ol>
              <li>
                Is an agreement between Incident Xpress Inc. (Licensor) and the
                Subscriber (Licensee) who is being licensed to use the Incident
                Xpress Software.
              </li>
              <li>
                Allows Licensee to access the software stored on Microsoft Azure
                Cloud Services servers.
              </li>
              <li>
                Agreement is the complete agreement between Licensor and
                Licensee in respect to the Software.
              </li>
              <li>
                Replaces and supersedes all prior negotiations, dealings, and
                agreements whether verbal or in writing between Licensor and
                Licensee in respect to this Software.
              </li>
              <li>
                Is governed by the laws of the province of Alberta, Canada.
              </li>
              <li>
                Is valid without the signature of the Licensor or Licensee
                immediately upon Licensee first accessing the Software.
              </li>
              <li>
                Confirms that Incident Xpress is only authorized for sale in the
                USA and Canada currently.
              </li>
              <li>May be amended by Licensor from time to time.</li>
            </ol>
            <p>&nbsp;</p>
            <h4>LICENSOR warrants to Licensee that:</h4>
            <ol>
              <li>
                The Software does not infringe on any third party&#8217;s
                intellectual property rights.
                <br /> 2. The Licensor will never, without the written
                permission of Licensee, access their data stored in Microsoft
                Azure Cloud.
                <br /> 3. The Licensor will never access the Licensee’s credit
                card details.
                <br /> 4. Licensor will maintain a comprehensive information
                security program to ensure the confidentiality, integrity and
                availability of the Customer Data that You upload into the
                Software and protect such Customer Data from unauthorized use,
                access, modification, disclosure or destruction in accordance
                with industry best practices.
                <br /> 5. Licensor will not be responsible for any compromise,
                loss, delay, alteration, or interception of Customer Data during
                the transmission of any data whatsoever across the Internet or
                mobile telecommunications networks.
                <br /> 6. Licensor will respond to technical support issues
                within 24 hours Monday through Friday from 9 AM to 5 PM Mountain
                time.
                <br /> 7. Licensor will use reasonable efforts to ensure online
                access to Incident Xpress available 99.9% of the time except
                during scheduled maintenance, or any unavailability caused by
                Force Majeure.
              </li>
            </ol>
            <p>&nbsp;</p>
            <h4>LICENSEE understands that:</h4>
            <ol>
              <li>
                The software is the proprietary and copyrighted property of the
                Licensor and that the Licensor will remain the sole owner of all
                titles, rights, and interests in the Software.
              </li>
              <li>
                Licensee transfers ownership of any suggestions, comments and
                ideas for product improvements shared with Licensor by Licensee
                to the Licensor.
              </li>
              <li>
                This License Agreement is valid without Licensor&#8217;s or
                Licensee’s signature.
              </li>
              <li>
                The effective date of this Agreement is the date the Licensee
                first accesses the Software.
                <br /> 5. Licensee will pay all fees as set out in subscription
                order. Fees for Software are payable regardless of Your actual
                usage of the Software.
                <br /> 6. That Licensee is prohibited from using the Software
                for the benefit of third parties whether for free or for a fee.
                <br /> 7. There is no warranty of fitness for a particular
                purpose.
                <br /> 8. The Licensee indemnifies and saves harmless the
                Licensor in respect to Licensee’s use of the product in a way
                that infringes on any country, state, or provincial legislation.
                <br /> 9. Licensee will comply with applicable privacy and/or
                data protection laws in relation to the collection, use and
                disclosure of any personally identifiable information or
                personal data (“PII”) that You upload including customer data,
                and will be responsible for the accuracy, quality integrity and
                legality of any content related to Your data.
                <br /> 10. Licensee will be responsible for protecting and
                securely managing account/user names, passwords and other login
                information to prevent unauthorized access and to promptly
                notify Licensor in event of unauthorized access to software.
                <br /> 11. The Licensee agrees to defend and indemnify Licensor
                and hold Licensor harmless from all claims, losses, damages,
                complaints, or expenses connected with, or resulting from,
                Licensee&#8217;s use of the Software.
                <br /> 12. The Licensor has the right to terminate this License
                Agreement should Licensee breach the terms of This License.
              </li>
            </ol>
            <p>&nbsp;</p>
            <h4>CANCELLATION Licensee understands that:</h4>
            <ol>
              <li>
                The Licensee can cancel their subscription at any time effective
                the expiry date of their current term.
                <br /> 2. Upon Termination, Licensee will pay all fees owing to
                Licensor and Licensor will not refund any fees You may have
                already paid to us.
                <br /> 3. Their data will be retained on Microsoft Azure Cloud
                Services servers for a period of six (6) months following the
                effective expiry date unless Licensor is specifically requested
                in writing by the Licensee to delete their data earlier.
                <br /> 4. The Licensor has the right to terminate this License
                Agreement should Licensee breach the terms of This License.
                <br /> 5. Licensor may update these Terms from time to time. If
                You do not agree with the changes, You can cancel the Services,
                stop using the Software without further obligation, except for
                the payment of amount due for any outstanding Services and
                terminate these Terms. Any changes or modifications to these
                Terms will be effective immediately upon posting of the
                revisions on the Incident Xpress Website.
              </li>
            </ol>
            <p>&nbsp;</p>
            <h4>NOTICES:</h4>
            <p>
              Licensee may submit any notifications in respect to this agreement
              to:
            </p>
            <p>
              ATTENTION: Denis O’Sullivan
              <br /> 14315 99 Avenue NW
              <br /> Edmonton, Alberta
              <br /> Canada T5N 0H3
            </p>
            <p>Or, via email to:</p>
            <p>
              <a href="mailto:denis@incidentxpress.com">
                denis@incidentxpress.com
              </a>
            </p>
            <p>With a copy to:</p>
            <p>
              <a href="mailto:cora@incidentxpress.com">
                cora@incidentxpress.com
              </a>
            </p>
            <p>&nbsp;</p>
            <p>
              Notwithstanding the foregoing, LICENSOR IS NOT LIABLE TO LICENSEE
              FOR ANY DAMAGES, INCLUDING COMPENSATORY, SPECIAL, INCIDENTAL,
              EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, CONNECTED WITH OR
              RESULTING FROM THIS LICENSE AGREEMENT OR LICENSEE&#8217;S USE OF
              THIS SOFTWARE.
            </p>
          </div>
        }
        open={state.serviceAgreementOpen}
        onClose={() => {
          setState({
            ...state,
            serviceAgreementOpen: false,
          });
        }}
        onOk={() => {
          setState({
            ...state,
            serviceAgreementOpen: false,
          });
        }}
      />
    </div>
  );
};

SignupForm.propTypes = {
  // stripe: PropTypes.object,
  onSave: PropTypes.func,
  loading: PropTypes.bool,
};
SignupForm.defaultProps = {
  onSave: f => f,
};

export default SignupForm;

// export default injectStripe(SignupForm);
