import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import userApi from '../apis/user.api';

const PROFILE_LOADED = 'user/PROFILE_LOADED';
const PROFILE_LOAD_FAILED = 'user/PROFILE_LOADED_FAILED';
const PROFILE_UPDATED = 'user/PROFILE_UPDATED';
const PROFILE_UPDATE_FAILED = 'user/PROFILE_UPDATE_FAILED';

export const profileLoaded = createAction(PROFILE_LOADED);
export const profileLoadFailed = createAction(PROFILE_LOAD_FAILED);
export const profileUpdated = createAction(PROFILE_UPDATED);
export const profileUpdateFailed = createAction(PROFILE_UPDATE_FAILED);

export const loadProfile = () => {
  return dispatch =>
    userApi
      .getProfile()
      .then(res => {
        dispatch(profileLoaded(res.data));
        // dispatch({
        //   type: PROFILE_LOADED,
        //   payload: res.data,
        // });
      })
      .catch(err => {
        dispatch(profileLoadFailed(err));
      });
};
export const updateProfile = profile => {
  return dispatch =>
    userApi
      .updateProfile(profile)
      .then(res => {
        dispatch(profileUpdated(profile));
      })
      .catch(err => {
        dispatch(profileUpdateFailed(err));
      });
};

const initState = {
  profileLoaded: false,
  profileUpdated: false,
  profile: undefined,
};

export default handleActions(
  {
    [PROFILE_LOADED]: (state, action) =>
      produce(state, draft => {
        draft.profileLoaded = true;
        draft.profile = action.payload;
      }),
    [PROFILE_UPDATED]: (state, action) =>
      produce(state, draft => {
        draft.profileUpdated = true;
        draft.profile = action.payload;
      }),
  },
  initState,
);
