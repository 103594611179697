import React from 'react';
import DefaultLayout from '../DefaultLayout';
import DraftIncidentFormWrapper from './DraftIncidentFormWrapper';

const NewIncident = props => {
  return (
    <DefaultLayout>
      <DraftIncidentFormWrapper />
    </DefaultLayout>
  );
};

export default NewIncident;
