import BaseApi from './BaseApi';

class UserGroupApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl += '/userGroups';
  }

  async query() {
    return await this.get(`${this.baseUrl}`);
  }

  async add(model) {
    return await this.post(`${this.baseUrl}`, model);
  }

  async update(model) {
    return await this.put(`${this.baseUrl}`, model);
  }

  async remove(id) {
    return await this.delete(`${this.baseUrl}/${id}`);
  }

  async setProperty(id, property) {
    return await this.put(`${this.baseUrl}/${id}/properties`, property);
  }

  async setPrivilege(id, name, active) {
    return active
      ? await this.post(`${this.baseUrl}/${id}/privileges/${name}`, {})
      : await this.delete(`${this.baseUrl}/${id}/privileges/${name}`);
  }
}

export default new UserGroupApi();
