import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import uuid from 'uuid/v4';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Typography,
  Paper,
  LinearProgress,
  FormControl,
  TextField,
  Button,
  InputLabel,
  Select,
  Card,
  Chip,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import _ from 'lodash';
import * as yup from 'yup';
import {
  getIncidentNotifications,
  createIncidentNotification,
  updateIncidentNotification,
  deleteIncidentNotification,
} from './ducks';
import { MeasurementType, LookupTypeKey, OrTooltip } from '../../../constants';
import { useForm } from '../../../utils/hooks';
import { enqueueError } from '../../../modules/global';
import { buildLookupPath } from '../../../utils/lookupTreeUtil';
import { LookupDataSetDef } from '../../../components/propTypeDefs';
import AlertDialog from '../../../components/dialogs/AlertDialogYesNo';
import NotificationAddDlg from './NotificationAddDlg';
import { getInitData } from '../../../modules/common';

const styles = theme => ({
  notificationRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    color: theme.palette.text.secondary,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  buttonContainer: {
    margin: theme.spacing(1),
    color: theme.palette.error.main,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  addUserDialog: {
    width: '80%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  linearProgressSpace: {
    height: '4px',
  },
  chip: {
    margin: theme.spacing(1),
  },
  notificationCard: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    maxWidth: '500px',
  },
  notificationTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const NotificationManagement = withStyles(styles)(props => {
  const { loaded, classes, actions, incidentNotifications } = props;

  React.useEffect(() => {
    actions.getInitData();
    actions.getIncidentNotifications();
  }, []);

  const [deleteNotificationInfo, setDeleteNotificationInfo] = React.useState({
    open: false,
    notification: undefined,
  });

  // const [addNotificationDlgOpen, setAddNotificationDlgOpen] = React.useState(
  //   false,
  // );

  const [notificationDlgInfo, setNotificationDlgInfo] = React.useState({
    open: false,
    notificationItem: undefined,
    isEdit: false,
  });

  const handleAddNotification = notificationItem => {
    actions
      .createIncidentNotification(notificationItem)
      .then(() => {
        setNotificationDlgInfo({
          open: false,
          notificationItem: undefined,
        });
      })
      .catch(() => {
        setNotificationDlgInfo({
          open: false,
          notificationItem: undefined,
        });
      });
  };
  const handleEditNotification = notificationItem => {
    actions
      .updateIncidentNotification(notificationItem)
      .then(() => {
        setNotificationDlgInfo({
          open: false,
          notificationItem: undefined,
        });
      })
      .catch(() => {
        setNotificationDlgInfo({
          open: false,
          notificationItem: undefined,
        });
      });
  };
  const handleDeleteNotification = () => {
    actions
      .deleteIncidentNotification(deleteNotificationInfo.notification.id)
      .then(() => {
        setDeleteNotificationInfo({ open: false, notification: undefined });
      })
      .catch(() => {
        setDeleteNotificationInfo({ open: false, notification: undefined });
      });
  };

  const editNotification = notification => {
    setNotificationDlgInfo({
      open: true,
      notificationItem: notification,
      isEdit: true,
    });
  };

  const getEventTypeList = csvEventTypeList => {
    return csvEventTypeList.split(',').join(', ');
  };

  return (
    <div className={classes.notificationRoot}>
      <div className={classes.paper}>
        {!loaded ? (
          <LinearProgress />
        ) : (
          <div className={classes.linearProgressSpace} />
        )}
        {incidentNotifications.map(notification => {
          return (
            <Card
              className={classes.notificationCard}
              hidden={notification.lookupClasses.length === 0}
              key={notification.id}
            >
              <div>
                <div className={classes.notificationTitle}>
                  <Typography variant="h6">{notification.name} </Typography>
                  <div>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        editNotification(notification);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setDeleteNotificationInfo({
                          open: true,
                          notification,
                        });
                      }}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </div>
                </div>
                <div>
                  <Typography variant="body2">{`Email: ${notification.recipientEmails.join(
                    ', ',
                  )}`}</Typography>
                </div>

                {notification.lookupClasses.map((classLookup, index) => (
                  <div key={classLookup.classLookupId}>
                    <Chip
                      label={`${buildLookupPath(
                        props.lookupDataSet[LookupTypeKey.incidentClass],
                        classLookup.classLookupId,
                      )} [${getEventTypeList(classLookup.eventTypeList)}]`}
                      className={classes.chip}
                      color="secondary"
                    />
                    {index < notification.lookupClasses.length - 1 && (
                      <Tooltip title={OrTooltip}>
                        <Chip label="OR" className={classes.chip} />
                      </Tooltip>
                    )}
                  </div>
                ))}
              </div>
            </Card>
          );
        })}
      </div>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setNotificationDlgInfo({
              open: true,
              notificationItem: {
                id: uuid(),
                name: 'New notification',
                lookupClasses: [
                  {
                    classLookupId: undefined,
                    eventTypeList: 'CREATE',
                  },
                ],
                recipientEmails: [],
              },
            });
          }}
        >
          Add
        </Button>
      </div>
      {deleteNotificationInfo.open && (
        <AlertDialog
          title="DELETE NOTIFICATION"
          message={
            <div className={classes.alertTextBold}>
              Are you sure you want to delete{' '}
              <b>{deleteNotificationInfo.notification.name}</b> notification?
            </div>
          }
          open={deleteNotificationInfo.open}
          onClose={() => {
            setDeleteNotificationInfo({ open: false, notification: undefined });
          }}
          onNo={() => {
            setDeleteNotificationInfo({ open: false, notification: undefined });
          }}
          onYes={() => {
            handleDeleteNotification();
          }}
        />
      )}
      {notificationDlgInfo.open && (
        <NotificationAddDlg
          open={notificationDlgInfo.open}
          onClose={() => {
            setNotificationDlgInfo({
              open: false,
              notificationItem: undefined,
            });
          }}
          onCancel={() => {
            setNotificationDlgInfo({
              open: false,
              notificationItem: undefined,
            });
          }}
          onAdd={notificationItem => {
            handleAddNotification(notificationItem);
          }}
          onEdit={notificationItem => {
            handleEditNotification(notificationItem);
          }}
          isEdit={notificationDlgInfo.isEdit}
          notificationItem={notificationDlgInfo.notificationItem}
        />
      )}
    </div>
  );
});

NotificationManagement.propTypes = {
  lookupDataSet: LookupDataSetDef.isRequired,
};

NotificationManagement.defaultProps = {
  onIncidentClassAdded: () => {},
};

function mapStateToProps({ administration, common }) {
  return {
    loaded: administration.notifications.loaded,
    incidentNotifications: administration.notifications.incidentNotifications,
    lookupDataSet: common.lookupDataSet,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = bindActionCreators(
    {
      getIncidentNotifications,
      createIncidentNotification,
      updateIncidentNotification,
      deleteIncidentNotification,
      enqueueError,
      getInitData,
    },
    dispatch,
  );
  return { actions, dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationManagement);
