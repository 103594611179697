import BaseApi from './BaseApi';

class AccountApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl += '/accounts';
  }

  async query() {
    return await this.get(`${this.baseUrl}`);
  }

  async update(model) {
    return await this.put(`${this.baseUrl}`, model);
  }

  async signup(model) {
    return await this.post(this.baseUrl, model);
  }

  async getCompanySettings() {
    return await this.get(`${this.baseUrl}/settings`);
  }

  async updateCompanySettings(model) {
    return await this.put(`${this.baseUrl}/settings`, model);
  }

  async uploadLogo(file) {
    const form = new FormData();
    form.append('file', file);

    return await this.post(`${this.baseUrl}/logo`, form);
  }

  async deleteLogo(file) {
    return await this.delete(`${this.baseUrl}/logo`);
  }

  async getLogo() {
    return await this.get(`${this.baseUrl}/logo`);
  }

  async updateBillingInformation(model) {
    return await this.put(`${this.baseUrl}/billingInformation`, model);
  }
}

export default new AccountApi();
