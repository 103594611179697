import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import {
  FormControl,
  Typography,
  Button,
  InputLabel,
  Input,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  group: {
    margin: 0,
  },
  label: {
    marginLeft: theme.spacing(-1),
  },
  searchFields: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  switchGroup: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: theme.spacing(1, 0, 1, 0),
  },
  switchGroupLabel: {
    marginRight: theme.spacing(1),
    width: '100%',
  },
}));

const PersonSearchOption = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    firstName: undefined,
    middleName: undefined,
    lastName: undefined,
    hasPerson: undefined,
    isEmployee: undefined,
  });
  const {
    firstName,
    middleName,
    lastName,
    hasPerson,
    isEmployee,
    relationReporter,
    relationVictim,
    relationPersonOfInterest,
    relationSuspect,
    relationWitness,
    relationSubject,
  } = state;

  const inputHandleChange = event => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const addSearchCriteria = () => {
    const relations = [];
    if (relationReporter) {
      relations.push('REPORTER');
    }
    if (relationVictim) {
      relations.push('VICTIM');
    }
    if (relationPersonOfInterest) {
      relations.push('PERSON_OF_INTEREST');
    }
    if (relationSuspect) {
      relations.push('SUSPECT');
    }
    if (relationWitness) {
      relations.push('WITNESS');
    }
    if (relationSubject) {
      relations.push('SUBJECT');
    }

    const person = {
      id: uuid(),
      firstName,
      middleName,
      lastName,
      hasPerson,
      isEmployee,
      relations: relations.length > 0 ? relations : undefined,
    };

    if (
      person.hasPerson ||
      person.firstName ||
      person.middleName ||
      person.lastName
    ) {
      props.onSearchCriteriaAdded(person);
    } else {
      props.onError('Please enter person name or set "Has person" on.');
    }
  };

  const toggleHasPerson = () => {
    setState({
      ...state,
      hasPerson: !hasPerson,
    });
  };

  const toggleIsEmployee = () => {
    setState({
      ...state,
      isEmployee: isEmployee ? undefined : true,
    });
  };

  const toggleRelationChange = event => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography variant="h6">Person</Typography>
        <div className={classes.searchFields}>
          <FormControlLabel
            control={
              <Switch checked={state.hasPerson} onChange={toggleHasPerson} />
            }
            label={<Typography variant="body1">Has person</Typography>}
          />
        </div>
        <div className={classes.searchFields}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="firstName">First name</InputLabel>
            <Input
              id="firstName"
              name="firstName"
              autoFocus={!hasPerson}
              value={hasPerson ? '' : firstName}
              disabled={hasPerson}
              onChange={inputHandleChange}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="middleName">Middle initial</InputLabel>
            <Input
              id="middleName"
              name="middleName"
              value={hasPerson ? '' : middleName}
              disabled={hasPerson}
              onChange={inputHandleChange}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="lastName">Last name</InputLabel>
            <Input
              id="lastName"
              name="lastName"
              value={hasPerson ? '' : lastName}
              disabled={hasPerson}
              onChange={inputHandleChange}
            />
          </FormControl>
          <FormControlLabel
            disabled={hasPerson}
            control={
              <Switch checked={state.isEmployee} onChange={toggleIsEmployee} />
            }
            label={<Typography variant="body1">Is employee</Typography>}
          />
        </div>

        <div className={classes.switchGroup}>
          <Typography variant="h6" className={classes.switchGroupLabel}>
            Relation
          </Typography>
          <FormControlLabel
            disabled={hasPerson}
            control={
              <Switch
                checked={state.relationReporter || false}
                onChange={toggleRelationChange}
                name="relationReporter"
              />
            }
            label={<Typography variant="body1">Reporter</Typography>}
          />
          <FormControlLabel
            disabled={hasPerson}
            control={
              <Switch
                checked={state.relationVictim || false}
                onChange={toggleRelationChange}
                name="relationVictim"
              />
            }
            label={<Typography variant="body1">Victim</Typography>}
          />
          <FormControlLabel
            disabled={hasPerson}
            control={
              <Switch
                checked={state.relationPersonOfInterest}
                onChange={toggleRelationChange}
                name="relationPersonOfInterest"
              />
            }
            label={<Typography variant="body1">Person of Interest</Typography>}
          />
          <FormControlLabel
            disabled={hasPerson}
            control={
              <Switch
                checked={state.relationSuspect || false}
                onChange={toggleRelationChange}
                name="relationSuspect"
              />
            }
            label={<Typography variant="body1">Suspect</Typography>}
          />
          <FormControlLabel
            disabled={hasPerson}
            control={
              <Switch
                checked={state.relationWitness || false}
                onChange={toggleRelationChange}
                name="relationWitness"
              />
            }
            label={<Typography variant="body1">Witness</Typography>}
          />
          <FormControlLabel
            disabled={hasPerson}
            control={
              <Switch
                checked={state.relationSubject || false}
                onChange={toggleRelationChange}
                name="relationSubject"
              />
            }
            label={<Typography variant="body1">Subject</Typography>}
          />
        </div>
        <div className={classes.searchFields}>
          <FormControl className={classes.formControl}>
            <Button
              variant="contained"
              color="primary"
              className={classes.searchButton}
              onClick={addSearchCriteria}
            >
              Add Search Criteria
            </Button>
          </FormControl>
        </div>
      </div>
    </React.Fragment>
  );
};

PersonSearchOption.propTypes = {
  onSearchCriteriaAdded: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

PersonSearchOption.defaultProps = {
  onSearchCriteriaAdded: () => {},
  onError: () => {},
};

export default PersonSearchOption;
