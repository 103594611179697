import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import config from '../../config';
import {
  Typography,
  Box,
  Avatar,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Button,
  Link,
  Container,
  CssBaseline,
  FormControl,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import _ from 'lodash';

import DefaultLayout from '../DefaultLayout';
import { useMediaInfo } from '../../utils/hooks';
import { indigo } from '@material-ui/core/colors';
import { useForm } from '../../utils/hooks';
import * as yup from 'yup';
import auth from '../../auth/Auth';
import { sendOTP, startEnrol, verifyEnrol } from './ducks';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5),
    backgroundColor: '#fafafa',
    justifyContent: 'center',
    alignItems: 'center',
  },

  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  mobileRoot: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0),
    backgroundColor: '#fafafa',
  },
  title: {
    padding: theme.spacing(2),
  },
  subTitle: {
    paddingBottom: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(1),
    color: theme.palette.getContrastText(indigo[500]),
    backgroundColor: indigo[500],
  },
  content1: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: theme.spacing(2),
  },
  phone: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(2),
  },
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 180,
    width: '100%',
  },
  buttons: {
    marginBottom: theme.spacing(2),
  },
}));

const schema = yup.object().shape({
  phoneNumber: yup
    .string()
    .matches(/^[0-9]+$/, 'please enter digit only')
    .required('Please enter phone number'),
});

const StartMfaEnrollment = props => {
  const {
    formValues,
    updateFormValues,
    handleFormChange,
    handleFormBlur,
    hasFormError,
    formHelpText,
    handleFormSubmit,
  } = useForm({
    validationSchema: schema,
    onSubmit(values, errors) {
      if (_.isEmpty(errors)) {
        const { phoneNumber } = values;

        props.startEnrol(phoneNumber).then(() => {
          props.history.push({
            pathname: '/verifyEnrollment',
            state: {
              phoneNumber: phoneNumber,
            },
          });
        });
      }
    },
  });

  const urlParams = new URLSearchParams(props.location.search);
  const [state, setState] = React.useState({
    token: urlParams.get('token'),
  });

  React.useEffect(() => {
    // use challenge token on MFA
    sessionStorage.setItem('jwt', state.token);
  }, []);

  const classes = useStyles();

  const logout = () => {
    auth.logout();
  };

  return (
    <DefaultLayout title={null}>
      <div className={classes.root}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div className={classes.center}>
              <Avatar className={classes.avatar}>
                <LockIcon />
              </Avatar>
              <div />
              <Typography component="h1" variant="h6">
                Security Check
              </Typography>
            </div>

            <div className={classes.content1}>
              <Typography variant="body1">
                Secure your account by adding phone verification. Please enter
                your phone number
              </Typography>
            </div>

            <Box component="form" noValidate sx={{ mt: 1 }}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="phoneNumber"
                  margin="normal"
                  required
                  fullWidth
                  label="Phone Number"
                  name="phoneNumber"
                  inputProps={{ autoComplete: 0 }}
                  variant="outlined"
                  onChange={handleFormChange}
                  onBlur={handleFormBlur}
                  helperText={formHelpText('phoneNumber')}
                  error={hasFormError('phoneNumber')}
                />
              </FormControl>

              <div className={classes.buttons}>
                {!state.phoneSent && (
                  <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    disabled={props.loading}
                    id="savePhoneNumber"
                    onClick={handleFormSubmit}
                  >
                    Save phone number
                  </Button>
                )}
              </div>

              <div className={classes.buttons}>
                <Button
                  // type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  id="mfaLogout"
                  className="free-trial-form-submit"
                  onClick={logout}
                >
                  Cancel
                </Button>
              </div>
            </Box>
          </Box>
        </Container>
      </div>
    </DefaultLayout>
  );
};

StartMfaEnrollment.propTypes = {
  phone: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ login }) => {
  return {
    phone: login.phone,
    loading: login.loading,
  };
};
const mapDispatchToProps = {
  sendOTP,
  startEnrol,
  verifyEnrol,
};

export default connect(mapStateToProps, mapDispatchToProps)(StartMfaEnrollment);
