import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Paper,
  Typography,
  IconButton,
  Button,
  Dialog,
  Card,
  CardHeader,
  CardContent,
  Tooltip,
} from '@material-ui/core';
import { CSVLink } from 'react-csv';
import PrintIcon from '@material-ui/icons/Print';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import uuid from 'uuid/v4';
import produce from 'immer';
import { makeStyles } from '@material-ui/styles';
import IncidentViewDialog from '../incidentView/IncidentViewDialog';
import {
  IncidentDef,
  LookupDataSetDef,
  SearchResultColumnDef,
} from '../../components/propTypeDefs';
import IncidentTable from '../dashboard/IncidentTable';
import { IncidentStatusEnum, LookupTypeKey } from '../../constants';
import reportApi from '../../apis/report.api';
import reportUtil, {
  convertIncidentSearchResultToReportData,
} from '../../utils/reportUtil';
import { enqueueError } from '../../modules/global';
import ReportViewer from '../../components/ReportViewer';
import auth from '../../auth/Auth';
import { useMediaInfo } from '../../utils/hooks';
import SearchResultColumnsDialog from './SearchResultColumnsDialog';
import ExcelIcon from '../../components/icons/ExcelIcon';
import { getCompanyLogo } from '../reports/ducks';
import { openClosedIncident } from '../dashboard/ducks';
import AlertDialog from '../../components/dialogs/AlertDialog';

const useStyles = makeStyles(theme => ({
  tableWrapper: {
    overflowX: 'auto',
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 700,
    padding: theme.spacing(1),
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  titleText: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3rem',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  mobileButton: {
    marginLeft: theme.spacing(1),
  },
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
    backgroundColor: '#f6f6f6',
  },
  cardHeader: {
    padding: theme.spacing(3),
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  cardContent: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  reportDialog: {
    '& .MuiDialog-paperScrollPaper': {
      flexGrow: 1,
    },
  },
  note: {
    color: theme.palette.grey[500],
  },
}));

const SearchResult = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    viewerOpen: false,
    selectedIncident: undefined,
    visibleIncidents: [],
    page: 0,
  });
  const rowsPerPage = 10;
  const { isSmaller, isMobile } = useMediaInfo({ minBreakPoint: 'sm' });

  const { selectedIncident } = state;

  const applyPagination = page => {
    const skip = page * rowsPerPage;
    setState({
      ...state,
      visibleIncidents: props.incidents.slice(skip, skip + rowsPerPage),
      page,
    });
  };

  const closeIncidentView = () => {
    setState({
      ...state,
      viewerOpen: false,
      selectedIncident: undefined,
    });
  };

  const [reportViewerInfo, setReportViewerInfo] = React.useState({
    open: false,
    reportName: undefined,
    reportId: undefined,
    reportTitle: 'Report',
  });

  const [
    openClosedIncidentAlertOpen,
    setOpenClosedIncidentAlertOpen,
  ] = React.useState(false);

  const handleIncidentCommand = (commandName, value) => {
    if (commandName === 'print') {
      // @TODO
    } else if (['view', 'edit'].includes(commandName)) {
      setState({
        ...state,
        viewerOpen: true,
        viewerMode: commandName,
        selectedIncident: value,
      });
    } else if (commandName === 'page') {
      applyPagination(value);
    }
  };
  const saveSearchCriteria = () => {
    props.onSaveToDashboard();
  };

  const saveSearchCriteriaToReport = () => {
    props.onSaveToReport();
  };

  const onUpdate = () => {
    props.onUpdate();
  };

  const [searchResultColumnInfo, setSearchResultColumnInfo] = React.useState({
    open: false,
  });

  // on mounted
  React.useEffect(() => {
    props.getCompanyLogo();
  }, []);

  React.useEffect(() => {
    applyPagination(0);
  }, [props.incidents]);

  const printIncidents = async type => {
    const reportId = uuid();
    const reportTitle = 'Search Result';

    const reportData = convertIncidentSearchResultToReportData(
      state.visibleIncidents,
      props.lookupDataSet[LookupTypeKey.incidentClass],
    );
    try {
      await reportApi.uploadReportData(reportId, reportData, reportTitle);

      setReportViewerInfo({
        open: true,
        reportName: 'IncidentSearchResult',
        reportId,
        reportTitle,
      });
    } catch (err) {
      props.enqueueError(err);
    }
  };

  const openColumnsDialog = () => {
    setSearchResultColumnInfo(
      produce(searchResultColumnInfo, draft => {
        draft.open = true;
      }),
    );
  };

  const handleColumDialogClose = () => {
    setSearchResultColumnInfo(
      produce(searchResultColumnInfo, draft => {
        draft.open = false;
      }),
    );
  };

  const getExportData = () => {
    return reportUtil.exportIncidentData(
      props.incidents,
      props.searchResultColumns,
      props.companySettings.dateFormat,
      props.lookupDataSet[LookupTypeKey.severityType],
    );
  };

  const handleOpenClosedIncident = incidentId => {
    props.openClosedIncident(incidentId).then(() => {
      setOpenClosedIncidentAlertOpen(false);
      setState({
        ...state,
        viewerOpen: true,
        viewerMode: 'edit',
        selectedIncident: Object.assign({}, state.selectedIncident, {
          status: IncidentStatusEnum.open,
          close: undefined,
        }),
      });
    });
  };

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={
            <>
              <div className={classes.title}>
                <Typography variant="h4" className={classes.titleText}>
                  Results
                  <Tooltip title="Adjust columns">
                    <IconButton
                      color="primary"
                      aria-label="Adjust columns"
                      onClick={openColumnsDialog}
                      size="small"
                    >
                      <ViewColumnIcon />
                    </IconButton>
                  </Tooltip>
                  <CSVLink data={getExportData()} filename="SearchResult.csv">
                    <Tooltip title="Export to excel">
                      <IconButton
                        color="primary"
                        aria-label="Export to excel"
                        size="small"
                      >
                        <ExcelIcon />
                      </IconButton>
                    </Tooltip>
                  </CSVLink>
                </Typography>
                <div className={classes.buttons}>
                  {props.updateEnabled && (
                    <Button
                      variant="contained"
                      color="primary"
                      className={
                        isMobile ? classes.mobileButton : classes.button
                      }
                      onClick={onUpdate}
                    >
                      Update
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    className={isMobile ? classes.mobileButton : classes.button}
                    onClick={saveSearchCriteriaToReport}
                    disabled={!auth.hasPrivilege(['REPORT_CREATE'])}
                  >
                    Save to Reports
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={isMobile ? classes.mobileButton : classes.button}
                    onClick={saveSearchCriteria}
                  >
                    Save to Dashboard
                  </Button>
                </div>
              </div>
              {!auth.hasPrivilege(['ALLINCIDENT_SEARCH']) && (
                <Typography variant="body2" className={classes.note}>
                  * Due to permission settings, your search will only return
                  results for incidents you have created.
                </Typography>
              )}
            </>
          }
        />
        <CardContent className={classes.cardContent}>
          <IncidentTable
            incidents={state.visibleIncidents}
            columns={props.searchResultColumns}
            incidentLookups={props.lookupDataSet[LookupTypeKey.incidentClass]}
            severityLookups={props.lookupDataSet[LookupTypeKey.severityType]}
            onItemCommand={handleIncidentCommand}
            showPagination
            rowsPerPage={rowsPerPage}
            totalCount={props.incidents.length}
            page={state.page}
            accountDateFormat={props.companySettings.dateFormat}
          />
        </CardContent>
      </Card>

      {selectedIncident && (
        <IncidentViewDialog
          open={state.viewerOpen}
          onClose={closeIncidentView}
          incident={selectedIncident}
          initialMode={state.viewerMode}
          onRefreshParentItems={f => f}
          lookupDataSet={props.lookupDataSet}
          openClosedIncident={() => {
            setOpenClosedIncidentAlertOpen(true);
          }}
        />
      )}
      <Dialog
        maxWidth="lg"
        open={reportViewerInfo.open}
        onClose={() => {
          setReportViewerInfo({ open: false });
        }}
        className={classes.reportDialog}
      >
        <ReportViewer
          reportName={reportViewerInfo.reportName}
          reportId={reportViewerInfo.reportId}
          parameters={{ ReportName: reportViewerInfo.reportTitle }}
          reportTitle="Search Result"
          logoUrl={props.companyLogo}
        />
      </Dialog>
      <SearchResultColumnsDialog
        open={searchResultColumnInfo.open}
        searchResultColumns={props.searchResultColumns}
        onChange={newColumns => props.onColumnsChange(newColumns)}
        onClose={handleColumDialogClose}
      />
      <AlertDialog
        title="Reopen closed incident"
        message="Are you sure you want to re-open closed incident?"
        open={openClosedIncidentAlertOpen}
        onClose={() => {
          setOpenClosedIncidentAlertOpen(false);
        }}
        onCancel={() => {
          setOpenClosedIncidentAlertOpen(false);
        }}
        onOk={() => {
          handleOpenClosedIncident(state.selectedIncident.id);
        }}
      />
    </React.Fragment>
  );
};
SearchResult.propTypes = {
  incidents: PropTypes.arrayOf(IncidentDef).isRequired,
  lookupDataSet: LookupDataSetDef.isRequired,
  updateEnabled: PropTypes.bool,
  onUpdate: PropTypes.func,
  onSaveToDashboard: PropTypes.func,
  onSaveToReport: PropTypes.func,
  enqueueError: PropTypes.func.isRequired,
  searchResultColumns: PropTypes.arrayOf(SearchResultColumnDef),
  onColumnsChange: PropTypes.func,
  companyLogo: PropTypes.string.isRequired,
  getCompanyLogo: PropTypes.func.isRequired,
  openClosedIncident: PropTypes.func.isRequired,
};

SearchResult.defaultProps = {
  updateEnabled: false,
  onSaveToDashboard: f => f,
  onSaveToReport: f => f,
  onColumnsChange: f => f,
};

const mapStateToProps = ({ common, reports }) => ({
  lookupDataSet: common.lookupDataSet,
  companyLogo: reports.companyLogo,
  companySettings: common.companySettings,
});

const mapDispatchToProps = {
  enqueueError,
  getCompanyLogo,
  openClosedIncident,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);
