import { Component } from 'react';
import PropTypes from 'prop-types';
import Auth from '../auth/Auth';
import { privateRoutes } from '../routes';
import * as workerTimers from 'worker-timers';

const eventsChanged = (previousEvents, newEvents) =>
  previousEvents.sort().toString() !== newEvents.sort().toString();

export default class IdleTimer extends Component {
  static propTypes = {
    defaultIdle: PropTypes.bool,
    onChange: PropTypes.func,
    render: PropTypes.func,
    idleSecond: PropTypes.number.isRequired,
    events: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    defaultIdle: false,
    render: () => null,
    onChange: () => {},
    events: [
      'click',
      'mousemove',
      'mouseenter',
      'keydown',
      'scroll',
      'touchmove',
      'touchstart',
    ],
  };

  constructor(props) {
    super(props);
    this.state = {
      idle: this.props.defaultIdle,
    };

    this.setTimeout = this.setTimeout.bind(this);
    this.handleEvent = this.handleEvent.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.removeEvents = this.removeEvents.bind(this);
    this.attachEvents = this.attachEvents.bind(this);
  }

  componentDidMount() {
    this.idleTime = 0;
    this.attachEvents();
    this.setTimeout();
  }

  componentDidUpdate(prevProps) {
    if (eventsChanged(prevProps.events, this.props.events)) {
      this.removeEvents();
      this.attachEvents();
    }
  }

  componentWillUnmount() {
    this.removeEvents();
    // clearTimeout(this.timeout);
    workerTimers.clearInterval(this.timeout);
  }

  setTimeout() {
    const isPrivateRoute = privateRoutes.includes(window.location.pathname);
    if (!Auth.isAuthenticated && isPrivateRoute) {
      Auth.logout();
      return;
    }

    // check idle on evry 5 seconds
    if (this.props.idleSecond) {
      this.timeout = workerTimers.setInterval(() => {
        this.idleTime += 5;
        if (!Auth.isAuthenticated && isPrivateRoute) {
          Auth.logout();
          return;
        }
        if (this.idleTime >= this.props.idleSecond) {
          this.idleTime = 0;
          this.handleChange(true);
        }
      }, 1000 * 5);

      // this.timeout = setInterval(() => {
      //   console.log('xxx idle second:', this.idleTime);
      //   this.idleTime += 5;
      //   if (!Auth.isAuthenticated && isPrivateRoute) {
      //     Auth.logout();
      //     return;
      //   }
      //   if (this.idleTime >= this.props.idleSecond) {
      //     this.idleTime = 0;
      //     this.handleChange(true);
      //   }
      // }, 1000 * 5);
    }
  }

  handleEvent(e) {
    this.idleTime = 0;
    if (this.state.idle) {
      this.handleChange(false);
    }
  }

  handleChange(idle) {
    this.props.onChange(idle);
    this.setState({ idle });
  }

  removeEvents() {
    this.props.events.forEach(event => {
      window.removeEventListener(event, this.handleEvent);
    });
  }

  attachEvents() {
    this.props.events.forEach(event => {
      window.addEventListener(event, this.handleEvent);
    });
  }

  render() {
    return this.props.render(this.state.idle);
  }
}
