import BaseApi from './BaseApi';

class CommonApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl += '/commons';
  }

  async getInitData() {
    return await this.get(`${this.baseUrl}/initData`);
  }
}

export default new CommonApi();
