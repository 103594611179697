import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import uuid from 'uuid/v4';
import produce from 'immer';
import { Button } from '@material-ui/core';
import {
  IncidentPersonDef,
  IncidentCompanyDef,
  IncidentItemDef,
  IncidentAttachmentDef,
} from '../../components/propTypeDefs';
import ExpansionCard from '../../components/cards/ExpansionCard';
import IncidentItemItem from './IncidentItemItem';
import { PersonType } from '../../constants';
import { getPersonName, getCompanyName } from '../../utils/nameUtil';

const useStyles = makeStyles(theme => ({
  expansionCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  itemItem: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
  },
  expansionCardItem: {},
  itemAddButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
  },
}));

const IncidentItemCard = props => {
  const classes = useStyles();
  const { items, incidentId } = props;
  const [state, setState] = React.useState({
    items,
    selectedItemId: items && items.length === 1 ? items[0].id : undefined,
  });
  const [propertyOwners, setPropertyOwners] = React.useState([]);

  // React.useEffect(() => {
  //   props.onChange({ items: state.items });
  // }, [state.items]);

  React.useEffect(() => {
    setState({ ...state, items });
  }, [props.items]);

  React.useEffect(() => {
    const { persons, companies } = props;
    const extractor = personType => ({
      id,
      name,
      firstName,
      middleName,
      lastName,
    }) => ({
      id,
      name:
        personType === PersonType.person
          ? getPersonName({ firstName, middleName, lastName })
          : getCompanyName({ name }),
      personType,
    });
    const mergedList = persons
      .map(extractor(PersonType.person))
      .concat(companies.map(extractor(PersonType.company)));
    setPropertyOwners(mergedList);
  }, [props.persons, props.companies]);

  const emitData = data => {
    props.onChange({ items: data });
  };

  const addItem = () => {
    const count = state.items.length;
    const name = `Item ${count + 1}`;
    const newItem = { id: uuid(), incidentId, name };
    const newList = [...state.items, newItem];
    setState({
      ...state,
      items: newList,
      selectedItemId: newItem.id,
    });
    emitData(newList);
  };

  const onItemCardExpanded = (expanded, itemId) => {
    setState({
      ...state,
      selectedItemId: expanded ? itemId : undefined,
    });
  };

  const onItemDeleted = itemId => {
    const newList = state.items.filter(x => x.id !== itemId);
    setState({
      ...state,
      items: newList,
    });
    emitData(newList);
  };

  const itemChanged = item => {
    const nextState = produce(state, draft => {
      const found = draft.items.find(x => x.id === item.id);
      Object.assign(found, item);
    });
    setState(nextState);
    emitData(nextState.items);
  };

  return (
    <ExpansionCard
      title={`Items (${state.items.length})`}
      helpMessage="Add information about any items involved in the incident."
    >
      <div className={classes.expansionCardContainer}>
        <div className={classes.expansionCardItem}>
          {state.items.map(item => {
            return (
              <div className={classes.itemItem} key={item.id}>
                <IncidentItemItem
                  item={item}
                  propertyOwners={propertyOwners}
                  expanded={state.selectedItemId === item.id}
                  onCardExpanded={onItemCardExpanded}
                  onDeleted={onItemDeleted}
                  onChange={itemChanged}
                  attachments={props.attachments}
                />
              </div>
            );
          })}
          <div className={classes.itemAddButton}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={addItem}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    </ExpansionCard>
  );
};

IncidentItemCard.propTypes = {
  onChange: PropTypes.func,
  incidentId: PropTypes.string,
  items: PropTypes.arrayOf(IncidentItemDef),
  persons: PropTypes.arrayOf(IncidentPersonDef),
  companies: PropTypes.arrayOf(IncidentCompanyDef),
  attachments: PropTypes.arrayOf(IncidentAttachmentDef),
};
IncidentItemCard.defaultProps = {
  onChange: f => f,
  incidentId: undefined,
  items: [],
  persons: [],
  companies: [],
  attachments: [],
};

export default IncidentItemCard;
