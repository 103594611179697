import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Slide,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import ReactPlayer from 'react-player';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import ResetIcon from '@material-ui/icons/ZoomOutMap';
import ZoomInIcon from '@material-ui/icons/AddCircleOutline';
import ZoomOutIcon from '@material-ui/icons/RemoveCircleOutline';

import { IncidentAttachmentDef } from '../propTypeDefs';
import Auth from '../../auth/Auth';
import { getAzureFileUrl, downloadFromUrl } from '../../utils/fileUtil';
import { AttachmentType } from '../../constants';
import PdfFileView from './PdfFileView';
import TextFileView from './TextFileView';
import DocFileView from './DocFileView';
import { useMediaInfo } from '../../utils/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    // overflow: 'hidden',
  },
  appBar: {
    position: 'relative',
    marginBottom: theme.spacing(1),
  },
  mediaContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1em',
    alignItems: 'center',
    flexGrow: 1,
    overflow: 'auto',
  },
  imageWrapper: {
    flexGrow: 1,
    height: 'auto',
    maxWidth: '90%',
    maxHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
  },
  imageToolbar: {
    flexGrow: 0,
    alignSelf: 'flex-end',
  },
  media: {
    display: 'block',
    height: '100%',
    width: '100%',
    // maxWidth: '100%',
    // maxHeight: '100%',
    objectFit: 'contain',
  },
  title: {
    flexGrow: 1,
  },
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const fileTypeMap = {
  [AttachmentType.doc]: 'docx',
  [AttachmentType.xls]: 'xlsx',
  [AttachmentType.pdf]: 'pdf',
};

const AttachedImageViewer = props => {
  const classes = useStyles();
  const { fileName, name, attachmentType, sasKey, thumbnailSasKey } = props.attachment || {};
  const { isIOS } = useMediaInfo({ minBreakPoint: 'sm' });

  const handleClose = () => {
    props.onClose();
  };

  const getFileUrl = () => {
    if (!Auth.currentUser) return '';
    return getAzureFileUrl(Auth.currentUser.accountId, fileName, false, sasKey, thumbnailSasKey);
  };

  const handleDownload = () => {
    downloadFromUrl(getFileUrl(), name);
  };

  const renderViewer = () => {
    if (!props.attachment) return null;

    switch (attachmentType) {
      case AttachmentType.image:
        return (
          <TransformWrapper
            className={classes.imageWrapper}
            options={{ minScale: 0.2 }}
          >
            {({ zoomIn, zoomOut, resetTransform, ...reset }) => (
              <>
                <div className={classes.imageToolbar}>
                  <IconButton
                    className={classes.imageToolButton}
                    onClick={zoomIn}
                  >
                    <ZoomInIcon />
                  </IconButton>
                  <IconButton
                    className={classes.imageToolButton}
                    onClick={zoomOut}
                  >
                    <ZoomOutIcon />
                  </IconButton>
                  <IconButton
                    className={classes.imageToolButton}
                    onClick={resetTransform}
                  >
                    <ResetIcon />
                  </IconButton>
                </div>
                <TransformComponent>
                  <img
                    src={getFileUrl()}
                    className={classes.media}
                    alt={name}
                  />
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        );
      case AttachmentType.txt:
        return <TextFileView fileName={fileName} sasKey={sasKey} />;
      case AttachmentType.pdf:
        return <PdfFileView fileName={fileName} sasKey={sasKey} />;
      case AttachmentType.doc:
        return <DocFileView fileName={fileName} sasKey={sasKey} />;
      case AttachmentType.movie:
      case AttachmentType.audio:
        return (
          <ReactPlayer
            url={getFileUrl()}
            playing
            width="100%"
            height="100%"
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={handleClose}
      TransitionComponent={Transition}
      className={classes.root}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.title}>
            {name}
          </Typography>
          {!isIOS && (
            <Button color="inherit" onClick={handleDownload}>
              Download
            </Button>
          )}
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={classes.mediaContainer}>{renderViewer()}</div>
    </Dialog>
  );
};

AttachedImageViewer.propTypes = {
  open: PropTypes.bool,
  attachment: IncidentAttachmentDef,
  onClose: PropTypes.func,
};

AttachedImageViewer.defaultProps = {
  open: false,
  attachment: undefined,
  onClose: f => f,
};

export default AttachedImageViewer;
