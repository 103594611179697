import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  DialogActions,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ChildrenDef } from '../propTypeDefs';

const useStyles = makeStyles(theme => ({
  root: {
    // '& .MuiDialog-paper': {
    //   [theme.breakpoints.down('xs')]: {
    //     margin: 16,
    //   },
    // },
  },
  container: {
    flexGrow: 1,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    // backgroundColor: '#f6f6f6',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
}));

const LoadingProgress = props => {
  return (
    <div display="flex" style={{ flexGrow: 1 }}>
      <LinearProgress color="secondary" />
    </div>
  );
};

const BaseDialog = props => {
  const classes = useStyles();
  const {
    open,
    onClose,
    title,
    actionContent,
    loading,
    fullScreen,
    maxWidth,
    ...other
  } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      {...other}
      className={classes.root}
      // scroll="body"
    >
      {loading && <LoadingProgress />}
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent className={classes.container}>
        {props.children}
      </DialogContent>
      {actionContent && <DialogActions>{actionContent}</DialogActions>}
    </Dialog>
  );
};

BaseDialog.propTypes = {
  onClose: PropTypes.func,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  children: ChildrenDef,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  actionContent: ChildrenDef,
  fullScreen: PropTypes.bool,
};

BaseDialog.defaultProps = {
  maxWidth: 'sm',
  fullScreen: false,
  loading: false,
};
export default BaseDialog;
