import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import {
  FormControl,
  Typography,
  Button,
  InputLabel,
  Input,
  Select,
  MenuItem,
} from '@material-ui/core';
import _ from 'lodash';

import { makeStyles } from '@material-ui/styles';
import CountrySelect from '../../components/forms/CountrySelect';
import CitySearch from '../../components/forms/CitySearch';
import { StateProvinceDef } from '../../components/propTypeDefs';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  group: {
    margin: 0,
  },
  label: {
    marginLeft: theme.spacing(-1),
  },
  searchFields: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}));

const AddressSearchOption = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    cityId: undefined,
    cityName: undefined,
    stateProvinceCode: undefined,
    countryCode: 'CA',
    statesOfCountry: [],
    street: undefined,
    address: undefined,
    unit: undefined,
  });

  const inputHandleChange = event => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleCityChange = e => {
    const value = e.target.value;
    setState({
      ...state,
      cityId: value ? value.id : undefined,
      cityName: value ? value.name : undefined,
      statesOfCountry: props.states.filter(
        x => x.countryCode === (value ? value.countryCode : state.countryCode),
      ),
      stateProvinceCode: value ? value.stateCode : state.stateProvinceCode,
      countryCode: value ? value.countryCode : state.countryCode,
    });
  };

  const addSearchCriteria = () => {
    const {
      cityId,
      cityName,
      stateProvinceCode,
      street,
      address,
      unit,
    } = state;
    const location = {
      id: uuid(),
      cityId,
      cityName,
      stateProvinceCode,
      countryCode: state.countryCode,
      street: street || undefined,
      address: address || undefined,
      unit: unit || undefined,
    };

    props.onSearchCriteriaAdded(location);
  };

  React.useEffect(() => {
    setState({
      ...state,
      statesOfCountry: props.states.filter(
        x => x.countryCode === state.countryCode,
      ),
    });
  }, [state.countryCode]);

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography variant="h6">Address</Typography>
        <div className={classes.searchFields}>
          <FormControl className={classes.formControl}>
            <CountrySelect
              classes={classes}
              defaultValue={state.countryCode}
              name="countryCode"
              onChange={inputHandleChange}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel shrink htmlFor="state-label-placeholder">
              State/Prov
            </InputLabel>
            <Select
              value={state.stateProvinceCode || ''}
              onChange={inputHandleChange}
              input={
                <Input name="stateProvinceCode" id="state-label-placeholder" />
              }
              displayEmpty
              name="stateProvinceCode"
              className={classes.selectEmpty}
            >
              <MenuItem value="" />
              {state.statesOfCountry.map(({ code, name }) => {
                return (
                  <MenuItem value={code} key={code}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <CitySearch
              // defaultCity={defaultCity}
              countryCode={state.countryCode}
              onChange={handleCityChange}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="street">Street/Ave</InputLabel>
            <Input
              name="street"
              autoFocus
              value={state.street}
              onChange={inputHandleChange}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="address">Address</InputLabel>
            <Input
              name="address"
              autoFocus
              value={state.address}
              onChange={inputHandleChange}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="unit">Unit</InputLabel>
            <Input
              name="unit"
              autoFocus
              value={state.unit}
              onChange={inputHandleChange}
            />
          </FormControl>
        </div>
        <div className={classes.searchFields}>
          <FormControl className={classes.formControl}>
            <Button
              variant="contained"
              color="primary"
              className={classes.searchButton}
              onClick={addSearchCriteria}
            >
              Add Search Criteria
            </Button>
          </FormControl>
        </div>
      </div>
    </React.Fragment>
  );
};

AddressSearchOption.propTypes = {
  onSearchCriteriaAdded: PropTypes.func.isRequired,
  states: PropTypes.arrayOf(StateProvinceDef).isRequired,
};
AddressSearchOption.defaultProps = {
  onSearchCriteriaAdded: () => {},
};

const mapStateToProps = ({ common }) => ({
  states: common.states,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddressSearchOption);
