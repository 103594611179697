import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Topbar from '../components/Topbar';
import AppFooter from './AppFooter';
import { ChildrenDef } from '../components/propTypeDefs';
import Auth from '../auth/Auth';
import NotificationBar from './NotificationBar';
import IdleTimer from '../utils/idleTimer';
import AutoLogoutDialog from '../components/dialogs/AutoLogoutDialog';
import { getAutoLogoutTime } from '../utils/autoLogoutUtil';

const backgroundShape = require('../images/shape.svg');

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    backgroundColor: theme.palette.grey['100'],
    overflow: 'hidden',
    background: `url(${backgroundShape}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: '0 0px',
    paddingBottom: 400,
    justifyContent: 'center',
  },
  page: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexWrap: 'wrap',
    // width: 1200,
    [theme.breakpoints.down('sm')]: {
      // width: 'calc(100% - 20px)',
      // margin: `0 ${theme.spacing(2)}px`,
      margin: theme.spacing(0, 3),
    },
    [theme.breakpoints.down('xs')]: {
      // width: 'calc(100% - 20px)',
      // margin: `0 ${theme.spacing(2)}px`,
      margin: theme.spacing(0, 2),
    },
    [theme.breakpoints.up('md')]: {
      // width: 'calc(100% - 20px)',
      margin: theme.spacing(0, 4),
    },
    minHeight: 400,
  },
  content: {},
  subscriptionLink: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.error.main,
    color: '#fff',
  },
}));

const DefaultLayout = props => {
  const { children, title, showNotifications } = props;
  const classes = useStyles();
  const autoLogoutTime = Number(getAutoLogoutTime()).valueOf();

  const [autoLogoutDialogOpen, SetAutoLogoutDialogOpen] = React.useState(false);
  return (
    <>
      <Topbar />
      {showNotifications && <NotificationBar />}
      <div className={classes.root}>
        <div className={classes.page}>
          {title}
          <div className={classes.content}>{children}</div>
        </div>
        <IdleTimer
          idleSecond={autoLogoutTime}
          onChange={idle => {
            if (idle) {
              if (Auth.isAuthenticated) {
                SetAutoLogoutDialogOpen(true);
              }
            }
          }}
        />
      </div>
      <AutoLogoutDialog
        open={autoLogoutDialogOpen}
        logoutSeconds={30} // 30 seconds wait until logout.
        onClose={() => {
          SetAutoLogoutDialogOpen(false);
        }}
      />
      <AppFooter />
    </>
  );
};

DefaultLayout.propTypes = {
  title: PropTypes.object,
  children: ChildrenDef,
  showNotifications: PropTypes.bool,
};
DefaultLayout.defaultProps = {
  title: null,
  children: null,
  alertOpen: false,
  showNotifications: false,
};

export default DefaultLayout;
