import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withSnackbar, useSnackbar } from 'notistack';
import { Button } from '@material-ui/core';
import * as globalActions from '../modules/global';

const withConnect = connect(
  state => ({
    notifications: state.global.notifications,
  }),
  dispatch => ({
    GlobalActions: bindActionCreators(globalActions, dispatch),
  }),
);

const usePrevious = value => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const Notifier = withConnect(({ notifications, GlobalActions }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [displayed, setDisplayed] = React.useState([]);
  const prevStacks = usePrevious(notifications);

  const storeDisplayed = id => {
    setDisplayed([...displayed, id]);
  };

  const shouldUpdate = React.useMemo(() => {
    if (!notifications.length) {
      setDisplayed([]);
      return false;
    }

    let notExists = false;
    notifications.forEach(({ key, message, dismissed, options = {} }) => {
      if (dismissed) {
        closeSnackbar(key);
        // GlobalActions.closeToast(key);
        GlobalActions.removeToast(key);
      }
      if (!notExists) {
        notExists = !prevStacks.some(({ key: prevKey }) => key === prevKey);
      }
    });

    return notExists;
  }, [notifications]);

  const getOptions = (key, options) => {
    const myOptions = [];
    if (options.canDismiss) {
      myOptions.push(
        <Button onClick={() => closeSnackbar(key)}>Dismiss</Button>,
      );
    }
    return myOptions;
  };

  React.useEffect(() => {
    if (shouldUpdate) {
      notifications.forEach(({ key, message, dismissed, options = {} }) => {
        if (displayed.includes(key)) return;
        enqueueSnackbar(message, {
          ...options,
          key,
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
          onClose: (e, reason, onCloseKey) => {
            options.onClose && options.onClose(e, reason, onCloseKey);
            GlobalActions.removeToast(onCloseKey);
          },
          action: getOptions(key, options),
          autoHideDuration: options.autoHideDuration || 5000,
        });
        storeDisplayed(key);
      });
    }
  }, [notifications]);

  return null;
});

export default Notifier;
