import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import produce from 'immer';
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import { makeStyles } from '@material-ui/styles';
import DashboardItem from './DashboardItem';
import SearchResultColumnsDialog from '../search/SearchResultColumnsDialog';
import { getDefaultSearchResultColumns } from '../../constants';

const grayColor = [
  '#999',
  '#777',
  '#3C4858',
  '#AAAAAA',
  '#D2D2D2',
  '#DDD',
  '#b4b4b4',
  '#555555',
  '#333',
  '#a9afbb',
  '#eee',
  '#e7e7e7',
];

const useStyles = makeStyles(theme => ({
  cardCategory: {
    color: grayColor[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1',
    },
  },
}));

const OpenIncidentCard = props => {
  const classes = useStyles();

  const [searchResultColumnInfo, setSearchResultColumnInfo] = React.useState({
    open: false,
    columns: getDefaultSearchResultColumns(),
  });

  const openColumnsDialog = () => {
    setSearchResultColumnInfo(
      produce(searchResultColumnInfo, draft => {
        draft.open = true;
      }),
    );
  };

  const handleColumDialogClose = () => {
    setSearchResultColumnInfo(
      produce(searchResultColumnInfo, draft => {
        draft.open = false;
      }),
    );
  };

  return (
    <DashboardItem>
      <Card>
        <CardActionArea onClick={props.onClick}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Open Incidents
            </Typography>
            <p className={classes.cardCategory}>My incidents to be processed</p>
            <h3 className={classes.cardTitle}>{props.count}</h3>
          </CardContent>
        </CardActionArea>
      </Card>
      <SearchResultColumnsDialog
        open={searchResultColumnInfo.open}
        searchResultColumns={getDefaultSearchResultColumns()}
        onChange={newColumns =>
          setSearchResultColumnInfo(
            produce(searchResultColumnInfo, draft => {
              draft.columns = newColumns;
            }),
          )
        }
        onClose={handleColumDialogClose}
      />
    </DashboardItem>
  );
};

OpenIncidentCard.propTypes = {
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};
OpenIncidentCard.defaultProps = {};

export default OpenIncidentCard;
