import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Chip, Tooltip } from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { AndTooltip, LookupTypeKey, OrTooltip } from '../../constants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  paper: {
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  group: {
    margin: 0,
  },
  label: {
    marginLeft: theme.spacing(-1),
  },
  chip: {
    margin: theme.spacing(1),
    textTransform: 'capitalize',
  },
  searchCriteria: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}));

const LookupItem = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    andOr: 'OR',
  });
  const { lastLookupIds } = props;
  const { andOr } = state;

  const handleAndOr = () => {
    setState({
      andOr: state.andOr === 'OR' ? 'AND' : 'OR',
    });
  };
  const handleClick = () => {
    props.onClick();
  };

  const handleDelete = id => {
    props.onDelete(id);
  };

  const getName = option => {
    if (option.allClass) {
      return props.lookupType === LookupTypeKey.incidentClass
        ? 'All classes'
        : 'All locations';
    }
    return option.lookupPath;
  };
  const title =
    props.lookupType === LookupTypeKey.incidentClass
      ? 'Incident class'
      : 'Location';

  return (
    <React.Fragment>
      {lastLookupIds.length > 0 && (
        <div className={classes.searchCriteria}>
          <Typography variant="h6">{title} : </Typography>
          {lastLookupIds.map((item, index) => {
            return (
              <React.Fragment key={item.id}>
                <Chip
                  label={getName(item)}
                  className={classes.chip}
                  color="secondary"
                  onClick={handleClick}
                  onDelete={() => {
                    handleDelete(item.id);
                  }}
                />
                {index < lastLookupIds.length - 1 && (
                  <Tooltip title={andOr === 'AND' ? AndTooltip : OrTooltip}>
                    <Chip label={andOr} className={classes.chip} />
                  </Tooltip>
                )}
              </React.Fragment>
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
};

LookupItem.propTypes = {
  lastLookupIds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      lookupId: PropTypes.string,
      lookupPath: PropTypes.string,
      includeDescendant: PropTypes.bool,
    }),
  ).isRequired,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  lookupType: PropTypes.oneOf(Object.values(LookupTypeKey)).isRequired,
};
LookupItem.defaultProps = {
  onDelete: () => {},
  onClick: () => {},
};

export default LookupItem;
