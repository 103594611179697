import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import userApi from '../../apis/user.api';
import { enqueueError } from '../global';

const USER_ADD_REQUEST = 'administration/users/ADD_REQUEST';
const USER_ADDED = 'administration/users/ADDED';
const USER_ADD_FAILED = 'administration/users/ADD_FAILED';

const USER_REMOVE_REQUEST = 'administration/users/REMOVE_REQUEST';
const USER_REMOVED = 'administration/users/REMOVED';
const USER_REMOVE_FAILED = 'administration/users/REMOVE_FAILED';

const USER_DEACTIVATE_REQUEST = 'administration/users/DEACTIVATE_REQUEST';
const USER_DEACTIVATED = 'administration/users/DEACTIVATED';
const USER_DEACTIVATE_FAILED = 'administration/users/DEACTIVATE_FAILED';

const USER_ACTIVATE_REQUEST = 'administration/users/ACTIVATE_REQUEST';
const USER_ACTIVATED = 'administration/users/ACTIVATED';
const USER_ACTIVATE_FAILED = 'administration/users/ACTIVATE_FAILED';

const USER_UPDATE_REQUEST = 'administration/users/UPDATE_REQUEST';
const USER_UPDATED = 'administration/users/UPDATED';
const USER_UPDATE_FAILED = 'administration/users/UPDATE_FAILED';

const USERS_LOAD_REQUEST = 'administration/users/USERS_LOAD_REQUEST';
const USERS_LOADED = 'administration/users/USERS_LOADED';
const USERS_LOAD_FAILED = 'administration/users/USERS_LOAD_FAILED';

const added = createAction(USER_ADDED);
const addFailed = createAction(USER_ADD_FAILED);
const updated = createAction(USER_UPDATED);
const updateFailed = createAction(USER_UPDATE_FAILED);
const removed = createAction(USER_REMOVED);
const removeFailed = createAction(USER_REMOVE_FAILED);
const deactivated = createAction(USER_DEACTIVATED);
const deactivateFailed = createAction(USER_DEACTIVATE_FAILED);
const activated = createAction(USER_ACTIVATED);
const activateFailed = createAction(USER_ACTIVATE_FAILED);
const loaded = createAction(USERS_LOADED);
const loadFailed = createAction(USERS_LOAD_FAILED);

export const queryUsers = params => async dispatch => {
  dispatch({ type: USERS_LOAD_REQUEST });

  try {
    const res = await userApi.queryUsers(params);
    dispatch(loaded(res.data));
  } catch (error) {
    dispatch(loadFailed(error));
    dispatch(enqueueError(error));
  }
};
export const addUser = user => async dispatch => {
  dispatch({ type: USER_ADD_REQUEST });
  try {
    const { data } = await userApi.create(user);
    dispatch(added(data));
  } catch (err) {
    dispatch(addFailed(err));
    dispatch(enqueueError(err));
    throw err;
  }
};
export const updateUser = (id, user) => async dispatch => {
  dispatch({ type: USER_UPDATE_REQUEST });
  try {
    await userApi.update(id, user);
    dispatch(updated(user));
  } catch (err) {
    dispatch(updateFailed(err));
    dispatch(enqueueError(err));
  }
};
export const removeUser = id => async dispatch => {
  dispatch({ type: USER_REMOVE_REQUEST });
  try {
    await userApi.remove(id);
    dispatch(removed(id));
  } catch (err) {
    dispatch(removeFailed(err));
    dispatch(enqueueError(err));
  }
};
export const deactivateUser = id => async dispatch => {
  dispatch({ type: USER_DEACTIVATE_REQUEST });
  try {
    await userApi.deactivate(id);
    dispatch(deactivated(id));
  } catch (err) {
    dispatch(deactivateFailed(err));
    dispatch(enqueueError(err));
  }
};
export const activateUser = id => async dispatch => {
  dispatch({ type: USER_ACTIVATE_REQUEST });
  try {
    await userApi.activate(id);
    dispatch(activated(id));
  } catch (err) {
    dispatch(activateFailed(err));
    dispatch(enqueueError(err));
  }
};

const initState = {
  queryState: undefined,
  addState: undefined,
  updateState: undefined,
  removeState: undefined,
  deactivateState: undefined,
  activateState: undefined,
  users: [],
  hasMoreUsers: false,
};

export default handleActions(
  {
    [USER_ADD_REQUEST]: (state, action) =>
      produce(state, draft => {
        draft.addState = 'request';
      }),
    [USER_ADDED]: (state, action) =>
      produce(state, draft => {
        draft.users.unshift(action.payload);
        draft.addState = 'done';
      }),
    [USER_ADD_FAILED]: (state, action) =>
      produce(state, draft => {
        draft.addState = 'fail';
      }),

    [USER_REMOVE_REQUEST]: (state, action) =>
      produce(state, draft => {
        draft.removeState = 'request';
      }),
    [USER_REMOVED]: (state, action) =>
      produce(state, draft => {
        draft.users = draft.users.filter(u => u.id !== action.payload);
        draft.removeState = 'done';
      }),
    [USER_REMOVE_FAILED]: (state, action) =>
      produce(state, draft => {
        draft.removeState = 'fail';
      }),

    [USER_DEACTIVATE_REQUEST]: (state, action) =>
      produce(state, draft => {
        draft.deactivateState = 'request';
      }),
    [USER_DEACTIVATED]: (state, action) =>
      produce(state, draft => {
        const user = draft.users.find(u => u.id === action.payload);
        user && (user.status = 2);
        draft.deactivateState = 'done';
      }),
    [USER_DEACTIVATE_FAILED]: (state, action) =>
      produce(state, draft => {
        draft.deactivateState = 'fail';
      }),

    [USER_ACTIVATE_REQUEST]: (state, action) =>
      produce(state, draft => {
        draft.activateState = 'request';
      }),
    [USER_ACTIVATED]: (state, action) =>
      produce(state, draft => {
        const user = draft.users.find(u => u.id === action.payload);
        user && (user.status = 1);
        draft.activateState = 'done';
      }),
    [USER_ACTIVATE_FAILED]: (state, action) =>
      produce(state, draft => {
        draft.activateState = 'fail';
      }),

    [USER_UPDATE_REQUEST]: (state, action) =>
      produce(state, draft => {
        draft.updateState = 'request';
      }),
    [USER_UPDATED]: (state, action) =>
      produce(state, draft => {
        const user = draft.users.find(u => u.id === action.payload.id);
        user && Object.assign(user, action.payload);
        draft.updateState = 'done';
      }),
    [USER_UPDATE_FAILED]: (state, action) =>
      produce(state, draft => {
        draft.updateState = 'fail';
      }),

    [USERS_LOAD_REQUEST]: (state, action) =>
      produce(state, draft => {
        draft.queryState = 'request';
      }),
    [USERS_LOADED]: (state, action) =>
      produce(state, draft => {
        const {
          items,
          meta: { hasMore, page },
        } = action.payload;
        if (page > 0) {
          draft.users = state.users.concat(items);
        } else {
          draft.users = items;
        }
        draft.hasMoreUsers = hasMore;
        draft.queryState = 'done';
      }),
    [USERS_LOAD_FAILED]: (state, action) =>
      produce(state, draft => {
        draft.queryState = 'fail';
      }),
  },
  initState,
);
