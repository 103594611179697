import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ExpansionCard from '../../components/cards/ExpansionCard';
import { ReadableIncidentItemDef } from '../../components/propTypeDefs';
import RichReader from '../../components/forms/RichReader';
import { labelValueStyle } from '../../theme';

const useStyles = makeStyles(theme => ({
  ...labelValueStyle,
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // flexWrap: 'wrap',
  },
  subRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    display: 'flex',
    alignItems: 'flex-start',
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
    // height: 40,
  },
  // label: {
  //   ...theme.typography.h5,
  //   fontSize: '1.1em',
  //   fontWeight: 600,
  //   color: theme.palette.grey[500],
  // },
  // value: {
  //   ...theme.typography.body,
  //   width: '100%',
  // },
}));

const IncidentViewCorrectiveActions = props => {
  const classes = useStyles();
  const { expanded, correctiveActions } = props;
  const renderItem = correctiveAction => {
    return (
      <ExpansionCard
        title={correctiveAction.type || 'Corrective Action'}
        theme="sub"
      >
        <div className={classes.root}>
          <div className={classes.lineItem}>
            <FormControl className={classes.formControl}>
              <div className={classes.label}>Type</div>
              <div className={classes.value}>{correctiveAction.type}</div>
            </FormControl>
            <FormControl className={classes.formControl}>
              <div className={classes.label}>Display type</div>
              <div className={classes.value}>
                {correctiveAction.displayType}
              </div>
            </FormControl>
            {/* <FormControl className={classes.formControl}>
              <div className={classes.label}>Property of</div>
              <div className={classes.value}>{item.ownerName}</div>
            </FormControl>
            <FormControl className={classes.formControl}>
              <div className={classes.label}>Status</div>
              <div className={classes.value}>{item.status}</div>
            </FormControl> */}
          </div>
          {correctiveAction.displayType === 'REFERRED' && (
            <div>
              <div className={classes.lineItem}>
                <FormControl className={classes.formControl}>
                  <div className={classes.label}>Referred type</div>
                  <div className={classes.value}>
                    {correctiveAction.referredType}
                  </div>
                </FormControl>
                {correctiveAction.referredType === 'EXTERNAL' && (
                  <FormControl className={classes.formControl}>
                    <div className={classes.label}>Agency</div>
                    <div className={classes.value}>
                      {correctiveAction.referredExternalAgency}
                    </div>
                  </FormControl>
                )}
                {correctiveAction.referredType === 'INTERNAL' && (
                  <FormControl className={classes.formControl}>
                    <div className={classes.label}>Department</div>
                    <div className={classes.value}>
                      {correctiveAction.referredInternalDepartment}
                    </div>
                  </FormControl>
                )}
              </div>
              <div className={classes.lineItem}>
                <FormControl className={classes.formControl}>
                  <div className={classes.label}>First name</div>
                  <div className={classes.value}>
                    {correctiveAction.referredContactFirstName}
                  </div>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <div className={classes.label}>Middle initial</div>
                  <div className={classes.value}>
                    {correctiveAction.referredContactMiddleName}
                  </div>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <div className={classes.label}>Last name</div>
                  <div className={classes.value}>
                    {correctiveAction.referredContactLastName}
                  </div>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <div className={classes.label}>Position</div>
                  <div className={classes.value}>
                    {correctiveAction.referredContactPosition}
                  </div>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <div className={classes.label}>Phone</div>
                  <div className={classes.value}>
                    {correctiveAction.referredContactPhone}
                  </div>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <div className={classes.label}>Mobile phone</div>
                  <div className={classes.value}>
                    {correctiveAction.referredContactMobilePhone}
                  </div>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <div className={classes.label}>Email</div>
                  <div className={classes.value}>
                    {correctiveAction.referredContactEmail}
                  </div>
                </FormControl>
              </div>
            </div>
          )}
          <div className={classes.lineItem}>
            <FormControl className={classes.formControl}>
              <div className={classes.label}>Involved persons</div>
              <div className={classes.value}>
                {correctiveAction.involvedPersons}
              </div>
            </FormControl>
          </div>
          <div className={classes.lineItem}>
            <FormControl className={classes.formControlFull}>
              <div className={classes.label}>Details</div>
              <div className={classes.value}>
                <RichReader content={correctiveAction.details} />
              </div>
            </FormControl>
          </div>
        </div>
      </ExpansionCard>
    );
  };

  return (
    <ExpansionCard
      title={`Corrective Actions (${correctiveActions.length})`}
      expanded={expanded}
    >
      <div className={classes.root}>
        {correctiveActions.map(correctiveAction => (
          <div key={correctiveAction.id} className={classes.subRoot}>
            {renderItem(correctiveAction)}
          </div>
        ))}
      </div>
    </ExpansionCard>
  );
};

IncidentViewCorrectiveActions.propTypes = {
  expanded: PropTypes.bool,
  correctiveActions: PropTypes.arrayOf(ReadableIncidentItemDef).isRequired,
};

IncidentViewCorrectiveActions.defaultProps = {
  expanded: false,
  correctiveActions: [],
};

export default IncidentViewCorrectiveActions;
