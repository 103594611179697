import React from 'react';
import PropTypes from 'prop-types';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { IconButton, TablePagination } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));

const PaginationActions = props => {
  const classes = useStyles();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </div>
  );
};

PaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const BasePagination = props => {
  const { totalCount, page } = props;

  function handleChangePage(event, newPage) {
    props.onChangePage(newPage);
  }

  return (
    <TablePagination
      colSpan={props.colSpan}
      count={totalCount}
      rowsPerPage={props.rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      ActionsComponent={PaginationActions}
      labelRowsPerPage=""
      rowsPerPageOptions={[]}
    />
  );
};
BasePagination.propTypes = {
  totalCount: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  colSpan: PropTypes.number,
  page: PropTypes.number,
};
BasePagination.defaultProps = {
  colSpan: 100,
  page: 0,
};

export default BasePagination;
