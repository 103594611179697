import React from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableBody,
  TableRow,
  IconButton,
  Table,
  TableHead,
  TableFooter,
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  CardActionArea,
} from '@material-ui/core';
import ViewIcon from '@material-ui/icons/Pageview';
import PrintIcon from '@material-ui/icons/Print';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LockIcon from '@material-ui/icons/Lock';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  IncidentDef,
  LookupDef,
  SearchResultColumnDef,
} from '../../components/propTypeDefs';
import { buildLookupPath } from '../../utils/lookupTreeUtil';
import BasePagination from '../../components/list/BasePagination';
import { dateFormat } from '../../utils/dateUtil';
import { translateConfig } from '../../utils/simpleTranslateUtil';
import { useMediaInfo } from '../../utils/hooks';
import {
  getIncidentSearchTableValue,
  getIncidentSearchTableValueForMobile,
} from '../../utils/reportUtil';
import {
  getDefaultSearchResultColumns,
  DefaultDateFormat,
  IncidentStatusEnum,
  AccountDateFormatEnum,
} from '../../constants';
import Auth from '../../auth/Auth';
import { canEditIncident } from '../../utils/privilegeUtil';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 700,
    padding: theme.spacing(1),
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardContent: {
    padding: theme.spacing(1, 2),
  },
  cardFieldRow: {},
  cardLabel: {
    marginRight: theme.spacing(2),
  },
  cardActions: {
    padding: 0,
  },
  tableCell: {
    maxWidth: 300,
    wordBreak: 'break-word',
  },
}));

const IncidentTable = props => {
  const classes = useStyles();
  const { isSmaller, isMobile } = useMediaInfo({ minBreakPoint: 'md' });

  const openIncident = incident => {
    props.onItemCommand('view', incident);
  };
  const editIncident = incident => {
    props.onItemCommand('edit', incident);
  };
  const printIncident = incident => {
    props.onItemCommand('print', incident);
  };
  function handleChangePage(page) {
    props.onItemCommand('page', page);
  }
  const deleteIncident = incident => {
    props.onItemCommand('delete', incident);
  };

  const { incidents, columns } = props;
  const visibleColumns = columns.filter(x => x.checked);
  const getStatusText = incident => {
    if (incident.locked) {
      return <LockIcon />;
    }
    return translateConfig.enums.incidentStatus[incident.status];
  };
  const hasDeletePermission = Auth.hasPrivilege(['INCIDENT_DELETE']);

  const isIncidentEditable = incident => {
    if (incident.status === IncidentStatusEnum.closed) {
      return false;
    }

    return canEditIncident(incident.createUserId);
  };
  return (
    <>
      {isSmaller ? (
        <Grid spacing={1} container>
          {incidents.map(incident => (
            <Grid item key={incident.id} xs={12} sm={12}>
              <Card>
                <CardActionArea
                  onClick={() => {
                    IncidentStatusEnum.closed
                      ? openIncident(incident)
                      : editIncident(incident);
                  }}
                >
                  <CardContent className={classes.cardContent}>
                    <div>
                      {/* <Typography variant="h6">#{incident.number}</Typography>
                      <Typography variant="subtitle2">
                        {buildLookupPath(
                          props.incidentLookups,
                          incident.lastClassId,
                        )}
                      </Typography>
                      {incident.reportedDate && (
                        <Typography variant="body2">
                          reported at {dateFormat(incident.reportedDate)}
                        </Typography>
                      )}
                      {incident.occurredDate && (
                        <Typography variant="body2">
                          ocurred at{' '}
                          {dateFormat(
                            incident.occurredDate,
                            DefaultDateFormat(props.companySettings.dateFormat).dateMoment,
                            incident.occurredDateUncertain,
                          )}
                        </Typography>
                      )} */}
                      {/* <div className={classes.cardFieldRow}>
                        <strong className={classes.cardLabel}>Status</strong>
                        <span>{getStatusText(incident)}</span>
                      </div> */}
                      {visibleColumns.map(col => (
                        <Typography variant="body2">
                          {getIncidentSearchTableValueForMobile(
                            col.id,
                            incident,
                            props.accountDateFormat,
                          )}
                        </Typography>
                      ))}
                    </div>
                  </CardContent>
                </CardActionArea>
                {/* <CardActions disableSpacing className={classes.cardActions}>
                  <IconButton
                    color="primary"
                    aria-label="View"
                    className={classes.margin}
                    onClick={() => {
                      openIncident(incident);
                    }}
                  >
                    <ViewIcon />
                  </IconButton>
                </CardActions> */}
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              {visibleColumns.map(col => (
                <TableCell component="th" key={col.id}>
                  {col.label}
                </TableCell>
              ))}
              {!props.actionButtonHidden && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {incidents.map(incident => (
              <TableRow key={incident.id}>
                {visibleColumns.map(col => (
                  <TableCell
                    scope="row"
                    className={classes.tableCell}
                    key={col.id}
                  >
                    {getIncidentSearchTableValue(
                      col.id,
                      incident,
                      props.accountDateFormat,
                      props.severityLookups,
                    )}
                  </TableCell>
                ))}
                {!props.actionButtonHidden && (
                  <TableCell>
                    {/* <IconButton
                      color="primary"
                      aria-label="View"
                      className={classes.margin}
                      onClick={() => {
                        openIncident(incident);
                      }}
                    >
                      <ViewIcon />
                    </IconButton> */}
                    {!isIncidentEditable(incident) ? (
                      <IconButton
                        color="primary"
                        aria-label="View"
                        className={classes.margin}
                        onClick={() => {
                          openIncident(incident);
                        }}
                        disabled={
                          !Auth.hasPrivilege([
                            'MYINCIDENT_EDIT',
                            'MYINCIDNET_ADD',
                            'MYINCIDNET_SEARCH',
                            'ALLINCIDENT_EDIT',
                            'ALLINCIDENT_SEARCH',
                          ])
                        }
                      >
                        <ViewIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        color="primary"
                        aria-label="Edit"
                        className={classes.margin}
                        onClick={() => {
                          editIncident(incident);
                        }}
                        disabled={
                          !Auth.hasPrivilege([
                            'MYINCIDENT_EDIT',
                            'ALLINCIDENT_EDIT',
                          ])
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    <IconButton
                      color="primary"
                      aria-label="Delete"
                      className={classes.margin}
                      onClick={() => {
                        deleteIncident(incident);
                      }}
                      disabled={!hasDeletePermission}
                    >
                      <DeleteIcon
                        color={hasDeletePermission ? 'error' : 'grey'}
                      />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
          {props.showPagination && (
            <TableFooter>
              <TableRow>
                <BasePagination
                  totalCount={props.totalCount}
                  onChangePage={handleChangePage}
                  rowsPerPage={props.rowsPerPage}
                  page={props.page}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      )}
    </>
  );
};

IncidentTable.propTypes = {
  incidents: PropTypes.arrayOf(IncidentDef).isRequired,
  onItemCommand: PropTypes.func,
  showPagination: PropTypes.bool,
  rowsPerPage: PropTypes.number,
  totalCount: PropTypes.number,
  page: PropTypes.number,
  columns: PropTypes.arrayOf(SearchResultColumnDef),
  actionButtonHidden: PropTypes.bool,
  accountDateFormat: PropTypes.number,
  severityLookups: PropTypes.arrayOf(LookupDef),
};

IncidentTable.defaultProps = {
  onItemCommand: f => f,
  showPagination: false,
  rowsPerPage: 10,
  totalCount: 0,
  page: 0,
  columns: getDefaultSearchResultColumns(),
  actionButtonHidden: false,
};

export default IncidentTable;
