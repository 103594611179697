import BaseApi from './BaseApi';

class ReportApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl += '/incidentNotifications';
  }

  async createIncidentNotification(model) {
    return await this.post(`${this.baseUrl}`, model);
  }

  async updateIncidentNotification(model) {
    return await this.put(`${this.baseUrl}`, model);
  }

  async deleteIncidentNotification(id) {
    return await this.delete(`${this.baseUrl}/${id}`);
  }

  async getIncidentNotifications() {
    return await this.get(`${this.baseUrl}`);
  }
}

export default new ReportApi();
