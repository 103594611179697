import React from 'react';
import { Chip, Typography } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import CompanyIcon from '@material-ui/icons/Business';
import VehicleIcon from '@material-ui/icons/DirectionsCar';
import ItemIcon from '@material-ui/icons/Redeem';

import { translateConfig } from './simpleTranslateUtil';
import { DefaultDateFormat, getDefaultSearchResultColumns } from '../constants';
import { dateFormat } from './dateUtil';
import moment from 'moment';
import { getSingleLookupName } from './incidentViewUtil';

export const convertIncidentSearchResultToReportData = accountDateFormat => searchResult => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return searchResult.map(incident => {
    const {
      id,
      number,
      occurredDate,
      reportedDate,
      status,
      locked,
      incidentClass,
      createUserName,
      assignedUserName,
      severity,
    } = incident;
    return {
      id,
      number,
      occurredDate: moment(occurredDate).format(
        DefaultDateFormat(accountDateFormat).dateMoment,
      ),
      reportedDate: moment(reportedDate).format(
        DefaultDateFormat(accountDateFormat).dateMoment,
      ),
      severity,
      status: locked ? 'Locked' : translateConfig.enums.incidentStatus[status],
      incidentClass, // buildLookupPath(lookupTree, lastClassId),
      createUserName,
      assignedUserName,
      timezone,
    };
  });
};

export const getIncidentSearchTableValue = (
  colId,
  incident,
  accountDateFormat,
  severityLookups,
) => {
  switch (colId) {
    case 'number':
      return incident.number;
    case 'occurredDate':
      return dateFormat(
        incident.occurredDate,
        DefaultDateFormat(accountDateFormat).dateMoment,
        incident.occurredDateUncertain,
      );
    case 'reportedDate':
      return dateFormat(
        incident.reportedDate,
        DefaultDateFormat(accountDateFormat).dateMoment,
      );
    case 'status':
      return incident.locked ? (
        <LockIcon />
      ) : (
        translateConfig.enums.incidentStatus[incident.status]
      );
    case 'incidentClass':
      return incident.incidentClass;
    case 'severity':
      return getSingleLookupName(incident.severityId, severityLookups);
    case 'createUserName':
      return incident.createUserName;
    case 'assignedUserName':
      return incident.assignedUserName;
    case 'location':
      return incident.location;
    case 'summaryText': {
      if (!incident.summaryText) {
        return '';
      }
      if (incident.summaryText.length > 200) {
        return `${incident.summaryText.substring(0, 200)}...`;
      }
      return incident.summaryText;
    }
    case 'persons':
      return incident.persons
        ? incident.persons.map(x => {
            return <Chip label={x.name} variant="outlined" key={x.id} />;
          })
        : undefined;
    case 'vehicles':
      return incident.vehicles
        ? incident.vehicles.map(x => {
            return <Chip label={x.name} variant="outlined" key={x.id} />;
          })
        : undefined;
    case 'companies':
      return incident.companies
        ? incident.companies.map(x => {
            return <Chip label={x.name} variant="outlined" key={x.id} />;
          })
        : undefined;
    case 'items':
      return incident.items
        ? incident.items.map(x => {
            return <Chip label={x.name} variant="outlined" key={x.id} />;
          })
        : undefined;
    case 'correctiveActions':
      return incident.correctiveActions
        ? incident.correctiveActions.map(x => {
            return <Chip label={x.name} variant="outlined" key={x.id} />;
          })
        : undefined;
    default:
      return '';
  }
};

export const getIncidentSearchTableValueForMobile = (
  colId,
  incident,
  accountDateFormat,
) => {
  switch (colId) {
    case 'number':
      return <Typography variant="h6">#{incident.number}</Typography>;
    case 'occurredDate':
      return (
        <Typography variant="body2">
          occurred on{' '}
          {dateFormat(
            incident.occurredDate,
            DefaultDateFormat(accountDateFormat).dateMoment,
            incident.occurredDateUncertain,
          )}
        </Typography>
      );
    case 'reportedDate':
      return (
        <Typography variant="body2">
          reported on{' '}
          {dateFormat(
            incident.reportedDate,
            DefaultDateFormat(accountDateFormat).dateMoment,
          )}
        </Typography>
      );
    case 'status':
      return (
        <Typography
          variant="body2"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <strong>Status </strong>
          {incident.locked ? (
            <LockIcon />
          ) : (
            <span style={{ paddingLeft: '2px' }}>
              {translateConfig.enums.incidentStatus[incident.status]}
            </span>
          )}
        </Typography>
      );
    case 'incidentClass':
      return (
        <Typography variant="subtitle2">{incident.incidentClass}</Typography>
      );
    case 'createUserName':
      return (
        <Typography variant="body2">
          <strong>Entered by </strong>
          {incident.createUserName}
        </Typography>
      );
    case 'assignedUserName':
      return (
        <Typography variant="body2">
          {incident.assignedUserName ? (
            <>
              <strong>Assigned to </strong>
              <span>{incident.assignedUserName}</span>
            </>
          ) : (
            <span />
          )}
        </Typography>
      );
    case 'location':
      return <Typography variant="body2">{incident.location}</Typography>;
    case 'summaryText': {
      if (!incident.summaryText) {
        return '';
      }
      if (incident.summaryText.length > 200) {
        return (
          <Typography variant="body2">{`${incident.summaryText.substring(
            0,
            200,
          )}...`}</Typography>
        );
      }
      return <Typography variant="body2">{incident.summaryText}</Typography>;
    }
    case 'persons':
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {incident.persons && incident.persons.length > 0 && <PersonIcon />}
          {incident.persons ? (
            incident.persons.map(x => (
              <Chip label={x.name} variant="outlined" />
            ))
          ) : (
            <div />
          )}
        </div>
      );
    case 'vehicles':
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {incident.vehicles && incident.vehicles.length > 0 && <VehicleIcon />}
          {incident.vehicles ? (
            incident.vehicles.map(x => {
              return <Chip label={x.name} variant="outlined" />;
            })
          ) : (
            <div />
          )}
        </div>
      );
    case 'companies':
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {incident.companies && incident.companies.length > 0 && (
            <CompanyIcon />
          )}
          {incident.companies ? (
            incident.companies.map(x => {
              return <Chip label={x.name} variant="outlined" />;
            })
          ) : (
            <div />
          )}
        </div>
      );
    case 'items':
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {incident.items && incident.items.length > 0 && <ItemIcon />}
          {incident.items ? (
            incident.items.map(x => {
              return <Chip label={x.name} variant="outlined" />;
            })
          ) : (
            <div />
          )}
        </div>
      );
    case 'correctiveActions':
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {incident.items && incident.items.length > 0 && (
            <span>Corrective Actions:</span>
          )}
          {incident.correctiveActions ? (
            incident.correctiveActions.map(x => {
              return <Chip label={x.name} variant="outlined" />;
            })
          ) : (
            <div />
          )}
        </div>
      );
    default:
      return '';
  }
};

export const getIncidentExportDataValue = (
  colId,
  incident,
  accountDateFormat,
  severityLookups,
) => {
  switch (colId) {
    case 'number':
      return incident.number;
    case 'occurredDate':
      return dateFormat(
        incident.occurredDate,
        DefaultDateFormat(accountDateFormat).datetimeMoment,
        incident.occurredDateUncertain,
      );
    case 'reportedDate':
      return dateFormat(
        incident.reportedDate,
        DefaultDateFormat(accountDateFormat).datetimeMoment,
      );
    case 'status':
      return translateConfig.enums.incidentStatus[incident.status];
    case 'incidentClass':
      return (
        incident.incidentClass && incident.incidentClass.replace(/"/g, '""')
      );
    case 'severity':
      return getSingleLookupName(incident.severityId, severityLookups);
    case 'createUserName':
      return (
        incident.createUserName && incident.createUserName.replace(/"/g, '""')
      );
    case 'summaryText': {
      if (!incident.summaryText) {
        return '';
      }
      return incident.summaryText && incident.summaryText.replace(/"/g, '""');
    }
    case 'persons':
      return incident.persons
        ? incident.persons
            .map(x => x.name && x.name.replace(/"/g, '""'))
            .join('\n')
        : undefined;
    case 'vehicles':
      return incident.vehicles
        ? incident.vehicles
            .map(x => x.name && x.name.replace(/"/g, '""'))
            .join('\n')
        : undefined;
    case 'companies':
      return incident.companies
        ? incident.companies
            .map(x => x.name && x.name.replace(/"/g, '""'))
            .join('\n')
        : undefined;
    case 'items':
      return incident.items
        ? incident.items
            .map(x => x.name && x.name.replace(/"/g, '""'))
            .join('\n')
        : undefined;
    case 'location':
      return (
        incident.location && incident.location.toString().replace(/"/g, '""')
      );
    case 'correctiveActions':
      return incident.correctiveActions
        ? incident.correctiveActions
            .map(x => x.name && x.name.replace(/"/g, '""'))
            .join('\n')
        : undefined;
    default:
      return '';
  }
};

const exportIncidentData = (
  incidents,
  columns,
  accountDateFormat,
  severityLookups,
) => {
  const enabledColumns = columns
    ? columns.filter(x => x.checked)
    : getDefaultSearchResultColumns().filter(x => x.checked);
  const result = [];

  // add columns
  result.push(enabledColumns.map(col => col.label));

  // add incident rows
  incidents.forEach(incident => {
    const row = enabledColumns.map(col =>
      getIncidentExportDataValue(
        col.id,
        incident,
        accountDateFormat,
        severityLookups,
      ),
    );
    result.push(row);
  });
  return result;
};

export default {
  convertIncidentSearchResultToReportData,
  exportIncidentData,
};
