import React from 'react';
import PropTypes from 'prop-types';
import MapIcon from '@material-ui/icons/Map';
import { Button, Typography, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ExpansionCard from '../../components/cards/ExpansionCard';
import { ReadableIncidentDef } from '../../components/propTypeDefs';
import RichReader from '../../components/forms/RichReader';
import { labelValueStyle } from '../../theme';

const useStyles = makeStyles(theme => ({
  ...labelValueStyle,
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    flexWrap: 'wrap',
  },
  // label: {
  //   ...theme.typography.body1,
  //   fontWeight: '500',
  //   color: theme.palette.grey[900],
  //   marginBottom: theme.spacing(0.5),
  // },
  // value: {
  //   ...theme.typography.body2,
  //   fontWeight: '400',
  //   width: '100%',
  //   color: theme.palette.grey[700],
  // },
  incidentNumber: {
    margin: '0 8px',
    fontSize: 16,
  },
}));

const IncidentViewDetails = props => {
  const classes = useStyles();
  const { expanded, incident } = props;

  return (
    <ExpansionCard title="When What Where Why" expanded={expanded}>
      <div className={classes.root}>
        <div className={classes.lineItem}>
          <FormControl className={classes.formControl}>
            <div className={classes.label}>Entered by</div>
            <div className={classes.value}>{incident.createUserName}</div>
          </FormControl>
          <FormControl className={classes.formControl}>
            <div className={classes.label}>Investigator</div>
            <div className={classes.value}>{incident.assignedUserName}</div>
          </FormControl>
        </div>

        <div className={classes.lineItem}>
          <FormControl className={classes.formControl}>
            <div className={classes.label}>Date reported</div>
            <div className={classes.value}>{incident.reportedDate}</div>
          </FormControl>
          <FormControl className={classes.formControl}>
            <div className={classes.label}>Date occurred</div>
            <div className={classes.value}>{incident.occurredDate}</div>
          </FormControl>
        </div>
        <div className={classes.lineItem}>
          <FormControl className={classes.formControl}>
            <div className={classes.label}>What</div>
            <div className={classes.value}>{incident.whatHappened}</div>
          </FormControl>
          <FormControl className={classes.formControl}>
            <div className={classes.label}>Severity</div>
            <div className={classes.value}>{incident.severity}</div>
          </FormControl>
        </div>
        <div className={classes.lineItem}>
          <FormControl className={classes.formControl}>
            <div className={classes.label}>Location</div>
            <div className={classes.value}>{incident.location}</div>
            <div className={classes.value}>{incident.address}</div>
          </FormControl>
        </div>
        <div className={classes.lineItem}>
          <FormControl className={classes.formControl}>
            <div className={classes.label}>Root cause</div>
            <div className={classes.value}>{incident.primaryCause}</div>
          </FormControl>
          <FormControl className={classes.formControl}>
            <div className={classes.label}>Cause comments</div>
            <div className={classes.value}>
              <RichReader content={incident.causeComments} />
            </div>
          </FormControl>
        </div>

        <div className={classes.lineItem}>
          <FormControl className={classes.formControl}>
            <div className={classes.label}>Summary</div>
            <div className={classes.value}>
              <RichReader content={incident.summary} />
            </div>
          </FormControl>
        </div>
      </div>
    </ExpansionCard>
  );
};

IncidentViewDetails.propTypes = {
  expanded: PropTypes.bool,
  incident: ReadableIncidentDef.isRequired,
};

IncidentViewDetails.defaultProps = {
  expanded: false,
};

export default IncidentViewDetails;
