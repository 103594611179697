import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Chip } from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { AnalyzeFrequencyType } from '../../constants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  paper: {
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  group: {
    margin: 0,
  },
  label: {
    marginLeft: theme.spacing(-1),
  },
  chip: {
    margin: theme.spacing(1),
    textTransform: 'capitalize',
  },
  searchCriteria: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}));

const FrequencyCriteria = props => {
  const classes = useStyles();

  const handleClick = () => {
    props.onClick();
  };

  return (
    <React.Fragment>
      {props.frequency && (
        <div className={classes.searchCriteria} hidden={!props.frequency}>
          <Typography variant="h6">Frequency: </Typography>
          <Chip
            // icon={<FaceIcon />}
            label={props.frequency}
            onClick={handleClick}
            className={classes.chip}
            color="secondary"
          />
        </div>
      )}
    </React.Fragment>
  );
};

FrequencyCriteria.propTypes = {
  frequency: PropTypes.oneOf(Object.values(AnalyzeFrequencyType)),
  onClick: PropTypes.func,
};

FrequencyCriteria.defaultProps = {
  frequency: undefined,
  onClick: () => {},
};

export default FrequencyCriteria;
