import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import { IncidentStatusEnum } from '../../constants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  group: {
    margin: 0,
  },
  label: {
    marginLeft: theme.spacing(-1),
  },
  searchFields: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}));

const StatusSearchOption = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    status: '',
  });

  const inputHandleChange = event => {
    const { value } = event.target;
    setState({ ...state, status: value });
  };

  const addSearchCriteria = () => {
    const { status } = state;
    if (status === '') return;
    const option = {
      id: uuid(),
      status: Number(status).valueOf(),
    };
    props.onSearchCriteriaAdded(option);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h6">Status</Typography>
      <div className={classes.lineItem}>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            aria-label="periodType"
            name="periodType"
            className={classes.group}
            value={state.status}
            onChange={inputHandleChange}
            row
          >
            <FormControlLabel value="1" control={<Radio />} label="Open" />
            <FormControlLabel value="2" control={<Radio />} label="Closed" />
          </RadioGroup>
        </FormControl>
      </div>
      <div className={classes.searchFields}>
        <FormControl className={classes.formControl}>
          <Button
            variant="contained"
            color="primary"
            className={classes.searchButton}
            onClick={addSearchCriteria}
          >
            Add Search Criteria
          </Button>
        </FormControl>
      </div>
    </div>
  );
};

StatusSearchOption.propTypes = {
  onSearchCriteriaAdded: PropTypes.func.isRequired,
};

export default StatusSearchOption;
