import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import uuid from 'uuid/v4';
import produce from 'immer';
import { Button } from '@material-ui/core';
import {
  IncidentPersonDef,
  IncidentCompanyDef,
  IncidentCorrectiveActionDef,
  LookupDef,
} from '../../components/propTypeDefs';
import ExpansionCard from '../../components/cards/ExpansionCard';
import IncidentCorrectiveActionItem from './IncidentCorrectiveActionItem';
import { PersonType } from '../../constants';
import { getPersonName, getCompanyName } from '../../utils/nameUtil';

const useStyles = makeStyles(theme => ({
  expansionCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  correctiveActionItem: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
  },
  expansionCardItem: {},
  correctiveActionAddButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
  },
}));

const IncidentCorrectiveActionCard = props => {
  const classes = useStyles();
  const { correctiveActions, incidentId } = props;
  const [state, setState] = React.useState({
    correctiveActions,
    selectedCorrectiveActionId:
      correctiveActions && correctiveActions.length === 1
        ? correctiveActions[0].id
        : undefined,
  });
  const [involvedPersons, setInvolvedPersons] = React.useState([]);

  // React.useEffect(() => {
  //   props.onChange({ correctiveActions: state.correctiveActions });
  // }, [state.correctiveActions]);

  React.useEffect(() => {
    setState({ ...state, correctiveActions });
  }, [props.correctiveActions]);

  React.useEffect(() => {
    const { persons, companies } = props;
    const extractor = personType => ({
      id,
      name,
      firstName,
      middleName,
      lastName,
    }) => ({
      id,
      name:
        personType === PersonType.person
          ? getPersonName({ firstName, middleName, lastName })
          : getCompanyName({ name }),
      personType,
    });
    const mergedList = persons
      .map(extractor(PersonType.person))
      .concat(companies.map(extractor(PersonType.company)));
    setInvolvedPersons(mergedList);
  }, [props.persons, props.companies]);

  const emitData = data => {
    props.onChange({ correctiveActions: data });
  };

  const addCorrectiveAction = () => {
    const count = state.correctiveActions.length;
    const newCorrectiveAction = {
      id: uuid(),
      incidentId,
      involvedPersonList: [],
    };
    const newList = [...state.correctiveActions, newCorrectiveAction];
    setState({
      ...state,
      correctiveActions: newList,
      selectedCorrectiveActionId: newCorrectiveAction.id,
    });
    emitData(newList);
  };

  const onCorrectiveActionCardExpanded = (expanded, correctiveActionId) => {
    setState({
      ...state,
      selectedCorrectiveActionId: expanded ? correctiveActionId : undefined,
    });
  };

  const onCorrectiveActionDeleted = correctiveActionId => {
    const newList = state.correctiveActions.filter(
      x => x.id !== correctiveActionId,
    );
    setState({
      ...state,
      correctiveActions: newList,
    });
    emitData(newList);
  };

  const correctiveActionChanged = correctiveAction => {
    const nextState = produce(state, draft => {
      const found = draft.correctiveActions.find(
        x => x.id === correctiveAction.id,
      );
      Object.assign(found, correctiveAction);
    });
    setState(nextState);
    emitData(nextState.correctiveActions);
  };

  return (
    <ExpansionCard
      title={`Corrective actions (${state.correctiveActions.length})`}
      helpMessage="Note the actions taken as a result of the incident."
    >
      <div className={classes.expansionCardContainer}>
        <div className={classes.expansionCardItem}>
          {state.correctiveActions.map(correctiveAction => {
            return (
              <div
                className={classes.correctiveActionItem}
                key={correctiveAction.id}
              >
                <IncidentCorrectiveActionItem
                  correctiveAction={correctiveAction}
                  involvedPersons={involvedPersons}
                  expanded={
                    state.selectedCorrectiveActionId === correctiveAction.id
                  }
                  onCardExpanded={onCorrectiveActionCardExpanded}
                  onDeleted={onCorrectiveActionDeleted}
                  onChange={correctiveActionChanged}
                  correctiveActionTypeLookups={
                    props.correctiveActionTypeLookups
                  }
                  departmentLookups={props.departmentLookups}
                  agencyLookups={props.agencyLookups}
                />
              </div>
            );
          })}
          <div className={classes.correctiveActionAddButton}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={addCorrectiveAction}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    </ExpansionCard>
  );
};

IncidentCorrectiveActionCard.propTypes = {
  onChange: PropTypes.func,
  incidentId: PropTypes.string,
  correctiveActions: PropTypes.arrayOf(IncidentCorrectiveActionDef),
  persons: PropTypes.arrayOf(IncidentPersonDef),
  companies: PropTypes.arrayOf(IncidentCompanyDef),
  correctiveActionTypeLookups: PropTypes.arrayOf(LookupDef),
  departmentLookups: PropTypes.arrayOf(LookupDef),
  agencyLookups: PropTypes.arrayOf(LookupDef),
};
IncidentCorrectiveActionCard.defaultProps = {
  onChange: f => f,
  incidentId: undefined,
  correctiveActions: [],
  persons: [],
  companies: [],
  correctiveActionTypeLookups: [],
  departmentLookups: [],
  agencyLookups: [],
};

export default IncidentCorrectiveActionCard;
