import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';

export default class IncidentAttachmentModel {
  constructor(id, name, description, url, attachmentType) {
    if (id) {
      this.id = id;
    } else {
      this.id = uuid();
    }
    this.name = name;
    this.description = description;
    this.url = url;
    this.attachmentType = attachmentType;
  }
}
