import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import uuid from 'uuid/v4';
import {
  FormControl,
  Typography,
  Button,
  InputLabel,
  Input,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  group: {
    margin: 0,
  },
  label: {
    marginLeft: theme.spacing(-1),
  },
  searchFields: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}));

const VehicleSearchOption = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    hasVehicle: false,
  });
  const {
    licensePlate,
    stateProvince,
    maker,
    model,
    color,
    hasVehicle,
  } = state;

  const inputHandleChange = event => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const addSearchCriteria = () => {
    const vehicle = {
      id: uuid(),
      hasVehicle,
      licensePlate,
      stateProvince,
      maker,
      model,
      color,
    };

    props.onSearchCriteriaAdded(vehicle);
  };

  const clearSearchCriteria = () => {
    setState({
      ...state,
      hasVehicle: false,
      licensePlate: '',
      stateProvince: '',
      maker: '',
      model: '',
      color: '',
    });
  };

  const toggleHasVehicle = () => {
    setState({
      ...state,
      hasVehicle: !hasVehicle,
      licensePlate: hasVehicle ? licensePlate : '',
      stateProvince: hasVehicle ? stateProvince : '',
      maker: hasVehicle ? maker : '',
      model: hasVehicle ? model : '',
      color: hasVehicle ? color : '',
    });
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography variant="h6">Vehicle</Typography>
        <div className={classes.searchFields}>
          <FormControlLabel
            control={
              <Switch checked={state.hasPerson} onChange={toggleHasVehicle} />
            }
            label={<Typography variant="body1">Has vehicle</Typography>}
          />
        </div>
        <div className={classes.searchFields}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="licensePlate">License plate number</InputLabel>
            <Input
              id="licensePlate"
              name="licensePlate"
              autoFocus={!hasVehicle}
              value={hasVehicle ? '' : licensePlate}
              disabled={hasVehicle}
              onChange={inputHandleChange}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="stateProvince">State/Province</InputLabel>
            <Input
              id="stateProvince"
              name="stateProvince"
              value={hasVehicle ? '' : stateProvince}
              disabled={hasVehicle}
              onChange={inputHandleChange}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="maker">Maker</InputLabel>
            <Input
              id="maker"
              name="maker"
              value={hasVehicle ? '' : maker}
              disabled={hasVehicle}
              onChange={inputHandleChange}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="model">Model</InputLabel>
            <Input
              id="model"
              name="model"
              value={hasVehicle ? '' : model}
              disabled={hasVehicle}
              onChange={inputHandleChange}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="color">Color</InputLabel>
            <Input
              id="color"
              name="color"
              value={hasVehicle ? '' : color}
              disabled={hasVehicle}
              onChange={inputHandleChange}
            />
          </FormControl>
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            onClick={clearSearchCriteria}
          >
            Clear
          </Button>
        </div>
        <div className={classes.searchFields}>
          <FormControl className={classes.formControl}>
            <Button
              variant="contained"
              color="primary"
              className={classes.searchButton}
              onClick={addSearchCriteria}
            >
              Add Search Criteria
            </Button>
          </FormControl>
        </div>
      </div>
    </React.Fragment>
  );
};
VehicleSearchOption.propTypes = {
  onSearchCriteriaAdded: PropTypes.func.isRequired,
};

VehicleSearchOption.defaultProps = {
  onSearchCriteriaAdded: () => {},
};

export default VehicleSearchOption;
