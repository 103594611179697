import config from '../config';

export const AUTH_CONFIG = {
  domain: config.auth0.domain,
  clientId: config.auth0.clientId,
  callbackUrl: config.auth0.callbackUrl,
  audience: 'https://api.incidentxpress.com/',
};

export default {
  AUTH_CONFIG,
};
