import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from '../history';
import global from './global';
import common from './common';
import user from './user';
import administration from './administration';
import profiles from '../views/profile/ducks';

import newIncident from '../views/newIncident/ducks';
import dashboard from '../views/dashboard/ducks';
import incidentView from '../views/incidentView/ducks';
import search from '../views/search/ducks';
import analyze from '../views/analyze/ducks';
import signup from '../views/signup/ducks';
import reports from '../views/reports/ducks';
import login from '../views/login/ducks';

export default function createReducer(injectReducers = {}) {
  return combineReducers({
    global,
    common,
    user,
    newIncident,
    dashboard,
    incidentView,
    search,
    analyze,
    administration,
    profiles,
    signup,
    reports,
    login,
    router: connectRouter(history),
  });
}
