import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Box,
  Switch,
  FormControlLabel,
  Slider,
} from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { SubscriptionDef, PlanDef } from '../../../components/propTypeDefs';
import AlertDialog from '../../../components/dialogs/AlertDialog';
import auth from '../../../auth/Auth';
import {
  DefaultDateFormat,
  AccountSubscriptionTypeEnum,
} from '../../../constants';
import { displayCentAsDollar } from '../../../utils/currencyUtil';

const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    alignItems: 'center',
    // height: 80,
    flexWrap: 'wrap',
    padding: theme.spacing(2),
  },
  lineItem2: {
    display: 'flex',
    // flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  subscriptionItem: {
    padding: theme.spacing(1),
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardHeaderActive: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  selectedPlan: {
    border: theme.palette.primary.main,
    borderWidth: '2px',
    borderStyle: 'solid',
  },
  currentPlan: {
    backgroundColor: '#ffc',
  },
  currentCharge: {
    padding: theme.spacing(1),
    fontWeight: 600,
  },
  alertTextBold: {
    fontSize: 12,
  },
  itemButton: {
    marginLeft: theme.spacing(1),
  },
  concurrentUserSlider: {
    marginTop: theme.spacing(3),
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  planSwitch: {
    display: 'flex',
    alignItems: 'center',
  },
  cancelButton: {
    marginLeft: theme.spacing(1),
    color: 'white',
    backgroundColor: theme.palette.error.main,
  },
}));

const PlanSelector = props => {
  const classes = useStyles();
  const { subscription, plans, periodType, concurrentUserCount } = props;

  const getSubscriptionPrice = (
    subscriptionPlans,
    periodType,
    concurrentUserCount,
  ) => {
    if (!subscriptionPlans) {
      return 0;
    }

    if (periodType === 'YEAR') {
      return (
        subscriptionPlans.yearly.defaultAmount +
        subscriptionPlans.yearly.additionalAmount *
          Math.ceil((concurrentUserCount - 5) / 10)
      );
    }
    return (
      subscriptionPlans.monthly.defaultAmount +
      subscriptionPlans.monthly.additionalAmount *
        Math.ceil((concurrentUserCount - 5) / 10)
    );
  };

  const [state, setState] = React.useState({
    loading: false,
    savePlanAlertOpen: false,
    planChangeSuccessAlertOpen: false,
    price: 0,
    cacelSubscriptionAlertOpen: false,
    savePlanSuccessAlertOpen: false,
  });

  React.useEffect(() => {
    if (!subscription) return;
    setState({
      ...state,
      price: getSubscriptionPrice(plans, periodType, concurrentUserCount),
    });
  }, [subscription]);

  const handleSave = _.debounce(() => {
    if (!subscription) {
      return;
    }

    if (!subscription.cardLast4) {
      props.enqueueError('Please submit a payment method first.');
    }
    setState({ ...state, savePlanAlertOpen: true });
  }, 200);

  const handleSavePlan = () => {
    setState({ ...state, loading: true });
    const { concurrentUserCount } = state;
    props
      .saveSubscription(periodType, concurrentUserCount, props.promotionCode)
      .then(() => {
        setState({
          ...state,
          loading: false,
          savePlanAlertOpen: false,
          savePlanSuccessAlertOpen: true,
        });
      })
      .catch(err => {
        setState({ ...state, loading: false });
      });
  };

  const handleSavePlanDebounce = _.debounce(() => {
    handleSavePlan();
  }, 500);

  const changePeriodType = event => {
    const periodType = event.target.checked ? 'YEAR' : 'MONTH';
    props.onChangePeriodType(periodType);
  };

  const changeConcurrentUser = (event, value) => {
    props.onChangeConcurrentUser(value);
  };

  const handleCancelSubscription = () => {
    setState({ ...state, loading: true });
    props
      .cancelSubscription()
      .then(() => {
        setState({
          ...state,
          loading: false,
          cacelSubscriptionAlertOpen: false,
        });
      })
      .catch(err => {
        props.enqueueError(err);
        setState({ ...state, loading: false });
      });
  };

  const handleCancelSubscriptionDebounce = _.debounce(() => {
    handleCancelSubscription();
  }, 500);

  const subscriptionType = subscription && subscription.subscriptionType;

  const getPlanTitle = () => {
    let title = state.periodType === 'YEAR' ? 'ANNUAL PLAN' : 'MONTHLY PLAN';
    if (props.promotionCode) {
      title += ` (Promotion: ${props.promotionCode})`;
    }
    return title;
  };

  const price = getSubscriptionPrice(
    plans,
    props.periodType,
    props.concurrentUserCount,
  );

  return (
    <>
      <div>
        {subscriptionType !== AccountSubscriptionTypeEnum.manual && (
          <div>
            <div className={classes.lineItem2}>
              <FormControlLabel
                className={classes.formControlLong}
                control={
                  <div className={classes.planSwitch}>
                    <Typography variant="h6">Monthly</Typography>
                    <Switch
                      checked={props.periodType === 'YEAR'}
                      onChange={changePeriodType}
                    />
                    <Typography variant="h6">Annual</Typography>
                  </div>
                }
              />
            </div>
            <div className={classes.lineItem2}>
              <Typography variant="h6" className={classes.subscriptionItem}>
                {`NUMBER OF CONCURRENT USERS: ${props.concurrentUserCount}`}
              </Typography>
              <Slider
                className={classes.concurrentUserSlider}
                defaultValue={5}
                value={props.concurrentUserCount}
                // valueLabelFormat={value => {
                //   return `${value}`;
                // }}
                // // getAriaValueText={value => `${value}`}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="on"
                onChange={changeConcurrentUser}
                step={10}
                min={5}
                max={205}
              />
            </div>
            <Card>
              <CardActionArea>
                <CardHeader
                  title={getPlanTitle()}
                  titleTypographyProps={{
                    align: 'center',
                  }}
                  className={classes.cardHeaderActive}
                />
                <CardContent className={classes.currentPlan}>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      {displayCentAsDollar(price)}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      {props.periodType === 'YEAR' ? '/year' : '/mo'} + TAX
                    </Typography>
                  </div>
                  <ul>
                    {/* <Typography component="li" variant="subtitle1" align="center">
                  Save 50%
                </Typography> */}
                    <Typography
                      component="li"
                      variant="subtitle1"
                      align="center"
                    >
                      {`${props.concurrentUserCount} concurrent users`}
                    </Typography>
                  </ul>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
        )}
      </div>

      <AlertDialog
        title="Save plan"
        message="Are you sure you want to change your subscription plan?"
        open={state.savePlanAlertOpen}
        loading={state.loading}
        buttonDisabled={state.loading}
        onClose={() => {
          // do not close by click out of view
        }}
        onCancel={() => {
          setState({ ...state, savePlanAlertOpen: false });
        }}
        onOk={() => {
          handleSavePlanDebounce();
        }}
      />
      <AlertDialog
        title="Logout required"
        message="Subscription plan is successfully changed. Please log out and log in again to apply the new plan."
        open={state.savePlanSuccessAlertOpen}
        cancelButtonHidden
        onClose={() => {
          // do not close by click out of view
        }}
        onOk={() => {
          auth.logout();
        }}
      />
      <AlertDialog
        title="Cancel subscription"
        message={`Are you sure you want to cancel your subscription? If you cancel now, your subscription will expire on ${moment(
          subscription && subscription.nextChargeDate,
        ).format(
          DefaultDateFormat(props.companySettings.dateFormat).dateMoment,
        )}.`}
        open={state.cacelSubscriptionAlertOpen}
        loading={state.loading}
        buttonDisabled={state.loading}
        onClose={() => {
          // do not close by click out of view
        }}
        onCancel={() => {
          setState({ ...state, cacelSubscriptionAlertOpen: false });
        }}
        onOk={() => {
          handleCancelSubscriptionDebounce();
        }}
      />
    </>
  );
};

PlanSelector.propTypes = {
  subscription: SubscriptionDef,
  plans: PlanDef,
  concurrentUserCount: PropTypes.string.isRequired,
  onChangePeriodType: PropTypes.func.isRequired,
  onChangeConcurrentUser: PropTypes.func.isRequired,
};
PlanSelector.defaultProps = {};

const mapStateToProps = ({ administration, common }) => ({
  currentSubscription: administration.subscriptions.currentSubscription,
  companySettings: common.companySettings,
  promotionCode: administration.subscriptions.promotionCode,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(PlanSelector);
