import { isNullOrUndefined } from 'util';
import BaseApi from './BaseApi';

class DraftIncidentApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl += `/draftIncidents`;
  }

  async query(status) {
    const url = !isNullOrUndefined(status)
      ? `${this.baseUrl}?status=${status}`
      : this.baseUrl;
    return await this.get(url);
  }

  async create(model) {
    return await this.post(this.baseUrl, model);
  }

  async update(model) {
    return await this.put(this.baseUrl, model);
  }

  async remove(id) {
    return await this.delete(`${this.baseUrl}/${id}`);
  }

  async getById(id) {
    return await this.get(`${this.baseUrl}/${id}`);
  }
}

export default new DraftIncidentApi();
