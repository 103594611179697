import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  TableCell,
  TableBody,
  TableRow,
  IconButton,
  Table,
  TableHead,
  Box,
  CircularProgress,
} from '@material-ui/core';
import ViewIcon from '@material-ui/icons/Pageview';
import moment from 'moment';
import { loadIncidentHistory } from './ducks';
import IncidentHistoryViewDialog from './IncidentHistoryViewDialog';
import { IncidentHistoryDef } from '../../components/propTypeDefs';
import { dateFormat } from '../../utils/dateUtil';
import { DefaultDateFormat } from '../../constants';

const useStyles = makeStyles(theme => ({
  table: {
    maxWidth: 600,
    padding: theme.spacing(1),
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const LoadingProgress = props => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt={4}
    >
      <CircularProgress color="secondary" size={100} />
    </Box>
  );
};

const IncidentViewHistory = props => {
  const classes = useStyles();
  const [historyOpen, setHistoryOpen] = React.useState(false);
  const { incidentId, incidentHistory, loaded, loadHistory } = props;
  const [state, setState] = React.useState({
    open: false,
    selectedHistory: undefined,
  });

  // mounted
  React.useEffect(() => {
    loadHistory(incidentId);
  }, []);

  const closeHistoryDialog = () => {
    setState({ ...state, open: false, selectedHistory: undefined });
  };

  const openHistoryDialog = history => () => {
    setState({ ...state, open: true, selectedHistory: history });
  };

  return (
    <React.Fragment>
      {!loaded ? (
        <LoadingProgress />
      ) : (
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date modified</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {incidentHistory.map(incidentHistoryItem => (
              <TableRow key={incidentHistoryItem.id}>
                <TableCell component="th" scope="row">
                  {dateFormat(
                    incidentHistoryItem.updateDate,
                    DefaultDateFormat(props.companySettings.dateFormat)
                      .datetimeMoment,
                  )}
                </TableCell>
                <TableCell>{incidentHistoryItem.actionType}</TableCell>
                <TableCell>{incidentHistoryItem.updateUserName}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    aria-label="Open"
                    className={classes.margin}
                    onClick={openHistoryDialog(incidentHistoryItem)}
                  >
                    <ViewIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {state.open && (
        <IncidentHistoryViewDialog
          open={state.open}
          historyId={state.selectedHistory.id}
          onClose={closeHistoryDialog}
        />
      )}
    </React.Fragment>
  );
};
IncidentViewHistory.propTypes = {
  incidentId: PropTypes.string.isRequired,
  incidentHistory: PropTypes.arrayOf(IncidentHistoryDef).isRequired,
  loadHistory: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
};

IncidentViewHistory.defaultProps = {};

const mapStateToProps = ({ incidentView, common }) => ({
  incidentHistory: incidentView.incidentHistory,
  loaded: incidentView.historyLoaded,
  companySettings: common.companySettings,
});

const mapDispatchToProps = {
  loadHistory: loadIncidentHistory,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncidentViewHistory);
