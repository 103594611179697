import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import BaseDialog from './BaseDialog';

const AlertDialogYesNo = props => {
  const { title, message } = props;
  return (
    <BaseDialog
      open={props.open}
      title={title}
      maxWidth={props.maxWidth}
      onClose={props.onClose}
      actionContent={
        <>
          <Button
            onClick={props.onYes}
            variant="contained"
            color="primary"
            autoFocus
          >
            Yes
          </Button>
          <Button onClick={props.onNo} color="primary" autoFocus>
            No
          </Button>
        </>
      }
    >
      {message}
    </BaseDialog>
  );
};

AlertDialogYesNo.propTypes = {
  onClose: PropTypes.func,
  onNo: PropTypes.func,
  onYes: PropTypes.func,
  title: PropTypes.string,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  open: PropTypes.bool.isRequired,
};

AlertDialogYesNo.defaultProps = {
  onClose: () => {},
  onNo: () => {},
  onYes: () => {},
  maxWidth: 'xs',
};

export default AlertDialogYesNo;
