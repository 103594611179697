import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Chip } from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { AnalyzeFrequencyType } from '../../constants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  paper: {
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  group: {
    margin: 0,
  },
  label: {
    marginLeft: theme.spacing(-1),
  },
  searchCriteria: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  chip: {
    margin: theme.spacing(1),
    textTransform: 'capitalize',
  },
}));

const ReportTitleCriteria = props => {
  const classes = useStyles();

  const handleClick = () => {
    props.onClick();
  };

  return (
    <React.Fragment>
      {props.reportTitle && (
        <div className={classes.searchCriteria} hidden={!props.reportTitle}>
          <Typography variant="h6">Title: </Typography>
          <Chip
            label={props.reportTitle}
            onClick={handleClick}
            className={classes.chip}
            color="secondary"
          />
        </div>
      )}
    </React.Fragment>
  );
};

ReportTitleCriteria.propTypes = {
  reportTitle: PropTypes.string,
  onClick: PropTypes.func,
};

ReportTitleCriteria.defaultProps = {
  reportTitle: undefined,
  onClick: () => {},
};

export default ReportTitleCriteria;
