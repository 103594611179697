import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import * as yup from 'yup';
import {
  Button,
  TextField,
  FormControl,
  Fab,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import { Search as SearchIcon, Close as CloseIcon } from '@material-ui/icons';
import BaseDialog from './BaseDialog';
import { useForm, useMediaInfo } from '../../utils/hooks';
import personApi from '../../apis/person.api';
import PersonList from '../../views/newIncident/PersonList';
import { PersonDef } from '../propTypeDefs';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 600,
    flexGrow: 1,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  bottomMargin: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // height: 80,
    flexWrap: 'wrap',
  },
  buttonSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    // alignItems: 'center',
    // height: 80,
    flexWrap: 'wrap',
  },
  cardDialogTitle: {
    display: 'flex',
  },
  cardDialogTitleText: {
    flexGrow: 1,
  },
}));

const SearchPersonDialog = props => {
  const classes = useStyles();
  const { isSmaller } = useMediaInfo({ minBreakPoint: 'sm' });

  const [state, setState] = React.useState({
    searchName: undefined,
    invalidSearchForm: false,
    persons: props.persons,
  });

  const searchDialogTitle = () => {
    const titleText = 'Select Person';
    return (
      <div className={classes.cardDialogTitle}>
        <div className={classes.cardDialogTitleText}>{titleText}</div>
        <IconButton onClick={() => props.onClose()}>
          <CloseIcon />
        </IconButton>
      </div>
    );
  };

  const validateSearchForm = searchName => {
    const invalidSearchForm = !searchName;

    setState({ ...state, invalidSearchForm });
    return !invalidSearchForm;
  };

  const handleSearchChange = e => {
    const { name, value } = e.target;
    // const nextSearch = { ...state.search, [name]: value };
    setState({ ...state, searchName: value });
  };

  const handleSearch = _.debounce(name => {
    if (!validateSearchForm(state.searchName)) return;

    personApi.search(name).then(persons => {
      setState({
        ...state,
        persons: persons.data,
      });
    });
  }, 200);

  const inputKeyDown = event => {
    if (event.charCode === 13 || event.keyCode === 13) {
      const { name, value } = event.target;
      handleSearch(value);
    }
  };

  React.useEffect(() => {
    setState({
      ...state,
      persons: props.persons || [],
    });
  }, [props.persons]);

  const handlePersonItemCommand = (command, value) => {
    props.onPersonSelected(value);

    // const { id: personId, ...others } = value;
    // const exists = state.persons.some(x => x.personId === personId);
    // if (exists) {
    //   props.enqueueError(`'${getPersonName(value)}' already exists.`);
    //   return;
    // }

    // // remove null values
    // const compressed = _.omitBy(others, _.isNull);
    // const newPerson = { ...compressed, id: uuid(), personId, incidentId };
    // addPersonWithModel(newPerson);
  };

  return (
    <BaseDialog
      open={props.open}
      maxWidth="md"
      title={searchDialogTitle()}
      onClose={() => props.onClose()}
      fullScreen={isSmaller}
    >
      {props.type === 'ADD' && (
        <div className={classes.searchForm}>
          <FormControl className={classes.formControlLong}>
            <TextField
              autoFocus
              name="name"
              label="Person name"
              placeholder="Enter first, last or first and last name"
              onChange={handleSearchChange}
              onKeyDown={inputKeyDown}
              error={state.invalidSearchForm}
              helperText={
                state.invalidSearchForm
                  ? 'Please enter "Person name".'
                  : undefined
              }
            />
          </FormControl>
          <Fab
            size="small"
            color="secondary"
            className={classes.button}
            onClick={() => handleSearch(state.searchName)}
          >
            <SearchIcon />
          </Fab>
        </div>
      )}

      <div className={classes.personList}>
        <PersonList
          persons={state.persons || []}
          onItemCommand={handlePersonItemCommand}
          type={props.type}
        />
      </div>
    </BaseDialog>
  );
};

SearchPersonDialog.propTypes = {
  type: PropTypes.oneOf(['ADD', 'SELECT']),
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onPersonSelected: PropTypes.func.isRequired,
  persons: PropTypes.arrayOf(PersonDef),
};

SearchPersonDialog.defaultProps = {
  onCloseIncident: () => {},
  onClose: () => {},
  type: 'ADD',
  persons: undefined,
};

export default SearchPersonDialog;
