import BaseApi from './BaseApi';

class ReportApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl += '/reports';
  }

  async uploadReportData(id, data, auditTitle) {
    return await this.post(this.baseUrl, {
      id,
      data,
      auditTitle,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }

  async deleteReportData(id) {
    return this.delete(`${this.baseUrl}/${id}`);
  }

  async executeReport(id) {
    return await this.get(`${this.baseUrl}/custom/${id}`);
  }

  async createCustomReport(model) {
    return await this.post(`${this.baseUrl}/custom`, model);
  }

  async updateCustomReport(model) {
    return await this.put(`${this.baseUrl}/custom`, model);
  }

  async deleteCustomReport(id) {
    return await this.delete(`${this.baseUrl}/custom/${id}`);
  }

  async getCustomReports() {
    return await this.get(`${this.baseUrl}/custom`);
  }
}

export default new ReportApi();
