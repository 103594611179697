import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import userApi from '../../apis/user.api';
import siteAdmin from '../../apis/siteAdmin.api';
import { enqueueError } from '../../modules/global';

const PROFILE_LOADED = 'views/profile/PROFILE_LOADED';
const PROFILE_RESET = 'views/profile/PROFILE_RESET';
const PROFILE_UPDATED = 'views/profile/PROFILE_UPDATED';
const PROFILE_ERROR = 'views/user/PROFILE_ERROR';
const CHANGE_PASSWORD = 'views/user/CHANGE_PASSWORD';

const profileLoadAction = createAction(PROFILE_LOADED);
const profileUpdatedAction = createAction(PROFILE_UPDATED);
const resetAction = createAction(PROFILE_RESET);
const errorAction = createAction(PROFILE_ERROR);
const changePasswordAction = createAction(CHANGE_PASSWORD);

const initState = {
  loaded: false,
  profile: undefined,
};

export default handleActions(
  {
    [PROFILE_RESET]: (state, action) =>
      produce(state, draft => {
        draft.loaded = false;
      }),
    [PROFILE_LOADED]: (state, action) =>
      produce(state, draft => {
        draft.loaded = true;
        draft.profile = action.payload;
      }),
    [PROFILE_UPDATED]: (state, action) =>
      produce(state, draft => {
        draft.loaded = true;
        draft.profile = action.payload;
      }),
    [PROFILE_ERROR]: (state, action) =>
      produce(state, draft => {
        draft.loaded = true;
      }),
  },
  initState,
);

export const loadProfile = () => {
  return dispatch => {
    dispatch(resetAction());

    userApi
      .getProfile()
      .then(res => {
        dispatch(profileLoadAction(res.data));
      })
      .catch(err => {
        dispatch(errorAction(err));
        dispatch(enqueueError(err));
      });
  };
};

export const updateProfile = profile => {
  return dispatch => {
    dispatch(resetAction());

    userApi
      .updateProfile(profile)
      .then(res => {
        dispatch(profileUpdatedAction(profile));
      })
      .catch(err => {
        dispatch(errorAction(err));
        dispatch(enqueueError(err));
      });
  };
};

export const requestChangePassword = () => {
  return async dispatch => {
    userApi
      .requestChangePassword()
      .then(() => {})
      .catch(err => {
        dispatch(enqueueError(err));
      });
  };
};

export const restoreDemoSite = () => {
  return async dispatch => {
    try {
      await siteAdmin.restoreDemoSite();
    } catch (err) {
      dispatch(enqueueError(err));
      throw err;
    }
  };
};

export const syncQuickBooks = () => {
  return async dispatch => {
    try {
      const res = await siteAdmin.syncQuickBooks();
      return res.data;
    } catch (err) {
      dispatch(enqueueError(err));
      throw err;
    }
  };
};
