// import axios from 'axios';
import api from './axios';
import config from '../config';
// import queryString from 'query-string';
export default class BaseApi {
  constructor() {
    this.api = api;
    this.baseUrl = config.url.apiUrl;
  }

  async get(url, options) {
    return this.api.get(url, options);
  }

  async post(url, data, options) {
    return this.api.post(url, data, options);
  }

  async put(url, data) {
    return this.api.put(url, data);
  }

  async delete(url, options) {
    return this.api.delete(url, options);
  }
  /**
   *
   * @param {string} url
   * @param {QueryRequest} queryInfo
   */
  // async query(url, queryInfo) {
  // 	return this.api.get(`${url}?${queryString.stringify(queryInfo.toParams(), {})}`);
  // }
}
