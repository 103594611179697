import BaseApi from './BaseApi';

class NotificationsApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl += '/notifications';
  }

  async query() {
    return await this.get(this.baseUrl);
  }

  async dismiss(id) {
    return await this.delete(`${this.baseUrl}/${id}`);
  }
}

export default new NotificationsApi();
