import BaseApi from './BaseApi';

class AddressApi extends BaseApi {
  urlCity;

  urlCountry;

  urlAddress;

  constructor() {
    super();
    this.urlCity = `${this.baseUrl}/cities`;
    this.urlCountry = `${this.baseUrl}/countries`;
    this.urlAddress = `${this.baseUrl}/lookupAddresses`;
    this.urlState = `${this.baseUrl}/states`;
  }

  async queryAllCounties() {
    return await this.get(this.urlCountry);
  }

  async searchCity(params) {
    return await this.get(`${this.urlCity}/search`, { params });
  }

  async addAddress(model) {
    return await this.post(`${this.urlAddress}`, model);
  }

  async getAddress(id) {
    return await this.get(`${this.urlAddress}/${id}`);
  }

  async updateAddress(model) {
    return await this.put(`${this.urlAddress}`, model);
  }

  async removeAddress(id) {
    return await this.delete(`${this.urlAddress}/${id}`);
  }
}

export default new AddressApi();
