import uuid from 'uuid/v4';

export default class IncidentCompanyModel {
  constructor(id, name) {
    if (id) {
      this.id = id;
    } else {
      this.id = uuid();
    }
    this.name = name;
  }

  getName = () => {
    if (this.name) {
      return this.name;
    }

    return 'Unknown';
  };
}
