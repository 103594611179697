import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {
  FormControl,
  TextField,
  Typography,
  Button,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import _ from 'lodash';
import BaseDialog from '../../../components/dialogs/BaseDialog';
import { useForm } from '../../../utils/hooks';

const useStyles = makeStyles(theme =>
  createStyles({
    lineItem: {
      display: 'flex',
      // flexDirection: 'row',
      // alignItems: 'center',
      // height: 80,
      flexWrap: 'wrap',
    },
    addUserDialog: {
      width: '80%',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 180,
      // width: '100%',
    },
    formControlShort: {
      margin: theme.spacing(1),
      width: 150,
      // width: '100%',
    },
    formControlLong: {
      margin: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        minWidth: 400,
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    formControlFull: {
      margin: theme.spacing(1),
      width: '100%',
    },
    itemButton: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    deleteButton: {
      marginLeft: theme.spacing(1),
      backgroundColor: theme.palette.error.main,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
  }),
);

const UserGroupEdit = props => {
  const classes = useStyles();
  const { userGroup, onSaveUserGroup, onClose, ...other } = props;
  const [isAdmin, setIsAdmin] = React.useState(userGroup.isAdmin);
  const [userPrivileges, setUserPrivileges] = React.useState(
    userGroup.userPrivileges || [],
  );

  const schema = yup.object().shape({
    name: yup
      .string()
      .max(100)
      .required(),
  });

  const {
    formValues,
    updateFormValues,
    hasFormError,
    formHelpText,
    handleFormChange,
    handleFormBlur,
    handleFormSubmit,
  } = useForm({
    validationSchema: schema,
    onSubmit: (values, errors) => {
      if (_.isEmpty(errors)) {
        const model = {
          id: userGroup.id,
          name: values.name,
          isAdmin,
          userPrivileges: isAdmin
            ? []
            : userPrivileges.filter(
                x => x !== 'MYINCIDENT_DELETE' && x !== 'ALLINCIDENT_DELETE',
              ),
        };
        onSaveUserGroup(model);
      }
    },
  });

  React.useEffect(() => {
    updateFormValues({
      name: userGroup.name,
    });
  }, []);

  const inputHandleChange = event => {
    handleFormChange(event);
  };

  const switchHandleChange = nameValue => event => {
    if (isAdmin) return;
    if (event.target.checked) {
      userPrivileges.push(nameValue);
      setUserPrivileges(_.uniq(userPrivileges));
    } else {
      setUserPrivileges(userPrivileges.filter(x => x !== nameValue));
    }
  };

  const hasPrivilege = nameValue => {
    return isAdmin || userPrivileges.includes(nameValue);
  };
  const handleChangeAdmin = e => {
    setIsAdmin(e.target.checked);
  };

  const handleSubmit = () => {
    handleFormSubmit();
  };

  const debounceHandleSubmit = _.debounce(handleSubmit, 200);

  return (
    <BaseDialog
      {...other}
      title="EDIT WORKGROUP PRIVILEGES"
      actionContent={
        <>
          <Button
            variant="contained"
            onClick={debounceHandleSubmit}
            color="primary"
          >
            Save
          </Button>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
        </>
      }
    >
      <div className={classes.lineItem}>
        <FormControl className={classes.formControlLong}>
          <TextField
            required
            name="name"
            label="Name"
            value={formValues.name || ''}
            onChange={inputHandleChange}
            margin="none"
            onBlur={handleFormBlur}
            InputLabelProps={{
              shrink: true,
            }}
            error={hasFormError('name')}
            helperText={formHelpText('name')}
            inputProps={{ autoComplete: 0 }}
          />
        </FormControl>
        <FormControlLabel
          control={
            <Switch checked={isAdmin} onChange={handleChangeAdmin} value={1} />
          }
          label={
            <Typography variant="body1">
              Is admin? (Allow all privileges)
            </Typography>
          }
        />
      </div>

      <div className={classes.lineItem}>
        <Typography variant="h6">Users</Typography>
      </div>
      <div className={classes.lineItem}>
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('USER_ADD')}
              onChange={switchHandleChange('USER_ADD')}
              value="USER_ADD"
            />
          }
          label={<Typography variant="body1">Add user</Typography>}
        />
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('USER_EDIT')}
              onChange={switchHandleChange('USER_EDIT')}
              value="USER_EDIT"
            />
          }
          label={<Typography variant="body1">Edit user</Typography>}
        />
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('USER_DELETE')}
              onChange={switchHandleChange('USER_DELETE')}
              value="USER_DELETE"
            />
          }
          label={<Typography variant="body1">Delete user</Typography>}
        />
      </div>

      <div className={classes.lineItem}>
        <Typography variant="h6">Workgroups</Typography>
      </div>
      <div className={classes.lineItem}>
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('USERGROUP_ADD')}
              onChange={switchHandleChange('USERGROUP_ADD')}
              value="USERGROUP_ADD"
            />
          }
          label={<Typography variant="body1">Add group</Typography>}
        />

        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('USERGROUP_EDIT')}
              onChange={switchHandleChange('USERGROUP_EDIT')}
              value="USERGROUP_EDIT"
            />
          }
          label={<Typography variant="body1">Edit group</Typography>}
        />

        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('USERGROUP_DELETE')}
              onChange={switchHandleChange('USERGROUP_DELETE')}
              value="USERGROUP_DELETE"
            />
          }
          label={<Typography variant="body1">Delete group</Typography>}
        />
      </div>

      <div className={classes.lineItem}>
        <Typography variant="h6">Lookups</Typography>
      </div>
      <div className={classes.lineItem}>
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('LOOKUP_ADD')}
              onChange={switchHandleChange('LOOKUP_ADD')}
              value="LOOKUP_ADD"
            />
          }
          label={<Typography variant="body1">Add lookup</Typography>}
        />

        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('LOOKUP_EDIT')}
              onChange={switchHandleChange('LOOKUP_EDIT')}
              value="LOOKUP_EDIT"
            />
          }
          label={<Typography variant="body1">Edit lookup</Typography>}
        />
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('LOOKUP_DELETE')}
              onChange={switchHandleChange('LOOKUP_DELETE')}
              value="LOOKUP_DELETE"
            />
          }
          label={<Typography variant="body1">Delete lookup</Typography>}
        />
      </div>

      <div className={classes.lineItem}>
        <Typography variant="h6">My Incidents</Typography>
      </div>
      <div className={classes.lineItem}>
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('MYINCIDENT_ADD')}
              onChange={switchHandleChange('MYINCIDENT_ADD')}
              value="MYINCIDENT_ADD"
            />
          }
          label={<Typography variant="body1">Add incident</Typography>}
        />
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('MYINCIDENT_EDIT')}
              onChange={switchHandleChange('MYINCIDENT_EDIT')}
              value="MYINCIDENT_EDIT"
            />
          }
          label={<Typography variant="body1">Edit incident</Typography>}
        />
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('MYINCIDENT_PRINT')}
              onChange={switchHandleChange('MYINCIDENT_PRINT')}
              value="MYINCIDENT_PRINT"
            />
          }
          label={<Typography variant="body1">Print incident</Typography>}
        />
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('MYINCIDENT_SEARCH')}
              onChange={switchHandleChange('MYINCIDENT_SEARCH')}
              value="MYINCIDENT_SEARCH"
            />
          }
          label={<Typography variant="body1">Search incident</Typography>}
        />
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('MYINCIDENT_CLOSE')}
              onChange={switchHandleChange('MYINCIDENT_CLOSE')}
              value="MYINCIDENT_CLOSE"
            />
          }
          label={<Typography variant="body1">Close incident</Typography>}
        />
      </div>

      <div className={classes.lineItem}>
        <Typography variant="h6">All Incidents</Typography>
      </div>
      <div className={classes.lineItem}>
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('ALLINCIDENT_EDIT')}
              onChange={switchHandleChange('ALLINCIDENT_EDIT')}
              value="ALLINCIDENT_EDIT"
            />
          }
          label={<Typography variant="body1">Edit incident</Typography>}
        />

        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('ALLINCIDENT_PRINT')}
              onChange={switchHandleChange('ALLINCIDENT_PRINT')}
              value="ALLINCIDENT_PRINT"
            />
          }
          label={<Typography variant="body1">Print incident</Typography>}
        />
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('ALLINCIDENT_SEARCH')}
              onChange={switchHandleChange('ALLINCIDENT_SEARCH')}
              value="ALLINCIDENT_SEARCH"
            />
          }
          label={<Typography variant="body1">Search incident</Typography>}
        />
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('ALLINCIDENT_CLOSE')}
              onChange={switchHandleChange('ALLINCIDENT_CLOSE')}
              value="ALLINCIDENT_CLOSE"
            />
          }
          label={<Typography variant="body1">Close incident</Typography>}
        />
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('INVESTIGATE_INCIDENT')}
              onChange={switchHandleChange('INVESTIGATE_INCIDENT')}
              value="INVESTIGATE_INCIDENT"
            />
          }
          label={<Typography variant="body1">Investigate incident</Typography>}
        />
      </div>
      <div className={classes.lineItem}>
        <Typography variant="h6">Misc</Typography>
      </div>

      <div className={classes.lineItem}>
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('INCIDENT_ANALYSIS')}
              onChange={switchHandleChange('INCIDENT_ANALYSIS')}
              value="INCIDENT_ANALYSIS"
            />
          }
          label={<Typography variant="body1">Perform analysis</Typography>}
        />
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('REPORT_PRINT')}
              onChange={switchHandleChange('REPORT_PRINT')}
              value="REPORT_PRINT"
            />
          }
          label={<Typography variant="body1">Print report</Typography>}
        />
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('REPORT_CREATE')}
              onChange={switchHandleChange('REPORT_CREATE')}
              value="REPORT_CREATE"
            />
          }
          label={<Typography variant="body1">Create report</Typography>}
        />
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('PRIVACY_INFO_VIEW')}
              onChange={switchHandleChange('PRIVACY_INFO_VIEW')}
              value="PRIVACY_INFO_VIEW"
            />
          }
          label={
            <Typography variant="body1">View privacy information</Typography>
          }
        />
      </div>
      <div className={classes.lineItem}>
        <Typography variant="h6">Admin</Typography>
      </div>

      <div className={classes.lineItem}>
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('INCIDENT_LOCK')}
              onChange={switchHandleChange('INCIDENT_LOCK')}
              value="INCIDENT_LOCK"
            />
          }
          label={<Typography variant="body1">Lock incident</Typography>}
        />
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('AUDIT_VIEW')}
              onChange={switchHandleChange('AUDIT_VIEW')}
              value="AUDIT_VIEW"
            />
          }
          label={<Typography variant="body1">View audit</Typography>}
        />
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('OPEN_CLOSED_INCIDENT')}
              onChange={switchHandleChange('OPEN_CLOSED_INCIDENT')}
              value="OPEN_CLOSED_INCIDENT"
            />
          }
          label={<Typography variant="body1">Open closed incident</Typography>}
        />
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('SUBSCRIPTION_EDIT')}
              onChange={switchHandleChange('SUBSCRIPTION_EDIT')}
              value="SUBSCRIPTION_EDIT"
            />
          }
          label={<Typography variant="body1">Edit subscription</Typography>}
        />
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('DELETE_ATTACHMENT')}
              onChange={switchHandleChange('DELETE_ATTACHMENT')}
              value="DELETE_ATTACHMENT"
            />
          }
          label={<Typography variant="body1">Delete attachment</Typography>}
        />
        <FormControlLabel
          control={
            <Switch
              checked={hasPrivilege('INCIDENT_DELETE')}
              onChange={switchHandleChange('INCIDENT_DELETE')}
              value="INCIDENT_DELETE"
            />
          }
          label={<Typography variant="body1">Delete incident</Typography>}
        />
      </div>
    </BaseDialog>
  );
};

UserGroupEdit.propTypes = {
  userGroup: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    userPrivileges: PropTypes.arrayOf(PropTypes.string),
    isAdmin: PropTypes.bool,
  }).isRequired,
  onSaveUserGroup: PropTypes.func,
  onClose: PropTypes.func,
};

export default UserGroupEdit;
