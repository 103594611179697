import uuid from 'uuid/v4';
import IncidentPersonModel from './models/IncidentPersonModel';
import IncidentCompanyModel from './models/IncidentCompanyModel';
import IncidentVehicleModel from './models/IncidentVehicleModel';
import IncidentItemModel from './models/IncidentItemModel';
import IncidentAttachmentModel from './models/IncidentAttachmentModel';

export const TEST_JWT =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ik9UYzRNa1UwTnpBd1F6azVRek5HUXprMVJqSTFORFE1TTBGQk1rWkVOVEU0TVRCR00wVXhRdyJ9.eyJodHRwczovL2luY2lkZW50eHByZXNzLmNvbS9jdXN0b21fY2xhaW1zIjp7InVzZXJJZCI6IjI1ODc1YjA5LWI3OGEtNDE3OC1iMjA5LWZlNGEwZWNiYjdjOCIsImFjY291bnRJZCI6ImNiMjRlNDY2LThkYmQtNGVlNS1iYjc2LWFjMjg4M2M3MjBlMCIsInVzZXJHcm91cElkIjoiNjJmZDI4MDktM2FlMi00YjlmLThjYmItOTcxODQ5MzY5NDAwIiwic3RhdHVzIjoxLCJtZWFzdXJlbWVudFR5cGUiOjB9LCJpc3MiOiJodHRwczovL2luY2lkZW50eHByZXNzLmF1dGgwLmNvbS8iLCJzdWIiOiJnb29nbGUtb2F1dGgyfDEwMDczNDM3OTExNTY1MzA3OTQ3NSIsImF1ZCI6WyJodHRwczovL2luY2lkZW50eHByZXNzLmF1dGgwLmNvbS9hcGkvdjIvIiwiaHR0cHM6Ly9pbmNpZGVudHhwcmVzcy5hdXRoMC5jb20vdXNlcmluZm8iXSwiaWF0IjoxNTYxMzM5NjAzLCJleHAiOjE1NjEzNDY4MDMsImF6cCI6IjdWVG5IZkYxYXZlWWJHTWxGa3ljMXFOQWFzSWE2R2ZQIiwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSBlbWFpbCJ9.r1RTbTESVPn8zaCbAW1wX0qfQw92NhlnGgqVV1jGnAKyozLg9epxoVtbvpGQyoZHO4KBU10mfc-FSF9yOaQoVq4iq70oharOWyXCl6LYmqVtLAvcDraiCGE6KXpnUisCSLPSrUhe52CIp2ZT4D_R--nqnBaZbfDAD9NROTK-5pb7ywwGG_ONKoa4Q1LaAscLcrM1PVERh8XqR6gbb0sjtLA7kKSq9hXq3Er964sF5y01IuJoUZcYIaXCsG2fYJ6M6zOoZNKeM6NZmPCzT1wiUPZ8AaBOWyJBlLWqSulAxGexXR3NVQ45b1Wc-3doGJxBGvMrYqbmdPAqlWwXET3-fA';

export const TEST_JWT_ADMIN =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ik9UYzRNa1UwTnpBd1F6azVRek5HUXprMVJqSTFORFE1TTBGQk1rWkVOVEU0TVRCR00wVXhRdyJ9.eyJodHRwczovL2luY2lkZW50eHByZXNzLmNvbS9jdXN0b21fY2xhaW1zIjp7InVzZXJJZCI6IjI1ODc1YjA5LWI3OGEtNDE3OC1iMjA5LWZlNGEwZWNiYjdjOCIsImFjY291bnRJZCI6ImNiMjRlNDY2LThkYmQtNGVlNS1iYjc2LWFjMjg4M2M3MjBlMCIsInVzZXJHcm91cElkIjoiNjJmZDI4MDktM2FlMi00YjlmLThjYmItOTcxODQ5MzY5NDAwIiwic3RhdHVzIjoxLCJtZWFzdXJlbWVudFR5cGUiOjAsImFkbWluIjp0cnVlLCJwcml2aWxlZ2VzIjpudWxsfSwiaXNzIjoiaHR0cHM6Ly9pbmNpZGVudHhwcmVzcy5hdXRoMC5jb20vIiwic3ViIjoiZ29vZ2xlLW9hdXRoMnwxMDA3MzQzNzkxMTU2NTMwNzk0NzUiLCJhdWQiOlsiaHR0cHM6Ly9pbmNpZGVudHhwcmVzcy5hdXRoMC5jb20vYXBpL3YyLyIsImh0dHBzOi8vaW5jaWRlbnR4cHJlc3MuYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTU2MjU1MzU5MywiZXhwIjoxNTYyNTYwNzkzLCJhenAiOiI3VlRuSGZGMWF2ZVliR01sRmt5YzFxTkFhc0lhNkdmUCIsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwifQ.rEJBI0ED8m8pPodqyhNxdR8Q_mVtlXD0RZDtaRe1t6PlJmh3K3g3AoayO8oPMItaQkaYYH3P6Vp-ld1bFz1ha9x41LzkVedVcKFc05MKjYh_zlXSNQEujpZ7Nnhv6JO4Y1XhP68ltR2FjdwS877XLaVkm-kP4a2O6daO6xcKG5vko3XGkYsKpwvsDG5Y7l__NWveRs3eFZU1JGMW3n-Gk1fje2EOi_kutikwHnKGnderr_Aiee_4kiIiYC4hI6ZaEF8Blvdwv4MMmSBo82RGWGQ7-tTT7aGlvMK2_wL5p-pgHfNdIiNXbAQX_gDty9UqiIUKSBR7ldPRDCFE8-5A5Q';
export const TEST_JWT_NOT_ADMIN =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ik9UYzRNa1UwTnpBd1F6azVRek5HUXprMVJqSTFORFE1TTBGQk1rWkVOVEU0TVRCR00wVXhRdyJ9.eyJodHRwczovL2luY2lkZW50eHByZXNzLmNvbS9jdXN0b21fY2xhaW1zIjp7InVzZXJJZCI6IjY0YTE5ZjU0LTdhNWMtNDA1OS05YmIwLWNjMzQ3OGVhMzNkMCIsImFjY291bnRJZCI6ImNiMjRlNDY2LThkYmQtNGVlNS1iYjc2LWFjMjg4M2M3MjBlMCIsInVzZXJHcm91cElkIjoiNWU4MjFkMTctZWI2MS00ZjVlLTg1NjItYjU1MjRhZmFmOGY5Iiwic3RhdHVzIjoxLCJtZWFzdXJlbWVudFR5cGUiOjAsImFkbWluIjpmYWxzZSwicHJpdmlsZWdlcyI6Ik1ZSU5DSURFTlRfQURELE1ZSU5DSURFTlRfQ0xPU0UsTVlJTkNJREVOVF9ERUxFVEUsTVlJTkNJREVOVF9FRElULE1ZSU5DSURFTlRfUFJJTlQsTVlJTkNJREVOVF9TRUFSQ0gsTVlJTkNJREVOVF9WSUVXIn0sImlzcyI6Imh0dHBzOi8vaW5jaWRlbnR4cHJlc3MuYXV0aDAuY29tLyIsInN1YiI6ImF1dGgwfDVjZTg5ZjNhNDdiN2RkMGY1OTNjZjI5MSIsImF1ZCI6WyJodHRwczovL2luY2lkZW50eHByZXNzLmF1dGgwLmNvbS9hcGkvdjIvIiwiaHR0cHM6Ly9pbmNpZGVudHhwcmVzcy5hdXRoMC5jb20vdXNlcmluZm8iXSwiaWF0IjoxNTYyNTU2MzQ4LCJleHAiOjE1NjI1NjM1NDgsImF6cCI6IjdWVG5IZkYxYXZlWWJHTWxGa3ljMXFOQWFzSWE2R2ZQIiwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSBlbWFpbCJ9.ZSI8EgbZR0JOq8QzKtipl__jJSBHX7s4w_qD4vIyOCGdazoKFzAW7GJqrceKU8qngwqteF-tXueMFdBF0XncgjlV-DcVCkYrHoIj89NlTfXWagK-2sCHrZPjgtiGxsQJKkjbrEDOKsn-rNjBXFNCSdZtuc7Gz0rTXxNl8vHeI-E2AAo3APHd-qz-semgSddT7WWRbInpYvf1G6W1J7WJyLed6d_sqLEJYRZtdwjEkiAe44PL-q_yJtMyw9X4Zw1PIJkB3zm0GPpKk_GMdmFTlXvAaDjICWuYQm5i5A1ftX4Wi6Wk0ed2MLnaLmMA0Aoa5WFr0Fwm7HgYTW7oi-rSjQ';
export const classOneValues = [{ label: 'Crime' }, { label: 'Safety' }].map(
  suggestion => ({
    value: suggestion.label,
    label: suggestion.label,
  }),
);

export const classTwoValues = [
  { label: 'Sexual Assault' },
  { label: 'Assault' },
].map(suggestion => ({
  value: suggestion.label,
  label: suggestion.label,
}));

export const classThreeValues = [
  { label: 'Employee' },
  { label: 'Visitor' },
  { label: 'Patient' },
].map(suggestion => ({
  value: suggestion.label,
  label: suggestion.label,
}));

export const classFourValues = [
  { label: 'By Employee' },
  { label: 'By Visitor' },
  { label: 'By Patient' },
].map(suggestion => ({
  value: suggestion.label,
  label: suggestion.label,
}));

export const buildingValues = [
  { label: 'ATB Place' },
  { label: 'Century Park Station' },
  { label: 'Telus Building' },
  { label: 'South Gate Station' },
  { label: 'Central Station' },
].map(suggestion => ({
  value: suggestion.label,
  label: suggestion.label,
}));

export const cityValues = [
  { label: 'Edmonton' },
  { label: 'Calgary' },
  { label: 'Red Deer' },
].map(suggestion => ({
  value: suggestion.label,
  label: suggestion.label,
}));

export const provinceValues = [
  { label: 'AB' },
  { label: 'OZ' },
  { label: 'BC' },
  { label: 'SK' },
].map(suggestion => ({
  value: suggestion.label,
  label: suggestion.label,
}));

export const countryValues = [{ label: 'CA' }, { label: 'US' }].map(
  suggestion => ({
    value: suggestion.label,
    label: suggestion.label,
  }),
);

export const draftIncidents = [
  {
    id: uuid(),
    number: '00001',
    dateReported: '2019-03-01 11:19:00',
    dateOccurred: '2019-03-01 08:09:00',
    class: 'Crime/Assault/Visitor/By employee',
    location: 'Century Park Station',
    reporter: 'Danny Oh',
    dateCreated: '2019-03-01 11:19:00',
    dateModified: '2019-03-02 11:19:00',
  },
  {
    id: uuid(),
    number: '00002',
    dateReported: '2019-03-01 11:19:00',
    dateOccurred: '2019-03-01 08:09:00',
    class: 'Crime/Assault/Visitor/By employee',
    location: 'Century Park Station',
    reporter: 'Danny Oh',
    dateCreated: '2019-03-02 11:19:00',
    dateModified: '2019-03-02 11:19:00',
  },
  {
    id: uuid(),
    number: '00003',
    dateReported: '2019-03-01 11:19:00',
    dateOccurred: '2019-03-01 08:09:00',
    class: 'Crime/Assault/Visitor/By employee',
    location: 'Century Park Station',
    reporter: 'Danny Oh',
    dateCreated: '2019-03-11 11:19:00',
    dateModified: '2019-03-12 23:00:00',
  },
];

export const incidentSearchResult = [
  {
    id: uuid(),
    number: '00001',
    dateReported: '2019-03-01 11:19:00',
    dateOccurred: '2019-03-01 08:09:00',
    class: 'Crime/Assault/Visitor/By employee',
    location: 'Century Park Station',
    reporter: 'Danny Oh',
  },
  {
    id: uuid(),
    number: '00002',
    dateReported: '2019-03-01 11:19:00',
    dateOccurred: '2019-03-01 08:09:00',
    class: 'Crime/Assault/Visitor/By employee',
    location: 'Century Park Station',
    reporter: 'Danny Oh',
  },
  {
    id: uuid(),
    number: '00003',
    dateReported: '2019-03-01 11:19:00',
    dateOccurred: '2019-03-01 08:09:00',
    class: 'Crime/Assault/Visitor/By employee',
    location: 'Century Park Station',
    reporter: 'Danny Oh',
  },
  {
    id: uuid(),
    number: '00004',
    dateReported: '2019-03-01 11:19:00',
    dateOccurred: '2019-03-01 08:09:00',
    class: 'Crime/Assault/Visitor/By employee',
    location: 'Century Park Station',
    reporter: 'Danny Oh',
  },
  {
    id: uuid(),

    number: '00005',
    dateReported: '2019-03-01 11:19:00',
    dateOccurred: '2019-03-01 08:09:00',
    class: 'Crime/Assault/Visitor/By employee',
    location: 'Century Park Station',
    reporter: 'Danny Oh',
  },
  {
    id: uuid(),
    number: '00006',
    dateReported: '2019-03-01 11:19:00',
    dateOccurred: '2019-03-01 08:09:00',
    class: 'Crime/Assault/Visitor/By employee',
    location: 'Century Park Station',
    reporter: 'Danny Oh',
  },
  {
    id: uuid(),
    number: '00007',
    dateReport: '2019-03-01 11:19:00',
    dateOccurred: '2019-03-01 08:09:00',
    class: 'Crime/Assault/Visitor/By employee',
    location: 'Century Park Station',
    reporter: 'Danny Oh',
  },
];

export const incidentPersons = [
  new IncidentPersonModel(uuid(), 'Person 1', ''),
  new IncidentPersonModel(uuid(), 'Danny', 'Oh'),
];

export const incidentCompanies = [
  new IncidentCompanyModel(uuid(), 'Starbucks #34'),
  new IncidentCompanyModel(uuid(), `McDonald's 100 ave 100 st`),
];

export const incidentVehicles = [
  new IncidentVehicleModel(uuid(), 'ABC 0099'),
  new IncidentVehicleModel(uuid(), 'XYZ 8899'),
];

export const incidentItems = [
  new IncidentItemModel(uuid(), 'iPhone 8'),
  new IncidentItemModel(uuid(), 'Mac Book Air'),
];

export const incidentAttachements = [
  new IncidentAttachmentModel(
    uuid(),
    'photo 1',
    'attachment description',
    'https://www.canadianbusiness.com/wp-content/uploads/2017/09/SEP13_PROFIT_500_REGIONAL_RANKINGS_EDMONTON_CANADA_POST.jpg',
    'image',
  ),
  new IncidentAttachmentModel(
    uuid(),
    'movie 1',
    'attachment description',
    'https://www.canadianbusiness.com/wp-content/uploads/2017/09/SEP13_PROFIT_500_REGIONAL_RANKINGS_EDMONTON_CANADA_POST.jpg',
    'movie',
  ),
  new IncidentAttachmentModel(
    uuid(),
    'file 1',
    'attachment description',
    'https://www.canadianbusiness.com/wp-content/uploads/2017/09/SEP13_PROFIT_500_REGIONAL_RANKINGS_EDMONTON_CANADA_POST.jpg',
    'file',
  ),
];

export const incidentHistoryItems = [
  {
    dateModified: '2019-03-01 11:19:00',
    type: 'Create',
    user: 'Danny Oh',
  },
  {
    dateModified: '2019-03-01 12:19:00',
    type: 'Edit',
    user: 'Danny Oh',
  },
  {
    dateModified: '2019-03-02 14:00:00',
    type: 'Edit',
    user: 'Danny Oh',
  },
  {
    dateModified: '2019-03-03 12:19:00',
    type: 'Close',
    user: 'Danny Oh',
  },
];
