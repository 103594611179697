import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {
  Button,
  Avatar,
  Fab,
  Popper,
  Paper,
  Grow,
  MenuItem,
  MenuList,
  ClickAwayListener,
} from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import auth from '../auth/Auth';
import { getMenu } from '../Menu';
import { useMediaInfo } from '../utils/hooks';

const crypto = require('crypto');
const logo = require('../images/logoFull.png');

const useStyle = makeStyles(theme => ({
  appBar: {
    position: 'sticky',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grey['100']}`,
    // backgroundColor: 'white',
    backgroundColor: theme.palette.common.white,
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  inline: {
    display: 'flex',
    alignItems: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    // flexGrow: 1,
    fontSize: 24,
  },
  left: {
    flex: 1,
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    justifyContent: 'flex-center',
  },
  button: {
    margin: theme.spacing(1),
  },
  productLogo: {
    display: 'inline-block',
    borderLeft: `1px solid ${theme.palette.grey.A100}`,
    marginLeft: 32,
    paddingLeft: 24,
    [theme.breakpoints.up('md')]: {
      paddingTop: '1.5em',
    },
  },
  tagline: {
    display: 'inline-block',
    marginLeft: 10,
    [theme.breakpoints.up('md')]: {
      paddingTop: '0.8em',
    },
  },
  iconContainer: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  iconButton: {
    float: 'right',
  },
  tabContainer: {
    marginLeft: 32,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tabItem: {
    paddingTop: 20,
    paddingBottom: 20,
    minWidth: 'auto',
  },
  tabLabel: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  avatarContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    alignItems: 'center',
  },
  avatar: {
    // margin: 10,
    width: 48,
    height: 48,
  },
}));

const Topbar = props => {
  const classes = useStyle();
  const { isSmaller, isMobile } = useMediaInfo({
    minBreakPoint: 'md',
  });

  const [state, setState] = React.useState({
    value: 0,
    menuDrawer: false,
    avatarMenuOpen: false,
    menuItems: [],
    anchorEl: null,
    gravatarImage: undefined,
  });
  const isAuthenticated = auth.isAuthenticated;
  const isInvalidStatus = auth.isInvalidStatus;

  const handleChange = (event, value) => {
    setState({ ...state, value });
  };

  const mobileMenuOpen = event => {
    setState({ ...state, menuDrawer: true });
  };

  const mobileMenuClose = event => {
    setState({ ...state, menuDrawer: false });
  };

  const handleToggle = event => {
    setState({
      ...state,
      avatarMenuOpen: !state.avatarMenuOpen,
      anchorEl: event.currentTarget,
    });
  };

  const handleClose = event => {
    if (state.anchorEl.contains(event.target)) {
      return;
    }

    setState({ ...state, avatarMenuOpen: false });
  };

  const handleLink = url => {
    props.history.push('/profile');
  };

  const current = () => {
    const menuIndex = _.findIndex(state.menuItems, menu => {
      return menu.pathname === props.location.pathname;
    });

    if (menuIndex >= 0) {
      return menuIndex;
    }

    return 0; // home
  };

  const signup = () => {
    props.history.push('/signup');
  };

  const login = () => {
    auth.login();
  };

  const logout = () => {
    auth.logout();
  };

  const setMenuItems = () => {
    const menuItems = getMenu(isMobile);
    setState({ ...state, menuItems });
  };

  const { currentUser } = auth;
  const gravatarHash = isAuthenticated
    ? crypto
        .createHash('md5')
        .update(currentUser.email)
        .digest('hex')
    : undefined;

  const [gravatarImage, setGravatarImage] = React.useState(
    `https://www.gravatar.com/avatar/${gravatarHash}?d=mp`,
  );

  const navigateMenu = menu => () => {
    mobileMenuClose();
    props.history.push(menu);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    setMenuItems();
  }, [isMobile]);

  const unauthenticatedToolbar = () => (
    <Toolbar className={classes.toolbar}>
      <div className={classes.left} />
      <Link key="signup" to="/" className={classes.link}>
        <img width={180} height={61} src={logo} alt="" />
      </Link>
      <div className={classes.right}>
        {!auth.isInvalidStatus && (
          <>
            {!isMobile && (
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={signup}
              >
                SIGN UP
              </Button>
            )}
            <Button color="primary" className={classes.button} onClick={login}>
              SIGN IN
            </Button>
          </>
        )}
      </div>
    </Toolbar>
  );

  const authenticatedToolbar = () => (
    <Toolbar className={classes.toolbar}>
      <Grid container alignItems="baseline">
        <Grid item xs={12} className={classes.flex}>
          <div className={classes.inline}>
            <Link to="/" className={classes.link}>
              <img width={180} height={61} src={logo} alt="" />
            </Link>
          </div>
          {!props.noTabs && (
            <React.Fragment>
              {/* <div className={classes.productLogo}>
                <Typography>Logo here</Typography>
              </div> */}
              <div className={classes.iconContainer}>
                <IconButton
                  onClick={mobileMenuOpen}
                  className={classes.iconButton}
                  color="inherit"
                  aria-label="Menu"
                >
                  <MenuIcon />
                </IconButton>
              </div>
              <div className={classes.tabContainer}>
                <SwipeableDrawer
                  anchor="right"
                  open={state.menuDrawer}
                  onClose={mobileMenuClose}
                  onOpen={mobileMenuOpen}
                >
                  <AppBar title="Menu" />
                  <List>
                    {state.menuItems.map((item, index) => (
                      <ListItem
                        onClick={navigateMenu({
                          pathname: item.pathname,
                          search: props.location.search,
                        })}
                        button
                        key={item.label}
                      >
                        <ListItemText primary={item.label} />
                      </ListItem>
                    ))}
                    {isMobile && (
                      <ListItem button onClick={logout}>
                        <ListItemText primary="Logout" />
                      </ListItem>
                    )}
                  </List>
                </SwipeableDrawer>
                <Tabs
                  value={current() || state.value}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChange}
                >
                  {state.menuItems.map((item, index) => (
                    <Tab
                      data-testid={`testid-${item.label}`}
                      key={item.label}
                      component={Link}
                      to={{
                        pathname: item.pathname,
                        search: props.location.search,
                      }}
                      classes={{
                        root: classes.tabItem,
                      }}
                      label={item.label}
                    />
                  ))}
                </Tabs>
              </div>
              <div className={classes.avatarContainer}>
                <Tab
                  data-testid="testid-help-center"
                  key="help-center"
                  component={Button}
                  target="incidentXpressHelpCenter"
                  href="http://help.incidentxpress.com"
                  classes={{
                    root: classes.tabItem,
                  }}
                  label="Help Center"
                ></Tab>
                <Fab
                  size="medium"
                  className={classes.fab}
                  // buttonRef={node => {
                  //   anchorEl = node;
                  // }}
                  aria-owns={
                    state.avatarMenuOpen ? 'menu-list-grow' : undefined
                  }
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  <Avatar src={auth.picture} className={classes.avatar} />
                </Fab>
                <Popper
                  open={state.avatarMenuOpen}
                  anchorEl={state.anchorEl}
                  transition
                  disablePortal
                  placement="bottom-end"
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      id="menu-list-grow"
                      style={{
                        transformOrigin:
                          placement === 'bottom-end'
                            ? 'center top'
                            : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList>
                            <MenuItem onClick={() => handleLink('/profile')}>
                              Profile
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                logout();
                                // props.history.push('/');
                              }}
                            >
                              Logout
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </Toolbar>
  );

  return (
    <AppBar position="fixed" color="default" className={classes.appBar}>
      {isAuthenticated
        ? authenticatedToolbar(classes)
        : unauthenticatedToolbar(classes)}
    </AppBar>
  );
};

Topbar.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  noTabs: PropTypes.bool,
};

export default withRouter(Topbar);
