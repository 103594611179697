import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MainContentFirstLayout from './MainContentFirstLayout';
// import Typography from '../components/Typography';

const productValues1 = require('../../images/productValues1.svg');
const productValues2 = require('../../images/productValues2.svg');
const productValues3 = require('../../images/productValues3.svg');

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
  },
  layoutBody: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `0px ${theme.spacing(5)}px`,
    color: '#000',
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

function MainContentSecond(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <MainContentFirstLayout className={classes.layoutBody} width="large">
        <img
          src="/static/themes/onepirate/productCurvyLines.png"
          className={classes.curvyLines}
          alt="curvy lines"
        />
        <Grid container spacing={10}></Grid>
      </MainContentFirstLayout>
    </section>
  );
}

MainContentSecond.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MainContentSecond);
