import BaseApi from './BaseApi';
import { downloadByBlobResponse } from '../utils/fileUtil';

class AnalyzeApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl += '/analysis';
  }

  async analyze(criteria, title, type) {
    if (type === 'excel') {
      const res = await this.post(
        `${this.baseUrl}?type=excel&auditTitle=${title}`,
        criteria,
        {
          responseType: 'blob',
        },
      );
      downloadByBlobResponse(res);
    }
    return await this.post(`${this.baseUrl}?auditTitle=${title}`, criteria);
  }

  async saveCustom(model) {
    return await this.post(`${this.baseUrl}/custom`, model);
  }

  async updateCustom(model) {
    return await this.put(`${this.baseUrl}/custom`, model);
  }

  async deleteCustom(id) {
    return await this.delete(`${this.baseUrl}/custom/${id}`);
  }

  async getCustoms() {
    return await this.get(`${this.baseUrl}/custom`);
  }

  async saveReport(model) {
    return await this.post(`${this.baseUrl}/reports`, model);
  }

  async updateReport(model) {
    return await this.put(`${this.baseUrl}/reports`, model);
  }

  async deleteReport(id) {
    return await this.delete(`${this.baseUrl}/reports/${id}`);
  }

  async getReports() {
    return await this.get(`${this.baseUrl}/reports`);
  }
}

export default new AnalyzeApi();
