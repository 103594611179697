import uuid from 'uuid/v4';

export default class IncidentVehicleModel {
  constructor(id, incidentId) {
    if (id) {
      this.id = id;
    } else {
      this.id = uuid();
    }
    this.incidentId = incidentId;
    this.maker = '';
    this.model = '';
    this.color = '';
  }

  getName = () => {
    const { licensePlate, maker, model, color, involvementId } = this;
    let vehicleName = '';
    const modelName = `${maker} ${model} ${color}`.trim();

    if (modelName && licensePlate) {
      vehicleName = `${modelName} (${licensePlate})`.trim();
    } else if (modelName) {
      vehicleName = modelName;
    } else if (licensePlate) {
      vehicleName = licensePlate;
    }

    if (vehicleName && involvementId) {
      vehicleName = `${vehicleName} - ${involvementId}`.trim();
    } else if (involvementId) {
      vehicleName = `Unknown - ${involvementId}`.trim();
    }

    if (vehicleName) {
      return vehicleName;
    }

    return 'Unknown';
  };
}
