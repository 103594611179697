import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import * as yup from 'yup';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import BaseDialog from './BaseDialog';
import Auth from '../../auth/Auth';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 600,
    flexGrow: 1,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  time: {
    marginLeft: theme.spacing(1),
  },
}));

let timer;

const AutoLogoutDialog = props => {
  const classes = useStyles();
  const [endTime, setEndTime] = React.useState(undefined);

  const calculateRemainSeconds = t => {
    if (!t) return undefined;
    const duration = moment.duration(moment(t).diff(moment()));
    const seconds = duration.asSeconds();
    return Math.ceil(seconds);
  };
  const [update, setUpdate] = React.useState(new Date().valueOf());

  const stopTimer = () => {
    if (timer) {
      clearTimeout(timer);
      timer = undefined;
    }
    setEndTime(undefined);
  };

  const startTimer = t => {
    timer = setInterval(() => {
      if (calculateRemainSeconds(t) <= 0) {
        stopTimer();
        Auth.logout();
      } else {
        setUpdate(new Date().valueOf());
      }
    }, 1000);
  };

  React.useEffect(() => {
    if (props.open) {
      const t = moment()
        .startOf('second')
        .add(props.logoutSeconds, 'second')
        .toDate();
      setEndTime(t);
      startTimer(t);
    } else {
      stopTimer();
    }
  }, [props.open]);

  const cancelLogout = () => {
    stopTimer();
    props.onClose();
  };

  const getRemainSecondText = () => {
    const reaminSecond = calculateRemainSeconds(endTime);
    if (!reaminSecond) {
      return `0 seconds`;
    }
    if (reaminSecond !== 1) {
      return `${calculateRemainSeconds(endTime)} seconds`;
    }
    return `1 second`;
  };

  return (
    <BaseDialog
      open={props.open}
      maxWidth="md"
      onClose={cancelLogout}
      title="Auto logout"
      actionContent={
        <>
          <Button onClick={cancelLogout} color="primary" pl={3}>
            Cancel
          </Button>
        </>
      }
    >
      <div className={classes.container}>
        <div>Due to inactivity, you will be logged out in:</div>
        <Typography variant="h2" className={classes.time}>
          {getRemainSecondText()}
        </Typography>
      </div>
    </BaseDialog>
  );
};

AutoLogoutDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  logoutSeconds: PropTypes.number.isRequired,
};

AutoLogoutDialog.defaultProps = {
  onClose: () => {},
};

export default AutoLogoutDialog;
