import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ExpansionCard from '../../components/cards/ExpansionCard';
import { ReadableIncidentCompanyDef } from '../../components/propTypeDefs';
import RichReader from '../../components/forms/RichReader';
import { labelValueStyle } from '../../theme';

const useStyles = makeStyles(theme => ({
  ...labelValueStyle,
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
  },
  subRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    display: 'flex',
    alignItems: 'flex-start',
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
    // height: 40,
  },
  // label: {
  //   ...theme.typography.h5,
  //   fontSize: '1.1em',
  //   fontWeight: 600,
  //   color: theme.palette.grey[500],
  // },
  // value: {
  //   ...theme.typography.body,
  //   // color: '#666',
  //   width: '100%',
  // },
}));

const IncidentViewCompanies = props => {
  const classes = useStyles();
  const { expanded, companies } = props;
  const renderCompany = company => {
    return (
      <ExpansionCard title={company.displayName} theme="sub">
        <div className={classes.root}>
          <div className={classes.lineItem}>
            <FormControl className={classes.formControl}>
              <div className={classes.label}>Person relation</div>
              <div className={classes.value}>{company.incidentRelation}</div>
            </FormControl>
            <FormControl className={classes.formControl}>
              <div className={classes.label}>Is owner</div>
              <div className={classes.value}>
                {company.isOwner ? 'Yes' : 'No'}
              </div>
            </FormControl>
          </div>
          <div className={classes.lineItem}>
            <FormControl className={classes.formControl}>
              <div className={classes.label}>Name</div>
              <div className={classes.value}>{company.name}</div>
            </FormControl>
          </div>
          {!company.isOwner && (
            <>
              <div className={classes.lineItem}>
                <FormControl className={classes.formControl}>
                  <div className={classes.label}>Location</div>
                  <div className={classes.value}>{company.address}</div>
                </FormControl>
              </div>
              <FormControl className={classes.formControl}>
                <div className={classes.label}>Contact name</div>
                <div className={classes.value}>{company.contact}</div>
              </FormControl>
              <div className={classes.lineItem}>
                <FormControl className={classes.formControl}>
                  <div className={classes.label}>Phone #</div>
                  <div className={classes.value}>{company.phone}</div>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <div className={classes.label}>Email</div>
                  <div className={classes.value}>{company.email}</div>
                </FormControl>
              </div>
            </>
          )}

          <div className={classes.lineItem}>
            <FormControl className={classes.formControlLong}>
              <div className={classes.label}>Comments</div>
              <div className={classes.value}>
                <RichReader content={company.comments} />
              </div>
            </FormControl>
          </div>
        </div>
      </ExpansionCard>
    );
  };

  return (
    <ExpansionCard
      title={`Legal entities (${companies.length})`}
      expanded={expanded}
    >
      <div className={classes.root}>
        {companies.map(company => (
          <div key={company.id} className={classes.subRoot}>
            {renderCompany(company)}
          </div>
        ))}
      </div>
    </ExpansionCard>
  );
};

IncidentViewCompanies.propTypes = {
  expanded: PropTypes.bool,
  companies: PropTypes.arrayOf(ReadableIncidentCompanyDef).isRequired,
};

IncidentViewCompanies.defaultProps = {
  expanded: false,
  companies: [],
};

export default IncidentViewCompanies;
