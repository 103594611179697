export const translateConfig = {
  enums: {
    incidentStatus: {
      0: 'Draft',
      1: 'Open',
      2: 'Closed',
      3: 'Editing',
    },
    measurementTypes: {
      0: 'Metric',
      1: 'US',
    },
    accountDateFormats: {
      0: 'DD/MM/YYYY',
      1: 'MM/DD/YYYY',
    },
  },
  statics: {
    charHeight: 'Height',
    charWeight: 'Weight',
    charHairColor: 'Hair Color',
    charHairLength: 'Hair Length',
    charHairType: 'Hair Type',
    charFacialHair: 'Facial Hair',
    charEyeGlasses: 'Eye Glasses',
    charOther: 'Distinguishing features',
  },
};

export default {
  translateConfig,
};
