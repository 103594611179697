import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import {
  FormControl,
  Typography,
  Button,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';

import {
  LookupDataSetDef,
  LookupLabelSetDef,
} from '../../components/propTypeDefs';
import LookupMultiplesSelect from '../newIncident/LookupMultiplesSelect';
import { LookupTypeKey } from '../../constants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  group: {
    margin: 0,
  },
  label: {
    marginLeft: theme.spacing(-1),
  },
  searchFields: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

const LookupMultipleSearchOption = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    lookupId: '',
    includeDescendant: true,
  });

  const handleChangeLookups = lookupId => {
    setState({ ...state, lookupId });
  };
  const toggleDescendant = () => {
    setState({ ...state, includeDescendant: !state.includeDescendant });
  };

  const addSearchCriteria = () => {
    const { lookupId, includeDescendant } = state;

    if (lookupId) {
      const incidentClass = {
        id: uuid(),
        lookupId,
        includeDescendant,
      };
      props.onSearchCriteriaAdded(incidentClass);
    }
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography variant="h6">{props.title}</Typography>
        <div className={classes.searchFields}>
          <LookupMultiplesSelect
            labels={props.lookupLabelSet[props.lookupType]}
            lookups={props.lookupDataSet[props.lookupType]}
            onChange={handleChangeLookups}
            defaultValue=""
          />
        </div>
        <div className={classes.searchFields}>
          <FormControl className={classes.formControl}>
            <Button
              variant="contained"
              color="primary"
              className={classes.searchButton}
              onClick={addSearchCriteria}
            >
              Add Search Criteria
            </Button>
          </FormControl>
        </div>{' '}
      </div>
    </React.Fragment>
  );
};

LookupMultipleSearchOption.propTypes = {
  onSearchCriteriaAdded: PropTypes.func.isRequired,
  lookupDataSet: LookupDataSetDef.isRequired,
  lookupLabelSet: LookupLabelSetDef.isRequired,
  title: PropTypes.string.isRequired,
  lookupType: PropTypes.string.isRequired,
};
LookupMultipleSearchOption.defaultProps = {
  onSearchCriteriaAdded: () => {},
};

const mapStateToProps = ({ common }) => ({
  lookupLabelSet: common.lookupLabelSet,
  lookupDataSet: common.lookupDataSet,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LookupMultipleSearchOption);
