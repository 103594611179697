import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import uuid from 'uuid/v4';
import _ from 'lodash';
import moment from 'moment';
import BaseDialog from '../../components/dialogs/BaseDialog';
import UserEdit from './UserEdit';
import {
  updateUser,
  addUser,
} from '../../modules/administration/userManagement';
import { enqueueError, enqueueSuccess } from '../../modules/global';
import userApi from '../../apis/user.api';
import { UserStatusEnum } from '../../constants';

const UserInfoDialog = props => {
  const {
    user,
    userAddState,
    userUpdateState,
    UserManageActions,
    mfaEnabled,
    ...other
  } = props;
  const [model, setModel] = React.useState({});
  const [isValidForm, setIsValidForm] = React.useState(false);
  const [userStatus, setUserStatus] = React.useState(undefined);

  const title = user ? 'Edit User' : 'Add User';

  React.useEffect(() => {
    setModel(user || {});
  }, [user]);

  React.useEffect(() => {
    (userUpdateState === 'done' || userAddState === 'done') &&
      props.onClose(true);
  }, [userUpdateState, userAddState]);

  React.useEffect(() => {
    if (user && user.id) {
      userApi
        .getUser(user.id)
        .then(response => {
          setUserStatus(response.data && response.data.status);
        })
        .catch(error => {
          enqueueError(error);
        });
    }
  }, [user]);

  const handleFieldChange = value => {
    if (model) {
      setModel({
        ...model,
        ...value,
      });
    } else {
      setModel(value);
    }
    // setModel(Object.assign({}, model, value));
  };
  const handleValidationChange = valid => {
    setIsValidForm(valid);
  };
  const onSave = () => {
    model.companyStartDate = model.companyStartDate
      ? moment(model.companyStartDate).toISOString()
      : moment()
          .startOf('day')
          .toISOString();

    if (model.id) {
      props.updateUser(model.id, model);
    } else {
      model.id = uuid();
      props.addUser(model).catch(err => {
        // if failed, reset the new id
        model.id = undefined;
      });
    }
  };
  const debounceOnSave = _.debounce(onSave, 200);
  const onCancel = () => {
    props.onClose(false);
  };

  const resendEmailVerification = userId => {
    userApi
      .resendEmailVerification(model.id)
      .then(res => {
        props.enqueueSuccess('Email verification is sent.');
      })
      .catch(error => {
        props.enqueueError(error);
      });
  };

  return (
    <BaseDialog
      {...other}
      title={title}
      actionContent={
        <>
          {user && user.id ? (
            <>
              <Button
                variant="contained"
                onClick={resendEmailVerification}
                color="primary"
                disabled={userStatus !== UserStatusEnum.pending}
              >
                Resend email verification
              </Button>
              <Button
                variant="contained"
                onClick={debounceOnSave}
                disabled={!isValidForm}
                color="primary"
              >
                Update
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              onClick={debounceOnSave}
              disabled={!isValidForm}
              color="primary"
            >
              Add
            </Button>
          )}
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
        </>
      }
    >
      <UserEdit
        user={user || {}}
        onFieldChange={handleFieldChange}
        onValidationChange={handleValidationChange}
        accountDateFormat={props.accountDateFormat}
        mfaEnabled={props.mfaEnabled}
      />
    </BaseDialog>
  );
};

UserInfoDialog.propTypes = {
  title: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    userGroupId: PropTypes.string,
  }),
  userAddState: PropTypes.string,
  userUpdateState: PropTypes.string,
  updateUser: PropTypes.func,
  addUser: PropTypes.func,
  enqueueError: PropTypes.func,
  enqueueSuccess: PropTypes.func,
  mfaEnabled: PropTypes.bool,
  onClose: PropTypes.func,
  enqueueError: PropTypes.func.isRequired,
  enqueueSuccess: PropTypes.func.isRequired,
  accountDateFormat: PropTypes.number,
};

function mapStateToProps({ administration, common }) {
  return {
    userAddState: administration.users.addState,
    userUpdateState: administration.users.updateState,
    mfaEnabled: common.companySettings.mfa,
  };
}

const mapDispatchToProps = {
  updateUser,
  addUser,
  enqueueError,
  enqueueSuccess,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withConnect(UserInfoDialog);
