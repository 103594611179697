import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useDrag, useDrop } from 'react-dnd';
import { SearchResultColumnDef } from '../../components/propTypeDefs';

const style = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
};

const SEARCH_RESULT_COLUMN_TYPE = 'searchResultColumn';

const SearchResultColumn = props => {
  const { index, col, moveColumn } = props;
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: SEARCH_RESULT_COLUMN_TYPE,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveColumn(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: SEARCH_RESULT_COLUMN_TYPE, id: col.id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const handleChange = event => {
    if (!col.fixed) {
      props.onChange(col.id, event.target.checked);
    }
  };

  return (
    <div ref={ref} style={{ ...style, opacity }}>
      <FormControlLabel
        control={
          <Checkbox
            disabled={col.fixed}
            checked={col.checked}
            onChange={handleChange}
            value={col.id}
            color="primary"
          />
        }
        label={col.label}
      />
    </div>
  );
};

SearchResultColumn.propTypes = {
  index: PropTypes.number,
  moveColumn: PropTypes.func,
  onChange: PropTypes.func,
  col: SearchResultColumnDef,
};
SearchResultColumn.defaultProps = {
  moveColumn: f => f,
  onChange: f => f,
};

export default SearchResultColumn;
