import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const ExternalRedirect = props => {
  React.useEffect(() => {
    window.location = props.loc;
  }, []);

  return <div />;
};

ExternalRedirect.propTypes = {
  loc: PropTypes.string.isRequired,
};

export default withRouter(ExternalRedirect);
