import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import ExpansionCard from '../../components/cards/ExpansionCard';
import IncidentViewPersonItem from './IncidentViewPersonItem';
import { ReadableIncidentPersonDef } from '../../components/propTypeDefs';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // flexWrap: 'wrap',
  },
  subRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  unlockButtonItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    display: 'flex',
    alignItems: 'flex-start',
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
    // height: 40,
  },
  label: {
    ...theme.typography.caption,
    color: 'grey',
  },
  value: {
    ...theme.typography.body,
    display: 'flex',
    alignItems: 'center',
  },
}));

const IncidentViewPersons = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    expanded: props.expanded,
  });
  const { persons } = props;

  const onExpansionCardExpanded = expanded => {
    setState({ ...state, expanded });
  };

  return (
    <ExpansionCard
      title={`Persons (${persons.length})`}
      expanded={state.expanded}
      onChange={onExpansionCardExpanded}
    >
      <div className={classes.root}>
        {persons.map(person => (
          <div key={person.id} className={classes.subRoot}>
            <IncidentViewPersonItem
              person={person}
              incidentStatus={props.incidentStatus}
            />
          </div>
        ))}
      </div>
    </ExpansionCard>
  );
};

IncidentViewPersons.propTypes = {
  expanded: PropTypes.bool,
  persons: PropTypes.arrayOf(ReadableIncidentPersonDef).isRequired,
  incidentStatus: PropTypes.string.isRequired,
};

IncidentViewPersons.defaultProps = {
  expanded: false,
  persons: [],
};

export default IncidentViewPersons;
