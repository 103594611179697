import React from 'react';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

import './App.css';
import Routes from './routes';
import theme from './theme';
import Notifier from './components/Notifier';
import config from './config';

import TagManager from 'react-gtm-module';

if (config.app.env === 'prod') {
  TagManager.initialize({
    gtmId: 'GTM-W3ZGJQG',
  });
} else {
  TagManager.initialize({
    gtmId: 'GTM-NBDTVCB',
  });
}

const App = props => {
  return (
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <Notifier />
        <CssBaseline />
        <Routes />
      </SnackbarProvider>
    </MuiThemeProvider>
  );
};
export default App;
