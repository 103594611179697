import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import _ from 'lodash';
import accountApi from '../../../apis/account.api';
import lookupApi from '../../../apis/lookup.api';
import { enqueueError, enqueueSuccess } from '../../../modules/global';
import { LookupTypeKey } from '../../../constants';
import { updateCompanySettingsActionInCommon } from '../../../modules/common';

const RESET = 'administration/companyManagement/RESET';
const COMPANY_LOADED = 'administration/companyManagement/COMPANY_LOADED';
const COMPANY_UPDATED = 'administration/companyManagement/COMPANY_UPDATED';
const COMPANY_SETTINGS_LOADED =
  'administration/companyManagement/COMPANY_SETTINGS_LOADED';
const COMPANY_SETTINGS_UPDATED =
  'administration/companyManagement/COMPANY_SETTINGS_UPDATED';
const ERROR = 'administration/companyManagement/ERROR';
const LABEL_LOADED = 'administration/companyManagement/LABEL_LOADED';
const LABEL_UPDATED = 'administration/companyManagement/LABEL_UPDATED';
const resetAction = createAction(RESET);
const loadedAction = createAction(COMPANY_LOADED);
const updatedAction = createAction(COMPANY_UPDATED);
const errorAction = createAction(ERROR);
const multipleLookupLabelsLoadedAction = createAction(LABEL_LOADED);
const updateLabelsAction = createAction(LABEL_UPDATED);
const companySettingsLoadedAction = createAction(COMPANY_SETTINGS_LOADED);
const companySettingsUpdatedAction = createAction(COMPANY_SETTINGS_UPDATED);

const initState = {
  loaded: false,
  company: {
    companyName: '',
  },
  companySettings: {},
  measurementTypes: [
    {
      code: 0,
      name: 'Metric',
      desc: 'ex) cm, kg',
    },
    {
      code: 1,
      name: 'US',
      desc: 'ex) inch, lb',
    },
  ],
  accountDateFormats: [
    {
      code: 0,
      name: 'DD/MM/YYYY (International)',
      format: 'DD/MM/YYYY',
      desc: 'ex) 30/06/2021',
    },
    {
      code: 1,
      name: 'MM/DD/YYYY (US)',
      format: 'MM/DD/YYYY',
      desc: 'ex) 06/30/2021',
    },
  ],
  multipleLookupLabels: {
    [LookupTypeKey.incidentClass]: [],
    [LookupTypeKey.incidentCause]: [],
    [LookupTypeKey.location]: [],
  },
};

export default handleActions(
  {
    [RESET]: (state, action) =>
      produce(state, draft => {
        draft.loaded = false;
      }),
    [ERROR]: (state, action) =>
      produce(state, draft => {
        draft.loaded = true;
      }),
    [COMPANY_LOADED]: (state, action) =>
      produce(state, draft => {
        draft.company = action.payload;
        draft.loaded = true;
      }),
    [COMPANY_UPDATED]: (state, action) =>
      produce(state, draft => {
        draft.company = action.payload;
        draft.loaded = true;
      }),
    [COMPANY_SETTINGS_LOADED]: (state, action) =>
      produce(state, draft => {
        draft.companySettings = action.payload;
      }),
    [COMPANY_SETTINGS_UPDATED]: (state, action) =>
      produce(state, draft => {
        draft.companySettings = action.payload;
      }),
    [LABEL_LOADED]: (state, action) =>
      produce(state, draft => {
        const { type, data } = action.payload;
        draft.multipleLookupLabels[type] = data;
        draft.loaded = true;
      }),
  },
  initState,
);

export const getCompany = () => async dispatch => {
  dispatch(resetAction());
  accountApi
    .query()
    .then(res => {
      dispatch(loadedAction(res.data));
      return accountApi.getCompanySettings();
    })
    .then(res => {
      dispatch(companySettingsLoadedAction(res.data));
    })
    .catch(err => {
      dispatch(errorAction());
      dispatch(enqueueError(err));
    });
};

// export const updateCompany = model => async dispatch => {
//   dispatch(resetAction());
//   try {
//     const { data } = await accountApi.update(model);
//     dispatch(updatedAction(data));
//   } catch (err) {
//     dispatch(errorAction());
//     dispatch(enqueueError(err));
//   }
// };

export const getCompanySettings = () => async dispatch => {
  dispatch(resetAction());
  try {
    const res = await accountApi.getCompanySettings();
    dispatch(companySettingsLoadedAction(res.data));
  } catch (err) {
    dispatch(errorAction());
    dispatch(enqueueError(err));
  }
};

export const updateCompanySettings = model => async dispatch => {
  try {
    await accountApi.updateCompanySettings(model);
    dispatch(companySettingsUpdatedAction(model));
    dispatch(updateCompanySettingsActionInCommon(model));
  } catch (err) {
    dispatch(errorAction());
    dispatch(enqueueError(err));
    throw err;
  }
};

export const queryLookupLabels = params => async dispatch => {
  dispatch(resetAction());
  try {
    const res = await lookupApi.queryMultipleLabels(params);
    dispatch(
      multipleLookupLabelsLoadedAction({ data: res.data, type: params.type }),
    );
  } catch (err) {
    dispatch(enqueueError(err));
  }
};

export const updateLookupLabels = model => async dispatch => {
  dispatch(resetAction());
  try {
    await lookupApi.addMultipleLabel(model);
    dispatch(updateLabelsAction(model));
  } catch (err) {
    dispatch(errorAction());
    dispatch(enqueueError(err));
  }
};

export const updateBillingInformation = model => async dispatch => {
  dispatch(resetAction());
  try {
    const { data } = await accountApi.updateBillingInformation(model);
    dispatch(updatedAction(data));
  } catch (err) {
    dispatch(errorAction());
    dispatch(enqueueError(err));
    throw err;
  }
};
