import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import produce from 'immer';
import ExpansionCard from '../../components/cards/ExpansionCard';
import IncidentAttachmentItem from './IncidentAttachmentItem';
import AddAttachmentDialog from '../../components/dialogs/AddAttachmentDialog';
import { IncidentAttachmentDef } from '../../components/propTypeDefs';
import { uploadAttachment, deleteAttachment } from './ducks';
import { AttachmentType } from '../../constants';
import Auth from '../../auth/Auth';
import { getAzureFileUrl, downloadFromUrl } from '../../utils/fileUtil';
import AttachedImageViewer from '../../components/dialogs/AttachedImageViewer';
import { canDeleteAttachment } from '../../utils/privilegeUtil';
import { enqueueError } from '../../modules/global';

const useStyles = makeStyles(theme => ({
  expansionCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  expansionCardItem: {},
  itemAddButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
  },
  attachmentRoot: {
    width: '100%',
    // flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  attachmentItem: {
    padding: 10,
  },
}));

const IncidentAttachmentsCard = props => {
  const classes = useStyles();
  const [state, setState] = React.useState(
    {
      attachments: props.attachments,
      selectedAttachment: undefined,
      viewerOpen: false,
    },
    [props.attachments],
  );
  const [addAttachmentDialogOpen, setAddAttachmentDialogOpen] = React.useState({
    open: false,
    uloading: false,
  });

  const emitData = data => {
    props.onChange({ attachments: data });
  };

  const openAddAttachmentDialog = () => {
    setState({
      ...state,
      selectedAttachment: {
        id: uuid(),
        incidentId: props.incidentId,
        name: `Attachment ${state.attachments.length + 1}`,
      },
    });
    setAddAttachmentDialogOpen({ open: true, uploading: false });
  };

  const onAttachmentDeleted = attachment => {
    if (attachment) {
      props.deleteAttachment(attachment);
    }
  };
  const validateDeleteAttachmentPrivilege = attachment => {
    // if attachment has creationDate, it is not draft attachment but saved in server.
    // validate if the attachment is not draft attachemnt and user has a DELETE_ATTACHMENT privilege.
    if (attachment.creationDate && !canDeleteAttachment()) {
      props.enqueueError(
        'You do not have permission to delete attachments. Please ask administrator to delete the attachment.',
      );
      return false;
    }

    return true;
  };
  const addAttachment = formData => {
    setAddAttachmentDialogOpen({ open: true, uploading: true });
    props
      .uploadAttachment(formData)
      .then(() => {
        setAddAttachmentDialogOpen({ open: false, uploading: false });
      })
      .catch(err => {
        setAddAttachmentDialogOpen({ open: true, uploading: false });
      });
  };
  const handleChange = model => {
    const nextState = produce(state, draft => {
      const found = draft.attachments.find(x => x.id === model.id);
      Object.assign(found, model);
    });
    setState(nextState);
    emitData(nextState.attachments);
  };
  const handleDownload = attachment => {
    if (
      attachment.attachmentType === AttachmentType.image ||
      attachment.attachmentType === AttachmentType.movie ||
      attachment.attachmentType === AttachmentType.audio ||
      attachment.attachmentType === AttachmentType.txt ||
      attachment.attachmentType === AttachmentType.pdf
    ) {
      setState({ ...state, viewerOpen: true, selectedAttachment: attachment });
    } else {
      if (!Auth.currentUser) return;
      const url = getAzureFileUrl(
        Auth.currentUser.accountId,
        attachment.fileName,
        false,
        attachment.sasKey,
        attachment.thumbnailSasKey,
      );
      downloadFromUrl(url, attachment.name);
    }
  };
  const handleViewerClose = () => {
    setState({ ...state, viewerOpen: false, selectedAttachment: undefined });
  };

  // React.useEffect(() => {
  //   props.onChange({ attachments: state.attachments });
  // }, [state.attachments]);

  React.useEffect(() => {
    setState({
      ...state,
      attachments: props.attachments,
    });
  }, [props.attachments]);

  return (
    <>
      <ExpansionCard
        title={`Attachments (${
          state.attachments.filter(
            x => !x.incidentItemId && !x.incidentVehicleId,
          ).length
        })`}
        helpMessage="Attach files related to the incident record."
      >
        <div className={classes.expansionCardContainer}>
          <div className={classes.attachmentRoot}>
            {state.attachments &&
              state.attachments
                .filter(x => !x.incidentItemId && !x.incidentVehicleId)
                .map(_attachment => {
                  return (
                    <div
                      className={classes.attachmentItem}
                      key={_attachment.id}
                    >
                      <IncidentAttachmentItem
                        editable
                        attachment={_attachment}
                        onDeleted={onAttachmentDeleted}
                        onChange={handleChange}
                        onDownload={handleDownload}
                        validateDeleteAttachmentPrivilege={
                          validateDeleteAttachmentPrivilege
                        }
                      />
                    </div>
                  );
                })}
          </div>

          <div className={classes.itemAddButton}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={openAddAttachmentDialog}
            >
              Add
            </Button>
          </div>
        </div>
      </ExpansionCard>
      {addAttachmentDialogOpen.open && (
        <AddAttachmentDialog
          open={addAttachmentDialogOpen.open}
          uploading={addAttachmentDialogOpen.uploading}
          onCancel={() => {
            setAddAttachmentDialogOpen({ open: false, uploading: false });
          }}
          onAttachmentAdded={addAttachment}
          attachment={state.selectedAttachment}
          uploadLimit={props.uploadLimit}
        />
      )}
      {state.selectedAttachment && (
        <AttachedImageViewer
          attachment={state.selectedAttachment}
          open={state.viewerOpen}
          onClose={handleViewerClose}
        />
      )}
    </>
  );
};

IncidentAttachmentsCard.propTypes = {
  incidentId: PropTypes.string,
  attachments: PropTypes.arrayOf(IncidentAttachmentDef),
  onChange: PropTypes.func,
  uploadAttachment: PropTypes.func.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  enqueueError: PropTypes.func.isRequired,
  uploadLimit: PropTypes.number,
};

IncidentAttachmentsCard.defaultProps = {
  incidentId: undefined,
  attachments: [],
  onChange: f => f,
};

const mapStateToProps = ({ newIncident, common }) => ({
  openAttachment: newIncident.openAttachment,
  uploadLimit: common.fileSizeLimit.maxUploadFileSize,
});
const mapDispatchToProps = {
  uploadAttachment,
  deleteAttachment,
  enqueueError,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncidentAttachmentsCard);
