import PropTypes from 'prop-types';
import {
  PersonType,
  LookupTypeKey,
  AttachmentType,
  DispositionCorrectiveActionType,
  IncidentHistoryActionType,
  AuditObjectType,
  NotificationType,
  NotificationLevel,
} from '../constants';

export const LookupTypeDef = PropTypes.shape({
  key: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['single', 'multiple', 'location']).isRequired,
});

export const LookupDef = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([0])]).isRequired,
  name: PropTypes.string.isRequired,
  fullName: PropTypes.string,
  type: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.object),
});

export const LookupDataSetDef = PropTypes.shape({
  [LookupTypeKey.personType]: PropTypes.arrayOf(LookupDef),
  [LookupTypeKey.vehicleInvolvement]: PropTypes.arrayOf(LookupDef),
  [LookupTypeKey.incidentClass]: PropTypes.arrayOf(LookupDef),
  [LookupTypeKey.incidentCause]: PropTypes.arrayOf(LookupDef),
  [LookupTypeKey.location]: PropTypes.arrayOf(LookupDef),
});

export const LookupLabelSetDef = PropTypes.shape({
  [LookupTypeKey.incidentClass]: PropTypes.arrayOf(PropTypes.string),
  [LookupTypeKey.incidentCause]: PropTypes.arrayOf(PropTypes.string),
  [LookupTypeKey.location]: PropTypes.arrayOf(PropTypes.string),
});

export const IncidentDispositionCorrectiveActionShape = {
  correctiveActionType: PropTypes.oneOf(
    Object.values(DispositionCorrectiveActionType),
  ),
  correctiveActionFirstName: PropTypes.string,
  correctiveActionLastName: PropTypes.string,
  correctiveActionPhone: PropTypes.string,
  correctiveActionEmail: PropTypes.string,
  correctiveActionSummary: PropTypes.object,
};

const incidentShape = {
  id: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  creationDate: PropTypes.string,
  lastUpdateDate: PropTypes.string,
  summary: PropTypes.object,
  occurredDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  reportedDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  lastClassId: PropTypes.string,
  primaryCauseId: PropTypes.string,
  severityId: PropTypes.string,
};

const addressShape = {
  address: PropTypes.string,
  unit: PropTypes.string,
  street: PropTypes.string,
  postalCode: PropTypes.string,
  cityId: PropTypes.string,
  cityName: PropTypes.string,
  countryCode: PropTypes.string,
  stateName: PropTypes.string,
  lastLocationId: PropTypes.string,
};

export const LocationDef = PropTypes.shape(addressShape);

export const DraftIncidentDef = PropTypes.shape({
  ...incidentShape,
  location: PropTypes.oneOfType([LocationDef, PropTypes.string]),
});

export const ReadableIncidentDef = PropTypes.shape({
  number: PropTypes.string,
  status: PropTypes.string,
  creationDate: PropTypes.string,
  lastUpdateDate: PropTypes.string,
  summary: PropTypes.object,
  occurredDate: PropTypes.string,
  reportedDate: PropTypes.string,
  whatHappened: PropTypes.string,
  location: PropTypes.string,
  address: PropTypes.string,
});

export const ReadableIncidentCloseDef = PropTypes.shape({
  closedBy: PropTypes.string,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  alias: PropTypes.string,
  department: PropTypes.string,
  poistion: PropTypes.string,
  phone: PropTypes.string,
  mobilePhone: PropTypes.string,
  email: PropTypes.string,
  comments: PropTypes.object,
});

export const IncidentDef = PropTypes.shape({
  ...incidentShape,
  location: PropTypes.oneOfType([LocationDef, PropTypes.string]),
});

export const IncidentPersonDef = PropTypes.shape({
  id: PropTypes.string.isRequired,
  isEmployee: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string,
  middleName: PropTypes.string,
  alias: PropTypes.string,
  phone: PropTypes.string,
  mobilePhone: PropTypes.string,
  email: PropTypes.string,
  ...addressShape,
  statusInjury: PropTypes.bool,
  statusFirstAid: PropTypes.bool,
  statusHospitalized: PropTypes.bool,
  statusRefusedHelp: PropTypes.bool,

  relationReporter: PropTypes.string,
  relationVictim: PropTypes.string,
  relationPersonOfInterest: PropTypes.string,
  relationSuspect: PropTypes.string,
  relationWitness: PropTypes.string,
  relationSubject: PropTypes.string,

  lookupPersonTypeId: PropTypes.string,
  lookupDepartmentId: PropTypes.string,

  charHeight: PropTypes.string,
  charWeight: PropTypes.string,
  charHairColor: PropTypes.string,
  charHairLength: PropTypes.string,
  charHairType: PropTypes.string,
  charFacialHair: PropTypes.string,
  charEyeGlasses: PropTypes.string,
  charOther: PropTypes.string,

  comments: PropTypes.object,
});

export const PersonDef = PropTypes.shape({
  id: PropTypes.string.isRequired,
  isEmployee: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string,
  middleName: PropTypes.string,
  alias: PropTypes.string,
  phone: PropTypes.string,
  mobilePhone: PropTypes.string,
  email: PropTypes.string,
});

export const ReadableIncidentPersonDef = PropTypes.shape({
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string,
  middleName: PropTypes.string,
  alias: PropTypes.string,
  phone: PropTypes.string,
  mobilePhone: PropTypes.string,
  email: PropTypes.string,
  ...addressShape,
  status: PropTypes.string,

  incidentRelation: PropTypes.string,
  personType: PropTypes.string,

  height: PropTypes.string,
  weight: PropTypes.string,
  hairColor: PropTypes.string,
  hairLength: PropTypes.string,
  hairType: PropTypes.string,
  facialHair: PropTypes.string,
  eyeGlasses: PropTypes.string,
  other: PropTypes.string,

  comments: PropTypes.object,
});

export const IncidentVehicleDef = PropTypes.shape({
  id: PropTypes.string.isRequired,
  ownerPersonId: PropTypes.string,
  licensePlate: PropTypes.string,
  stateProvince: PropTypes.string,
  maker: PropTypes.string,
  model: PropTypes.string,
  color: PropTypes.string,
  vin: PropTypes.string,
  involvementId: PropTypes.string,
  comments: PropTypes.object,
});
export const ReadableIncidentVehicleDef = PropTypes.shape({
  id: PropTypes.string,
  ownerName: PropTypes.string,
  licensePlate: PropTypes.string,
  stateProvince: PropTypes.string,
  maker: PropTypes.string,
  model: PropTypes.string,
  color: PropTypes.string,
  vin: PropTypes.string,
  involvement: PropTypes.string,
  comments: PropTypes.object,
});

export const IncidentCompanyDef = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  ...addressShape,
  phone: PropTypes.string,
  email: PropTypes.string,
  contact: PropTypes.string,
});
export const ReadableIncidentCompanyDef = IncidentCompanyDef;

export const PropertyOwnerDef = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  personType: PropTypes.oneOf(Object.values(PersonType)).isRequired,
});

export const InvolvedPersonDef = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  personType: PropTypes.oneOf(Object.values(PersonType)).isRequired,
});

export const UserGroupDef = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const UserDef = PropTypes.shape({
  id: PropTypes.string.isRequired,
  auth0Id: PropTypes.string,
  email: PropTypes.string,
  authenticationType: PropTypes.number.isRequired,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  userGroupId: PropTypes.string.isRequired,
  userGroupName: PropTypes.string,
  status: PropTypes.number.isRequired,
  avatarUrl: PropTypes.string,
  companyStartDate: PropTypes.string,
  position: PropTypes.string,
  company: PropTypes.string,
  department: PropTypes.string,
  division: PropTypes.string,
  location: PropTypes.string,
  phone: PropTypes.string,
  fax: PropTypes.string,
  mobilePhone: PropTypes.string,
});

export const ChildrenDef = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);
export const IncidentItemDef = PropTypes.shape({
  id: PropTypes.string.isRequired,
  incidentId: PropTypes.string.isRequired,
  ownerPersonId: PropTypes.string,
  status: PropTypes.string,
  company: PropTypes.string,
  name: PropTypes.string,
  brand: PropTypes.string,
  model: PropTypes.string,
  color: PropTypes.string,
  serialNumber: PropTypes.string,
  comments: PropTypes.object,
});
export const ReadableIncidentItemDef = PropTypes.shape({
  id: PropTypes.string,
  ownerName: PropTypes.string,
  status: PropTypes.string,
  company: PropTypes.string,
  name: PropTypes.string,
  brand: PropTypes.string,
  model: PropTypes.string,
  color: PropTypes.string,
  serialNumber: PropTypes.string,
  comments: PropTypes.object,
});
export const IncidentAttachmentDef = PropTypes.shape({
  id: PropTypes.string.isRequired,
  incidentId: PropTypes.string.isRequired,
  incidentItemId: PropTypes.string,
  incidentVehicleId: PropTypes.string,
  name: PropTypes.string.isRequired,
  comments: PropTypes.string,
  fileName: PropTypes.string,
  attachmentType: PropTypes.oneOf(Object.values(AttachmentType)),
});
export const IncidentCorrectiveActionDef = PropTypes.shape({
  id: PropTypes.string.isRequired,
  incidentId: PropTypes.string.isRequired,
  typeId: PropTypes.string,
  displayType: PropTypes.string,
  involvedPersonList: PropTypes.arrayOf(PropTypes.string),
  actionType: PropTypes.oneOf(Object.values(IncidentHistoryActionType)),
  details: PropTypes.object,

  referredType: PropTypes.string,
  referredInternalDepartmentId: PropTypes.string,
  referredExternalAgencyId: PropTypes.string,
  referredContactFirstName: PropTypes.string,
  referredContactMiddleName: PropTypes.string,
  referredContactLastName: PropTypes.string,
  referredContactAlias: PropTypes.string,
  referredContactPosition: PropTypes.string,
  referredContactMobilePhone: PropTypes.string,
  referredContactPhone: PropTypes.string,
  referredContactEmail: PropTypes.string,
});

export const IncidentHistoryDef = PropTypes.shape({
  id: PropTypes.string.isRequired,
  incidentId: PropTypes.string.isRequired,
  status: PropTypes.string,
  actionType: PropTypes.oneOf(Object.values(IncidentHistoryActionType)),
  updateDate: PropTypes.Date,
  updateUserId: PropTypes.string,
  updateUserName: PropTypes.string,
});
export const IncidentHistoryCompareDef = PropTypes.shape({
  currentIncident: DraftIncidentDef.isRequired,
  previousIncident: DraftIncidentDef,
});

export const StateProvinceDef = PropTypes.shape({
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
});

export const CustomSearchDef = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  criteria: PropTypes.object,
  creationDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
});
export const CustomAnalysisDef = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  criteria: PropTypes.array,
  creationDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
});

export const AuditDef = PropTypes.shape({
  id: PropTypes.string.isRequired,
  createUserId: PropTypes.string.isRequired,
  createUserName: PropTypes.string.isRequired,
  createUserTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  objectType: PropTypes.oneOf(Object.values(AuditObjectType)).isRequired,
  objectName: PropTypes.string,
  objectId: PropTypes.string,
  objectHistoryId: PropTypes.string,
  transactionType: PropTypes.string,
  objectData: PropTypes.any,
});

// export const SubscriptionDef = PropTypes.shape({
//   accountId: PropTypes.string.isRequired,
//   maxActiveUser: PropTypes.number.isRequired,
//   subscriptionType: PropTypes.string.isRequired,
//   paymentEmail: PropTypes.string.isRequired,
//   paymentSourceValid: PropTypes.bool.isRequired,
//   paymentError: PropTypes.string.isRequired,
//   paymentSourceLast4: PropTypes.string.isRequired,
//   paymentSourceName: PropTypes.string.isRequired,
//   paymentAmount: PropTypes.number.isRequired,
// });

export const SubscriptionDef = PropTypes.shape({
  concurrentUserCount: PropTypes.number.isRequired,
  periodType: PropTypes.oneOf(['MONTH', 'YEAR']).isRequired,
  trial: PropTypes.bool.isRequired,
  nextChargeDate: PropTypes.string,
  subscriptionValidDate: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  cardLast4: PropTypes.string.isRequired,
  taxPercent: PropTypes.number.isRequired,
});

export const PlanDef = PropTypes.shape({
  monthly: PropTypes.shape({
    defaultAmount: PropTypes.number.isRequired,
    additionalAmount: PropTypes.number.isRequired,
  }),
  yearly: PropTypes.shape({
    defaultAmount: PropTypes.number.isRequired,
    additionalAmount: PropTypes.number.isRequired,
  }),
  taxRate: PropTypes.number.isRequired,
});

export const NotificationDef = PropTypes.shape({
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  notificationType: PropTypes.oneOf(Object.values(NotificationType)),
  level: PropTypes.oneOf(Object.values(NotificationLevel)),
  userDismissAvailable: PropTypes.bool.isRequired,
  dismissed: PropTypes.bool.isRequired,
  userDismissed: PropTypes.bool.isRequired,
});

export const SelectValueDef = PropTypes.shape({
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const SearchResultColumnDef = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  fixed: PropTypes.bool.isRequired,
});

export const AccountDef = PropTypes.shape({
  id: PropTypes.string,
  companyName: PropTypes.string,
  companyCountryCode: PropTypes.string,
  accountHolderId: PropTypes.string,
  active: PropTypes.bool,
  measurementType: PropTypes.number,
  trial: PropTypes.bool,
  trialExpiryDate: PropTypes.string,
  logoImage: PropTypes.string,
  billingAddressLine1: PropTypes.string,
  billingAddressLine2: PropTypes.string,
  billingAddressCity: PropTypes.string,
  billingAddressState: PropTypes.string,
  billingAddressPostalCode: PropTypes.string,
  billingAddressCountryCode: PropTypes.string,
  billingEmail: PropTypes.string,
  billingPhone: PropTypes.string,
  billingContactName: PropTypes.string,
});

export const CompanySettingsDef = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([0])]).isRequired,
  companyName: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  companyCountryCode: PropTypes.string.isRequired,
  measurementType: PropTypes.oneOf([0, 1]), // MeasurementType
  dateFormat: PropTypes.oneOf([0, 1]), // AccountDateFormatEnum
  logoImage: PropTypes.string,
  autoLogoutTime: PropTypes.number,
});
