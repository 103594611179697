import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { AnalyzeFrequencyType } from '../../constants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  group: {
    margin: 0,
  },
  label: {
    marginLeft: theme.spacing(-1),
  },
}));

const FrequencySelector = props => {
  const classes = useStyles();

  const inputHandleChange = event => {
    const { value } = event.target;
    props.onChange(value);
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <React.Fragment>
          <div className={classes.lineItem}>
            <FormControl className={classes.formControl}>
              <RadioGroup
                aria-label="frequency"
                name="frequency"
                className={classes.group}
                value={props.frequency}
                onChange={inputHandleChange}
                row
              >
                <FormControlLabel
                  value={AnalyzeFrequencyType.day}
                  control={<Radio />}
                  label="Day"
                />
                <FormControlLabel
                  value={AnalyzeFrequencyType.weekday}
                  control={<Radio />}
                  label="Weekday"
                />
                <FormControlLabel
                  value={AnalyzeFrequencyType.month}
                  control={<Radio />}
                  label="Month"
                />
                <FormControlLabel
                  value={AnalyzeFrequencyType.year}
                  control={<Radio />}
                  label="Year"
                />
                <FormControlLabel
                  value={AnalyzeFrequencyType.class}
                  control={<Radio />}
                  label="Class"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </React.Fragment>
      </div>
    </React.Fragment>
  );
};

FrequencySelector.propTypes = {
  frequency: PropTypes.string,
  onChange: PropTypes.func,
};

FrequencySelector.defaultProps = {
  onChange: () => {},
  frequency: '',
};

export default FrequencySelector;
