import uuid from 'uuid/v4';

export default class IncidentPersonModel {
  constructor(id, firstName, lastName) {
    if (id) {
      this.id = id;
    } else {
      this.id = uuid();
    }
    this.firstName = firstName;
    this.lastName = lastName;
  }

  getName = () => {
    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`.trim();
    }
    if (this.firstName) {
      return this.firstName;
    }
    if (this.lastName) {
      return this.lastName;
    }
    return 'Unknown';
  };
}
