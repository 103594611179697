import { createMuiTheme } from '@material-ui/core/styles';
import { green, grey, red, blue, indigo } from '@material-ui/core/colors';

const rawTheme = createMuiTheme({
  palette: {
    // primary: {
    //   light: indigo[100],
    //   main: indigo[700],
    //   dark: indigo[900],
    // },
    // secondary: {
    //   light: blue[100],
    //   main: blue[700],
    //   dark: blue[900],
    // },
    primary: {
      light: '#96bb57',
      main: '#7CAA2D',
      dark: '#56761f',
    },
    secondary: {
      light: '#33b0a8',
      main: '#009d93',
      dark: '#006d66',
    },
    warning: {
      main: '#ffc071',
      dark: '#ffb25e',
    },
    error: {
      xLight: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      xLight: green[50],
      main: red[500],
      dark: green[700],
    },
  },
  // typography: {
  //   // Use the system font instead of the default Roboto font.
  //   // fontFamily: ['"Lato"', 'sans-serif'].join(','),
  //   fontSize: 14,
  //   fontWeightLight: 300, // Work Sans
  //   fontWeightRegular: 400, // Work Sans
  //   fontWeightMedium: 700, // Roboto Condensed
  //   fontFamilySecondary: "'Roboto Condensed', sans-serif",
  //   useNextVariants: true,
  //   color: '#000',
  // },
});

// const rawTheme1 = createMuiTheme({
//   palette: {
//     primary: {
//       ligth: '#69696a',
//       main: '#28282a',
//       dark: '#1e1e1f',
//     },
//     secondary: {
//       light: '#fff5f8',
//       main: '#ff3366',
//       dark: '#e62958',
//     },
//     warning: {
//       main: '#ffc071',
//       dark: '#ffb25e',
//     },
//     error: {
//       xLight: red[50],
//       main: red[500],
//       dark: red[700],
//     },
//     success: {
//       xLight: green[50],
//       dark: green[700],
//     },
//   },
//   typography: {
//     fontFamily: "'Work Sans', sans-serif",
//     fontSize: 14,
//     fontWeightLight: 300, // Work Sans
//     fontWeightRegular: 400, // Work Sans
//     fontWeightMedium: 700, // Roboto Condensed
//     fontFamilySecondary: "'Roboto Condensed', sans-serif",
//     useNextVariants: true,
//     color: '#000',
//   },
//   overrides: {
//     MUIDataTableBodyCell: {
//       root: {
//         backgroundColor: '#FFF',
//         width: '30px',
//       },
//     },
//   },
// });

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: rawTheme.typography.fontWeightMedium,
  fontFamily: rawTheme.typography.fontFamilySecondary,
  textTransform: 'uppercase',
};

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.common.white,
      placeholder: grey[200],
    },
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
      fontSize: 60,
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: 48,
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: 42,
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: 36,
    },
    h5: {
      ...rawTheme.typography.h5,
      fontSize: 20,
      fontWeight: rawTheme.typography.fontWeightLight,
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      fontSize: 18,
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: 18,
    },
    body1: {
      ...rawTheme.typography.body1,
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontSize: 16,
      color: rawTheme.palette.text.primary,
    },
    body2: {
      ...rawTheme.typography.body2,
      fontSize: 13,
      color: rawTheme.palette.text.primary,
    },
  },
};

export const labelValueStyle = {
  label: {
    ...theme.typography.body1,
    fontWeight: '500',
    color: theme.palette.grey[900],
    marginBottom: theme.spacing(0.5),
  },
  value: {
    ...theme.typography.body2,
    fontWeight: '400',
    width: '100%',
    color: theme.palette.grey[700],
  },
};

export default theme;
