import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core';
import classNames from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ChildrenDef } from '../propTypeDefs';
import HelpButton from '../buttons/HelpButton';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    paper: {
      padding: theme.spacing(3),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    mainTheme: {
      ...theme.typography.h4,
      fontSize: 22,
      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },
    mainThemeOpen: {
      backgroundColor: theme.palette.primary.light,
    },
    subTheme: {
      ...theme.typography.h6,
      color: 'white',
      backgroundColor: theme.palette.primary.dark,
    },
    detailWrapper: {
      [theme.breakpoints.down('xs')]: {
        padding: 8,
      },
    },
  }),
);

const ExpansionCard = props => {
  const classes = useStyles();
  const { title, helpMessage, theme, children } = props;
  const [opened, setOpened] = React.useState(false);

  React.useEffect(() => {
    setOpened(props.expanded);
  }, [props.expanded]);

  const style = classNames(
    { [classes.mainTheme]: theme === 'main' },
    { [classes.subTheme]: theme === 'sub' },
    { [classes.mainThemeOpen]: theme === 'main' && opened },
  );
  const handleChange = (event, expandedParm) => {
    setOpened(expandedParm);
    props.onChange(expandedParm);
  };
  return (
    <div className={classes.root}>
      <ExpansionPanel
        expanded={opened}
        onChange={handleChange}
        TransitionProps={{ unmountOnExit: props.unmount }}
      >
        <ExpansionPanelSummary
          expandIcon={
            <ExpandMoreIcon htmlColor={theme === 'sub' ? 'white' : undefined} />
          }
          className={style}
        >
          {title}
          {helpMessage && (
            <HelpButton
              popupId={`help-expansion-card-${title}`}
              message={helpMessage}
            />
          )}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.detailWrapper}>
          {children || ''}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};
ExpansionCard.propTypes = {
  expanded: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  helpMessage: PropTypes.string,
  theme: PropTypes.oneOf(['main', 'sub']),
  onChange: PropTypes.func,
  children: ChildrenDef,
  unmount: PropTypes.bool,
};

ExpansionCard.defaultProps = {
  helpMessage: undefined,
  theme: 'main',
  onChange: () => {},
  expanded: false,
  unmount: true,
};

export default ExpansionCard;
