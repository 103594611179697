import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  TableCell,
  TableBody,
  TableRow,
  IconButton,
  Table,
  TableHead,
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  CardActionArea,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';

import { DraftIncidentDef, LookupDef } from '../../components/propTypeDefs';
import { buildLookupPath } from '../../utils/lookupTreeUtil';
import { translateConfig } from '../../utils/simpleTranslateUtil';
import AlertDialog from '../../components/dialogs/AlertDialog';
import { openDraftIncidentDeleteDialog } from './ducks';
import { useMediaInfo } from '../../utils/hooks';
import { dateFormat } from '../../utils/dateUtil';
import { DefaultDateFormat } from '../../constants';
import auth from '../../auth/Auth';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 700,
    padding: theme.spacing(1),
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardContent: {
    padding: theme.spacing(1, 2),
  },
  cardFieldRow: {},
  cardLabel: {
    marginRight: theme.spacing(2),
  },
  cardActions: {
    padding: 0,
    justifyContent: 'flex-end',
  },
}));

const DraftIncidentTable = props => {
  const classes = useStyles();
  const { draftIncidents } = props;
  const { isSmaller, isMobile } = useMediaInfo({ minBreakPoint: 'md' });

  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);
  const [selectedIncident, setSelectedIncident] = useState(undefined);

  const openDraftIncident = incident => {
    props.onItemCommand('edit', incident);
  };

  const deleteDraftIncident = incident => {
    props.onItemCommand('deleteDraft', incident);
  };

  const openDeleteDialog = incident => {
    setSelectedIncident(incident);
    props.openDraftIncidentDeleteDialog(true);
  };

  const getStatusText = status => translateConfig.enums.incidentStatus[status];

  return (
    <React.Fragment>
      {isSmaller ? (
        <Grid spacing={1} container>
          {draftIncidents.map(incident => (
            <Grid item key={incident.id} xs={12} sm={12}>
              <Card>
                <CardActionArea
                  onClick={() => {
                    openDraftIncident(incident);
                  }}
                >
                  <CardContent className={classes.cardContent}>
                    <div>
                      <Typography variant="h6">#{incident.number}</Typography>
                      <Typography variant="subtitle2">
                        {buildLookupPath(
                          props.incidentLookups,
                          incident.lastClassId,
                        )}
                      </Typography>
                      {incident.reportedDate && (
                        <Typography variant="body2">
                          reported on{' '}
                          {dateFormat(
                            incident.reportedDate,
                            DefaultDateFormat(props.companySettings).dateMoment,
                          )}
                        </Typography>
                      )}
                      {incident.occurredDate && (
                        <Typography variant="body2">
                          ocurred on{' '}
                          {dateFormat(
                            incident.occurredDate,
                            DefaultDateFormat(props.companySettings.dateFormat)
                              .dateFormat,
                            incident.occurredDateUncertain,
                          )}
                        </Typography>
                      )}
                      <div className={classes.cardFieldRow}>
                        <strong className={classes.cardLabel}>Status</strong>
                        <span>{getStatusText(incident.status)}</span>
                      </div>
                    </div>
                  </CardContent>
                  <CardActions disableSpacing className={classes.cardActions}>
                    <IconButton
                      color="primary"
                      aria-label="View"
                      className={classes.margin}
                      onClick={event => {
                        event.stopPropagation();
                        openDeleteDialog(incident);
                      }}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </CardActions>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Number</TableCell>
              <TableCell>Date created</TableCell>
              <TableCell>Last modified</TableCell>
              <TableCell>What</TableCell>
              <TableCell>Entered By</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {draftIncidents.map(incident => (
              <TableRow key={incident.id}>
                <TableCell component="th" scope="row">
                  {incident.number}
                </TableCell>
                <TableCell>
                  {moment(incident.creationDate)
                    .toDate()
                    .toLocaleDateString()}
                </TableCell>
                <TableCell>
                  {moment(incident.lastUpdateDate)
                    .toDate()
                    .toLocaleDateString()}
                </TableCell>
                <TableCell>
                  {buildLookupPath(props.incidentLookups, incident.lastClassId)}
                </TableCell>
                <TableCell>{incident.createUserName}</TableCell>
                <TableCell>{getStatusText(incident.status)}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    aria-label="Edit"
                    className={classes.margin}
                    onClick={() => {
                      openDraftIncident(incident);
                    }}
                    disabled={
                      !auth.hasPrivilege([
                        'MYINCIDENT_EDIT',
                        'ALLINCIDENT_EDIT',
                      ])
                    }
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    aria-label="Delete"
                    className={classes.margin}
                    onClick={() => {
                      openDeleteDialog(incident);
                    }}
                    disabled={
                      !auth.hasPrivilege([
                        'MYINCIDENT_EDIT',
                        'ALLINCIDENT_EDIT',
                      ])
                    }
                  >
                    <DeleteIcon
                      color={
                        auth.hasPrivilege([
                          'MYINCIDENT_EDIT',
                          'ALLINCIDENT_EDIT',
                        ])
                          ? 'error'
                          : 'grey'
                      }
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <AlertDialog
        title="Delete Draft Incident"
        message={`Are you sure you want to delete the draft incident #${selectedIncident &&
          selectedIncident.number}?`}
        open={props.deleteDraftIncidentDialogOpened}
        onClose={() => {
          props.openDraftIncidentDeleteDialog(false);
        }}
        onCancel={() => {
          props.openDraftIncidentDeleteDialog(false);
        }}
        onOk={() => {
          deleteDraftIncident(selectedIncident);
        }}
      />
    </React.Fragment>
  );
};

DraftIncidentTable.propTypes = {
  draftIncidents: PropTypes.arrayOf(DraftIncidentDef).isRequired,
  incidentLookups: PropTypes.arrayOf(LookupDef).isRequired,
  onItemCommand: PropTypes.func,
  openDraftIncidentDeleteDialog: PropTypes.func,
  deleteDraftIncidentDialogOpened: PropTypes.bool.isRequired,
};

DraftIncidentTable.defaultProps = {
  onItemCommand: f => f,
};

// export default DraftIncidentTable;

const mapStateToProps = ({ dashboard, common }) => ({
  deleteDraftIncidentDialogOpened: dashboard.deleteDraftIncidentDialogOpened,
  companySettings: common.companySettings,
});

const mapDispatchToProps = {
  openDraftIncidentDeleteDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(DraftIncidentTable);
