import BaseApi from './BaseApi';

class PersonApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl += '/persons';
  }

  async search(name) {
    return await this.get(`${this.baseUrl}/search?name=${name}`);
  }

  async duplicationCheck(firstName, middleName, lastName) {
    return await this.post(`${this.baseUrl}/duplicationCheck`, {
      firstName,
      middleName,
      lastName,
    });
  }
}

export default new PersonApi();
