import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import _ from 'lodash';
import accountApi from '../../../apis/account.api';
import lookupApi from '../../../apis/lookup.api';
import { enqueueError } from '../../../modules/global';
import { LookupTypeKey } from '../../../constants';
import auditApi from '../../../apis/audit.api';

const RESET = 'administration/auditManagement/RESET';
const AUDITS_LOADED = 'administration/auditManagement/AUDITS_LOADED';

const resetAction = createAction(RESET);
const auditsLoadedAction = createAction(AUDITS_LOADED);

const initState = {
  loaded: true,
  audits: [],
};

export default handleActions(
  {
    [RESET]: (state, action) =>
      produce(state, draft => {
        draft.loaded = false;
      }),
    [AUDITS_LOADED]: (state, action) =>
      produce(state, draft => {
        draft.audits = action.payload;
        draft.loaded = true;
      }),
  },
  initState,
);

export const queryAuditsByPeriod = params => async dispatch => {
  dispatch(resetAction());
  try {
    const { data } = await auditApi.queryByPeriod(params);
    dispatch(auditsLoadedAction(data));
  } catch (err) {
    dispatch(enqueueError(err));
  }
};
