import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import _ from 'lodash';
import userGroupApi from '../../../apis/userGroup.api';
import { enqueueError } from '../../../modules/global';

const USER_GROUPS_RESET = 'administration/userGroups/USER_GROUPS_RESET';
const USER_GROUP_SELECTED = 'administration/userGroups/USER_GROUP_SELECTED';
const USER_GROUPS_LOADED = 'administration/userGroups/LOADED';
const USER_GROUP_ADDED = 'administration/userGroups/ADDED';
const USER_GROUP_REMOVED = 'administration/userGroups/REMOVED';
const USER_GROUPS_ERROR = 'administration/userGroups/USER_GROUPS_ERROR';
const USER_GROUPS_UPDATED = 'administration/userGroups/USER_GROUPS_UPDATED';
const resetAction = createAction(USER_GROUPS_RESET);
const setSelectedUserGroupAction = createAction(USER_GROUP_SELECTED);
const errorAction = createAction(USER_GROUPS_ERROR);
const loadedAction = createAction(USER_GROUPS_LOADED);
const addedAction = createAction(USER_GROUP_ADDED);
const updatedAction = createAction(USER_GROUPS_UPDATED);
const removedAction = createAction(USER_GROUP_REMOVED);

const initState = {
  loaded: false,
  userGroups: [],
  selectedUserGroup: undefined,
};

export default handleActions(
  {
    [USER_GROUPS_ERROR]: (state, action) =>
      produce(state, draft => {
        draft.loaded = true;
      }),
    [USER_GROUP_SELECTED]: (state, action) =>
      produce(state, draft => {
        draft.loaded = true;
        draft.selectedUserGroup = action.payload;
      }),
    [USER_GROUPS_RESET]: (state, action) =>
      produce(state, draft => {
        draft.loaded = false;
      }),
    [USER_GROUPS_LOADED]: (state, action) =>
      produce(state, draft => {
        draft.userGroups = action.payload;
        draft.loaded = true;
      }),
    [USER_GROUP_ADDED]: (state, action) =>
      produce(state, draft => {
        draft.userGroups.push(action.payload);
        draft.loaded = true;
        draft.selectedUserGroup = action.payload;
      }),
    [USER_GROUPS_UPDATED]: (state, action) =>
      produce(state, draft => {
        const { id } = action.payload;
        const found = draft.userGroups.find(x => x.id === id);
        found && Object.assign(found, action.payload);
        draft.loaded = true;
        draft.selectedUserGroup = action.payload;
      }),
    [USER_GROUP_REMOVED]: (state, action) =>
      produce(state, draft => {
        draft.userGroups = state.userGroups.filter(
          x => x.id !== action.payload,
        );
        draft.loaded = true;
        draft.selectedUserGroup = undefined;
      }),
  },
  initState,
);

export const getAllGroups = () => async dispatch => {
  dispatch(resetAction());
  try {
    const res = await userGroupApi.query();
    dispatch(loadedAction(res.data));
  } catch (err) {
    dispatch(errorAction());
    dispatch(enqueueError(err));
  }
};

export const addGroup = model => async dispatch => {
  dispatch(resetAction());
  try {
    await userGroupApi.add(model);
    dispatch(addedAction(model));
  } catch (err) {
    dispatch(errorAction());
    dispatch(enqueueError(err));
    throw err;
  }
};

export const updateGroup = model => async dispatch => {
  dispatch(resetAction());
  try {
    await userGroupApi.update(model);
    dispatch(updatedAction(model));
  } catch (err) {
    dispatch(errorAction());
    dispatch(enqueueError(err));
  }
};

export const removeGroup = id => async dispatch => {
  dispatch(resetAction());
  try {
    await userGroupApi.remove(id);
    dispatch(removedAction(id));
  } catch (err) {
    dispatch(errorAction());
    dispatch(enqueueError(err));
  }
};

export const setSelectedUserGroup = userGroup => async dispatch => {
  dispatch(setSelectedUserGroupAction(userGroup));
};
