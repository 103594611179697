import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  IconButton,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import AlertDialog from '../../components/dialogs/AlertDialog';
import { IncidentAttachmentDef } from '../../components/propTypeDefs';
import { AttachmentType } from '../../constants';
import Auth from '../../auth/Auth';
import { getAzureFileUrl, downloadFromUrl } from '../../utils/fileUtil';
import EditAttachmentDialog from '../../components/dialogs/EditAttachmentDialog';
import theme from '../../theme';

const useStyles = makeStyles({
  root: {
    // minWidth: 600,
  },
  card: {
    maxWidth: 200,
  },
  media: {
    // ⚠️ object-fit is not supported by IE 11.
    objectFit: 'cover',
  },
  deleteButton: {
    // color: 'red',
  },
  attachmentDesc: {
    color: theme.palette.grey['600'],
  },
});

const mediaThumbnail = require('../../images/multimedia.png');

const getFileUrl = (fileName, thumbnail, sasKey, thumbnailSasKey) => {
  if (!Auth.currentUser) return '';
  return getAzureFileUrl(Auth.currentUser.accountId, fileName, thumbnail, sasKey, thumbnailSasKey);
};

const AttachmentThumbnail = props => {
  const classes = useStyles();
  const { name, fileName, attachmentType, sasKey, thumbnailSasKey} = props.attachment;
  const getMediaCard = () => {
    if (attachmentType === AttachmentType.image) {
      return (
        <CardMedia
          component="img"
          alt={name}
          className={classes.media}
          height="140"
          image={getFileUrl(fileName, true, sasKey, thumbnailSasKey)}
          title={name}
        />
      );
    }
    if (attachmentType === AttachmentType.movie) {
      return (
        <CardMedia
          component="video"
          alt={name}
          className={classes.media}
          height="140"
          image={getFileUrl(fileName, false, sasKey)}
          title={name}
        />
      );
    }

    if (attachmentType === AttachmentType.audio) {
      return (
        <CardMedia
          component="img"
          alt={name}
          className={classes.media}
          height="140"
          image={`${window.location.origin}${mediaThumbnail}`}
          title={name}
        />
      );
    }

    return (
      <Box display="flex" pt={3} justifyContent="center">
        <AttachFileIcon style={{ fontSize: '80px' }} color="disabled" />
      </Box>
    );
  };
  return <>{getMediaCard()}</>;
};
AttachmentThumbnail.propTypes = {
  attachment: IncidentAttachmentDef.isRequired,
};

const IncidentAttachmentVehicle = props => {
  const classes = useStyles();
  const { attachment, editable } = props;
  const { name, fileName, comments, attachmentType } = attachment;
  const [state, setState] = React.useState({
    deleteAlertOpen: false,
    editOpen: false,
  });

  const handleRemove = () => {
    if (!state.deleteAlertOpen) {
      setState({
        ...state,
        deleteAlertOpen: true,
      });
      return;
    }

    props.onDeleted(props.attachment);
    setState({
      ...state,
      deleteAlertOpen: false,
    });
  };

  const closeDeleteAlert = () => {
    setState({
      ...state,
      deleteAlertOpen: false,
    });
  };

  const handleEdit = model => {
    if (!state.editOpen) {
      setState({ ...state, editOpen: true });
      return;
    }
    props.onChange(model);
    setState({ ...state, editOpen: false });
  };

  const closeEdit = () => {
    setState({ ...state, editOpen: false });
  };

  const handleDownload = () => {
    props.onDownload(attachment);
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardActionArea onClick={handleDownload}>
          <AttachmentThumbnail attachment={attachment} />
          <CardContent>
            <Typography gutterBottom variant="body1">
              {name}
            </Typography>
            <Typography variant="body2" className={classes.attachmentDesc}>
              {comments}
            </Typography>
          </CardContent>
        </CardActionArea>

        {editable && (
          <CardActions>
            <IconButton aria-label="Edit" color="primary" onClick={handleEdit}>
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="Delete"
              className={classes.deleteButton}
              color="primary"
              onClick={handleRemove}
            >
              <DeleteIcon />
            </IconButton>
          </CardActions>
        )}
      </Card>
      <AlertDialog
        title={`Delete ${name}`}
        message="Are you sure you want to delete this attachment?"
        open={state.deleteAlertOpen}
        onClose={closeDeleteAlert}
        onCancel={closeDeleteAlert}
        onOk={handleRemove}
      />
      <EditAttachmentDialog
        open={state.editOpen}
        onClose={closeEdit}
        onChange={handleEdit}
        attachment={attachment}
      />
    </div>
  );
};

IncidentAttachmentVehicle.propTypes = {
  attachment: IncidentAttachmentDef.isRequired,
  editable: PropTypes.bool,
  onDeleted: PropTypes.func,
  onChange: PropTypes.func,
  onDownload: PropTypes.func,
};

IncidentAttachmentVehicle.defaultProps = {
  onDeleted: () => {},
  onChange: f => f,
  onDownload: f => f,
  editable: false,
};

export default IncidentAttachmentVehicle;
