import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import _ from 'lodash';
import incidentNotificationApi from '../../../apis/incidentNotification.api';
import lookupApi from '../../../apis/lookup.api';
import { enqueueError } from '../../../modules/global';
import { LookupTypeKey } from '../../../constants';

const RESET = 'administration/notificationManagement/RESET';
const INCIDENT_NOTIFICATIONS_LOADED =
  'administration/notificationManagement/INCIDENT_NOTIFICATION_LOADED';
const INCIDENT_NOTIFICATION_CREATED =
  'administration/notificationManagement/INCIDENT_NOTIFICATION_CREATED';
const INCIDENT_NOTIFICATION_UPDATED =
  'administration/notificationManagement/INCIDENT_NOTIFICATION_UPDATED';
const INCIDENT_NOTIFICATION_DELETED =
  'administration/notificationManagement/INCIDENT_NOTIFICATION_DELETED';
const ERROR = 'administration/notificationManagement/ERROR';
const resetAction = createAction(RESET);
const loadedAction = createAction(INCIDENT_NOTIFICATIONS_LOADED);
const createIncidentNotificationAction = createAction(
  INCIDENT_NOTIFICATION_CREATED,
);
const updateIncidentNotificationAction = createAction(
  INCIDENT_NOTIFICATION_UPDATED,
);
const deleteIncidentNotificationAction = createAction(
  INCIDENT_NOTIFICATION_DELETED,
);
const errorAction = createAction(ERROR);

const initState = {
  loaded: false,
  incidentNotifications: [],
};

export default handleActions(
  {
    [RESET]: (state, action) =>
      produce(state, draft => {
        draft.loaded = false;
      }),
    [ERROR]: (state, action) =>
      produce(state, draft => {
        draft.loaded = true;
      }),
    [INCIDENT_NOTIFICATIONS_LOADED]: (state, action) =>
      produce(state, draft => {
        draft.incidentNotifications = action.payload;
        draft.loaded = true;
      }),
    [INCIDENT_NOTIFICATION_CREATED]: (state, action) =>
      produce(state, draft => {
        draft.incidentNotifications.push(action.payload);
        draft.loaded = true;
      }),
    [INCIDENT_NOTIFICATION_UPDATED]: (state, action) =>
      produce(state, draft => {
        const { id } = action.payload;
        const found = draft.incidentNotifications.find(x => x.id === id);
        found && Object.assign(found, action.payload);
        draft.loaded = true;
      }),
    [INCIDENT_NOTIFICATION_DELETED]: (state, action) =>
      produce(state, draft => {
        draft.incidentNotifications = state.incidentNotifications.filter(
          x => x.id !== action.payload,
        );
        draft.loaded = true;
      }),
  },
  initState,
);

export const getIncidentNotifications = () => async dispatch => {
  dispatch(resetAction());
  incidentNotificationApi
    .getIncidentNotifications()
    .then(res => {
      dispatch(loadedAction(res.data));
    })
    .catch(err => {
      dispatch(errorAction());
      dispatch(enqueueError(err));
    });
};

export const createIncidentNotification = model => async dispatch => {
  dispatch(resetAction());
  try {
    await incidentNotificationApi.createIncidentNotification(model);
    dispatch(createIncidentNotificationAction(model));
  } catch (err) {
    dispatch(errorAction());
    dispatch(enqueueError(err));
  }
};

export const updateIncidentNotification = model => async dispatch => {
  dispatch(resetAction());
  try {
    await incidentNotificationApi.updateIncidentNotification(model);
    dispatch(updateIncidentNotificationAction(model));
  } catch (err) {
    dispatch(errorAction());
    dispatch(enqueueError(err));
  }
};

export const deleteIncidentNotification = id => async dispatch => {
  dispatch(resetAction());
  try {
    const res = await incidentNotificationApi.deleteIncidentNotification(id);
    dispatch(deleteIncidentNotificationAction(id));
  } catch (err) {
    dispatch(errorAction());
    dispatch(enqueueError(err));
  }
};
