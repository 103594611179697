import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Typography,
  FormControl,
  FormControlLabel,
  Switch,
  FormGroup,
  FormLabel,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as yup from 'yup';
import _ from 'lodash';

import RichEditor from '../../components/forms/RichEditor';
import ExpansionCard from '../../components/cards/ExpansionCard';
import {
  IncidentCorrectiveActionDef,
  InvolvedPersonDef,
  LookupDef,
} from '../../components/propTypeDefs';
import { useForm } from '../../utils/hooks';
import {
  uuidPattern,
  CorrectiveActionDisplayType,
  CorrectiveActionReferredType,
} from '../../constants';
import HelpButton from '../../components/buttons/HelpButton';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    minWidth: 150,
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  subCommentForm: {
    flexGrow: 1,
    margin: theme.spacing(1, 0),
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    // alignItems: 'center',
    // height: 80,
    flexWrap: 'wrap',
  },
  correctiveActionName: {
    margin: theme.spacing(1),
  },

  correctiveActionButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  deleteButton: {
    backgroundColor: theme.palette.error.dark,
    color: 'white',
  },
  correctiveActionHeader: {
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
  },
  expansionCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  labelWithHelpIcon: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const schema = yup.object().shape({
  involvedPersons: yup
    .array()
    .of(
      yup.string().matches(uuidPattern, {
        message: 'invalid person id',
      }),
    )
    .nullable(),
  displayType: yup
    .string()
    .nullable()
    .max(20),
  details: yup.object(),
  referredContactFirstName: yup
    .string()
    .nullable()
    .max(100)
    .required("'Person's first name is required"),
  referredContactMiddleName: yup
    .string()
    .nullable()
    .max(100),
  referredContactLastName: yup
    .string()
    .nullable()
    .max(100),
  referredContactAlias: yup
    .string()
    .nullable()
    .max(100),
  referredContactPosition: yup
    .string()
    .nullable()
    .max(100),
  referredContactEmail: yup
    .string()
    .nullable()
    .email(),
  referredContactPhone: yup
    .string()
    .nullable()
    .max(20),
  referredContactMobilePhone: yup
    .string()
    .nullable()
    .max(20),
});

const IncidentCorrectiveActionItem = props => {
  const classes = useStyles();
  const { expanded, correctiveAction } = props;
  const {
    formValues,
    updateFormValues,
    handleFormChange,
    handleFormBlur,
    hasFormError,
    formHelpText,
    handleFormSubmit,
  } = useForm({
    validationSchema: schema,
    onSubmit: (values, errors) => {},
  });

  React.useEffect(() => {
    updateFormValues(correctiveAction);
  }, []);

  React.useEffect(() => {
    if (props.errors && props.errors.length > 0) {
      handleFormSubmit();
    }
  }, [props.errors]);

  React.useEffect(() => {
    if (!_.isEmpty(formValues)) {
      props.onChange(formValues);
    }
  }, [formValues]);

  const inputHandleChange = event => {
    handleFormChange(event);
  };
  const raiseFormEvent = (name, value) => {
    inputHandleChange({ target: { name, value } });
  };
  const displayTypeChange = value => event => {
    if (formValues.displayType === value) {
      raiseFormEvent('displayType', undefined);
    } else {
      raiseFormEvent('displayType', value);
    }
  };

  const onCardExpanded = expand => {
    props.onCardExpanded(expand, correctiveAction.id);
  };

  const handleDetailsChange = value => {
    raiseFormEvent('details', value);
  };

  const deleteCorrectiveAction = () => {
    props.onDeleted(correctiveAction.id);
  };

  const handleInvolvedPersonChange = id => event => {
    if (!formValues.involvedPersonList) {
      formValues.involvedPersonList = [];
    }
    if (event.target.checked) {
      formValues.involvedPersonList.push(id);
    } else {
      _.remove(formValues.involvedPersonList, involvedPersonId => {
        return involvedPersonId === id;
      });
    }

    raiseFormEvent('involvedPersonList', _.uniq(formValues.involvedPersonList));
  };

  const referredTypeChange = value => event => {
    updateFormValues({
      referredInternalDepartmentId: undefined,
      referredExternalAgencyId: undefined,
      referredType: value,
    });
  };

  const getIncidentCorrectiveActionName = () => {
    if (formValues && formValues.typeId) {
      const lookup = _.find(props.correctiveActionTypeLookups, {
        id: formValues.typeId,
      });
      return lookup.name;
    }
    return 'Corrective Action';
  };

  return (
    <ExpansionCard
      title={getIncidentCorrectiveActionName()}
      theme="sub"
      expanded={expanded}
      onChange={onCardExpanded}
      titleStyle={classes.correctiveActionHeader}
    >
      <div className={classes.expansionCardContainer}>
        <div className={classes.expansionCardItem}>
          <Typography variant="h6">Type</Typography>
          <div className={classes.lineItem}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink>Corrective action type</InputLabel>
              <Select
                value={formValues.typeId || ''}
                onChange={inputHandleChange}
                displayEmpty
                name="typeId"
                className={classes.selectEmpty}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {props.correctiveActionTypeLookups.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {/* <FormHelperText>Label + placeholder</FormHelperText> */}
            </FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={
                    formValues.displayType ===
                    CorrectiveActionDisplayType.referred
                  }
                  onChange={displayTypeChange(
                    CorrectiveActionDisplayType.referred,
                  )}
                  value="REFERRED"
                />
              }
              label={<Typography variant="body1">REFERRED</Typography>}
            />
          </div>
          {formValues.displayType === CorrectiveActionDisplayType.referred && (
            <div>
              <Typography variant="h6">REFERRED</Typography>
              <div className={classes.lineItem}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        formValues.referredType ===
                        CorrectiveActionReferredType.internal
                      }
                      onChange={referredTypeChange(
                        CorrectiveActionReferredType.internal,
                      )}
                    />
                  }
                  label={<Typography variant="body1">Internal</Typography>}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        formValues.referredType ===
                        CorrectiveActionReferredType.external
                      }
                      onChange={referredTypeChange(
                        CorrectiveActionReferredType.external,
                      )}
                    />
                  }
                  label={<Typography variant="body1">External</Typography>}
                />
              </div>
              <div className={classes.lineItem}>
                {formValues.referredType ===
                  CorrectiveActionReferredType.internal && (
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink>Department</InputLabel>
                    <Select
                      value={formValues.referredInternalDepartmentId || ''}
                      onChange={inputHandleChange}
                      displayEmpty
                      name="referredInternalDepartmentId"
                      className={classes.selectEmpty}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {props.departmentLookups.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {/* <FormHelperText>Label + placeholder</FormHelperText> */}
                  </FormControl>
                )}
                {formValues.referredType ===
                  CorrectiveActionReferredType.external && (
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink>Outside agency</InputLabel>
                    <Select
                      value={formValues.referredExternalAgencyId || ''}
                      onChange={inputHandleChange}
                      displayEmpty
                      name="referredExternalAgencyId"
                      className={classes.selectEmpty}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {props.agencyLookups.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {/* <FormHelperText>Label + placeholder</FormHelperText> */}
                  </FormControl>
                )}
              </div>

              <div className={classes.lineItem}>
                <Typography variant="h6">Contact</Typography>
              </div>
              <div className={classes.lineItem}>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="referredContactFirstName"
                    label="First name"
                    value={formValues.referredContactFirstName || ''}
                    onChange={inputHandleChange}
                    margin="none"
                    onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('referredContactFirstName')}
                    helperText={formHelpText('referredContactFirstName')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="referredContactMiddleName"
                    label="Middle initial"
                    value={formValues.referredContactMiddleName || ''}
                    onChange={inputHandleChange}
                    margin="none"
                    onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('referredContactMiddleName')}
                    helperText={formHelpText('referredContactMiddleName')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="referredContactLastName"
                    label="Last name"
                    value={formValues.referredContactLastName || ''}
                    onChange={inputHandleChange}
                    margin="none"
                    onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('referredContactLastName')}
                    helperText={formHelpText('referredContactLastName')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="referredContactPosition"
                    label="Position"
                    value={formValues.referredContactPosition || ''}
                    margin="none"
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('referredContactPosition')}
                    helperText={formHelpText('referredContactPosition')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="referredContactMobilePhone"
                    label="Mobile phone #"
                    type="tel"
                    placeholder="ex) 123-456-7890"
                    value={formValues.referredContactMobilePhone || ''}
                    margin="none"
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('referredContactMobilePhone')}
                    helperText={formHelpText('referredContactMobilePhone')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="referredContactPhone"
                    label="Phone #"
                    type="tel"
                    placeholder="ex) 123-456-7890"
                    value={formValues.referredContactPhone || ''}
                    margin="none"
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('referredContactPhone')}
                    helperText={formHelpText('referredContactPhone')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControlLong}>
                  <TextField
                    name="referredContactEmail"
                    label="Email"
                    type="email"
                    value={formValues.referredContactEmail || ''}
                    margin="none"
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('referredContactEmail')}
                    helperText={formHelpText('referredContactEmail')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
              </div>
            </div>
          )}
          <div className={classes.lineItem}>
            <FormControl className={classes.formControlLong}>
              <FormLabel component="legend">
                <Typography variant="h6">
                  Involved persons or legal entities
                </Typography>
              </FormLabel>
              <FormGroup>
                {props.involvedPersons.map(person => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            formValues.involvedPersonList &&
                            formValues.involvedPersonList.includes(person.id)
                          }
                          onChange={handleInvolvedPersonChange(person.id)}
                        />
                      }
                      label={person.name}
                    />
                  );
                })}
              </FormGroup>

              {/* <InputLabel shrink>Involved persons</InputLabel>
              <Select
                value={formValues.involvedPersonId || ''}
                onChange={inputHandleChange}
                displayEmpty
                name="involvedPersonId"
                className={classes.selectEmpty}
              >
                <MenuItem value="" />
                {props.involvedPersons.map(owner => {
                  return (
                    <MenuItem value={owner.id} key={owner.id}>
                      {`${owner.name}`}
                      {` (${
                        owner.personType === 0 ? 'Person' : 'Legal entity'
                      })`}
                    </MenuItem>
                  );
                })}
              </Select> */}
            </FormControl>
          </div>
          <Typography variant="h6" className={classes.labelWithHelpIcon}>
            Comments{' '}
            <HelpButton message="Add more details regarding the corrective action, if necessary." />
          </Typography>
          <FormControl className={classes.subCommentForm}>
            <RichEditor
              height="low"
              onChange={handleDetailsChange}
              defaultValue={formValues.details}
            />
          </FormControl>
          <div className={classes.correctiveActionButton}>
            <Button
              variant="contained"
              className={classes.deleteButton}
              onClick={deleteCorrectiveAction}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </ExpansionCard>
  );
};
IncidentCorrectiveActionItem.propTypes = {
  correctiveAction: IncidentCorrectiveActionDef.isRequired,
  expanded: PropTypes.bool,
  onCardExpanded: PropTypes.func,
  onDeleted: PropTypes.func,
  onChange: PropTypes.func,
  involvedPersons: PropTypes.arrayOf(InvolvedPersonDef),
  correctiveActionTypeLookups: PropTypes.arrayOf(LookupDef),
  departmentLookups: PropTypes.arrayOf(LookupDef),
  agencyLookups: PropTypes.arrayOf(LookupDef),
  errors: PropTypes.object,
};

IncidentCorrectiveActionItem.defaultProps = {
  expanded: true,
  involvedPersons: [],
  onCardExpanded: () => {},
  onDeleted: () => {},
  onChange: () => {},
  correctiveActionTypeLookups: [],
  departmentLookups: [],
  agencyLookups: [],
};

export default IncidentCorrectiveActionItem;
