import Axios from 'axios';
import auth from '../auth/Auth';

const client = Axios.create({
  baseURL: '/api',
});

client.interceptors.request.use(
  request => {
    if (!request.headers.Authorization) {
      const token = sessionStorage.getItem('jwt');
      token && (request.headers.Authorization = `Bearer ${token}`);
    }
    request.headers['x-timezone-offset'] = new Date().getTimezoneOffset();
    return request;
  },
  error => Promise.reject(error),
);

client.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const { response = error } = error;
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data &&
      error.response.data.code === 'TOKEN_EXPIRED'
    ) {
      auth.logout();
    }

    return Promise.reject(response.data || error);
  },
);

export default client;
