import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import _ from 'lodash';
import { enqueueError } from '../../modules/global';
import draftApi from '../../apis/draftIncident.api';
import incidentApi from '../../apis/incident.api';
import { convertTree } from '../../utils/lookupTreeUtil';
import history from '../../history';
import personApi from '../../apis/person.api';
import companyApi from '../../apis/company.api';
import { draftIncidentUpdateAction } from '../dashboard/ducks';

const RESET = 'newIncident/RESET';
const DRAFTS_QUERIED = 'newIncident/DRAFTS_QUERIED';
const DRAFT_ADDED = 'newIncident/DRAFT_ADDED';
const DRAFT_UPDATED = 'newIncident/DRAFT_UPDATED';
const DRAFT_REMOVED = 'newIncident/DRAFT_REMOVED';
const DRAFT_SELECTED = 'newIncident/DRAFT_SELECTED';
const DRAFT_CHANGED = 'newIncident/DRAFT_CHANGED';

const INCIDENT_SUBMITTED = 'newIncident/INCIDENT_SUBMITTED';
const INCIDENT_CLOSED = 'newIncident/INCIDENT_CLOSED';
const INCIDENT_DELETE_OPEN = 'newIncident/INCIDENT_DELETE_OPEN';
const ATTACHMENT_ADDED = 'newIncident/ATTACHMENT_ADDED';
const ATTACHMENT_REMOVED = 'newIncident/ATTACHMENT_REMOVED';

const PERSON_SEARCHED = 'newIncident/PERSON_SEARCHED';
const PERSON_RESET = 'newIncident/PERSON_RESET';
const COMPANY_SEARCHED = 'newIncident/COMPANY_SEARCHED';
const COMPANY_RESET = 'newIncident/COMPANY_RESET';

const resetAction = createAction(RESET);
const draftQueriedAction = createAction(DRAFTS_QUERIED);
const draftAddedAction = createAction(DRAFT_ADDED);
const draftUpdatedAction = createAction(DRAFT_UPDATED);
const draftRemovedAction = createAction(DRAFT_REMOVED);
const draftSelectedAction = createAction(DRAFT_SELECTED);
const draftChangedAction = createAction(DRAFT_CHANGED);

const incidentSubmittedAction = createAction(INCIDENT_SUBMITTED);
const incidentClosedAction = createAction(INCIDENT_CLOSED);
const setDeleteOpenAction = createAction(INCIDENT_DELETE_OPEN);
const attachmentAddedAction = createAction(ATTACHMENT_ADDED);
const attachmentRemovedAction = createAction(ATTACHMENT_REMOVED);

const personSearchedAction = createAction(PERSON_SEARCHED);
const personResetAction = createAction(PERSON_RESET);

const companySearchedAction = createAction(COMPANY_SEARCHED);
const companyResetAction = createAction(COMPANY_RESET);

const initState = {
  loaded: false,
  draftSelectorOpened: false,
  openDeleteDraft: false,
  submitted: false,

  draftList: [],
  draftIncident: undefined,
  tempDraftIncident: undefined,

  personList: [],
  companyList: [],
};

export default handleActions(
  {
    [RESET]: (state, action) =>
      produce(state, draft => {
        draft.loaded = false;
        draft.submitted = false;
      }),
    [DRAFTS_QUERIED]: (state, action) =>
      produce(state, draft => {
        const list = action.payload;
        draft.draftList = list;
        draft.draftSelectorOpened = list && list.length > 0;
        draft.loaded = true;
      }),
    [DRAFT_REMOVED]: (state, action) =>
      produce(state, draft => {
        draft.draftList = state.draftList.filter(
          x => x.id !== action.payload.id,
        );
        draft.loaded = true;
        draft.draftIncident = undefined;
        draft.tempDraftIncident = undefined;
        draft.openDeleteDraft = false;
        draft.submitted = true;
      }),
    [DRAFT_ADDED]: (state, action) =>
      produce(state, draft => {
        draft.draftList.push(action.payload);
      }),
    [DRAFT_UPDATED]: (state, action) =>
      produce(state, draft => {
        const { id } = action.payload;
        draft.tempDraftIncident = action.payload;
        const found = draft.draftList.find(x => x.id === id);
        found && Object.assign(found, action.payload);
        draft.loaded = true;
      }),
    [DRAFT_SELECTED]: (state, action) =>
      produce(state, draft => {
        draft.draftSelectorOpened = false;
        const selectedItem = action.payload;
        draft.draftIncident = selectedItem;
        draft.tempDraftIncident = selectedItem;
        draft.submitted = false;
      }),
    [DRAFT_CHANGED]: (state, action) =>
      produce(state, draft => {
        const data = action.payload;
        draft.draftIncident && data && Object.assign(draft.draftIncident, data);
      }),
    [INCIDENT_SUBMITTED]: (state, action) =>
      produce(state, draft => {
        draft.draftIncident = undefined;
        draft.tempDraftIncident = undefined;
        const { id } = action.payload;
        draft.draftList = state.draftList.filter(x => x.id === id);
        draft.submitted = true;
        draft.loaded = true;
      }),
    [INCIDENT_CLOSED]: (state, action) =>
      produce(state, draft => {
        const { id } = action.payload;
        draft.draftList = state.draftList.filter(x => x.id === id);
        draft.draftIncident = undefined;
        draft.tempDraftIncident = undefined;
        draft.submitted = true;
        draft.loaded = true;
        draft.openDeleteDraft = false;
      }),
    [INCIDENT_DELETE_OPEN]: (state, action) =>
      produce(state, draft => {
        draft.openDeleteDraft = action.payload;
      }),
    [ATTACHMENT_ADDED]: (state, action) =>
      produce(state, draft => {
        const attachment = action.payload;
        const attachList = state.draftIncident.attachments || [];
        draft.draftIncident.attachments = [...attachList, attachment];
        draft.openAttachment = false;
        draft.loaded = true;
      }),
    [ATTACHMENT_REMOVED]: (state, action) =>
      produce(state, draft => {
        const { id } = action.payload;
        const attachList = state.draftIncident.attachments || [];
        draft.draftIncident.attachments = attachList.filter(x => x.id !== id);
        draft.loaded = true;
      }),
    [PERSON_SEARCHED]: (state, action) =>
      produce(state, draft => {
        const list = action.payload;
        draft.personList = list;
        draft.loaded = true;
      }),
    [PERSON_RESET]: (state, action) =>
      produce(state, draft => {
        draft.personList = [];
        draft.loaded = true;
      }),
    [COMPANY_SEARCHED]: (state, action) =>
      produce(state, draft => {
        const list = action.payload;
        draft.companyList = list;
        draft.loaded = true;
      }),
    [COMPANY_RESET]: (state, action) =>
      produce(state, draft => {
        draft.companyList = [];
        draft.loaded = true;
      }),
  },
  initState,
);

export const setSelectedDraft = doc => draftSelectedAction(doc);
export const setOpenDeleteDraft = open => setDeleteOpenAction(open);
export const createDraftIncident = () => async dispatch => {
  dispatch(resetAction());
  try {
    const { data } = await draftApi.create();
    dispatch(setSelectedDraft(data));
    dispatch(draftAddedAction(data));
  } catch (err) {
    dispatch(enqueueError(err));
    throw err;
  }
};
export const queryDraftIncidents = status => async dispatch => {
  dispatch(resetAction());
  try {
    const { data } = await draftApi.query(status);
    if (data.length > 0) {
      dispatch(draftQueriedAction(data));
    } else {
      dispatch(createDraftIncident());
    }
  } catch (err) {
    dispatch(enqueueError(err));
    throw err;
  }
};

const debounceDashboardUpdate = _.debounce((dispatch, payload) =>
  dispatch(draftIncidentUpdateAction(payload), 200),
);
export const changeDraftIncident = data => async (dispatch, getState) => {
  const {
    newIncident: { draftIncident },
  } = getState();

  // update the draft incident in dashboard
  if (draftIncident) {
    debounceDashboardUpdate(dispatch, { id: draftIncident.id, data });
  }

  dispatch(draftChangedAction(data));
};
export const updateDraftIncident = savedCallback => async (
  dispatch,
  getState,
) => {
  const {
    newIncident: { draftIncident, tempDraftIncident },
  } = getState();
  // auto-save action is canceled if already submitted
  if (!draftIncident) return;

  // check diff
  if (_.isEqual(draftIncident, tempDraftIncident)) {
    return;
  }

  savedCallback();
  dispatch(resetAction());
  try {
    await draftApi.update(draftIncident);
    dispatch(draftUpdatedAction(draftIncident));
  } catch (err) {
    dispatch(enqueueError(err));
    throw err;
  }
};
export const removeDraftIncident = model => async dispatch => {
  dispatch(resetAction());
  try {
    await draftApi.remove(model.id);
    dispatch(draftRemovedAction(model));
    history.push(`/dashboard`);
  } catch (err) {
    dispatch(enqueueError(err));
    throw err;
  }
};
export const submitIncident = close => async (dispatch, getState) => {
  dispatch(resetAction());

  const {
    newIncident: { draftIncident },
  } = getState();
  const { location, ...others } = draftIncident;
  const model = Object.assign({ close }, location, others);

  try {
    const res = await incidentApi.submit(model);
    if (res.data && res.data.id) {
      dispatch(incidentSubmittedAction(model));
      history.push(`/dashboard`); // @TODO : remove
    }
  } catch (err) {
    dispatch(enqueueError(err));
    throw err;
  }
};

export const closeIncident = id => async dispatch => {
  dispatch(resetAction());
  try {
    await incidentApi.close(id);
    dispatch(incidentClosedAction(id));
    history.push(`/dashboard`);
  } catch (err) {
    dispatch(enqueueError(err));
    throw err;
  }
};

export const uploadAttachment = formData => async (dispatch, getState) => {
  dispatch(resetAction());
  try {
    const {
      newIncident: { draftIncident },
    } = getState();
    const { data } = await incidentApi.addAttachment(formData);
    dispatch(attachmentAddedAction(data));

    // update the draft incident in dashboard
    if (draftIncident) {
      debounceDashboardUpdate(dispatch, {
        id: draftIncident.id,
        data: {
          attachments: draftIncident.attachments
            ? [...draftIncident.attachments, data]
            : [data],
        },
      });
    }
  } catch (err) {
    dispatch(enqueueError(err));
    throw err;
  }
};
export const deleteAttachment = attachment => async (dispatch, getState) => {
  dispatch(resetAction());
  try {
    const {
      newIncident: { draftIncident },
    } = getState();
    dispatch(attachmentRemovedAction(attachment));

    // update the draft incident in dashboard
    if (draftIncident) {
      debounceDashboardUpdate(dispatch, {
        id: draftIncident.id,
        data: {
          attachments: draftIncident.attachments.filter(
            x => x.id !== attachment.id,
          ),
        },
      });
    }
  } catch (err) {
    dispatch(enqueueError(err));
    throw err;
  }
};
export const searchPerson = name => async dispatch => {
  dispatch(resetAction());
  try {
    const { data } = await personApi.search(name);
    dispatch(personSearchedAction(data));
    (!data || data.length === 0) && dispatch(enqueueError('no search data.'));
  } catch (err) {
    dispatch(enqueueError(err));
    throw err;
  }
};

export const resetPersonList = () => async dispatch => {
  try {
    dispatch(personResetAction());
  } catch (err) {
    dispatch(enqueueError(err));
    throw err;
  }
};

export const searchCompany = name => async dispatch => {
  dispatch(resetAction());
  try {
    const { data } = await companyApi.search(name);
    dispatch(companySearchedAction(data));
    (!data || data.length === 0) && dispatch(enqueueError('no search data.'));
  } catch (err) {
    dispatch(enqueueError(err));
    throw err;
  }
};

export const resetCompanyList = () => async dispatch => {
  try {
    dispatch(companyResetAction());
  } catch (err) {
    dispatch(enqueueError(err));
    throw err;
  }
};
