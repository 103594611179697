import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './index.css';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configStore from './store';

const applicationInsightKey = process.env.APPINSIGHTS_INSTRUMENTATIONKEY;
if (applicationInsightKey) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: applicationInsightKey,
      enableAutoRouteTracking: true,
      autoTrackPageVisitTime: true,
      /* ...Other Configuration Options... */
    },
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

const store = configStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
