import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Chip } from '@material-ui/core';
import PeriodIcon from '@material-ui/icons/DateRange';
import moment from 'moment';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { DefaultDateFormat, AnalysisDatePeriodType } from '../../constants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  paper: {
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  group: {
    margin: 0,
  },
  label: {
    marginLeft: theme.spacing(-1),
  },
  chip: {
    margin: theme.spacing(1),
  },
  searchCriteria: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  searchCriteriaPaper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));

const PeriodItem = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    andOr: 'OR',
  });

  const handleClick = () => {
    props.onClick();
  };

  const getMonthLabel = months => {
    const monthNames = moment.monthsShort();
    let result = '';
    months.forEach(index => {
      result += `${monthNames[index]} `;
    });

    return result.trim();
  };

  const handleDelete = id => {
    props.onDelete(id);
  };

  const getLabel = period => {
    const { type, days, months, fromDate, toDate, periodName } = period;

    if (periodName) {
      return periodName;
    }

    if (type === AnalysisDatePeriodType.lastNDays) {
      return days === 1 ? `Last ${days} day` : `Last ${days} days`;
    }

    if (type === AnalysisDatePeriodType.lastNMonths) {
      if (months === 0) {
        return 'Current month';
      }
      if (months === 1) {
        return 'Previous month';
      }
      return `Last ${months} months`;
    }

    return `${moment(fromDate).format(
      DefaultDateFormat(props.dateFormat).dateMoment,
    )} ~ ${moment(toDate).format(
      DefaultDateFormat(props.dateFormat).dateMoment,
    )}`;
  };

  return (
    <React.Fragment>
      {props.periods.length > 0 && (
        <div className={classes.searchCriteria}>
          <Typography variant="h6">Date occurred: </Typography>
          {props.periods.map(period => (
            <Chip
              key={period.id}
              icon={<PeriodIcon />}
              label={getLabel(period)}
              className={classes.chip}
              color="secondary"
              onClick={handleClick}
              onDelete={() => {
                handleDelete(period.id);
              }}
            />
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

PeriodItem.propTypes = {
  periods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      fromDate: PropTypes.string,
      toDate: PropTypes.string,
    }),
  ),
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  dateFormat: PropTypes.oneOf([0, 1]), // AccountDateFormatEnum
};

PeriodItem.defaultProps = {
  periods: [],
  onClick: () => {},
  onDelete: () => {},
};

export default PeriodItem;
