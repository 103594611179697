import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import moment from 'moment';
import {
  Button,
  Typography,
  TextField,
  FormControl,
  FormControlLabel,
  Switch,
  makeStyles,
  Box,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Checkbox,
} from '@material-ui/core';
import _ from 'lodash';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { FiberNew as NewIcon } from '@material-ui/icons';

import RichEditor from '../../components/forms/RichEditor';
import ExpansionCard from '../../components/cards/ExpansionCard';
import { IncidentPersonDef, LookupDef } from '../../components/propTypeDefs';
import { useForm, useFormInput, useMediaInfo } from '../../utils/hooks';
import { uuidPattern, DefaultDateFormat } from '../../constants';
import CountrySelect from '../../components/forms/CountrySelect';
import CitySearch from '../../components/forms/CitySearch';
import PersonPictureForm from '../../components/forms/PersonPictureForm';
import { enqueueError } from '../../modules/global';
import { getProvinceStateLabel } from '../../utils/addressUtil';
import { getPersonName } from '../../utils/nameUtil';
import {
  getHeightMeasurementLabel,
  getWeightMeasurementLabel,
  getHeightPlaceholder,
} from '../../utils/incidentViewUtil';
import { translateConfig } from '../../utils/simpleTranslateUtil';
import personApi from '../../apis/person.api';
import SearchPersonDialog from '../../components/dialogs/SearchPersonDialog';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
    flexGrow: 1,
  },
  subCommentForm: {
    flexGrow: 1,
    margin: theme.spacing(1, 0),
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  personName: {
    margin: theme.spacing(1),
  },
  itemButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  switchGroup: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  switchGroupLabel: {
    marginRight: theme.spacing(1),
    width: '100%',
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  picture: {},
  expansionCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
  },
  newIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const schema = yup.object().shape({
  firstName: yup
    .string()
    .nullable()
    .max(100)
    .required("'Person's first name is required"),
  middleName: yup
    .string()
    .nullable()
    .max(100),
  lastName: yup
    .string()
    .nullable()
    .max(100),
  alias: yup
    .string()
    .nullable()
    .max(100),
  position: yup
    .string()
    .nullable()
    .max(100),
  unit: yup
    .string()
    .nullable()
    .max(20),
  address: yup
    .string()
    .nullable()
    .max(100),
  street: yup
    .string()
    .nullable()
    .max(50),
  cityId: yup
    .string()
    .nullable()
    .matches(uuidPattern, {
      message: 'invalid cityId',
      excludeEmptyString: true,
    }),
  postalCode: yup
    .string()
    .nullable()
    .max(10),
  email: yup
    .string()
    .nullable()
    .email(),
  phone: yup
    .string()
    .nullable()
    .max(20),
  mobilePhone: yup
    .string()
    .nullable()
    .max(20),
  charHeight: yup
    .string()
    .nullable()
    .max(50),
  charWeight: yup
    .string()
    .nullable()
    .max(50),
  charHairColor: yup
    .string()
    .nullable()
    .max(100),
  charHairLength: yup
    .string()
    .nullable()
    .max(100),
  charHairType: yup
    .string()
    .nullable()
    .max(100),
  charFacialHair: yup
    .string()
    .nullable()
    .max(100),
  charEyeGlasses: yup
    .string()
    .nullable()
    .max(100),
  charOther: yup
    .string()
    .nullable()
    .max(500),
});

const IncidentPersonItem = props => {
  const classes = useStyles();
  const { expanded, person } = props;
  const [defaultCity] = React.useState({
    cityId: person.cityId,
    cityName: person.cityName,
  });

  const [duplicationChecked, setDuplicationChecked] = React.useState({
    checked: person && !!person.personId,
    selectorOpen: false,
    persons: [],
  });

  const { isSmaller } = useMediaInfo({ minBreakPoint: 'sm' });

  const {
    formValues,
    updateFormValues,
    hasFormError,
    formHelpText,
    handleFormChange,
    handleFormBlur,
    handleFormSubmit,
    formErrors,
  } = useForm({
    validationSchema: schema,
    onSubmit: (values, errors) => {},
  });

  React.useEffect(() => {
    updateFormValues({
      ...person,
      duplicationChecked: person.duplicationChecked
        ? person.duplicationChecked
        : !!person.personId,
    });
  }, []);

  React.useEffect(() => {
    if (props.errors && props.errors.length > 0) {
      handleFormSubmit();
    }
  }, [props.errors]);

  React.useEffect(() => {
    !_.isEmpty(formValues) && props.onChange(formValues);
  }, [formValues]);

  const inputHandleChange = event => {
    handleFormChange(event);
  };

  const selectHandleChange = event => {
    const { name, value } = event.target;
    inputHandleChange({ target: { name, value: value || undefined } });
  };

  const raiseFormEvent = (name, value) => {
    inputHandleChange({ target: { name, value } });
  };

  const handleCommentsChange = value => {
    raiseFormEvent('comments', value);
  };

  const handleCityChange = e => {
    const { value: city } = e.target || {};
    const value = (city && city.id) || undefined;
    updateFormValues({
      cityId: value,
      cityName: value ? city.name : undefined,
      stateName: value ? city.stateName : undefined,
      countryCode: value ? city.countryCode : undefined,
    });
  };

  const onCardExpanded = expand => {
    props.onCardExpanded(expand, person.id);
  };

  const deletePerson = () => {
    props.onDeleted(person.id);
  };

  const handlePictureChange = ({ fileName, sasKey }) => {
    updateFormValues(
      { personPicture: fileName, personPictureSasKey: sasKey },
      true,
    );
  };

  const handlePictureError = error => {
    props.enqueueError(error);
  };

  const handleChangeOverwrite = event => {
    event.stopPropagation();
    inputHandleChange(event);
  };

  const handleDateOfBirthChange = date => {
    raiseFormEvent('dateOfBirth', date ? moment(date).toDate() : null);
  };

  const debounceCheckPersonDuplication = _.debounce(() => {
    const { firstName, middleName, lastName } = formValues;
    if (!firstName && !middleName && !lastName) return;

    personApi
      .duplicationCheck(firstName, middleName, lastName)
      .then(response => {
        const duplicatedPersons = response.data;
        if (duplicatedPersons && duplicatedPersons.length > 0) {
          // Open person select
          setDuplicationChecked({
            selectorOpen: true,
            persons: duplicatedPersons,
          });
        } else {
          raiseFormEvent('duplicationChecked', true);
        }
      })
      .catch(err => {});
  }, 1000);

  const checkDuplication = () => {
    debounceCheckPersonDuplication();
  };

  const getTitle = () => {
    const name = getPersonName(person);
    if (!person.personId) {
      return (
        <div className={classes.title}>
          {`${name}`} <NewIcon className={classes.newIcon} />
        </div>
      );
    }
    return name;
  };

  const nameHandleChange = event => {
    if (!person.personId) {
      const target = event.target;
      const value = target.value;
      const name = target.name;
      updateFormValues(
        Object.assign(formValues, { [name]: value, duplicationChecked: false }),
      );
    } else {
      inputHandleChange(event);
    }
  };

  return (
    <ExpansionCard
      title={getTitle()}
      theme="sub"
      expanded={expanded}
      onChange={onCardExpanded}
    >
      <div className={classes.expansionCardContainer}>
        <div className={classes.expansionCardItem}>
          {isSmaller && (
            <>
              <div className={classes.switchGroup}></div>
              <Divider variant="fullWidth" />
            </>
          )}

          <div className={classes.switchGroup}>
            <Typography variant="h6" className={classes.switchGroupLabel}>
              Status
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={formValues.statusInjury || false}
                  onChange={inputHandleChange}
                  name="statusInjury"
                />
              }
              label={<Typography variant="body1">Injured</Typography>}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formValues.statusFirstAid || false}
                  onChange={inputHandleChange}
                  name="statusFirstAid"
                />
              }
              label={
                <Typography variant="body1">Received First Aid</Typography>
              }
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formValues.statusHospitalized || false}
                  onChange={inputHandleChange}
                  name="statusHospitalized"
                />
              }
              label={<Typography variant="body1">Was hospitalized</Typography>}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formValues.statusRefusedHelp || false}
                  onChange={inputHandleChange}
                  name="statusRefusedHelp"
                />
              }
              label={
                <Typography variant="body1">Refused Assistance</Typography>
              }
            />
          </div>
          <Divider variant="fullWidth" />

          <div className={classes.switchGroup}>
            <Typography variant="h6" className={classes.switchGroupLabel}>
              Relation
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={formValues.relationReporter || false}
                  onChange={inputHandleChange}
                  name="relationReporter"
                />
              }
              label={<Typography variant="body1">Reporter</Typography>}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formValues.relationVictim || false}
                  onChange={inputHandleChange}
                  name="relationVictim"
                />
              }
              label={<Typography variant="body1">Victim</Typography>}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formValues.relationPersonOfInterest}
                  onChange={inputHandleChange}
                  name="relationPersonOfInterest"
                />
              }
              label={
                <Typography variant="body1">Person of Interest</Typography>
              }
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formValues.relationSuspect || false}
                  onChange={inputHandleChange}
                  name="relationSuspect"
                />
              }
              label={<Typography variant="body1">Suspect</Typography>}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formValues.relationWitness || false}
                  onChange={inputHandleChange}
                  name="relationWitness"
                />
              }
              label={<Typography variant="body1">Witness</Typography>}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formValues.relationSubject || false}
                  onChange={inputHandleChange}
                  name="relationSubject"
                />
              }
              label={<Typography variant="body1">Subject</Typography>}
            />
          </div>
          <Divider variant="fullWidth" />
          {isSmaller ? (
            <>
              <Box display="flex" flex="1" flexDirection="row">
                <Box flexGrow="1">
                  <div className={classes.lineItem}>
                    <FormControl className={classes.formControl}>
                      <TextField
                        name="firstName"
                        label="First name"
                        value={formValues.firstName || ''}
                        onChange={nameHandleChange}
                        margin="none"
                        onBlur={handleFormBlur}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={hasFormError('firstName')}
                        helperText={formHelpText('firstName')}
                        inputProps={{ autoComplete: 0 }}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        name="middleName"
                        label="Middle initial"
                        value={formValues.middleName || ''}
                        onChange={nameHandleChange}
                        margin="none"
                        onBlur={handleFormBlur}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={hasFormError('middleName')}
                        helperText={formHelpText('middleName')}
                        inputProps={{ autoComplete: 0 }}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        name="lastName"
                        label="Last name"
                        value={formValues.lastName || ''}
                        onChange={nameHandleChange}
                        margin="none"
                        onBlur={handleFormBlur}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={hasFormError('lastName')}
                        helperText={formHelpText('lastName')}
                        inputProps={{ autoComplete: 0 }}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        name="alias"
                        label="Alias"
                        value={formValues.alias || ''}
                        onChange={inputHandleChange}
                        margin="none"
                        onBlur={handleFormBlur}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={hasFormError('alias')}
                        helperText={formHelpText('alias')}
                        inputProps={{ autoComplete: 0 }}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <InputLabel shrink>Comments</InputLabel>
                      <Button
                        variant="contained"
                        // color="red"
                        className={classes.deleteButton}
                        onClick={deletePerson}
                      >
                        Delete
                      </Button>
                    </FormControl>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formValues.isEmployee || false}
                          onChange={inputHandleChange}
                          name="isEmployee"
                        />
                      }
                      label={<Typography variant="body1">Employee</Typography>}
                    />
                  </div>
                </Box>
                <Box className={classes.picture}>
                  <PersonPictureForm
                    personId={person.id}
                    incidentId={person.incidentId}
                    pictureUrl={person.personPicture}
                    onChange={handlePictureChange}
                    onError={handlePictureError}
                    sasKey={person.personPictureSasKey}
                    thumbnailSasKey={undefined}
                  />
                  <div className={classes.lineItem}>
                    <Typography variant="body2">
                      * upload limit: {props.uploadLimit} MB
                    </Typography>
                  </div>
                </Box>
              </Box>
              <div className={classes.lineItem}>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink>Person type</InputLabel>
                  <Select
                    value={formValues.lookupPersonTypeId || ''}
                    onChange={selectHandleChange}
                    displayEmpty
                    name="lookupPersonTypeId"
                    className={classes.selectEmpty}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {props.personTypeLookups.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {/* <FormHelperText>Label + placeholder</FormHelperText> */}
                </FormControl>
                {formValues.isEmployee && (
                  <>
                    <FormControl className={classes.formControlLong}>
                      <InputLabel shrink>Department</InputLabel>
                      <Select
                        value={formValues.lookupDepartmentId || ''}
                        onChange={selectHandleChange}
                        displayEmpty
                        name="lookupDepartmentId"
                        className={classes.selectEmpty}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {props.departmentLookups.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {/* <FormHelperText>Label + placeholder</FormHelperText> */}
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        id="position"
                        label="Position"
                        name="position"
                        value={formValues.position || ''}
                        onChange={inputHandleChange}
                        onBlur={handleFormBlur}
                        error={hasFormError('position')}
                        helperText={formHelpText('position')}
                        margin="none"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{ autoComplete: 0 }}
                      />
                    </FormControl>
                  </>
                )}
                <FormControl className={classes.formControl}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      clearable
                      disableFuture
                      label="Date of birth"
                      format={
                        DefaultDateFormat(props.companySettings.dateFormat).date
                      }
                      name="dateOfBirth"
                      value={
                        formValues.dateOfBirth
                          ? moment(formValues.dateOfBirth).toDate()
                          : null
                      }
                      onChange={handleDateOfBirthChange}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink>Gender</InputLabel>
                  <Select
                    value={formValues.lookupGenderTypeId || ''}
                    onChange={selectHandleChange}
                    displayEmpty
                    name="lookupGenderTypeId"
                    className={classes.selectEmpty}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {props.genderTypeLookups.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {!formValues.isEmployee && (
                <div className={classes.lineItem}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      label="Unit #"
                      margin="none"
                      name="unit"
                      value={formValues.unit || ''}
                      onChange={inputHandleChange}
                      onBlur={handleFormBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={hasFormError('unit')}
                      helperText={formHelpText('unit')}
                      inputProps={{ autoComplete: 0 }}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="address"
                      label="Address #"
                      name="address"
                      value={formValues.address || ''}
                      onChange={inputHandleChange}
                      onBlur={handleFormBlur}
                      error={hasFormError('address')}
                      helperText={formHelpText('address')}
                      margin="none"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ autoComplete: 0 }}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="street"
                      label="Street/Ave"
                      name="street"
                      value={formValues.street || ''}
                      onChange={inputHandleChange}
                      onBlur={handleFormBlur}
                      error={hasFormError('street')}
                      helperText={formHelpText('street')}
                      margin="none"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ autoComplete: 0 }}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <CitySearch
                      defaultCity={defaultCity}
                      onChange={handleCityChange}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      label={getProvinceStateLabel(formValues.countryCode)}
                      disabled
                      value={formValues.stateName || ''}
                      margin="none"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      label="Country"
                      disabled
                      value={formValues.countryCode || ''}
                      margin="none"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="postalCode"
                      label="Zip/Postal code"
                      className={classes.postalCode}
                      name="postalCode"
                      value={formValues.postalCode || ''}
                      onChange={inputHandleChange}
                      onBlur={handleFormBlur}
                      error={hasFormError('postalCode')}
                      helperText={formHelpText('postalCode')}
                      margin="none"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ autoComplete: 0 }}
                    />
                  </FormControl>
                </div>
              )}

              <div className={classes.lineItem}>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="phone"
                    label="Phone #"
                    type="tel"
                    placeholder="ex) 123-456-7890"
                    value={formValues.phone || ''}
                    margin="none"
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('phone')}
                    helperText={formHelpText('phone')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="mobilePhone"
                    label="Mobile Phone #"
                    type="tel"
                    placeholder="ex) 123-456-7890"
                    value={formValues.mobilePhone || ''}
                    margin="none"
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('mobilePhone')}
                    helperText={formHelpText('mobilePhone')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControlLong}>
                  <TextField
                    name="email"
                    label="Email"
                    type="email"
                    value={formValues.email || ''}
                    margin="none"
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('email')}
                    helperText={formHelpText('email')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
              </div>
            </>
          ) : (
            <Box display="flex" flex="1" flexDirection="row">
              <Box flexGrow="1">
                <div className={classes.lineItem}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      name="firstName"
                      required
                      label="First name"
                      value={formValues.firstName || ''}
                      onChange={nameHandleChange}
                      margin="none"
                      onBlur={handleFormBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={hasFormError('firstName')}
                      helperText={formHelpText('firstName')}
                      inputProps={{ autoComplete: 0 }}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      name="middleName"
                      label="Middle initial"
                      value={formValues.middleName || ''}
                      onChange={nameHandleChange}
                      margin="none"
                      onBlur={handleFormBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={hasFormError('middleName')}
                      helperText={formHelpText('middleName')}
                      inputProps={{ autoComplete: 0 }}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      name="lastName"
                      label="Last name"
                      value={formValues.lastName || ''}
                      onChange={nameHandleChange}
                      margin="none"
                      onBlur={handleFormBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={hasFormError('lastName')}
                      helperText={formHelpText('lastName')}
                      inputProps={{ autoComplete: 0 }}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      name="alias"
                      label="Alias"
                      value={formValues.alias || ''}
                      onChange={inputHandleChange}
                      margin="none"
                      onBlur={handleFormBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={hasFormError('alias')}
                      helperText={formHelpText('alias')}
                      inputProps={{ autoComplete: 0 }}
                    />
                  </FormControl>

                  {!person.personId && (
                    <FormControl className={classes.formControl}>
                      {formValues.duplicationChecked ? (
                        <FormControlLabel
                          value="top"
                          control={
                            <Checkbox checked={formValues.duplicationChecked} />
                          }
                          label="Duplication checked"
                        />
                      ) : (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={checkDuplication}
                        >
                          Check Duplication
                        </Button>
                      )}
                    </FormControl>
                  )}

                  <FormControlLabel
                    control={
                      <Switch
                        checked={formValues.isEmployee || false}
                        onChange={inputHandleChange}
                        name="isEmployee"
                      />
                    }
                    label={<Typography variant="body1">Employee</Typography>}
                  />
                </div>
                <div className={classes.lineItem}>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink>Person type</InputLabel>
                    <Select
                      value={formValues.lookupPersonTypeId || ''}
                      onChange={selectHandleChange}
                      displayEmpty
                      name="lookupPersonTypeId"
                      className={classes.selectEmpty}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {props.personTypeLookups.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {/* <FormHelperText>Label + placeholder</FormHelperText> */}
                  </FormControl>
                  {formValues.isEmployee && (
                    <>
                      <FormControl className={classes.formControl}>
                        <InputLabel shrink>Department</InputLabel>
                        <Select
                          value={formValues.lookupDepartmentId || ''}
                          onChange={selectHandleChange}
                          displayEmpty
                          name="lookupDepartmentId"
                          className={classes.selectEmpty}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {props.departmentLookups.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {/* <FormHelperText>Label + placeholder</FormHelperText> */}
                      </FormControl>
                      <FormControl className={classes.formControl}>
                        <TextField
                          id="position"
                          label="Position"
                          name="position"
                          value={formValues.position || ''}
                          onChange={inputHandleChange}
                          onBlur={handleFormBlur}
                          error={hasFormError('position')}
                          helperText={formHelpText('position')}
                          margin="none"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ autoComplete: 0 }}
                        />
                      </FormControl>
                    </>
                  )}
                  <FormControl className={classes.formControl}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        clearable
                        disableFuture
                        label="Date of birth"
                        format={
                          DefaultDateFormat(props.companySettings.dateFormat)
                            .date
                        }
                        name="dateOfBirth"
                        value={
                          formValues.dateOfBirth
                            ? moment(formValues.dateOfBirth).toDate()
                            : null
                        }
                        onChange={handleDateOfBirthChange}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink>Gender</InputLabel>
                    <Select
                      value={formValues.lookupGenderTypeId || ''}
                      onChange={selectHandleChange}
                      displayEmpty
                      name="lookupGenderTypeId"
                      className={classes.selectEmpty}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {props.genderTypeLookups.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {!formValues.isEmployee && (
                  <div className={classes.lineItem}>
                    <FormControl className={classes.formControl}>
                      <TextField
                        label="Unit #"
                        margin="none"
                        name="unit"
                        value={formValues.unit || ''}
                        onChange={inputHandleChange}
                        onBlur={handleFormBlur}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={hasFormError('unit')}
                        helperText={formHelpText('unit')}
                        inputProps={{ autoComplete: 0 }}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        id="address"
                        label="Address #"
                        name="address"
                        value={formValues.address || ''}
                        onChange={inputHandleChange}
                        onBlur={handleFormBlur}
                        error={hasFormError('address')}
                        helperText={formHelpText('address')}
                        margin="none"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{ autoComplete: 0 }}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        id="street"
                        label="Street/Ave"
                        name="street"
                        value={formValues.street || ''}
                        onChange={inputHandleChange}
                        onBlur={handleFormBlur}
                        error={hasFormError('street')}
                        helperText={formHelpText('street')}
                        margin="none"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{ autoComplete: 0 }}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <CitySearch
                        defaultCity={defaultCity}
                        countryCode={formValues.countryCode}
                        onChange={handleCityChange}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        label={getProvinceStateLabel(formValues.countryCode)}
                        disabled
                        value={formValues.stateName || ''}
                        margin="none"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        label="Country"
                        disabled
                        value={formValues.countryCode || ''}
                        margin="none"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        id="postalCode"
                        label="Zip/Postal code"
                        className={classes.postalCode}
                        name="postalCode"
                        value={formValues.postalCode || ''}
                        onChange={inputHandleChange}
                        onBlur={handleFormBlur}
                        error={hasFormError('postalCode')}
                        helperText={formHelpText('postalCode')}
                        margin="none"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{ autoComplete: 0 }}
                      />
                    </FormControl>
                  </div>
                )}

                <div className={classes.lineItem}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      name="phone"
                      label="Phone #"
                      type="tel"
                      placeholder="ex) 123-456-7890"
                      value={formValues.phone || ''}
                      margin="none"
                      onChange={inputHandleChange}
                      onBlur={handleFormBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={hasFormError('phone')}
                      helperText={formHelpText('phone')}
                      inputProps={{ autoComplete: 0 }}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      name="mobilePhone"
                      label="Mobile Phone #"
                      type="tel"
                      placeholder="ex) 123-456-7890"
                      value={formValues.mobilePhone || ''}
                      margin="none"
                      onChange={inputHandleChange}
                      onBlur={handleFormBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={hasFormError('mobilePhone')}
                      helperText={formHelpText('mobilePhone')}
                      inputProps={{ autoComplete: 0 }}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlLong}>
                    <TextField
                      name="email"
                      label="Email"
                      type="email"
                      value={formValues.email || ''}
                      margin="none"
                      onChange={inputHandleChange}
                      onBlur={handleFormBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={hasFormError('email')}
                      helperText={formHelpText('email')}
                      inputProps={{ autoComplete: 0 }}
                    />
                  </FormControl>
                </div>
              </Box>
              <Box className={classes.picture}>
                <PersonPictureForm
                  personId={person.id}
                  incidentId={person.incidentId}
                  pictureUrl={person.personPicture}
                  onChange={handlePictureChange}
                  onError={handlePictureError}
                  sasKey={person.personPictureSasKey}
                  thumbnailSasKey={undefined}
                />
                <div className={classes.lineItem}>
                  <Typography variant="body2">
                    * upload limit: {props.uploadLimit} MB
                  </Typography>
                </div>
              </Box>
            </Box>
          )}
          {!formValues.isEmployee && (
            <>
              <Typography variant="h6">Description</Typography>
              <div className={classes.lineItem}>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="charHeight"
                    label={`Height (${getHeightMeasurementLabel()})`}
                    value={formValues.charHeight || ''}
                    margin="none"
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('charHeight')}
                    helperText={formHelpText('charHeight')}
                    inputProps={{ autoComplete: 0 }}
                    placeholder={getHeightPlaceholder()}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="charWeight"
                    label={`Weight (${getWeightMeasurementLabel()})`}
                    value={formValues.charWeight || ''}
                    margin="none"
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('charWeight')}
                    helperText={formHelpText('charWeight')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="charHairColor"
                    label="Hair Color"
                    value={formValues.charHairColor || ''}
                    margin="none"
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('charHairColor')}
                    helperText={formHelpText('charHairColor')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="charHairLength"
                    label="Hair Length"
                    value={formValues.charHairLength || ''}
                    margin="none"
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('charHairLength')}
                    helperText={formHelpText('charHairLength')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="charHairType"
                    label="Hair Type"
                    value={formValues.charHairType || ''}
                    margin="none"
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('charHairType')}
                    helperText={formHelpText('charHairType')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="charFacialHair"
                    label="Facial Hair"
                    value={formValues.charFacialHair || ''}
                    margin="none"
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('charFacialHair')}
                    helperText={formHelpText('charFacialHair')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="charEyeGlasses"
                    label="Eye Glasses"
                    value={formValues.charEyeGlasses || ''}
                    margin="none"
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('charEyeGlasses')}
                    helperText={formHelpText('charEyeGlasses')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControlLong}>
                  <TextField
                    name="charOther"
                    label="Distinguishing features"
                    value={formValues.charOther || ''}
                    margin="none"
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('charOther')}
                    helperText={formHelpText('charOther')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
              </div>
            </>
          )}

          <Typography variant="h6">Comments</Typography>
          <FormControl className={classes.subCommentForm}>
            <RichEditor
              height="low"
              onChange={handleCommentsChange}
              defaultValue={formValues.comments}
            />
          </FormControl>
          <div className={classes.itemButton}>
            <Button
              variant="contained"
              className={classes.deleteButton}
              onClick={deletePerson}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
      <SearchPersonDialog
        open={duplicationChecked.selectorOpen}
        type="SELECT"
        persons={duplicationChecked.persons}
        onPersonSelected={personSelected => {
          const { id: personId, ...others } = personSelected;
          const compressed = _.omitBy(others, _.isNull);
          const newPerson = {
            ...compressed,
            personId,
            duplicationChecked: true,
          };
          updateFormValues(newPerson);
          setDuplicationChecked({
            selectorOpen: false,
            persons: [],
          });
        }}
        onClose={() => {
          setDuplicationChecked({
            selectorOpen: false,
            persons: [],
          });
        }}
      />
    </ExpansionCard>
  );
};

IncidentPersonItem.propTypes = {
  person: IncidentPersonDef.isRequired,
  expanded: PropTypes.bool,
  onCardExpanded: PropTypes.func,
  onDeleted: PropTypes.func,
  onChange: PropTypes.func,
  enqueueError: PropTypes.func.isRequired,
  personTypeLookups: PropTypes.arrayOf(LookupDef),
  departmentLookups: PropTypes.arrayOf(LookupDef),
  genderTypeLookups: PropTypes.arrayOf(LookupDef),
  errors: PropTypes.object,
  uploadLimit: PropTypes.number,
};

IncidentPersonItem.defaultProps = {
  expanded: true,
  onCardExpanded: () => {},
  onDeleted: () => {},
  onChange: () => {},
  personTypeLookups: [],
  departmentLookups: [],
  genderTypeLookups: [],
};

const mapStateToProps = ({ common }) => ({
  uploadLimit: common.fileSizeLimit.maxAvatarFileSize,
  companySettings: common.companySettings,
});

const mapDispatchToProps = {
  enqueueError,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentPersonItem);
