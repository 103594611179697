import React from 'react';
import PropTypes from 'prop-types';
import { Button, LinearProgress } from '@material-ui/core';
import Cropper from 'react-cropper';
import BaseDialog from './BaseDialog';
// eslint-disable-next-line
import 'cropperjs/dist/cropper.css';

const ImageCropperDialog = props => {
  const { options } = props;
  const cropperRef = React.useRef(); // for cropper control
  const cropResult = React.useRef(null); // fro cropped image
  const [uploading, setUploading] = React.useState(false);

  React.useEffect(() => {
    return () => {
      cropperRef.current = null;
      cropResult.current = null;
    };
  }, []);

  const handleClose = () => {
    props.onClose();
  };
  const handleChange = () => {
    setUploading(true);
    props
      .onCrop(cropResult.current)
      .then(() => {
        setUploading(false);
      })
      .catch(() => {
        setUploading(false);
      });
  };
  const handleCrop = () => {
    cropperRef.current.getCroppedCanvas().toBlob(blob => {
      cropResult.current = blob;
    });
  };
  return (
    <BaseDialog
      open={props.open}
      maxWidth="sm"
      title="Confirm Image"
      onClose={handleClose}
      actionContent={
        <>
          <Button onClick={handleChange} variant="contained" color="primary">
            Ok
          </Button>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
        </>
      }
    >
      <>
        {uploading ? <LinearProgress /> : undefined}
        <Cropper
          ref={cropperRef}
          src={props.src}
          viewMode={1}
          background
          style={{ height: 300, width: '100%' }}
          aspectRatio={options.aspect}
          guides={options.guides}
          crop={handleCrop}
        />
      </>
    </BaseDialog>
  );
};

ImageCropperDialog.propTypes = {
  open: PropTypes.bool,
  src: PropTypes.string.isRequired,
  options: PropTypes.object,
  onCrop: PropTypes.func,
  onClose: PropTypes.func,
};

ImageCropperDialog.defaultProps = {
  open: false,
  options: {
    unit: 'px',
    width: 300,
    aspect: 1,
  },
  onCrop: f => f,
  onClose: f => f,
};

export default ImageCropperDialog;
