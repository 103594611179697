import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Button,
  FormControl,
  TextField,
  Box,
} from '@material-ui/core';
import * as yup from 'yup';
import _ from 'lodash';
import uuid from 'uuid/v4';

import BaseDialog from '../../components/dialogs/BaseDialog';
import CitySearch from '../../components/forms/CitySearch';
import { useFormInput, useForm } from '../../utils/hooks';
import { uuidPattern } from '../../constants';
import CountrySelect from '../../components/forms/CountrySelect';
import { LookupDef } from '../../components/propTypeDefs';
import { getProvinceStateLabel } from '../../utils/addressUtil';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  redButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  rightActions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const schema = yup.object().shape({
  unit: yup
    .string()
    .nullable()
    .max(20),
  address: yup
    .string()
    .nullable()
    .max(100),
  street: yup
    .string()
    .nullable()
    .max(50),
  cityId: yup
    .string()
    .nullable()
    .matches(uuidPattern, {
      message: 'invalid cityId',
      excludeEmptyString: true,
    }),
  postalCode: yup
    .string()
    .nullable()
    .max(10),
});

const LocationFormDialog = props => {
  const classes = useStyles();
  const { lookup, open } = props;
  const [defaultCity, setDefaultCity] = React.useState(undefined);
  const toModel = ({ unit, address, street, cityId, postalCode } = {}) => ({
    unit,
    address,
    street,
    cityId,
    postalCode,
  });
  const [defaultValue] = React.useState(
    props.mode === 'add' ? {} : toModel(props.address),
  );

  const {
    formValues,
    updateFormValues,
    hasFormError,
    formHelpText,
    handleFormChange,
    handleFormBlur,
    handleFormSubmit,
  } = useForm({
    onSubmit(values, errors) {
      if (_.isEmpty(errors)) {
        const { name } = lookup;
        const model = {
          ...values,
          name,
          id: uuid(),
          lookupLocationId: lookup.id,
        };

        props.onSave(lookup, model);
      }
    },
    validationSchema: schema,
  });

  React.useEffect(() => {
    if (props.mode === 'update' && props.address) {
      updateFormValues(toModel(props.address));
      const { cityId, cityName } = props.address;
      cityId && cityName && setDefaultCity({ cityId, cityName });
    }
  }, [props.address]);

  const handleCityChange = e => {
    const { value: city } = e.target || {};
    const value = (city && city.id) || undefined;
    updateFormValues({
      cityId: value,
      cityName: value ? city.name : undefined,
      stateName: value ? city.stateName : undefined,
      countryCode: value ? city.countryCode : undefined,
    });
  };

  return (
    <BaseDialog
      open={open}
      maxWidth="md"
      title="Attach address"
      onClose={props.onClose}
      actionContent={
        <>
          {props.mode === 'update' && (
            <Box flexGrow={1}>
              <Button
                onClick={() => props.onDelete(props.address)}
                variant="contained"
                disabled={!props.address}
                className={classes.redButton}
              >
                Delete
              </Button>
            </Box>
          )}
          <Box flexGrow={0} className={classes.rightActions}>
            <Button
              onClick={handleFormSubmit}
              variant="contained"
              disabled={props.mode === 'update' && !props.address}
              color="primary"
            >
              Ok
            </Button>
            <Button onClick={props.onClose} color="default" pl={3}>
              Cancel
            </Button>
          </Box>
        </>
      }
    >
      <Box
        flexDirection="row"
        flexWrap="wrap"
        className={classes.formGroup}
        p={1}
      >
        <FormControl className={classes.formControl}>
          <TextField
            id="buildingUnit"
            label="Unit #"
            margin="none"
            name="unit"
            value={formValues.unit || ''}
            onChange={handleFormChange}
            onBlur={handleFormBlur}
            InputLabelProps={{
              shrink: true,
            }}
            error={hasFormError('unit')}
            helperText={formHelpText('unit')}
            inputProps={{ autoComplete: 0 }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="address"
            label="Address #"
            name="address"
            value={formValues.address || ''}
            onChange={handleFormChange}
            onBlur={handleFormBlur}
            error={hasFormError('address')}
            helperText={formHelpText('address')}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ autoComplete: 0 }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="street"
            label="Street/Ave"
            name="street"
            value={formValues.street || ''}
            onChange={handleFormChange}
            onBlur={handleFormBlur}
            error={hasFormError('street')}
            helperText={formHelpText('street')}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ autoComplete: 0 }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <CitySearch
            defaultCity={defaultCity}
            value={formValues.cityId}
            onChange={handleCityChange}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label={getProvinceStateLabel(formValues.countryCode)}
            disabled
            value={formValues.stateName || ''}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Country"
            disabled
            value={formValues.countryCode || ''}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="postalCode"
            label="Zip/Postal code"
            className={classes.postalCode}
            name="postalCode"
            value={formValues.postalCode || ''}
            onChange={handleFormChange}
            onBlur={handleFormBlur}
            error={hasFormError('postalCode')}
            helperText={formHelpText('postalCode')}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ autoComplete: 0 }}
          />
        </FormControl>
      </Box>
    </BaseDialog>
  );
};

LocationFormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  lookup: LookupDef,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  mode: PropTypes.string.isRequired,
  address: PropTypes.object,
};

LocationFormDialog.defaultProps = {
  lookup: {},
  onClose: f => f,
  onSave: f => f,
  onDelete: f => f,
};

export default LocationFormDialog;
