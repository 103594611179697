import React from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableBody,
  TableRow,
  IconButton,
  Table,
  TableHead,
  TableFooter,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Fab,
  Avatar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { getPersonName } from '../../utils/nameUtil';
import { flattenCharacteristicsComponent } from '../../utils/incidentViewUtil';
import { IncidentPersonDef } from '../../components/propTypeDefs';
import { useMediaInfo } from '../../utils/hooks';
import { getAzureFileUrl } from '../../utils/fileUtil';
import Auth from '../../auth/Auth';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 700,
    padding: theme.spacing(1),
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  nameCell: {
    display: 'flex',
  },
  typeCell: {},
  actionCell: {},
  cardContent: {
    padding: theme.spacing(1, 2),
  },
  cardFieldRow: {},
  cardLabel: {
    marginRight: theme.spacing(2),
  },
  avatar: {
    width: 24,
    height: 24,
    marginLeft: theme.spacing(1),
  },
}));

const PersonList = props => {
  const classes = useStyles();
  const { isSmaller, isMobile } = useMediaInfo({ minBreakPoint: 'md' });

  const handleSelectPerson = person => event => {
    props.onItemCommand('select', person);
  };

  return (
    <>
      {isSmaller ? (
        <Grid spacing={1} container>
          {props.persons.map(person => (
            <Grid item key={person.id} xs={12} sm={12}>
              <Card>
                <CardContent className={classes.cardContent}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography variant="h6">
                      <div className={classes.nameCell}>
                        {getPersonName(person)}
                        {person.personPicture && (
                          <Avatar
                            src={getAzureFileUrl(
                              Auth.currentUser.accountId,
                              person.personPicture,
                              true,
                              person.sasKey,
                              person.thumbnailSasKey
                            )}
                            className={classes.avatar}
                          />
                        )}
                      </div>
                    </Typography>
                    <Button
                      onClick={handleSelectPerson(person)}
                      variant="contained"
                      color="primary"
                    >
                      Add
                    </Button>
                  </Box>
                  {person.lookupPersonTypeName && (
                    <div className={classes.cardFieldRow}>
                      <strong className={classes.cardLabel}>Type</strong>
                      <span>{person.lookupPersonTypeName}</span>
                    </div>
                  )}
                  {person.dateOfBirth && (
                    <div className={classes.cardFieldRow}>
                      <strong className={classes.cardLabel}>DOB</strong>
                      <span>{person.dateOfBirth}</span>
                    </div>
                  )}
                  <div className={classes.personDescriptions}>
                    {flattenCharacteristicsComponent(person)}
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>DOB</TableCell>
              <TableCell>Description</TableCell>
              <TableCell className={classes.actionCell} />
            </TableRow>
          </TableHead>
          <TableBody>
            {props.persons.map(person => (
              <TableRow key={person.id}>
                <TableCell scope="row">
                  <div className={classes.nameCell}>
                    {getPersonName(person)}
                    {person.personPicture && (
                      <Avatar
                        src={getAzureFileUrl(
                          Auth.currentUser.accountId,
                          person.personPicture,
                          true,
                        )}
                        className={classes.avatar}
                      />
                    )}
                  </div>
                </TableCell>
                <TableCell scope="row">{person.lookupPersonTypeName}</TableCell>
                <TableCell scope="row">{person.dateOfBirth}</TableCell>
                <TableCell>{flattenCharacteristicsComponent(person)}</TableCell>
                <TableCell>
                  <Button
                    onClick={handleSelectPerson(person)}
                    variant="contained"
                    color="primary"
                  >
                    {props.type}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

PersonList.propTypes = {
  type: PropTypes.oneOf(['ADD', 'SELECT']),
  persons: PropTypes.arrayOf(IncidentPersonDef).isRequired,
  onItemCommand: PropTypes.func,
};
PersonList.defaultProps = {
  type: 'ADD',
  onItemCommand: f => f,
};

export default PersonList;
