import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter as Router } from 'connected-react-router';
import { isMobile } from 'react-device-detect';

import Dashboard from './views/dashboard/index';
import Administration from './views/administration/index';
import NewIncident from './views/newIncident/index';
import Main from './views/main/index';
import Reports from './views/reports';
import Signup from './views/signup';
import Search from './views/search/index';
import Analysis from './views/analyze/index';
import ScrollToTop from './components/ScrollTop';
import Callback from './callback/Callback';
import auth from './auth/Auth';
import history from './history';
import PrivateRoute from './components/navs/PrivateRoute';
import Profile from './views/profile';
import CustomError from './views/errors';
import ExternalRedirect from './components/navs/ExternalRedirect';
import config, { getEnvironment } from './config';
import mfa from './views/login/mfa';
import StartMfaEnrolloment from './views/login/startMfaEnrolloment';
import VerifyMfaEnrollment from './views/login/verifyMfaEnrollment';
import Change2FAPhone from './views/login/change2FAPhone';
import Verify2FAPhone from './views/login/verify2FAPhone';

const handleAuthentication = ({ location }) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication();
  }
};

const handleLogin = props => {
  auth.login();
};

const env = getEnvironment();

export default props => (
  <Router history={history}>
    <ScrollToTop>
      <Switch>
        {env === 'prod' ? (
          <Route
            exact
            path="/"
            render={() => {
              return (
                <ExternalRedirect loc={config.url.incidentXpressSiteUrl} />
              );
            }}
          />
        ) : (
          <Route exact path="/" component={Main} />
        )}

        <Route
          path="/incidentxpress"
          render={() => {
            return <ExternalRedirect loc={config.url.incidentXpressSiteUrl} />;
          }}
        />
        <Route path="/signup" component={Signup} />
        <Route path="/errors/:type" component={CustomError} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/newincident" component={NewIncident} />
        <PrivateRoute path="/search" component={Search} />
        <PrivateRoute path="/reports" component={Reports} />
        <Route path="/mfa" component={mfa} />
        <Route path="/startMfaEnrolloment" component={StartMfaEnrolloment} />
        <Route path="/verifyEnrollment" component={VerifyMfaEnrollment} />
        <Route path="/change2FAPhone" component={Change2FAPhone} />
        <Route path="/verify2FAPhone" component={Verify2FAPhone} />

        <Route
          path="/login"
          render={renderProps => {
            handleLogin(renderProps);
            return undefined;
          }}
        />
        <Route
          path="/callback"
          render={renderProps => {
            handleAuthentication(renderProps);
            return <Callback {...renderProps} />;
          }}
        />
        {!isMobile && (
          <>
            <PrivateRoute path="/analyze" component={Analysis} />
            <PrivateRoute path="/administration" component={Administration} />
            <PrivateRoute path="/profile" component={Profile} />
          </>
        )}
        {/* path not found */}
        <Route render={renderProps => <CustomError />} />
      </Switch>
    </ScrollToTop>
  </Router>
);

export const privateRoutes = [
  '/profile',
  '/administration',
  '/analyze',
  '/dashboard',
  '/newincident',
  '/search',
  '/reports',
];
