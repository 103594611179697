import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles, Button } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import RefreshIcon from '@material-ui/icons/Refresh';
import UserGroupManagement from './userGroupManagement/index';
import LookupManagement from './LookupManagement';
import AuditManagement from './auditManagement';
import CompanyManagement from './companyManagement';
import SubscriptionManagement2 from './subscriptionManagement2';
import NotificationManagement from './notificationManagement';
import BillingInformation from './billingInformation';

import DefaultLayout from '../DefaultLayout';

import ExpansionCard from '../../components/cards/ExpansionCard';
import Auth from '../../auth/Auth';

const useStyles = makeStyles(theme => ({
  root: {
    // width: 1200,
    margin: `${theme.spacing(2)}px`,
  },
  expansionCard: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  menuContainer: {
    width: '100%',
  },
  flexRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0),
  },
  infoMessage: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconButton: {},
}));

const Administration = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    subRoute: undefined,
  });
  const isActive = pathName => pathName === state.subRoute;

  const hasPrivilege = privileges => {
    return Auth.hasPrivilege(privileges);
  };

  React.useEffect(() => {
    const { subRoute } = props.location.state || {};
    setState({ ...state, subRoute });
  }, []);

  return (
    <DefaultLayout>
      <div className={classes.root}>
        {hasPrivilege(['ACCOUNT_ADMIN']) && (
          <div className={classes.expansionCard}>
            <ExpansionCard
              expanded={isActive('settings')}
              title="Settings"
              unmount={false}
              helpMessage="Customize system settings for your organization."
            >
              <CompanyManagement />
            </ExpansionCard>
          </div>
        )}

        {hasPrivilege(['USER_ADD', 'USER_EDIT', 'USER_DELETE']) && (
          <div className={classes.expansionCard}>
            <ExpansionCard
              expanded={isActive('users')}
              title="Workgroups"
              unmount={false}
              helpMessage="Create a group/groups of users who share the same rights within the system."
            >
              <UserGroupManagement />
            </ExpansionCard>
          </div>
        )}
        {hasPrivilege(['LOOKUP_ADD', 'LOOKUP_EDIT', 'LOOKUP_DELETE']) && (
          <div className={classes.expansionCard}>
            <ExpansionCard
              expanded={isActive('lookups')}
              title="Lookups"
              unmount={false}
              helpMessage="Create/edit lists of values that users can select from when entering incident details."
            >
              <div className={classes.flexRow}>
                <LookupManagement />

                <div className={classes.info}>
                  <InfoIcon />
                  <h3 className={classes.infoMessage}>
                    Please refresh page after updating lookups.{' '}
                  </h3>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Refresh
                  </Button>
                </div>
              </div>
            </ExpansionCard>
          </div>
        )}
        {hasPrivilege(['INCIDENT_NOTIFICATION']) && (
          <div className={classes.expansionCard}>
            <ExpansionCard
              expanded={isActive('notification')}
              title="Email Notifications"
              unmount={false}
              helpMessage="Create customized email notifications based on incidents created, edited and/or closed with certain class/category/subcategory/types."
            >
              <NotificationManagement />
            </ExpansionCard>
          </div>
        )}
        {hasPrivilege(['AUDIT_VIEW']) && (
          <div className={classes.expansionCard}>
            <ExpansionCard
              expanded={isActive('audit')}
              title="Audit"
              unmount={false}
              helpMessage="Review activities performed within the system."
            >
              <AuditManagement />
            </ExpansionCard>
          </div>
        )}

        {/* {hasPrivilege(['SUBSCRIPTION_EDIT']) && (
          <div className={classes.expansionCard}>
            <ExpansionCard
              expanded={isActive('subscription')}
              title="Subscription"
              unmount={false}
              helpMessage="Manage your Incident Xpress subscription details such as billing information, payment method and subscription plan."
            >
              <SubscriptionManagement />
            </ExpansionCard>
          </div>
        )} */}

        {hasPrivilege(['SUBSCRIPTION_EDIT']) && (
          <div className={classes.expansionCard}>
            <ExpansionCard
              expanded={isActive('subscription2')}
              title="Subscription"
              unmount={false}
              helpMessage="Manage your Incident Xpress subscription details such as billing information, payment method and subscription plan."
            >
              <SubscriptionManagement2 />
            </ExpansionCard>
          </div>
        )}
        {!Auth.isTrial && (
          <div className={classes.expansionCard}>
            <ExpansionCard
              expanded={isActive('billingInformation')}
              title="Billing information"
              unmount={false}
              helpMessage="Update billing information"
            >
              <BillingInformation />
            </ExpansionCard>
          </div>
        )}
      </div>
    </DefaultLayout>
  );
};

Administration.propTypes = {
  location: PropTypes.object,
};

export default withRouter(Administration);
