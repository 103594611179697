import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  TextField,
  Radio,
  Button,
  Typography,
  Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/styles';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { DefaultDateFormat, AnalysisDatePeriodType } from '../../constants';
import HelpButton from '../../components/buttons/HelpButton';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  group: {
    margin: 0,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(-1),
  },
  labelWithHelpIcon: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const PeriodSelector = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    type: AnalysisDatePeriodType.period,
    periodValue: 'Custom',
    periodName: '',
    toDate: moment()
      .endOf('day')
      .toDate(),
    fromDate: moment()
      .startOf('month')
      .startOf('day')
      .toDate(),
    days: '30',
    lastNMonths: '0',
  });

  const typeChangeHandler = event => {
    const { value } = event.target;
    setState({ ...state, type: value });
  };

  const periodChangeHandler = event => {
    let { fromDate, toDate } = state;
    const { value } = event.target;

    if (value === 'thisMonth') {
      toDate = moment()
        .endOf('day')
        .toDate();
      fromDate = moment()
        .startOf('month')
        .startOf('day')
        .toDate();
    } else if (value === 'lastMonth') {
      toDate = moment()
        .add(-1, 'month')
        .endOf('month')
        .endOf('day')
        .toDate();
      fromDate = moment()
        .add(-1, 'month')
        .startOf('month')
        .startOf('day')
        .toDate();
    } else if (value === 'ytd') {
      toDate = moment()
        .endOf('day')
        .toDate();
      fromDate = moment()
        .startOf('year')
        .startOf('day')
        .toDate();
    } else if (value === 'last12Month') {
      toDate = moment()
        .endOf('day')
        .toDate();
      fromDate = moment()
        .add(-12, 'month')
        .startOf('day')
        .toDate();
    }

    setState({ ...state, periodValue: value, fromDate, toDate });
  };

  const inputHandleChange = event => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const dateChangeHandler = event => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const addPeriod = () => {
    const { type, days, lastNMonths, fromDate, toDate, periodName } = state;

    let newPeriodName = periodName;
    if (!newPeriodName) {
      if (type === AnalysisDatePeriodType.lastNDays) {
        newPeriodName = days === 1 ? `Last ${days} day` : `Last ${days} days`;
      } else if (type === AnalysisDatePeriodType.lastNMonths) {
        if (lastNMonths === '0') {
          newPeriodName = 'Current month';
        } else if (lastNMonths === '1') {
          newPeriodName = `Previous month`;
        } else {
          newPeriodName = `Last ${lastNMonths} months`;
        }
      } else {
        newPeriodName = `${moment(fromDate).format(
          DefaultDateFormat(props.dateFormat).dateMoment,
        )} ~ ${moment(toDate).format(
          DefaultDateFormat(props.dateFormat).dateMoment,
        )}`;
      }
    }

    props.onPeriodAdded({
      id: uuid(),
      type,
      days,
      lastNMonths: Number(lastNMonths).valueOf(),
      fromDate,
      toDate,
      periodName: newPeriodName,
    });
  };

  const handleToDateChange = date => {
    setState({
      ...state,
      toDate: moment(date)
        .endOf('day')
        .toDate(),
    });
  };

  const handleFromDateChange = date => {
    setState({
      ...state,
      fromDate: moment(date)
        .startOf('day')
        .toDate(),
    });
  };

  const isDaysError = () => {
    return !new RegExp('^[1-9][1-9]*').test(state.days);
  };

  const isLastNMonthsError = () => {
    return !new RegExp('^[0-9][0-9]*').test(state.lastNMonths);
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <React.Fragment>
          <div className={classes.lineItem}>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                aria-label="Period"
                name="type"
                className={classes.group}
                value={state.type}
                onChange={typeChangeHandler}
                row
              >
                <FormControlLabel
                  value={AnalysisDatePeriodType.period}
                  control={<Radio />}
                  label="Period"
                />
                <FormControlLabel
                  value={AnalysisDatePeriodType.lastNDays}
                  control={<Radio />}
                  label={
                    <div className={classes.label}>
                      Last n days
                      <HelpButton message="When selecting 'Last n dans', the time period pulled will be from the current date back n days." />
                    </div>
                  }
                />
                <FormControlLabel
                  value={AnalysisDatePeriodType.lastNMonths}
                  control={<Radio />}
                  label={<div className={classes.label}>Last n months</div>}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <Typography variant="body1" gutterBottom>
            Period
          </Typography>

          <div className={classes.lineItem}>
            {state.type === AnalysisDatePeriodType.period && (
              <>
                {/* <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <RadioGroup
                    aria-label="Period"
                    name="periodValue"
                    className={classes.group}
                    value={state.periodValue}
                    onChange={periodChangeHandler}
                    row
                  >
                    <FormControlLabel
                      value="thisMonth"
                      control={<Radio />}
                      label="This month"
                    />
                    <FormControlLabel
                      value="lastMonth"
                      control={<Radio />}
                      label="Last month"
                    />
                    <FormControlLabel
                      value="ytd"
                      control={<Radio />}
                      label="Year to date"
                    />
                    <FormControlLabel
                      value="last12Month"
                      control={<Radio />}
                      label="Last 12 months"
                    />
                    <FormControlLabel
                      value="Custom"
                      control={<Radio />}
                      label="Custom"
                    />
                  </RadioGroup>
                </FormControl> */}
                <FormControl className={classes.formControl}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      label="Date from"
                      format={DefaultDateFormat(props.dateFormat).date}
                      value={state.fromDate}
                      onChange={handleFromDateChange}
                      disabled={state.periodValue !== 'Custom'}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      label="Date to"
                      format={DefaultDateFormat(props.dateFormat).date}
                      value={state.toDate}
                      onChange={handleToDateChange}
                      disabled={state.periodValue !== 'Custom'}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </>
            )}
            {state.type === AnalysisDatePeriodType.lastNDays && (
              <>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <RadioGroup
                    aria-label="Last n days"
                    name="days"
                    className={classes.group}
                    value={state.days}
                    onChange={inputHandleChange}
                    row
                  >
                    <FormControlLabel
                      value="30"
                      control={<Radio />}
                      label="Last 30 days"
                    />
                    <FormControlLabel
                      value="90"
                      control={<Radio />}
                      label="Last 90 days"
                    />
                    <FormControlLabel
                      value="180"
                      control={<Radio />}
                      label="Last 180 days"
                    />
                    <FormControlLabel
                      value="365"
                      control={<Radio />}
                      label="Last 365 days"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="days"
                    label="Days"
                    value={state.days || ''}
                    onChange={event => {
                      setState({ ...state, days: event.target.value });
                    }}
                    margin="none"
                    // onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={isDaysError()}
                    helperText={
                      isDaysError()
                        ? 'Please enter a number greater than 0.'
                        : undefined
                    }
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
              </>
            )}
            {state.type === AnalysisDatePeriodType.lastNMonths && (
              <>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <RadioGroup
                    aria-label="Last n months"
                    name="lastNMonths"
                    className={classes.group}
                    value={state.lastNMonths}
                    onChange={inputHandleChange}
                    row
                  >
                    <Tooltip title={moment().format('MMMM')} placement="bottom">
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="Current month"
                      />
                    </Tooltip>

                    <Tooltip
                      title={moment()
                        .add(-1, 'month')
                        .format('MMMM')}
                      placement="bottom"
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Previous month"
                      />
                    </Tooltip>

                    <Tooltip
                      title={`${moment()
                        .add(-3, 'month')
                        .format('MMM')} - ${moment()
                        .add(-1, 'month')
                        .format('MMM')}`}
                      placement="bottom"
                    >
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="Last 2 months"
                      />
                    </Tooltip>

                    <Tooltip
                      title={`${moment()
                        .add(-4, 'month')
                        .format('MMMM')} - ${moment()
                        .add(-1, 'month')
                        .format('MMMM')}`}
                      placement="bottom"
                    >
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label="Last 3 months"
                      />
                    </Tooltip>

                    <Tooltip
                      title={`${moment()
                        .add(-13, 'month')
                        .format('MMM YYYY')} - ${moment()
                        .add(-1, 'month')
                        .format('MMM YYYY')}`}
                      placement="bottom"
                    >
                      <FormControlLabel
                        value="12"
                        control={<Radio />}
                        label="Last 12 months"
                      />
                    </Tooltip>
                  </RadioGroup>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="lastNMonths"
                    label="Months"
                    value={state.lastNMonths || ''}
                    onChange={event => {
                      setState({ ...state, lastNMonths: event.target.value });
                    }}
                    margin="none"
                    // onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={isLastNMonthsError()}
                    helperText={
                      isLastNMonthsError()
                        ? 'Please enter a number greater than or equalt to 0.'
                        : undefined
                    }
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
              </>
            )}
          </div>
          <div className={classes.searchFields}>
            <FormControl className={classes.formControl}>
              <TextField
                name="periodName"
                label={
                  <div className={classes.labelWithHelpIcon}>
                    Period Name
                    <HelpButton message="Period name will appear on chart. When left blank, the date range selected will appear." />
                  </div>
                }
                className={classes.periodName}
                value={state.periodName}
                onChange={inputHandleChange}
                margin="none"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </div>
          <div className={classes.searchFields}>
            <FormControl className={classes.formControl}>
              <Button
                variant="contained"
                color="primary"
                className={classes.searchButton}
                onClick={addPeriod}
              >
                Add Period
              </Button>
            </FormControl>
          </div>
        </React.Fragment>
      </div>
    </React.Fragment>
  );
};

PeriodSelector.propTypes = {
  onPeriodAdded: PropTypes.func,
  dateFormat: PropTypes.oneOf([0, 1]), // AccountDateFormatEnum
};

PeriodSelector.defaultProps = {
  onPeriodAdded: () => {},
};

export default PeriodSelector;
