import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import _ from 'lodash';
import { enqueueError, enqueueSuccess } from '../../modules/global';
import analyzeApi from '../../apis/analyze.api';
import { updateDraftIncident } from '../newIncident/ducks';

const RESET = 'analyze/RESET';
const LOADED = 'analyze/LOADED';
const SAVED = 'analyze/SAVED';

const resetAction = createAction(RESET);
const loadedAction = createAction(LOADED);
const savedAction = createAction(SAVED);

const initState = {
  loaded: false,
  analyzeResult: undefined,
};

export default handleActions(
  {
    [RESET]: (state, action) =>
      produce(state, draft => {
        draft.loaded = false;
        draft.analyzeResult = undefined;
      }),
    [LOADED]: (state, action) =>
      produce(state, draft => {
        draft.loaded = true;
        draft.analyzeResult = action.payload;
      }),
    [SAVED]: (state, action) => produce(state, draft => {}),
  },
  initState,
);

export const executeAnalyze = (criteria, title) => async dispatch => {
  dispatch(resetAction());
  try {
    const { data } = await analyzeApi.analyze(criteria, title);
    dispatch(loadedAction(data));
  } catch (err) {
    dispatch(enqueueError(err));
  }
};

export const downloadCSV = (criteria, title) => async dispatch => {
  try {
    await analyzeApi.analyze(criteria, title, 'excel');
  } catch (err) {
    dispatch(enqueueError(err));
  }
};

export const saveCustomAnalysis = model => async dispatch => {
  try {
    await analyzeApi.saveCustom(model);
    dispatch(enqueueSuccess('Analysis is saved to dashboard.'));
  } catch (err) {
    dispatch(enqueueError(err));
  }
};

export const updateCustomAnalysis = model => async dispatch => {
  try {
    await analyzeApi.updateCustom(model);
    dispatch(enqueueSuccess(`${model.name} has been updated.`));
  } catch (err) {
    dispatch(enqueueError(err));
  }
};

export const saveReportAnalysis = model => async dispatch => {
  try {
    await analyzeApi.saveReport(model);
    dispatch(enqueueSuccess('Analysis is saved to report.'));
  } catch (err) {
    dispatch(enqueueError(err));
  }
};

export const updateReportAnalysis = model => async dispatch => {
  try {
    await analyzeApi.updateReport(model);
    dispatch(enqueueSuccess(`${model.name} has been updated.`));
  } catch (err) {
    dispatch(enqueueError(err));
  }
};

export const resetAnalyze = () => resetAction();
