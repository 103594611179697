export const convertTree = origin => {
  const getRecursive = (input, parent) => {
    const output = [];
    let i = 0;
    const iMax = input.length;
    for (; i < iMax; i += 1) {
      const node = input[i];
      if (node.parentId === parent) {
        const children = getRecursive(input, node.id);
        children.length && (node.children = children);
        output.push(node);
      }
    }
    return output;
  };
  return getRecursive(origin, null);
};

const simplify = ({ id, name, active }) => ({ id, name, active });

const findSimpleNode = (node, id, parents = []) => {
  if (node.id === id) {
    return { item: { ...node, children: undefined }, parents };
  }
  if (!node.children || node.children.length === 0) {
    return false;
  }
  parents.push(simplify(node));

  let i = 0;
  const iMax = node.children.length;
  for (; i < iMax; i += 1) {
    const next = node.children[i];
    const result = findSimpleNode(next, id, parents);
    if (!result) {
      const wrongIndex = parents.findIndex(x => x.id === next.id);
      wrongIndex >= 0 && parents.splice(wrongIndex, 1);
    } else if (result.item) {
      return result;
    }
  }
  return false;
};

export const findFromLookupTreeList = (treeList, id) => {
  let i = 0;
  const iMax = treeList.length;
  for (; i < iMax; i += 1) {
    const result = findSimpleNode(treeList[i], id);
    if (result) return result;
  }
  return null;
};

const findNodeRecursive = (node, id) => {
  if (node.id === id) {
    return node;
  }
  if (!node.children || node.children.length === 0) {
    return null;
  }

  let i = 0;
  const iMax = node.children.length;
  for (; i < iMax; i += 1) {
    const next = node.children[i];
    const result = findNodeRecursive(next, id);
    if (result) {
      return result;
    }
  }
  return null;
};

const findNode = (treeList, id) => {
  let i = 0;
  const iMax = treeList.length;
  let node = null;
  for (; i < iMax; i += 1) {
    node = findNodeRecursive(treeList[i], id);
    if (node) break;
  }
  return node;
};

export const buildLookupPath = (treeList, id) => {
  if (!treeList || treeList.length === 0 || !id) return null;
  const found = findFromLookupTreeList(treeList, id);
  if (found) {
    const namePaths = found.parents.map(p => p.name);
    namePaths.push(found.item.name);
    return namePaths.join(' / ');
  }
  return null;
};
export const getChildLookups = (treeList, parentId, activeOnly = false) => {
  let children = [];
  if (!parentId) {
    children = treeList.map(x => simplify(x));
  } else {
    const parent = findNode(treeList, parentId);
    children =
      parent && parent.children ? parent.children.map(x => simplify(x)) : [];
  }

  if (activeOnly) {
    return children.filter(x => x.active) || [];
  }
  return children;
};
export const getDescendantLookupIds = (treeList, parentId) => {
  const extractDescendantIds = parentNode => {
    const ids = [];
    parentNode.children.forEach(node => {
      ids.push(node.id);
      node.children &&
        node.children.length &&
        ids.push(...extractDescendantIds(node));
    });
    return ids;
  };
  const ids = [];
  if (parentId) {
    const parent = findNode(treeList, parentId);
    if (parent && parent.children && parent.children.length) {
      parent.children.forEach(node => {
        ids.push(node.id);
        node.children &&
          node.children.length &&
          ids.push(...extractDescendantIds(node));
      });
      return ids;
    }
  }
  return ids;
};

export const appendChildLookup = (treeList, lookup) => {
  const { parentId } = lookup;
  if (!parentId) {
    treeList.push(lookup);
    return treeList;
  }
  const parent = findNode(treeList, parentId);
  if (!parent) {
    return treeList;
  }
  // set parent opened
  parent.opened = true;

  if (parent.children) {
    parent.children.push(lookup);
  } else {
    parent.children = [lookup];
  }
  return treeList;
};
export const updateLookupTreeNode = (treeList, id, values) => {
  const node = findNode(treeList, id);
  if (!node) {
    return;
  }
  Object.assign(node, values);
};
export const removeLookupTree = (treeList, lookup) => {
  const { id, parentId } = lookup;
  if (!parentId) {
    const index = treeList.findIndex(x => x.id === id);
    treeList.splice(index, 1);
    return;
  }
  const parent = findNode(treeList, parentId);
  const index = parent.children.findIndex(x => x.id === id);
  parent.children.splice(index, 1);
};
