import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import auth from '../../auth/Auth';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = auth.isAuthenticated;
  let nextUrl;
  if (!isAuthenticated) {
    nextUrl = auth.getAuthErrorUrl();
  } else if (!auth.hasMenuPrivilege(rest.path)) {
    nextUrl = auth.getPermissionErrorUrl();
  }

  return (
    <Route
      {...rest}
      render={props =>
        !nextUrl ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: nextUrl,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.object,
  ]),
  location: PropTypes.object,
};

export default PrivateRoute;
