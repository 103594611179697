import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
  Tooltip,
  Box,
} from '@material-ui/core';
import { Settings as SettingsIcon } from '@material-ui/icons';
import _ from 'lodash';
import uuid from 'uuid/v4';
import { CSVLink } from 'react-csv';
import {
  querySingles,
  addSingle,
  updateSingle,
  removeSingle,
  queryMultiples,
  addMultiple,
  updateMultiple,
  removeMultiple,
  getAddress,
  updateAddress,
  removeAddress,
  addAddress,
} from '../../modules/administration/lookupManagement';
import {
  queryLookupLabels,
  updateLookupLabels,
  setLabelDialogOpen,
} from './lookupManagement/ducks';
import { LookupQueryFilter } from '../../models/helpers/QueryModel';
import { lookupTypeList } from '../../constants';
import LookupList from './LookupList';
import LookupFormDialog from './LookupFormDialog';
import AlertDialog from '../../components/dialogs/AlertDialog';
import LocationFormDialog from './LocationFormDialog';
import LookupLabelFormDialog from './lookupManagement/LookupLabelFormDialog';
import { buildLookupPath } from '../../utils/lookupTreeUtil';
import ExcelIcon from '../../components/icons/ExcelIcon';
import lookupApi from '../../apis/lookup.api';
import { enqueueError } from '../../modules/global';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['100'],
    overflow: 'hidden',
    paddingBottom: 200,
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
    width: '100%',
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  lineItem: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  addUserDialog: {
    width: '80%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  lookupItem: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
    overflowX: 'auto',
  },
}));

const useActionDone = (
  singleActionStates,
  multipleActionStates,
  dialogInfo,
  selectedType,
) => {
  const [results, setResults] = React.useState([false, false, false]);

  React.useEffect(() => {
    const type = selectedType.type;
    const { mode } = dialogInfo;
    const [addState, updateState, removeState] =
      type === 'single' ? singleActionStates : multipleActionStates;

    const removed = removeState === 'done';
    const added = addState === 'done' && mode === 'add';
    const updated = updateState === 'done' && mode === 'update';

    setResults([added, updated, removed]);
  }, [...singleActionStates, ...multipleActionStates, selectedType.type]);

  return results;
};

const useAddressActionDone = (actionStates, dialogInfo) => {
  const [results, setResults] = React.useState([false, false, false]);

  React.useEffect(() => {
    const [addState, updateState, removeState] = actionStates;
    const { mode } = dialogInfo;
    const added = mode === 'add' && addState === 'done';
    const updated = mode === 'update' && updateState === 'done';
    const removed = removeState === 'done';

    setResults([added, updated, removed]);
  }, [...actionStates]);

  return results;
};

const sortedLookupTypeList = _.sortBy(lookupTypeList, ['name']);

const LookupManagement = props => {
  const classes = useStyles();
  const {
    queryLabelState,
    addSingleState,
    updateSingleState,
    removeSingleState,
    addMultipleState,
    updateMultipleState,
    removeMultipleState,
  } = props;
  const [selectedType, setSelectedType] = React.useState(
    sortedLookupTypeList[0],
  );
  const [dialogOpenInfo, setDialogOpenInfo] = React.useState({
    open: false,
    mode: undefined,
    lookup: undefined,
    lookupType: undefined,
  });
  const [deleteOpenInfo, setDeleteOpenInfo] = React.useState({
    open: false,
    lookup: undefined,
    lookupType: undefined,
  });
  const [addressOpenInfo, setAddressOpenInfo] = React.useState({
    open: false,
    lookup: undefined,
    lookupType: undefined,
    mode: undefined,
  });
  const [deleteAddressOpenInfo, setDeleteAddressOpenInfo] = React.useState({
    open: false,
    address: undefined,
  });
  const [exportData, setExportData] = React.useState('');

  const actionDone = useActionDone(
    [addSingleState, updateSingleState, removeSingleState],
    [addMultipleState, updateMultipleState, removeMultipleState],
    dialogOpenInfo,
    selectedType,
  );
  const addressActionDone = useAddressActionDone(
    [props.addAddressState, props.updateAddressState, props.removeAddressState],
    addressOpenInfo,
  );

  const filter = new LookupQueryFilter();
  const loadList = () => {
    const { type } = selectedType;
    type === 'single' && props.querySingles(filter.toParams());
    ['multiple', 'location'].includes(type) &&
      props.queryMultiples(filter.toParams());
  };

  const loadLabels = () => {
    const { type, cols } = selectedType;
    type !== 'single' &&
      cols.length === 0 &&
      props.queryLookupLabels(filter.toParams());
  };

  const loadExportData = async () => {
    try {
      const { type, cols } = selectedType;
      if (type === 'multiple') {
        const { data } = await lookupApi.exportMultiples(filter.toParams());
        setExportData(data);
      } else {
        setExportData([]);
      }
    } catch (err) {
      props.enqueueError(err);
    }
  };

  // raise load events at selectedType changed
  React.useEffect(() => {
    if (selectedType) {
      filter.type = selectedType.key;
      loadList();
      loadLabels();
      loadExportData();
    }
  }, [selectedType.key]);

  // // binding labels
  const [labels, setLabels] = React.useState([]);
  React.useEffect(() => {
    const currentLabels = props.multipleLookupLabels[selectedType.key];
    sortedLookupTypeList.find(
      x => x.key === selectedType.key,
    ).cols = currentLabels;
    setLabels(currentLabels);
    setSelectedType({ ...selectedType, cols: labels });
  }, [props.multipleLookupLabels]);

  const getLookups = () => {
    return props.storedDataSet[selectedType.key] || [];
  };

  const [lookups, setLookups] = React.useState([]);
  React.useEffect(() => {
    setLookups(getLookups());
  }, [props.storedDataSet]);

  // // closing lookup dialogs
  React.useEffect(() => {
    const [added, updated, removed] = actionDone;
    if (added || updated) {
      setDialogOpenInfo({ ...dialogOpenInfo, open: false, lookup: undefined });
    }
    if (removed) {
      setDeleteOpenInfo({ open: false, lookup: undefined });
    }
  }, [...actionDone]);

  // // closing address address dialogs
  React.useEffect(() => {
    const [added, updated, removed] = addressActionDone;
    if (added || updated || removed) {
      setAddressOpenInfo({
        ...addressOpenInfo,
        open: false,
        lookup: undefined,
      });
      if (removed) {
        setDeleteAddressOpenInfo({
          ...deleteAddressOpenInfo,
          open: false,
          address: undefined,
        });
      }
    }
  }, [...addressActionDone]);

  const lookupTypeChange = event => {
    const { value } = event.target;
    setSelectedType(_.find(sortedLookupTypeList, { key: value }));
  };

  const handleCancelInfo = () => {
    setDialogOpenInfo({
      open: false,
      mode: undefined,
      lookup: undefined,
      lookupType: undefined,
    });
  };
  const handleCancelDelete = () => {
    setDeleteOpenInfo({ open: false, lookup: undefined });
  };

  const handleEdit = lookup => {
    const { open, lookupType } = dialogOpenInfo;
    if (open) {
      if (lookupType.type === 'single') {
        props.updateSingle(lookup);
      } else {
        if (lookup.level === 1) {
          lookup.parentId = undefined;
        }
        delete lookup.children;
        lookup.parentPath = lookup.parentId
          ? buildLookupPath(lookups, lookup.parentId)
          : undefined;
        const level = lookup.level;
        lookup.label = labels[level > 0 ? level - 1 : 0];

        props.updateMultiple(lookup);
      }
    } else {
      setDialogOpenInfo({
        open: true,
        lookup,
        mode: 'update',
        lookupType: selectedType,
      });
    }
  };
  const handleAddress = (lookup, address) => {
    const { open, mode } = addressOpenInfo;
    if (open) {
      mode === 'add' && props.addAddress(address);
      mode === 'update' && props.updateAddress(address);
    } else {
      const addressMode = lookup.addressId ? 'update' : 'add';
      if (addressMode === 'update') {
        props.getAddress(lookup.addressId);
      }
      setAddressOpenInfo({
        open: true,
        addressMode,
        lookup,
        lookupType: selectedType,
      });
    }
  };
  const handleAddressCancel = () => {
    setAddressOpenInfo({ open: false, mode: undefined, lookup: undefined });
  };

  const handleAdd = (lookup, value) => {
    const { open, lookupType } = dialogOpenInfo;
    if (open) {
      if (lookupType.type === 'single') {
        props.addSingle({
          id: uuid(),
          type: lookupType.key,
          name: value,
          active: true,
        });
      } else {
        const parentId = lookup.id ? lookup.id : undefined;
        const level = lookup.level + 1;
        const parentPath = parentId
          ? buildLookupPath(lookups, parentId)
          : undefined;
        const label = labels[lookup.level];
        props.addMultiple({
          id: uuid(),
          type: lookupType.key,
          name: value,
          active: true,
          parentId,
          level,
          parentPath,
          label,
        });
      }
    } else {
      setDialogOpenInfo({
        open: true,
        lookup,
        mode: 'add',
        lookupType: selectedType,
      });
    }
  };
  const handleDelete = lookup => {
    const { open, lookupType } = deleteOpenInfo;
    if (open) {
      if (lookupType.type === 'single') {
        props.removeSingle(deleteOpenInfo.lookup);
      } else {
        props.removeMultiple(deleteOpenInfo.lookup);
      }
    } else {
      const message = lookup.children ? (
        <>
          This lookup has child lookup(s). Child lookup(s) will be deleted if
          this lookup is deleted.
          <br /> {`Are you sure you want to delete "${lookup.name}" lookup?`}
        </>
      ) : (
        `Are you sure you want to delete "${lookup.name}" lookup?`
      );
      setDeleteOpenInfo({
        open: true,
        lookup,
        lookupType: selectedType,
        message,
      });
    }
  };

  const handleDeleteAddress = address => {
    const { open } = deleteAddressOpenInfo;
    if (open) {
      props.removeAddress(deleteAddressOpenInfo.address);
    } else {
      setDeleteAddressOpenInfo({
        open: true,
        address,
      });
    }
  };
  const handleCancelDeleteAddress = () => {
    setDeleteAddressOpenInfo({ open: false, address: undefined });
  };
  const handleOpenLabelsDialog = openDialog => event => {
    props.setLabelDialogOpen(openDialog);
  };
  const handleSaveLabels = _.debounce(values => {
    const model = {
      type: selectedType.key,
      labels: values,
    };
    props.updateLookupLabels(model);
  }, 200);

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        {/* <div className={classes.title}>
          <Typography variant="h4">{selectedType.name}</Typography>
        </div> */}
        <Box display="flex" alignItems="baseline">
          <FormControl className={classes.formControlLong}>
            <InputLabel shrink htmlFor="lookup-type-label-placeholder">
              Lookup type
            </InputLabel>
            <Select
              value={selectedType.key}
              onChange={lookupTypeChange}
              name="selectedType"
            >
              {sortedLookupTypeList.map(lookupType => {
                return (
                  <MenuItem value={lookupType.key} key={lookupType.key}>
                    {lookupType.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {selectedType.type !== 'single' && props.labelLoaded > 0 && (
            <>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleOpenLabelsDialog(true)}
                size="small"
              >
                Labelsetting
              </Button>
              <CSVLink data={exportData} filename={`${selectedType.key}.csv`}>
                <Tooltip title="Export to excel">
                  <IconButton color="primary" aria-label="Export to excel">
                    <ExcelIcon />
                  </IconButton>
                </Tooltip>
              </CSVLink>
            </>
          )}
        </Box>
        <div className={classes.lookupItem}>
          <LookupList
            lookups={lookups}
            labels={labels}
            lookupType={selectedType}
            onDelete={handleDelete}
            onEdit={handleEdit}
            onAdd={handleAdd}
            onAttachAddress={handleAddress}
            selectedLookupId={
              dialogOpenInfo.lookup && dialogOpenInfo.lookup.id
                ? dialogOpenInfo.lookup.id
                : undefined
            }
          />
        </div>
      </Paper>
      {dialogOpenInfo.open && (
        <LookupFormDialog
          open={dialogOpenInfo.open}
          mode={dialogOpenInfo.mode}
          lookupType={dialogOpenInfo.lookupType}
          lookup={dialogOpenInfo.lookup}
          labels={labels}
          onClose={handleCancelInfo}
          onLookupAdded={handleAdd}
          onLookupEdited={handleEdit}
          onLookupDisabled={handleEdit}
          onLookupEnabled={handleEdit}
        />
      )}
      {addressOpenInfo.open && (
        <LocationFormDialog
          open={addressOpenInfo.open}
          lookupType={addressOpenInfo.lookupType}
          lookup={addressOpenInfo.lookup}
          address={props.storedAddress}
          onClose={handleAddressCancel}
          onSave={handleAddress}
          onDelete={handleDeleteAddress}
          mode={addressOpenInfo.mode}
        />
      )}
      {props.labelDialogOpen && (
        <LookupLabelFormDialog
          open={props.labelDialogOpen}
          onSave={handleSaveLabels}
          onClose={handleOpenLabelsDialog(false)}
          labels={props.multipleLookupLabels[selectedType.key]}
          lookupType={selectedType}
        />
      )}
      {deleteOpenInfo.open && (
        <AlertDialog
          title="Delete Lookup"
          message={deleteOpenInfo.message}
          open={deleteOpenInfo.open}
          onClose={handleCancelDelete}
          onCancel={handleCancelDelete}
          onOk={handleDelete}
        />
      )}
      {deleteAddressOpenInfo.open && (
        <AlertDialog
          title="Delete Address"
          message="Are you sure?"
          open={deleteAddressOpenInfo.open}
          onClose={handleCancelDeleteAddress}
          onCancel={handleCancelDeleteAddress}
          onOk={handleDeleteAddress}
        />
      )}
    </React.Fragment>
  );
};

LookupManagement.propTypes = {
  queryLabelState: PropTypes.string,
  addSingleState: PropTypes.string,
  updateSingleState: PropTypes.string,
  removeSingleState: PropTypes.string,
  addMultipleState: PropTypes.string,
  updateMultipleState: PropTypes.string,
  removeMultipleState: PropTypes.string,
  addAddressState: PropTypes.string,
  updateAddressState: PropTypes.string,
  removeAddressState: PropTypes.string,
  multipleLookupLabels: PropTypes.object,
  storedDataSet: PropTypes.object,
  querySingles: PropTypes.func,
  queryMultiples: PropTypes.func,
  updateSingle: PropTypes.func,
  updateMultiple: PropTypes.func,
  addAddress: PropTypes.func,
  getAddress: PropTypes.func,
  updateAddress: PropTypes.func,
  removeAddress: PropTypes.func,
  storedAddress: PropTypes.object,
  addSingle: PropTypes.func,
  addMultiple: PropTypes.func,
  removeSingle: PropTypes.func,
  removeMultiple: PropTypes.func,
  queryLookupLabels: PropTypes.func,
  updateLookupLabels: PropTypes.func,
  setLabelDialogOpen: PropTypes.func,
  labelDialogOpen: PropTypes.bool,
  labelLoaded: PropTypes.bool,
  enqueueError: PropTypes.func,
};

const mapStateToProps = ({ administration }) => ({
  loaded: administration.lookup.loaded,
  labelLoaded: administration.lookup.labelLoaded,
  multipleLookupLabels: administration.lookup.multipleLookupLabels,
  labelDialogOpen: administration.lookup.labelDialogOpen,

  queryLabelState: administration.lookups.queryLabelState,
  addSingleState: administration.lookups.addSingleState,
  updateSingleState: administration.lookups.updateSingleState,
  removeSingleState: administration.lookups.removeSingleState,
  addMultipleState: administration.lookups.addMultipleState,
  updateMultipleState: administration.lookups.updateMultipleState,
  removeMultipleState: administration.lookups.removeMultipleState,
  addAddressState: administration.lookups.addAddressState,
  getAddressState: administration.lookups.getAddressState,
  updateAddressState: administration.lookups.updateAddressState,
  removeAddressState: administration.lookups.removeAddressState,
  storedDataSet: administration.lookups.dataSet,
  storedAddress: administration.lookups.address,
});

const mapDispatchToProps = {
  queryLookupLabels,
  updateLookupLabels,
  setLabelDialogOpen,

  querySingles,
  addSingle,
  updateSingle,
  removeSingle,
  queryMultiples,
  addMultiple,
  updateMultiple,
  removeMultiple,
  getAddress,
  addAddress,
  updateAddress,
  removeAddress,
  enqueueError,
};
export default connect(mapStateToProps, mapDispatchToProps)(LookupManagement);
