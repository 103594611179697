import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card,
  CardContent,
  CardActionArea,
  IconButton,
  Button,
  CardHeader,
  CardActions,
  Dialog,
  Box,
  Tooltip,
} from '@material-ui/core';
import produce from 'immer';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ChartIcon from '@material-ui/icons/Equalizer';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import PrintIcon from '@material-ui/icons/Print';
import uuid from 'uuid/v4';
import { CSVLink } from 'react-csv';
import ExcelIcon from '../../components/icons/ExcelIcon';
import { getInitData } from '../../modules/common';
import IncidentTable from './IncidentTable';
import DraftIncidentTable from './DraftIncidentTable';
import SectionHeader from '../../components/typo/SectionHeader';
import DefaultLayout from '../DefaultLayout';
import OpenIncidentCard from './OpenIncidentsCard';
import BaseDialog from '../../components/dialogs/BaseDialog';
import {
  LookupDataSetDef,
  DraftIncidentDef,
  CustomSearchDef,
  IncidentDef,
  CustomAnalysisDef,
  NotificationDef,
} from '../../components/propTypeDefs';
import { LookupQueryFilter } from '../../models/helpers/QueryModel';
import {
  LookupTypeKey,
  IncidentStatusEnum,
  getDefaultSearchResultColumns,
  CustomReportTypeEnum,
} from '../../constants';
import DraftIncidentsCard from './DraftIncidentsCard';
import {
  refreshData,
  deleteDraftIncident,
  deleteIncident,
  loadCustomSearches,
  executeCustomSearch,
  deleteCustomSearch,
  setOpenCustomSearchDeleteDialog,
  loadCustomAnalysis,
  executeCustomAnalysis,
  deleteCustomAnalysis,
  setOpenCustomAnalysisDeleteDialog,
  openClosedIncident,
} from './ducks';
import { getCompanyLogo } from '../reports/ducks';
import IncidentViewDialog from '../incidentView/IncidentViewDialog';
import ClosedIncidentsCard from './ClosedIncidentsCard';
import DashboardItem from './DashboardItem';
import AlertDialog from '../../components/dialogs/AlertDialog';
import reportApi from '../../apis/report.api';
import reportUtil, {
  convertIncidentSearchResultToReportData,
} from '../../utils/reportUtil';
import ReportViewer from '../../components/ReportViewer';
import { enqueueError } from '../../modules/global';
import auth from '../../auth/Auth';
import AnalyzeDialog from './AnalyzeDialog';
import DashboardChartItem from './DashboardChartItem';
import { useMediaInfo } from '../../utils/hooks';
import SearchResultColumnsDialog from '../search/SearchResultColumnsDialog';

const grayColor = [
  '#999',
  '#777',
  '#3C4858',
  '#AAAAAA',
  '#D2D2D2',
  '#DDD',
  '#b4b4b4',
  '#555555',
  '#333',
  '#a9afbb',
  '#eee',
  '#e7e7e7',
];

const useStyles = makeStyles(theme => ({
  grid: {
    // width: 1200,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  loadingState: {
    opacity: 0.05,
  },
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
    overflowX: 'auto',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  outlinedButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing(1),
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing(1),
    width: 152,
    height: 36,
  },
  blockCenter: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  block: {
    padding: theme.spacing(2),
  },
  inlining: {
    display: 'inline-block',
    marginRight: 10,
  },
  buttonBar: {
    display: 'flex',
  },
  noBorder: {
    borderBottomStyle: 'hidden',
  },
  cardCategory: {
    color: grayColor[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
    minHeight: 30,
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  cardItemTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardSearchItem: {
    backgroundColor: grayColor[5],
    cursor: 'pointer',
  },
  printButton: {},
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  reportDialog: {
    '& .MuiDialog-paperScrollPaper': {
      flexGrow: 1,
    },
  },
  cardDialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  cardDialogTitleText: {
    flexGrow: 1,
  },
  deleteIncidentDesc: {
    fontWeight: 800,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  note: {
    color: theme.palette.grey[500],
  },
}));

const title = <SectionHeader title="Dashboard" subtitle="" />;

const Dashboard = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    loading: true,
    data: [],
    dialogOpen: false,
    dialogTitle: '',
    openCardType: undefined,
    contentType: undefined,
    viewerOpen: false,
    viewerMode: 'view',
    printOpen: false,
    selectedIncident: undefined,
    // currentCustomId: undefined,
    selectedCustomSearch: undefined,
    selectedAnalysis: undefined,
    accountDisabled: false,
  });
  const { isSmaller, isMobile } = useMediaInfo({ minBreakPoint: 'sm' });

  const [reportViewerInfo, setReportViewerInfo] = React.useState({
    open: false,
    reportName: undefined,
    reportId: undefined,
    reportTitle: 'Report',
  });

  const [deleteIncidentInfo, setDeleteIncidentInfo] = React.useState({
    open: false,
    selectedIncident: undefined,
  });

  const [
    openClosedIncidentAlertOpen,
    setOpenClosedIncidentAlertOpen,
  ] = React.useState(false);

  const handleRefreshItems = keys => {
    props.refreshData(keys);
  };

  // on mounted
  React.useEffect(() => {
    handleRefreshItems();
    if (auth.hasPrivilege(['ALLINCIDENT_SEARCH', 'MYINCIDENT_SEARCH'])) {
      props.loadCustomSearches();
    }
    if (auth.hasPrivilege(['ANALYZE_VIEW']) && !isMobile) {
      props.loadCustomAnalysis();
    }
    props.getCompanyLogo();
    props.getInitData();
  }, []);

  const openDraftIncidentsDialog = () => {
    setState({
      ...state,
      dialogTitle: 'Draft Incidents',
      contentType: 'draftIncidents',
      dialogOpen: true,
    });
  };

  const openIncidentsDialog = dialogTitle => {
    setState({
      ...state,
      dialogTitle,
      contentType: 'mock',
      dialogOpen: true,
    });
  };

  const closeDialog = () => {
    setState({
      ...state,
      dialogOpen: false,
      selectedIncident: undefined,
    });
  };

  const closeIncidentPrint = () => {
    setState({
      ...state,
      printOpen: false,
      selectedIncident: undefined,
    });
  };

  const closeIncidentView = () => {
    setState({
      ...state,
      viewerOpen: false,
      selectedIncident: undefined,
    });
  };

  const handleShowDialog = (
    openCardType,
    dialogTitle,
    contentType,
    viewMode,
  ) => {
    setState({
      ...state,
      openCardType,
      viewMode,
      dialogTitle,
      contentType,
      dialogOpen: true,
    });
  };
  const handleShowCustomDialog = customItem => {
    setState({
      ...state,
      openCardType: 'customResult',
      viewMode: 'view',
      dialogTitle: customItem.name,
      contentType: 'incidents',
      dialogOpen: true,
      // currentCustomId: customItem.id,
      selectedCustomSearch: customItem,
    });
  };
  const handleShowAnalysisDialog = customItem => {
    setState({
      ...state,
      selectedAnalysis: customItem,
    });
  };
  const handleCloseAnalyze = () => {
    setState({ ...state, selectedAnalysis: undefined, analysisOpen: false });
  };

  const handleIncidentCommand = (commandName, incident) => {
    if (commandName === 'print') {
      // @TODO
    } else if (['view', 'edit'].includes(commandName)) {
      setState({
        ...state,
        viewerOpen: true,
        viewerMode: commandName,
        selectedIncident: incident,
      });
    } else if (commandName === 'deleteDraft') {
      // Delete draft incident
      props.deleteDraftIncident(incident.id);
    } else if (commandName === 'delete') {
      setDeleteIncidentInfo({ open: true, selectedIncident: incident });
    }
  };

  const onOpenIncidentCardClick = () => {
    handleShowDialog('openIncidents', 'Open Incidents', 'incidents', 'edit');
  };

  const onClosedIncidentCardClick = () => {
    handleShowDialog(
      'closedIncidents',
      'Closed Incidents',
      'incidents',
      'view',
    );
  };

  const onDraftIncidentCardClick = () => {
    handleShowDialog(
      'draftIncidents',
      'Draft Incidents',
      'draftIncidents',
      'view',
    );
  };
  const handleCustomCardClick = item => event => {
    props.executeCustomSearch(item);
    handleShowCustomDialog(item);
  };
  const handleDeleteCustomSearch = item => event => {
    event.stopPropagation();
    setState({ ...state, selectedCustomSearch: item });
    props.setOpenCustomSearchDeleteDialog(true);
  };
  const handleEditCustomSearch = item => event => {
    event.stopPropagation();
    props.history.push({
      pathname: '/search',
      state: {
        selectedItem: item,
        itemType: CustomReportTypeEnum.dashboardSearch,
      },
    });
  };

  const handleAnalysisCommand = item => commandName => {
    switch (commandName) {
      case 'view':
        setState({ ...state, selectedAnalysis: item, analysisOpen: true });
        break;
      case 'delete':
        setState({ ...state, selectedAnalysis: item });
        props.setOpenCustomAnalysisDeleteDialog(true);
        break;
      case 'edit':
        props.history.push({
          pathname: '/analyze',
          state: {
            selectedItem: item,
            itemType: CustomReportTypeEnum.dashboardAnalysis,
          },
        });
        break;
      default:
        break;
    }
  };

  const printIncidents = async type => {
    const reportId = uuid();
    let incidentList;
    let reportTitle;
    if (type === 'openIncidents') {
      incidentList = props.openIncidents;
      reportTitle = 'Open Incidents';
    } else if (type === 'closedIncidents') {
      incidentList = props.closedIncidents;
      reportTitle = 'Closed Incidents';
    } else if (type === 'draftIncidents') {
      incidentList = props.draftIncidents;
      reportTitle = 'Draft Incidents';
    } else if (type === 'customResult') {
      incidentList = props.customResultSet[state.selectedCustomSearch.id] || [];
      reportTitle = 'Draft Incidents';
    }

    const reportData = convertIncidentSearchResultToReportData(
      incidentList,
      props.lookupDataSet[LookupTypeKey.incidentClass],
    );
    try {
      await reportApi.uploadReportData(reportId, reportData, reportTitle);

      setReportViewerInfo({
        open: true,
        reportName: 'IncidentSearchResult',
        reportId,
        reportTitle,
      });
    } catch (err) {
      props.enqueueError(err);
    }
  };

  const handleOpenClosedIncident = incidentId => {
    props.openClosedIncident(incidentId).then(() => {
      setOpenClosedIncidentAlertOpen(false);
      setState({
        ...state,
        viewerOpen: true,
        viewerMode: 'edit',
        selectedIncident: Object.assign({}, state.selectedIncident, {
          status: IncidentStatusEnum.open,
          close: undefined,
        }),
      });
    });
  };

  const [searchResultColumnInfo, setSearchResultColumnInfo] = React.useState({
    open: false,
    columns: getDefaultSearchResultColumns(),
  });

  const openColumnsDialog = () => {
    setSearchResultColumnInfo(
      produce(searchResultColumnInfo, draft => {
        draft.open = true;
      }),
    );
  };

  const handleColumDialogClose = () => {
    setSearchResultColumnInfo(
      produce(searchResultColumnInfo, draft => {
        draft.open = false;
      }),
    );
  };

  const handleDeleteIncident = id => {
    props.deleteIncident(id).then(() => {
      setDeleteIncidentInfo({ open: false });
    });
  };

  const getExportData = () => {
    let incidents = [];
    let cols = getDefaultSearchResultColumns();
    if (state.openCardType === 'openIncidents') {
      incidents = props.openIncidents;
      cols = searchResultColumnInfo.columns;
    } else if (state.openCardType === 'closedIncidents') {
      incidents = props.closedIncidents;
      cols = searchResultColumnInfo.columns;
    } else if (state.openCardType === 'draftIncidents') {
      incidents = props.draftIncidents;
      cols = searchResultColumnInfo.columns;
    } else if (state.openCardType === 'customResult') {
      incidents = props.customResultSet[state.selectedCustomSearch.id] || [];
      if (state.selectedCustomSearch.columns) {
        cols = state.selectedCustomSearch.columns;
      }
    }
    return reportUtil.exportIncidentData(
      incidents,
      cols,
      props.companySettings.dateFormat,
      props.lookupDataSet[LookupTypeKey.severityType],
    );
  };

  const authenitcated = auth.isAuthenticated;
  return !authenitcated ? (
    <Redirect to="/" />
  ) : (
    <DefaultLayout title={title} showNotifications>
      <Grid container justify="center">
        <Grid
          spacing={2}
          alignItems="center"
          justify="flex-start"
          container
          className={classes.grid}
        >
          <DraftIncidentsCard
            onClick={onDraftIncidentCardClick}
            count={props.draftIncidents.length}
          />

          <OpenIncidentCard
            onClick={onOpenIncidentCardClick}
            count={props.openIncidents.length}
          />
          <ClosedIncidentsCard
            onClick={onClosedIncidentCardClick}
            count={props.closedIncidents.length}
          />

          {props.customSearches.map(item => (
            <DashboardItem key={item.id}>
              <Card
                className={classes.cardSearchItem}
                onClick={handleCustomCardClick(item)}
              >
                <CardHeader
                  // avatar={}
                  title={
                    <Box display="flex" alignItems="center">
                      <SearchIcon className={classes.leftIcon} />
                      <Typography variant="h5" gutterBottom>
                        {item.name}
                      </Typography>
                    </Box>
                  }
                  subheader={item.description}
                />
                <CardActions disableSpacing>
                  <IconButton
                    onClick={handleEditCustomSearch(item)}
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={handleDeleteCustomSearch(item)}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </CardActions>
              </Card>
            </DashboardItem>
          ))}

          {props.customAnalysis.map(item => (
            <DashboardChartItem
              key={item.id}
              analysis={item}
              onCommand={handleAnalysisCommand(item)}
            />
          ))}
        </Grid>
      </Grid>

      {state.dialogOpen && (
        <BaseDialog
          open={state.dialogOpen}
          maxWidth="lg"
          fullScreen={isSmaller}
          onClose={closeDialog}
          title={
            <div className={classes.cardDialogTitle}>
              <div className={classes.cardDialogTitleText}>
                {state.dialogTitle}
              </div>
              {isMobile ? (
                <>
                  {['openIncidents', 'closedIncidents'].includes(
                    state.openCardType,
                  ) && (
                    <Tooltip title="Adjust columns">
                      <IconButton
                        color="primary"
                        aria-label="Adjust columns"
                        onClick={openColumnsDialog}
                        size="small"
                      >
                        <ViewColumnIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  <CSVLink
                    data={getExportData()}
                    filename={`${state.dialogTitle}.csv`}
                  >
                    <Tooltip title="Export to excel">
                      <IconButton
                        color="primary"
                        aria-label="Export to excel"
                        size="small"
                      >
                        <ExcelIcon />
                      </IconButton>
                    </Tooltip>
                  </CSVLink>
                  <IconButton onClick={closeDialog}>
                    <CloseIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  {['openIncidents', 'closedIncidents'].includes(
                    state.openCardType,
                  ) && (
                    <Tooltip title="Adjust columns">
                      <IconButton
                        color="primary"
                        aria-label="Adjust columns"
                        onClick={openColumnsDialog}
                        size="small"
                      >
                        <ViewColumnIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  <CSVLink
                    data={getExportData()}
                    filename={`${state.dialogTitle}.csv`}
                  >
                    <Tooltip title="Export to excel">
                      <IconButton
                        color="primary"
                        aria-label="Export to excel"
                        size="small"
                      >
                        <ExcelIcon />
                      </IconButton>
                    </Tooltip>
                  </CSVLink>
                  <IconButton onClick={closeDialog} size="small">
                    <CloseIcon />
                  </IconButton>
                </>
              )}
            </div>
          }
        >
          <>
            {!auth.hasPrivilege(['ALLINCIDENT_SEARCH']) && (
              <Typography variant="body2" className={classes.note}>
                * Due to permission settings, your search will only return
                results for incidents you have created.
              </Typography>
            )}
            {state.openCardType === 'openIncidents' && (
              <IncidentTable
                incidents={props.openIncidents}
                incidentLookups={
                  props.lookupDataSet[LookupTypeKey.incidentClass]
                }
                severityLookups={
                  props.lookupDataSet[LookupTypeKey.severityType]
                }
                onItemCommand={handleIncidentCommand}
                columns={searchResultColumnInfo.columns}
                accountDateFormat={props.companySettings.dateFormat}
              />
            )}
            {state.openCardType === 'closedIncidents' && (
              <IncidentTable
                incidents={props.closedIncidents}
                incidentLookups={
                  props.lookupDataSet[LookupTypeKey.incidentClass]
                }
                severityLookups={
                  props.lookupDataSet[LookupTypeKey.severityType]
                }
                onItemCommand={handleIncidentCommand}
                columns={searchResultColumnInfo.columns}
                accountDateFormat={props.companySettings.dateFormat}
              />
            )}
            {state.openCardType === 'draftIncidents' && (
              <DraftIncidentTable
                draftIncidents={props.draftIncidents}
                incidentLookups={
                  props.lookupDataSet[LookupTypeKey.incidentClass]
                }
                onItemCommand={handleIncidentCommand}
              />
            )}
            {state.openCardType === 'customResult' &&
              state.selectedCustomSearch && (
                <IncidentTable
                  incidents={
                    props.customResultSet[state.selectedCustomSearch.id] || []
                  }
                  incidentLookups={
                    props.lookupDataSet[LookupTypeKey.incidentClass]
                  }
                  severityLookups={
                    props.lookupDataSet[LookupTypeKey.severityType]
                  }
                  onItemCommand={handleIncidentCommand}
                  columns={
                    state.selectedCustomSearch.columns
                      ? state.selectedCustomSearch.columns
                      : getDefaultSearchResultColumns()
                  }
                  accountDateFormat={props.companySettings.dateFormat}
                />
              )}
          </>
        </BaseDialog>
      )}

      {state.selectedAnalysis && (
        <AnalyzeDialog
          open={state.analysisOpen}
          onClose={handleCloseAnalyze}
          analysis={state.selectedAnalysis}
          chartResult={props.analysisResultSet[state.selectedAnalysis.id] || []}
        />
      )}

      {state.selectedIncident && (
        <>
          <IncidentViewDialog
            open={state.viewerOpen}
            onClose={closeIncidentView}
            incident={state.selectedIncident}
            initialMode={state.viewerMode}
            onRefreshParentItems={handleRefreshItems}
            lookupDataSet={props.lookupDataSet}
            openClosedIncident={() => {
              setOpenClosedIncidentAlertOpen(true);
            }}
          />
        </>
      )}
      {!isMobile && (
        <>
          <AlertDialog
            title="Delete Saved Search"
            message={`Are you sure you want to delete this saved search "${state.selectedCustomSearch &&
              state.selectedCustomSearch.name}"?`}
            open={props.deleteCustomSearchDialogOpened}
            onClose={() => {
              props.setOpenCustomSearchDeleteDialog(false);
            }}
            onCancel={() => {
              props.setOpenCustomSearchDeleteDialog(false);
            }}
            onOk={() => {
              props.deleteCustomSearch(state.selectedCustomSearch.id);
            }}
          />
          <AlertDialog
            title="Delete Saved Analysis"
            message={`Are you sure you want to delete this saved analysis "${state.selectedAnalysis &&
              state.selectedAnalysis.name}"?`}
            open={props.deleteCustomAnalysisDialogOpened}
            onClose={() => {
              props.setOpenCustomAnalysisDeleteDialog(false);
            }}
            onCancel={() => {
              props.setOpenCustomAnalysisDeleteDialog(false);
            }}
            onOk={() => {
              props.deleteCustomAnalysis(state.selectedAnalysis.id);
            }}
          />
        </>
      )}
      <Dialog
        maxWidth="lg"
        open={reportViewerInfo.open}
        onClose={() => {
          setReportViewerInfo({ open: false });
        }}
        className={classes.reportDialog}
      >
        <ReportViewer
          reportName={reportViewerInfo.reportName}
          reportId={reportViewerInfo.reportId}
          parameters={{ ReportName: reportViewerInfo.reportTitle }}
          reportTitle={reportViewerInfo.reportTitle}
          logoUrl={props.companyLogo}
        />
      </Dialog>
      <AlertDialog
        title="Reopen closed incident"
        message="Are you sure you want to re-open closed incident?"
        open={openClosedIncidentAlertOpen}
        onClose={() => {
          setOpenClosedIncidentAlertOpen(false);
        }}
        onCancel={() => {
          setOpenClosedIncidentAlertOpen(false);
        }}
        onOk={() => {
          handleOpenClosedIncident(state.selectedIncident.id);
        }}
      />
      <AlertDialog
        title="Delete incident"
        message={
          <>
            <div>{`Are you sure you want to delete the incident #${deleteIncidentInfo.selectedIncident &&
              deleteIncidentInfo.selectedIncident.number}?`}</div>
            <div className={classes.deleteIncidentDesc}>
              You will not be able to undo this action.
            </div>
          </>
        }
        open={deleteIncidentInfo.open}
        onClose={() => {
          setDeleteIncidentInfo({ open: false, selectedIncident: undefined });
        }}
        onCancel={() => {
          setDeleteIncidentInfo({ open: false, selectedIncident: undefined });
        }}
        onOk={() => {
          handleDeleteIncident(deleteIncidentInfo.selectedIncident.id);
        }}
      />
      <SearchResultColumnsDialog
        open={searchResultColumnInfo.open}
        searchResultColumns={searchResultColumnInfo.columns}
        onChange={newColumns =>
          setSearchResultColumnInfo(
            produce(searchResultColumnInfo, draft => {
              draft.columns = newColumns;
            }),
          )
        }
        onClose={handleColumDialogClose}
      />
    </DefaultLayout>
  );
};

Dashboard.propTypes = {
  lookupDataSet: LookupDataSetDef.isRequired,
  refreshData: PropTypes.func.isRequired,
  loadCustomSearches: PropTypes.func.isRequired,
  deleteDraftIncident: PropTypes.func.isRequired,
  deleteIncident: PropTypes.func.isRequired,
  executeCustomSearch: PropTypes.func.isRequired,
  deleteCustomSearch: PropTypes.func.isRequired,
  setOpenCustomSearchDeleteDialog: PropTypes.func.isRequired,
  deleteCustomSearchDialogOpened: PropTypes.bool.isRequired,
  draftIncidents: PropTypes.arrayOf(DraftIncidentDef).isRequired,
  openIncidents: PropTypes.arrayOf(DraftIncidentDef).isRequired,
  closedIncidents: PropTypes.arrayOf(DraftIncidentDef).isRequired,
  customSearches: PropTypes.arrayOf(CustomSearchDef).isRequired,
  customResultSet: PropTypes.object.isRequired,
  enqueueError: PropTypes.func.isRequired,
  customAnalysis: PropTypes.arrayOf(CustomAnalysisDef).isRequired,
  analysisResultSet: PropTypes.object.isRequired,
  loadCustomAnalysis: PropTypes.func.isRequired,
  deleteCustomAnalysis: PropTypes.func.isRequired,
  deleteCustomAnalysisDialogOpened: PropTypes.bool.isRequired,
  setOpenCustomAnalysisDeleteDialog: PropTypes.func.isRequired,
  openClosedIncident: PropTypes.func.isRequired,
  companyLogo: PropTypes.string,
  getCompanyLogo: PropTypes.func.isRequired,
  getInitData: PropTypes.func.isRequired,
  history: PropTypes.object,
};

const mapStateToProps = ({ common, dashboard, reports }) => ({
  lookupDataSet: common.lookupDataSet,
  draftIncidents: dashboard.draftIncidents,
  customSearches: dashboard.customSearches,
  customResultSet: dashboard.customResultSet,
  deleteCustomSearchDialogOpened: dashboard.deleteCustomSearchDialogOpened,
  customAnalysis: dashboard.customAnalysis,
  analysisResultSet: dashboard.analysisResultSet,
  deleteCustomAnalysisDialogOpened: dashboard.deleteCustomAnalysisDialogOpened,
  openIncidents: dashboard.myIncidents.filter(
    x => x.status === IncidentStatusEnum.open,
  ),
  closedIncidents: dashboard.myIncidents.filter(
    x => x.status === IncidentStatusEnum.closed,
  ),
  companyLogo: reports.companyLogo,
  companySettings: common.companySettings,
});

const mapDispatchToProps = {
  refreshData,
  loadCustomSearches,
  deleteDraftIncident,
  deleteIncident,
  executeCustomSearch,
  deleteCustomSearch,
  setOpenCustomSearchDeleteDialog,
  enqueueError,
  loadCustomAnalysis,
  deleteCustomAnalysis,
  setOpenCustomAnalysisDeleteDialog,
  openClosedIncident,
  getCompanyLogo,
  getInitData,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard),
);
