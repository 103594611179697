import BaseApi from './BaseApi';

class LookupApi extends BaseApi {
  urlSingle;

  urlMultiple;

  urlMultipleLabel;

  urlAddress;

  constructor() {
    super();
    this.urlSingle = `${this.baseUrl}/lookupSingles`;
    this.urlMultiple = `${this.baseUrl}/lookupMultiples`;
    this.urlMultipleLabel = `${this.baseUrl}/lookupMultipleLabels`;
    this.urlAddress = `${this.baseUrl}/lookupAddresses`;
  }

  async querySingles(params) {
    return await this.get(this.urlSingle, { params });
  }

  async getSingle(id) {
    return await this.get(`${this.urlSingle}/${id}`);
  }

  async addSingle(model) {
    return await this.post(`${this.urlSingle}`, model);
  }

  async updateSingle(model) {
    return await this.put(`${this.urlSingle}`, model);
  }

  async removeSingle(model) {
    return await this.delete(`${this.urlSingle}/${model.id}`, {
      params: { type: model.type },
    });
  }

  async queryMultiples(params) {
    return await this.get(this.urlMultiple, { params });
  }

  async getMultiple(id) {
    return await this.get(`${this.urlMultiple}/${id}`);
  }

  async exportMultiples(params) {
    return await this.get(`${this.urlMultiple}/export`, { params });
  }

  async addMultiple(model) {
    return await this.post(`${this.urlMultiple}`, model);
  }

  async updateMultiple(model) {
    return await this.put(this.urlMultiple, model);
  }

  async removeMultiple(model) {
    return await this.delete(`${this.urlMultiple}/${model.id}`, {
      params: { type: model.type },
    });
  }

  async queryMultipleLabels(params) {
    return await this.get(this.urlMultipleLabel, { params });
  }

  async addMultipleLabel(model) {
    return await this.post(this.urlMultipleLabel, model);
  }

  async getAddress(id) {
    return await this.get(`${this.urlAddress}/${id}`);
  }

  async addAddress(model) {
    return await this.post(this.urlAddress, model);
  }

  async updateAddress(model) {
    return await this.put(this.urlAddress, model);
  }

  async removeAddress(id) {
    return await this.delete(`${this.urlAddress}/${id}`);
  }
}

export default new LookupApi();
