import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import {
  FormControl,
  Typography,
  Button,
  InputLabel,
  Input,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  group: {
    margin: 0,
  },
  label: {
    marginLeft: theme.spacing(-1),
  },
  searchFields: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}));

const ItemSearchOption = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({});
  const { name, brand, model, color, serialNumber, hasItem } = state;

  const inputHandleChange = event => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const addSearchCriteria = () => {
    const item = {
      id: uuid(),
      hasItem,
      name,
      brand,
      model,
      color,
      serialNumber,
    };

    props.onSearchCriteriaAdded(item);
  };

  const clearSearchCriteria = () => {
    setState({
      ...state,
      hasItem: false,
      name: '',
      brand: '',
      model: '',
      color: '',
      serialNumber: '',
    });
  };

  const toggleHasItem = () => {
    setState({
      ...state,
      hasItem: !hasItem,
      name: hasItem ? name : '',
      brand: hasItem ? brand : '',
      model: hasItem ? model : '',
      color: hasItem ? color : '',
      serialNumber: hasItem ? serialNumber : '',
    });
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography variant="h6">Item</Typography>
        <div className={classes.searchFields}>
          <FormControlLabel
            control={
              <Switch checked={state.hasPerson} onChange={toggleHasItem} />
            }
            label={<Typography variant="body1">Has item</Typography>}
          />
        </div>
        <div className={classes.searchFields}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="name">Name</InputLabel>
            <Input
              id="name"
              name="name"
              autoFocus={!hasItem}
              value={hasItem ? '' : name}
              disabled={hasItem}
              onChange={inputHandleChange}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="brand">Brand</InputLabel>
            <Input
              id="brand"
              name="brand"
              value={hasItem ? '' : brand}
              disabled={hasItem}
              onChange={inputHandleChange}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="model">Model</InputLabel>
            <Input
              id="model"
              name="model"
              value={hasItem ? '' : model}
              disabled={hasItem}
              onChange={inputHandleChange}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="color">Color</InputLabel>
            <Input
              id="color"
              name="color"
              value={hasItem ? '' : color}
              disabled={hasItem}
              onChange={inputHandleChange}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="serialNumber">Serial #</InputLabel>
            <Input
              id="serialNumber"
              name="serialNumber"
              value={hasItem ? '' : serialNumber}
              disabled={hasItem}
              onChange={inputHandleChange}
            />
          </FormControl>
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            onClick={clearSearchCriteria}
          >
            Clear
          </Button>
        </div>
        <div className={classes.searchFields}>
          <FormControl className={classes.formControl}>
            <Button
              variant="contained"
              color="primary"
              className={classes.searchButton}
              onClick={addSearchCriteria}
            >
              Add Search Criteria
            </Button>
          </FormControl>
        </div>
      </div>
    </React.Fragment>
  );
};

ItemSearchOption.propTypes = {
  onSearchCriteriaAdded: PropTypes.func.isRequired,
};

ItemSearchOption.defaultProps = {
  onSearchCriteriaAdded: () => {},
};

export default ItemSearchOption;
