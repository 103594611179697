import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
// import mammoth from 'mammoth';

import { getContentFromUrl, getAzureFileUrl } from '../../utils/fileUtil';
import Auth from '../../auth/Auth';

const useStyles = makeStyles(theme => ({
  textContainer: {
    flexGrow: 1,
    width: '100%',
    padding: theme.spacing(1, 2),
  },
}));

const DocFileView = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({});

  const convertHtml = async data => {
    // return await mammoth.convertToHtml(
    //   { arrayBuffer: data },
    //   { includeDefaultStyleMap: true },
    // );
  };

  React.useEffect(() => {
    getContentFromUrl(
      getAzureFileUrl(Auth.currentUser.accountId, props.fileName, false, props.sasKey),
      'arraybuffer',
    )
      .then(({ data }) => convertHtml(data))
      .then(({ value }) => {
        setState({ ...state, remoteContent: value });
      });
  }, []);

  return (
    <div
      className={classes.textContainer}
      dangerouslySetInnerHTML={{ __html: state.remoteContent }}
    />
  );
};

DocFileView.propTypes = {
  fileName: PropTypes.string.isRequired,
  sasKey: PropTypes.string.isRequired,
};

export default DocFileView;
