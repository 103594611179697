import React from 'react';
import PropTypes from 'prop-types';
import MapIcon from '@material-ui/icons/Map';
import { Button, TextField, FormControl, makeStyles } from '@material-ui/core';
import * as yup from 'yup';
import _ from 'lodash';
import LookupMultiplesSelect from './LookupMultiplesSelect';
import CitySearch from '../../components/forms/CitySearch';
import { useForm } from '../../utils/hooks';
import { uuidPattern } from '../../constants';
import CountrySelect from '../../components/forms/CountrySelect';
import { LookupDef, LocationDef } from '../../components/propTypeDefs';
import { getProvinceStateLabel } from '../../utils/addressUtil';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  expansionCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  expansionCardItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // padding: 10,
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  lineItem: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));

const schema = yup.object().shape({
  unit: yup
    .string()
    .nullable()
    .max(20),
  address: yup
    .string()
    .nullable()
    .max(100),
  street: yup
    .string()
    .nullable()
    .max(50),
  cityId: yup
    .string()
    .nullable()
    .matches(uuidPattern, {
      message: 'invalid cityId',
      excludeEmptyString: true,
    }),
  postalCode: yup
    .string()
    .nullable()
    .max(10),
});

const LocationDetailForm = props => {
  const classes = useStyles();
  const { locationLabels, locationLookups } = props;
  const {
    formValues,
    updateFormValues,
    hasFormError,
    formHelpText,
    handleFormChange,
    handleFormBlur,
  } = useForm({
    validationSchema: schema,
  });
  const [defaultCity, setDefaultCity] = React.useState({
    cityId: props.defaultValues.cityId,
    cityName: props.defaultValues.cityName,
  });

  React.useEffect(() => {
    const {
      address,
      cityId,
      cityName,
      countryCode,
      lastLocationId,
      postalCode,
      stateName,
      street,
      unit,
    } = props.defaultValues || {};

    const initialValue = {
      address,
      cityId,
      cityName,
      countryCode,
      lastLocationId,
      postalCode,
      stateName,
      street,
      unit,
    };
    updateFormValues(initialValue);
    setDefaultCity({
      cityId: initialValue.cityId,
      cityName: initialValue.cityName,
    });
  }, []);

  React.useEffect(() => {
    !_.isEmpty(formValues) && props.onChange(formValues);
  }, [formValues]);

  const handleChangeValues = e => {
    handleFormChange(e);
  };
  const raiseFormEvent = (name, value) => {
    handleFormChange({ target: { name, value } });
  };
  const handleChangeLookups = value => {
    raiseFormEvent('lastLocationId', value);
  };
  const handleCityChange = e => {
    const { value: city } = e.target || {};
    const value = (city && city.id) || undefined;
    updateFormValues({
      cityId: value,
      cityName: value ? city.name : undefined,
      stateName: value ? city.stateName : undefined,
      countryCode: value ? city.countryCode : undefined,
    });
  };

  return (
    <>
      <div className={classes.lineItem}>
        <LookupMultiplesSelect
          labels={locationLabels}
          lookups={locationLookups}
          onChange={handleChangeLookups}
          defaultValue={formValues.lastLocationId || ''}
        />
      </div>
      <div className={classes.expansionCardItem}>
        <FormControl className={classes.formControl}>
          <TextField
            id="buildingUnit"
            label="Unit #"
            margin="none"
            name="unit"
            value={formValues.unit || ''}
            onChange={handleChangeValues}
            onBlur={handleFormBlur}
            InputLabelProps={{
              shrink: true,
            }}
            error={hasFormError('unit')}
            helperText={formHelpText('unit')}
            inputProps={{ autoComplete: 0 }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="address"
            label="Address #"
            name="address"
            value={formValues.address || ''}
            onChange={handleChangeValues}
            onBlur={handleFormBlur}
            error={hasFormError('address')}
            helperText={formHelpText('address')}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ autoComplete: 0 }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="street"
            label="Street/Ave"
            name="street"
            value={formValues.street || ''}
            onChange={handleChangeValues}
            onBlur={handleFormBlur}
            error={hasFormError('street')}
            helperText={formHelpText('street')}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ autoComplete: 0 }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <CitySearch defaultCity={defaultCity} onChange={handleCityChange} />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label={getProvinceStateLabel(formValues.countryCode)}
            disabled
            value={formValues.stateName || ''}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Country"
            disabled
            value={formValues.countryCode || ''}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="postalCode"
            label="Zip/Postal code"
            className={classes.postalCode}
            name="postalCode"
            value={formValues.postalCode || ''}
            onChange={handleChangeValues}
            onBlur={handleFormBlur}
            error={hasFormError('postalCode')}
            helperText={formHelpText('postalCode')}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ autoComplete: 0 }}
          />
        </FormControl>
      </div>
    </>
  );
};

LocationDetailForm.propTypes = {
  locationLabels: PropTypes.arrayOf(PropTypes.string),
  locationLookups: PropTypes.arrayOf(LookupDef),
  defaultValues: LocationDef,
  onChange: PropTypes.func,
};
LocationDetailForm.defaultValues = {
  locationLabels: [],
  locationLookups: [],
  defaultValues: undefined,
  onChange: f => f,
};

export default LocationDetailForm;
