import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Typography,
  Grid,
  Button,
  Dialog,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  IconButton,
  DialogTitle,
  DialogContent,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import ReportIcon from '@material-ui/icons/Description';
import ChartIcon from '@material-ui/icons/ShowChart';
import PrintIcon from '@material-ui/icons/Print';
import uuid from 'uuid/v4';
import { CSVLink } from 'react-csv';
import ExcelIcon from '../../components/icons/ExcelIcon';
import IncidentTable from '../dashboard/IncidentTable';

import AlertDialog from '../../components/dialogs/AlertDialog';
import BaseDialog from '../../components/dialogs/BaseDialog';
import ReportViewer from '../../components/ReportViewer';
import SectionHeader from '../../components/typo/SectionHeader';
import DefaultLayout from '../DefaultLayout';
import { getCustomReports, deleteCustomReport, getCompanyLogo } from './ducks';
import {
  CustomSearchDef,
  LookupDataSetDef,
} from '../../components/propTypeDefs';
import reportUtil, {
  convertIncidentSearchResultToReportData,
} from '../../utils/reportUtil';
import reportApi from '../../apis/report.api';
import {
  LookupTypeKey,
  getDefaultSearchResultColumns,
  CustomReportTypeEnum,
} from '../../constants';
import { enqueueError } from '../../modules/global';
import Auth from '../../auth/Auth';
import { useMediaInfo } from '../../utils/hooks';
import AnalyzeDialog from '../dashboard/AnalyzeDialog';
import analyzeApi from '../../apis/analyze.api';
import searchApi from '../../apis/search.api';

const useStyles = makeStyles(theme => ({
  grid: {
    // width: 1200,
    // marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  card: {
    textAlign: 'left',
    // color: theme.palette.text.secondary,
  },
  cardContent: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
      minHeight: '100px',
    },
  },
  rangeLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 32,
  },
  outlinedButton: {
    textTransform: 'uppercase',
    margin: theme.spacing(1),
  },
  actionButton: {
    textTransform: 'uppercase',
    margin: theme.spacing(1),
    width: 152,
  },
  blockCenter: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  block: {
    padding: theme.spacing(2),
  },
  box: {
    marginBottom: 40,
    height: 65,
  },
  inlining: {
    display: 'inline-block',
    marginRight: 10,
  },
  buttonBar: {
    display: 'flex',
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  noBorder: {
    borderBottomStyle: 'hidden',
  },
  loadingState: {
    opacity: 0.05,
  },
  loadingMessage: {
    position: 'absolute',
    top: '40%',
    left: '40%',
  },
  reportViewer: {
    maxWidth: '800px',
    width: '800px',
    height: '600px',
  },
  cardDialogTitle: {
    display: 'flex',
  },
  cardDialogTitleText: {
    flexGrow: 1,
  },
  dialogContent: {
    padding: 0,
  },
  cardActions: {
    padding: 0,
    justifyContent: 'flex-end',
  },
  reportTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const title = <SectionHeader title="Reports" subtitle="" />;

const ReportList = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    reportViewerOpen: false,
  });
  const { reportViewerOpen } = state;
  const { isSmaller, isMobile } = useMediaInfo({
    minBreakPoint: 'md',
  });

  const openIncidentSearchResult = event => {
    setState({
      reportViewerOpen: true,
      reportName: 'IncidentSearchResult',
      reportUrl: `searchResult/39a4d83b-0cc4-41cb-9976-7feb6d8e827a`,
    });
  };

  // on mounted
  React.useEffect(() => {
    props.getCustomReports();
    props.getCompanyLogo();
  }, []);

  const [incidentTableViewerInfo, setIncidentTableViewerInfo] = React.useState({
    open: false,
    selectedReport: undefined,
    title: undefined,
    incidents: [],
    columns: getDefaultSearchResultColumns(),
  });

  const [reportViewerInfo, setReportViewerInfo] = React.useState({
    open: false,
    reportName: undefined,
    reportId: undefined,
    reportTitle: 'Report',
  });

  const [analysisViewerInfo, setAnalysisViewerInfo] = React.useState({
    open: false,
    analysis: undefined,
    chartResult: undefined,
  });

  const [deleteReport, setDeleteReport] = React.useState(undefined);

  const openAnalysis = async analysis => {
    const { id, criteria, name } = analysis;
    const { data } = await analyzeApi.analyze(criteria, name);

    setAnalysisViewerInfo({
      open: true,
      analysis,
      chartResult: data,
    });
  };

  const printReport = async report => {
    try {
      const searchResult = await reportApi.executeReport(report.id);
      const incidentList =
        searchResult && searchResult.data ? searchResult.data : [];
      const reportData = convertIncidentSearchResultToReportData(
        props.companySettings.dateFormat,
      )(incidentList, props.lookupDataSet[LookupTypeKey.incidentClass]);

      const newUploadReportId = uuid();

      await reportApi.uploadReportData(
        newUploadReportId,
        reportData,
        report.name,
      );

      setReportViewerInfo({
        open: true,
        reportName: 'IncidentSearchResult',
        reportId: newUploadReportId,
        reportTitle: report.name,
      });
    } catch (err) {
      props.enqueueError(err);
    }
  };

  const openReport = async report => {
    const { data } = await searchApi.search(report.criteria, report.name);

    setIncidentTableViewerInfo({
      open: true,
      selectedReport: report,
      incidents: data,
      title: report.name,
      columns: report.columns
        ? report.columns
        : getDefaultSearchResultColumns(),
    });
  };

  const handleOpenReport = id => {
    // get report data
    const item = _.find(props.reportItems, { id });
    if (item) {
      if (item.chartType) {
        openAnalysis(item);
      } else {
        openReport(item);
        // printReport(item);
      }
    }
  };

  const handleDeleteCustomReport = report => event => {
    event.stopPropagation();
    setDeleteReport(report);
  };

  const handleEditCustomReport = item => event => {
    event.stopPropagation();
    if (item.chartType) {
      // analysis
      props.history.push({
        pathname: '/analyze',
        state: {
          selectedItem: item,
          itemType: CustomReportTypeEnum.reportAnalysis,
        },
      });
    } else {
      // search
      props.history.push({
        pathname: '/search',
        state: {
          selectedItem: item,
          itemType: CustomReportTypeEnum.reportSearch,
        },
      });
    }
  };

  const handleCloseReport = () => {
    setReportViewerInfo({ open: false });
  };

  const closeIncidentTableDialog = () => {
    setIncidentTableViewerInfo({
      open: false,
      selectedReport: undefined,
      title: undefined,
      incidents: [],
      columns: getDefaultSearchResultColumns(),
    });
  };

  const items = isSmaller
    ? props.reportItems.filter(x => !x.chartType)
    : props.reportItems;

  const getExportData = () => {
    return reportUtil.exportIncidentData(
      incidentTableViewerInfo.incidents,
      incidentTableViewerInfo.columns,
      props.companySettings.dateFormat,
      props.lookupDataSet[LookupTypeKey.severityType],
    );
  };

  return (
    <DefaultLayout title={title}>
      <Grid container justify="center">
        <Grid
          spacing={2}
          alignItems="center"
          justify="flex-start"
          container
          className={classes.grid}
        >
          {items.map(report => (
            <Grid item xs={12} md={4} key={report.id}>
              <Card className={classes.card}>
                <CardActionArea
                  onClick={() => {
                    handleOpenReport(report.id);
                  }}
                >
                  <CardContent className={classes.cardContent}>
                    <div className={classes.reportTitle}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        color="primary"
                      >
                        {report.name}
                      </Typography>
                      {report.chartType ? (
                        <ChartIcon color="primary" />
                      ) : (
                        <ReportIcon color="primary" />
                      )}
                    </div>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {report.description}
                    </Typography>
                  </CardContent>
                  <CardActions className={classes.cardActions}>
                    {Auth.hasPrivilege(['REPORT_CREATE']) && (
                      <>
                        <IconButton
                          onClick={handleEditCustomReport(report)}
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={handleDeleteCustomReport(report)}>
                          <DeleteIcon color="error" />
                        </IconButton>
                      </>
                    )}
                  </CardActions>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {incidentTableViewerInfo.open && (
        <BaseDialog
          open={incidentTableViewerInfo.open}
          maxWidth="lg"
          fullScreen={isSmaller}
          onClose={closeIncidentTableDialog}
          title={
            <div className={classes.cardDialogTitle}>
              <div className={classes.cardDialogTitleText}>
                {incidentTableViewerInfo.title}
              </div>
              {isMobile ? (
                <IconButton onClick={closeIncidentTableDialog}>
                  <CloseIcon />
                </IconButton>
              ) : (
                <>
                  <CSVLink
                    data={getExportData()}
                    filename={`${incidentTableViewerInfo.title}.csv`}
                  >
                    <Tooltip title="Export to excel">
                      <IconButton color="primary" aria-label="Export to excel">
                        <ExcelIcon />
                      </IconButton>
                    </Tooltip>
                  </CSVLink>
                  <Tooltip title="Print">
                    <IconButton
                      color="primary"
                      aria-label="Print"
                      className={classes.printButton}
                      onClick={() => {
                        printReport(incidentTableViewerInfo.selectedReport);
                      }}
                    >
                      <PrintIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
          }
        >
          <IncidentTable
            incidents={incidentTableViewerInfo.incidents}
            incidentLookups={props.lookupDataSet[LookupTypeKey.incidentClass]}
            severityLookups={props.lookupDataSet[LookupTypeKey.severityType]}
            columns={incidentTableViewerInfo.columns}
            actionButtonHidden
            accountDateFormat={props.companySettings.dateFormat}
          />
        </BaseDialog>
      )}

      {reportViewerInfo.open && (
        <Dialog
          maxWidth="lg"
          fullWidth
          open={reportViewerInfo.open}
          fullScreen={isSmaller}
          onClose={handleCloseReport}
        >
          <DialogTitle>
            <div className={classes.cardDialogTitle}>
              <div className={classes.cardDialogTitleText}>Report Viewer</div>
              <IconButton onClick={handleCloseReport}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <ReportViewer
              reportName={reportViewerInfo.reportName}
              reportId={reportViewerInfo.reportId}
              parameters={{ ReportName: reportViewerInfo.reportTitle }}
              reportTitle={reportViewerInfo.reportTitle}
              logoUrl={props.companyLogo}
            />
          </DialogContent>
        </Dialog>
      )}

      {analysisViewerInfo.open && (
        <AnalyzeDialog
          open={analysisViewerInfo.open}
          onClose={() => {
            setAnalysisViewerInfo({ open: false });
          }}
          analysis={analysisViewerInfo.analysis}
          chartResult={analysisViewerInfo.chartResult}
        />
      )}

      {deleteReport && (
        <AlertDialog
          title="Delete Report"
          message={`Are you sure you want to delete this report, "${deleteReport &&
            deleteReport.name}"?`}
          open={!!deleteReport}
          onClose={() => {
            setDeleteReport();
          }}
          onCancel={() => {
            setDeleteReport();
          }}
          onOk={() => {
            props.deleteCustomReport(deleteReport).then(() => {
              setDeleteReport();
            });
          }}
        />
      )}
    </DefaultLayout>
  );
};

ReportList.propTypes = {
  lookupDataSet: LookupDataSetDef.isRequired,
  getCustomReports: PropTypes.func.isRequired,
  deleteCustomReport: PropTypes.func.isRequired,
  reportItems: PropTypes.arrayOf(CustomSearchDef).isRequired,
  enqueueError: PropTypes.func.isRequired,
  companyLogo: PropTypes.string.isRequired,
  getCompanyLogo: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = ({ common, reports }) => ({
  reportItems: reports.reportItems,
  lookupDataSet: common.lookupDataSet,
  companyLogo: reports.companyLogo,
  companySettings: common.companySettings,
});

const mapDispatchToProps = {
  getCustomReports,
  deleteCustomReport,
  enqueueError,
  getCompanyLogo,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportList),
);
