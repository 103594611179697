import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import ExpansionCard from '../../components/cards/ExpansionCard';
import IncidentAttachmentItem from '../newIncident/IncidentAttachmentItem';
import { IncidentAttachmentDef } from '../../components/propTypeDefs';
import AttachedImageViewer from '../../components/dialogs/AttachedImageViewer';
import { AttachmentType } from '../../constants';
import Auth from '../../auth/Auth';
import { getAzureFileUrl, downloadFromUrl } from '../../utils/fileUtil';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // flexWrap: 'wrap',
  },
  subRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  lineAttachment: {
    display: 'flex',
    flexDirection: 'row',
    alignAttachments: 'flex-start',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    display: 'flex',
    alignAttachments: 'flex-start',
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
    // height: 40,
  },
  label: {
    ...theme.typography.caption,
    color: 'grey',
  },
  value: {
    ...theme.typography.body,
    display: 'flex',
    alignAttachments: 'center',
  },
  attachmentRoot: {
    width: '100%',
    // flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  attachmentItem: {
    padding: 10,
  },
}));

const IncidentViewAttachments = props => {
  const classes = useStyles();
  const { expanded, attachments } = props;
  const [state, setState] = React.useState({
    selectedAttachment: undefined,
    viewerOpen: false,
  });

  const handleDownload = attachment => {
    if (
      attachment.attachmentType === AttachmentType.image ||
      attachment.attachmentType === AttachmentType.movie ||
      attachment.attachmentType === AttachmentType.audio ||
      attachment.attachmentType === AttachmentType.txt ||
      attachment.attachmentType === AttachmentType.pdf
    ) {
      setState({ ...state, viewerOpen: true, selectedAttachment: attachment });
    } else {
      if (!Auth.currentUser) return;
      const url = getAzureFileUrl(
        Auth.currentUser.accountId,
        attachment.fileName,
        false,
        attachment.sasKey,
        attachment.thumbnailSasKey
      );
      downloadFromUrl(url, attachment.name);
    }
  };
  const handleViewerClose = () => {
    setState({ ...state, viewerOpen: false, selectedAttachment: undefined });
  };

  return (
    <ExpansionCard
      title={`Attachments (${
        attachments.filter(x => !x.incidentItemId && !x.incidentVehicleId)
          .length
      })`}
      expanded={expanded}
    >
      <div className={classes.attachmentRoot}>
        {attachments
          .filter(x => !x.incidentItemId && !x.incidentVehicleId)
          .map(attachment => {
            return (
              <div key={attachment.id} className={classes.attachmentItem}>
                <IncidentAttachmentItem
                  editable={false}
                  attachment={attachment}
                  onDownload={handleDownload}
                />
              </div>
            );
          })}
      </div>
      {state.selectedAttachment && (
        <AttachedImageViewer
          attachment={state.selectedAttachment}
          open={state.viewerOpen}
          onClose={handleViewerClose}
        />
      )}
    </ExpansionCard>
  );
};

IncidentViewAttachments.propTypes = {
  expanded: PropTypes.bool,
  attachments: PropTypes.arrayOf(IncidentAttachmentDef).isRequired,
};

IncidentViewAttachments.defaultProps = {
  expanded: false,
  attachments: [],
};

export default IncidentViewAttachments;
