import React from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableBody,
  TableRow,
  IconButton,
  Table,
  TableHead,
  TableFooter,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { flattenCharacteristicsComponent } from '../../utils/incidentViewUtil';
import { IncidentCompanyDef } from '../../components/propTypeDefs';
import { useMediaInfo } from '../../utils/hooks';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 700,
    padding: theme.spacing(1),
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  nameCell: {
    width: '12em',
  },
  typeCell: {
    width: '10em',
  },
  actionCell: {
    width: '6em',
  },
  cardContent: {
    padding: theme.spacing(1, 2),
  },
  cardFieldRow: {},
  cardLabel: {
    marginRight: theme.spacing(2),
  },
}));

const CompanyList = props => {
  const classes = useStyles();
  const { isSmaller, isMobile } = useMediaInfo({ minBreakPoint: 'md' });

  const handleSelectCompany = company => event => {
    props.onItemCommand('select', company);
  };

  return (
    <>
      {isSmaller ? (
        <Grid spacing={1} container>
          {props.companies.map(company => (
            <Grid item key={company.id} xs={12} sm={12}>
              <Card>
                <CardContent className={classes.cardContent}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography variant="h6">{company.name}</Typography>
                    <Button
                      onClick={handleSelectCompany(company)}
                      variant="contained"
                      color="primary"
                    >
                      Add
                    </Button>
                  </Box>
                  {company.cityName && (
                    <div className={classes.cardFieldRow}>
                      <strong className={classes.cardLabel}>City</strong>
                      <span>{company.cityName}</span>
                    </div>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.nameCell}>Name</TableCell>
              <TableCell className={classes.typeCell}>City</TableCell>
              <TableCell className={classes.actionCell} />
            </TableRow>
          </TableHead>
          <TableBody>
            {props.companies.map(company => (
              <TableRow key={company.id}>
                <TableCell component="th" scope="row">
                  {company.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {company.cityName}
                </TableCell>
                <TableCell>
                  <Button
                    onClick={handleSelectCompany(company)}
                    variant="contained"
                    color="primary"
                  >
                    Add
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

CompanyList.propTypes = {
  companies: PropTypes.arrayOf(IncidentCompanyDef).isRequired,
  onItemCommand: PropTypes.func,
};
CompanyList.defaultProps = {
  onItemCommand: f => f,
};

export default CompanyList;
