import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import produce from 'immer';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ExpansionCard from '../../components/cards/ExpansionCard';
import IncidentVehicleItem from './IncidentVehicleItem';
import {
  IncidentPersonDef,
  IncidentVehicleDef,
  LookupDef,
  IncidentCompanyDef,
  StateProvinceDef,
  IncidentAttachmentDef,
} from '../../components/propTypeDefs';
import { PersonType } from '../../constants';
import { getCompanyName, getPersonName } from '../../utils/nameUtil';

const useStyles = makeStyles(theme => ({
  expansionCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  vehicleItem: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
  },
  expansionCardItem: {},
  itemAddButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
  },
}));

const IncidentVehicleCard = props => {
  const classes = useStyles();
  const { vehicles, incidentId } = props;
  const [state, setState] = React.useState({
    vehicles,
    selectedVehicleId:
      vehicles && vehicles.length === 1 ? vehicles[0].id : undefined,
  });
  const [propertyOwners, setPropertyOwners] = React.useState([]);

  // React.useEffect(() => {
  //   props.onChange({ vehicles: state.vehicles });
  // }, [state.vehicles]);

  React.useEffect(() => {
    setState({ ...state, vehicles });
  }, [props.vehicles]);

  React.useEffect(() => {
    const { persons, companies } = props;
    const extractor = personType => ({
      id,
      name,
      firstName,
      middleName,
      lastName,
    }) => ({
      id,
      name:
        personType === PersonType.person
          ? getPersonName({ firstName, middleName, lastName })
          : getCompanyName({ name }),
      personType,
    });
    const mergedList = persons
      .map(extractor(PersonType.person))
      .concat(companies.map(extractor(PersonType.company)));
    setPropertyOwners(mergedList);
  }, [props.persons, props.companies]);

  const emitData = data => {
    props.onChange({ vehicles: data });
  };

  const addVehicle = () => {
    const count = state.vehicles.length;
    const name = `Vehicle ${count + 1}`;
    const newVehicle = { id: uuid(), incidentId, licensePlate: name };
    const newList = [...state.vehicles, newVehicle];
    setState({
      ...state,
      vehicles: newList,
      selectedVehicleId: newVehicle.id,
    });
    emitData(newList);
  };

  const onVehicleCardExpanded = (expanded, vehicleId) => {
    setState({
      ...state,
      selectedVehicleId: expanded ? vehicleId : undefined,
    });
  };

  const onVehicleDeleted = vehicleId => {
    const newList = state.vehicles.filter(x => x.id !== vehicleId);
    setState({
      ...state,
      vehicles: newList,
    });
    emitData(newList);
  };
  const vehicleChanged = vehicle => {
    const nextState = produce(state, draft => {
      const found = draft.vehicles.find(x => x.id === vehicle.id);
      Object.assign(found, vehicle);
    });
    setState(nextState);
    emitData(nextState.vehicles);
  };

  return (
    <ExpansionCard
      title={`Vehicles (${state.vehicles.length})`}
      helpMessage="Add information about any vehicles involved in the incident."
    >
      <div className={classes.expansionCardContainer}>
        <div className={classes.expansionCardItem}>
          {state.vehicles.map(vehicle => {
            return (
              <div className={classes.vehicleItem} key={vehicle.id}>
                <IncidentVehicleItem
                  vehicle={vehicle}
                  propertyOwners={propertyOwners}
                  expanded={state.selectedVehicleId === vehicle.id}
                  onCardExpanded={onVehicleCardExpanded}
                  onDeleted={onVehicleDeleted}
                  onChange={vehicleChanged}
                  involveLookups={props.involveLookups}
                  states={props.states}
                  attachments={props.attachments}
                />
              </div>
            );
          })}
          <div className={classes.itemAddButton}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={addVehicle}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    </ExpansionCard>
  );
};

IncidentVehicleCard.propTypes = {
  onChange: PropTypes.func,
  incidentId: PropTypes.string,
  vehicles: PropTypes.arrayOf(IncidentVehicleDef),
  persons: PropTypes.arrayOf(IncidentPersonDef),
  companies: PropTypes.arrayOf(IncidentCompanyDef),
  involveLookups: PropTypes.arrayOf(LookupDef),
  states: PropTypes.arrayOf(StateProvinceDef),
  attachments: PropTypes.arrayOf(IncidentAttachmentDef),
};
IncidentVehicleCard.defaultProps = {
  onChange: f => f,
  vehicles: [],
  persons: [],
  companies: [],
  involveLookups: [],
  incidentId: undefined,
  states: [],
  attachments: [],
};

export default IncidentVehicleCard;
