import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Typography,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Input,
  FormHelperText,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import * as groupManageActions from './userGroupManagement/ducks';
import { UserGroupDef } from '../../components/propTypeDefs';
import {
  passwordSchema,
  userGroupSchema,
  userDetailSchema,
  profileSchema,
} from '../../models/validateSchemas';
import { DefaultDateFormat } from '../../constants';

const styles = theme => ({
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    // height: 80,
    flexWrap: 'wrap',
  },
  addUserDialog: {
    width: '80%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
});

const FormGroup = withStyles(styles)(props => {
  const {
    errors,
    touched,
    values,
    handleBlur,
    handleChange,
    classes,
    user,
    onFieldChange,
    onValidationChange,
    groupList,
    isAddMode,
  } = props;

  const [startDate, setStartDate] = React.useState(undefined);
  const [mfa, setMfa] = React.useState(values.mfa);
  React.useEffect(() => {
    if (user && user.companyStartDate) {
      setStartDate(user.companyStartDate);
    } else {
      const now = moment()
        .startOf('day')
        .toDate();
      onFieldChange({ companyStartDate: now });
      setStartDate(now);
    }
  }, [user]);

  React.useEffect(() => {
    onValidationChange(_.isEmpty(errors));
  }, [errors]);

  const handleStartDate = date => {
    setStartDate(date);
    onFieldChange({ companyStartDate: date });
  };
  const handleInputChange = e => {
    handleChange(e);
    onFieldChange({ [e.target.name]: e.target.value });
  };
  const handleInputBlur = e => {
    handleBlur(e);
  };
  const changeMFA = event => {
    setMfa(event.target.checked);
    onFieldChange({ mfa: event.target.checked });
  };
  return (
    <Form>
      <div className={classes.lineItem}>
        <FormControl className={classes.formControlLong}>
          <TextField
            required
            type="email"
            name="email"
            label="Email (User ID)"
            disabled={!isAddMode}
            value={values.email || ''}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            helperText={errors.email && touched.email && errors.email}
            error={touched.email && Boolean(errors.email)}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        {isAddMode && (
          <FormControl className={classes.formControlLong}>
            <TextField
              required
              type="password"
              name="password"
              label="Password"
              value={values.password || ''}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              helperText={
                errors.password && touched.password && errors.password
              }
              error={touched.password && Boolean(errors.password)}
              margin="none"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        )}
        {/* {!isAddMode && (
          <FormControl className={classes.formControl}>
            <Button color="primary">Password Reset</Button>
          </FormControl>
        )} */}
        {props.mfaEnabled && (
          <FormControlLabel
            control={<Switch checked={mfa} onChange={changeMFA} />}
            label={
              <Typography variant="body1">2-step verification (MFA)</Typography>
            }
          />
        )}
      </div>
      <div className={classes.lineItem}>
        <FormControl className={classes.formControl}>
          <TextField
            required
            name="firstName"
            label="First name"
            value={values.firstName || ''}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            helperText={
              errors.firstName && touched.firstName && errors.firstName
            }
            error={touched.firstName && Boolean(errors.firstName)}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            name="middleName"
            label="Middle initial"
            value={values.middleName || ''}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            helperText={
              errors.middleName && touched.middleName && errors.middleName
            }
            error={touched.middleName && Boolean(errors.middleName)}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            required
            name="lastName"
            label="Last name"
            value={values.lastName || ''}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            helperText={errors.lastName && touched.lastName && errors.lastName}
            error={touched.lastName && Boolean(errors.lastName)}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      </div>

      <div className={classes.lineItem}>
        <FormControl className={classes.formControl}>
          <TextField
            name="position"
            label="Position"
            value={values.position || ''}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            helperText={errors.position && touched.position && errors.position}
            error={touched.position && Boolean(errors.position)}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              label="Start date with company"
              format={DefaultDateFormat(props.accountDateFormat).date}
              value={startDate}
              onChange={handleStartDate}
              // onBlur={handleInputBlur}
              helperText={
                errors.companyStartDate &&
                touched.companyStartDate &&
                errors.companyStartDate
              }
              error={
                touched.companyStartDate && Boolean(errors.companyStartDate)
              }
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      </div>
      <div className={classes.lineItem}>
        <FormControl className={classes.formControl}>
          <TextField
            name="company"
            label="Company"
            value={values.company || ''}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            helperText={errors.company && touched.company && errors.company}
            error={touched.company && Boolean(errors.company)}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            name="department"
            label="Department"
            value={values.department || ''}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            helperText={
              errors.department && touched.department && errors.department
            }
            error={touched.department && Boolean(errors.department)}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            name="division"
            label="Division"
            value={values.division || ''}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            helperText={errors.division && touched.division && errors.division}
            error={touched.division && Boolean(errors.division)}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            name="location"
            label="Location"
            value={values.location || ''}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            helperText={errors.location && touched.location && errors.location}
            error={touched.location && Boolean(errors.location)}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            name="phone"
            label="Phone"
            type="tel"
            placeholder="ex) 123-456-7890"
            value={values.phone || ''}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            helperText={errors.phone && touched.phone && errors.phone}
            error={touched.phone && Boolean(errors.phone)}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            name="fax"
            label="Fax"
            type="tel"
            placeholder="ex) 123-456-7890"
            value={values.fax || ''}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            helperText={errors.fax && touched.fax && errors.fax}
            error={touched.fax && Boolean(errors.fax)}
            margin="none"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      </div>
      <div className={classes.lineItem}>
        <FormControl className={classes.formControl}>
          <Typography variant="h6">Workgroup</Typography>
        </FormControl>
      </div>
      <div className={classes.lineItem}>
        {/* {groupList && ( */}
        <FormControl
          className={classes.formControlLong}
          error={touched.userGroupId && Boolean(errors.userGroupId)}
        >
          <Select
            value={values.userGroupId || ''}
            onChange={handleInputChange}
            input={<Input id="userGroup-label-placeholder" />}
            onBlur={handleInputBlur}
            displayEmpty
            name="userGroupId"
            className={classes.selectEmpty}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {groupList &&
              groupList.map(g => (
                <MenuItem key={g.id} value={g.id}>
                  {g.name}
                </MenuItem>
              ))}
          </Select>
          {touched.userGroupId && Boolean(errors.userGroupId) && (
            <FormHelperText>
              {errors.userGroupId && touched.userGroupId && errors.userGroupId}
            </FormHelperText>
          )}
        </FormControl>

        {/* )} */}
      </div>
    </Form>
  );
});

const UserEdit = props => {
  const formRef = React.useRef();
  const { user, GroupManageActions, groupList } = props;
  const isAddMode = !user.id;
  // validation rules
  const validationSchema = yup.object().shape(
    isAddMode
      ? {
          ...profileSchema,
          ...passwordSchema,
          ...userGroupSchema,
          ...userDetailSchema,
        }
      : {
          ...profileSchema,
          ...userGroupSchema,
          ...userDetailSchema,
        },
  );

  React.useEffect(() => {
    if (formRef.current) {
      formRef.current.validateForm();
    }

    GroupManageActions.getAllGroups();
  }, []);

  return (
    <Formik
      ref={formRef}
      initialValues={user}
      isInitialValid={false}
      validationSchema={validationSchema}
      validateOnChange
      render={formikBag => (
        <FormGroup
          {...formikBag}
          {...props}
          groupList={groupList}
          isAddMode={isAddMode}
        />
      )}
    />
  );
};

UserEdit.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  userGroup: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  GroupManageActions: PropTypes.object,
  groupList: PropTypes.arrayOf(UserGroupDef),
  enqueueError: PropTypes.func.isRequired,
  accountDateFormat: PropTypes.number,
  mfaEnabled: PropTypes.bool,
};

UserEdit.defaultProps = {
  user: {},
};

const withConnect = connect(
  state => ({
    groupQueryState: state.administration.userGroups.queryState,
    groupList: state.administration.userGroups.userGroups,
  }),
  dispatch => ({
    GroupManageActions: bindActionCreators(groupManageActions, dispatch),
  }),
);

export default withConnect(UserEdit);
