/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';
import Editor, { createEditorStateWithText } from 'draft-js-plugins-editor';
import createToolbarPlugin, { Separator } from 'draft-js-static-toolbar-plugin';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
  CodeBlockButton,
} from 'draft-js-buttons';
import _ from 'lodash';

import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import editorStyles from './draftEditorStyles.module.css';
import buttonStyles from './draftEditorButtonStyles.module.css';
import toolbarStyles from './draftEditorToolbarStyles.module.css';

class HeadlinesPicker extends Component {
  componentDidMount() {
    setTimeout(() => {
      window.addEventListener('click', this.onWindowClick);
    });
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }

  onWindowClick = () =>
    // Call `onOverrideContent` again with `undefined`
    // so the toolbar can show its regular content again.
    this.props.onOverrideContent(undefined);

  render() {
    const buttons = [HeadlineOneButton, HeadlineTwoButton, HeadlineThreeButton];
    return (
      <div>
        {buttons.map((Button, i) => (
          // eslint-disable-next-line
          <Button key={i} {...this.props} />
        ))}
      </div>
    );
  }
}

HeadlinesPicker.propTypes = {
  onOverrideContent: PropTypes.func,
};

class HeadlinesButton extends Component {
  onClick = () =>
    // A button can call `onOverrideContent` to replace the content
    // of the toolbar. This can be useful for displaying sub
    // menus or requesting additional information from the user.
    this.props.onOverrideContent(HeadlinesPicker);

  render() {
    return (
      <div className={buttonStyles.headlineButtonWrapper}>
        <button
          type="button"
          onClick={this.onClick}
          className={buttonStyles.headlineButton}
        >
          H
        </button>
      </div>
    );
  }
}

HeadlinesButton.propTypes = {
  onOverrideContent: PropTypes.func,
};

export default class RichEditor extends Component {
  // state = {
  //   editorState: this.props.defaultValue
  //     ? EditorState.createWithContent(convertFromRaw(this.props.defaultValue))
  //     : createEditorStateWithText(''),
  // };

  handleChange = _.debounce((data, plainText) => {
    this.props.onChange(data, plainText);
  }, 1000);

  constructor(props) {
    super(props);
    let editorState;
    try {
      editorState = props.defaultValue
        ? EditorState.createWithContent(convertFromRaw(this.props.defaultValue))
        : createEditorStateWithText('');
    } catch (err) {
      editorState = createEditorStateWithText('');
    }

    // create toolbar plugins for each component.
    const toolbarPlugin = createToolbarPlugin({
      theme: { buttonStyles, toolbarStyles },
    });
    const { Toolbar } = toolbarPlugin;
    const plugins = [toolbarPlugin];

    this.state = {
      editorState,
      Toolbar,
      plugins,
    };
  }

  onChange = editorState => {
    const contentState = editorState.getCurrentContent();
    const plainText = contentState.getPlainText('\n');
    this.handleChange(convertToRaw(contentState), plainText);
    this.setState({
      editorState,
    });
  };

  focus = () => {
    this.editor.focus();
  };

  getLengthOfSelectedText = () => {
    const currentSelection = this.state.editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = this.state.editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  handleBeforeInput = chars => {
    const totalLength =
      this.state.editorState.getCurrentContent().getPlainText().length +
      chars.length -
      this.getLengthOfSelectedText();

    return totalLength > this.props.maxLength ? 'handled' : undefined;
  };

  handlePastedText = pastedText => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText().length;

    return currentContentLength +
      pastedText.length -
      this.getLengthOfSelectedText() >
      this.props.maxLength
      ? 'handled'
      : undefined;
  };

  render() {
    const { height } = this.props;
    const Toolbar = this.state.Toolbar;

    const myEditorStyle =
      height === 'low' ? editorStyles.editorShort : editorStyles.editor;
    return (
      // eslint-disable-next-line
      <div className={myEditorStyle} onClick={this.focus}>
        <Editor
          editorState={this.state.editorState}
          onChange={this.onChange}
          handleBeforeInput={this.handleBeforeInput}
          handlePastedText={this.handlePastedText}
          plugins={this.state.plugins}
          ref={element => {
            this.editor = element;
          }}
          spellCheck
        />
        <Toolbar>
          {// may be use React.Fragment instead of div to improve perfomance after React 16
          externalProps => (
            <React.Fragment>
              <BoldButton {...externalProps} description="bold" />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <CodeButton {...externalProps} />
              <Separator {...externalProps} />
              <HeadlinesButton {...externalProps} />
              <UnorderedListButton {...externalProps} />
              <OrderedListButton {...externalProps} />
              <BlockquoteButton {...externalProps} />
            </React.Fragment>
          )}
        </Toolbar>
      </div>
    );
  }
}

RichEditor.propTypes = {
  height: PropTypes.oneOf(['normal', 'low']),
  onChange: PropTypes.func,
  defaultValue: PropTypes.object,
  maxLength: PropTypes.number,
};

RichEditor.defaultProps = {
  height: 'normal',
  onChange: f => f,
  defaultValue: undefined,
  maxLength: 10000,
};
