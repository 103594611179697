import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import {
  FormControl,
  Button,
  InputLabel,
  Select,
  Input,
  MenuItem,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';

import {
  classOneValues,
  classTwoValues,
  classThreeValues,
  classFourValues,
} from '../../mockData';
import LookupMultiplesSelect from '../newIncident/LookupMultiplesSelect';
import {
  LookupDataSetDef,
  LookupLabelSetDef,
} from '../../components/propTypeDefs';
import { LookupTypeKey } from '../../constants';
import { buildLookupPath } from '../../utils/lookupTreeUtil';
import { enqueueError } from '../../modules/global';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  lineItem: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  group: {
    margin: 0,
  },
  label: {
    marginLeft: theme.spacing(-1),
  },
  searchFields: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}));

const LookupSelector = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    includeDescendant: true,
    allClass: false,
  });

  const addLookup = () => {
    const { lookupId, lookupPath, allClass, includeDescendant } = state;
    if (allClass) {
      props.onLookupAdded({
        id: uuid(),
        lookupId: undefined,
        lookupPath: undefined,
        allClass,
        includeDescendant: true,
      });
    } else {
      const lookupCriteria = {
        id: uuid(),
        lookupId,
        lookupPath,
        allClass,
        includeDescendant,
      };

      if (lookupCriteria.allClass || lookupCriteria.lookupId) {
        props.onLookupAdded(lookupCriteria);
      } else if (props.lookupType === LookupTypeKey.incidentClass) {
        props.enqueueError('Please select Incident class');
      } else {
        props.enqueueError('Please select Location');
      }
    }
  };

  const addAllClasses = () => {
    props.onLookupAdded({
      id: uuid(),
      lookupId: undefined,
      lookupPath: undefined,
      allClass: true,
      includeDescendant: true,
    });
  };

  const handleChangeLookups = lookupId => {
    const lookupPath = buildLookupPath(
      props.lookupDataSet[props.lookupType],
      lookupId,
    );
    setState({ ...state, lookupPath, lookupId });
  };
  const toggleDescendant = event => {
    setState({ ...state, includeDescendant: !state.includeDescendant });
  };
  const toggleAllChildren = () => {
    setState({ ...state, allChildren: !state.allChildren });
  };

  const toggleAllClass = event => {
    setState({ ...state, allClass: !state.allClass });
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classes.searchFields}>
          <LookupMultiplesSelect
            labels={props.lookupLabelSet[props.lookupType]}
            lookups={props.lookupDataSet[props.lookupType]}
            onChange={handleChangeLookups}
            defaultValue=""
          />
        </div>
        <div className={classes.searchFields}>
          <FormControl className={classes.formControl}>
            <Button
              variant="contained"
              color="primary"
              className={classes.searchButton}
              onClick={addLookup}
            >
              Add{' '}
              {props.lookupType === LookupTypeKey.incidentClass
                ? 'class'
                : 'location'}
            </Button>
          </FormControl>
          {(props.lookupType === LookupTypeKey.incidentClass ||
            props.lookupType === LookupTypeKey.location) && (
            <Button
              variant="contained"
              color="primary"
              className={classes.searchButton}
              onClick={addAllClasses}
            >
              Add all
            </Button>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

LookupSelector.propTypes = {
  onLookupAdded: PropTypes.func,
  lookupDataSet: LookupDataSetDef.isRequired,
  lookupLabelSet: LookupLabelSetDef.isRequired,
  lookupType: PropTypes.string.isRequired,
  enqueueError: PropTypes.func.isRequired,
};
LookupSelector.defaultProps = {
  onLookupAdded: () => {},
};

const mapStateToProps = ({ common }) => ({
  lookupLabelSet: common.lookupLabelSet,
  lookupDataSet: common.lookupDataSet,
});

const mapDispatchToProps = {
  enqueueError,
};

export default connect(mapStateToProps, mapDispatchToProps)(LookupSelector);
