import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import BaseDialog from './BaseDialog';

const AlertDialog = props => {
  const {
    title,
    message,
    loading,
    buttonDisabled,
    okButtonHidden,
    cancelButtonHidden,
  } = props;
  return (
    <BaseDialog
      open={props.open}
      title={title}
      maxWidth={props.maxWidth}
      onClose={props.onClose}
      loading={loading}
      actionContent={
        <>
          {!okButtonHidden && (
            <Button
              onClick={props.onOk}
              variant="contained"
              color="primary"
              autoFocus
              disabled={buttonDisabled}
            >
              Ok
            </Button>
          )}

          {!cancelButtonHidden && (
            <Button
              onClick={props.onCancel}
              color="primary"
              autoFocus
              disabled={buttonDisabled}
            >
              Cancel
            </Button>
          )}
        </>
      }
    >
      {message}
    </BaseDialog>
  );
};

AlertDialog.propTypes = {
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  title: PropTypes.string,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
  okButtonHidden: PropTypes.bool,
  cancelButtonHidden: PropTypes.bool,
};

AlertDialog.defaultProps = {
  onClose: () => {},
  onCancel: () => {},
  onOk: () => {},
  maxWidth: 'xs',
  loading: false,
  buttonDisabled: false,
  okButtonHidden: false,
  cancelButtonHidden: false,
};

export default AlertDialog;
