import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { makeStyles, createStyles } from '@material-ui/styles';
import {
  Button,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import UnlockIcon from '@material-ui/icons/LockOpen';

import {
  deactivateUser,
  activateUser,
  removeUser,
} from '../../../modules/administration/userManagement';
import { enqueueError } from '../../../modules/global';
import { dateFormat } from '../../../utils/dateUtil';
import ExpansionCard from '../../../components/cards/ExpansionCard';
import { UserDef } from '../../../components/propTypeDefs';
import AlertDialog from '../../../components/dialogs/AlertDialog';
import { removeGroup, updateGroup } from './ducks';
import userApi from '../../../apis/user.api';
import UserInfoDialog from '../UserInfoDialog';
import UserGroupEdit from './UserGroupEdit';
import auth from '../../../auth/Auth';
import { getPersonName } from '../../../utils/nameUtil';
import { DefaultDateFormat, UserStatusEnum } from '../../../constants';
import { common } from '@material-ui/core/colors';

const useStyles = makeStyles(theme =>
  createStyles({
    expansionCardContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    expansionCardItem: {
      // display: 'flex',
      // flexDirection: 'row',
      // alignItems: 'center',
      // marginTop: theme.spacing(2),
      // marginBottom: theme.spacing(2),
      // padding: theme.spacing(3),
      // textAlign: 'left',
      // color: theme.palette.text.secondary,
    },
    lineItem: {
      display: 'flex',
      // flexDirection: 'row',
      // alignItems: 'center',
      // height: 80,
      flexWrap: 'wrap',
    },
    addUserDialog: {
      width: '80%',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 180,
      // width: '100%',
    },
    formControlShort: {
      margin: theme.spacing(1),
      width: 150,
      // width: '100%',
    },
    formControlLong: {
      margin: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        minWidth: 400,
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    formControlFull: {
      margin: theme.spacing(1),
      width: '100%',
    },
    itemButtonSection: {
      marginTop: theme.spacing(1),
      display: 'flex',
      justifyContent: 'flex-end',
    },
    itemButton: {
      marginLeft: theme.spacing(1),
    },
    deleteUserGroupButton: {
      marginLeft: theme.spacing(1),
      backgroundColor: theme.palette.error.main,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
    deleteButton: {
      color: theme.palette.error.main,
    },
    lockButton: {
      color: theme.palette.grey['800'],
    },
  }),
);

const UserGroupItem = props => {
  const classes = useStyles();
  const { userGroup, expanded, users, selectedUserGroup } = props;
  const [
    deleteUserGroupDialogOpen,
    setDeleteUserGroupDialogOpen,
  ] = React.useState(false);
  const [selectedUserInfo, setSelectedUserInfo] = React.useState({
    user: undefined,
    addDialogOpen: false,
    deactivateDialogOpen: false,
    activateDialogOpen: false,
    deleteDialogOpen: false,
  });

  const [editUserGroupOpen, setEditUserGroupOpen] = React.useState(false);

  const groupUsers = users.filter(
    x =>
      x.userGroupId === userGroup.id &&
      (!props.inactiveUserHidden ||
        x.status === UserStatusEnum.active ||
        x.status === UserStatusEnum.pending),
  );
  const onCardExpanded = expandedValue => {
    props.onCardExpanded(expandedValue, props.userGroup.id);
  };

  const deleteUserGroup = () => {
    if (groupUsers.length > 0) {
      props.enqueueError(
        `Workgroup cannot be deleted if the workgroup has users.`,
      );
      return;
    }

    setDeleteUserGroupDialogOpen(true);
  };

  const onConfirmDeleteUserGroup = () => {
    if (selectedUserGroup) {
      setDeleteUserGroupDialogOpen(false);
      props.removeGroup(selectedUserGroup.id).then(() => {
        setDeleteUserGroupDialogOpen(false);
      });
    }
  };

  const canDelete = user => user.status !== 2;

  const addUser = () => {
    setSelectedUserInfo({
      user: { userGroupId: selectedUserGroup.id },
      addDialogOpen: true,
      deactivateDialogOpen: false,
      activateDialogOpen: false,
      deleteDialogOpen: false,
    });
  };

  const openEditUserDialog = user => {
    setSelectedUserInfo({
      user,
      addDialogOpen: true,
      deactivateDialogOpen: false,
      activateDialogOpen: false,
      deleteDialogOpen: false,
    });
  };

  const openDeactivateUserDialog = user => {
    setSelectedUserInfo({
      user,
      addDialogOpen: false,
      activateDialogOpen: false,
      deactivateDialogOpen: true,
      deleteDialogOpen: false,
    });
  };

  const onDeactivateUserRequest = () => {
    if (selectedUserInfo.user) {
      props.deactivateUser(selectedUserInfo.user.id).then(() => {
        setSelectedUserInfo({
          user: selectedUserInfo.user,
          addDialogOpen: false,
          activateDialogOpen: false,
          deactivateDialogOpen: false,
          deleteDialogOpen: false,
        });
      });
    }
  };

  const openActivateUserDialog = user => {
    setSelectedUserInfo({
      user,
      addDialogOpen: false,
      activateDialogOpen: true,
      deactivateDialogOpen: false,
      deleteDialogOpen: false,
    });
  };

  const onActivateUserRequest = () => {
    if (selectedUserInfo.user) {
      props.activateUser(selectedUserInfo.user.id).then(() => {
        setSelectedUserInfo({
          user: selectedUserInfo.user,
          addDialogOpen: false,
          activateDialogOpen: false,
          deactivateDialogOpen: false,
          deleteDialogOpen: false,
        });
      });
    }
  };

  const openDeleteUserDialog = user => {
    setSelectedUserInfo({
      user,
      addDialogOpen: false,
      activateDialogOpen: false,
      deactivateDialogOpen: false,
      deleteDialogOpen: true,
    });
  };

  const onDeleteUserRequest = () => {
    if (selectedUserInfo.user) {
      props.removeUser(selectedUserInfo.user.id).then(() => {
        setSelectedUserInfo({
          user: undefined,
          addDialogOpen: false,
          activateDialogOpen: false,
          deactivateDialogOpen: false,
          deleteDialogOpen: false,
        });
      });
    }
  };

  const openUserGroupEdit = () => {
    if (selectedUserGroup) {
      setEditUserGroupOpen(true);
    }
  };

  const saveUserGroup = userGroupForEdit => {
    props.updateGroup(userGroupForEdit).then(() => {
      setEditUserGroupOpen(false);
    });
  };

  const getStateName = state => {
    if (state === 0) {
      return 'Pending';
    }
    if (state === 2) {
      return 'Inactive';
    }
    return 'Active';
  };

  return (
    <React.Fragment>
      <ExpansionCard
        title={`${userGroup.name} (${groupUsers.length})`}
        theme="sub"
        expanded={expanded}
        onChange={onCardExpanded}
      >
        <div className={classes.expansionCardContainer}>
          <div className={classes.expansionCardItem}>
            <div className={classes.tableWrapper}>
              <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Position</TableCell>
                    <TableCell>Start date</TableCell>
                    <TableCell>Status</TableCell>
                    {props.mfaEnabled && <TableCell>MFA</TableCell>}
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupUsers.map(user => (
                    <TableRow key={user.id}>
                      <TableCell component="th" scope="row">
                        {`${user.firstName} ${user.lastName}`}
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.position}</TableCell>
                      <TableCell>
                        {dateFormat(
                          user.companyStartDate,
                          DefaultDateFormat(props.companySettings.dateFormat)
                            .dateMoment,
                        )}
                      </TableCell>
                      <TableCell>{getStateName(user.status)}</TableCell>
                      {props.mfaEnabled && (
                        <TableCell>{user.mfa ? 'On' : 'Off'}</TableCell>
                      )}
                      <TableCell>
                        <Tooltip title="Edit User" placement="right">
                          <IconButton
                            color="primary"
                            aria-label="Edit"
                            className={classes.margin}
                            onClick={() => {
                              openEditUserDialog(user);
                            }}
                            disabled={!auth.hasPrivilege(['USER_EDIT'])}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        {user.status === UserStatusEnum.active && (
                          <Tooltip title="Deactivate User" placement="right">
                            <IconButton
                              aria-label="Deactivate"
                              className={classes.lockButton}
                              onClick={() => {
                                openDeactivateUserDialog(user);
                              }}
                            >
                              <LockIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {user.status === UserStatusEnum.inactive && (
                          <Tooltip title="Activate User" placement="right">
                            <IconButton
                              aria-label="Activate"
                              className={classes.lockButton}
                              onClick={() => {
                                openActivateUserDialog(user);
                              }}
                            >
                              <UnlockIcon />
                            </IconButton>
                          </Tooltip>
                        )}

                        <Tooltip title="Delete User" placement="right">
                          <IconButton
                            aria-label="Delete"
                            className={classes.deleteButton}
                            onClick={() => {
                              openDeleteUserDialog(user);
                            }}
                            disabled={!auth.hasPrivilege(['USER_DELETE'])}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
          <div className={classes.itemButtonSection}>
            <Button
              variant="contained"
              color="primary"
              className={classes.itemButton}
              onClick={addUser}
              disabled={!auth.hasPrivilege(['USER_ADD'])}
            >
              Add User
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.itemButton}
              onClick={openUserGroupEdit}
              disabled={!auth.hasPrivilege(['USERGROUP_EDIT'])}
            >
              Edit Group
            </Button>
            <Button
              variant="contained"
              className={classes.deleteUserGroupButton}
              onClick={deleteUserGroup}
              disabled={!auth.hasPrivilege(['USERGROUP_DELETE'])}
            >
              Delete Group
            </Button>
          </div>
        </div>
      </ExpansionCard>
      <AlertDialog
        open={deleteUserGroupDialogOpen}
        title="Delete workgroup"
        message={`Are you sure you want to delete ${selectedUserGroup &&
          selectedUserGroup.name}?`}
        onOk={onConfirmDeleteUserGroup}
        onCancel={() => {
          setDeleteUserGroupDialogOpen(false);
        }}
      />
      <AlertDialog
        open={selectedUserInfo.deactivateDialogOpen}
        onClose={() => {
          setSelectedUserInfo({ user: selectedUserInfo.user });
        }}
        title="Deactivate User"
        message={`Are you sure you want to deactivate user ${getPersonName(
          selectedUserInfo.user || {},
        )}?`}
        onOk={onDeactivateUserRequest}
        onCancel={() => {
          setSelectedUserInfo({ user: selectedUserInfo.user });
        }}
      />
      <AlertDialog
        open={selectedUserInfo.activateDialogOpen}
        onClose={() => {
          setSelectedUserInfo({ user: selectedUserInfo.user });
        }}
        title="Activate User"
        message={`Are you sure you want to activate user ${getPersonName(
          selectedUserInfo.user || {},
        )}?`}
        onOk={onActivateUserRequest}
        onCancel={() => {
          setSelectedUserInfo({ user: selectedUserInfo.user });
        }}
      />
      <AlertDialog
        open={selectedUserInfo.deleteDialogOpen}
        onClose={() => {
          setSelectedUserInfo({ user: selectedUserInfo.user });
        }}
        title="Delete User"
        message={`Are you sure you want to delete user ${getPersonName(
          selectedUserInfo.user || {},
        )}?`}
        onOk={onDeleteUserRequest}
        onCancel={() => {
          setSelectedUserInfo({ user: selectedUserInfo.user });
        }}
      />
      <UserInfoDialog
        maxWidth="lg"
        open={selectedUserInfo.addDialogOpen}
        onClose={() => {
          setSelectedUserInfo({ user: selectedUserInfo.user });
        }}
        user={selectedUserInfo.user}
        accountDateFormat={props.companySettings.dateFormat}
      />

      {editUserGroupOpen && (
        <UserGroupEdit
          maxWidth="lg"
          open={editUserGroupOpen}
          onClose={() => {
            setEditUserGroupOpen(false);
          }}
          userGroup={selectedUserGroup || {}}
          onSaveUserGroup={saveUserGroup}
        />
      )}
    </React.Fragment>
  );
};

UserGroupItem.propTypes = {
  expanded: PropTypes.bool.isRequired,
  userGroup: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  selectedUserGroup: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  onCardExpanded: PropTypes.func,
  users: PropTypes.arrayOf(UserDef),
  enqueueError: PropTypes.func,
  removeGroup: PropTypes.func,
  deactivateUser: PropTypes.func,
  activateUser: PropTypes.func,
  removeUser: PropTypes.func,
  updateGroup: PropTypes.func,
  inactiveUserHidden: PropTypes.bool,
};

UserGroupItem.defaultProps = {
  expanded: false,
  onCardExpanded: () => {},
  users: [],
  enqueueError: f => f,
  removeGroup: f => f,
  deactivateUser: f => f,
  activateUser: f => f,
  removeUser: f => f,
  updateGroup: f => f,
  inactiveUserHidden: true,
};

function mapStateToProps({ administration, common }) {
  return {
    loadState: administration.users.queryState,
    users: administration.users.users,
    selectedUserGroup: administration.userGroups.selectedUserGroup,
    companySettings: common.companySettings,
    mfaEnabled: common.companySettings.mfa,
  };
}

const mapDispatchToProps = {
  enqueueError,
  removeGroup,
  deactivateUser,
  activateUser,
  removeUser,
  updateGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserGroupItem);
