import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import produce from 'immer';
import {
  Button,
  MenuItem,
  Typography,
  TextField,
  Input,
  InputLabel,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Select,
  FormControlLabel,
  Switch,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as yup from 'yup';
import _ from 'lodash';

import NumberFormat from 'react-number-format';
import {
  deleteAttachment,
  uploadAttachment,
  changeDraftIncident,
} from './ducks';
import RichEditor from '../../components/forms/RichEditor';
import ExpansionCard from '../../components/cards/ExpansionCard';
import {
  IncidentItemDef,
  PropertyOwnerDef,
  IncidentAttachmentDef,
} from '../../components/propTypeDefs';
import { useForm } from '../../utils/hooks';
import {
  uuidPattern,
  IncidentItemStatusEnum,
  AttachmentType,
} from '../../constants';
import { convertCurrencyToNumber } from '../../utils/currencyUtil';
import { getIncidentItemName } from '../../utils/nameUtil';
import IncidentAttachmentItem from './IncidentAttachmentItem';
import AddAttachmentDialog from '../../components/dialogs/AddAttachmentDialog';
import AttachedImageViewer from '../../components/dialogs/AttachedImageViewer';
import { getAzureFileUrl, downloadFromUrl } from '../../utils/fileUtil';
import Auth from '../../auth/Auth';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    minWidth: 150,
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  subCommentForm: {
    flexGrow: 1,
    margin: theme.spacing(1, 0),
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    // alignItems: 'center',
    // height: 80,
    flexWrap: 'wrap',
  },
  itemName: {
    margin: theme.spacing(1),
  },

  itemButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addAttachmentButton: {
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  deleteButton: {
    backgroundColor: theme.palette.error.dark,
    color: 'white',
  },
  itemHeader: {
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
  },
  expansionCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  attachmentRoot: {
    width: '100%',
    // flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  attachmentItem: {
    padding: theme.spacing(1),
  },
  emptyItem: {
    minHeight: '30px',
  },
}));

const schema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .max(100),
  ownerPersonId: yup
    .string()
    .nullable()
    .matches(uuidPattern, {
      message: 'invalid ownerPersonId',
    }),
  status: yup
    .string()
    .nullable()
    .max(50),
  company: yup
    .string()
    .nullable()
    .max(50),
  brand: yup
    .string()
    .nullable()
    .max(50),
  model: yup
    .string()
    .nullable()
    .max(50),
  color: yup
    .string()
    .nullable()
    .max(50),
  serialNumber: yup
    .string()
    .nullable()
    .max(50),
  comments: yup.object(),
});

const IncidentItemItem = props => {
  const classes = useStyles();
  const { expanded, item } = props;
  const {
    formValues,
    updateFormValues,
    hasFormError,
    formHelpText,
    handleFormChange,
    handleFormBlur,
  } = useForm({
    validationSchema: schema,
    onSubmit: (values, errors) => {},
  });

  const [state, setState] = React.useState({
    attachments: props.attachments,
    selectedAttachment: undefined,
    viewerOpen: false,
  });

  React.useEffect(() => {
    updateFormValues(item);
  }, []);

  const [addAttachmentDialogOpen, setAddAttachmentDialogOpen] = React.useState({
    open: false,
    uloading: false,
  });

  React.useEffect(() => {
    setState({
      attachments: props.attachments,
      selectedAttachment: undefined,
      viewerOpen: false,
    });
  }, [props.attachments]);

  React.useEffect(() => {
    if (!_.isEmpty(formValues)) {
      if (formValues.value) {
        const itemValues = Object.assign({}, formValues, {
          value: convertCurrencyToNumber(formValues.value),
        });
        props.onChange(itemValues);
      } else {
        props.onChange(formValues);
      }
    }
  }, [formValues]);

  const inputHandleChange = event => {
    handleFormChange(event);
  };
  const raiseFormEvent = (name, value) => {
    inputHandleChange({ target: { name, value } });
  };
  const itemStatusChange = value => event => {
    raiseFormEvent('status', value);
  };

  const onCardExpanded = expand => {
    props.onCardExpanded(expand, item.id);
  };

  const handleCommentsChange = value => {
    raiseFormEvent('comments', value);
  };

  const deleteItem = () => {
    state.attachments.forEach(attachment => {
      if (attachment.incidentItemId) {
        props.deleteAttachment(attachment);
      }
    });
    props.onDeleted(item.id);
  };
  const itemName = getIncidentItemName(item);

  const onAttachmentDeleted = attachment => {
    if (attachment) {
      props.deleteAttachment(attachment);
    }
  };

  const openAddAttachmentDialog = () => {
    setState({
      ...state,
      selectedAttachment: {
        id: uuid(),
        incidentId: props.item.incidentId,
        incidentItemId: props.item.id,
        name: `Attachment ${state.attachments.length + 1}`,
        comments: '',
      },
    });
    setAddAttachmentDialogOpen({ open: true, uploading: false });
  };

  const addAttachment = formData => {
    setAddAttachmentDialogOpen({ open: true, uploading: true });
    props
      .uploadAttachment(formData)
      .then(() => {
        setAddAttachmentDialogOpen({ open: false, uploading: false });
      })
      .catch(err => {
        setAddAttachmentDialogOpen({ open: true, uploading: false });
      });
  };
  const handleAttachmentDownload = attachment => {
    if (
      attachment.attachmentType === AttachmentType.image ||
      attachment.attachmentType === AttachmentType.movie ||
      attachment.attachmentType === AttachmentType.audio ||
      attachment.attachmentType === AttachmentType.txt ||
      attachment.attachmentType === AttachmentType.pdf
    ) {
      setState({ ...state, viewerOpen: true, selectedAttachment: attachment });
    } else {
      if (!Auth.currentUser) return;
      const url = getAzureFileUrl(
        Auth.currentUser.accountId,
        attachment.fileName,
        false,
        attachment.sasKey,
        attachment.thumbnailSasKey
      );
      downloadFromUrl(url, attachment.name);
    }
  };
  const handleViewerClose = () => {
    setState({ ...state, viewerOpen: false, selectedAttachment: undefined });
  };

  const handleAttachmentChange = model => {
    const nextState = produce(state, draft => {
      const found = draft.attachments.find(x => x.id === model.id);
      Object.assign(found, model);
    });
    setState(nextState);
    props.changeDraftIncident({ attachments: nextState.attachments });
  };

  return (
    <ExpansionCard
      title={itemName}
      theme="sub"
      expanded={expanded}
      onChange={onCardExpanded}
      titleStyle={classes.itemHeader}
    >
      <div className={classes.expansionCardContainer}>
        <div className={classes.expansionCardItem}>
          <div className={classes.lineItem}>
            <FormControlLabel
              control={
                <Switch
                  checked={formValues.status === IncidentItemStatusEnum.lost}
                  onChange={itemStatusChange(IncidentItemStatusEnum.lost)}
                  value="lost"
                />
              }
              label={<Typography variant="body1">Lost</Typography>}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formValues.status === IncidentItemStatusEnum.damaged}
                  onChange={itemStatusChange(IncidentItemStatusEnum.damaged)}
                  value="damaged"
                />
              }
              label={<Typography variant="body1">Damaged</Typography>}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formValues.status === IncidentItemStatusEnum.stolen}
                  onChange={itemStatusChange(IncidentItemStatusEnum.stolen)}
                  value="stolen"
                />
              }
              label={<Typography variant="body1">Stolen</Typography>}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formValues.status === IncidentItemStatusEnum.found}
                  onChange={itemStatusChange(IncidentItemStatusEnum.found)}
                  value="found"
                />
              }
              label={<Typography variant="body1">Found</Typography>}
            />
          </div>
          <div className={classes.lineItem}>
            <FormControl className={classes.formControlLong}>
              <InputLabel shrink htmlFor="propertyOf-label-placeholder">
                Property of
              </InputLabel>
              <Select
                value={formValues.ownerPersonId || ''}
                onChange={inputHandleChange}
                displayEmpty
                name="ownerPersonId"
                className={classes.selectEmpty}
              >
                <MenuItem value="" className={classes.emptyItem} />
                {props.propertyOwners.map(owner => {
                  return (
                    <MenuItem value={owner.id} key={owner.id}>
                      {`${owner.name}`}
                      {` (${
                        owner.personType === 0 ? 'Person' : 'Legal entity'
                      })`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className={classes.lineItem}>
            <FormControl className={classes.formControl}>
              <TextField
                name="name"
                label="Name"
                value={formValues.name || ''}
                onChange={inputHandleChange}
                margin="none"
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={handleFormBlur}
                error={hasFormError('name')}
                helperText={formHelpText('name')}
                inputProps={{ autoComplete: 0 }}
              />
            </FormControl>
            <FormControl className={classes.formControlShort}>
              <TextField
                name="brand"
                label="Brand"
                value={formValues.brand || ''}
                onChange={inputHandleChange}
                margin="none"
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={handleFormBlur}
                error={hasFormError('brand')}
                helperText={formHelpText('brand')}
                inputProps={{ autoComplete: 0 }}
              />
            </FormControl>
            <FormControl className={classes.formControlShort}>
              <TextField
                name="model"
                label="Model"
                value={formValues.model || ''}
                onChange={inputHandleChange}
                margin="none"
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={handleFormBlur}
                error={hasFormError('model')}
                helperText={formHelpText('model')}
                inputProps={{ autoComplete: 0 }}
              />
            </FormControl>
            <FormControl className={classes.formControlShort}>
              <TextField
                name="color"
                label="Color"
                value={formValues.color || ''}
                onChange={inputHandleChange}
                margin="none"
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={handleFormBlur}
                error={hasFormError('color')}
                helperText={formHelpText('color')}
                inputProps={{ autoComplete: 0 }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                name="serialNumber"
                label="Serial #"
                value={formValues.serialNumber || ''}
                onChange={inputHandleChange}
                margin="none"
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={handleFormBlur}
                error={hasFormError('serialNumber')}
                helperText={formHelpText('serialNumber')}
                inputProps={{ autoComplete: 0 }}
              />
            </FormControl>
            <FormControl className={classes.formControlShort}>
              <NumberFormat
                name="value"
                label="value"
                InputLabelProps={{
                  shrink: true,
                }}
                error={hasFormError('value')}
                helperText={formHelpText('value')}
                onChange={inputHandleChange}
                margin="none"
                onBlur={handleFormBlur}
                value={formValues.value || ''}
                thousandSeparator
                prefix="$"
                customInput={TextField}
              />
            </FormControl>
          </div>
          <Typography variant="h6">Comments</Typography>
          <FormControl className={classes.subCommentForm}>
            <RichEditor
              height="low"
              onChange={handleCommentsChange}
              defaultValue={formValues.comments}
            />
          </FormControl>
          <Typography variant="h6">Attachments</Typography>
          <div className={classes.attachmentRoot}>
            {state.attachments &&
              state.attachments
                .filter(x => x.incidentItemId)
                .map(_attachment => {
                  return (
                    <div
                      className={classes.attachmentItem}
                      key={_attachment.id}
                    >
                      <IncidentAttachmentItem
                        editable
                        attachment={_attachment}
                        onDeleted={onAttachmentDeleted}
                        onChange={handleAttachmentChange}
                        onDownload={handleAttachmentDownload}
                      />
                    </div>
                  );
                })}
          </div>
          <div className={classes.itemButton}>
            <Button
              variant="contained"
              className={classes.addAttachmentButton}
              onClick={openAddAttachmentDialog}
            >
              Add attachment
            </Button>
            <Button
              variant="contained"
              className={classes.deleteButton}
              onClick={deleteItem}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
      {addAttachmentDialogOpen.open && (
        <AddAttachmentDialog
          open={addAttachmentDialogOpen.open}
          uploading={addAttachmentDialogOpen.uploading}
          onCancel={() => {
            setAddAttachmentDialogOpen({ open: false, uploading: false });
          }}
          onAttachmentAdded={addAttachment}
          attachment={state.selectedAttachment}
          uploadLimit={props.uploadLimit}
        />
      )}
      {state.selectedAttachment && (
        <AttachedImageViewer
          attachment={state.selectedAttachment}
          open={state.viewerOpen}
          onClose={handleViewerClose}
        />
      )}
    </ExpansionCard>
  );
};
IncidentItemItem.propTypes = {
  item: IncidentItemDef.isRequired,
  expanded: PropTypes.bool,
  onCardExpanded: PropTypes.func,
  onDeleted: PropTypes.func,
  onChange: PropTypes.func,
  propertyOwners: PropTypes.arrayOf(PropertyOwnerDef),
  attachments: PropTypes.arrayOf(IncidentAttachmentDef),
  deleteAttachment: PropTypes.func,
  uploadAttachment: PropTypes.func,
  changeDraftIncident: PropTypes.func,
  uploadLimit: PropTypes.number,
};

IncidentItemItem.defaultProps = {
  expanded: true,
  propertyOwners: [],
  attachments: [],
  onCardExpanded: () => {},
  onDeleted: () => {},
  onChange: () => {},
  deleteAttachment: () => {},
  changeDraftIncident: () => {},
};

const mapStateToProps = ({ common }) => ({
  uploadLimit: common.fileSizeLimit.maxUploadFileSize,
});
const mapDispatchToProps = {
  deleteAttachment,
  uploadAttachment,
  changeDraftIncident,
};
export default connect(mapStateToProps, mapDispatchToProps)(IncidentItemItem);
