import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ChildrenDef } from '../../components/propTypeDefs';

const DashboardItem = props => {
  return (
    <Grid item xs={12} sm={4} md={4} lg={3}>
      {props.children}
    </Grid>
  );
};

DashboardItem.propTypes = {
  children: ChildrenDef,
};

export default DashboardItem;
