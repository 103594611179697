import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Typography, Paper } from '@material-ui/core';
import _ from 'lodash';

import TagManager from 'react-gtm-module';
import DefaultLayout from '../DefaultLayout';
import SignupForm from './SignupForm';
import config from '../../config';
import Auth from '../../auth/Auth';
import history from '../../history';
import { executeSignup } from './ducks';
import SignupCompleted from './SignupCompleted';
import { useMediaInfo } from '../../utils/hooks';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5),
    backgroundColor: '#fafafa',
  },

  mobileRoot: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0),
    backgroundColor: '#fafafa',
  },
  title: {
    padding: theme.spacing(2),
  },
  subTitle: {
    paddingBottom: theme.spacing(1),
  },
}));

const Signup = props => {
  const { isSmaller, isMobile } = useMediaInfo({ minBreakPoint: 'md' });
  const classes = useStyles();

  if (Auth.isAuthenticated) {
    history.push('/dashboard');
  }

  const handleSave = model => {
    props
      .executeSignup(model)
      .then(() => {
        // GTM for signup
        // { gtm: { start: 1618530253332, uniqueEventId: 4 }, event: 'singup' }
        //
        TagManager.dataLayer({
          dataLayer: {
            event: 'singup',
          },
        });
      })
      .catch(err => {});
  };

  return (
    <DefaultLayout title={null}>
      <Typography
        variant="h4"
        marked="center"
        align="center"
        className={classes.title}
      >
        Sign Up for a 30 Day Free Trial
      </Typography>

      <Typography variant="body2" marked="center" className={classes.subTitle}>
        By signing up for Incident Xpress, you will automatically be given 30
        days free access to trial a 5 concurrent user system. If you find iX is
        not for you, simply let your free trial account expire.
        <br />
        If you like what you see, then subscribing is very easy. Simply go to
        the Subscription section in your Incident Xpress account, select a
        monthly or yearly plan and the number of users you need, then enter your
        billing and payment information. Cancel any time. Your subscription will
        terminate on the anniversary date of the monthly or yearly plan you’ve
        selected.
      </Typography>

      <Paper className={isMobile ? classes.mobileRoot : classes.root}>
        {props.completed ? (
          <SignupCompleted emailVerified={props.emailVerified} />
        ) : (
          <SignupForm onSave={handleSave} loading={props.loading} />
        )}
      </Paper>
    </DefaultLayout>
  );
};

Signup.propTypes = {
  completed: PropTypes.bool.isRequired,
  emailVerified: PropTypes.bool.isRequired,
  executeSignup: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ signup }) => ({
  completed: signup.completed,
  emailVerified: signup.user?.status === 1,
  loading: signup.loading,
});
const mapDispatchToProps = {
  executeSignup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
