import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { getContentFromUrl, getAzureFileUrl } from '../../utils/fileUtil';
import Auth from '../../auth/Auth';

const useStyles = makeStyles(theme => ({
  textContainer: {
    flexGrow: 1,
    width: '100%',
    whiteSpace: 'pre-wrap',
    padding: theme.spacing(1, 2),
  },
}));

const TextFileView = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({});

  React.useEffect(() => {
    getContentFromUrl(
      getAzureFileUrl(Auth.currentUser.accountId, props.fileName, false, props.sasKey, props.thumbnailSasKey),
    ).then(res => {
      setState({ ...state, remoteContent: res.data });
    });
  }, []);

  return <div className={classes.textContainer}>{state.remoteContent}</div>;
};

TextFileView.propTypes = {
  fileName: PropTypes.string.isRequired,
  sasKey: PropTypes.string.isRequired,
};

export default TextFileView;
