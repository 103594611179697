import moment from 'moment';
import { DefaultDateFormat } from '../constants';

export const dateFormat = (date, format, occurredDateUncertain = false) => {
  if (!date) {
    return '';
  }

  if (occurredDateUncertain) {
    return `${moment(date).format(format)}?`;
  }
  return `${moment(date).format(format)}`;
};

export default { dateFormat };
