import Auth from '../auth/Auth';

export const canEditIncident = ownerId => {
  if (Auth.hasPrivilege(['ALLINCIDENT_EDIT'])) return true;
  if (
    Auth.hasPrivilege(['MYINCIDENT_EDIT']) &&
    Auth.currentUser.userId === ownerId
  )
    return true;
  return false;
};

export const canPrintIncident = ownerId => {
  if (Auth.hasPrivilege(['ALLINCIDENT_PRINT'])) return true;
  if (
    Auth.hasPrivilege(['MYINCIDENT_PRINT']) &&
    Auth.currentUser.userId === ownerId
  )
    return true;
  return false;
};

export const canDeleteAttachment = ownerId => {
  if (Auth.hasPrivilege(['DELETE_ATTACHMENT'])) return true;
  return false;
};

export const canCloseIncident = ownerId => {
  if (Auth.hasPrivilege(['ALLINCIDENT_CLOSE'])) return true;
  if (
    Auth.hasPrivilege(['MYINCIDENT_CLOSE']) &&
    Auth.currentUser.userId === ownerId
  )
    return true;
  return false;
};
