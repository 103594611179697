import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as yup from 'yup';
import {
  Button,
  TextField,
  FormControl,
  makeStyles,
  Typography,
  FormControlLabel,
  Switch,
  Divider,
  Checkbox,
} from '@material-ui/core';
import _ from 'lodash';
import { FiberNew as NewIcon } from '@material-ui/icons';
import RichEditor from '../../components/forms/RichEditor';
import ExpansionCard from '../../components/cards/ExpansionCard';
import { IncidentCompanyDef } from '../../components/propTypeDefs';
import { uuidPattern } from '../../constants';
import { useForm, useFormInput, useMediaInfo } from '../../utils/hooks';
import IncidentCompanyModel from '../../models/IncidentCompanyModel';
import CitySearch from '../../components/forms/CitySearch';
import CountrySelect from '../../components/forms/CountrySelect';
import { getProvinceStateLabel } from '../../utils/addressUtil';
import companyApi from '../../apis/company.api';
import { getCompanyName } from '../../utils/nameUtil';
import SearchCompanyDialog from '../../components/dialogs/SearchCompanyDialog';
import { enqueueError } from '../../modules/global';

const useStyles = makeStyles(theme => ({
  expansionCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  expansionCardItem: {},
  companyItem: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
  },
  itemAddButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-2),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },
    // width: '100%',
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  subCommentForm: {
    flexGrow: 1,
    margin: theme.spacing(1, 0),
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  lineItem: {
    display: 'flex',
    flexDirection: 'row',
    // alignItems: 'center',
    // height: 80,
    flexWrap: 'wrap',
  },
  companyName: {
    margin: theme.spacing(1),
  },

  itemButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  deleteButton: {
    backgroundColor: theme.palette.error.dark,
    color: 'white',
  },
  newIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const schema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .max(100)
    .required('Name is required'),
  unit: yup
    .string()
    .nullable()
    .max(20),
  address: yup
    .string()
    .nullable()
    .max(100),
  street: yup
    .string()
    .nullable()
    .max(50),
  cityId: yup
    .string()
    .nullable()
    .matches(uuidPattern, {
      message: 'invalid cityId',
      excludeEmptyString: true,
    }),
  postalCode: yup
    .string()
    .nullable()
    .max(10),
  email: yup
    .string()
    .nullable()
    .email(),
  contact: yup
    .string()
    .nullable()
    .max(100),
  phone: yup
    .string()
    .nullable()
    .max(20),
  mobilePhone: yup
    .string()
    .nullable()
    .max(20),
});

const IncidentCompanyItem = props => {
  const classes = useStyles();
  const { expanded, company } = props;
  // const [defaultCity] = React.useState({
  //   cityId: company.cityId,
  //   cityName: company.cityName,
  // });
  const {
    formValues,
    updateFormValues,
    hasFormError,
    formHelpText,
    handleFormChange,
    handleFormBlur,
  } = useForm({
    validationSchema: schema,
    onSubmit: (values, errors) => {},
  });
  const companyModel = new IncidentCompanyModel(company.id, company.name);

  const { isSmaller } = useMediaInfo({ minBreakPoint: 'sm' });

  const [duplicationChecked, setDuplicationChecked] = React.useState({
    checked: company && !!company.personId,
    selectorOpen: false,
    companies: [],
  });

  React.useEffect(() => {
    updateFormValues({
      ...company,
      isOwner: !!company.isOwner,
      duplicationChecked: company.duplicationChecked
        ? company.duplicationChecked
        : !!company.personId,
    });
  }, []);

  React.useEffect(() => {
    !_.isEmpty(formValues) && props.onChange(formValues);
  }, [formValues]);

  const inputHandleChange = event => {
    handleFormChange(event);
  };

  const handleCityChange = e => {
    const { value: city } = e.target || {};
    const value = (city && city.id) || undefined;
    updateFormValues({
      cityId: value,
      cityName: value ? city.name : undefined,
      stateName: value ? city.stateName : undefined,
      countryCode: value ? city.countryCode : undefined,
    });
  };

  const raiseFormEvent = (name, value) => {
    inputHandleChange({ target: { name, value } });
  };

  const handleCommentsChange = value => {
    raiseFormEvent('comments', value);
  };

  const onCardExpanded = expand => {
    props.onCardExpanded(expand, props.company.id);
  };

  const deleteCompany = () => {
    props.onDeleted(props.company.id);
  };

  const handleChangeOverwrite = event => {
    event.stopPropagation();
    inputHandleChange(event);
  };

  const nameHandleChange = event => {
    if (!company.personId) {
      const target = event.target;
      const value = target.value;
      const name = target.name;
      updateFormValues(
        Object.assign(formValues, { [name]: value, duplicationChecked: false }),
      );
    } else {
      inputHandleChange(event);
    }
  };

  const debounceCheckCompanyDuplication = _.debounce(() => {
    const { name } = formValues;
    if (!name) return;

    companyApi
      .duplicationCheck(name)
      .then(response => {
        const duplicatedCompanies = response.data;
        if (duplicatedCompanies && duplicatedCompanies.length > 0) {
          // Open person select
          setDuplicationChecked({
            selectorOpen: true,
            companies: duplicatedCompanies,
          });
        } else {
          raiseFormEvent('duplicationChecked', true);
        }
      })
      .catch(err => {});
  }, 1000);

  const checkDuplication = () => {
    debounceCheckCompanyDuplication();
  };

  const isOwnerChange = event => {
    if (event.target.checked) {
      companyApi
        .getOwnerCompany()
        .then(ownerCompany => {
          updateFormValues(
            Object.assign(formValues, {
              personId: ownerCompany.id,
              isOwner: true,
              name: ownerCompany.name,
            }),
          );
        })
        .catch(err => {
          props.enqueueError(err);
        });
    } else {
      updateFormValues(
        Object.assign(formValues, {
          isOwner: false,
          name: '',
          personId: undefined,
        }),
      );
    }
  };

  const getTitle = () => {
    const name = getCompanyName(company);
    if (!company.personId) {
      return (
        <div className={classes.title}>
          {`${name}`} <NewIcon className={classes.newIcon} />
        </div>
      );
    }
    return name;
  };

  const defaultCity =
    company && company.cityId
      ? { cityId: company.cityId, cityName: company.cityName }
      : undefined;

  return (
    <ExpansionCard
      title={getTitle()}
      theme="sub"
      expanded={expanded}
      onChange={onCardExpanded}
    >
      <div className={classes.expansionCardContainer}>
        <div className={classes.expansionCardItem}>
          <div className={classes.switchGroup}>
            <Typography variant="h6" className={classes.switchGroupLabel}>
              Relation
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={formValues.relationReporter || false}
                  onChange={inputHandleChange}
                  name="relationReporter"
                />
              }
              label={<Typography variant="body1">Reporter</Typography>}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formValues.relationVictim || false}
                  onChange={inputHandleChange}
                  name="relationVictim"
                />
              }
              label={<Typography variant="body1">Victim</Typography>}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formValues.relationSubject || false}
                  onChange={inputHandleChange}
                  name="relationSubject"
                />
              }
              label={<Typography variant="body1">Subject</Typography>}
            />
          </div>
          <Divider variant="fullWidth" />
          <div className={classes.lineItem}>
            <FormControl className={classes.formControlLong}>
              <TextField
                name="name"
                required
                label="Name"
                value={formValues.name || ''}
                onChange={nameHandleChange}
                margin="none"
                onBlur={handleFormBlur}
                InputLabelProps={{
                  shrink: true,
                }}
                error={hasFormError('name')}
                helperText={formHelpText('name')}
                inputProps={{ autoComplete: 0 }}
                disabled={formValues.isOwner}
              />
            </FormControl>
            {!company.personId && (
              <FormControl className={classes.formControl}>
                {formValues.duplicationChecked ? (
                  <FormControlLabel
                    value="top"
                    control={
                      <Checkbox checked={formValues.duplicationChecked} />
                    }
                    label="Duplication checked"
                  />
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={checkDuplication}
                    disabled={formValues.isOwner}
                  >
                    Check Duplication
                  </Button>
                )}
              </FormControl>
            )}
            <FormControlLabel
              control={
                <Switch
                  checked={formValues.isOwner || false}
                  onChange={isOwnerChange}
                  name="isOwner"
                />
              }
              label={<Typography variant="body1">Owner</Typography>}
            />
          </div>
          {!formValues.isOwner && (
            <>
              <div className={classes.lineItem}>
                <FormControl className={classes.formControl}>
                  <TextField
                    label="Unit #"
                    margin="none"
                    name="unit"
                    value={formValues.unit || ''}
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={hasFormError('unit')}
                    helperText={formHelpText('unit')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="address"
                    label="Address #"
                    name="address"
                    value={formValues.address || ''}
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    error={hasFormError('address')}
                    helperText={formHelpText('address')}
                    margin="none"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="street"
                    label="Street/Ave"
                    name="street"
                    value={formValues.street || ''}
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    error={hasFormError('street')}
                    helperText={formHelpText('street')}
                    margin="none"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <CitySearch
                    defaultCity={defaultCity}
                    countryCode={formValues.countryCode}
                    onChange={handleCityChange}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    label={getProvinceStateLabel(formValues.countryCode)}
                    disabled
                    value={formValues.stateName || ''}
                    margin="none"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    label="Country"
                    disabled
                    value={formValues.countryCode || ''}
                    margin="none"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="postalCode"
                    label="Zip/Postal code"
                    className={classes.postalCode}
                    name="postalCode"
                    value={formValues.postalCode || ''}
                    onChange={inputHandleChange}
                    onBlur={handleFormBlur}
                    error={hasFormError('postalCode')}
                    helperText={formHelpText('postalCode')}
                    margin="none"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
              </div>
              <div className={classes.lineItem}>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="contact"
                    label="Contact name"
                    value={formValues.contact || ''}
                    onChange={inputHandleChange}
                    margin="none"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={handleFormBlur}
                    error={hasFormError('contact')}
                    helperText={formHelpText('contact')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    name="phone"
                    label="Phone #"
                    type="tel"
                    placeholder="ex) 123-456-7890"
                    value={formValues.phone || ''}
                    onChange={inputHandleChange}
                    margin="none"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={handleFormBlur}
                    error={hasFormError('phone')}
                    helperText={formHelpText('phone')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
                <FormControl className={classes.formControlLong}>
                  <TextField
                    name="email"
                    label="Email"
                    type="email"
                    value={formValues.email || ''}
                    onChange={inputHandleChange}
                    margin="none"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={handleFormBlur}
                    error={hasFormError('email')}
                    helperText={formHelpText('email')}
                    inputProps={{ autoComplete: 0 }}
                  />
                </FormControl>
              </div>
            </>
          )}
          <Typography variant="h6">Comments</Typography>
          <FormControl className={classes.subCommentForm}>
            <RichEditor
              height="low"
              onChange={handleCommentsChange}
              defaultValue={formValues.comments}
            />
          </FormControl>
          <div className={classes.itemButton}>
            <Button
              variant="contained"
              // color="red"
              className={classes.deleteButton}
              onClick={deleteCompany}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
      <SearchCompanyDialog
        open={duplicationChecked.selectorOpen}
        type="SELECT"
        companies={duplicationChecked.companies}
        onCompanySelected={companySelected => {
          const { id: personId, ...others } = companySelected;
          const compressed = _.omitBy(others, _.isNull);
          const newCompany = {
            ...compressed,
            personId,
            duplicationChecked: true,
          };

          updateFormValues(newCompany);
          setDuplicationChecked({
            selectorOpen: false,
            companies: [],
          });
        }}
        onClose={() => {
          setDuplicationChecked({
            selectorOpen: false,
            companies: [],
          });
        }}
      />
    </ExpansionCard>
  );
};

IncidentCompanyItem.propTypes = {
  company: IncidentCompanyDef.isRequired,
  expanded: PropTypes.bool,
  onCardExpanded: PropTypes.func,
  onDeleted: PropTypes.func,
  onChange: PropTypes.func,
  enqueueError: PropTypes.func,
};

IncidentCompanyItem.defaultProps = {
  expanded: true,
  onCardExpanded: () => {},
  onDeleted: () => {},
  onChange: () => {},
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  enqueueError,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncidentCompanyItem);
