import BaseApi from './BaseApi';
import { incidentSearchResult } from '../mockData';

class SearchApi extends BaseApi {
  constructor() {
    super();
    this.baseUrl += `/incidents/search`;
  }

  async search(criteria, name) {
    return await this.post(this.baseUrl, { ...criteria, auditTitle: name });
  }

  async createCustom(model) {
    return await this.post(`${this.baseUrl}/custom`, model);
  }

  async updateCustom(model) {
    return await this.put(`${this.baseUrl}/custom`, model);
  }

  async deleteCustom(id) {
    return await this.delete(`${this.baseUrl}/custom/${id}`);
  }

  async queryCustomSearches() {
    return await this.get(`${this.baseUrl}/custom`);
  }
}

export default new SearchApi();
