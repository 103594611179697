import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  NoSsr,
  makeStyles,
  Typography,
  MenuItem,
  Paper,
  TextField,
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import _ from 'lodash';
import { CityQueryFilter } from '../../models/helpers/QueryModel';
import addressApi from '../../apis/address.api';
import { ChildrenDef } from '../propTypeDefs';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    overflow: 'visible',
    minWidth: '200px',
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    // zIndex: theme.zIndex.modal + 100,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
}));

const NoOptionsMessage = props => {
  const classes = useStyles();
  return (
    <Typography
      color="textSecondary"
      className={classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

NoOptionsMessage.propTypes = {
  children: ChildrenDef.isRequired,
  innerProps: PropTypes.object,
};

const inputComponent = ({ inputRef, ...props }) => {
  return <div ref={inputRef} {...props} />;
};

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

const Control = props => {
  const classes = useStyles();
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.TextFieldProps}
    />
  );
};

Control.propTypes = {
  children: ChildrenDef.isRequired,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

const Option = props => {
  const { label, stateCode, countryCode } = props.data;
  const cityLabel = [label, stateCode, countryCode].filter(x => x).join(', ');
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {cityLabel}
    </MenuItem>
  );
};

Option.propTypes = {
  children: ChildrenDef.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isFocused: PropTypes.bool.isRequired,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  data: PropTypes.object,
};

const Placeholder = props => {
  const classes = useStyles();
  return (
    <Typography
      color="textSecondary"
      className={classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

Placeholder.propTypes = {
  children: ChildrenDef.isRequired,
  innerProps: PropTypes.object,
};

const SingleValue = props => {
  const classes = useStyles();
  return (
    <Typography className={classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
};

SingleValue.propTypes = {
  children: ChildrenDef.isRequired,
  innerProps: PropTypes.object,
};

const ValueContainer = props => {
  const classes = useStyles();
  return <div className={classes.valueContainer}>{props.children}</div>;
};

ValueContainer.propTypes = {
  children: ChildrenDef.isRequired,
};

const Menu = props => {
  const classes = useStyles();
  return (
    <Paper square className={classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
};

Menu.propTypes = {
  children: ChildrenDef.isRequired,
  innerProps: PropTypes.object,
};

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const CitySearch = props => {
  const classes = useStyles();
  const theme = useTheme();
  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    menuPortal: base => ({ ...base, zIndex: theme.zIndex.modal + 100 }),
    dropdownIndicator: base => ({
      ...base,
      padding: 6,
    }),
    clearIndicator: base => ({
      ...base,
      padding: 6,
    }),
  };
  const [state, setState] = React.useState({
    cityName: '',
    searchResults: [],
    value: null,
  });
  const searchMinLength = 1;
  const filter = new CityQueryFilter();

  React.useEffect(() => {
    if (props.defaultCity && props.defaultCity.cityId) {
      const { cityId, cityName, ...others } = props.defaultCity;
      const defaultValue = { value: cityId, label: cityName, ...others };
      setState({
        ...state,
        searchResults: [defaultValue],
        value: defaultValue,
      });
    }
  }, [props.defaultCity]);

  const handelInputChange = (value, { action }) => {
    // if (action === 'input-change') {
    // }
    const search = _.debounce(async keyword => {
      filter.name = keyword;
      const res = await addressApi.searchCity(filter.toParams());
      setState({
        ...state,
        searchResults: res.data.map(x => {
          const { id, name, ...others } = x;
          return { value: id, label: name, ...others };
        }),
      });
    }, 300);

    switch (action) {
      case 'input-change':
        if (value && value.length > searchMinLength) {
          setState({ ...state, cityName: value });
          search(value);
        } else if (!value) {
          setState({ ...state, searchResults: [], cityName: '' });
        }
        break;
      case 'set-value': // value is empty
        break;
      default:
        break;
    }
  };
  const handleChange = selected => {
    setState({ ...state, searchResults: [], cityName: '', value: selected });
    let value;
    if (selected) {
      const { value: id, label: name, ...others } = selected;
      value = { id, name, ...others };
    }
    props.onChange({ target: { name: props.name, value } });
  };

  const noOptionsMessage = () =>
    state.cityName ? 'No cities' : 'Search a city';

  return (
    <div className={classes.root}>
      <NoSsr>
        <Select
          classes={classes}
          styles={selectStyles}
          menuPortalTarget={document.body}
          onInputChange={handelInputChange}
          onChange={handleChange}
          value={state.value}
          components={components}
          inputId="city-search"
          isClearable
          noOptionsMessage={noOptionsMessage}
          TextFieldProps={{
            label: 'City',
            InputLabelProps: { htmlFor: 'city-search', shrink: true },
            margin: 'none',
            autoComplete: 'off',
          }}
          placeholder=""
          options={state.searchResults}
        />
        <div className={classes.divider} />
      </NoSsr>
    </div>
  );
};

CitySearch.propTypes = {
  defaultCity: PropTypes.shape({
    cityId: PropTypes.string,
    cityName: PropTypes.string,
  }),
  onChange: PropTypes.func,
  name: PropTypes.string,
};

CitySearch.defaultProps = {
  defaultCity: undefined,
  name: 'cityId',
  onChange: f => f,
};

export default CitySearch;
