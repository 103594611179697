import React from 'react';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import { makeStyles } from '@material-ui/styles';

import _ from 'lodash';
import BaseDialog from '../../components/dialogs/BaseDialog';
import { CustomAnalysisDef } from '../../components/propTypeDefs';
import ChartViewer from '../analyze/ChartViewer';

const useStyles = makeStyles(theme => ({
  chartWrapper: {
    height: 520,
  },
}));

const AnalyzeDialog = props => {
  const classes = useStyles();
  const { analysis } = props;
  const { name, criteria, chartType } = analysis;

  return (
    <BaseDialog
      open={props.open}
      maxWidth="md"
      title=""
      onClose={props.onClose}
    >
      <div className={classes.chartWrapper}>
        <ChartViewer
          title={name}
          chartType={chartType || 'bar'}
          analysisCriteria={criteria}
        />
      </div>
    </BaseDialog>
  );
};

AnalyzeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  analysis: CustomAnalysisDef.isRequired,
};
AnalyzeDialog.defaultProps = {
  open: false,
  onClose: f => f,
};

export default AnalyzeDialog;
