import BaseApi from './BaseApi';
import { IncidentStatusEnum } from '../constants';

class IncidentApi extends BaseApi {
  urlPersons;

  constructor() {
    super();
    this.baseUrl += `/incidents`;
    this.urlPersons = `${this.baseUrl}/persons`;
  }

  async submit(model) {
    if (model.status === IncidentStatusEnum.openDraft) {
      return await this.put(this.baseUrl, model);
    }
    return await this.post(this.baseUrl, model);
  }

  async close(id) {
    return await this.post(`${this.baseUrl}/close/${id}`);
  }

  async queryMyIncidents() {
    return await this.get(`${this.baseUrl}/my`);
  }

  async addPerson(model) {
    return await this.post(this.urlPersons, model);
  }

  async uploadPersonPicture(id, incidentId, file) {
    const form = new FormData();
    form.append('incidentId', incidentId);
    form.append('file', file);

    return await this.post(`${this.urlPersons}/picture/${id}`, form);
  }

  async queryPersons(incidentId) {
    return await this.get(`${this.baseUrl}/${incidentId}/persons`);
  }

  async queryCompanies(incidentId) {
    return await this.get(`${this.baseUrl}/${incidentId}/companies`);
  }

  async queryVehicles(incidentId) {
    return await this.get(`${this.baseUrl}/${incidentId}/vehicles`);
  }

  async queryItems(incidentId) {
    return await this.get(`${this.baseUrl}/${incidentId}/items`);
  }

  async addAttachment(attachment) {
    const form = new FormData();
    Object.keys(attachment).forEach(key => {
      form.append(key, attachment[key]);
    });
    return await this.post(`${this.baseUrl}/attachments`, form);
  }

  async updateAttachment(attachment) {
    return await this.put(`${this.baseUrl}/attachments`, attachment);
  }

  async queryAttachments(incidentId) {
    return await this.get(`${this.baseUrl}/${incidentId}/attachments`);
  }

  async getCorrectiveActions(incidentId) {
    return await this.get(`${this.baseUrl}/${incidentId}/correctiveActions`);
  }

  async queryHistory(incidentId) {
    return await this.get(`${this.baseUrl}/${incidentId}/history`);
  }

  async getHistory(id) {
    return await this.get(`${this.baseUrl}/historyItem/${id}`);
  }

  async getHistoryCompare(id) {
    return await this.get(`${this.baseUrl}/historyItemForCompare/${id}`);
  }

  async openClosedIncident(incidentId) {
    return await this.post(`${this.baseUrl}/open/${incidentId}`);
  }

  async sendReadAudit(id, number) {
    return await this.post(`${this.baseUrl}/audits/read`, { id, number });
  }

  async deleteIncident(id) {
    return await this.delete(`${this.baseUrl}/${id}`);
  }

  async lock(id) {
    return await this.post(`${this.baseUrl}/lock/${id}`);
  }

  async unlock(id) {
    return await this.post(`${this.baseUrl}/unlock/${id}`);
  }
}

export default new IncidentApi();
