import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  Select,
  makeStyles,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import produce from 'immer';
import {
  getChildLookups,
  findFromLookupTreeList,
} from '../../utils/lookupTreeUtil';
import { LookupDef } from '../../components/propTypeDefs';
import { LookupTypeKey } from '../../constants';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const LookupMultiplesSelect = props => {
  const classes = useStyles();
  const { labels, lookups } = props;
  const [state, setState] = React.useState({
    values: [], // selected lookup ids
    lookupOptions: [], // array of select options array
    openIndex: -1, // opened select index (-1 : all closed)
  });
  let currentValue = props.defaultValue;

  function buildDefaultValues(treeList, value) {
    const found = findFromLookupTreeList(treeList, value);
    if (found) {
      const values = found.parents.map(x => x.id);
      values.push(value);
      return values;
    }
    return [];
  }

  React.useEffect(() => {
    const values = buildDefaultValues(lookups, props.defaultValue);
    const lookupOptions = values.map(x => getChildLookups(lookups, x, true));
    lookupOptions.splice(0, 0, getChildLookups(lookups, undefined, true));
    setState({
      ...state,
      values,
      lookupOptions,
    });
  }, [props.defaultValue]);

  const handleChange = index => e => {
    const { value } = e.target;
    if (currentValue === value) return;
    if (index) {
      currentValue = value || state.values[index - 1];
    } else {
      // first lookup
      currentValue = value;
    }
    const nextOptions = value ? getChildLookups(lookups, value, true) : []; // next lookup select control's option
    const nextState = produce(state, draft => {
      if (nextOptions && nextOptions.length > 0) {
        draft.lookupOptions[index + 1] = nextOptions;
        draft.openIndex = value ? index + 1 : -1;
      } else {
        draft.lookupOptions.length = index + 1;
        draft.openIndex = -1;
      }
      draft.values[index] = value;
      const length = labels.length;
      for (let i = index + 1; i < length; i++) {
        draft.values[i] = '';
      }
    });
    setState(nextState);
    props.onChange(currentValue);
  };
  const handleOpen = index => () => {
    setState({ ...state, openIndex: index });
  };
  const handleClose = () => {
    setState({ ...state, openIndex: -1 });
  };
  const hasOptions = index =>
    state.lookupOptions[index] && state.lookupOptions[index].length;

  return (
    <>
      {labels.map((labelName, index) => (
        <FormControl
          key={labelName}
          disabled={props.disabled}
          className={classes.formControl}
          error={props.error && index === 0 && props.required}
          required={index === 0 && props.required}
        >
          <InputLabel
            disabled={props.disabled}
            shrink
            htmlFor="category-one-label-placeholder"
          >
            {labelName}
          </InputLabel>
          <Select
            open={state.openIndex === index}
            onOpen={handleOpen(index)}
            onClose={handleClose}
            onChange={handleChange(index)}
            onBlur={props.onBlur}
            value={state.values[index] || ''}
            name={labelName}
            disabled={props.disabled || !hasOptions(index)}
            displayEmpty
            className={classes.selectEmpty}
          >
            <MenuItem value="" />
            {state.lookupOptions[index] &&
              state.lookupOptions[index].map(({ id, name }) => (
                <MenuItem value={id} key={id}>
                  {name}
                </MenuItem>
              ))}
          </Select>
          {index === 0 && props.error && (
            <FormHelperText>{props.errorText}</FormHelperText>
          )}
        </FormControl>
      ))}
    </>
  );
};

LookupMultiplesSelect.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  lookups: PropTypes.arrayOf(LookupDef),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  required: PropTypes.bool,
};
LookupMultiplesSelect.defaultProps = {
  labels: [],
  lookups: [],
  onChange: f => f,
  defaultValue: '',
  disabled: false,
  required: false,
};

export default LookupMultiplesSelect;
