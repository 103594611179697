import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';

import BaseDialog from '../../../components/dialogs/BaseDialog';
import { toReadableAudit } from '../../../utils/auditViewUtil';

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: 600,
  },
  value: {
    fontWeight: 500,
    whiteSpace: 'pre-line',
  },
}));

const AuditDetailViewer = props => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    readable: {},
  });
  const { auditData } = props;
  const { readable } = state;

  const handleClose = () => {
    props.onClose();
  };

  React.useEffect(() => {
    setState({
      ...state,
      readable: toReadableAudit(auditData, props.auditType),
    });
  }, [props.auditData]);

  return (
    <BaseDialog
      open={props.open}
      maxWidth="sm"
      onClose={handleClose}
      title="Audit detail"
      actionContent={
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      }
    >
      <Table size="small">
        <TableBody>
          {Object.keys(readable).map(k => (
            <TableRow key={k}>
              <TableCell
                component="th"
                className={classes.label}
                scope="column"
              >
                {k}
              </TableCell>
              <TableCell className={classes.value}>{readable[k]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </BaseDialog>
  );
};

AuditDetailViewer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  auditData: PropTypes.object.isRequired,
  auditType: PropTypes.string.isRequired,
};

AuditDetailViewer.defaultProps = {
  onClose: f => f,
};

export default AuditDetailViewer;
