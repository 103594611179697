import { translateConfig } from './simpleTranslateUtil';
import { displayCentAsDollar } from './currencyUtil';

const convertUser = data => {
  const { email, firstName, middleName, lastName, userGroupName } = data;
  return {
    'First name': firstName,
    'Middle initial': middleName,
    'Last name': lastName,
    Email: email,
    Workgroup: userGroupName,
  };
};
const convertUserGroup = data => {
  const { isAdmin, name, userPrivileges } = data;
  return {
    Name: name,
    Admin: isAdmin ? 'Yes' : 'No',
    Privileges: userPrivileges.join('\n'),
  };
};
const convertAccount = data => {
  if (!data) {
    return {};
  }

  const { companyName, measurementType, dateFormat } = data;
  return {
    Name: companyName,
    'Measurement type': translateConfig.enums.measurementTypes[measurementType],
    'Date format': translateConfig.enums.accountDateFormats[dateFormat],
  };
};
const convertLookup = data => {
  const { name, parentPath, label } = data;
  return {
    [label || 'Name']: name,
    Parent: parentPath,
  };
};
const convertSubscription = data => {
  const {
    trial,
    amount,
    cardLast4,
    periodType,
    countryCode,
    nextChargeDate,
    paymentCustomerId,
    concurrentUserCount,
    subscriptionValidDate,
  } = data;
  return {
    Period: periodType,
    'Concurrent User': concurrentUserCount,
    Amount: displayCentAsDollar(amount),
    'Card Number': `xxxx xxxx xxxx ${cardLast4}`,
  };
};
const convertPaymentMethod = data => {
  const { paymentEmail, paymentMethodValid, paymentError } = data;
  return {
    'Billing email': paymentEmail,
    'Is valid': paymentMethodValid ? 'Valid' : 'Invalid',
    'Error message': paymentError,
  };
};

export const toReadableAudit = (data, objectType) => {
  switch (objectType) {
    case 'USER':
      return convertUser(data);
    case 'USER_GROUP':
      return convertUserGroup(data);
    case 'ACCOUNT':
      return convertAccount(data);
    case 'LOOKUP':
      return convertLookup(data);
    case 'SUBSCRIPTION':
      return convertSubscription(data);
    case 'PAYMENT_METHOD':
      return convertPaymentMethod(data);
    default:
      return data;
  }
};

export default {
  toReadableAudit,
};
